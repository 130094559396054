import { ApModel } from 'app/models/documents/ap.model';
import { DocumentVM } from 'app/stores/ui/document.ui.store';
import { observer } from 'mobx-react';
import { Button } from '../common/Button';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import FormField from '../form/FormField';
import { useStore } from 'app/context';

interface IApJahresZielForm {
	doc: DocumentVM;
}

const yupNum = Yup.number().typeError('Nur Zahleneingaben sind erlaubt').required('Eingabe erforderlich').default(0);

const abBestandSchema = Yup.object({
	bestand: yupNum,
}).required();

type Bestand = Yup.InferType<typeof abBestandSchema>;

export const ApBestandForm = observer((props: IApJahresZielForm) => {
	const { uiStore, documentUiStore } = useStore();
	const ap = props.doc.doc.content as ApModel;

	const onCancel = () => {
		uiStore.hideModal(uiStore.modalIds.apEditBestand);
	};

	const handleSubmit = async (values: Bestand) => {
		ap.commissionCalcContent.bestand = values.bestand;
		await documentUiStore.save(props.doc);
		uiStore.hideModal(uiStore.modalIds.apEditBestand);
	};

	return (
		<>
			<Formik
				validationSchema={abBestandSchema}
				initialValues={{
					bestand: ap.commissionCalcContent.bestand,
				}}
				onSubmit={handleSubmit}
			>
				{({ errors, touched, values, isValid, handleChange }) => {
					return (
						<Form>
							<div className="pad-bottom-1rem">
								<FormField className="title" error={errors.bestand} touched={touched.bestand} label="Eingabe Sach-Gesamt-Bestand in €">
									<Field type="text" name="bestand" placeholder="Sach-Gesamt-Bestand" />
								</FormField>
							</div>

							<div className="modal-footer">
								<Button type="button" className="button is-secondary" onClick={onCancel}>
									Abbrechen
								</Button>
								<Button type="submit" className="button is-primary ">
									Speichern
								</Button>
							</div>
						</Form>
					);
				}}
			</Formik>
		</>
	);
});
