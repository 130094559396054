import { useStore } from 'app/context';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { useHistory } from 'react-router';
import routes from 'routes';
import Panel from '../../Panel';
import { AdminBnrTransferEditForm } from './AdminBnrTransferEditForm';
export const AdminBnrTransferDetail = observer(() => {
	const { adminBnrTransferUiStore } = useStore();
	const transfer = adminBnrTransferUiStore.current;
	const history = useHistory();

	const onClose = () => {
		runInAction(() => {
			adminBnrTransferUiStore.current = undefined;
			history.push(routes.ADMINBNRTRANSFER.getPath());
		});
	};

	return (
		<>
			{transfer && (
				<Panel title={transfer.transfer.sourceBnrId + ' --> ' + transfer.transfer.targetBnrId} header={<> </>} onClose={onClose}>
					{transfer && <AdminBnrTransferEditForm transfer={transfer} onClose={onClose} />}
				</Panel>
			)}
		</>
	);
});
