import { ILeadnowFormItem } from './leadnow.form.items';
import { LeadnowKVModel } from './leadnow.kv.model';

export class FormActionButton {
	constructor(opts: any) {
		this.buttonClass = opts.buttonClass;
		this.buttonText = opts.buttonText;
		this.buttonAction = opts.buttonAction;
		this.buttonIcon = opts.buttonIcon;
	}
	buttonClass: string;
	buttonText: string;
	buttonAction: any;
	buttonIcon: string;
}

export class LeadnowCheckFormItem {
	constructor(form: ILeadnowFormItem, items: LeadnowKVModel[]) {
		const item = items.find((i) => i.key === form.key);
		if (item) {
			this.data = item;
		} else {
			this.data = new LeadnowKVModel(form);
		}

		this.label = form.label;
		this.details = form.details;
		if (form.visible !== undefined) {
			this.visible = form.visible;
		} else {
			this.visible = true;
		}

		if (form.editable !== undefined) {
			this.editable = form.editable;
		} else {
			this.editable = true;
		}

		if (form.pro3Paket2 !== undefined) {
			this.pro3Paket2 = form.pro3Paket2;
		} else {
			this.pro3Paket2 = true;
		}
		if (form.actionButton) {
			this.actionButton = new FormActionButton(form.actionButton);
		}
	}
	data: LeadnowKVModel;
	label: string;
	details?: string;
	visible: boolean;
	editable: boolean;
	pro3Paket2: boolean;
	actionButton?: FormActionButton;
}
