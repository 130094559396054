import Widget from 'app/components/common/Widget';
import { Table } from 'app/components/table/table';
import { AggregrationType, ITableColumn, TableModel } from 'app/components/table/table.model';
import { useStore } from 'app/context';
import { IUserTotalsData } from 'app/stores/ui/admin.usage.ui.store';
import { observer } from 'mobx-react';
import { useEffect, useMemo } from 'react';

export const AdminUserRegisteredUsers = observer(() => {
	const { adminUsageUiStore } = useStore();

	useEffect(() => {
		adminUsageUiStore.loadLoginData();
	}, [adminUsageUiStore]);

	return (
		<>
			<AdminUserRegisteredUsersByFunc />
			<AdminUserRegisteredUsersByVD />
			{/* <AdminUsageLoginsProRolleJeVd /> */}
			{adminUsageUiStore.currentFilter.vdId && <AdminUserRegisteredUsersByGs />}
		</>
	);
});

export const AdminUserRegisteredUsersByFunc = observer(() => {
	const { adminUsageUiStore } = useStore();

	const tm = useMemo(() => {
		const tm = new TableModel<IUserTotalsData, number>();
		let cols: ITableColumn<IUserTotalsData, number>[] = [
			{
				label: 'Funktion',
				path: 'name',
			},
			{
				label: 'Angemeldet',
				path: 'registered',
				agg: {
					path: 'registered',
					aggType: AggregrationType.sum,
				},
			},
			{
				label: 'Gesamt',
				path: 'total',
				agg: {
					path: 'total',
					aggType: AggregrationType.sum,
				},
			},
			{
				label: 'Prozent',
				path: 'percent',
				agg: {
					path: 'percent',
					aggType: AggregrationType.avg,
				},
			},
		];
		tm.setCols(cols);
		tm.sortBy = 'name';
		tm.idProperty = 'id';
		tm.idType = 'string';
		return tm;
	}, []);

	useEffect(() => {
		tm.setRowData(adminUsageUiStore.registerdUserByRole);
	}, [tm, adminUsageUiStore.registerdUserByRole]);

	return (
		<>
			<div className="pad-1rem">
				<Widget title="Angemeldete Nutzer nach Funktionsgruppe" modifier="is-paddingless" action={<span className="tag is-green">DVS Datenbank</span>}>
					<Table tm={tm} />
				</Widget>
			</div>
		</>
	);
});

export const AdminUserRegisteredUsersByVD = observer(() => {
	const { adminUsageUiStore } = useStore();

	const tm = useMemo(() => {
		const tm = new TableModel<IUserTotalsData, number>();
		let cols: ITableColumn<IUserTotalsData, number>[] = [
			{
				label: 'Funktion',
				path: 'name',
			},
			{
				label: 'Angemeldet',
				path: 'registered',
				agg: {
					path: 'registered',
					aggType: AggregrationType.sum,
				},
			},
			{
				label: 'Gesamt',
				path: 'total',
				agg: {
					path: 'total',
					aggType: AggregrationType.sum,
				},
			},
			{
				label: 'Prozent',
				path: 'percent',
				agg: {
					path: 'percent',
					aggType: AggregrationType.avg,
				},
			},
		];
		tm.setCols(cols);
		tm.sortBy = 'name';
		tm.idProperty = 'id';
		tm.idType = 'string';
		return tm;
	}, []);

	useEffect(() => {
		tm.setRowData(adminUsageUiStore.registerdUserByVD);
	}, [tm, adminUsageUiStore.registerdUserByVD]);

	return (
		<>
			<div className="pad-1rem">
				<Widget title="Angemeldete Nutzer nach VD" modifier="is-paddingless" action={<span className="tag is-green">DVS Datenbank</span>}>
					<Table tm={tm} />
				</Widget>
			</div>
		</>
	);
});

export const AdminUserRegisteredUsersByGs = observer(() => {
	const { adminUsageUiStore } = useStore();

	const tm = useMemo(() => {
		const tm = new TableModel<IUserTotalsData, number>();
		let cols: ITableColumn<IUserTotalsData, number>[] = [
			{
				label: 'Funktion',
				path: 'name',
			},
			{
				label: 'Angemeldet',
				path: 'registered',
				agg: {
					path: 'registered',
					aggType: AggregrationType.sum,
				},
			},
			{
				label: 'Gesamt',
				path: 'total',
				agg: {
					path: 'total',
					aggType: AggregrationType.sum,
				},
			},
			{
				label: 'Prozent',
				path: 'percent',
				agg: {
					path: 'percent',
					aggType: AggregrationType.avg,
				},
			},
		];
		tm.setCols(cols);
		tm.sortBy = 'name';
		tm.idProperty = 'id';
		tm.idType = 'string';
		return tm;
	}, []);

	useEffect(() => {
		tm.setRowData(adminUsageUiStore.registerdUserByGs);
	}, [tm, adminUsageUiStore.registerdUserByGs]);

	return (
		<>
			<div className="pad-1rem">
				<Widget title="Angemeldete Nutzer nach GS" modifier="is-paddingless" action={<span className="tag is-green">DVS Datenbank</span>}>
					<Table tm={tm} />
				</Widget>
			</div>
		</>
	);
});
