import { Button } from 'app/components/common/Button';
import { Icon } from 'app/components/common/Icon';
import Widget, { IWidgetConsumer } from 'app/components/common/Widget';
import { useStore } from 'app/context';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { useHistory, useLocation } from 'react-router';
import routes from 'routes';

export const AgenturKontaktDatenWidget = observer((props: IWidgetConsumer) => {
	const { agenturUiStore, agenturMapsUiStore, uiStore } = useStore();
	const agt = agenturUiStore.current;
	const history = useHistory();
	const location = useLocation();

	const onHighlight = () => {
		runInAction(() => {
			agenturMapsUiStore.higlightedAgtId = agt!.agtId;
			uiStore.showMap = true;
		});
		let url = routes.AGENTUR.getPath() + location.search;

		history.push(url);
	};

	return (
		<Widget title={'Kontaktdaten'} modifier="is-paddingless" widgetId={props.widgetKey}>
			{agt && (
				<table className="table is-text">
					<tbody>
						<tr>
							<td className="full-width">
								<strong>{agt.gebiet.vgbStufe2Text}</strong>
							</td>
							<td>Telefon</td>
							<td className="has-text-left">
								<a href={`tel:${agt.vtnrTelFest}`} className="button is-text is-padded">
									{agt.vtnrTelFest}
								</a>
							</td>
						</tr>
						<tr>
							<td>{agt.vtnrStr}</td>
							<td>Mobil</td>
							<td className="has-text-left">
								<a href={`tel:${agt.vtnrTelMobil}`} className="button is-text is-padded">
									{agt.vtnrTelMobil}
								</a>
							</td>
						</tr>
						<tr>
							<td>
								{agt.vtnrPlz} {agt.vtnrOrt}
							</td>
							<td>E-Mail</td>
							<td className="has-text-left">
								<a href={`mailto:${agt.email}`} className="button is-text is-padded">
									{agt.email}
								</a>
							</td>
						</tr>
						<tr>
							<td colSpan={3}>
								<Button className={'button is-small is-turquoise is-inverted'} onClick={onHighlight}>
									<Icon iconClass="map-marker-alt" />
									<span>Auf Karte zeigen</span>
								</Button>
							</td>
						</tr>
					</tbody>
				</table>
			)}
		</Widget>
	);
});
