import { observer } from 'mobx-react';
import Widget, { IWidgetConsumer } from '../common/Widget';
import { NewsLast3 } from './NewsList';
import { useStore } from 'app/context';
import { useEffect, useState } from 'react';

export const NewsWidget = observer((props: IWidgetConsumer) => {
	const { newsUiStore } = useStore();
	const [itemCount, setItemCount] = useState<number>(newsUiStore.last3Items.length);
	useEffect(() => {
		newsUiStore.load().then(() => {
			setItemCount(newsUiStore.last3Items.length)
		})
	}, [newsUiStore.last3Items])
	if (itemCount === 0) {
		return <></>;
	}
	return (
		<>
			<Widget modifier="is-paddingless" title={'News'} widgetId={props.widgetKey}>
				<NewsLast3 />
			</Widget>
		</>
	);
});
