import Widget from 'app/components/common/Widget';
import { Table } from 'app/components/table/table';
import { AggregrationType, ITableColumn, TableModel, TableRow } from 'app/components/table/table.model';
import { useStore } from 'app/context';
import { IBPTableRow, trackingRoles, vdIds } from 'app/stores/ui/admin.usage.ui.store';
import { observer } from 'mobx-react';
import moment from 'moment';
import { useEffect, useMemo } from 'react';

export const AdminUsageBranchenplanung = observer(() => {
	const { adminUsageUiStore } = useStore();

	useEffect(() => {
		adminUsageUiStore.loadBranchenPlanung();
	}, [adminUsageUiStore]);

	return (
		<>
			<AdminUsageVdBp />
			<AdminUsageBpProRolle />
			{adminUsageUiStore.currentFilter.vdId && <AdminUsageGsBp />}
		</>
	);
});

export const AdminUsageBpProRolle = observer(() => {
	const { adminUsageUiStore } = useStore();

	const tmRoleBp = useMemo(() => {
		const tm = new TableModel<IBPTableRow, number>();
		let cols: ITableColumn<IBPTableRow, number>[] = [
			{
				label: 'Monat',
				path: 'ym',
				render: (row: TableRow<IBPTableRow>) => {
					return <span>{moment(row.data.ym).format('MMM YYYY')}</span>;
				},
			},
		];

		trackingRoles.forEach((role) => {
			cols.push({
				label: role.name.toUpperCase(),
				path: role.name.toLowerCase(),
				agg: {
					path: role.name.toLowerCase(),
					aggType: AggregrationType.sum,
				},
			});
		});

		cols.push({
			label: 'Summe',
			path: 'sum',
			agg: {
				path: 'sum',
				aggType: AggregrationType.sum,
			},
		});

		tm.setCols(cols);
		tm.sortBy = 'ym';
		tm.idProperty = 'id';
		tm.idType = 'string';
		return tm;
	}, []);

	useEffect(() => {
		tmRoleBp.setRowData(adminUsageUiStore.agtBpStatsByRole);
	}, [adminUsageUiStore.agtBpStatsByRole, tmRoleBp]);

	return (
		<>
			<div className="pad-1rem">
				<Widget title="Vollständige Branchenplanungen je Rolle" modifier="is-paddingless" action={<span className="tag is-green">DVS Datenbank</span>}>
					<Table tm={tmRoleBp} />
				</Widget>
			</div>
		</>
	);
});

export const AdminUsageVdBp = observer(() => {
	const { adminUsageUiStore } = useStore();

	const tmAgtBpVd = useMemo(() => {
		const tm = new TableModel<IBPTableRow, number>();
		let cols: ITableColumn<IBPTableRow, number>[] = [
			{
				label: 'Monat',
				path: 'ym',
				render: (row: TableRow<IBPTableRow>) => {
					return <span>{moment(row.data.ym).format('MMM YYYY')}</span>;
				},
			},
		];

		vdIds.forEach((vd) => {
			cols.push({
				label: adminUsageUiStore.currentFilter.vdId?.toUpperCase() === vd.toUpperCase() ? '*' + vd.toUpperCase() + '*' : vd.toUpperCase(),
				path: vd.toLowerCase(),
				agg: {
					path: vd.toLowerCase(),
					aggType: AggregrationType.sum,
				},
			});
		});

		cols.push({
			label: 'Summe',
			path: 'sum',
			agg: {
				path: 'sum',
				aggType: AggregrationType.sum,
			},
		});

		tm.setCols(cols);
		tm.sortBy = 'ym';
		tm.idProperty = 'id';
		tm.idType = 'string';
		return tm;
	}, [adminUsageUiStore.currentFilter.vdId]);

	useEffect(() => {
		tmAgtBpVd.setRowData(adminUsageUiStore.agtBpByVd);
	}, [adminUsageUiStore.agtBpByVd, tmAgtBpVd]);

	return (
		<>
			<div className="pad-1rem">
				<Widget title="Vollständige Branchenplanungen je VD" modifier="is-paddingless" action={<span className="tag is-green">DVS Datenbank</span>}>
					<Table tm={tmAgtBpVd} />
				</Widget>
			</div>
		</>
	);
});

export const AdminUsageGsBp = observer(() => {
	const { adminUsageUiStore } = useStore();

	const tmAgtBpGs = useMemo(() => {
		const tm = new TableModel<IBPTableRow, number>();
		let cols: ITableColumn<IBPTableRow, number>[] = [
			{
				label: 'Monat',
				path: 'ym',
				render: (row: TableRow<IBPTableRow>) => {
					return <span>{moment(row.data.ym).format('MMM YYYY')}</span>;
				},
			},
		];

		adminUsageUiStore.filteredGsIds.forEach((vd) => {
			cols.push({
				label: vd.toUpperCase(),
				path: vd.toLowerCase(),
				agg: {
					path: vd.toLowerCase(),
					aggType: AggregrationType.sum,
				},
			});
		});

		cols.push({
			label: 'Summe',
			path: 'sum',
			agg: {
				path: 'sum',
				aggType: AggregrationType.sum,
			},
		});

		tm.setCols(cols);
		tm.sortBy = 'ym';
		tm.idProperty = 'id';
		tm.idType = 'string';
		return tm;
	}, [adminUsageUiStore.filteredGsIds]);

	useEffect(() => {
		tmAgtBpGs.setRowData(adminUsageUiStore.agtBpByGs);
	}, [adminUsageUiStore.agtBpByGs, tmAgtBpGs]);

	return (
		<>
			<div className="pad-1rem">
				<Widget title="Vollständige Branchenplanungen je GS" modifier="is-paddingless" action={<span className="tag is-green">DVS Datenbank</span>}>
					<Table tm={tmAgtBpGs} />
				</Widget>
			</div>
		</>
	);
});
