import React from 'react';
import BaseContainer from 'app/containers/BaseContainer';
import { observer } from 'mobx-react';
import { MainMenu } from './MainMenu';
import { RouteComponentProps } from 'react-router-dom';

const VerlinkungenContainer = observer((props: RouteComponentProps) => {
	const links = [
		{
			title: 'Erfassungskriterien',
			url: 'https://amisonline.allianz.de/agentur-und-vertrieb/organisation-und-finanzen/finanzen/geschaeftsplaene.html',
		},
		{
			title: 'Primavista',
			url: 'https://primavista.allianz-people.de/login?ReturnUrl=%2F',
		},
		{
			title: 'ProGress',
			url: 'https://avc-portal.srv.allianz/Citrix/SmartCardWeb/',
		},
	];

	return (
		<BaseContainer sideMenu={<MainMenu {...props} />} navbar={<div>Verlinkungen</div>}>
			<section className="section">
				<div className="content">
					<h4>Nützliche Verlinkungen</h4>
					<p>
						Über diese Links gelangen Sie direkt zur jeweiligen Anmeldeseite. <br />
						Progress und Erfassungskriterien funktionieren nur im Allianz-Netz.
					</p>

					<ul className="links-list">
						{links.map((link, i) => {
							return (
								<li key={i}>
									<a href={link.url} title={link.title} target="_blank" rel="noreferrer">
										{link.title}
									</a>
								</li>
							);
						})}
					</ul>
				</div>
			</section>
		</BaseContainer>
	);
});

export default VerlinkungenContainer;
