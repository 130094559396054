import { ApModel } from 'app/models/documents/ap.model';
import { DocumentVM } from 'app/stores/ui/document.ui.store';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Button } from '../common/Button';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import FormField from '../form/FormField';
import { useStore } from 'app/context';
import { runInAction } from 'mobx';

interface IApJahresZielForm {
	doc: DocumentVM;
}

const yupNum = Yup.number().typeError('Nur Zahleneingaben sind erlaubt').required('Eingabe erforderlich').default(0);

const apCalcFreeSchema = Yup.object({
	gesamtbewertung: yupNum,
}).required();

type CalcFree = Yup.InferType<typeof apCalcFreeSchema>;

export const ApJahresZielFreeForm = observer((props: IApJahresZielForm) => {
	const { uiStore, documentUiStore } = useStore();
	const ap = props.doc.doc.content as ApModel;
	const [baq, setBaq] = useState(ap.baq);

	const onCancel = () => {
		uiStore.hideModal(uiStore.modalIds.apEditJahresZiel);
	};

	const handleSubmit = async (values: CalcFree) => {
		runInAction(() => {
			ap.commissionCalcContent.enableCalc = false;
			ap.setGesamtbewertung(values.gesamtbewertung);
		});
		// baq will be computed by autorun;
		await documentUiStore.save(props.doc);
		uiStore.hideModal(uiStore.modalIds.apEditJahresZiel);
	};

	return (
		<>
			<Formik
				validationSchema={apCalcFreeSchema}
				initialValues={{
					gesamtbewertung: ap.gesamtbewertung,
				}}
				onSubmit={handleSubmit}
			>
				{({ errors, touched, values, isValid, handleChange }) => {
					useEffect(() => {
						setBaq(ap.calcBac(ap.commissionCalcContent.bestand, values.gesamtbewertung));
					}, [values.gesamtbewertung]);
					return (
						<Form>
							<div className="grid">
								<FormField className="is-inline" error={errors.gesamtbewertung} touched={touched.gesamtbewertung} label="Erforderliche Gesamtbewertung in €">
									<Field type="text" name="gesamtbewertung" placeholder="Gesamtbewertung" />
								</FormField>
								<FormField className="is-inline" label="BAQ in %">
									{baq}
								</FormField>
							</div>
							<div className="modal-footer">
								<Button type="button" className="button is-secondary" onClick={onCancel}>
									Abbrechen
								</Button>
								<Button type="submit" className="button is-primary ">
									Speichern
								</Button>
							</div>
						</Form>
					);
				}}
			</Formik>
		</>
	);
});
