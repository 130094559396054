import { BaseCachedEntityStore } from './core/base.cached.entity.store';
import { CoronaKVModel } from 'app/models/corona/corona.kv.model';

export class KvCoronaCheckStore extends BaseCachedEntityStore<CoronaKVModel> {
	apiPath = 'api/coronatodo';
	idProp: string = 'id';
	createInstances(opts: any): CoronaKVModel {
		return new CoronaKVModel(opts);
	}
}
