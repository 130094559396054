const values = [
	{
		key: 'betriebswirtschaftlichegesamtsituation',
		title: 'Betriebswirtschaftliche Gesamtsituation',
		children: [
			{
				key: 'ueberpruefungausgabensituation',
				title: 'Überprüfung der Ausgabensituation',
			},
			{
				key: 'ueberpruefungeinnahmensituation',
				title: 'Überprüfung der Einnahmensituation',
			},
			{
				key: 'neugeschaeft',
				title: 'Neugeschäft',
			},
			{
				key: 'geschaeftsplanincentivesvertriebsschwerpunkte',
				title: 'Geschäftsplan, Incentives, Vertriebsschwerpunkte',
			},
			{
				key: 'provisionsstufungen',
				title: 'Provisionsstufungen',
			},
		],
	},
	{
		key: 'kundenzufriedenheit',
		title: 'Kundenzufriedenheit (NPS)',
		children: [
			{
				key: 'steigerungkundenkontaktquote',
				title: 'Steigerung Kundenkontaktquote',
			},
			{
				key: 'ableitungenkundenzufriedenheitsreport',
				title: 'Ableitungen aus Kundenzufriedenheitsreport',
			},
			{
				key: 'festlegenstandardskundenkontakt',
				title: 'Festlegen von Standards im Kundenkontakt',
			},
		],
	},
	{
		key: 'personalfuehrung',
		title: 'Personalführung',
		children: [
			{
				key: 'personalfuehrungsseminar',
				title: 'Personalführungsseminar',
			},
			{
				key: 'regelmässigeraustauschmitvbl',
				title: 'Regelmäßiger Austausch mit dem VBL',
			},
		],
	},
	{
		key: 'personalkapazitaet',
		title: 'Personalkapazität',
		children: [
			{
				key: 'bedarfueberpruefen',
				title: 'Bedarf überprüfen',
			},
			{
				key: 'bedarffestlegen',
				title: 'Bedarf festlegen',
			},
			{
				key: 'vorgehensweisepersonalsuche',
				title: 'Vorgehensweise bei Personalsuche',
			},
		],
	},
	{
		key: 'qualifikationweiterbildung',
		title: 'Qualifikation/Weiterbildung',
		children: [
			{
				key: 'aufstellenseminarplans',
				title: 'Aufstellen eines Seminarplans (AAA)',
			},
			{
				key: 'sicherstellungweiterbildungsmassnahmen',
				title: 'Sicherstellung Weiterbildungsmaßnahmen durch IDD',
			},
			{
				key: 'formulierungzielbildes',
				title: 'Formulierung eines Zielbildes',
			},
			{
				key: 'weiterbildungzweitverkaeufer',
				title: 'Weiterbildung Zweitverkäufer, Bürokraft',
			},
			{
				key: 'agenturformat',
				title: 'Agenturformat (Fach: BauFi, Firmen, Kranken,...)',
			},
			{
				key: 'elearning',
				title: 'eLearning',
			},
		],
	},
	{
		key: 'schwerpunktagentur',
		title: 'Schwerpunktagentur',
		children: [
			{
				key: 'festlegungerfüllungskriterien',
				title: 'Festlegung Erfüllungskriterien',
			},
			{
				key: 'fahrplanmitzuständigenspezialistenerstellen',
				title: 'Fahrplan mit zuständigen Spezialisten erstellen',
			},
		],
	},
	{
		key: 'bestandsanalyse',
		title: 'Bestandsanalyse',
		children: [
			{
				key: 'vertragsdichte',
				title: 'Vertragsdichte',
			},
			{
				key: 'vorgehensweiesebieeinzweivertragskunden',
				title: 'Vorgensweise bei Ein- und Zweivertragskunden',
			},
			{
				key: 'identifizierungkundenzielgruppen',
				title: 'Identifizierung Kundenzielgruppen',
			},
			{
				key: 'stornoquote',
				title: 'Stornoquote',
			},
		],
	},
	{
		key: 'workwell',
		title: 'Work Well',
		children: [
			{
				key: 'inboundoutbound',
				title: 'Inbound/Outbound',
			},
			{
				key: 'uebpepruefungagenturprozess',
				title: 'Überprüfung der Agenturprozesse',
			},
			{
				key: 'ueberblickarbeitszeitvsfreitzeit',
				title: 'Überblick Arbeitszeit vs. Freizeit',
			},
		],
	},
	{
		key: 'zusammenarbeitmitgs',
		title: 'Zusammenarbeit mit GS',
		children: [
			{
				key: 'gsl',
				title: 'GSL',
			},
			{
				key: 'vbl',
				title: 'VBL',
			},
			{
				key: 'buel',
				title: 'BÜL',
			},
			{
				key: 'edvvb',
				title: 'EDVVB',
			},
			{
				key: 'sachbearbeiter',
				title: 'Sachbearbeiter',
			},
		],
	},
];
export default values;
