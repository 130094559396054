import BaseContainer from 'app/containers/BaseContainer';
import { observer } from 'mobx-react';
import { MainMenu } from './MainMenu';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useStore } from 'app/context';
import { AdminUserLoginTrackingList } from 'app/components/admin/users/UserLoginTrackingList';

export interface AdminBnrDiffParamTypes {
    bnrId?: string;
}

const AdminUserLoginContainer = observer((props: RouteComponentProps) => {
    const { session, adminUserLoginUiStore } = useStore();
    const { bnrId } = useParams<AdminBnrDiffParamTypes>();



    const currentUser = session.currentUser;

    const AdminUsersNavbar = observer(() => {
        return (
            <div className="admin-navbar">
                <div className="is-left">
                    <strong>Login Tracking</strong>
                </div>
                <div className="is-right"></div>
            </div>
        );
    });

    return (
        <BaseContainer classModifier="admin-container admin-users-container" sideMenu={<MainMenu {...props} />} navbar={<AdminUsersNavbar />}>
            {currentUser && (
                <>
                    <div className="table-container">
                        <AdminUserLoginTrackingList />
                    </div>
                </>
            )}
        </BaseContainer>
    );
});

export default AdminUserLoginContainer;
