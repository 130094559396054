export interface ICoronaFormItem {
	groupId: string;
	key: string;
	label: string;
	details?: string;
	editable?: boolean;
	support: string;
	pro3Paket2?: boolean;
	visible?: boolean;
	actionButton?: any;
}

export interface ICoronaFormGroup {
	groupId: string;
	name: string;
	visible?: boolean;
}

export const CoronaFormGroups: ICoronaFormGroup[] = [
	{
		name: 'Erreichbarkeit',
		groupId: 'erreichbarkeit',
	},
	{
		name: 'Kundenkontakt und Beratung',
		groupId: 'kontaktundberatung',
	},
	{
		name: 'Abläufe in der Agentur',
		groupId: 'ablauefeinderagentur',
	},
];

export const CoronaFormItems: ICoronaFormItem[] = [
	{
		groupId: 'erreichbarkeit',
		key: 'ivr_vorhanden',
		label: 'IVR vorhanden?',
		details: 'Rufumleitung ist zu Inbound geschaltet?',
		support: 'VBL',
	},
	{
		groupId: 'erreichbarkeit',
		key: 'ivr_nicht_vorhanden',
		label: 'IVR Nicht vorhanden?',
		details: 'Rufumleitung auf anderen Anschluss (Mobiltelefon, etc.) geschaltet?',
		support: 'VBL',
	},
	{
		groupId: 'erreichbarkeit',
		key: 'ivr_nicht_vorhanden_pro3',
		label: 'IVR Nicht vorhanden? (Pro3)',
		details: 'Kurzfristig bestellen!',
		support: 'VBL',
		pro3Paket2: true,
	},
	{
		groupId: 'erreichbarkeit',
		key: 'amisnow',
		label: 'Nutzung und Bearbeitung AMIS.Now sicher gestellt?',
		details: '',
		support: 'EDV-B',
	},
	{
		groupId: 'erreichbarkeit',
		key: 'whatsapp',
		label: 'WhatsApp-Status mit Informationen zur Erreichbarkeit?',
		details: '',
		support: 'DIGI-BA, webDSE',
	},
	{
		groupId: 'erreichbarkeit',
		key: 'fb',
		label: 'FB- und Instagram-Posts mit Informationen zur Erreichbarkeit (telefonisch, Mail, social media, etc.)?',
		details: '',
		support: 'DIGI-BA, webDSE',
	},
	{
		groupId: 'erreichbarkeit',
		key: 'zustaendigkeiten',
		label: 'Zuständigkeit festlegen für regelmäßige Aktualisierung VT-HP, FB-Seite und Instagram?',
		details: '',
		support: '',
	},
	{
		groupId: 'erreichbarkeit',
		key: 'inserate_lokalzeitungen',
		label: 'Inserate in Lokalzeitungen?',
		details: '',
		support: 'Marketing',
	},
	{
		groupId: 'erreichbarkeit',
		key: 'dienstplan_besetzung',
		label: 'Dienstplan für Agentur-/Telefonbesetzung erstellt?',
		details: '',
		support: '',
	},
	{
		groupId: 'erreichbarkeit',
		key: 'erreichbarkeit_aussenwerbung',
		label: 'Erreichbarkeit über Außenwerbung sichergestellt?',
		details: 'Plakate, digitales Schaufenster..',
		support: 'Marketing',
	},

	{
		groupId: 'kontaktundberatung',
		key: 'gtm',
		label: 'GTM Lizenz vorhanden?',
		details: 'Falls nein: bestellen',
		support: 'EDV-B',
	},
	{
		groupId: 'kontaktundberatung',
		key: 'esign',
		label: 'Befähigt zur Nutzung von eSign?',
		details: '',
		support: 'EDV-B, Digi-BA, VBL',
	},
	{
		groupId: 'kontaktundberatung',
		key: 'gtm_webinar_angemeldet',
		label: 'Angemeldet zum Webinar „Basics zum Einsatz von GtM in der Onlineberatung“?',
		details: '',
		support: 'AAA',
	},
	{
		groupId: 'kontaktundberatung',
		key: 'gtm_webinar_teilgenommen',
		label: 'Teilgenommen am Webinar „Basics zum Einsatz von GtM in der Onlineberatung“?',
		details: '',
		support: 'AAA',
	},
	{
		groupId: 'kontaktundberatung',
		key: 'onlineberatung_webinar_angemeldet',
		label: 'Angemeldet zum Webinar „1x1 der Onlineberatung – Digital erfolgreich verkaufen “?',
		details: '',
		support: 'AAA',
	},
	{
		groupId: 'kontaktundberatung',
		key: 'onlineberatung_webinar_teilgenommen',
		label: 'Teilgenommen am Webinar „1x1 der Onlineberatung – Digital erfolgreich verkaufen “?',
		details: '',
		support: 'AAA',
	},
	{
		groupId: 'kontaktundberatung',
		key: 'aaa_gs_vertiefung_angemeldet',
		label: 'Angemeldet zu Vertiefungsangeboten der AAA und GS?',
		details: '',
		support: 'AAA, GS',
	},
	{
		groupId: 'kontaktundberatung',
		key: 'aaa_gs_vertiefung_teilgenommen',
		label: 'Teilgenommen an Vertiefungsangeboten der AAA und GS?',
		details: '',
		support: 'AAA, GS',
	},
	{
		groupId: 'kontaktundberatung',
		key: 'teilnahme_webinare_dse',
		label: 'Teilnahme an Webinaren der DSE zu Vertriebsansätzen?',
		details: '',
		support: 'DSE',
	},
	{
		groupId: 'kontaktundberatung',
		key: 'schadenbearbeitung_sichergestellt',
		label: 'Schadenbearbeitung sichergestellt?',
		details: 'inkl. Nutzung Allianz ConnX besprochen',
		support: '',
	},

	{
		groupId: 'ablauefeinderagentur',
		key: 'checkin_via_gtm',
		label: 'Check-In Gespräche via GtM?',
		details: '',
		support: 'EDV-B (Technik)',
	},
	{
		groupId: 'ablauefeinderagentur',
		key: 'elpoko',
		label: 'Bearbeitung ELPOKO sichergestellt?',
		details: '',
		support: '',
	},
	{
		groupId: 'ablauefeinderagentur',
		key: 'serverabgleich_bei_agenturschliessung',
		label: 'Bei Agenturschließungen: 1-2x pro Woche Server-Abgleich der Laptops in der Agentur (jeweils nur eine Person in der Agentur) sichergestellt?',
		details: '',
		support: 'EDV-B (beratend)',
	},
	{
		groupId: 'ablauefeinderagentur',
		key: 'sicherstellung_postbearbeitung',
		label: 'Sicherstellung der Postbearbeitung in der Agentur?',
		details: '1-2x pro Woche',
		support: '',
	},
];
