import React, { useState } from 'react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import FormField from '../form/FormField';
import { useStore } from 'app/context';
import { Button } from '../common/Button';
import { Link } from 'react-router-dom';
import routes from './../../../routes';
import { useHistory } from 'react-router';
import { observer } from 'mobx-react';
import { notify } from '../common/notify';
import { Icon } from '../common/Icon';

const benMsg = 'BENSL ist erforderlich';
const loginFormSchema = Yup.object({
	bensl: Yup.string()
		.required(benMsg)
		.max(11, benMsg)
		.matches(/^[a-zA-Z0-9]+$/, benMsg)
		.default(''),
	password: Yup.string().required('Passwort ist erforderlich').default(''),
}).required();

type Login = Yup.InferType<typeof loginFormSchema>;

export const LoginForm: React.FC = observer(() => {
	const { authStore, session } = useStore();
	const history = useHistory();
	const { trackerStore } = useStore();
	const [showPassword, setShowPassword] = useState(false);

	async function handleSubmit(values: Login) {
		const res = await authStore.login({ username: values.bensl, password: values.password });
		if (res.status === 200 && res.data && res.data.pinRequired) {
			history.push(routes.VERIFYPIN.getPath() + '?bensl=' + values.bensl);
			return;
		}
		if (session.isLoggedIn) {
			if (session.isAdmin) {
				history.push(routes.ADMINHOME.getPath());
			} else {
				if (session.currentUser) {
					trackerStore.trackEvent('Login', 'VD', session.currentUser?.vdList[0] || '-');
					trackerStore.trackEvent('Login', 'GS', session.currentUser?.gsList[0] || '-');
				}
				history.push(routes.HOME.getPath());
			}
		} else {
			notify('Anmeldung fehlgeschlagen', '', 'error');
		}
	}

	const toggleRevealPassword = () => {
		setShowPassword(!showPassword);
	};

	return (
		<Formik
			validationSchema={loginFormSchema}
			initialValues={{
				bensl: '',
				password: '',
				asBensl: '',
			}}
			onSubmit={handleSubmit}
		>
			{({ errors, touched, isValid }) => {
				return (
					<Form className="auth-form">
						<div className="auth-form-header">
							<div className="title">DVS</div>
						</div>

						<div>
							<FormField error={errors.bensl} touched={touched.bensl} label="BENSL">
								<Field type="text" name="bensl" autoComplete="username" placeholder="Benutzername (BENSL)" autoFocus={true} className="pw-username" />
							</FormField>
							<FormField error={errors.password} touched={touched.password} label="Passwort">
								<Field type={showPassword ? 'text' : 'password'} name="password" autoComplete="password" placeholder="Passwort" className="pw-password" />
								<Button className="button is-blank reveal-pw-button" onClick={toggleRevealPassword}>
									{showPassword ? <Icon iconClass="eye-slash" /> : <Icon iconClass="eye" />}
								</Button>
							</FormField>
							<div className="text-right">
								<Link to={routes.FORGOTPASSWORD.getPath()} className="pw-pw-reset">
									Passwort vergessen?
								</Link>
							</div>
						</div>
						<div>
							<Button type="submit" className="button is-primary is-block pw-submit" isFormInvalid={!isValid}>
								Anmelden
							</Button>
							<Link className="button is-block pw-register" to={routes.REGISTER.getPath()}>
								Erstanmeldung
							</Link>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
});
