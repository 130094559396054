import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { TreeNode } from '../common/TreeNode';

export const AnwendungenTreeMenu = observer(() => {
	const { anwendungenUiStore } = useStore();
	const tree = anwendungenUiStore.menu;

	return (
		<div className="tree-nodes-container">
			<TreeNode nodes={tree.nodes} treeStore={anwendungenUiStore} />
		</div>
	);
});
