import { useStore } from 'app/context';
import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import routes from './routes';

export const AuthRoute: React.FC<RouteProps> = ({ children, ...rest }: RouteProps) => {
	const { session } = useStore();
	if (!session.isLoggedIn) {
		return (
			// @ts-ignore
			<Redirect
				to={{
					pathname: routes.LOGIN.getPath(),
					//state: { from: location }
				}}
			/>
		);
	}
	// @ts-ignore
	return <Route {...rest} />;
};
