import { AgtSchwerpunktEdit } from 'app/components/anwendungen/schwerpunkt/AgtSchwerpunktEdit';
import Widget, { IWidgetConsumer } from 'app/components/common/Widget';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import moment from 'moment';

export const AgtSchwerpunktWidget = observer((props: IWidgetConsumer) => {
	const { uiStore, agenturUiStore } = useStore();
	const agt = agenturUiStore.current;
	const currentYear = moment().year();
	return (
		<>
			{agt && (
				<Widget title="Schwerpunktagentur" edit={uiStore.schwerpunktEdit} widgetId={props.widgetKey}>
					<AgtSchwerpunktEdit agt={agt} year={currentYear} />
				</Widget>
			)}
		</>
	);
});
