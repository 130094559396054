import { Button } from 'app/components/common/Button';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router';
import routes from 'routes';

export const AdminNewAdminUserButton = observer(() => {
	const history = useHistory();
	const open = () => {
		history.push(routes.ADMINUSERS.getPath('123', 'admin'));
	};
	return (
		<>
			<Button type="button" className="button is-small" onClick={open}>
				Neuen DVS Admin anlegen
			</Button>
		</>
	);
});
