import _ from 'lodash';
import { action, computed, IObservableArray, makeObservable, observable, runInAction } from 'mobx';
import { NewsModel, NewsStore } from '../news.store';
import { NewsVM } from './news.ui.store';



export class AdminNewsUiStore {
    newsStore: NewsStore;

    constructor(newsStore: NewsStore) {
        makeObservable(this);
        this.newsStore = newsStore;
    }

    @observable current?: NewsVM;

    @computed
    get currentItems() {
        let items = this._items.map((i) => i);
        return _.sortBy(items, (r) => r.news.created).reverse();
    }

    _items: IObservableArray<NewsVM> = observable([]);
    loaded: boolean = false;
    async load() {
        if (this.loaded) {
            return this._items;
        }

        const items = await this.newsStore.findAll();

        const res: NewsVM[] = [];

        items.forEach((t) => {
            const vm = new NewsVM(t);
            res.push(vm);
        });
        runInAction(() => {
            this._items.replace(res);
            this.loaded = true;
        });

        return this._items;
    }

    @action
    async newNews(opts: any) {
        const t = new NewsModel(opts);
        const t2 = await this.newsStore.save(t);
        const vm = new NewsVM(t2);
        this._items.push(vm);
        return vm;
    }

    @action
    async loadById(id?: string) {
        if (!id) {
            this.current = undefined;
        }
        const newsss = await this.findAll();
        const u = newsss.find((u) => u.news.id === id);
        runInAction(() => {
            this.current = u;
        });
    }

    @action
    async save(vm: NewsVM) {
        await this.newsStore.save(vm.news);
        this.reload();
    }

    @action
    async reload() {
        this.loaded = false;
        await this.load();
    }

    async findAll() {
        return this.load();
    }


}