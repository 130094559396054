import { KvHistoryEntry } from 'app/stores/kv.agentur.year.errungenschaften.store';
import { action, computed, makeObservable, observable } from 'mobx';
import { Steart } from '../core/steart.model';
import moment from 'moment';

export enum ErrungenschaftState {
	off = 'off',
	silver = 'silver',
	bronze = 'bronze',
	gold = 'gold', // Aka YES
}

export enum ErrungenschaftType {
	boolean = 'boolean',
	gsb = 'gsb',
}

export interface IErrungenschaftModal {
	title: string;
	description: string;
}
export interface IErrungenschaft {
	key: string;
	// title: string;
	component: (m: ErrungenschaftModel) => JSX.Element;
	valType: ErrungenschaftType;
	gsbLevels?: number[];
	visibleForRoles?: Steart[];
	// description: string;
	pathToReport?: string;
	modal: IErrungenschaftModal;
}

export class ErrungenschaftSummaryModel {

	constructor(errungenschaft: IErrungenschaft) {
		this.errungenschaft = errungenschaft;
	}

	errungenschaft: IErrungenschaft;
	count: number = 0;
	countCurrentKw = 0;
}

export class ErrungenschaftModel {
	constructor(opts: IErrungenschaft) {
		makeObservable(this);
		this.key = opts.key;
		this._component = opts.component;
		this.valType = opts.valType;
		this.gsbLevels = opts.gsbLevels ? opts.gsbLevels : [];
		this.pathToReport = opts.pathToReport;
		this.modal = opts.modal;
		this.visibleForRoles = opts.visibleForRoles;
	}
	key: string;
	valType: ErrungenschaftType;
	gsbLevels: number[];
	_component: (m: ErrungenschaftModel) => JSX.Element;
	pathToReport?: string;
	modal: IErrungenschaftModal;
	visibleForRoles?: Steart[];

	canSee(steart: number) {
		if (!this.visibleForRoles) {
			return true;
		}
		return this.visibleForRoles.includes(steart);
	}

	@observable
	boolVal: boolean = false;

	@observable
	gsbVal: number = 0;

	@observable
	history?: KvHistoryEntry[];

	@observable
	modified?: Date;

	@computed
	get lastModifiedKw() {
		if (this.modified) {
			return moment(this.modified).format('yyyyww');
		}
		return 0

	}



	@observable
	currentKw: string = moment().format('yyyyww');
	// currentKw: string = '202411';

	@computed
	get isNewInCurrentKw() {

		const currKwState = this.getStateForKw(this.currentKw);
		if (currKwState && currKwState !== null && currKwState !== 'off' && this.state !== 'off') {
			return true;
		}
		return false;

	}

	getStateForKw(kw: string) {
		if (this.lastModifiedKw == kw) {
			return this.state;
		}
		if (!this.history) {
			return;
		}
		const h = this.history.find(h => h.kw === kw);
		return h?.valueString;
	}

	@computed
	get component() {
		return this._component(this);
	}

	@observable
	state: ErrungenschaftState = ErrungenschaftState.off;


	@computed
	get stateForSort() {
		let res = 0;
		switch (this.state) {
			case ErrungenschaftState.gold:
				res = 9;
				break;
			case ErrungenschaftState.silver:
				res = 8;
				break;
			case ErrungenschaftState.bronze:
				res = 7;
				break;
			case ErrungenschaftState.off:
				res = 6;
				break;
			default:
				res = 0;
				break;

		}
		if (this.isNewInCurrentKw) {
			res += 10;
		}
		return res;
	}

	@action
	setState(state: ErrungenschaftState) {
		this.state = state;
	}

	@computed
	get stateAsNumber() {
		switch (this.state) {
			case ErrungenschaftState.gold:
				return 3;
			case ErrungenschaftState.silver:
				return 2;
			case ErrungenschaftState.bronze:
				return 1;
			default:
				return 0;
		}
	}

	@action
	setStateBool(yes: boolean) {
		this.boolVal = yes;
		if (yes) {
			this.state = ErrungenschaftState.gold;
		} else {
			this.state = ErrungenschaftState.off;
		}
	}
	@action
	setStateGSB(val: number) {
		this.gsbVal = val;
		if (!this.gsbLevels || this.gsbLevels.length === 0) {
			throw new Error('No gsbLevels defined for ' + this.key);
		}
		if (val >= this.gsbLevels[0]) {
			this.setState(ErrungenschaftState.gold);
		} else if (val >= this.gsbLevels[1]) {
			this.setState(ErrungenschaftState.silver);
		} else if (val >= this.gsbLevels[2]) {
			this.setState(ErrungenschaftState.bronze);
		} else {
			this.setState(ErrungenschaftState.off);
		}
	}
	@action
	setStateFromString(s: string) {
		switch (s) {
			case 'gold':
				this.setState(ErrungenschaftState.gold);
				break;
			case 'silver':
				this.setState(ErrungenschaftState.silver);
				break
			case 'bronze':
				this.setState(ErrungenschaftState.bronze);
				break;
			default:
				this.setState(ErrungenschaftState.off);

		}
	}
}
