import { BrandenBurgerTorData, ThemaModel, ThemenGroupModel } from 'app/models/brandenburger-tor/brandeburger.tor.data';
import _ from 'lodash';
import { action, computed, makeObservable, observable } from 'mobx';
import { AgtPlanThemaModel } from '../agt.plan.store';
import { TaskStatus } from '../bnr.task.store';
import { SessionStore } from '../session.store';
import { AgtPlanUiStore, AgtPlanYearVM } from './agt.plan.ui.store';
import { TaskUiStore, TaskVM } from './task.ui.store';

export class AgtPlanThemaVM {
	constructor(thema: AgtPlanThemaModel, planTasks: TaskVM[]) {
		this.thema = thema;
		this.planTasks = planTasks;
	}

	thema: AgtPlanThemaModel;
	@observable
	planTasks: TaskVM[];

	@computed
	get tasks() {
		return this.planTasks.filter((t) => t.task.thema && t.task.thema.key === this.thema.key);
	}

	@computed
	get tasksCompleted() {
		return this.tasks.filter((t) => t.task.status === TaskStatus.CLOSED);
	}
}

export class PlanThemenTreeVM {
	plan: AgtPlanYearVM;
	constructor(plan: AgtPlanYearVM) {
		makeObservable(this);
		this.plan = plan;
		const year = plan.year;
		const items = BrandenBurgerTorData.filter((i) => i.year === year);
		this.themenGroups = items.map((i) => new ThemenGroupModel(i));
		const eigeneThemenGroup = new ThemenGroupModel({
			key: 'eigene',
			title: 'Eigene Themen',
			children: [],
			year,
		});
		this.themenGroups.push(eigeneThemenGroup);
		plan.plan.themen.themen.forEach((thema) => {
			let found = false;
			this.themenGroups.forEach((g) => {
				const t = g.children.find((i) => i.key === thema.key);
				if (t) {
					t.selected = true;
					found = true;
				}
			});
			if (!found) {
				const eigenesThema = new ThemaModel({
					key: thema.key,
					title: thema.title,
					children: [], // keine handlungsempfehlnge
				});
				eigenesThema.selected = true;
				eigenesThema.isEigenesThema = true;
				eigeneThemenGroup.children.push(eigenesThema);
			}
		});
	}

	themenGroups: ThemenGroupModel[];

	@observable
	currentGroup?: ThemenGroupModel;
	@action
	setCurrentGroup(themenGroup?: ThemenGroupModel) {
		this.currentGroup = themenGroup;
		this.currentThema = undefined;
	}
	@computed
	get currentGroupKey() {
		if (this.currentGroup) {
			return this.currentGroup.key;
		}
		return undefined;
	}

	@computed
	get currentThemen() {
		if (!this.currentGroup) {
			return [];
		}
		return this.currentGroup.children;
	}

	@observable
	currentThema?: ThemaModel;
	@action
	setCurrentThema(currentThema?: ThemaModel) {
		this.currentThema = currentThema;
	}
	@computed
	get currentThemaKey() {
		if (this.currentThema) {
			return this.currentThema.key;
		}
		return undefined;
	}

	@computed
	get currentEmpfehlungen() {
		if (!this.currentThema) {
			return [];
		}
		return this.currentThema.children;
	}
}

export class AgtPlanThemenUiStore {
	session: SessionStore;
	agtPlanUiStore: AgtPlanUiStore;
	tasksUiStore: TaskUiStore;

	constructor(session: SessionStore, agtPlanUiStore: AgtPlanUiStore, tasksUiStore: TaskUiStore) {
		makeObservable(this);
		this.session = session;
		this.agtPlanUiStore = agtPlanUiStore;
		this.tasksUiStore = tasksUiStore;
	}

	@computed
	get themen() {
		const plan = this.agtPlanUiStore.current;
		if (!plan) {
			return [];
		}
		const themen = plan.plan.themen;
		const tasks = this.planTasks;
		return themen.themen.map((thema) => new AgtPlanThemaVM(thema, tasks));
	}

	@computed
	get planTasks() {
		const plan = this.agtPlanUiStore.current;
		let tasks = this.tasksUiStore._items;
		if (!plan || !tasks) {
			return [];
		}
		return tasks.filter((t) => t.agtId === plan.agtId && (t.planYear === plan.year || t.isOpen));
	}

	@action
	initTree() {
		if (this.agtPlanUiStore.current) {
			this.themenTree = new PlanThemenTreeVM(this.agtPlanUiStore.current);
			this.themenTreeCreated = new Date();
			this.treeSearchText = '';
		}
	}

	@observable
	treeSearchText: string = '';
	@action
	setTreeSearchText(s: string) {
		this.treeSearchText = s;
		this.themenTree!.themenGroups.forEach((g) => {
			g.search(s);
			g.children.forEach((t) => {
				t.search(s);
				if (t.highlight) {
					g.highlight = true;
				}
				t.children.forEach((e) => {
					e.search(s);
					if (e.highlight) {
						g.highlight = true;
						t.highlight = true;
					}
				});
			});
		});
	}

	@computed
	get treeTasks() {
		// only tasks with themen
		let tasks = this.planTasks.filter((t) => t.task.themaKey);
		tasks = _.sortBy(tasks, 'task.created').reverse();
		return tasks;
	}

	@computed
	get treeTasksFiltered() {
		// only tasks with themen
		let tasks = this.planTasks.filter((t) => t.task.themaKey);
		if (this.treeSearchText.length > 0) {
			const search = this.treeSearchText.toLowerCase();
			tasks = tasks.filter((t) => t.fullText.indexOf(search) > 0);
		}
		tasks = _.sortBy(tasks, 'task.created').reverse();
		return tasks;
	}

	@computed
	get newTreeTasks() {
		if (!this.themenTreeCreated) {
			return [];
		}
		return this.treeTasks.filter((t) => t.task.created.getTime() > this.themenTreeCreated!.getTime());
	}

	@observable
	themenTree?: PlanThemenTreeVM;
	@observable
	themenTreeCreated?: Date;

	@computed
	get currentTreeThemen() {
		const tree = this.themenTree;
		if (!tree) {
			return [];
		}
		const themen: AgtPlanThemaModel[] = [];
		tree.themenGroups.forEach((g) => {
			g.children.forEach((thema) => {
				if (thema.selected) {
					const t = new AgtPlanThemaModel({
						title: thema.title,
						key: thema.key,
					});
					themen.push(t);
				}
			});
		});
		return themen;
	}
	@computed
	get currentPlanThemen() {
		if (this.agtPlanUiStore.current) {
			const plan = this.agtPlanUiStore.current.plan;
			return plan.themen.themen;
		}
		return [];
	}

	@computed
	get keysChanged() {
		const tree = this.themenTree;
		if (!tree) {
			return false;
		}
		const treeThemen = this.currentTreeThemen;
		const planThemen = this.currentPlanThemen;

		const newKeys = treeThemen.map((t) => t.key);
		const currentPlanKeys = planThemen.map((t) => t.key);

		let keysChanged = true;
		if (_.isEqual(newKeys.sort(), currentPlanKeys.sort())) {
			keysChanged = false;
		}
		return keysChanged;
	}

	@action
	async saveThemen() {
		if (!this.agtPlanUiStore.current) {
			return;
		}
		const newThemen = this.currentTreeThemen;
		const plan = this.agtPlanUiStore.current.plan;
		plan.themen.themen = newThemen;
		const s = await this.agtPlanUiStore.agtPlanStore.save(plan);
		this.agtPlanUiStore.current.plan = s;
	}

	@action
	async cancelThemenSave() {
		for (const task of this.newTreeTasks) {
			await this.tasksUiStore.deleteById(task.id);
		}
	}
}
