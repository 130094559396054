import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { WochenmailAgtErrungenschaften } from './WochenmailAgtErrungenschaften';
import { WochenmailBirthdays } from './WochenmailBirthdays';
import { WochenmailBnrErrungenschaften } from './WochenmailBnrErrungenschaften';
import { WochenmailExpertisen } from './WochenmailExpertise';
import { WochenmailJubel } from './WochenmailJubel';
import { WochenmailSharedDocs } from './WochenmailSharedDocs';
import { WochenmailTaskStats } from './WochenmailTasksStats';
import { WochenmailNews } from './WochenmailNews';

interface IWMHeading {
	title: string;
}
export const WMHeading: React.FC<IWMHeading> = (props: IWMHeading) => {
	const divider = Array(props.title.length).fill('-').join('');

	return (
		<>
			{divider} <br />
			{props.title} <br />
			{divider} <br />
			<br />
		</>
	);
};

interface IPlainTextWochenmail {
	displayKw: string;
}
export const PlainTextWochenmail = observer((props: IPlainTextWochenmail) => {
	const { wochenmailtUiStore } = useStore();

	return (
		<>
			=================================== <br />
			DVS Wochenbericht: KW {props.displayKw} <br />
			=================================== <br />
			<br />
			<br />
			<WochenmailNews isText={true} />
			<WochenmailTaskStats isText={true} />
			<WochenmailSharedDocs isText={true} />
			<WochenmailBnrErrungenschaften isText={true} />
			<WochenmailAgtErrungenschaften isText={true} />
			{wochenmailtUiStore.isVblOrLeiter && (
				<>
					<WochenmailExpertisen isText={true} />
					<WochenmailBirthdays isText={true} />
					<WochenmailJubel isText={true} />
				</>
			)}
			<br />
			<br />
			Mehr in der DVS APP unter https://dvs-app.de <br />
			<br />
			<br />
			<br />
			<br />
			Falls Sie diese E-Mail in Zukunft nicht mehr erhalten möchten, können Sie die in den Einstellungen Ihrer DVS abbestellen. <br />
		</>
	);
});
