import _ from 'lodash';
import { action, computed, IObservableArray, makeObservable, observable, runInAction } from 'mobx';
import { BnrDocumentModel, BnrDocumentStore } from '../bnr.document.store';
import { BnrUploadModel, BnrUploadStore } from '../bnr.upload.store';
import { SessionStore } from '../session.store';

interface INotizVM {
	currentBnrId: number;
	doc: BnrDocumentModel;
	uploads: BnrUploadModel[];
}

export class NotizVM {
	constructor(opts: INotizVM) {
		makeObservable(this);
		this.currentBnrId = opts.currentBnrId;
		this.doc = opts.doc;
		this.uploads = opts.uploads;
	}

	@observable
	isNew: boolean = false;

	currentBnrId: number;
	@observable
	doc: BnrDocumentModel;
	@observable
	uploads: BnrUploadModel[] = [];

	@computed
	get id() {
		return this.doc.id;
	}
}

export class NotizenUiStore {
	bnrDocumentStore: BnrDocumentStore;
	session: SessionStore;
	bnrUploadStore: BnrUploadStore;
	constructor(session: SessionStore, bnrDocumentStore: BnrDocumentStore, bnrUploadStore: BnrUploadStore) {
		makeObservable(this);
		this.session = session;
		this.bnrDocumentStore = bnrDocumentStore;
		this.bnrUploadStore = bnrUploadStore;
	}

	loaded: boolean = false;

	@observable
	currentItems: IObservableArray<NotizVM> = observable([]);

	@computed
	get realItemsForFuckSakes() {
		return this.currentItems;
	}

	@computed
	get last5() {
		const items = _.sortBy(this.currentItems, (i) => i.doc.modified).reverse();
		return items.slice(0, 4);
	}

	@action
	async load() {
		if (this.loaded) {
			return this.currentItems;
		}
		// const docs = await this.bnrDocumentStore.findAll();
		const fetch = await Promise.all([this.bnrDocumentStore.findAll(), this.bnrUploadStore.findAll()]);

		let res: NotizVM[] = [];
		const docs = fetch[0];
		const uploads = fetch[1];

		docs.forEach((d) => {
			if (d.category !== 'user_notiz') {
				return;
			}
			const docUploads = uploads.filter((u) => u.documentId === d.id);
			const vm = new NotizVM({
				currentBnrId: this.session.currentUser!.bnrId,
				doc: d,
				uploads: docUploads,
			});

			res.push(vm);
		});
		runInAction(() => {
			res = _.sortBy(res, (i) => i.doc.modified).reverse();
			this.currentItems.replace(res);
			this.loaded = true;
		});

		return res;
	}

	async findAll() {
		return this.load();
	}

	@action
	async refresh() {
		this.loaded = false;
		this.bnrUploadStore.loaded = false;
		await this.load();
	}

	async findById(docId: string) {
		const items = await this.load();
		return items.find((d) => d.id === docId);
	}

	@action
	async addNotiz() {
		const doc = new BnrDocumentModel({
			id: undefined,
			bnrId: this.session.currentUser!.bnrId,
			category: 'user_notiz',
			created: new Date(),
			modified: new Date(),
			editable: true,
			title: 'Notiz',
			status: 0,
			text: '',
		});

		const vm = new NotizVM({
			currentBnrId: this.session.currentUser!.bnrId,
			doc,
			uploads: [],
		});
		vm.isNew = true;
		vm.doc = await this.bnrDocumentStore.save(vm.doc);
		runInAction(() => {
			vm.doc.editable = true;
		});

		this.currentItems.push(vm);

		return vm;
	}

	@action
	undoAdd() {
		this.currentItems.replace(this.currentItems.filter((i) => !!i.id));
		this.currentModalDoc = undefined;
	}

	async deleteUpload(notiz: NotizVM, upload: BnrUploadModel) {
		await this.bnrUploadStore.deleteById(upload.id);
		runInAction(() => {
			notiz.uploads = notiz.uploads.filter((u) => u.id !== upload.id);
		});
	}

	async save(model: NotizVM) {
		const doc = await this.bnrDocumentStore.save(model.doc);
		model.doc = doc;
		// force reload, so that also new documents appear in the list
		//await this.refresh();

		return this.currentItems.find((i) => i.id === doc.id);
	}

	@action
	async deleteById(id: string) {
		await this.bnrDocumentStore.deleteById(id);
		runInAction(() => {
			this.currentItems.replace(this.currentItems.filter((d) => d.id !== id));
			this.setCurrentModalDoc(undefined);
		});
	}

	@observable currentModalDoc?: NotizVM;

	@action
	setCurrentModalDoc(doc?: NotizVM) {
		this.currentModalDoc = doc;
	}
	@action
	setPreviousModalDoc() {
		if (!this.currentModalDoc || this.currentItems.length === 0) {
			return;
		}
		let i = this.currentItems.findIndex((d) => d.id === this.currentModalDoc!.id);
		if (i === 0) {
			i = this.currentItems.length;
		}
		const d = this.currentItems[i - 1];
		this.setCurrentModalDoc(d);
	}

	@action
	setNextModalDoc() {
		if (!this.currentModalDoc || this.currentItems.length === 0) {
			return;
		}
		let i = this.currentItems.findIndex((d) => d.id === this.currentModalDoc!.id);
		if (i === this.currentItems.length) {
			i = 0;
		}
		const d = this.currentItems[i + 1];
		this.setCurrentModalDoc(d);
	}
}
