import React, { useEffect } from 'react';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';

import { NewsVM } from 'app/stores/ui/news.ui.store';
import { HtmlContent } from '../common/HtmlContent';
import { formatDate, formatKw } from 'app/utils';

export const NewsList = observer(() => {
	const { newsUiStore } = useStore();
	const [items, setItems] = React.useState<NewsVM[]>([]);

	useEffect(() => {
		newsUiStore.findAll().then((res) => {
			setItems(res);
		});
	}, [newsUiStore]);

	return (
		<div className="tasks-list">
			{items.map((vm) => (
				<NewsItem key={vm.id} news={vm} />
			))}
		</div>
	);
});

export const NewsLast3 = observer(() => {
	const { newsUiStore } = useStore();
	const [items, setItems] = React.useState<NewsVM[]>([]);

	useEffect(() => {
		newsUiStore.findAll().then(() => {
			setItems(newsUiStore.last3Items);
		});
	}, [newsUiStore]);

	return (
		<div className="news-list">
			{items.map((vm) => (
				<NewsItem key={vm.id} news={vm} />
			))}
		</div>
	);
});

interface INewsItem {
	news: NewsVM;
}

export const NewsItem = observer((props: INewsItem) => {
	const vm = props.news;

	return (
		<div className="news-list-item">
			<div className="header">
				<div className="title is-4">{vm.news.title}</div>
				<small className="date">{formatDate(vm.news.publishDate)}</small>
			</div>
			<div className="details content">
				<HtmlContent content={vm.news.text} />
			</div>
		</div>
	);
});
