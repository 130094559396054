import { AgenturModel } from 'app/models/agentur.model';
import { AgenturStore } from '../agentur.store';
import { TempAgenturStore } from '../temp.agentur.store';

export class TempAgenturUiStore {
	agenturStore: AgenturStore;
	tempAgenturStore: TempAgenturStore;

	constructor(tempAgenturStore: TempAgenturStore, agenturStore: AgenturStore) {
		// makeObservable(this);
		this.agenturStore = agenturStore;
		this.tempAgenturStore = tempAgenturStore;
	}

	async save(agt: AgenturModel) {
		const res = await this.tempAgenturStore.save(agt);

		return res;
	}
	async delete(agtId: number) {
		const res = await this.tempAgenturStore.deleteById(agtId);
		return res;
	}
	async transfer(tempAgtId: number, targetAgtId: number) {
		const res = await this.tempAgenturStore.transfer(tempAgtId, targetAgtId);
		return res;
	}
}
