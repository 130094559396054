import { observer } from 'mobx-react';
import { useStore } from 'app/context';
import Widget, { IWidgetConsumer } from 'app/components/common/Widget';
import { AgtPlanYearVM } from 'app/stores/ui/agt.plan.ui.store';
import React, { useEffect, useState } from 'react';
import { Button } from 'app/components/common/Button';
import { Icon } from 'app/components/common/Icon';
import { InputControl } from 'app/components/common/InputControl';
import { IObservableArray, observable, runInAction } from 'mobx';

interface IAgtPlanungsZielItem {
	ziel: string;
	onRemove: (z: string) => void;
}
export const AgtPlanungsZielItem = observer((props: IAgtPlanungsZielItem) => {
	return (
		<div className="ziele-list-item">
			<span>{props.ziel}</span>
			<Button className="button is-icon is-delete-button" onClick={() => props.onRemove(props.ziel)}>
				<Icon iconClass="times-circle" />
			</Button>
		</div>
	);
});

interface IAgtPlanungsZieleEdit {
	plan: AgtPlanYearVM;
}

class _ZieleStore {
	ziele: IObservableArray<string> = observable([]);
}
const ZielStore = new _ZieleStore();

export const AgtPlanungsZieleEdit = observer((props: IAgtPlanungsZieleEdit) => {
	const { agtPlanUiStore, uiStore } = useStore();
	useEffect(() => {
		runInAction(() => {
			ZielStore.ziele.replace(props.plan.plan.ziele.map((z) => z));
		});
	}, [props.plan.plan.ziele]);

	const [ziel, setZiel] = useState('');

	const removeZiel = (z: string) => {
		runInAction(() => {
			ZielStore.ziele.remove(z);
		});
	};

	const onChange = (name: string, value: string) => {
		setZiel(value);
	};

	const addZiel = () => {
		if (ziel === '') {
			return;
		}
		runInAction(() => {
			ZielStore.ziele.push(ziel);
			setZiel(' ');
		});
	};

	const onCancel = () => {
		uiStore.apZieleEdit.endEdit();
	};
	const onSave = async () => {
		runInAction(() => {
			props.plan.plan.ziele = ZielStore.ziele;
		});

		await agtPlanUiStore.agtPlanStore.save(props.plan.plan);
		uiStore.apZieleEdit.endEdit();
	};

	return (
		<>
			<div className="ziele-list is-edit-mode">
				{ZielStore.ziele.map((z, i) => (
					<AgtPlanungsZielItem key={'z' + i.toString()} ziel={z} onRemove={removeZiel} />
				))}
			</div>
			<div className="ziele-input">
				<InputControl onEnter={addZiel} name="fullText" value={ziel} onChange={onChange} placeholder={'Weiteres Ziele eingeben'} autoFocus={true} />
				<Button type="button" className="button is-primary " onClick={addZiel} disabled={!ziel || ziel.length === 0}>
					<Icon iconClass={'check'}></Icon>
				</Button>
			</div>

			<div className="has-text-right">
				{uiStore.apZieleEdit.enabled && (
					<>
						<Button type="button" className="button is-primary " onClick={onSave}>
							Fertig
						</Button>
						<Button type="button" className="button is-secondary " onClick={onCancel}>
							Abbrechen
						</Button>
					</>
				)}
			</div>
		</>
	);
});

interface IAgtPlanungsZiele {
	ziele: string[];
}
export const AgtPlanungsZiele = observer((props: IAgtPlanungsZiele) => {
	return (
		<div className="ziele-list is-readonly">
			{props.ziele.map((z, i) => (
				<div className="ziele-list-item" key={'z' + i.toString()}>
					{z}
				</div>
			))}
		</div>
	);
});

export const AgtPlanungsZieleWidget = observer((props: IWidgetConsumer) => {
	const { agtPlanUiStore, uiStore } = useStore();

	return (
		<>
			{agtPlanUiStore.current && (
				<Widget modifier="is-ziele-widget" title="Ziele" edit={uiStore.apZieleEdit} widgetId={props.widgetKey}>
					<div className="has-text-centered">Was will ich konkret in diesem Jahr erreichen?</div>
					{!uiStore.apZieleEdit.enabled && <AgtPlanungsZiele ziele={agtPlanUiStore.current.plan.ziele} />}
					{uiStore.apZieleEdit.enabled && <AgtPlanungsZieleEdit plan={agtPlanUiStore.current} />}
				</Widget>
			)}
		</>
	);
});
