import { BaseCachedEntityStore } from './core/base.cached.entity.store';
import iconv from 'iconv-lite';
import sanitizeHtml from 'sanitize-html';

// https://github.com/ashtuchkin/iconv-lite/wiki/Use-Buffers-when-decoding
// @ts-ignore
iconv.skipDecodeWarning = true;

export class VorlagenContent {
	constructor(opts: any) {
		this.title = opts.title;
		this.text = iconv.decode(opts.text, 'iso-8859-1');
		try {
			if (this.text) {
				this.text = sanitizeHtml(this.text) as string;
				// this.text = parse(c);
				// setContent(c as any);
			}
		} catch (err) {
			console.warn('VorlagenContent sanitization error', err);
		}
		this.visible = opts.visible;
		this.deletable = opts.deletable;
		this.category = opts.category;
		this.stearts = opts.stearts;
	}
	title: string;
	text: string;
	category: string;
	visible: boolean = true;
	deletable: boolean = false;

	stearts: any; // todo

	static fromJson(j?: string) {
		if (!j || j === '') {
			return new VorlagenContent({ text: '' });
		}
		const o = JSON.parse(j);
		return new VorlagenContent(o);
	}

	toJSON() {
		return JSON.stringify({
			title: this.title,
			text: this.text,
			visible: this.visible,
			category: this.category,
		});
	}
}

export class BnrTemplateModel {
	constructor(opts: any) {
		this.id = opts.id;
		this.bnrId = opts.bnrId;
		this.created = opts.created;
		this.modified = opts.modified;
		this.content = VorlagenContent.fromJson(opts.contentJson);
		if (opts.stearts) {
			this.stearts = opts.stearts;
		}
	}
	id: string;
	bnrId: number;
	created: Date;
	modified: Date;
	content: VorlagenContent;
	disabled: boolean = false;

	stearts: number[] = [];

	serialize() {
		return {
			id: this.id,
			bnrId: this.bnrId,
			contentJson: this.content.toJSON(),
			stearts: this.stearts,
		};
	}
}

export class BnrTemplateStore extends BaseCachedEntityStore<BnrTemplateModel> {
	apiPath = 'api/vbl/meta';
	idProp: string = 'id';
	createInstances(opts: any): BnrTemplateModel {
		return new BnrTemplateModel(opts);
	}
}
