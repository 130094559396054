import BaseContainer from 'app/containers/BaseContainer';
import { MainMenu } from './MainMenu';
import { RouteComponentProps } from 'react-router-dom';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { AnwendungenTreeMenu } from 'app/components/anwendungen/AnwendungeTreeMenu';
import { AnwendungenPanel } from 'app/components/anwendungen/AnwendungenPanel';
import { AgtQueryStringSetter, TreePathLoader } from './BerichteContainer';

const AnwendungeNavBar = observer(() => {
	return <div>Anwendungen</div>;
});
const AnwendungenContainer = (props: RouteComponentProps) => {
	const { anwendungenUiStore } = useStore();

	return (
		<BaseContainer sideMenu={<MainMenu {...props} />} navbar={<AnwendungeNavBar />}>
			<TreePathLoader store={anwendungenUiStore} />
			<AgtQueryStringSetter />
			<AnwendungenTreeMenu />
			<AnwendungenPanel />
		</BaseContainer>
	);
};
export default AnwendungenContainer;
