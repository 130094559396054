import { useEffect, useMemo } from 'react';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { Table } from './../table/table';
import { ITableColumn, TableModel, TableRow } from './../table/table.model';
import { formatDate } from 'app/utils';
import { reaction, runInAction } from 'mobx';
import { NotizVM } from 'app/stores/ui/notizen.ui.store';
import { HtmlContent } from '../common/HtmlContent';

export const NotizenList = observer(() => {
	const { notizenUiStore, uiStore } = useStore();

	const tm = useMemo(() => {
		const viewDoc = (doc: NotizVM) => {
			runInAction(() => {
				notizenUiStore.setCurrentModalDoc(doc);
				uiStore.showModal(uiStore.modalIds.notizViewModal);
			});
		};
		const tm = new TableModel<NotizVM, number>();
		let cols: ITableColumn<NotizVM, number>[] = [
			{
				label: 'Notiz',
				path: 'doc.content.text',
				render: (row: TableRow<NotizVM>) => {
					return <HtmlContent content={row.data.doc.content.text} />;
				},
			},

			{
				label: 'Erstellt',
				path: 'doc.created',
				format: (val: any) => {
					return formatDate(val);
				},
			},
			{
				label: 'Geändert',
				path: 'doc.modified',
				format: (val: any) => {
					return formatDate(val);
				},
			},
		];

		tm.onRowClick = (row: TableRow<NotizVM>) => {
			viewDoc(row.data);
		};

		tm.setCols(cols);
		tm.sortBy = 'doc.modified';
		tm.sortAsc = false;
		tm.idProperty = 'id';
		tm.idType = 'string';
		return tm;
	}, [notizenUiStore, uiStore]);

	useEffect(() => {
		notizenUiStore.findAll();
		return reaction(
			() => notizenUiStore.realItemsForFuckSakes.length,
			() => {
				tm.setRowData(notizenUiStore.realItemsForFuckSakes);
			},
			{ fireImmediately: true },
		);
	}, [notizenUiStore, tm]);

	return (
		<>
			<Table modifier="is-notiz-table" tm={tm}></Table>
		</>
	);
});
