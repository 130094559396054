import { Table } from 'app/components/table/table';
import { ITableColumn, TableModel, TableRow } from 'app/components/table/table.model';
import { observer } from 'mobx-react';
import { useStore } from 'app/context';
import { formatNum } from 'app/utils';
import { useEffect, useMemo } from 'react';
import moment from 'moment';
import { AgtBestandPos5YearVM } from 'app/models/berichte/agt.bestand.pos.5year.vm';
import { PleaseSelectAgt } from 'app/components/berichte/PleaseSelectAgt';

export const AgtNeugeschaeft = observer(() => {
	const { berichteUiiStore, agtNeugeschaftUiStore: neugeschaftUiStore } = useStore();
	const agt = berichteUiiStore.selectedAgt;
	const tm = useMemo(() => {
		const tm = new TableModel<AgtBestandPos5YearVM, number>();
		tm.idProperty = 'title';
		tm.idType = 'number';

		return tm;
	}, []);

	useEffect(() => {
		if (agt) {
			neugeschaftUiStore.find5YearHistoryByAgtId(agt.agtId).then((res) => {
				const item = res[0];
				const cols: ITableColumn<AgtBestandPos5YearVM, number>[] = [
					{
						label: 'Neugeschäft',
						path: 'title',
						render: (row: TableRow<AgtBestandPos5YearVM>) => {
							return <strong>{row.data.title} </strong>;
						},
					},
				];
				if (item.year0) {
					cols.push({
						label: moment(item.year0.bm).format('MM/YYYY'),
						path: ['year0.val.bt'],
						render: (row) => {
							const val = row.data.year0?.val.bt || 0;
							if (row.data.posDef.posId === -1) {
								return <>{val} %</>;
							}
							return <>{formatNum(val)}</>;
						},
					});
				}
				if (item.year1) {
					cols.push({
						label: moment(item.year1!.bm).format('YYYY'),
						path: ['year1.val.bt'],
						render: (row) => {
							const val = row.data.year1?.val.bt || 0;
							if (row.data.posDef.posId === -1) {
								return <>{val} %</>;
							}
							return <>{formatNum(val)}</>;
						},
					});
				}
				if (item.year2) {
					cols.push({
						label: moment(item.year2!.bm).format('YYYY'),
						path: ['year2.val.bt'],
						render: (row) => {
							const val = row.data.year2?.val.bt || 0;
							if (row.data.posDef.posId === -1) {
								return <>{val} %</>;
							}
							return <>{formatNum(val)}</>;
						},
					});
				}
				if (item.year3) {
					cols.push({
						label: moment(item.year3!.bm).format('YYYY'),
						path: ['year3.val.bt'],
						render: (row) => {
							const val = row.data.year3?.val.bt || 0;
							if (row.data.posDef.posId === -1) {
								return <>{val} %</>;
							}
							return <>{formatNum(val)}</>;
						},
					});
				}

				if (item.year4) {
					cols.push({
						label: moment(item.year4!.bm).format('YYYY'),
						path: ['year4.val.bt'],
						render: (row) => {
							const val = row.data.year4?.val.bt || 0;
							if (row.data.posDef.posId === -1) {
								return <>{val} %</>;
							}
							return <>{formatNum(val)}</>;
						},
					});
				}

				tm.setCols(cols);

				tm.setRowData(res);
			});
		}
	}, [tm, neugeschaftUiStore, berichteUiiStore.selectedAgt, agt]);

	return (
		<>
			{berichteUiiStore.selectedAgt ? (
				<div className="table-container">
					<Table tm={tm} stickyHeader={true}></Table>
				</div>
			) : (
				<PleaseSelectAgt />
			)}
		</>
	);
});
