import { observer } from 'mobx-react';
import { useStore } from 'app/context';
import React from 'react';
import { AnwendungenPanel } from '../anwendungen/AnwendungenPanel';
import { AnwendungenTreeMenu } from '../anwendungen/AnwendungeTreeMenu';

export const AgenturAnwendungen = observer(() => {
	const { agenturUiStore } = useStore();
	const agt = agenturUiStore.current;
	return (
		<>
			<AnwendungenTreeMenu />
			{agt && (
				<>
					<div>
						<AnwendungenPanel />
						<br />
					</div>
				</>
			)}
		</>
	);
});
