import { useEffect } from 'react';

/* 
Improvement: Set up an array of components (or escape funcstions?) that use this hook in the ui store, 
then only close the upper most in the dom
*/

export const useEscape = (onEscape: () => void) => {
	useEffect(() => {
		const handleEsc = (e: KeyboardEvent) => {
			if (e.key === 'Escape') {
				const activeElement = document.activeElement;
				let tagName;
				let tagType;
				let shouldBlur = false;

				// if an input element is focus, first unfocus it
				if (activeElement) {
					tagName = activeElement.tagName;
					tagType = activeElement.getAttribute('type');
					tagType = activeElement.getAttribute('contenteditable');
				}

				switch (true) {
					case tagName === 'INPUT' && tagType === 'text':
					case tagName === 'INPUT' && tagType === 'number':
					case tagName === 'SELECT':
					case tagName === 'DIV':
						shouldBlur = true;
						break;
				}

				if (shouldBlur && activeElement instanceof HTMLElement) {
					activeElement.blur();
					return;
				}

				// if there are any element that override the ecs function
				const overrideEsc = document.getElementsByClassName('override-esc');
				if (overrideEsc.length > 0) {
					return;
				}

				// if no input elements are focused, close modal
				onEscape();
				return;
			}
		};

		window.addEventListener('keydown', handleEsc);

		return () => {
			window.removeEventListener('keydown', handleEsc);
		};
	}, [onEscape]);
};
