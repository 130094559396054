import { ZielErgebnisBalkenModel } from 'app/components/charts/ZielErgebnisBalken';
import { BnrErrungenschaftVSP } from 'app/components/errungenschaften/BnrErrungenschaften';
import { ErrungenschaftModel, ErrungenschaftState, ErrungenschaftType, IErrungenschaft } from 'app/models/errungenshaften/errungenschaften.model';
import _ from 'lodash';
import { computed, makeObservable, observable, reaction, runInAction } from 'mobx';
import moment from 'moment';
import { BnrGpStore } from '../bnr.gp.store';
import { AgtBranchenplanungUiStore } from './agt.branchenplanung.ui.store';
import { AgtErrungenschaftenUiStore } from './agt.errungenschaften.ui.store';
import { AgenturListUiStore } from './agt.list.ui.store';
import { BnrProdUiStore } from './bnr.prod.ui.store';
import { DocumentUiStore } from './document.ui.store';
import { TaskUiStore } from './task.ui.store';
import { BnrErrungenschaftenDefinition, BnrSecretErrungenschaften } from 'app/models/errungenshaften/bnr.errungenschaften.def';
import { KvBnrYearErrungenschaftenModel, KvBnrYearErrungenschaftenStore } from '../kv.bnr.year.errungenschaften.store';

export class BnrErrungenschaftenVM {
	constructor() {
		makeObservable(this);
		this.items = BnrErrungenschaftenDefinition.map((e) => new ErrungenschaftModel(e));
	}
	@observable
	items: ErrungenschaftModel[] = [];

	@computed
	get sortedByAchieved() {
		return _.sortBy(this.items, (i) => i.stateAsNumber).reverse();
	}

	@computed
	get achievedItems() {
		return this.items.filter((i) => i.state !== ErrungenschaftState.off);
	}

	@computed
	get nonAchievedItems() {
		return this.items.filter((i) => i.state === ErrungenschaftState.off);
	}
}

class ChangedErrungeschaft {
	constructor(opts: any) {
		this.def = opts.def;
		this.kv = opts.kv;
	}
	def: IErrungenschaft;
	kv: KvBnrYearErrungenschaftenModel;
}

export class BnrErrungenschaftenUiStore {
	agtErrungenschaftenUiStore: AgtErrungenschaftenUiStore;
	agenturListUiStore: AgenturListUiStore;
	documentUiStore: DocumentUiStore;
	taskUiStore: TaskUiStore;
	bnrGpStore: BnrGpStore;
	bnrProdUiStore: BnrProdUiStore;
	agtBranchenplanungUiStore: AgtBranchenplanungUiStore;
	kvBnrYearErrungenschaftenStore: KvBnrYearErrungenschaftenStore;

	constructor(
		agtErrungenschaftenUiStore: AgtErrungenschaftenUiStore,
		agenturListUiStore: AgenturListUiStore,
		documentUiStore: DocumentUiStore,
		taskUiStore: TaskUiStore,
		bnrGpStore: BnrGpStore,
		bnrProdUiStore: BnrProdUiStore,
		agtBranchenplanungUiStore: AgtBranchenplanungUiStore,
		kvBnrYearErrungenschaftenStore: KvBnrYearErrungenschaftenStore,
	) {
		makeObservable(this);
		this.agtErrungenschaftenUiStore = agtErrungenschaftenUiStore;
		this.agenturListUiStore = agenturListUiStore;
		this.documentUiStore = documentUiStore;
		this.taskUiStore = taskUiStore;
		this.bnrGpStore = bnrGpStore;
		this.bnrProdUiStore = bnrProdUiStore;
		this.agtBranchenplanungUiStore = agtBranchenplanungUiStore;
		this.kvBnrYearErrungenschaftenStore = kvBnrYearErrungenschaftenStore;
	}

	@observable
	current: BnrErrungenschaftenVM = new BnrErrungenschaftenVM();

	@observable
	kvItems: KvBnrYearErrungenschaftenModel[] = [];

	get errungenschaftenDef() {
		let defs: IErrungenschaft[] = [];
		defs = defs.concat(BnrSecretErrungenschaften);
		defs = defs.concat(BnrErrungenschaftenDefinition);
		return defs;
	}

	@computed
	get changedThisKw() {
		const kw = this.agtErrungenschaftenUiStore.currentKw;
		const kvs = this.kvItems.filter((x) => x.history && x.history.find((h) => h.kw === kw));
		const res: ChangedErrungeschaft[] = [];
		kvs.forEach((kv) => {
			const def = this.errungenschaftenDef.find((e) => e.key === kv.key);
			if (!def) {
				console.warn('no errungenschaften definition found for key', kv.key);
				return;
			}
			res.push(new ChangedErrungeschaft({ def, kv }));
		});
		return res;
	}

	@computed
	get zielErfuellerItems() {
		return this.current.items.filter((e) => e.key === 'bnr.gp.eh' || e.key.startsWith('bnr.vsp.'));
	}

	@computed
	get isZielErfueller() {
		let allGold = true;
		this.zielErfuellerItems.forEach((i) => {
			if (i.state !== ErrungenschaftState.gold) {
				allGold = false;
			}
		});
		return allGold;
	}

	@computed
	get meisterPlanerItems() {
		const meisterPlanerKeys = ['bnr.bp.planned', 'bnr.jpgs.created', 'bnr.agt.produktivitaet'];
		return this.current.items.filter((e) => meisterPlanerKeys.includes(e.key));
	}
	@computed
	get isMeisterPlaner() {
		let allGold = true;
		this.meisterPlanerItems.forEach((i) => {
			if (i.state !== ErrungenschaftState.gold) {
				allGold = false;
			}
		});
		return allGold;
	}

	@computed
	get dvsExperteItems() {
		const meisterPlanerKeys = ['bnr.docs.created', 'bnr.docs.shared', 'bnr.tasks.completed'];
		return this.current.items.filter((e) => meisterPlanerKeys.includes(e.key));
	}
	@computed
	get isDvsExperte() {
		// let atLeastOneGold = false;
		// this.dvsExperteItems.forEach((i) => {
		// 	if (i.state === ErrungenschaftState.gold) {
		// 		atLeastOneGold = true;
		// 	}
		// });

		// return atLeastOneGold;
		return this.dvsExperteLevel !== ErrungenschaftState.off;
	}

	@computed
	get dvsExperteLevel(): ErrungenschaftState {
		const goldCount = this.dvsExperteItems.filter((x) => x.state === ErrungenschaftState.gold).length;
		const silverCount = this.dvsExperteItems.filter((x) => x.state === ErrungenschaftState.silver).length;
		const bronzeCount = this.dvsExperteItems.filter((x) => x.state === ErrungenschaftState.bronze).length;

		if (goldCount >= 3) {
			return ErrungenschaftState.gold;
		}
		if (silverCount >= 3) {
			return ErrungenschaftState.silver;
		}
		if (bronzeCount >= 3) {
			return ErrungenschaftState.bronze;
		}
		return ErrungenschaftState.off;
	}

	@computed
	get showTrophyCase() {
		return this.isDvsExperte || this.isMeisterPlaner || this.isZielErfueller;
	}

	async save() {
		await this.clearCache();
		await this.load(true);
		const year = moment().year();

		const allKvs = this.kvItems;
		const kvs = allKvs.filter((x) => x.year === year);

		const promises: any = [];
		this.current.items.forEach((e) => {
			promises.push(this._saveOne(kvs, e.key, e.state));
		});

		const meisterPlanerKey = 'bnr.meisterplaner';
		const meisterPlanerState = this.isMeisterPlaner ? ErrungenschaftState.gold : ErrungenschaftState.off;
		promises.push(this._saveOne(kvs, meisterPlanerKey, meisterPlanerState));

		const zielerfuellerKey = 'bnr.zielerfueller';
		const zielerfuellerState = this.isZielErfueller ? ErrungenschaftState.gold : ErrungenschaftState.off;
		promises.push(this._saveOne(kvs, zielerfuellerKey, zielerfuellerState));

		const dvsExperteKey = 'bnr.dvs.experte';
		const dvsExperteState = this.dvsExperteLevel;
		promises.push(this._saveOne(kvs, dvsExperteKey, dvsExperteState));

		await Promise.all(promises);
		await this.agtErrungenschaftenUiStore.save();
		this.kvItems = await this.kvBnrYearErrungenschaftenStore.findAll();
	}

	_saveOne(kvs: KvBnrYearErrungenschaftenModel[], key: string, state: ErrungenschaftState) {
		const bnrId = this.kvBnrYearErrungenschaftenStore.session.currentUser!.bnrId;
		const year = moment().year();

		let kv = kvs.find((k) => k.key === key);
		if (!kv) {
			kv = new KvBnrYearErrungenschaftenModel({
				bnrId,
				bm: year,
				group: 'errungenschaften',
				key: key,
			});
		} else {
			if (kv.valueString === state) {
				return;
			}
			kv.addHistoryEntry();
		}
		kv.group = 'errungenschaften';
		kv.bnrId = bnrId;
		kv.valueString = state;
		return this.kvBnrYearErrungenschaftenStore.save(kv);
	}

	loadingPromise?: Promise<any>;
	loadingPromiseResolver?: any;

	vspsAdded: boolean = false;

	async clearCache() {
		if (this.loadingPromise) {
			await this.loadingPromise;
		}
		this.loadingPromise = undefined;
		this.loadingPromiseResolver = undefined;
		this.agtErrungenschaftenUiStore.clearCache();
	}

	async load(doCalcs: boolean) {
		if (this.loadingPromise) {
			await this.loadingPromise;
			return;
		}

		this.loadingPromise = new Promise((resolve) => {
			this.loadingPromiseResolver = resolve;
		});

		if (!this.vspsAdded) {
			const vspWidgets = this.bnrGpStore.positionen.filter((p) => p.group.id === 'vss');
			vspWidgets.forEach((w) => {
				if (!w.planKey) {
					return;
				}

				const e = new ErrungenschaftModel({
					key: 'bnr.vsp.' + w.planKey,
					component: (m: ErrungenschaftModel) => <BnrErrungenschaftVSP vspKey={w.planKey!} {...m} />,
					valType: ErrungenschaftType.boolean,
					modal: {
						title: 'VSP ' + w.posText,
						description: 'Erreichung der Eingangshürde des Vertriebsschwerpunktes',
					},
				});
				this.current.items.push(e);
			});
			runInAction(() => {
				this.vspsAdded = true;
			});
		}


		const allKvs = await this.kvBnrYearErrungenschaftenStore.findAll();
		this.current.items.forEach(e => {
			allKvs.forEach(kv => {
				if (e.key === kv.key) {
					runInAction(() => {
						e.setState(kv.state);
						e.history = kv.history;
					})
				}
			})

		});

		await this.agtErrungenschaftenUiStore.load(doCalcs);
		if (doCalcs) {
			const currentYear = moment().year();
			const promises = [
				this.loadDocsCreatedAndShared(),
				this.loadTasksCompleted(),
				this.loadJpgsCreated(),
				this.loadGpEh(currentYear),
				this.loadVSP(currentYear),
				this.loadBP(),
				this.loadAgtBewertung(),
			];
			await Promise.all(promises);
		}

		runInAction(() => {
			this.kvItems = allKvs;
		});


		if (this.loadingPromiseResolver) {
			this.loadingPromiseResolver();
		}
	}

	async loadDocsCreatedAndShared() {
		await this.documentUiStore.load();

		reaction(
			() => this.documentUiStore._items.length,
			() => {
				const docs = this.documentUiStore._items.filter((d) => d.isJpg !== true && d.isAp !== true && d.isKbv !== true);
				const currYear = moment().year();
				const docsCreatedThisYear = docs.filter((d) => d.doc.yearCreated === currYear);
				const countCreatedThisYear = docsCreatedThisYear.length;
				const docSharedThisYear = docsCreatedThisYear.filter((d) => d.isSharedByMe);
				const countSharedThisYear = docSharedThisYear.length;

				const eCreated = this.current.items.find((e) => e.key === 'bnr.docs.created')!;
				eCreated.setStateGSB(countCreatedThisYear);
				const eShared = this.current.items.find((e) => e.key === 'bnr.docs.shared')!;
				eShared.setStateGSB(countSharedThisYear);
			},
			{ fireImmediately: true },
		);
	}

	async loadTasksCompleted() {
		await this.taskUiStore.load();

		reaction(
			() => this.taskUiStore._items.length,
			() => {
				const tasks = this.taskUiStore._items;
				const currYear = moment().year();
				const tasksCompltedThisYear = tasks.filter((d) => d.task.yearModified === currYear && d.isClosed);
				const count = tasksCompltedThisYear.length;
				const e = this.current.items.find((e) => e.key === 'bnr.tasks.completed')!;
				e.setStateGSB(count);
			},
			{ fireImmediately: true },
		);
	}
	async loadJpgsCreated() {
		await this.agenturListUiStore.load();

		const items = this.agenturListUiStore.itemsForErrungenschaften;
		const doCount = () => {
			const countDone = items.filter((i) => i.jpgDone).length;
			const countAll = items.length;
			const p = (countDone / countAll) * 100;
			// console.log('value reaced', p);
			const e = this.current.items.find((e) => e.key === 'bnr.jpgs.created')!;
			e.setStateGSB(p);
		};
		items.forEach((i) => {
			reaction(
				() => i.jpgDone,
				() => {
					doCount();
				},
				{ fireImmediately: false },
			);
		});
		doCount();
		console.log('done jpg');
	}

	async loadGpEh(year: number) {
		const widgets = this.bnrGpStore.zeWidgets;
		const e = this.current.items.find((e) => e.key === 'bnr.gp.eh')!;
		let yup = false;
		for (const w of widgets) {
			for (const i of w.items) {
				let model: ZielErgebnisBalkenModel = new ZielErgebnisBalkenModel(0, 0);
				if (i.posId) {
					model = await this.bnrProdUiStore.getZeForPosId(i.posId, year);
				}
				if (i.planKey) {
					model = await this.bnrProdUiStore.getZeForPlanKey(i.planKey, year);
				}

				if (model.e > 0 && model.e >= model.z) {
					yup = true;
					break;
				}
			}
			if (yup) {
				break;
			}
		}
		e.setStateBool(yup);
		// console.warn('hackkkkk ');
		// console.warn('hackkkkk ');
		// console.warn('hackkkkk ');
		// e.setStateBool(true);
	}
	async loadVSP(year: number) {
		const vspWidgets = this.bnrGpStore.positionen.filter((p) => p.group.id === 'vss');
		for (const w of vspWidgets) {
			if (!w.planKey) {
				return;
			}
			const eKey = 'bnr.vsp.' + w.planKey;
			const e = this.current.items.find((e) => e.key === eKey)!;
			const pos = await this.bnrProdUiStore.getZeForPosId(w.posId, year);
			e.setStateBool(false);
			if (pos && pos.e > pos.z) {
				e.setStateBool(true);
			}
		}
		console.log('done VSP');
	}

	async loadBP() {
		const bps = await this.agtBranchenplanungUiStore.findByYear(moment().year());

		let wSum = 0;
		let wTotal = 0;
		bps.forEach((bp) => {
			wSum += bp.myCountWithValues;
			wTotal += bp.myPoses.length;
		});

		const e = this.current.items.find((e) => e.key === 'bnr.bp.planned')!;
		const res = (wSum / wTotal) * 100;
		e.setStateGSB(res);
	}

	async loadAgtBewertung() {
		let count = 0;
		const total = this.agtErrungenschaftenUiStore.vmItems.length;
		this.agtErrungenschaftenUiStore.vmItems.forEach((i) => {
			const eAgt = i.items.find((e) => e.key === 'agt.produktivitaet');
			if (eAgt!.boolVal) {
				count++;
			}
		});
		let p = (count / total) * 100;
		const e = this.current.items.find((e) => e.key === 'bnr.agt.produktivitaet')!;
		e.setStateBool(p > 80);
	}
}
