import { Einheit } from 'app/models/core/einheit.model';
import { BnrGpPosModel } from '../bnr.gp.pos.model';
import { PosUnDef } from '../gp';
const posDef: BnrGpPosModel[] = [];

posDef.push(new BnrGpPosModel({ year: 2023, posId: 2230001, group: 'vss', posText: 'Weiterbildungspfl. BNR (IDD)', planKey: 'idd', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2023, posId: PosUnDef, group: 'vss', posText: 'Weiterbildungsz.Fonds', planKey: 'idd_fonds', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2023, posId: 2230011, group: 'vss', posText: 'Leben Firmen Risiko', planKey: 'lebnris', einheit: Einheit.bew }));

posDef.push(new BnrGpPosModel({ year: 2023, posId: PosUnDef, group: 'eh', posText: 'Leben Firmen Bew 90%', planKey: 'lebenfirmenbew90', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2023, posId: 2230042, group: 'eh', posText: 'Leben Firmen Bew', planKey: 'lebenfirmenbew', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2023, posId: PosUnDef, group: 'eh', posText: 'Leben Firmen Risiko 90%', planKey: 'lebenfirmenrisk90', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2023, posId: 2230011, group: 'eh', posText: 'Leben Firmen Risiko', planKey: 'lebenfirmenrisk', einheit: Einheit.bew }));

posDef.push(new BnrGpPosModel({ year: 2023, posId: 2230111, group: 'neh', posText: 'Leben Firmen KnP80 Bew.', planKey: 'lebenfimenknp', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2023, posId: 2230101, group: 'neh', posText: 'Leben Firmen Bew', planKey: 'untervers', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2023, posId: 2230131, group: 'neh', posText: 'berat.int. Firmengeschäft', planKey: 'beratrem', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2023, posId: 2230141, group: 'neh', posText: 'Firmenfonds NMZ o. SV', planKey: 'unfallbewrtungoanpp', einheit: Einheit.bew }));

posDef.push(new BnrGpPosModel({ year: 2023, posId: 3830011, group: 'gpplus', posText: 'Leben bAVA Fonl o.BZM', planKey: 'lebenbava', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2023, posId: 3830021, group: 'gpplus', posText: 'Kranken bKV Bew', planKey: 'krankenbkv', einheit: Einheit.bew }));

export const UbPositionen = posDef;
