const Config = {
	API_BASE_URL: 'http://localhost:8090',
	basicAuth: 'df:dv$2017!!',
	showFuturePlanYears: false, // ab Oktober bereits das nächste Jahr in der Planung anbieten
	showGslStuff: false,
	showVisTeilnahme: false, // Awaiting feedback from Allianz,
	showFitness: true
};

if (process.env.REACT_APP_ENV === 'holger') {
	Config.API_BASE_URL = 'http://192.168.10.47:8090';
}
if (process.env.REACT_APP_ENV === 'dvstest') {
	Config.API_BASE_URL = 'https://dvstest.isarbits.com/';
}

if (process.env.REACT_APP_ENV === 'dvswip') {
	Config.API_BASE_URL = 'https://dvswip.isarbits.com/';
}

if (process.env.REACT_APP_ENV === 'dvsdemo') {
	Config.API_BASE_URL = 'https://dvsdemo.isarbits.com/';
	Config.showFuturePlanYears = true;
}

if (process.env.REACT_APP_ENV === 'production') {
	Config.showGslStuff = false;
	Config.showFitness = false;
	Config.API_BASE_URL = 'https://dvs-app.de/';
	if (window.location.host.indexOf('server') >= 0) {
		Config.API_BASE_URL = 'http://server:8090';
		console.log('Crawler setup using ' + Config.API_BASE_URL);
	}
}

//console.log(Config);

export default Config;
