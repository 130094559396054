import { observer } from 'mobx-react';
import { useStore } from 'app/context';
import { runInAction } from 'mobx';
import { Modal } from 'app/components/Modal';
import { DocumentVM, IShareData } from 'app/stores/ui/document.ui.store';
import { Button } from 'app/components/common/Button';
import { useEffect, useState } from 'react';
import { DokumentShareVM } from 'app/stores/shared.document.store';

interface IDocumentSendModalProps {
	// onClose: () => void;
}

interface IDocShareTable {
	doc: DocumentVM;
	shares: DokumentShareVM[];
	showActions: boolean;
}

interface IDocShareTableRow {
	doc: DocumentVM;
	share: DokumentShareVM;
	showActions: boolean;
}

const DocShareTableRow = observer((props: IDocShareTableRow) => {
	const { documentUiStore } = useStore();
	const s = props.share;

	const onShare = async () => {
		const share = await documentUiStore.share(props.doc.id, s.user.bnrId);
		runInAction(() => {
			if (share) {
				s.share = share;
				props.doc.doc.sharedWith.push(share);
			}
		});
	};
	const onUnShare = async () => {
		if (s.share) {
			documentUiStore.sharedDocumentStore.unshare(s.share.id);
			runInAction(() => {
				if (s.share) {
					props.doc.doc.sharedWith = props.doc.doc.sharedWith.filter((_s) => _s.id !== s.share!.id);
				}
				s.share = undefined;
			});
		}
	};

	return (
		<tr>
			<td>{s.user.steart.name}</td>
			<td>
				{s.user.lastName}, {s.user.firstName}
			</td>
			<td>{s.user.bnrId}</td>
			{props.showActions && (
				<td>
					{s.isShared ? (
						<Button className="button is-small" onClick={onUnShare}>
							Nicht mehr teilen
						</Button>
					) : (
						<Button className="button is-small is-primary" onClick={onShare}>
							Teilen
						</Button>
					)}{' '}
				</td>
			)}
		</tr>
	);
});

const DocShareTable = observer((props: IDocShareTable) => {
	return (
		<table className="table">
			<tbody>
				{props.shares.map((s) => (
					<DocShareTableRow key={s.user.bnrId} {...props} share={s} />
				))}
			</tbody>
		</table>
	);
});

export const DocumentShareModal = observer((props: IDocumentSendModalProps) => {
	const { uiStore, documentUiStore, teamUiStore } = useStore();
	const modalId = uiStore.modalIds.documentShareModal;

	const [shareData, setShareData] = useState<IShareData>({ shares: [], nonShareables: [] });
	// const [doc, setDoc] = useState<IShareData>({ shares: [], nonShareables: [] });

	useEffect(() => {
		if (documentUiStore.currentModalDoc) {
			teamUiStore.load().then(() => {
				const _shareData = documentUiStore.getSharesForDocument(documentUiStore.currentModalDoc!, true);
				setShareData(_shareData);
			});
		}
	}, [documentUiStore.currentModalDoc, documentUiStore, teamUiStore]);

	const onCancel = () => {
		runInAction(() => {
			documentUiStore.setCurrentModalDoc(undefined);
			uiStore.hideModal(modalId);
			documentUiStore.refresh();

			// props.onClose();
		});
	};

	return (
		<>
			<Modal modalId={modalId} title={'Dokument teilen'} modifier="is-paddingless" onClose={onCancel}>
				<div>
					<div className="pad-1rem">
						<p>
							<strong>Verfügbare DVS Benutzer</strong>
						</p>
					</div>

					{documentUiStore.currentModalDoc && <DocShareTable doc={documentUiStore.currentModalDoc} shares={shareData.shares} showActions={true} />}

					{shareData.nonShareables.length > 0 && (
						<div className="pad-1rem">
							<p>
								<strong>Folgende Rollen sind nicht in der DVS registriert</strong>
							</p>
						</div>
					)}
					{documentUiStore.currentModalDoc && <DocShareTable doc={documentUiStore.currentModalDoc} shares={shareData.nonShareables} showActions={false} />}

					<div className="modal-footer">
						<Button type="button" className="button" onClick={onCancel}>
							Schließen
						</Button>
					</div>
				</div>
			</Modal>
		</>
	);
});
