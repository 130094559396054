import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { Modal } from '../Modal';
import { TemplateEditForm } from './TemplateEditForm';

export const TemplatesEditModal = observer(() => {
	const { uiStore, templateUiStore } = useStore();
	const modalId = uiStore.modalIds.templateEdit;

	const onCancel = () => {
		uiStore.hideModal(modalId);
	};

	return templateUiStore.current ? (
		<Modal modalId={modalId} size="large" title={`Vorlage bearbeiten: ${templateUiStore.current.title}`} onClose={onCancel}>
			<TemplateEditForm template={templateUiStore.current} onClose={onCancel} />
		</Modal>
	) : null;
});
