import { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import _, { debounce } from 'lodash';
import { init, EChartsOption, ECharts } from 'echarts';
import { getColor } from 'app/utils';
import { LeadNowAbos, LeadNowType, LeadNowVM } from 'app/stores/ui/agt.leadnow.ui.store';

interface ILeadNowBarChart {
	type: LeadNowType;
	items: LeadNowVM[];
}

// see https://echarts.apache.org/handbook/en/concepts/dataset/
export interface IGraphDataset {
	dimensions: string[];
	source: {
		[key: string]: number | string;
	};
}

export const LeadNowBarChart = observer((props: ILeadNowBarChart) => {
	const type = props.type === LeadNowType.brutto ? 'brutto' : 'netto';

	let kwData = props.items;
	if (!kwData) return null;

	// echarts stuff
	const chartRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		// clone LeadNowAbos
		let leadData = JSON.parse(JSON.stringify(LeadNowAbos));
		leadData.forEach((ld: any) => {
			kwData.forEach((kw) => {
				ld.val = ld.val + kw[ld.path][type];
			});
		});
		leadData = leadData.filter((ld: any) => ld.val > 0);

		const dim = ['path', 'label', 'val'];

		// const legendData = leadData.map((ld: any) => ({ name: ld.label }));

		const chartHeight = 350;

		let option: EChartsOption = {
			color: getColor('all'),
			dataset: {
				dimensions: dim,
				source: leadData,
			},
			yAxis: {
				type: 'category',
				axisLabel: {
					interval: 0,
					fontSize: 14,
					padding: [0, 5, 0, 0],
				},
			},
			xAxis: {
				type: 'value',
			},
			series: {
				type: 'bar',
				encode: {
					y: 'label',
					x: 'val',
				},
				colorBy: 'data',
			},
			legend: {
				show: true,
			},
			grid: {
				containLabel: true,
			},
		};

		let chart: ECharts | null = null;
		if (chartRef.current !== null) {
			chart = init(chartRef.current, null, { renderer: 'svg', height: chartHeight });
			chart.setOption(option);
		}
		const handleResize = debounce(() => {
			chart?.resize();
		}, 300);
		window.addEventListener('resize', handleResize);

		return () => {
			chart?.dispose();
			window.removeEventListener('resize', handleResize.cancel);
		};
	}, [chartRef, kwData, type]);

	return (
		<>
			<div style={{ width: '100%', height: 'auto', background: '#ffffff' }} ref={chartRef}></div>
		</>
	);
});
