import { ZweitverkaeuferModel } from 'app/models/personal/zweitverkauefer.model';
import { BaseCachedEntityStore } from './core/base.cached.entity.store';

export class PersonalZweitverkaeuferStore extends BaseCachedEntityStore<ZweitverkaeuferModel> {
	apiPath = 'api/mitarbeiter/zweitverkaeufer';
	idProp: string = 'identifier';
	createInstances(opts: any): ZweitverkaeuferModel {
		return new ZweitverkaeuferModel(opts);
	}

	async findByAgtId(agtId: number) {
		const items = await this.findAll();

		return items.filter((i) => i.agtId === agtId);
	}
}
