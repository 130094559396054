import { UserModel } from 'app/models/core/user.model';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import { BnrDocumentShareModel } from './bnr.document.store';
import { BaseCachedEntityStore } from './core/base.cached.entity.store';
import { DocumentVM, DokumentReadState } from './ui/document.ui.store';

export class DokumentShare {
	constructor(opts: any) {
		this.user = opts.user;
		this.share = opts.share;
		this.isShared = opts.isShared;
		this.isOwner = opts.isOwner;
		this.isMine = opts.isMine;
	}
	user: UserModel;
	share?: BnrDocumentShareModel;
	isShared: boolean = false;
	isOwner: boolean = false;
	isMine: boolean = false;
}

export class DokumentShareVM {
	constructor(opts: any) {
		makeObservable(this);
		this.user = opts.user;
		this.share = opts.share;
		this.isOwner = opts.isOwner;
		this.isMine = opts.isMine;
	}
	user: UserModel;
	@observable
	share?: BnrDocumentShareModel;
	isOwner: boolean = false;
	isMine: boolean = false;

	@computed
	get isShared() {
		return !!this.share;
	}
}

export class SharedDocumentStore extends BaseCachedEntityStore<DokumentShare> {
	apiPath = 'api/shareddocs';
	idProp: string = 'id';
	disableFindForStearts = [123];

	createInstances(opts: any): DokumentShare {
		return new DokumentShare(opts);
	}

	getSharesForDocument(document: DocumentVM, users: UserModel[], filterByRole: boolean) {
		const shares: DokumentShare[] = [];
		const doc = document.doc;
		// const agt = document.agt;
		// // if (!agt) {
		// // 	return shares;
		// // }
		const sharedWith = doc.sharedWith;
		if (!sharedWith) {
			return shares;
		}

		const ownerBnrId = doc.bnrId;
		const currentBnrId = document.currentUser.bnrId;
		const currentRole = this.session.currentUser!.steart;

		users.forEach((user) => {
			let isOwner = false;
			let isMine = false;
			if (user.bnrId === currentBnrId) {
				isMine = true;
			}
			if (user.bnrId === ownerBnrId) {
				isOwner = true;
			}
			const share = sharedWith.find((s) => s.bnrId === user.bnrId);
			const dokShare = new DokumentShareVM({
				user,
				share,
				isOwner,
				isMine,
			});
			const canShareWith = currentRole.canShareWith.includes(user.steart.group);
			if (canShareWith || !filterByRole) {
				shares.push(dokShare);
			}
		});
		return shares;
	}

	share(docId: string, bnrIdList: number[]) {
		const data = {
			docId: docId,
			bnrIdList: bnrIdList,
		};
		return this.post('', data);
	}

	unshare(shareId: string) {
		const data = {
			uuidList: [shareId],
		};
		return this.post('unshare', data);
	}

	async toggleRead(document: DocumentVM) {
		const share = document.doc.sharedWith.find((s) => s.bnrId === document.currentUser.bnrId);
		if (!share) {
			console.warn('no share to toggle');
			return;
		}
		let apiUrl = '';
		if (document.readState === DokumentReadState.unread) {
			apiUrl = share.id + '/markAsRead';
		}
		if (document.readState === DokumentReadState.read) {
			apiUrl = share.id + '/markAsUnread';
		}
		if (apiUrl === '') {
			console.warn('invalid read state');
			return;
		}
		const res = await this.put(apiUrl, {});
		const data = res.data;
		runInAction(() => {
			document.doc.sharedWith = document.doc.sharedWith.map((s) => {
				if (s.id === share.id) {
					return new BnrDocumentShareModel(data);
				}
				return s;
			});
		});
	}
}
