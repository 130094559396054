export enum Einheit {
	nichts = 'nichts',
	stck = 'stck',
	std = 'stunden',
	bew = 'bew',
	minuten = 'minuten',
	proz = 'proz',
	bAS = 'bAS',
	anzGeplanMin5 = 'anzGeplanMin5',
	BSoZe = 'BSoZe',
	MBoD = 'MBoD',
	bDS = 'bDS',
	bBS = 'bBS',
	NMBT = 'NMBT',
	lfNST = 'lfNST',
	NMBToA = 'NMBToA',
	quali = 'quali',
}

interface IEinheitModel {
	id: string;
	shortText: string;
	longText: string;
}

export class EinheitModel {
	constructor(opts: IEinheitModel) {
		this.id = opts.id;
		this.shortText = opts.shortText;
		this.longText = opts.longText;
	}

	id: string;
	shortText: string;
	longText: string;
}

const units: IEinheitModel[] = [];

units.push({
	id: 'stck',
	shortText: 'Stk',
	longText: 'Stück',
});
units.push({
	id: 'stunden',
	shortText: 'Std',
	longText: 'Stunden',
});

units.push({
	id: 'bew',
	shortText: 'Bew',
	longText: 'Bewertung',
});

units.push({
	id: 'nichts',
	shortText: '',
	longText: '',
});
units.push({
	id: 'min',
	shortText: 'Min',
	longText: 'Minuten',
});

units.push({
	id: 'minuten',
	shortText: 'Min',
	longText: 'Minuten',
});

units.push({
	id: 'proz',
	shortText: '%',
	longText: 'Prozent',
});

units.push({
	id: 'BSoZe',
	shortText: 'BSoZe',
	longText: 'BS o. ZE',
});

units.push({
	id: 'bAS',
	shortText: 'bAS',
	longText: 'bAS',
});

units.push({
	id: 'anzGeplanMin5',
	shortText: '%',
	longText: 'Anzahl geplanter Agenturen min. 5%',
});

units.push({
	id: 'MBoD',
	shortText: 'MBoD',
	longText: 'MB o. Dynamik',
});

units.push({
	id: 'bDS',
	shortText: 'bDS',
	longText: 'bDS',
});

units.push({
	id: 'bBS',
	shortText: 'bBS',
	longText: 'bBS',
});

units.push({
	id: 'NMBT',
	shortText: 'NMBT',
	longText: 'N/M-BT',
});

units.push({
	id: 'lfNST',
	shortText: 'lfNST',
	longText: 'Lf. NST',
});

units.push({
	id: 'NMBToA',
	shortText: 'NMBToA',
	longText: 'N/M-BT o. Anp.',
});
units.push({
	id: 'quali',
	shortText: 'quali',
	longText: 'Qualitative Ziele',
});

export const Einheiten = units.map((x) => new EinheitModel(x));

const Unbekannt = new EinheitModel({
	id: 'unbekannt',
	shortText: 'unbekannt',
	longText: 'unbekannt',
});

export const FindEinheit = (e: Einheit) => {
	const res = Einheiten.find((x) => x.id === e.toString());
	if (res) {
		return res;
	}
	return Unbekannt;
};
