import { AgenturModel } from 'app/models/agentur.model';
import { AgtBestandPos5YearVM } from 'app/models/berichte/agt.bestand.pos.5year.vm';
import { AgtBestandPosModel } from 'app/models/berichte/agt.bestand.pos.model';
import { SegmentState } from 'app/models/segment/segment.model';
import _ from 'lodash';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import moment from 'moment';
import { AgenturStore } from '../agentur.store';
import { KvAgenturYearSegmentnModel, KvAgenturYearSegmentStore } from '../kv.agentur.year.segment.store';
import { SessionStore } from '../session.store';
import { AgtBestandUiStore, BESTANDPOS_ID } from './agt.bestand.ui.store';
import { AgtNeugeschaftUiStore, BAQNeugeschaeftPos } from './agt.neugeschaeft.ui.store';

export const SEG_BAQ_LOWER = 18.5;

export class AgtSegmentVM {
	constructor(agt: AgenturModel, eigenerSegment: KvAgenturYearSegmentnModel, baqs: AgtBestandPos5YearVM[], bestaende: AgtBestandPosModel[]) {
		this.agt = agt;
		this._eigenerSegment = eigenerSegment;
		this.eigenerSegement = eigenerSegment.valueString as SegmentState;
		this.year = eigenerSegment.year;
		this.baqs = [];
		if (baqs.length > 1) {
			console.log('got to mony baqs');
		}
		let _baqs = baqs[0].asArray;
		_baqs = _baqs.filter((b) => moment(b.bm).year() <= this.year);
		_baqs = _.sortBy(_baqs, 'b.bm').reverse();
		this.baqs = _baqs.slice(0, 3);

		bestaende = bestaende.filter((b) => moment(b.bm).year() <= this.year);
		bestaende = _.sortBy(bestaende, 'b.bm');
		this.bestaende = bestaende.slice(0, 3);
	}
	agt: AgenturModel;

	year: number;

	ergebnis?: number;
	_eigenerSegment: KvAgenturYearSegmentnModel;
	eigenerSegement: SegmentState;
	baqs: AgtBestandPosModel[];
	bestaende: AgtBestandPosModel[];
	z?: number;

	@computed
	get agtId() {
		return this.agt.agtId;
	}

	@computed
	get baq() {
		const count = this.baqs.length;
		const sum = _.sumBy(this.baqs, 'val.bt');
		if (count === 0) {
			return 0;
		}
		const baq = sum / count;
		return Math.round(baq * 100) / 100;
	}

	@computed
	get baq40Max() {
		if (this.baq > 40) {
			return 40;
		}
		return this.baq;
	}

	@computed
	get bestand() {
		const val = this.bestaende[0]; // nur vj wert interessant
		if (val && val.val && val.val.bt >= 0) {
			return Math.round(val.val.bt);
		}
		return 0;

		// const count = this.bestaende.length;
		// const sum = _.sumBy(this.bestaende, 'val.bt');
		// if (count == 0) {
		// 	return 0;
		// }
		// const bestand = sum / count;
		// return Math.round(bestand);
	}

	@computed
	get bestand3mMax() {
		if (this.bestand > 3000000) {
			return 3000000;
		}
		return this.bestand;
	}

	@computed
	get isAgenturStarterDate() {
		const gruendung = this.agt.vtnrBegin;
		if (!gruendung) {
			return false;
		}
		if (moment(gruendung).isBefore(moment('2019-01-01'))) {
			return false;
		}
		return true;
	}

	/*
		Unternehmer: BAQ >= 20 UND Bestand > 1000000
		Potential: BAQ >= 18,5, Bestand egal
		Breiten: BAQ < 18,5 UND Bestand >= 550000
		Entwicklung: BAQ < 18,5 UND Bestand < 550000
		Starter: Wie Entwicklung, ABER Gründung nach 01.01.2019

	*/

	@computed
	get calcSegement() {
		const baq = this.baq;
		const bestand = this.bestand;
		if (baq >= 20 && bestand > 1000000) {
			return SegmentState.unternehmer;
		}
		if (baq >= SEG_BAQ_LOWER) {
			return SegmentState.potential;
		}
		if (baq < SEG_BAQ_LOWER && bestand >= 550000) {
			return SegmentState.breitenagentur;
		}
		if (baq < SEG_BAQ_LOWER && bestand < 550000) {
			if (this.isAgenturStarterDate) {
				return SegmentState.starter;
			}
			return SegmentState.entwicklungsagentur;
		}
		return SegmentState.calcuated;
	}

	@computed
	get displaySegment() {
		if (this.eigenerSegement === SegmentState.calcuated) {
			return this.calcSegement;
		}
		return this.eigenerSegement;
	}
}

export class AgtSegmentUiStore {
	session: SessionStore;
	agenturStore: AgenturStore;
	kvAgenturYearSegmentStore: KvAgenturYearSegmentStore;
	agtNeugeschaftUiStore: AgtNeugeschaftUiStore;
	agtBestandUiStore: AgtBestandUiStore;

	constructor(
		session: SessionStore,
		agenturStore: AgenturStore,
		kvAgenturYearSegmentStore: KvAgenturYearSegmentStore,
		agtNeugeschaftUiStore: AgtNeugeschaftUiStore,
		agtBestandUiStore: AgtBestandUiStore,
	) {
		makeObservable(this);
		this.session = session;
		this.agenturStore = agenturStore;
		this.kvAgenturYearSegmentStore = kvAgenturYearSegmentStore;
		this.agtNeugeschaftUiStore = agtNeugeschaftUiStore;
		this.agtBestandUiStore = agtBestandUiStore;
	}

	onLogout() {
		this.loaded = false;
		this.items = [];
	}

	loaded: boolean = false;
	@observable items: AgtSegmentVM[] = [];
	async findByYear(year: number) {
		const items = await this.findAll();
		return items.filter((x) => x.year === year);
	}

	@computed
	get years() {
		const res = [];
		for (let y = 2022; y <= moment().year(); y++) {
			res.push(y);
		}
		return res;
	}

	async findByYearAndAgtId(year: number, agtId: number) {
		const items = await this.findAll();
		return items.find((i) => i.year === year && i.agtId === agtId);
	}

	async findAll() {
		if (this.loaded) {
			return this.items;
		}

		const promises = await Promise.all([
			this.kvAgenturYearSegmentStore.findAll(),
			this.agenturStore.findAll(),
			this.agtNeugeschaftUiStore.find5YearHistory(),
			this.agtBestandUiStore.find5YearHistory(),
		]);
		const eigeneItems = promises[0];
		const agts = promises[1];
		const neugeschaeft = promises[2];
		const bestaende = promises[3];

		const sachBesteande = bestaende.filter((b) => b.posId === BESTANDPOS_ID);

		const baqs = neugeschaeft.filter((n) => n.posDef.posId === BAQNeugeschaeftPos);

		const res: AgtSegmentVM[] = [];
		const bnrId = this.session.currentUser!.bnrId;

		agts.forEach((agt) => {
			this.years.forEach((year) => {
				let eigenesItem = eigeneItems.find((i) => i.agtId === agt.agtId && i.year === year);
				if (!eigenesItem) {
					eigenesItem = new KvAgenturYearSegmentnModel({
						agtId: agt.agtId,
						bnrId,
						bm: year,
					});
				}

				const agtBaqs = baqs.filter((b) => b.agtId === agt.agtId);
				const agtBestande = sachBesteande.filter((b) => b.agtId === agt.agtId);

				res.push(new AgtSegmentVM(agt, eigenesItem, agtBaqs, agtBestande));
			});
		});

		runInAction(() => {
			this.items = res;
		});

		return res;
	}

	// newAgtSchwerpunkt(agt: AgenturModel, year: number) {
	// 	return new AgtSegmentVM(
	// 		{
	// 			year: year,
	// 			agtId: agt.agtId,
	// 			bnrId: this.session.currentUser!.bnrId,
	// 		},
	// 		agt,
	// 	);
	// }

	async saveMany(data: AgtSegmentVM[]) {
		for (const item of data) {
			item._eigenerSegment.valueString = item.eigenerSegement;
			await this.kvAgenturYearSegmentStore.save(item._eigenerSegment);
		}
		this.loaded = false;
		this.items = [];
		this.findAll();
	}

	// async save(data: AgtSegmentVM) {
	// 	await this.agtYearStore.save(data);
	// 	runInAction(() => {
	// 		this.loaded = false;
	// 		this.items = [];
	// 	});
	// }
}
