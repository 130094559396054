import { AxiosInstance } from 'axios';
import { CurrentUserModel } from 'app/models/auth/current.user.model';
import { action, computed, makeObservable, observable } from 'mobx';

export class SessionStore {
	readonly axios: AxiosInstance;
	constructor(axios: AxiosInstance) {
		makeObservable(this);
		this.axios = axios;
		this.activeApiCallCount = 0;

		// load from local storage
		this.trackingEnabled = window.localStorage.getItem('tracking') === 'ENABLED';
		this.menuCollapse = window.localStorage.getItem('menuCollapse') === 'ENABLED';
		this.isSuperAdmin = window.localStorage.getItem('isSuperAdmin') === 'true';
	}

	trackingEnabled: boolean = true;
	setTrackingState(enabled: boolean) {
		this.trackingEnabled = enabled;
		window.localStorage.setItem('tracking', enabled ? 'ENABLED' : 'DISABLED');
	}

	@observable
	menuCollapse?: boolean = undefined;

	@action
	setMenuCollapseState(enabled: boolean) {
		this.menuCollapse = enabled;
		window.localStorage.setItem('menuCollapse', enabled ? 'ENABLED' : 'DISABLED');
	}

	@observable currentUser?: CurrentUserModel;

	@computed
	get currentSteart() {
		if (this.currentUser) {
			return this.currentUser.steart;
		}
		return undefined;
	}

	@computed
	get isLoggedIn() {
		return this.currentUser ? true : false;
	}

	token?: string = undefined;
	refreshToken?: string = undefined;
	lastTokenUpdate?: Date = undefined;
	isReadonlyToken: boolean = false;

	@computed
	get isAdmin() {
		return this.currentUser && this.currentUser.isAdmin;
	}


	@computed
	get isVbl() {
		return this.currentUser && this.currentUser.isVbl;
	}

	@computed
	get isGsl() {
		return this.currentUser && this.currentUser.isGsl;
	}

	@computed
	get isMulti() {
		return this.currentUser && this.currentUser.isMulti;
	}

	@observable isSuperAdmin: boolean = false;
	@action
	setIsSuperAdmin(isSuperAdmin: boolean) {
		this.isSuperAdmin = isSuperAdmin;
		localStorage.setItem('isSuperAdmin', isSuperAdmin ? 'true' : 'false');
	}

	clearLocalStorage() {
		localStorage.removeItem('isSuperAdmin');
		localStorage.removeItem('token');
		localStorage.removeItem('refreshToken');
		localStorage.removeItem('isReadonlyToken');
		this.isReadonlyToken = false;
	}

	_setTokensToLocalStorage(token: string, refreshToken: string) {
		localStorage.setItem('token', token);
		localStorage.setItem('refreshToken', refreshToken);
		this.lastTokenUpdate = new Date();
	}

	@observable
	activeApiCallCount: number = 0;
	@action
	incApiCallCount() {
		this.activeApiCallCount = this.activeApiCallCount + 1;
	}
	@action
	decApiCallCount() {
		if (!this) {
			return; // needed during startup
		}
		this.activeApiCallCount = this.activeApiCallCount - 1;
	}
	@computed
	get isApiBusy() {
		return this.activeApiCallCount > 0;
	}
}
