import { Einheit } from 'app/models/core/einheit.model';
import { BnrGpPosModel } from '../bnr.gp.pos.model';
import { PosUnDef } from '../gp';
const posDef: BnrGpPosModel[] = [];

posDef.push(
	new BnrGpPosModel({
		year: 2023,
		posId: 2310001,
		group: 'gwp',
		posText: 'Gesetzliche Weiterbildungspflicht',
		planKey: 'idd',
		einheit: Einheit.bew,
		posDesc: `Voraussetzung für die Bonifikation ist der Nachweis von 15 Weiterbildungsstunden im Geschäftsjahr`,
	}),
);

posDef.push(
	new BnrGpPosModel({
		year: 2023,
		posId: PosUnDef,
		group: 'ausMaxi',
		posText: 'Unterstützung bei der Einführung der neuen Tools im Rahmen des Rollouts von AMIS.Now',
		planKey: 'amisnow',
		einheit: Einheit.quali,
		posDesc: `Befähigung von Vertretungen und Vertriebseinheiten im Verantwortungsbereich. Unterstützung bei der Einrichtung vor Ort bei Bedarf.`,
	}),
);
posDef.push(
	new BnrGpPosModel({
		year: 2023,
		posId: PosUnDef,
		group: 'ausMaxi',
		posText: 'Einsatz digitaler Instrumente',
		planKey: 'digi_instr',
		einheit: Einheit.quali,
		posDesc: `Befähigung von Vertretungen und Vertriebseinheiten im Verantwortungsbereich. Ausbau der Nutzung
		von digitalen Anwendungen, die von der Gesellschaft unterstützt und freigeben sind,
		entlang der strategischen Ziele der Gesellschaft.`,
	}),
);
posDef.push(
	new BnrGpPosModel({
		year: 2023,
		posId: PosUnDef,
		group: 'ausMaxi',
		posText: 'Agenturprozesse und Agenturadministration',
		planKey: 'agtproz_i',
		einheit: Einheit.quali,
		posDesc: `Multiplikator:in und Ansprechpartner:in im Verant- wortungsbereich für die Gestaltung effizienter Agenturabläufe und -prozesse. Verantwortung
		für die Etablierung von neuen Anwendungen durch gezielte Einsteuerung von Neuerungen und Best-Practice.`,
	}),
);

posDef.push(
	new BnrGpPosModel({
		year: 2023,
		posId: 2310100,
		group: 'max8k',
		posText: 'Neuanmeldungen Meine Allianz (E-Mailadressen und Mobilnummern)',
		planKey: 'neunanmeldungen_maz',
		einheit: Einheit.proz,
		posDesc: `Anzahl Erfüller der Position "Meine Allianz" im Geschäftsplan der GV/HV`,
	}),
);
posDef.push(
	new BnrGpPosModel({
		year: 2023,
		posId: 2310105,
		group: 'max8k',
		posText: 'Neue Digi-ID im Bestand',
		planKey: 'digiid_bestand',
		einheit: Einheit.proz,
		posDesc: `Umwandlung Kund:innen ohne Digi ID in Kund:innen mit Digi ID im Bestand. Basis Jahresendbestand Vorjahr`,
	}),
);
posDef.push(
	new BnrGpPosModel({
		year: 2023,
		posId: 2310125,
		group: 'max8k',
		posText: 'Steigerung des Kundenvertrauens durch Kundenbewertungsinstrumente (Allianz Empfehlen)',
		planKey: 'steigerung_kundenvertrauen_ii',
		einheit: Einheit.proz,
		posDesc: `Einsatz "Allianz Empfehlen". 
		Anzahl Leads aus dem Programm "Allianz Empfehlen" umgelegt auf alle Agenturen in der
		VD im Zeitraum 01.01.2023 bis 31.12.2023. (Stichtag Gesamtagenturanzahl 01.01.2023)`,
	}),
);
posDef.push(
	new BnrGpPosModel({
		year: 2023,
		posId: 2310130,
		group: 'max8k',
		posText: 'Förderung der Digitalisierungsstrategie im Verantwortungsbereich',
		planKey: 'digitalisierungsstrategie',
		einheit: Einheit.proz,
		posDesc: `Steigerung des Kundenvertrauens durch Ein- bindung Online Beratung auf der Vertreter Home- page gegenüber dem Jahresendstand 2022`,
	}),
);
posDef.push(
	new BnrGpPosModel({
		year: 2023,
		posId: 2310140,
		group: 'max8k',
		posText: 'Voicegate in der jeweiligen VD',
		planKey: 'voicegate',
		einheit: Einheit.proz,
		posDesc: `Steigerung der Registrierungsquote von Voicegate für die Vertreter:innen und deren Agenturmitarbeiter:innen`,
	}),
);
posDef.push(
	new BnrGpPosModel({
		year: 2023,
		posId: PosUnDef,
		group: 'max8k',
		posText: 'Bewerbung der web-DSE in der VD',
		planKey: 'webdse',
		einheit: Einheit.quali,
		posDesc: `Bekanntmachung der Themen- und Aufgaben- schwerpunkte der web-DSE, Forcierung, Förderung der direkten Nutzung`,
	}),
);

export const EdpPositionen = posDef;
