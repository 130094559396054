import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { BnrBranchenplanungSummary } from './BnrBranchenplanungSummary';

export const BranchenplanungZielerreichung = observer(() => {
	const { anwendungenUiStore } = useStore();
	return (
		<>
			{!anwendungenUiStore.selectedAgt && (
				<>
					<BnrBranchenplanungSummary year={anwendungenUiStore.currentBpYear} includeZe={true} />
				</>
			)}
		</>
	);
});
