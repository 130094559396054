import { DottedTag, TagColors } from 'app/components/common/DottedTag';
import { Table } from 'app/components/table/table';
import { AggregrationType, TableModel, TableRow } from 'app/components/table/table.model';
import { useStore } from 'app/context';
import { AgtClusterVM } from 'app/stores/ui/agt.cluster.ui.store';
import { formatPercentWithIcon } from 'app/utils';
import { observer } from 'mobx-react';
import { useEffect, useMemo } from 'react';

interface IAgenturClusterListe {
	year: number;
}

export const AgenturClusterListe = observer((props: IAgenturClusterListe) => {
	const { agtClusterUiStore, berichteUiiStore, session } = useStore();

	const tm = useMemo(() => {
		const getAgg = (path: string, name: string) => {
			return [
				{
					path,
					format: formatPercentWithIcon,
					aggType: AggregrationType.percentCountOfTotal,
					aggIf: (val: any) => {
						return val >= 1 && val < 1.5;
					},
					aggCustomSymbol: name === session.currentSteart?.name ? <DottedTag color={TagColors.turquoise} text={'1'} /> : <span className="dotted-tag-vertical-spacer" />,
					aggModifier: 'is-centered',
				},
				{
					path,
					format: formatPercentWithIcon,
					aggType: AggregrationType.percentCountOfTotal,
					aggIf: (val: any) => {
						return val >= 1.5 && val < 2.5;
					},
					aggCustomSymbol: name === session.currentSteart?.name ? <DottedTag color={TagColors.purple} text={'2'} /> : <span className="dotted-tag-vertical-spacer" />,
					aggModifier: 'is-centered',
				},
				{
					path,
					format: formatPercentWithIcon,
					aggType: AggregrationType.percentCountOfTotal,
					aggIf: (val: any) => {
						return val >= 2.5;
					},
					aggCustomSymbol: name === session.currentSteart?.name ? <DottedTag color={TagColors.yellow} text={'3'} /> : <span className="dotted-tag-vertical-spacer" />,
					aggModifier: 'is-centered',
				},
			];
		};

		const tm = new TableModel<AgtClusterVM, number>();
		const cols = [
			{
				label: 'Agentur',
				path: 'agt.name',
				render: AgtClusterName,
				cellModifier: '',
				labelModifier: '',
			},
		];

		const steartCols = agtClusterUiStore.steartCols;
		for (const col of steartCols) {
			cols.push({
				label: col.name,
				path: col.path,
				cellModifier: col.name === session.currentSteart?.name ? 'bg-blue has-text-centered' : 'bg-grey has-text-centered',
				labelModifier: col.name === session.currentSteart?.name ? 'bg-blue has-text-centered' : 'bg-grey has-text-centered',
				// @ts-ignore
				agg: getAgg(col.path, col.name),
				render: (row) => <AgtClusterTableDot row={row} path={col.path} />,
			});
		}

		tm.setCols(cols);
		tm.sortBy = 'agt.name';
		tm.idProperty = 'id';
		tm.idType = 'number';
		return tm;
	}, [agtClusterUiStore.steartCols, session]);

	useEffect(() => {
		agtClusterUiStore.findAllByYear(props.year).then((items) => {
			if (berichteUiiStore.selectedAgt) {
				const agtId = berichteUiiStore.selectedAgt.agtId;
				items = items.filter((d) => d.agt.agtId === agtId);
			}
			tm.setRowData(items);
		});
	}, [tm, agtClusterUiStore, berichteUiiStore.selectedAgt, props.year]);

	return (
		<>
			<div className="table-container">
				<Table tm={tm} stickyHeader={true}></Table>

				<div className="pad-1rem">
					<div className="note-box content">
						<div className="note-message">
							<p>
								<strong>Legende</strong>
							</p>
							<p>Segmentierung der Agenturen nach Umfang des Betreuungsbedarfst</p>

							<div className="grid is-col-auto justify-start pad-bottom-1rem">
								<DottedTag color={TagColors.turquoise} text={'1'} />
								<div>
									<strong>Vor Ort und Remote</strong> <br />
									Aktive Betreuung der Agenturen, inklusive regelmäßige Vorort-Termine mit GS-Spezialisten.
								</div>
							</div>

							<div className="grid is-col-auto justify-start pad-bottom-1rem">
								<DottedTag color={TagColors.purple} text={'2'} />
								<div>
									<strong>Überwiegend Remote</strong> <br />
									Aktive Betreuung der Agenturen, vor allem remote durch den GS-Spezialisten.
								</div>
							</div>

							<div className="grid is-col-auto justify-start pad-bottom-1rem">
								<DottedTag color={TagColors.yellow} text={'3'} />
								<div>
									<strong>Grundsätzlich DSE</strong> <br />
									Passive / reaktive Betreuung der Agenturen, wobei die DSE-Spezialisten erster Ansprechpartner sind
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
});

const AgtClusterName = (row: TableRow<AgtClusterVM>) => {
	let col = TagColors.turquoise;
	const avg = Math.round(row.data.avg || 2);

	if (avg >= 2) {
		col = TagColors.purple;
	}
	if (avg >= 3) {
		col = TagColors.yellow;
	}

	return (
		<>
			{row.data.avg && (
				<>
					<DottedTag color={col} modifier="is-compact" text={`${row.data.agt.name} (${row.data.agt.agtId})`} />
				</>
			)}
		</>
	);
};

interface IAgtClusterTableDot {
	row: TableRow<AgtClusterVM>;
	path: string;
}
const AgtClusterTableDot = (props: IAgtClusterTableDot) => {
	const { row, path } = props;
	//@ts-ignore
	const value = row.data[path];

	let col = TagColors.turquoise;

	if (value >= 2) {
		col = TagColors.purple;
	}
	if (value >= 3) {
		col = TagColors.yellow;
	}

	return <DottedTag color={col} text={value} />;
};
