import parse from 'html-react-parser';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import sanitizeHtml from 'sanitize-html';
import Catch from './functional-error-boundary';

const ErrorBoundary = Catch(function MyErrorBoundary(props: any, error?: Error) {
	const [content, setContent] = useState<any>();
	useEffect(() => {
		try {
			if (props.content) {
				let c = sanitizeHtml(props.content) as any;
				c = parse(c);
				setContent(c as any);
			}
		} catch (err) {
			setContent('error');
		}
	}, [props.content]);

	if (error) {
		return (
			<>
				<span className="html-content">{content}</span>;
			</>
		);
	} else {
		return <React.Fragment>{props.children}</React.Fragment>;
	}
});

interface IHtmlContent {
	content?: string;
}

export const HtmlContent = observer((props: IHtmlContent) => {
	const [content, setContent] = useState<any>();
	useEffect(() => {
		try {
			if (props.content) {
				let c = parse(props.content);
				setContent(c as any);
			}
		} catch (err) {
			try {
				if (props.content) {
					let c = sanitizeHtml(props.content) as any;
					c = parse(c);
					setContent(c as any);
				}
			} catch (err) {
				setContent('Fehler: Inhalt konnte nicht dargestellt werden');
			}
		}
	}, [props.content]);

	if (props.content) {
		return (
			<ErrorBoundary content={props.content}>
				<span className="html-content">{content}</span>
			</ErrorBoundary>
		);
	}

	return (
		<div className="html-content is-empty">
			<span>Kein Inhalt</span>
		</div>
	);
});
