import _ from 'lodash';
import { action, computed, IObservableArray, makeObservable, observable, runInAction } from 'mobx';
import { AdminTemplatesStore } from '../admin.templates.store';
import { BnrTemplateModel } from '../bnr.template.store';
import { TemplateVM } from './template.ui.store';

export class AdminTemplatesUiStore {
	adminTemplateStore: AdminTemplatesStore;

	constructor(adminTemplateStore: AdminTemplatesStore) {
		makeObservable(this);
		this.adminTemplateStore = adminTemplateStore;
	}

	@observable current?: TemplateVM;

	@computed
	get currentItems() {
		let items = this._items.map((i) => i);
		return _.sortBy(items, (r) => r.template.created).reverse();
	}

	_items: IObservableArray<TemplateVM> = observable([]);
	loaded: boolean = false;
	async load() {
		if (this.loaded) {
			return this._items;
		}

		const items = await this.adminTemplateStore.findAll();

		const res: TemplateVM[] = [];

		items.forEach((t) => {
			const vm = new TemplateVM({ template: t });
			res.push(vm);
		});
		runInAction(() => {
			this._items.replace(res);
			this.loaded = true;
		});

		return this._items;
	}

	@action
	async loadById(id?: string) {
		if (!id) {
			this.current = undefined;
		}
		const templates = await this.findAll();
		const u = templates.find((u) => u.template.id === id);
		runInAction(() => {
			this.current = u;
			this.newTemplate = undefined;
		});
	}
	@observable
	newTemplate?: TemplateVM;

	@action
	createNewTemplate() {
		const template = new BnrTemplateModel({
			bnrId: 0,
			created: new Date(),
			modified: new Date(),
			disabled: false,
			contentJson: JSON.stringify({
				title: 'Neue Vorlage',
				text: ' Hier etwas text eingeben',
				visible: false,
				category: '',
			}),
		});
		this.current = undefined;
		this.newTemplate = new TemplateVM({ template });
	}

	@action
	async save(template: TemplateVM) {
		await this.adminTemplateStore.save(template.template);
		this.reload();
	}

	@action
	async reload() {
		this.loaded = false;
		await this.load();
	}

	async findAll() {
		return this.load();
	}
}
