import { Button } from 'app/components/common/Button';
import { Icon } from 'app/components/common/Icon';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import * as React from 'react';

interface IProps {
	header?: React.ReactNode;
	title: string;
	subtitle?: string;
	classModifier?: string;
	onClose: () => void;
	children?: React.ReactNode;
}

const Panel: React.FC<IProps> = observer(({ title, subtitle, children, header, classModifier, onClose }) => {
	const { uiStore, session } = useStore();

	const closePanel = () => {
		if (uiStore.fullScreenPanel) {
			uiStore.toggleFullScreenPanel();
		}
		onClose();
	};

	// useEscape(() => {
	// 	closePanel();
	// });

	return (
		<div className={`panel side-panel ${classModifier ? classModifier : ''} ${uiStore.fullScreenPanel ? 'is-fullscreen' : ''} ${session.menuCollapse ? 'is-wide' : ''}`}>
			{header && (
				<div className="header">
					<div className="header-left">
						<Button className="button is-icon is-close" onClick={closePanel} autoFocus={true}>
							<Icon iconClass="times" />
						</Button>

						<div className="title-area">
							<div className="title">{title}</div>
							{subtitle && <div className="subtitle">{subtitle}</div>}
						</div>
					</div>
					<div className="header-right">{header}</div>
				</div>
			)}
			<div className="body">{children}</div>
		</div>
	);
});

export default Panel;
