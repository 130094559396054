import { FormActionButton } from '../leadnowcheck/leadnow.check.form.item';
import { ICoronaFormItem } from './corona.form.items';
import { CoronaKVModel } from './corona.kv.model';

export class CoronaCheckFormItem {
	constructor(form: ICoronaFormItem, items: CoronaKVModel[]) {
		const item = items.find((i) => i.key === form.key);
		if (item) {
			this.data = item;
		} else {
			this.data = new CoronaKVModel(form);
		}

		this.label = form.label;
		this.details = form.details;
		if (form.visible !== undefined) {
			this.visible = form.visible;
		} else {
			this.visible = true;
		}

		if (form.editable !== undefined) {
			this.editable = form.editable;
		} else {
			this.editable = true;
		}

		if (form.pro3Paket2 !== undefined) {
			this.pro3Paket2 = form.pro3Paket2;
		} else {
			this.pro3Paket2 = true;
		}
		this.support = form.support;
		if (form.actionButton) {
			this.actionButton = new FormActionButton(form.actionButton);
		}
	}
	data: CoronaKVModel;
	label: string;
	details?: string;
	support: string;
	visible: boolean;
	editable: boolean;
	pro3Paket2: boolean;
	actionButton?: FormActionButton;
}
