import { Button } from 'app/components/common/Button';
import { Modal } from 'app/components/Modal';
import { useStore } from 'app/context';
import { ThemaModel, ThemenGroupModel } from 'app/models/brandenburger-tor/brandeburger.tor.data';
import { PlanThemenTreeVM } from 'app/stores/ui/agt.plan.themen.ui.store';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import FormField from 'app/components/form/FormField';
import { Icon } from 'app/components/common/Icon';

interface IThemenGroupItem {
	tree: PlanThemenTreeVM;
	group: ThemenGroupModel;
}

export const AddEigenesThemaButton = observer((props: IThemenGroupItem) => {
	const { uiStore } = useStore();
	const openAddThemaModal = () => {
		uiStore.showModal(uiStore.modalIds.addEigenesThema);
	};
	return (
		<>
			<div className="themen-list-item add-custom-item">
				<Button className="button" onClick={openAddThemaModal}>
					<span>Eigenes Thema hinzufügen</span>
					<Icon iconClass="plus-circle" faModifier="far" />
				</Button>
			</div>
		</>
	);
});

const addEigenesThemaSchama = Yup.object({
	thema: Yup.string().trim().required('Bitte geben Sie einen Namen für das Thema ein').default(''),
}).required();

type EigenesThema = Yup.InferType<typeof addEigenesThemaSchama>;

export const AddEigenesThemaModal = observer((props: IThemenGroupItem) => {
	const { uiStore } = useStore();
	const modaliId = uiStore.modalIds.addEigenesThema;
	const group = props.group;
	async function handleSubmit(values: EigenesThema) {
		const eigenesThema = new ThemaModel({
			key: values.thema,
			title: values.thema,
			children: [], // keine handlungsempfehlnge
		});
		runInAction(() => {
			eigenesThema.isEigenesThema = true;
			group.children.push(eigenesThema);
		});
		onClose();
	}
	const onClose = () => {
		uiStore.hideModal(uiStore.modalIds.addEigenesThema);
	};

	return (
		<Modal modalId={modaliId} title={'Eigenes Thema hinzufügen'}>
			<Formik
				validationSchema={addEigenesThemaSchama}
				initialValues={{
					thema: '',
				}}
				onSubmit={handleSubmit}
			>
				{({ errors, touched, isValid }) => {
					return (
						<Form>
							<div>
								<FormField error={errors.thema} touched={touched.thema} label="Themenbezeichnung">
									<Field type="text" name="thema" placeholder="Themenbezeichnung" />
								</FormField>
							</div>

							<div className="modal-footer">
								<Button type="submit" className="button is-primary" isFormInvalid={!isValid}>
									Übernehmen
								</Button>
								<Button type="button" className="button is-secondary" onClick={onClose}>
									Abbrechen
								</Button>
							</div>
						</Form>
					);
				}}
			</Formik>
		</Modal>
	);
});
