import { BaseEntityStore } from './core/base.entity.store';

export interface INotifcationData {
	testEmailTo?: string;
	testBensl?: string;
	subject: string;
	body?: string;
	sendLive: boolean;
}

export class AdminNotificationsStore extends BaseEntityStore<Object> {
	apiPath = 'api/admin/email';
	idProp: string = 'id';
	createInstances(opts: any): Object {
		return opts;
	}

	async sendNotification(data: INotifcationData) {
		await this.callRefreshTokenIfNecessary();
		const head = this.getAuthHeader();
		const res = await this.axios({
			url: this.apiPath + '/notification',
			method: 'POST',
			headers: head.headers,
			data,
		});
		return res.data;
	}
}
