import Widget from 'app/components/common/Widget';
import { Table } from 'app/components/table/table';
import { ITableColumn, TableModel } from 'app/components/table/table.model';
import { useStore } from 'app/context';
import { trackingRoles } from 'app/stores/ui/admin.usage.ui.store';
import { observer } from 'mobx-react';
import { useEffect, useMemo } from 'react';

export const AdminUsageLoginsProRolle = observer(() => {
	const { adminUsageUiStore } = useStore();

	useEffect(() => {
		adminUsageUiStore.loadLoginData();
	}, [adminUsageUiStore]);

	return (
		<>
			<AdminUsageLoginsProRolleJeVd />
			{adminUsageUiStore.currentFilter.vdId && <AdminUsageLoginsProRolleJeGs />}
		</>
	);
});

export const AdminUsageLoginsProRolleJeVd = observer(() => {
	const { adminUsageUiStore } = useStore();

	const tm = useMemo(() => {
		const tm = new TableModel<any, number>();
		let cols: ITableColumn<any, number>[] = [
			{
				label: 'VD',
				path: 'vd',
			},
		];

		trackingRoles.forEach((role) => {
			cols.push({
				label: role.name.toUpperCase(),
				path: role.name.toLowerCase(),
			});
		});

		cols.push({
			label: 'Summe',
			path: 'sum',
		});

		cols.push({
			label: 'Score',
			path: 'score',
		});

		tm.setCols(cols);
		tm.sortBy = 'vd';
		tm.idProperty = 'id';
		tm.idType = 'string';
		return tm;
	}, []);

	useEffect(() => {
		tm.setRowData(adminUsageUiStore.loginsProRolleJeVd);
	}, [adminUsageUiStore.loginsProRolleJeVd, tm]);

	return (
		<>
			<div className="pad-1rem">
				<Widget title="Logins: Rolle / VD" modifier="is-paddingless" action={<span className="tag is-red">Pro</span>}>
					<Table tm={tm} />
				</Widget>
			</div>
		</>
	);
});

export const AdminUsageLoginsProRolleJeGs = observer(() => {
	const { adminUsageUiStore } = useStore();

	const tm = useMemo(() => {
		const tm = new TableModel<any, number>();
		let cols: ITableColumn<any, number>[] = [
			{
				label: 'GS',
				path: 'gs',
			},
		];
		trackingRoles.forEach((role) => {
			cols.push({
				label: role.name.toUpperCase(),
				path: role.name.toLowerCase(),
			});
		});

		cols.push({
			label: 'Summe',
			path: 'sum',
		});

		cols.push({
			label: 'Score',
			path: 'score',
		});

		tm.setCols(cols);
		tm.sortBy = 'gs';
		tm.idProperty = 'id';
		tm.idType = 'string';
		return tm;
	}, []);

	useEffect(() => {
		tm.setRowData(adminUsageUiStore.loginsProRolleJeGs);
	}, [adminUsageUiStore.loginsProRolleJeGs, tm]);

	return (
		<>
			<div className="pad-1rem">
				<Widget title="Logins: Rolle / GS" modifier="is-paddingless" action={<span className="tag is-red">Pro</span>}>
					<Table tm={tm} />
				</Widget>
			</div>
		</>
	);
});
