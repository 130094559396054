export class GebietModel {
	constructor(opts: any) {
		this.inspId = opts.inspId;
		this.vgbGsnr = opts.vgbGsnr;
		this.vgbInspnr = opts.vgbInspnr;
		this.vgbStufe0Id = opts.vgbStufe0Id;
		this.vgbStufe0Text = opts.vgbStufe0Text;
		this.vgbStufe1Id = opts.vgbStufe1Id;
		this.vgbStufe1Text = opts.vgbStufe1Text;
		this.vgbStufe2Id = opts.vgbStufe2Id;
		this.vgbStufe2Text = opts.vgbStufe2Text;
		this.vgbVbnr = opts.vgbVbnr;
	}

	inspId: number;
	vgbGsnr: number;
	vgbInspnr: number;
	vgbStufe0Id: number;
	vgbStufe0Text: string;
	vgbStufe1Id: number;
	vgbStufe1Text: string;
	vgbStufe2Id: number;
	vgbStufe2Text: string;
	vgbVbnr: number;
}

// export class GebieteStore extends BaseCachedEntityStore<GebietModel> {
// 	apiPath = 'api/gebiete';
// 	idProp: string = 'inspId';
// 	createInstances(opts: any): GebietModel {
// 		return new GebietModel(opts);
// 	}
// 	async findAll() {
// 		console.warn('Too much data!!');
// 		return [];
// 	}
// }
