import { BaseCachedEntityStore } from './core/base.cached.entity.store';
import { UserModel } from 'app/models/core/user.model';

export class UserStore extends BaseCachedEntityStore<UserModel> {
	apiPath = 'api/user';
	idProp: string = 'userId';

	createInstances(opts: any): UserModel {
		return new UserModel(opts);
	}

	async findById(id: number): Promise<UserModel | undefined> {
		await this.findAll();
		return this._items.find((x) => x.bnrId === id);
	}
}
