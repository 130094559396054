import BaseContainer from 'app/containers/BaseContainer';
import { observer } from 'mobx-react';
import { MainMenu } from './MainMenu';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useStore } from 'app/context';
import { AdminUsersList } from 'app/components/admin/users/AdminUsersList';
import React, { useEffect } from 'react';
import { AdminUsersFilter } from 'app/components/admin/users/AdminUsersFilter';
import { AdminNewStandardUserModal } from 'app/components/admin/users/AdminNewStandardUserForm';
import { AdminUserDetail } from 'app/components/admin/users/AdminUserDetail';
import { AdminNewAdminUserButton } from 'app/components/admin/users/AdminNewAdminUserButton';
import { Button } from 'app/components/common/Button';
import { EnsureAdmin } from './AdminHomeContainer';
import { notify } from 'app/components/common/notify';
import { AdminNewMultiModal } from 'app/components/admin/users/AdminNewMultiUserForm';
import { AdminUpdateOrgaModal } from 'app/components/admin/users/AdminUpdateOrgaModal';

export interface AdminUsersParamTypes {
	userId?: string;
	userType?: string;
	bensl?: string;
	steartId?: string;
}

const UserActions = () => {
	return (
		<>
			<AdminNewStandardUserModal />
			<AdminNewAdminUserButton />
			<AdminNewMultiModal />

		</>
	);
};

const AdminUsersContainer = observer((props: RouteComponentProps) => {
	const { session, adminUserUiStore } = useStore();

	const currentUser = session.currentUser;

	const { userId, userType, bensl, steartId } = useParams<AdminUsersParamTypes>();

	const AdminUsersNavbar = observer(() => {
		return (
			<>
				<div className="admin-navbar">
					<div className="is-left">
						<strong>Benutzer</strong>
					</div>
				</div>
			</>
		);
	});

	const AdminActions = observer(() => {
		return (
			<>
				<div className="admin-actions">
					<AdminUpdateOrgaModal />
					<Button type="button" className="button is-small" onClick={updateAgtGeo}>
						Geo Update
					</Button>
					<Button type="button" className="button is-small" disabled={false} onClick={triggerImport}>
						Multi User Import 2023 auslösen
					</Button>
					<Button type="button" className="button is-small" disabled={adminUserUiStore.invitableUserCount === 0} onClick={bulkSendMail}>
						Einladungsmail an {adminUserUiStore.invitableUsers.length} Benutzer schicken
					</Button>
					<div className="divider"></div>
					<UserActions />

					<Button type="button" className="button is-small" onClick={emptyCache}>
						Cache leeren
					</Button>
					<Button type="button" className="button is-small" onClick={fillCache}>
						Cache auffüllen
					</Button>
				</div>
			</>
		);
	});

	// load or create the user model
	useEffect(() => {
		if (userId) {
			if (userType) {
				if (bensl) {
					adminUserUiStore.newUser(userId, userType, bensl, steartId);
				} else {
					adminUserUiStore.newUser(userId, userType, '', steartId);
				}
			} else {
				adminUserUiStore.loadUserById(userId);
			}
		}
	}, [adminUserUiStore, userId, userType, bensl, steartId]);

	const triggerImport = () => {
		if (window.confirm('Sicher?? Kann mann nicht ohne viele Mühe rückgängig machen..') !== true) {
			return;
		}
		adminUserUiStore.triggerImport();
	};

	const bulkSendMail = () => {
		if (adminUserUiStore._items.length === adminUserUiStore.currentItems.length) {
			alert('Bitte Filter setzen. ');
			return;
		}
		adminUserUiStore.bulkSendMail();
	};



	const updateAgtGeo = () => {
		adminUserUiStore.adminUserStore.updateAgtGeo();
		notify('agt geo', 'agt geo update gestartet');
	};

	const emptyCache = () => {
		adminUserUiStore.adminUserStore.emptyCache();
		notify('agt geo', 'cache wurde gelöscht');
	};

	const fillCache = () => {
		adminUserUiStore.adminUserStore.fillCache();
		notify('agt geo', 'cache wird aufgefüllt');
	};

	return (
		<BaseContainer classModifier="admin-container admin-users-container" sideMenu={<MainMenu {...props} />} navbar={<AdminUsersNavbar />}>
			<EnsureAdmin />
			<AdminActions />
			{currentUser && (
				<>
					<div className="table-container">
						<AdminUserDetail />
						<AdminUsersFilter />

						<AdminUsersList />
					</div>
				</>
			)}
		</BaseContainer>
	);
});

export default AdminUsersContainer;
