import { Button } from 'app/components/common/Button';
import { notify } from 'app/components/common/notify';
import { IModalButton, Modal } from 'app/components/Modal';
import { useStore } from 'app/context';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { AgtPlanungThemenAuswahl } from './AgtPlanungThemenAuswahl';

export const AgtPlanungThemenAuswahlModal = observer(() => {
	const { uiStore, agtPlanThemenUiStore } = useStore();
	const modalId = uiStore.modalIds.apThemenAuswahl;

	const onSave = async () => {
		await agtPlanThemenUiStore.saveThemen();
		runInAction(() => {
			uiStore.apThemenEdit.enabled = false;
			uiStore.hideModal(modalId);
		});
		notify('Themen gespeichert', '', 'info');
	};

	const onClose = async () => {
		await agtPlanThemenUiStore.cancelThemenSave();
		runInAction(() => {
			uiStore.apThemenEdit.enabled = false;
			uiStore.hideModal(modalId);
		});
	};

	const footerButton: IModalButton[] = [
		{
			title: 'Speichern',
			className: 'button is-primary',
			onClick: onSave,
		},
		{
			title: 'Abbrechen',
			className: 'button',
			onClick: onClose,
		},
	];

	return (
		<>
			<Modal modalId={modalId} title={'Themen und Maßnahmen'} onClose={onClose} size="fullscreen" buttons={footerButton}>
				{agtPlanThemenUiStore.themenTree && (
					<>
						<AgtPlanungThemenAuswahl tree={agtPlanThemenUiStore.themenTree} />
					</>
				)}

				{!agtPlanThemenUiStore.themenTree && (
					<>
						<div className="content">
							<p>Keine Planung gefunden</p>
						</div>
						<Button className="button" onClick={onClose}>
							Schließen
						</Button>
					</>
				)}
			</Modal>
		</>
	);
});
