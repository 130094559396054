import { Steart } from 'app/models/core/steart.model';
import { ZeWidgetDef, ZeWidgetItem } from './vbl.ze.widgets';

export const BbsZeWidgets: ZeWidgetDef[] = [
	new ZeWidgetDef({
		title: 'Baufinanzierung Allianz Leben und Bausparen',
		subTitle: 'Bewertung',
		key: 'ZE_FirmenSachGesamt_BFG',
		stearts: [Steart.BBS],
		items: [
			new ZeWidgetItem({
				text: <strong>Summe der Zielerfüllungen im individuellen Arbeitsgebiet 190%</strong>,
				posId: 2245043,
				einheit: '%',
			}),
			new ZeWidgetItem({
				text: <strong>Mindestens in Baufinanzierung Allianz Leben 90%</strong>,
				posId: 2245041,
			}),
			new ZeWidgetItem({
				text: <strong>Mindestens in Bausparen 90%</strong>,
				posId: 2245042,
			}),
		],
	}),
	new ZeWidgetDef({
		title: 'oder Baufinanzierung Allianz Leben und Bausparen',
		subTitle: 'Anzahl Vertriebseinheiten mit mindestens 6.500 (4.000 VG NO) Bewertung in Baufinanzierung Allianz Leben bzw. 3.000 Bewertung in Bausparen',
		key: 'ZE_FirmenSachGesamt_bewertung',
		stearts: [Steart.BBS],
		items: [
			new ZeWidgetItem({
				text: <strong>Mindestens in Baufinanzierung Allianz Leben / Bausparen 25%</strong>,
				posId: 2245048,
				einheit: '%',
			}),
			new ZeWidgetItem({
				text: <strong>Mindestens in Baufinanzierung Allianz Leben 13%</strong>,
				posId: 2245046,
				einheit: '%',
			}),
			new ZeWidgetItem({
				text: <strong>Mindestens in Bausparen 8%</strong>,
				posId: 2245047,
				einheit: '%',
			}),
		],
	}),
];
