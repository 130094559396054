import { KvAgtMsModel } from 'app/models/kv.agt.ms.model';
import { SegmentState } from 'app/models/segment/segment.model';
import { computed } from 'mobx';
import { BaseCachedEntityStore } from './core/base.cached.entity.store';

export class KvAgenturYearSegmentnModel extends KvAgtMsModel {
	constructor(opts: any) {
		super(opts);
		if (!this.group) {
			this.group = 'segment';
		}
		if (!this.key) {
			this.key = 'segment';
		}
		if (!this.valueString) {
			this.valueString = SegmentState.calcuated;
		}
	}

	@computed
	get state() {
		return this.valueString as SegmentState;
	}

	@computed
	get year() {
		return this.bm;
	}
}

export class KvAgenturYearSegmentStore extends BaseCachedEntityStore<KvAgenturYearSegmentnModel> {
	idProp: string = 'id';
	apiPath = 'api/kv_agentur_ms/segment';

	createInstances(opts: any): KvAgenturYearSegmentnModel {
		return new KvAgenturYearSegmentnModel(opts);
	}

	disableFindForStearts = [123];

	async saveMany(items: KvAgenturYearSegmentnModel[]) {
		return this.post('many', items);
	}
}
