import { BaseEntityStore } from './core/base.entity.store';

export class BnrStatsModel {
	constructor(opts: any) {
		this.ABV_DF_Avg = opts.ABV_DF_Avg;
		this.GS = opts.GS;
		this.GS_DF_Avg = opts.GS_DF_Avg;
		this.VD = opts.VD;
		this.VD_DF_Avg = opts.VD_DF_Avg;
	}

	ABV_DF_Avg: number = 0;
	GS: string = '';
	GS_DF_Avg: number = 0;
	VD: string = '';
	VD_DF_Avg: number = 0;
}

export class BnrStatsStore extends BaseEntityStore<BnrStatsModel> {
	apiPath = 'api/vbl/stats';
	idProp: string = 'foo';
	disableFindForStearts = [123];

	loaded: boolean = false;
	stats: BnrStatsModel = new BnrStatsModel({});

	createInstances(opts: any): BnrStatsModel {
		return new BnrStatsModel(opts);
	}

	async load() {
		const res = await this.findAll();
		this.stats = res[0];
		this.loaded = true;
	}
	onLogout() {
		this.loaded = false;
		this.stats = new BnrStatsModel({});
	}
}
