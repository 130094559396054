export interface IGpGroupModel {
	name: string;
	id: string;
}
export class GpGroupModel {
	constructor(opts: IGpGroupModel) {
		this.name = opts.name;
		this.id = opts.id;
	}
	name: string;
	id: string;
}
