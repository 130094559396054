import { Table } from 'app/components/table/table';
import { TableModel, TableRow } from 'app/components/table/table.model';
import { formatDate, formatNum } from 'app/utils';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { useEffect, useMemo } from 'react';
import { PleaseSelectAgt } from 'app/components/berichte/PleaseSelectAgt';
import { AgtBestandPosModel } from 'app/models/berichte/agt.bestand.pos.model';

export const AgtBestand5JahresVerlauf = observer(() => {
	const { berichteUiiStore, agtBestandUiStore: bestandUiStore } = useStore();
	const agt = berichteUiiStore.selectedAgt;

	const tm = useMemo(() => {
		const tm = new TableModel<AgtBestandPosModel, number>();
		tm.idProperty = 'title';
		tm.idType = 'number';
		tm.sortDisabled = true;
		tm.setCols([
			{
				label: 'Bestand',
				path: 'title',
				render: (row: TableRow<AgtBestandPosModel>) => {
					if (row.data.isFirst) {
						return <strong>{row.data.title} </strong>;
					}
					return <></>;
				},
			},
			{
				label: '',
				path: 'bm',
				render: (row: TableRow<AgtBestandPosModel>) => {
					if (row.data.isFirst) {
						return <>{formatDate(row.data.bm, 'MM/YYYY')} </>;
					}
					return <>{formatDate(row.data.bm, 'YYYY')} </>;
				},
			},
			{
				label: 'Beitrag Stich.',
				path: 'val.bt',
				format: formatNum,
			},
			{
				label: 'Beitrag Zuw.',
				path: 'zuwachs.bt',
				format: formatNum,
			},
			{
				label: 'Stück Stich.',
				path: 'val.st',
				format: formatNum,
			},
			{
				label: 'Stück Zuw.',
				path: 'zuwachs.st',
				format: formatNum,
			},
		]);

		return tm;
	}, []);

	useEffect(() => {
		if (agt) {
			bestandUiStore.find5YearHistoryByAgtId(agt.agtId).then((res) => {
				// filter out gesamtbewertung for this view
				res = res.filter((x) => x.posId !== 32);
				tm.setRowData(res);
			});
		}
	}, [tm, bestandUiStore, berichteUiiStore.selectedAgt, agt]);

	return (
		<>
			{berichteUiiStore.selectedAgt ? (
				<>
					<Table tm={tm}></Table>
				</>
			) : (
				<PleaseSelectAgt />
			)}
		</>
	);
});
