import { Steart } from 'app/models/core/steart.model';
import { ZeWidgetDef, ZeWidgetItem } from './vbl.ze.widgets';

export const KvsZeWidgets: ZeWidgetDef[] = [
	new ZeWidgetDef({
		title: 'Kranken',
		key: 'ZE_Kranken_KVS',
		subTitle: '(Bewertung ohne Dynamik)',
		stearts: [Steart.KVS],
		items: [
			new ZeWidgetItem({
				text: <>im indviduellen Arbeitsgebiet zu 100% </>,
				posId: 2240041,
				einheit: 'Bewertung',
			}),
		],
	}),
	new ZeWidgetDef({
		title: 'Kranken HKV',
		key: 'ZE_Kranke_HKV_KVS',
		subTitle: '(Bewertung ohne Dynamik)',
		stearts: [Steart.KVS],
		items: [
			new ZeWidgetItem({
				text: <>im indviduellen Arbeitsgebiet zu 100% </>,
				posId: 2275632,
				einheit: 'Bewertung',
			}),
		],
	}),
];
