import { Steart } from 'app/models/core/steart.model';
import moment from 'moment';
import { ZeWidgetDef, ZeWidgetItem } from './vbl.ze.widgets';

export const LsvZeWidgets: ZeWidgetDef[] = [
	new ZeWidgetDef({
		title: 'EH Sach Gesamt',
		key: 'ZE_SachGesamt_LSV',
		subTitle: '(Bewertung ohne Anpassung Unfall)',
		stearts: [Steart.LSV],
		items: [
			new ZeWidgetItem({
				text: <></>,
				posId: 2265041,
				einheit: '',
			}),
		],
		rptPath: `berichte/production/gp/${moment().year()}/overview`,
		rptPath2: `/berichte/production/agtpos/eh/sachbewertung`,
	}),

	new ZeWidgetDef({
		title: 'EH Bestandszuwachs Beitrag Sach Gesamt',
		key: 'ZE_Bestandszuwachs_LSV',
		subTitle: `Anzahl Vertriebseinheiten mit Bestandszuwachs >= 4%`,
		stearts: [Steart.LSV],
		items: [
			new ZeWidgetItem({
				text: <></>,
				posId: 2265047,
				einheit: '',
			}),
		],
		rptPath: `berichte/production/gp/${moment().year()}/overview`,
	}),
];
