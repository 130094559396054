import { useStore } from 'app/context';
import { Field, Form, Formik } from 'formik';
import { observer } from 'mobx-react';
import * as Yup from 'yup';
import FormField from 'app/components/form/FormField';
import { Button } from 'app/components/common/Button';
import { useHistory } from 'react-router';
import routes from 'routes';
import { runInAction } from 'mobx';
import { BnrBlacklistModel } from 'app/stores/admin.bnrblacklist.store';

const adminBnrBlacklistSchema = Yup.object({
	bnrId: Yup.number().positive().required(),
	comment: Yup.string().required().trim(),
}).required();

type BnrBlacklist = Yup.InferType<typeof adminBnrBlacklistSchema>;

interface IAdminTemplateEditForm {
	blacklist: BnrBlacklistModel;
}

export const AdminBnrBlacklistForm = observer((props: IAdminTemplateEditForm) => {
	const { adminBnrBlacklistUiStore } = useStore();
	const history = useHistory();
	const blacklist = props.blacklist;
	const handleSubmit = async (values: BnrBlacklist) => {
		blacklist.bnrId = values.bnrId;
		blacklist.comment = values.comment;
		await adminBnrBlacklistUiStore.save(blacklist);
		onCancel();
	};

	const onCancel = () => {
		history.push(routes.ADMINBNRBLACKLIST.getPath());
		runInAction(() => {
			adminBnrBlacklistUiStore.current = undefined;
			adminBnrBlacklistUiStore.newBlacklist = undefined;
		});
	};

	return (
		<>
			<Formik
				validationSchema={adminBnrBlacklistSchema}
				initialValues={{
					bnrId: blacklist.bnrId,
					comment: blacklist.comment ? blacklist.comment : '',
				}}
				onSubmit={handleSubmit}
			>
				{({ errors, touched, isValid, values }) => {
					return (
						<Form>
							<div className="pad-1rem">
								<FormField className="is-inline" error={errors.bnrId} touched={touched.bnrId} label="bnrId">
									<Field type="text" name="bnrId" placeholder="BnrId" />
								</FormField>

								<FormField className="is-inline" error={errors.comment} touched={touched.comment} label="comment">
									<Field type="text" name="comment" placeholder="Kommentar" />
								</FormField>
							</div>

							<div className="border-top">
								<div className="pad-1rem is-right">
									<Button type="submit" className="button is-primary" disabled={!isValid}>
										Speichern
									</Button>
									<Button type="button" className="button" onClick={onCancel}>
										Abbrechen
									</Button>
								</div>
							</div>
						</Form>
					);
				}}
			</Formik>
		</>
	);
});
