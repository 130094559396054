import { GpGroupModel } from 'app/models/gp/gp.group.model';
const gpGroups: GpGroupModel[] = [];

gpGroups.push(new GpGroupModel({ id: 'kunde', name: 'Kunde' }));
gpGroups.push(new GpGroupModel({ id: 'eh', name: 'Eingangshürde' }));
gpGroups.push(new GpGroupModel({ id: 'vss', name: 'Vertriebsschwerpunkte' }));
gpGroups.push(new GpGroupModel({ id: 'neh', name: 'Nach Eingangshürde' }));
gpGroups.push(new GpGroupModel({ id: 'vuv', name: 'Versicherung und Vorsorge' }));
gpGroups.push(new GpGroupModel({ id: 'gpplus', name: 'Geschäftsplan Plus' }));
gpGroups.push(new GpGroupModel({ id: 'incfuerfach', name: 'Incentives für Fachagenturen' }));
gpGroups.push(new GpGroupModel({ id: 'pro3', name: 'Provisionsmodell PRO3' }));
gpGroups.push(new GpGroupModel({ id: 'ohnedigi', name: 'Info: ohne digitale Daten' }));

// Multis

gpGroups.push(new GpGroupModel({ id: 'gwp', name: 'Gesetzliche Weiterbildungspflicht' }));
gpGroups.push(new GpGroupModel({ id: 'ausMaxi', name: 'Außerhalb der Maximierung' }));
gpGroups.push(new GpGroupModel({ id: 'max10k', name: 'Innerhalb einer Maximierung von 10.000 EUR' }));
gpGroups.push(new GpGroupModel({ id: 'max8k', name: 'Innerhalb einer Maximierung von 8.000 EUR' }));

export const GpGroups = gpGroups;

export const UnkownGroup = new GpGroupModel({ id: 'unbekannt', name: 'Unbekannte Group' });
