import { useEffect, useMemo } from 'react';
import { Button } from 'app/components/common/Button';
import { Table } from 'app/components/table/table';
import { TableModel, TableRow } from 'app/components/table/table.model';
import { useStore } from 'app/context';
import { formatDate } from 'app/utils';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { AgtExpertisenModel } from 'app/models/agentur.model';
import { Icon } from 'app/components/common/Icon';

export const AgtExpertisenList = observer(() => {
	const { agenturUiStore, kvAgtCustomExpertiseStore, uiStore } = useStore();
	const modalId = uiStore.modalIds.expertiseEdit;

	const agt = agenturUiStore.current;
	if (!agt) {
		return <></>;
	}

	const tm = useMemo(() => {
		const tm = new TableModel<AgtExpertisenModel, number>();
		const cols = [
			{
				label: 'Thema',
				path: 'text',
			},
			{
				label: 'Ablaufdatum',
				path: 'ablauf',
				format: formatDate,
			},
			{
				label: '',
				path: 'endAblauf',
			},
			{
				label: '',
				path: 'type',
				render: (row: TableRow<AgtExpertisenModel>) => {
					return <>{row.data.type === 'custom' && <Icon iconClass="edit" />}</>;
				},
			},
		];
		tm.onRowClick = (row: TableRow<AgtExpertisenModel>) => {
			if (row.data.type !== 'custom') {
				return;
			}
			runInAction(() => {
				kvAgtCustomExpertiseStore.setExpertisenId(row.data.id);
				uiStore.showModal(modalId);
			});
		};
		tm.setCols(cols);
		tm.sortBy = 'text';
		tm.idProperty = 'type';
		tm.idType = 'string';
		return tm;
	}, [kvAgtCustomExpertiseStore, modalId, uiStore]);

	useEffect(() => {
		tm.setRowData(agt.allExpertisen);
	}, [tm, agt.allExpertisen]);

	const onModalOpen = () => {
		runInAction(() => {
			kvAgtCustomExpertiseStore.setExpertisenId(undefined);
			uiStore.showModal(modalId);
		});
	};

	return (
		<>
			<Table modifier="is-compact" tm={tm}></Table>
			<div className="is-padded has-text-centered">
				<Button type="button" className="button is-text is-padded is-small" onClick={onModalOpen}>
					Expertise Hinzufügen
				</Button>
			</div>
		</>
	);
});
