import { BtStueckTimeModel, BtStueckTimeModelChart } from 'app/components/charts/BtStueckTimeChart';
import { useStore } from 'app/context';
import { BESTANDPOS_ID } from 'app/stores/ui/agt.bestand.ui.store';
import { useEffect, useState } from 'react';

export const AgtBestandVerlauf = () => {
	const { berichteUiiStore, agtBestandUiStore: bestandUiStore } = useStore();
	const [model, setModel] = useState<BtStueckTimeModel>();
	const agt = berichteUiiStore.selectedAgt;

	useEffect(() => {
		if (agt) {
			const _model = new BtStueckTimeModel({
				title: 'Bestand',
				fill: 'blue',
				btKey: 'val.bt',
				btLabel: 'Bestand',
				stKey: 'val.st',
				stLabel: 'Stück',
				timeKey: 'bmLabel',
				timeLabel: 'Monat',
			});
			bestandUiStore.find5YearHistoryByAgtId(agt.agtId).then((res) => {
				const x = res.filter((i) => i.posId === BESTANDPOS_ID);
				if (x) {
					_model.data = x.reverse();
				}
				setModel(_model);
			});
		}
	}, [agt, bestandUiStore]);
	return <>{agt && model && <BtStueckTimeModelChart {...model} />}</>;
};
