import { SelectInput } from 'app/components/common/Select';
import { AgenturNameCell } from 'app/components/shared/AgenturName.cell';
import { Table } from 'app/components/table/table';
import { ITableColumn, TableModel } from 'app/components/table/table.model';
import { useStore } from 'app/context';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import _ from 'lodash';
import { GoogleProfileVM } from 'app/stores/ui/agt.googleprofile.ui.store';

export const GoogleProfileOptions = observer(() => {
	const { agtGoogleProfileUiStore } = useStore();

	const onChange = (name: string, opt: any) => {
		runInAction(() => {
			agtGoogleProfileUiStore.bmCurrent = parseInt(opt.value);
		});
	};

	return (
		<>
			<div className="grid is-col-auto align-center justify-start pad-1rem">
				<strong>BM: </strong>
				<div>
					<SelectInput name="kwMin" emptyText="Start KW" value={agtGoogleProfileUiStore.bmCurrent} onChange={onChange} opts={agtGoogleProfileUiStore.bmOpts} isClearable={false} />
				</div>

			</div>
		</>
	);
});



export const GoogleProfileListe = observer(() => {
	const { berichteUiiStore, agtGoogleProfileUiStore, agenturListUiStore } = useStore();
	const history = useHistory();
	const [hasItems, setHasItems] = useState(false);


	const tm = useMemo(() => {
		const tm = new TableModel<GoogleProfileVM, number>();
		const cols: ITableColumn<GoogleProfileVM, number>[] = [];

		cols.push({
			label: 'Agentur',
			path: 'agt.name',
			sortBy: 'agt.name',
			render: AgenturNameCell,
		});
		cols.push({
			label: 'bew',
			path: `bew`,
		});
		cols.push({
			label: 'bewAvg',
			path: `bewAvg`,
		});
		cols.push({
			label: 'imgCount',
			path: `imgCount`,
		});

		cols.push({
			label: 'qCount',
			path: `qCount`,
			// format: formatNum,
		});


		tm.sortBy = 'agt.name';
		tm.setCols(cols);

		tm.idProperty = 'agtId';
		tm.idType = 'number';



		return tm;
	}, [history]);

	useEffect(() => {
		agtGoogleProfileUiStore.load().then(() => {
			let items = agtGoogleProfileUiStore.currentBMItems;
			if (berichteUiiStore.selectedAgt) {
				const agtId = berichteUiiStore.selectedAgt.agtId;
				items = items.filter((i) => i.agt.agtId === agtId);
			} else {
				items = agenturListUiStore.applyFilterOnAgt(items) as GoogleProfileVM[];
			}
			setHasItems(items.length > 0);
			tm.setRowData(items);
			tm.sort();
		});
	}, [
		tm,
		berichteUiiStore.selectedAgt,
		agenturListUiStore.filterHasChanged,
		agenturListUiStore,
		agtGoogleProfileUiStore.allVmItems,
		agtGoogleProfileUiStore.currentBMItems,
		agtGoogleProfileUiStore.bmCurrent,
		agtGoogleProfileUiStore,
	]);

	return (
		<>
			<GoogleProfileOptions />
			{!hasItems ? (
				<div className="pad-1rem">Keine Google Unternehmensprofil Teilnahme</div>
			) : (
				<>

					<Table stickyHeader={true} tm={tm}></Table>
				</>
			)}
		</>
	);
});
