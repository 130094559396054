import React, { useState } from 'react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import FormField from '../form/FormField';
import { useStore } from 'app/context';
import { Button } from '../common/Button';
import routes from './../../../routes';
import { Link } from 'react-router-dom';
import { notify } from '../common/notify';
import { useHistory } from 'react-router';
import { IResponse } from 'app/stores/core/base.api.store';

const registerFormSchema = Yup.object({
	bensl: Yup.string().required('Bensl ist erforderlich').default(''),
}).required();

type Register = Yup.InferType<typeof registerFormSchema>;

export const AuthError = (res: IResponse) => {
	let msg = undefined;
	if (!res.data && res.status) {
		msg = res.message;
	}
	return <>{msg && <div>{msg}</div>}</>;
};

export const RegisterForm: React.FC = () => {
	const { authStore } = useStore();
	const history = useHistory();
	const [response, setResponse] = useState<IResponse | undefined>();

	async function handleSubmit(values: Register) {
		const res = await authStore.register(values.bensl);
		if (!res.data && res.status) {
			setResponse(res);
		} else {
			const title = 'Erstanmeldung';
			const msg = 'E-Mail zur Erstanmeldung wurde versendet. Bitte folgen Sie den Anweisungen in Ihrem Postfach ';
			notify(title, msg);
			history.push(routes.LOGIN.getPath());
		}
	}

	return (
		<Formik
			validationSchema={registerFormSchema}
			initialValues={{
				bensl: '',
			}}
			onSubmit={handleSubmit}
		>
			{({ errors, touched, isValid }) => {
				return (
					<Form className="auth-form">
						<div className="auth-form-header">
							<div className="title">DVS</div>
						</div>

						<div>
							<FormField className="title" error={errors.bensl} touched={touched.bensl} label="BENSL">
								<Field type="text" name="bensl" autoComplete="username" placeholder="Benutzername (BENSL)" autoFocus={true} />
							</FormField>
						</div>
						{response && <AuthError {...response} />}

						<div>
							<Button type="submit" className="button is-primary is-block" isFormInvalid={!isValid}>
								Erstanmeldung
							</Button>
							<Link className="button is-block" to={routes.LOGIN.getPath()}>
								Zurück
							</Link>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
};
