import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import moment from 'moment';
import { observer } from 'mobx-react';

export const FormikDateComponent = observer(({ ...props }) => {
	const { setFieldValue } = useFormikContext();

	const field = props.field;

	const formattedValue = field.value ? moment(field.value).format('YYYY-MM-DD') : undefined;

	const [displayDate, setDisplayDate] = useState(formattedValue);
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setDisplayDate(e.target.value);
		setFieldValue(field.name, new Date(e.target.value));
	};

	return (
		<>
			<input className="input" {...field} {...props} type="date" value={displayDate} onChange={handleChange} />
		</>
	);
});
