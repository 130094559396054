import { computed } from 'mobx';
import moment from 'moment';
import { AgenturModel } from '../agentur.model';
import { PosAktModel } from './pos.akt.model';
import { PosBjModel } from './pos.bj.model';
import { PosVal } from './pos.val';

export interface IAgtBestandPosDefinition {
	posId: number;
	title: string;
	zuwachsPosId?: number;
	bm?: Date;
}

export interface IAgtBestandPosDef {
	agt: AgenturModel;
	posId: number;
	title: string;
	zuwachsPosId?: number;
	bm?: Date;
}

export class AgtBestandPosModel {
	constructor(opts: IAgtBestandPosDef) {
		this.agtId = opts.agt.agtId;
		this.agt = opts.agt;
		this.posId = opts.posId;
		this.title = opts.title;
		this.zuwachsPosId = opts.zuwachsPosId;
		this.bm = opts.bm;
	}
	agtId: number;
	agt: AgenturModel;
	bm?: Date;
	@computed
	get bmLabel() {
		return moment(this.bm).format('YYYY-MM');
	}

	isFirst: boolean = false;

	posId: number;
	title: string;
	zuwachsPosId?: number;
	val: PosVal = new PosVal(0, 0);
	zuwachs: PosVal = new PosVal(0, 0);
	setFromList(items: PosAktModel[] | PosBjModel[] | undefined) {
		if (!items) {
			return;
		}
		const item = items.find((i) => i.posId === this.posId);
		if (item) {
			this.val = item.getPosval();
		}
		const zw = items.find((i) => i.posId === this.zuwachsPosId);
		if (zw) {
			this.zuwachs = zw.getPosval();
		}
	}
	@computed
	get deltaBt() {
		if (this.val && this.zuwachs) {
			const v = this.val.bt;
			const z = this.zuwachs.bt;
			return (1 - (v - z) / v) * 100;
		}
		return undefined;
	}
	@computed
	get deltaBtLimited() {
		if (this.deltaBt) {
			if (this.deltaBt > 100) return 100;
			if (this.deltaBt < -100) return -100;
		}
		return this.deltaBt;
	}
}
