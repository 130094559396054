import BaseContainer from 'app/containers/BaseContainer';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { BnrTransferFormLoader } from 'app/components/admin/bnrtransfer/BnrTransferForm';

export interface IBnrTransferParamTypes {
	id?: string;
	sourceId?: string;
	targetId?: string;
}

const AdminBnrTransferContainer = observer(() => {
	const { id, sourceId, targetId } = useParams<IBnrTransferParamTypes>();
	return (
		<BaseContainer classModifier="is-auth">
			<BnrTransferFormLoader id={id} sourceId={sourceId} targetId={targetId} />
		</BaseContainer>
	);
});

export default AdminBnrTransferContainer;

export interface IBnrTransferDoneParamTypes {
	id?: string;
}

export const AdminBnrTransferDoneContainer = observer(() => {
	const { id } = useParams<IBnrTransferDoneParamTypes>();
	return (
		<BaseContainer classModifier="is-auth">
			<form className="auth-form">
				<div className="auth-form-header">
					<div className="title">DVS Datenübernahme</div>
				</div>
				<div>
					{id === 'approved' && <>Vielen Dank für die Bestätigung!</>}
					{id === 'rejected' && <>Vielen Dank! Die Anfrage wurde abgelehnt!</>}
				</div>
			</form>
		</BaseContainer>
	);
});
