import { useStore } from 'app/context';
import { Field, Form, Formik } from 'formik';
import { observer } from 'mobx-react';
import * as Yup from 'yup';
import { notify } from 'app/components/common/notify';
import FormField from 'app/components/form/FormField';
import { Button } from 'app/components/common/Button';
import { ImportJobVM } from 'app/stores/ui/admin.imports.ui.store';
import { useEffect, useState } from 'react';
import { AdminFileUpload } from '../AdminFileUpload';
import { AupFile, ImportDataType } from 'app/stores/admin.imports.store';
import { formatDateLong, humanizeFileSize } from 'app/utils';
import { useHistory } from 'react-router';
import routes from 'routes';

const newAupFormSchema = Yup.object({
	kw: Yup.string().required('KW ist erfoderlich').default(''),
}).required();

type NewAupForm = Yup.InferType<typeof newAupFormSchema>;

interface IUserEditForm {
	job: ImportJobVM;
	onClose: () => void;
}
export const AdminImportNewAupForm = observer((props: IUserEditForm) => {
	const { adminImportsUiStore } = useStore();
	const history = useHistory();
	const job = props.job;
	const [lastChange, setLastChange] = useState(new Date());

	const [files, setFiles] = useState<AupFile[]>([]);

	const handleSubmit = async (values: NewAupForm) => {
		job.job.meta.kw = values.kw;
		notify('Job wird gestartet', '', 'info');
		const res = (await adminImportsUiStore.triggerJob(job.job)) as any;
		history.push(routes.ADMINIMPORTS.getPath('job', res.id));
	};

	const onUploadDone = () => {
		setLastChange(new Date());
	};

	return (
		<>
			<Formik
				validationSchema={newAupFormSchema}
				initialValues={{
					kw: job.KW,
				}}
				onSubmit={handleSubmit}
			>
				{({ errors, touched, isValid, values }) => {
					useEffect(() => {
						adminImportsUiStore.getAupKwFiles(values.kw, lastChange).then((res) => {
							setFiles(res);
						});
					}, [values.kw]);
					return (
						<Form>
							<div className="pad-1rem">
								<FormField className="is-inline" error={errors.kw} touched={touched.kw} label="kw">
									<Field type="text" name="kw" placeholder="KW" />
								</FormField>
							</div>
							<div className="pad-1rem">
								<div className="form-control is-inline">
									<label>Dateien</label>
									{isValid && (
										<AdminFileUpload
											isButton={false}
											maxSize={200000000}
											// maxFiles={1}
											dataType={ImportDataType.aup}
											kw={values.kw}
											multiple={true}
											dropZoneActiveText={'Datei loslassen'}
											dropZoneText={'Dateien Hochladen'}
											uploadingText={'Upload läuft'}
											onDone={onUploadDone}
										/>
									)}
								</div>
							</div>

							<div className="pad-1rem">
								<AdminImportAupKWFiles kw={values.kw} files={files} />
							</div>
							<div className="pad-1rem">
								<Button type="submit" className={'button is-primary'} disabled={!isValid || files.length !== 16}>
									Import für {values.kw} starten
								</Button>
							</div>
						</Form>
					);
				}}
			</Formik>
		</>
	);
});

interface IAdminImportAupKWFiles {
	files: AupFile[];
	kw: string;
}
export const AdminImportAupKWFiles = observer((props: IAdminImportAupKWFiles) => {
	return (
		<div className="pad-top-1rem border-top">
			<strong>Hochgeladene Dateien für KW: {props.kw}</strong>
			<br />

			{props.files.length === 0 && <>Keine Datein hochgeladen </>}
			{props.files.length !== 16 && <>Es sollten 16 Datein hochgeladen werden</>}
			<table className={'table'}>
				<thead>
					<tr>
						<th>Name</th>
						<th>Größe</th>
						<th>Datum</th>
					</tr>
				</thead>
				<tbody>
					{props.files.map((f) => (
						<tr key={f.fileName}>
							<td>{f.fileName}</td>
							<td>{humanizeFileSize(f.fileSize)}</td>
							<td>{formatDateLong(f.fileDate)}</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
});
