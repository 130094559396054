import ProgressRing from 'app/components/common/ProgressRing';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import Widget, { IWidgetConsumer } from '../common/Widget';
import colors from 'assets/scss/colors.module.scss';
import { WidgetNavigate } from '../common/WidgetNavigate';

export const TasksStatsHomeWidget = observer((props: IWidgetConsumer) => {
	const { taskUiStore } = useStore();
	useEffect(() => {
		taskUiStore.load();
	}, [taskUiStore]);

	const total = taskUiStore.stats.total || 0;
	const offen = taskUiStore.stats.offen || 0;
	const erledigt = taskUiStore.stats.erledigt || 0;
	const rptPath = '/massnahmen';

	return (
		<>
			{total > 0 && (
				<Widget modifier="is-paddingless" title={'Maßnahmen Statisik'} action={<WidgetNavigate text={'Alle'} path={rptPath} />} widgetId={props.widgetKey}>
					<div className="grid is-gapless is-col-auto is-responsive">
						<ProgressRing total={total} text={total} value={total} color={colors.primary} label="Gesamt" />
						<ProgressRing total={total} text={offen} value={offen} color={colors.warning} label="Offen" />
						<ProgressRing total={total} text={erledigt} value={erledigt} color={colors.success} label="Erledigt" />
					</div>
				</Widget>
			)}
		</>
	);
});
