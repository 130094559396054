import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import React from 'react';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router';
import routes from 'routes';
import Panel from '../Panel';
import { AgenturAnwendungen } from './AgenturAnwendungen';
import { AgenturBerichte } from './AgenturBerichte';
import { AgenturDokumente } from './AgenturDokumente';
import { AgenturMenu } from './AgenturMenu';
import { ADAOverview, AgenturOverview } from './AgenturOverview';
import { AgenturPlanung } from './AgenturPlanung';
import { AgenturTasks } from './AgenturTasks';

export const AgenturDetail = observer(() => {
	const { agenturUiStore } = useStore();
	const agt = agenturUiStore.current;
	const history = useHistory();
	let match = useRouteMatch();
	const location = useLocation();

	const onClose = () => {
		const url = routes.AGENTUR.getPath() + location.search
		history.push(url);
	};

	return (
		<>
			{agt && (
				<Panel title={agt.name} subtitle={agt.agtId.toString()} header={<AgenturMenu />} onClose={onClose}>
					<Switch>
						<Route path={`${match.path}/:agtId/planung`}>
							<AgenturPlanung />
						</Route>
						<Route path={`${match.path}/:agtId/berichte`}>
							<AgenturBerichte />
						</Route>
						<Route path={`${match.path}/:agtId/anwendungen`}>
							<AgenturAnwendungen />
						</Route>
						<Route path={`${match.path}/:agtId/dokumente`}>
							<AgenturDokumente />
						</Route>
						<Route path={`${match.path}/:agtId/massnahmen`}>
							<AgenturTasks />
						</Route>
						<Route path={`${match.path}/:agtId`} exact>
							<AgenturOverview />
						</Route>
					</Switch>
				</Panel>
			)}
		</>
	);
});

export const ADADetail = observer(() => {
	const { agenturUiStore } = useStore();
	const agt = agenturUiStore.current;
	const history = useHistory();
	const location = useLocation();


	const onClose = () => {
		const url = routes.AGENTUR.getPath() + location.search
		history.push(url);
	};

	return (
		<>
			{agt && (
				<Panel title={agt.name} subtitle={agt.agtId.toString()} header={<></>} onClose={onClose}>
					<ADAOverview />
				</Panel>
			)}
		</>
	);
});
