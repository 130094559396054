import { AgenturStore } from '../agentur.store';
import { SessionStore } from '../session.store';
import { runInAction, observable, makeObservable } from 'mobx';
import { AgenturModel, AgtType } from 'app/models/agentur.model';
import { DigitalreportModel, KvAgtDigitalreportStore } from '../kv.agt.digitalreport.store';

export class DigitalReportVM {
	agt: AgenturModel;
	pos: DigitalreportModel;

	constructor(agt: AgenturModel, pos: DigitalreportModel) {
		this.agt = agt;
		this.pos = pos;
	}
}

export class AgtDigitalreportUiStore {
	session: SessionStore;
	agenturStore: AgenturStore;
	kvAgtDigitalreportStore: KvAgtDigitalreportStore;
	constructor(session: SessionStore, agenturStore: AgenturStore, kvAgtDigitalreportStore: KvAgtDigitalreportStore) {
		makeObservable(this);
		this.session = session;
		this.agenturStore = agenturStore;
		this.kvAgtDigitalreportStore = kvAgtDigitalreportStore;
	}

	@observable
	items: DigitalReportVM[] = [];

	async findAll() {
		if (this.items.length > 0) {
			return this.items;
		}
		const agts = (await this.agenturStore.findAll()).filter((x) => x.agtType === AgtType.agt);
		const items = await this.kvAgtDigitalreportStore.load();

		const res: DigitalReportVM[] = [];

		runInAction(() => {
			items.forEach((i) => {
				const agt = agts.find((a) => a.agtId === i.agtId);
				if (agt) {
					res.push(new DigitalReportVM(agt!, i));
				}
			});
		});
		runInAction(() => {
			this.items = res;
		});
		return this.items;
	}
}
