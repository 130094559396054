import { AgenturModel } from 'app/models/agentur.model';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import moment from 'moment';
import { AgenturStore } from '../agentur.store';
import { AgtYearModel, AgtYearStore } from '../agt.year.store';
import { SessionStore } from '../session.store';

export const schwerpunktRoles = ['BSG', 'BBS', 'BFG', 'KVS', 'KME', 'SPS', 'VAS', 'UB', 'EDT', 'EDP'];

export class AgtSchwerpunktVM extends AgtYearModel {
	constructor(item: any, agt: AgenturModel) {
		super(item);
		this.agt = agt;
	}
	agt: AgenturModel;

	isRoleSchwerpunkt(role: string) {
		return this.selectedRolesAsList.includes(role.toUpperCase());
	}

	@computed
	get selectedRolesAsList() {
		const res: string[] = [];
		schwerpunktRoles.forEach((r) => {
			const prop = 'schwerpunkt' + r;
			const v = (this as any)[prop];
			if (v === true) {
				res.push(r);
			}
		});
		return res;
	}

	@computed
	get firstSelected() {
		return this.selectedRolesAsList[0];
	}
}

export class AgtSchwerpunktUiStore {
	session: SessionStore;
	agenturStore: AgenturStore;
	agtYearStore: AgtYearStore;

	constructor(session: SessionStore, agenturStore: AgenturStore, agtYearStore: AgtYearStore) {
		makeObservable(this);
		this.session = session;
		this.agenturStore = agenturStore;
		this.agtYearStore = agtYearStore;
	}

	onLogout() {
		this.loaded = false;
		this.items = [];
	}

	loaded: boolean = false;
	@observable items: AgtSchwerpunktVM[] = [];
	async findByYear(year: number) {
		const items = await this.findAll();
		return items.filter((x) => x.year === year && x.agt.GP!!);
	}

	@computed
	get years() {
		const res = [];
		for (let y = 2020; y <= moment().year(); y++) {
			res.push(y);
		}
		return res;
	}

	getRolesByYear(year: number) {
		if (year <= 2022) {
			return schwerpunktRoles.filter((x) => x !== 'BSG' && x !== 'EDP' && x !== 'EDT');
		} else {
			return schwerpunktRoles.filter((x) => x !== 'BFG' && x !== 'SPS');
		}
	}

	async findByYearAndAgtId(year: number, agtId: number) {
		const items = await this.findAll();
		return items.find((i) => i.year === year && i.agtId === agtId);
	}

	async findAll() {
		if (this.loaded) {
			return this.items;
		}
		const items = await this.agtYearStore.findAll();
		const agts = await this.agenturStore.findAll();
		const res: AgtSchwerpunktVM[] = [];

		this.years.forEach((year) => {
			const yearItems = items.filter((i) => i.year === year);
			agts.forEach((agt) => {
				const item = yearItems.find((i) => i.agtId === agt.agtId && i.year === year);
				if (item) {
					res.push(new AgtSchwerpunktVM(item, agt));
				} else {
					res.push(new AgtSchwerpunktVM({ year, agtId: agt.agtId }, agt));
				}
			});
		});

		runInAction(() => {
			this.items = res;
		});

		return res;
	}

	newAgtSchwerpunkt(agt: AgenturModel, year: number) {
		return new AgtSchwerpunktVM(
			{
				year: year,
				agtId: agt.agtId,
				bnrId: this.session.currentUser!.bnrId,
			},
			agt,
		);
	}

	async saveMany(data: AgtSchwerpunktVM[]) {
		for (const item of data) {
			await this.agtYearStore.save(item);
		}
		this.loaded = false;
		this.items = [];
	}

	async save(data: AgtSchwerpunktVM) {
		await this.agtYearStore.save(data);
		runInAction(() => {
			this.loaded = false;
			this.items = [];
		});
	}
}
