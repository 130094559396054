import { TreeMenuModel } from 'app/models/core/tree.node.model';
import { makeObservable, reaction } from 'mobx';
import { AgenturStore } from '../agentur.store';
import { AgtGpStore } from '../agt.gp.store';
import { SessionStore } from '../session.store';
import { AgtBaseTreeStore } from '../core/base.tree.store';
import routes from 'routes';
import { AgtDigigFaktorUiStore, DigiFaktorSchema } from './agt.digifaktor.ui.store';
import { BerichteTreeNodes } from 'app/models/berichte/berichte.tree.nodes';
import { AgtProdGpUebersicht } from 'app/components/berichte/prod/AgtProdGpUebersicht';
import { ProdZwischenstand } from 'app/components/berichte/prod/ProdZwischenstand';
import { ProdPositionen } from 'app/components/berichte/prod/ProdPositionen';
import { DigifaktorList } from 'app/components/berichte/digifaktor/DigifaktorListe';
import { BnrProdGpUebersicht } from 'app/components/berichte/prod/BnrProdGpUebersicht';
import { DatenStandAkumaPrime, DatenStandDigiFaktor, DatenStandPosZiel } from 'app/components/berichte/DatenStand';
import { AgtZielStore } from '../agt.ziel.store';
import { AgtRingPositionen } from 'app/components/berichte/ring/AgtRingPositionen';
import { AgtRingUiStore } from './agt.ring.ui.store';
import { RingOveriew } from 'app/components/berichte/ring/AgtRingOverview';
import { AgtUserUiStore } from './agt.user.ui.store';
import { AkumaPrimeOverview } from 'app/components/berichte/akumaprime/AkumaPrimeOverview';
import { AkumaPrimeEinordnungsOverview } from 'app/components/berichte/akumaprime/AkumaPrimeEinordnungsOverview';
import { AgenturClusterListe } from 'app/components/berichte/agenturcluster/AgenturClusterListe';
import { Steart } from 'app/models/core/steart.model';
import { AkumaPrimeTerminierungsOverview } from 'app/components/berichte/akumaprime/AkumaPrimeTerminierungsOverview';
import moment from 'moment';
import { AkumaPrimeBeziehungOverview } from 'app/components/berichte/akumaprime/AkumaPrimeBeziehungOverview';
import { AkumaPrimeNachbearbeitungOverview } from 'app/components/berichte/akumaprime/AkumaPrimeNachbearbeitungOverview';
import { AkumaPlanerListe } from 'app/components/berichte/akumaplaner/AkumaPlanerListe';
import Config from 'Config';
import { AkumaPlanerWochenbericht } from 'app/components/berichte/akumaplaner/AkumaPlanerWochenbericht';

export const GPMAXYEAR = 2024;
export const BPMAXYEAR = moment().year();
export const CLUSTERMAXYEAR = 2024;

export class BerichteUiiStore extends AgtBaseTreeStore {
	gpStore: AgtGpStore;
	agtZielStore: AgtZielStore;
	agtDigigFaktorStore: AgtDigigFaktorUiStore;
	agtRingUiStore: AgtRingUiStore;

	constructor(
		session: SessionStore,
		agenturStore: AgenturStore,
		gpStore: AgtGpStore,
		agtZielStore: AgtZielStore,
		agtDigigFaktorStore: AgtDigigFaktorUiStore,
		agtRingUiStore: AgtRingUiStore,
		agtUserUiStore: AgtUserUiStore,
	) {
		super(session, agenturStore, agtUserUiStore);
		makeObservable(this);
		this.gpStore = gpStore;
		this.agtZielStore = agtZielStore;
		this.agtDigigFaktorStore = agtDigigFaktorStore;
		this.agtRingUiStore = agtRingUiStore;
		reaction(
			() => this.session.currentUser || this.agtUserUiStore.users.length,
			() => this.fillNodesFromUser(),
			{ fireImmediately: true },
		);
	}
	onLogout() { }

	gpMaxYear: number = GPMAXYEAR;
	gpPrevYear: number = GPMAXYEAR - 1;

	async ensureLateLoadedNodes() {
		if (this.session.currentSteart && this.session.currentSteart.steart === Steart.LDP) {
			await this.agtUserUiStore.load();
			const gpRoot = this.menu.findNodeByPath('production', 'gp');
			if (gpRoot) {
				gpRoot.nodes.forEach((gpCurr) => {
					this.agtUserUiStore.users.forEach((u) => {
						if (u.steart.steart === Steart.EDP || u.steart.steart === Steart.EDT) {
							const year = parseInt(gpCurr.name);
							const name = u.name + ' (' + u.steartText + ')';
							const path = 'gp_' + u.bnrId;
							const found = gpCurr.nodes.find((x) => x.name === name);
							if (found) {
								return;
							}
							gpCurr.addNode({
								name,
								path,
								agtComponent: undefined,
								component: <BnrProdGpUebersicht year={year} betreuerBnrId={u.bnrId} />,
								datenStand: <DatenStandPosZiel year={year} />,
								icon: '',
								hideFilter: true,
							});
						}
					});
				});
			}
		}
	}

	fillNodesFromUser() {
		if (!this.session.currentUser) {
			return;
		}
		const currentUser = this.session.currentUser;

		this.AGENTURROUTE = routes.AGENTUR;
		this.OUTEROUTE = routes.BERICHTE;

		const gpMaxYear = this.gpMaxYear.toString();

		// gp
		const gpRoot = this.menu.findNodeByPath('production', 'gp');
		if (gpRoot) {
			if (gpRoot.nodes.find((n) => n.path === gpMaxYear)) {
				console.log('aborting fillNodesFromUser, because it seems it has run once.');
				return;
			}
			const gpCurr = gpRoot.addNode({
				name: gpMaxYear,
				path: gpMaxYear,
				icon: '',
			});
			gpCurr.addNode({
				name: 'Übersicht',
				path: 'overview',
				agtComponent: <AgtProdGpUebersicht year={this.gpMaxYear} />,
				component: <BnrProdGpUebersicht year={this.gpMaxYear} />,
				datenStand: <DatenStandPosZiel year={this.gpMaxYear} />,
				icon: '',
				hideFilter: true,
			});

			if (this.session.currentUser!.canSeeProdZwischenstand) {
				gpCurr.addNode({
					name: 'Produktivitäten Zwischenstand',
					path: 'prod-zwischenstand',
					component: <ProdZwischenstand year={this.gpMaxYear} />,
					datenStand: <DatenStandPosZiel year={this.gpMaxYear} />,
					icon: '',
				});
			}

			// if (!currentUser.isMulti) {
			const gpPrev = gpRoot.addNode({
				name: this.gpPrevYear.toString(),
				path: this.gpPrevYear.toString(),
				icon: '',
			});
			gpPrev.addNode({
				name: 'Übersicht',
				path: 'overview',
				agtComponent: <AgtProdGpUebersicht year={this.gpPrevYear} />,
				component: <BnrProdGpUebersicht year={this.gpPrevYear} />,
				datenStand: <DatenStandPosZiel year={this.gpPrevYear} />,
				icon: '',
				hideFilter: true,
			});

			if (this.session.currentUser!.canSeeProdZwischenstand) {
				gpPrev.addNode({
					name: 'Produktivitäten Zwischenstand',
					path: 'prod-zwischenstand',
					component: <ProdZwischenstand year={this.gpPrevYear} />,
					datenStand: <DatenStandPosZiel year={this.gpPrevYear} />,
					icon: '',
				});
			}
		}

		// agtPos
		const agtPosRoot = this.menu.findNodeByPath('production', 'agtpos');
		if (agtPosRoot) {
			if (agtPosRoot.nodes.find((n) => n.path === gpMaxYear)) {
				console.log('aborting fillNodesFromUser, because it seems it has run once.');
				return;
			}
			const agtCurr = agtPosRoot.addNode({
				name: gpMaxYear,
				path: gpMaxYear,
				icon: '',
			});

			this.gpStore.getAgtPositionenByGroup(this.gpMaxYear).forEach((groups) => {
				const g = groups[0];
				const groupNode = agtCurr.addNode({
					path: g.group.id,
					name: g.group.name,
					icon: '',
				});
				groups.forEach((pos) => {
					if (pos.hideFromTreeMenu) {
						return;
					}
					const eh = pos.einheit.shortText;
					groupNode.addNode({
						name: pos.posText + ` (${eh})`,
						component: <ProdPositionen year={this.gpMaxYear} />,
						path: pos.planKey,
						icon: '',
						datenStand: <DatenStandPosZiel year={this.gpMaxYear} />,
					});
				});
			});

			if (!currentUser.isMulti) {
				const agtPrev = agtPosRoot.addNode({
					name: this.gpPrevYear.toString(),
					path: this.gpPrevYear.toString(),
					icon: '',
				});
				this.gpStore.getAgtPositionenByGroup(this.gpPrevYear).forEach((groups) => {
					const g = groups[0];
					const groupNode = agtPrev.addNode({
						path: g.group.id,
						name: g.group.name,
						icon: '',
					});
					groups.forEach((pos) => {
						if (pos.hideFromTreeMenu) {
							return;
						}
						const eh = pos.einheit.shortText;
						groupNode.addNode({
							name: pos.posText + ` (${eh})`,
							component: <ProdPositionen year={this.gpPrevYear} />,
							path: pos.planKey,
							icon: '',
							datenStand: <DatenStandPosZiel year={this.gpPrevYear} />,
						});
					});
				});
			}
		}

		// ring
		const ringRoot = this.menu.findNodeByPath('production', 'ring');
		if (ringRoot) {
			const ringCurr = ringRoot.addNode({
				name: this.gpMaxYear.toString(),
				path: this.gpMaxYear.toString(),
				icon: '',
			});
			ringCurr.addNode({
				path: 'overview',
				name: 'Übersicht',
				icon: '',
				component: <RingOveriew year={this.gpMaxYear} />,
			});
			this.agtRingUiStore.ringPosGroups.forEach((ringPos) => {
				ringCurr.addNode({
					path: ringPos.goldPosId.toString(),
					name: ringPos.name,
					icon: '',
					component: <AgtRingPositionen year={this.gpMaxYear} />,
				});
			});
			const ringPrev = ringRoot.addNode({
				name: this.gpPrevYear.toString(),
				path: this.gpPrevYear.toString(),
				icon: '',
			});
			ringPrev.addNode({
				path: 'overview',
				name: 'Übersicht',
				icon: '',
				component: <RingOveriew year={this.gpPrevYear} />,
			});
			this.agtRingUiStore.ringPosGroups.forEach((ringPos) => {
				ringPrev.addNode({
					path: ringPos.goldPosId.toString(),
					name: ringPos.name,
					icon: '',
					component: <AgtRingPositionen year={this.gpPrevYear} />,
				});
			});
		}

		// akumaprime
		const akuma = this.menu.findNodeByPath('kunde', 'akuma');
		if (akuma) {
			if (akuma.nodes.find((n) => n.path === 'prime')) {
				console.log('aborting fillNodesFromUser for akuma because it seems it has run once.');
				return;
			}

			const prime = akuma.addNode({
				name: 'Prime',
				path: 'prime',
				icon: '',
			});

			prime.addNode({
				name: 'Übersicht',
				component: <AkumaPrimeOverview />,
				datenStand: <DatenStandAkumaPrime />,
				path: 'uebersicht',
				icon: '',
			});

			prime.addNode({
				name: 'Einordnung',
				component: <AkumaPrimeEinordnungsOverview />,
				datenStand: <DatenStandAkumaPrime />,
				path: 'einordnung',
				icon: '',
			});
			prime.addNode({
				name: 'Terminierung',
				component: <AkumaPrimeTerminierungsOverview />,
				datenStand: <DatenStandAkumaPrime />,
				path: 'terminierung',
				icon: '',
			});
			// agenturpilot.addNode({
			// 	name: 'Ergebnis externe Terminierung',
			// 	component: <AgenturPilotNachbearbeitungOverview />,
			// 	datenStand: <DatenStandAgenturPilot />,
			// 	path: 'nachbearbeitung',
			// 	icon: '',
			// });
			prime.addNode({
				name: 'Beziehung',
				component: <AkumaPrimeBeziehungOverview />,
				datenStand: <DatenStandAkumaPrime />,
				path: 'beziehung',
				icon: '',
			});

			const planer = akuma.addNode({
				name: 'Planer',
				path: 'planer',
				icon: '',
			});

			planer.addNode({
				name: 'Übersicht',
				component: <AkumaPlanerListe />,
				datenStand: <DatenStandAkumaPrime />,
				path: 'uebersicht',
				icon: '',
			});
			planer.addNode({
				name: 'Wochenscheiben',
				component: <AkumaPlanerWochenbericht />,
				datenStand: <DatenStandAkumaPrime />,
				path: 'wochenscheiben',
				icon: '',
			});
		}

		// digifaktor
		const dfRoot = this.menu.findNodeByPath('kunde', 'digi');
		if (dfRoot) {
			DigiFaktorSchema.forEach((d) => {
				dfRoot.addNode({
					name: d.title,
					component: <DigifaktorList />,
					datenStand: <DatenStandDigiFaktor />,
					datenStandDate: () => {
						return this.agtDigigFaktorStore.datenStand ? this.agtDigigFaktorStore.datenStand?.toString() : '';
					},
					path: d.path,
					icon: '',
				});
			});
		}

		const cluster = this.menu.findNodeByPath('bestand', 'cluster');
		if (cluster) {
			const firstYear = 2023;
			const currYear = CLUSTERMAXYEAR;
			for (let y = currYear; y >= firstYear; y--) {
				cluster.addNode({
					name: y.toString(),
					path: y.toString(),
					component: <AgenturClusterListe year={y} />,

					icon: '',
				});
			}
		}
	}

	menu: TreeMenuModel = new TreeMenuModel(BerichteTreeNodes);
}
