import { Steart } from 'app/models/core/steart.model';
import _ from 'lodash';
import moment from 'moment';

interface IZeWidgetDef {
	key: string;
	title: string;
	subTitle?: string;
	items: ZeWidgetItem[];
	stearts: Steart[];
	rptPath?: string;
	rptPath2?: string;
}

export class ZeWidgetDef {
	constructor(opts: IZeWidgetDef) {
		this.key = opts.key;
		this.title = opts.title;
		this.subTitle = opts.subTitle;
		this.items = opts.items;
		this.stearts = opts.stearts;
		this.rptPath = opts.rptPath;
		this.rptPath2 = opts.rptPath2;
	}
	key: string;
	title: string;
	subTitle?: string;
	items: ZeWidgetItem[];
	stearts: Steart[];
	rptPath?: string;
	rptPath2?: string;
}

interface IZeWidgetItem {
	text: string | JSX.Element;
	planKey?: string;
	posId: number | number[];
	einheit?: string;
	decimals?: number;
	rptPath?: string;
	title?: string;
	subtitle?: string;
}

export class ZeWidgetItem {
	constructor(opts: IZeWidgetItem) {
		this.text = opts.text;
		this.planKey = opts.planKey;
		this.einheit = opts.einheit;
		this.decimals = opts.decimals;
		this.rptPath = opts.rptPath;
		this.title = opts.title;
		this.subtitle = opts.subtitle;
		if (_.isArray(opts.posId)) {
			this.posId = opts.posId;
		} else {
			this.posId = [opts.posId];
		}
	}
	text: string | JSX.Element;
	planKey?: string;
	posId: number[];
	einheit?: string;
	decimals?: number;
	rptPath?: string;
	title?: string;
	subtitle?: string;
}

export const VblZeWidgets: ZeWidgetDef[] = [
	new ZeWidgetDef({
		title: 'Breitenmaß Gesamtbewertung',
		subTitle: 'GV/HV, Co-Verkäufer',
		key: 'Breitenmass',
		stearts: [Steart.VBL],
		items: [
			new ZeWidgetItem({
				title: 'Summe prozentuale Zielerfüllung',
				subtitle: '(mindestens 80% - maximal 180%)',
				text: (
					<div className="pad-bottom-1rem">
						1. GV/HV GP-EH Gesamtbewertung
						<br />
						2. Co-Verkäufer 65.000 Gesamtbewertung
						<br />
						im Verhältnis zur Anzahl gewerteter Verkäufer
						<br />
						(inklusive unterjähriger Zu-/Abgänge)
					</div>
				),
				posId: 2255041,
				einheit: '%',
			}),
		],
		// rptPath: `/berichte/production/gp/${moment().year()}/overview`,
	}),
	new ZeWidgetDef({
		title: 'oder Unfall und Leben',
		key: 'Bestandszuwachs',
		stearts: [Steart.VBL],
		items: [
			new ZeWidgetItem({
				title: 'Unfall',
				text: <>Anzahl Vertriebseinheiten mit mindestens 24 langfristigen Neustück 40%</>,
				posId: 2255045,
				planKey: 'Unfall2255045',
				rptPath: `/berichte/production/agtpos/${moment().year()}/eh/unfallfstnr`,
			}),
			new ZeWidgetItem({
				title: 'Leben',
				text: <>Anzahl Vertriebseinheiten mit mindestens 24 Neustück 40%</>,
				posId: 2255046,
				planKey: 'Leben2255046',
				rptPath: `/berichte/production/agtpos/${moment().year()}/eh/lebennst`,
			}),
		],
		// rptPath: `/berichte/production/gp/${moment().year()}/overview`,
	}),
	// new ZeWidgetDef({
	// 	title: 'Bestandszuwachs Beitrag Sach Gesamt',
	// 	key: 'Bestandszuwachs',
	// 	stearts: [Steart.VBL],
	// 	items: [
	// 		new ZeWidgetItem({
	// 			text: 'Anzahl Vertriebseinheiten mit Bestandszuwachs >= 4%',
	// 			posId: 2255047,
	// 		}),
	// 	],
	// 	// rptPath: `/berichte/production/gp/${moment().year()}/overview`,
	// }),
];
