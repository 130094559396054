import { Button } from 'app/components/common/Button';
import { Modal } from 'app/components/Modal';
import { useStore } from 'app/context';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import FormField from 'app/components/form/FormField';
import { useEffect, useState } from 'react';
import { CustomExpertiseModel } from 'app/stores/kv.agt.custom.expertise.store';
import { notify } from 'app/components/common/notify';
import moment from 'moment';
import { Icon } from 'app/components/common/Icon';

const expertiseFormSchema = Yup.object({
	text: Yup.string().required(),
	ablauf: Yup.string(),
}).required();

type Expertise = Yup.InferType<typeof expertiseFormSchema>;

const SelectCustomExpertisenForm = observer(() => {
	const { agenturUiStore, kvAgtCustomExpertiseStore } = useStore();
	const agt = agenturUiStore.current;

	if (!agt) return null;

	const customExpertisenTexts = ['Ausbildungsagentur AQV/AQS', 'Ausbildungsagentur AZUBI', 'Eigene Expertise'];
	const expertisenList = agt?.customExpertisen;

	const handleButtons = (text: string) => {
		kvAgtCustomExpertiseStore.setCustomExpertisenText(text);
	};

	return (
		<div className="select-expertise-buttons">
			<h2 className="title is-4 pad-bottom-1rem">Expertise wählen</h2>

			<div className="select-custom-expertise grid">
				{customExpertisenTexts.map((v, i) => {
					const isDisabled = expertisenList && expertisenList.filter((e) => e.valueString === v).length > 0;
					return (
						<Button key={i} onClick={() => handleButtons(v)} className="button is-marginless" disabled={isDisabled}>
							{v}
						</Button>
					);
				})}
			</div>
		</div>
	);
});

export const AgtCustomExpertisenEdit = observer(() => {
	const { agenturUiStore, uiStore, kvAgtCustomExpertiseStore } = useStore();
	const agt = agenturUiStore.current;
	const [expertise, setExpertise] = useState<CustomExpertiseModel | undefined>(undefined);

	useEffect(() => {
		if (kvAgtCustomExpertiseStore.currentExpertisenId) {
			kvAgtCustomExpertiseStore.findByAgtId(agt!.agtId).then((exps) => {
				const e = exps.find((e) => e.id === kvAgtCustomExpertiseStore.currentExpertisenId);
				if (e) {
					setExpertise(e);
				}
			});
		} else {
			setExpertise(undefined);
		}
	}, [agt, kvAgtCustomExpertiseStore, kvAgtCustomExpertiseStore.currentExpertisenId]);

	const modalId = uiStore.modalIds.expertiseEdit;

	const handleSubmit = async (values: Expertise) => {
		kvAgtCustomExpertiseStore.loaded = false;

		let e: CustomExpertiseModel;
		if (!expertise) {
			e = new CustomExpertiseModel({
				agtId: agt!.agtId,
				key: values.text,
			});
			// customExps.push(e);
		} else {
			e = expertise;
		}
		if (values.ablauf) {
			const mom = moment(values.ablauf);
			e.valueNum = mom.unix();
		} else {
			e.valueNum = 0;
		}
		e.valueString = values.text;

		notify('Expertise wird gespeichert', '');
		await kvAgtCustomExpertiseStore.save(e);
		await refreshAgt();

		uiStore.hideModal(modalId);
	};

	const formName = modalId;
	const onClose = () => {
		runInAction(() => {
			uiStore.hideModal(modalId);
			setExpertise(undefined);
			kvAgtCustomExpertiseStore.setExpertisenId(undefined);
			kvAgtCustomExpertiseStore.setCustomExpertisenText(undefined);
		});
	};

	const refreshAgt = async () => {
		kvAgtCustomExpertiseStore.clearCache();
		await kvAgtCustomExpertiseStore.findAll();
		const customExps = await kvAgtCustomExpertiseStore.findByAgtId(agt!.agtId);
		agt!.setCustomExpertisen(customExps);
	};

	const onDelete = async () => {
		if (!expertise) {
			return;
		}
		if (window.confirm('Sicher? Dies kann nicht rückgängig gemacht werden') !== true) {
			return;
		}
		await kvAgtCustomExpertiseStore.deleteById(expertise.id);
		notify('Expertise wurde gelöscht', '');
		await refreshAgt();
		uiStore.hideModal(modalId);
	};

	const customExpertiseText = kvAgtCustomExpertiseStore.customExpertisenTempText;

	return (
		<>
			<Modal modalId={modalId} title={`Expertise ${expertise ? 'bearbeiten' : 'hinzufügen'}`} onClose={onClose}>
				{!expertise && !customExpertiseText ? (
					<SelectCustomExpertisenForm />
				) : (
					<Formik
						validationSchema={expertiseFormSchema}
						initialValues={{
							ablauf: expertise && expertise.ablauf ? moment(expertise.ablauf).format('YYYY-MM-DD') : '',
							text: expertise ? expertise.text : customExpertiseText || '',
						}}
						onSubmit={handleSubmit}
					>
						{({ errors, touched, isValid, values, setFieldValue }) => {
							const clearDate = () => {
								setFieldValue('ablauf', '');
							};
							return (
								<Form id={formName}>
									<div className="select-expertise-form">
										<div className="expertise-form">
											<div>
												<FormField error={errors.text} touched={touched.text} label={'Bezeichnung'}>
													<Field type="text" name="text" placeholder="Bezeichnung der Expertise" autoFocus={true} />
												</FormField>

												<FormField className="date-with-clear-button" error={errors.ablauf} touched={touched.ablauf} label={'Ablaufdatum (Optional)'}>
													<div className="grid">
														<Field type="date" name="ablauf" />
														<Button className="clear-button" onClick={clearDate} disabled={!values.ablauf}>
															<Icon iconClass="times-circle" />
														</Button>
													</div>
												</FormField>
											</div>
											<div className="pad-top-1rem">
												<Button type="submit" className="button is-primary " disabled={!isValid}>
													Speichern
												</Button>
												<Button type="button" className="button is-secondary" onClick={onClose}>
													Abbrechen
												</Button>

												{expertise && (
													<Button type="button" className="button is-danger" onClick={onDelete}>
														Löschen
													</Button>
												)}
											</div>
										</div>
									</div>
								</Form>
							);
						}}
					</Formik>
				)}
			</Modal>
		</>
	);
});
