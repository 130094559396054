import React from 'react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import FormField from '../form/FormField';
import { useStore } from 'app/context';
import { Button } from '../common/Button';
import { useHistory, useParams } from 'react-router';
import routes from 'routes';
import { notify } from '../common/notify';
import { IRedirectParms } from 'app/containers/AuthContainers';

const superLoginFormSchema = Yup.object({
	bensl: Yup.string().required('Bensl ist erforderlich').default(''),
	password: Yup.string().required('Passwort ist erforderlich').default(''),
	asBensl: Yup.string().required('Bensl ist erforderlich').default(''),
}).required();

type Login = Yup.InferType<typeof superLoginFormSchema>;

export const SuperLoginForm: React.FC = () => {
	const { authStore, session } = useStore();
	const history = useHistory();

	const { redirect } = useParams<IRedirectParms>();

	async function handleSubmit(values: Login) {
		await authStore.superLogin({ username: values.bensl, password: values.password, asUsername: values.asBensl });
		if (session.isLoggedIn) {
			if (redirect) {
				console.log('redirecting to ' + redirect);
				history.push('/' + redirect);
			} else {
				console.log('going to home');
				history.push(routes.HOME.getPath());
			}
		} else {
			console.log('not logged in');
			notify('Anmeldung fehlgeschlagen', '', 'error');
		}
	}

	return (
		<Formik
			validationSchema={superLoginFormSchema}
			initialValues={{
				bensl: '',
				password: '',
				asBensl: '',
			}}
			onSubmit={handleSubmit}
		>
			{({ errors, touched, isValid }) => {
				return (
					<Form className="auth-form">
						<div className="auth-form-header">
							<div className="title">DVS</div>
						</div>

						<div>
							<FormField error={errors.bensl} touched={touched.bensl} label="BENSL">
								<Field className="pw-bensl" type="text" name="bensl" autoComplete="username" placeholder="Benutzername (BENSL)" autoFocus={true} />
							</FormField>
							<FormField error={errors.password} touched={touched.password} label="Passwort">
								<Field className="pw-password" type="password" name="password" autoComplete="password" placeholder="Passwort" />
							</FormField>
							<FormField error={errors.asBensl} touched={touched.asBensl} label="Fremder BENSL / BnrId">
								<Field className="pw-asbensl" type="text" name="asBensl" autoComplete="username" placeholder="Fremder BENSL / BnrId" />
							</FormField>
						</div>

						<div>
							<Button type="submit" className="button is-primary is-block pw-submit" isFormInvalid={!isValid}>
								Anmelden
							</Button>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
};
