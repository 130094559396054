import { useStore } from 'app/context';
import { Field } from 'formik';

export const BetreuerMultiSelectField = ({ ...props }) => {
	const { agtUserUiStore } = useStore();

	var { agtId, ...fieldProps } = props;

	return (
		<>
			<div className="pad-bottom-1rem">
				{agtUserUiStore.betreuerOptsAllExcludingSelf.map((p) => (
					<label key={p.value}>
						<Field {...fieldProps} type="checkbox" value={p.value.toString()} />
						<span>{p.label}</span>{' '}
					</label>
				))}
			</div>
		</>
	);
};
