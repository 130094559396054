import { TableModel } from 'app/components/table/table.model';
import { formatCurrency } from 'app/utils';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';

import { ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Label, Cell, ZAxis } from 'recharts';
import { IRadioButtonListItem, RadioButtonList } from 'app/components/common/RadioButtonList';
import _ from 'lodash';

import colors from 'assets/scss/colors.module.scss';
import moment from 'moment';

export interface IScatterPlotVarationModel {
	key: string;
	labelX: string;
	labelY: string;
	pathX: string;
	pathY: string;
	buttonLabel: string;
	plotColor: string | ((model: any) => string);
	plotColorProp?: string;
	domainX?: [any, any];
	ticksX?: number[];
}

export class ScatterPlotVarationModel {
	constructor(opts: IScatterPlotVarationModel) {
		this.key = opts.key;
		this.labelX = opts.labelX;
		this.labelY = opts.labelY;
		this.pathX = opts.pathX;
		this.pathY = opts.pathY;
		this.buttonLabel = opts.buttonLabel;
		this.plotColor = opts.plotColor;
		if (opts.plotColorProp) {
			this.plotColorProp = opts.plotColorProp;
		}
		if (opts.domainX) {
			this.domainX = opts.domainX;
		}
		if (opts.ticksX) {
			this.ticksX = opts.ticksX;
		}
	}
	key: string;
	labelX: string;
	labelY: string;
	pathX: string;
	pathY: string;
	buttonLabel: string;
	plotColor: string | ((model: any) => string);
	plotColorProp?: string;
	domainX: [any, any] = ['auto', 'auto'];
	ticksX: number[] = [];
}

export class ScatterPlotModel<T, P> {
	constructor(opts: any) {
		this.tableModel = opts.tableModel;
		this.variations = opts.variations;
	}

	tableModel: TableModel<T, P>;
	variations: ScatterPlotVarationModel[];
}

export interface IScatterPlotChart<T, P> {
	tableModel: TableModel<T, P>;
	variations: ScatterPlotVarationModel[];
	tooltip?: JSX.Element;
}

export const ScatterPlotChart = observer((props: IScatterPlotChart<any, any>) => {
	const [activeIndex, setActiveIndex] = useState<number | undefined>();

	let defaultVariation = props.variations[0];
	if (moment().month() < 9) {
		// Zeitziel vor Oktober
		const v = props.variations.find((x) => x.key.indexOf('zeit') >= 0);
		if (v) {
			defaultVariation = v;
		}
	}

	const [variation, setVariation] = useState<ScatterPlotVarationModel>(defaultVariation);

	const tm = props.tableModel;
	const buttons = props.variations.map((v) => {
		return { label: v.buttonLabel, value: v.key } as IRadioButtonListItem;
	});

	const formatYAxis = (val: any) => {
		return formatCurrency(val, 0, false);
	};

	const formatXAxis = (val: any) => {
		return val;
	};

	const onMouseEnter = (data: any, index: number) => {
		const agtId = tm.data[index].agtId;
		tm.setHoverId(agtId);
		setActiveIndex(index);
	};

	const onMouseLeave = () => {
		tm.setHoverId();
		setActiveIndex(undefined);
	};

	const activeCell = (props: any) => {
		const { cx, cy, fill } = props;
		return <circle cx={cx} cy={cy} r={10} fill={fill} stroke="none" />;
	};

	const fillColor = (entry: any) => {
		let plotColor = 'green';
		if (variation && typeof variation.plotColor === 'string') {
			plotColor = variation.plotColor;
		}
		if (variation && typeof variation.plotColor === 'function') {
			const prop = variation.plotColorProp ? variation.plotColorProp : variation.pathX;
			const val = _.get(entry, prop);
			plotColor = variation.plotColor(val);
		}
		return plotColor;
	};

	useEffect(() => {
		const index = tm.data.findIndex((d) => d.agtId === tm.hoverId);
		setActiveIndex(index);
	}, [tm.hoverId, tm.data]);

	const onRadioSelect = (key: any) => {
		setVariation(props.variations.find((v) => v.key === key)!);
	};

	const margin = {
		top: 20,
		bottom: 20,
		left: 20,
		right: 20,
	};

	return (
		<>
			<RadioButtonList value={variation.key} buttons={buttons} onSelect={onRadioSelect} />
			<ResponsiveContainer width="99%" height={200} debounce={300}>
				<ScatterChart margin={margin}>
					<CartesianGrid strokeDasharray="6 8" />
					<Scatter yAxisId="left" data={tm.data} fill={colors.border} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} activeIndex={activeIndex} activeShape={activeCell}>
						{tm.data.map((entry, index) => (
							<Cell key={`cell-${index}`} fill={fillColor(entry)} opacity={0.75} />
						))}
					</Scatter>
					<XAxis type="number" dataKey={variation.pathX} ticks={variation.ticksX} name="ZE" unit=" %" domain={variation.domainX} tickFormatter={formatXAxis}>
						<Label value={variation.labelX} offset={-10} position="insideBottom" />
					</XAxis>
					<YAxis yAxisId="left" type="number" dataKey={variation.pathY} name="Ergebnis" unit="" stroke={colors.grey} tickFormatter={formatYAxis}>
						<Label value={variation.labelY} offset={-10} position="insideLeft" angle={-90} />
					</YAxis>
					<ZAxis range={[200, 200]} />
					{props.tooltip}
				</ScatterChart>
			</ResponsiveContainer>
		</>
	);
});
