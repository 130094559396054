import { ForgotPasswordForm } from 'app/components/auth/ForgotPasswordForm';
import { LoginForm } from 'app/components/auth/LoginForm';
import { RegisterForm } from 'app/components/auth/RegisterForm';
import { ISetPasswordProps, SetPasswordForm } from 'app/components/auth/SetPassword';
import { SuperLoginForm } from 'app/components/auth/SuperLoginForm';
import { IVerifyPinProps, VerifyPinForm } from 'app/components/auth/VerifyPinForm';
import { notify } from 'app/components/common/notify';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import routes from 'routes';
import BaseContainer from './BaseContainer';

export interface IRedirectParms {
	redirect?: string;
}

const RedirectToHomeIfLoggedIn = observer(() => {

	const { session } = useStore();
	const history = useHistory();

	useEffect(() => {
		if (session.isLoggedIn) {
			history.push(routes.HOME.getPath());
		}

	}, [session.isLoggedIn])

	return <></>
});

export const LoginContainer: React.FC = observer(() => {
	return (
		<BaseContainer classModifier="is-auth">
			<RedirectToHomeIfLoggedIn />
			<LoginForm />
		</BaseContainer>
	);
});

export const LogoutContainer: React.FC = observer(() => {
	const { authStore } = useStore();
	authStore.logoutForTest();
	return <BaseContainer classModifier="is-auth">Logging out..</BaseContainer>;
});

export const SuperLoginContainer: React.FC = observer(() => {
	return (
		<BaseContainer classModifier="is-auth">
			<RedirectToHomeIfLoggedIn />
			<SuperLoginForm />
		</BaseContainer>
	);
});

export const RegisterContainer: React.FC = observer(() => {
	return (
		<BaseContainer classModifier="is-auth">
			<RegisterForm />
		</BaseContainer>
	);
});

export const ForgotPasswordContainer: React.FC = observer(() => {
	return (
		<BaseContainer classModifier="is-auth">
			<ForgotPasswordForm />
		</BaseContainer>
	);
});

export const SetPasswordContainer: React.FC<RouteComponentProps> = observer((props: RouteComponentProps) => {
	const history = useHistory();
	const [pprops, setProps] = useState<ISetPasswordProps>();
	useEffect(() => {
		const query = qs.parse(props.location.search, { ignoreQueryPrefix: true });
		if (!query.id || !query.token) {
			notify('Passwort setzen', 'Ungültig Anforderung');
			history.push(routes.LOGIN.getPath());
			return;
		}
		setProps({
			token: query.token as string,
			id: query.id as string,
		});
	}, [history, props.location.search]);

	return <BaseContainer classModifier="is-auth">{pprops && <SetPasswordForm {...pprops} />}</BaseContainer>;
});

export const VerifyPinContainer: React.FC<RouteComponentProps> = observer((props: RouteComponentProps) => {
	const history = useHistory();
	const [pprops, setProps] = useState<IVerifyPinProps>();
	useEffect(() => {
		const query = qs.parse(props.location.search, { ignoreQueryPrefix: true });
		if (!query.bensl) {
			notify('Pin Eingabe', 'Ungültig Anforderung');
			history.push(routes.LOGIN.getPath());
			return;
		}
		setProps({
			bensl: query.bensl as string,
		});
	}, [history, props.location.search]);

	return <BaseContainer classModifier="is-auth">{pprops && <VerifyPinForm {...pprops} />}</BaseContainer>;
});
