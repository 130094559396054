import { Einheit } from 'app/models/core/einheit.model';
import { BnrGpPosModel } from '../bnr.gp.pos.model';
import { PosUnDef } from '../gp';

const of90 = (val: number) => val * 0.9;

const posDef: BnrGpPosModel[] = [];

posDef.push(new BnrGpPosModel({ year: 2023, posId: 2235001, group: 'vss', posText: 'Weiterbildungspfl. BNR (IDD)', planKey: 'idd', einheit: Einheit.minuten }));
posDef.push(new BnrGpPosModel({ year: 2023, posId: 2235002, group: 'vss', posText: 'Weiterbildungsz. Fonds', planKey: 'weiterbildungfonds', einheit: Einheit.minuten }));
posDef.push(new BnrGpPosModel({ year: 2023, posId: 2235021, group: 'vss', posText: 'Nettomittelzufluss o. VZ', planKey: 'nettomittelzufluss', einheit: Einheit.lfNST })); // 5
posDef.push(new BnrGpPosModel({ year: 2023, posId: 2235031, group: 'vss', posText: 'Leben KnP80 NSt Digi', planKey: 'lebenknp', einheit: Einheit.bew })); // 1

//ok
posDef.push(new BnrGpPosModel({ year: 2023, posId: 2275621, calcFunc: of90, group: 'eh', posText: 'Fonds Bew+FRVZ oGeno 90%', planKey: 'fondsbewfrvz90', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2023, posId: 2275621, group: 'eh', posText: 'Fonds Bew+FRVZ oGeno 100%', planKey: 'fondsbewfrvz100', einheit: Einheit.bew }));

posDef.push(new BnrGpPosModel({ year: 2023, posId: 2275622, calcFunc: of90, group: 'eh', posText: 'Leben KnP Bew oGeno 90%', planKey: 'lebelknpbew90', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2023, posId: 2275622, group: 'eh', posText: 'Leben KnP Bew oGeno 100%', planKey: 'lebelknpbew100', einheit: Einheit.bew }));

posDef.push(new BnrGpPosModel({ year: 2023, posId: 2235101, group: 'neh', posText: 'Fonds Bew o. Geno 100%', planKey: 'wb_fondsbewertung', einheit: Einheit.bew })); // 9
posDef.push(new BnrGpPosModel({ year: 2023, posId: PosUnDef, group: 'neh', posText: 'AktivDepot Digital o. Ge', planKey: 'aktivdep', einheit: Einheit.bew })); // 9

posDef.push(new BnrGpPosModel({ year: 2023, posId: 2235111, group: 'neh', posText: 'Firmenfonds NMZ o. Geno ', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2023, posId: 2235115, group: 'neh', posText: 'berat.int. FG o. Geno', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2023, posId: 2235121, group: 'neh', posText: 'Leben KnP80 Bew Digi', planKey: 'wb_lebenknpbewoze', einheit: Einheit.bew })); // 7
posDef.push(new BnrGpPosModel({ year: 2023, posId: 2235131, group: 'neh', posText: 'Leben KnP EB Digi', planKey: 'wb_lebenebbew', einheit: Einheit.bew })); // 7

// probably ok
posDef.push(new BnrGpPosModel({ year: 2023, posId: 3835021, group: 'gpplus', posText: 'Wiederanlage', planKey: 'wb_wiederanlage', einheit: Einheit.bew })); // 10
posDef.push(new BnrGpPosModel({ year: 2023, posId: 3835031, group: 'gpplus', posText: 'Firmen Fonds Bew o.Geno', planKey: 'firmenfondsbew', einheit: Einheit.bew })); // 10

export const KmePositionen = posDef;
