import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import moment from 'moment';
import { WMHeading } from './PlainTextWochenmail';
import { IWMStyle, WmTableRow } from './Wochenmail';

export const WochenmailSharedDocs = observer((props: IWMStyle) => {
	const { wmStyles, isText } = props;
	const { wochenmailtUiStore } = useStore();

	const year = moment().format('YYYY');
	const title = `Geteilte Dokumente ${year}`;

	return (
		<>
			{wochenmailtUiStore.loaded && wochenmailtUiStore.showSharedDocs && (
				<>
					{isText ? (
						<>
							<WMHeading title={title} />
							Neu seit letzter Woche: {wochenmailtUiStore.sharedDocs.newThisWeek} <br />
							Ungelesen: {wochenmailtUiStore.sharedDocs.unreadTotal} <br />
							<br />
							<br />
						</>
					) : (
						<WmTableRow>
							<table width={'100%'} style={wmStyles.innerTableStyle}>
								<tbody>
									<tr>
										<td colSpan={2} style={wmStyles.headingTdStyle}>
											<h2 style={{ ...wmStyles.headingStyle, textAlign: 'center' }}>{title}</h2>
										</td>
									</tr>
									<tr>
										<td style={{ ...wmStyles.blueBlock, textAlign: 'center' }}>
											<p>Neu seit letzter Woche</p>
											<h2 style={{ ...wmStyles.headingStyle, textAlign: 'center' }}>{wochenmailtUiStore.sharedDocs.newThisWeek}</h2>
										</td>
										<td style={{ ...wmStyles.yellowBlock, textAlign: 'center' }}>
											<p>Ungelesen</p>
											<h2 style={{ ...wmStyles.headingStyle, textAlign: 'center' }}>{wochenmailtUiStore.sharedDocs.unreadTotal}</h2>
										</td>
									</tr>
								</tbody>
							</table>
						</WmTableRow>
					)}
				</>
			)}
		</>
	);
});
