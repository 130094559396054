import { AgtDigiVM } from 'app/stores/ui/agt.digifaktor.ui.store';
import { computed } from 'mobx';
import { AgenturModel } from '../agentur.model';
import { LeadnowCheckFormItem } from './leadnow.check.form.item';
import { LeadnowFormItems } from './leadnow.form.items';
import { LeadnowKVModel } from './leadnow.kv.model';
import colors from 'assets/scss/colors.module.scss';

export enum LeadNowEmpfehlung {
	none = 'none',
	'yes.todo' = 'yes.todo',
	'rejected' = 'rejected',
	'yes' = 'yes',
}

const dfKeys = ['OT', 'ESIGN', 'EKOMI', 'GTM', 'WA'];

export class LeadNowCheckVM {
	constructor(agt: AgenturModel, items: LeadnowKVModel[], df?: AgtDigiVM) {
		this.agtId = agt.agtId;
		this.agt = agt;
		this.items = LeadnowFormItems.map((x) => new LeadnowCheckFormItem(x, items));
		this.items.forEach((i) => i.data.agtId === agt.agtId);
		this.df = df;
		this.ensureDigiState();
	}
	agtId: number;
	agt: AgenturModel;
	items: LeadnowCheckFormItem[];

	df?: AgtDigiVM;

	ensureDigiState() {
		const dfItems = this.items.filter((i) => i.data.groupId === 'df');
		dfKeys.forEach((k) => {
			const item = dfItems.find((i) => i.data.key === k);
			if (item && this.df) {
				const dfVal = (this.df as any)[k];
				if (dfVal > 0) {
					item.data.state = 'done';
				}
			}
		});
	}

	@computed
	get score() {
		if (!this.df || this.df === undefined) {
			return 0;
		}
		let s = 0;
		const d = this.df;
		if (d.GTM && d.GTM > 0) {
			s += 30;
		} else return 0;
		if (d.OT && d.OT > 0) s += 15;
		if (d.ESIGN && d.ESIGN > 0) s += 20;
		if (d.EKOMI && d.EKOMI > 0) s += 20;
		if (d.WA && d.WA > 0) s += 15;

		return s;
	}

	@computed
	get overallState() {
		const i = this.items.find((i) => i.data.key === 'overallState');
		if (i) {
			return i.data.state;
		}
		return undefined;
	}

	@computed
	get empfehlung(): LeadNowEmpfehlung {
		let rec = LeadNowEmpfehlung.none;
		const score = this.score;
		if (!this.overallState && score >= 80) {
			rec = LeadNowEmpfehlung['yes.todo'];
		}
		if (this.overallState === 'maybe') {
			rec = LeadNowEmpfehlung['yes.todo'];
		}
		if (this.overallState === 'rejected') {
			rec = LeadNowEmpfehlung.rejected;
		}
		if (this.overallState === 'member') {
			rec = LeadNowEmpfehlung.yes;
		}
		return rec;
	}

	// groups: [], // akaFormGroups

	@computed
	get iconType() {
		switch (this.empfehlung) {
			case LeadNowEmpfehlung.yes:
				return 'full';
			case LeadNowEmpfehlung['yes.todo']:
				return 'outline';
			case LeadNowEmpfehlung['rejected']:
				return 'rejected';
			default:
				return '';
		}
	}

	@computed
	get iconColor() {
		switch (this.empfehlung) {
			case LeadNowEmpfehlung.yes:
			case LeadNowEmpfehlung['yes.todo']:
				return LeadNowCheckVM.ColorGood;
			case LeadNowEmpfehlung.rejected:
				return LeadNowCheckVM.ColorBad;
			default:
				return LeadNowCheckVM.ColorNone;
		}
	}

	static ColorGood = colors.turquoise;
	static ColorBad = colors.red;
	static ColorNone = colors.textLight;
}
