import { BtStueckTimeModel, BtStueckTimeModelChart } from 'app/components/charts/BtStueckTimeChart';
import { useStore } from 'app/context';
import { AgenturModel } from 'app/models/agentur.model';
import { useEffect, useState } from 'react';
import colors from 'assets/scss/colors.module.scss';

interface IAgt {
	agt?: AgenturModel;
}

export const AgtBewertungsVerlauf = (props: IAgt) => {
	const { berichteUiiStore, agtBestandUiStore: bestandUiStore } = useStore();
	const [model, setModel] = useState<BtStueckTimeModel>();
	let agt: AgenturModel | undefined;
	if (props.agt) {
		agt = props.agt;
	} else {
		agt = berichteUiiStore.selectedAgt;
	}

	useEffect(() => {
		if (agt) {
			const _model = new BtStueckTimeModel({
				title: 'Bewertung',
				fill: colors.turquoise,
				btKey: 'production.val.bt',
				btLabel: 'Bewertung',
				stKey: 'production.val.st',
				stLabel: 'Stück',
				timeKey: 'bmLabel',
				timeLabel: 'Monat',
			});
			bestandUiStore.find1YearHistoryByAgtId(agt.agtId).then((res) => {
				_model.agentur = agt;
				_model.data = res;
				setModel(_model);
			});
		}

		return () => {
			setModel(undefined);
		};
	}, [agt, bestandUiStore]);
	return <>{agt && model && <BtStueckTimeModelChart {...model} />}</>;
};
