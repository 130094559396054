import React, { useState } from 'react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import FormField from '../form/FormField';
import { useStore } from 'app/context';
import { Button } from '../common/Button';
import routes from './../../../routes';
import { useHistory } from 'react-router';
import { IResponse } from 'app/stores/core/base.api.store';
import { notify } from '../common/notify';

const verifyPinFormSchema = Yup.object({
	pin: Yup.string().required('Ein Pin ist eforderlich').default(''),
}).required();

type VerifyPin = Yup.InferType<typeof verifyPinFormSchema>;

export interface IVerifyPinProps {
	bensl: string;
}

export const VerifyPinForm = (props: IVerifyPinProps) => {
	const [response, setResponse] = useState<IResponse | undefined>();
	const authStore = useStore().authStore;
	const history = useHistory();

	async function handleSubmit(values: VerifyPin) {
		const res = await authStore.verifyPin(props.bensl, values.pin);
		if (!res.data && res.status) {
			setResponse(res);
		} else {
			notify('PIN Bestätigt', 'Vielen Dank!', 'success');
			history.push(routes.HOME.getPath());
		}
	}

	return (
		<Formik
			validationSchema={verifyPinFormSchema}
			initialValues={{
				pin: '',
			}}
			onSubmit={handleSubmit}
		>
			{({ errors, touched, isValid }) => {
				return (
					<Form className="auth-form">
						<div className="auth-form-header">
							<div className="title">DVS</div>
						</div>

						<p>Zur Sicherheit haben wir Ihnen eine PIN per E-Mail gesendet. Bitte diese PIN hier eingeben.</p>
						<div className="project-details">
							<FormField className="title" error={errors.pin} touched={touched.pin} label="PIN">
								<Field type="text" name="pin" autoComplete="username" placeholder="PIN" autoFocus={true} />
							</FormField>
						</div>

						{response && <div>{response.message}</div>}
						{/* {response && <AuthError {...response} />} */}

						<div>
							<Button type="submit" className="button is-primary is-block" isFormInvalid={!isValid}>
								PIN Abschicken
							</Button>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
};
