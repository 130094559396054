import { AgenturModel, AgtType } from 'app/models/agentur.model';
import { BaseCachedEntityStore } from './core/base.cached.entity.store';
import { SessionStore } from './session.store';
import { KvAgtCustomExpertiseStore } from './kv.agt.custom.expertise.store';
import { computed } from 'mobx';

export class AgenturStore extends BaseCachedEntityStore<AgenturModel> {
	apiPath = 'api/agt';
	idProp: string = 'agtId';
	kvAgtCustomExpertiseStore: KvAgtCustomExpertiseStore;

	constructor(session: SessionStore, kvAgtCustomExpertiseStore: KvAgtCustomExpertiseStore) {
		super(session);
		this.kvAgtCustomExpertiseStore = kvAgtCustomExpertiseStore;
	}

	createInstances(opts: any): AgenturModel {
		const agt = new AgenturModel(opts);
		this.kvAgtCustomExpertiseStore.findByAgtId(agt.agtId).then((customExp) => {
			if (customExp) {
				agt.setCustomExpertisen(customExp);
			}
		});
		return agt;
	}

	async findAll(): Promise<AgenturModel[]> {
		const data = await this.__findAll();
		return data.filter((a) => a.vtnrLebend === 1);
	}

	@computed
	get agenturen() {
		return this._items.filter((a) => a.vtnrLebend === 1);
	}

	@computed
	get agenturenWithoutAda() {
		return this.agenturen.filter((agt) => agt.agtType !== AgtType.ada);
	}
}

export class AdminAgenturStore extends AgenturStore {
	apiPath = 'api/agt/admin';
	idProp: string = 'agtId';

	createInstances(opts: any): AgenturModel {
		const agt = new AgenturModel(opts);
		// this.gebieteStore.getById(agt.inspId).then((geb) => {
		// 	if (geb) {
		// 		agt.setGebiet(geb);
		// 	}
		// });
		// const aladin = this.aladinStore.findByAgtId(agt.agtId);
		// if (aladin) {
		// 	agt.setAladin(aladin);
		// }
		// this.kvAgtCustomExpertiseStore.findByAgtId(agt.agtId).then((customExp) => {
		// 	if (customExp) {
		// 		agt.setCustomExpertisen(customExp);
		// 	}
		// });
		return agt;
	}
}
