import React from 'react';

const BusyScreen: React.FC = () => {
	return (
		<div className="busy-screen">
			<div className="loader" />
		</div>
	);
};

export default BusyScreen;
