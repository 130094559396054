import { computed } from 'mobx';
import moment from 'moment';
import { PersonalVM } from './personal.vm';

export class TeilhaberModel {
	constructor(opts: any) {
		this.agtId = opts.agtId;
		this.pro3paket1 = opts.pro3paket1;
		this.thBeginn = new Date(opts.thBeginn);
		this.thBensl = opts.thBensl;
		this.thGebdat = new Date(opts.thGebdat);
		this.thVname = opts.thVname;
		this.thZname = opts.thZname;
		this.thZubdat = new Date(opts.thZubdat);
	}

	@computed
	get fullName() {
		return this.thZname + ', ' + this.thVname;
	}

	@computed
	get gebDat(): Date {
		return moment(this.thGebdat).toDate();
	}

	@computed
	get identifier() {
		return this.fullName + '-' + this.gebDat;
	}

	agtId: number;
	pro3paket1: boolean;
	thBeginn: Date;
	thBensl: Date;
	thGebdat: Date;
	thVname: string;
	thZname: string;
	thZubdat: Date;
	toPersonalModel(): PersonalVM {
		return new PersonalVM({
			// id: this.identifier,
			kat: 'teilhaber',
			id: this.agtId + this.thGebdat.toString(),
			agtId: this.agtId,
			bnrId: 0,
			bensl: this.thBensl,
			gebDat: this.thGebdat,
			vorname: this.thVname,
			nachname: this.thZname,
			beitrittDat: this.thZubdat,
		});
	}
}
