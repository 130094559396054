import { useStore } from 'app/context';
import { BnrBlacklistModel } from 'app/stores/admin.bnrblacklist.store';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import routes from 'routes';
import Panel from '../../Panel';
import { AdminBnrBlacklistForm } from './AdminBnrBlacklistForm';

export const AdminBnrBlacklistDetail = observer(() => {
	const { adminBnrBlacklistUiStore } = useStore();
	const [title, setTitle] = useState('');
	const [blacklist, setBlacklist] = useState<BnrBlacklistModel>();

	const history = useHistory();
	useEffect(() => {
		setBlacklist(undefined);
		if (adminBnrBlacklistUiStore.current) {
			setTitle('Blacklist ' + adminBnrBlacklistUiStore.current.bnrId);
			setBlacklist(adminBnrBlacklistUiStore.current);
		}
		if (adminBnrBlacklistUiStore.newBlacklist) {
			setTitle('Neuen Eintrag ');
			setBlacklist(adminBnrBlacklistUiStore.newBlacklist);
		}
	}, [adminBnrBlacklistUiStore.newBlacklist, adminBnrBlacklistUiStore]);

	const onClose = () => {
		runInAction(() => {
			adminBnrBlacklistUiStore.current = undefined;
			adminBnrBlacklistUiStore.newBlacklist = undefined;
			history.push(routes.ADMINBNRBLACKLIST.getPath());
		});
	};

	return (
		<>
			{blacklist && (
				<Panel title={title} header={<> </>} onClose={onClose}>
					{blacklist && (
						<>
							<AdminBnrBlacklistForm blacklist={blacklist} />
						</>
					)}
				</Panel>
			)}
		</>
	);
});
