import React, { useEffect, useMemo } from 'react';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { Table } from './../table/table';
import { ITableColumn, TableModel, TableRow } from './../table/table.model';
import { formatDate } from 'app/utils';
import { AgenturNameCell } from '../shared/AgenturName.cell';
import { TaskVM } from 'app/stores/ui/task.ui.store';

interface ITaskList {
	agtId?: number;
}

export const TasksList = observer((props: ITaskList) => {
	const { taskUiStore, uiStore } = useStore();
	taskUiStore.findAll();

	const tm = useMemo(() => {
		const tm = new TableModel<TaskVM, number>();
		let cols: ITableColumn<TaskVM, number>[] = [];
		if (!props.agtId) {
			cols.push({
				label: 'Agentur',
				path: 'agt.name',
				render: AgenturNameCell,
			});
		}
		cols = cols.concat([
			{
				label: 'Plan',
				path: 'plan.year',
			},
			{
				label: 'Titel',
				path: 'task.text',
			},
			{
				label: 'Thema',
				path: 'task.thema.title',
			},

			{
				label: 'Status',
				path: 'statusText',
			},

			{
				label: 'Wiedervorlage',
				path: 'task.dueDate',
				format: (val: any) => {
					return formatDate(val);
				},
			},

			{
				label: 'Erstellt',
				path: 'task.created',
				format: (val: any) => {
					return formatDate(val);
				},
			},
			{
				label: 'Geändert',
				path: 'task.modified',
				format: (val: any) => {
					return formatDate(val);
				},
			},
		]);

		tm.onRowClick = (row: TableRow<TaskVM>) => {
			taskUiStore.setCurrentModalTask(row.data);
			uiStore.showModal(uiStore.modalIds.taskViewModal);
		};
		tm.setCols(cols);
		tm.sortBy = 'task.modified';
		tm.sortAsc = false;
		tm.idProperty = 'id';
		tm.idType = 'string';
		return tm;
	}, [props.agtId, taskUiStore, uiStore]);

	useEffect(() => {
		tm.setRowData(taskUiStore.currentItems);
	}, [taskUiStore.currentItems, tm]);

	return (
		<>
			<Table tm={tm}></Table>
		</>
	);
});
