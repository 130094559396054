import { Einheit } from 'app/models/core/einheit.model';
import { BnrGpPosModel } from '../bnr.gp.pos.model';

const posDef: BnrGpPosModel[] = [];

posDef.push(new BnrGpPosModel({ year: 2024, posId: [2215001, 2216001], group: 'vss', posText: 'Weiterbildungspfl. BNR', planKey: 'idd', einheit: Einheit.stck }));
posDef.push(new BnrGpPosModel({ year: 2024, posId: [2215010, 2216010], group: 'vss', posText: 'Fzg. in Kleinflotten/FPM', planKey: 'fzgkleinflotten', einheit: Einheit.stck }));
posDef.push(new BnrGpPosModel({ year: 2024, posId: [2215030, 2216030], group: 'vss', posText: 'Unternehmensschutz', planKey: 'unternehmensschutz', einheit: Einheit.stck }));
posDef.push(new BnrGpPosModel({ year: 2024, posId: [2215035, 2216035], group: 'vss', posText: 'Unfall Bew o. Anp. Digi', planKey: 'unfallbew', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2024, posId: [2215036, 2216036], group: 'vss', posText: 'Unf-Risiko Best.-Zuw. ST', planKey: 'unfallrisikobew', einheit: Einheit.stck }));

posDef.push(new BnrGpPosModel({ year: 2024, posId: [2215041, 2216041], group: 'eh', posText: 'F-Sach Bewertung', planKey: 'firmensachbewertung', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2024, posId: [2215042, 2216042], group: 'eh', posText: 'Unfall Bew o. Anp.', planKey: 'unfallbewoanp', einheit: Einheit.bew }));

posDef.push(new BnrGpPosModel({ year: 2024, posId: [2215111, 2216111], group: 'neh', posText: 'Firmen Sach o. Kr. Bew', planKey: 'firmensachokrbew', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2024, posId: [2215121, 2216121], group: 'neh', posText: 'Firmen Kraft Bew', planKey: 'firmenkraftbew', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2024, posId: [2215131, 2216131], group: 'neh', posText: 'Firmen Rechtsschutz', planKey: 'firmenrechtschutz', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2024, posId: [2215141, 2216141], group: 'neh', posText: 'F-Sach o.Kr. BZUW-Beitrag', planKey: 'fsachokrbzwu', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2024, posId: [2215151, 2216151], group: 'neh', posText: 'Unfall Anschlussgeschäft', planKey: 'unfallanschl', einheit: Einheit.bew }));

export const BsgPositionen = posDef;
