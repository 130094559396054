import { BaseCachedEntityStore } from './core/base.cached.entity.store';

interface IAkumaPrimeModelOpts {
	aid: number;
	bm: number;
	j: string;
	key: string;
	key2: string;
	modified: number;
}

export interface IAkumaPlanerValues {
	bm: number;
	km: number;
	nu: number;
	nb: number;
	zagt: number;
	zma: number;
	zu: number;
	gesamt: number;
}
export class AkumaPlanerKWModel implements IAkumaPlanerValues {
	constructor(opts: IAkumaPrimeModelOpts) {
		this.bm = opts.bm;
		this.year = Math.floor(this.bm / 100);
		this.agtId = opts.aid;
		const j = JSON.parse(opts.j || '{}');
		this.km = j.km ? j.km : 0;
		this.nu = j.nu ? j.nu : 0;
		this.nb = j.nb ? j.nb : 0;
		this.zagt = j.zagt ? j.zagt : 0;
		this.zma = j.zma ? j.zma : 0;

		//für gpwertung 6 mal im Jahr : ku /gesamt >= 0.5
		this.zu = this.zagt + this.zma;
		this.gesamt = this.km + this.nb + this.nu + this.zagt + this.zma;
		this.gpWochen = this.zu / this.gesamt >= 0.5 ? 1 : 0;
	}

	bm: number;

	year: number;

	agtId: number;
	km: number;
	nu: number;
	nb: number;
	zagt: number;
	zma: number;

	zu: number;
	gesamt: number;
	gpWochen: number;
}

export class KvAgenturYearAkumaPlanerStore extends BaseCachedEntityStore<AkumaPlanerKWModel> {
	idProp: string = 'id';
	apiPath = 'api/kv_agentur_ms/akumaplaner';

	createInstances(opts: any): AkumaPlanerKWModel {
		return new AkumaPlanerKWModel(opts);
	}
}
