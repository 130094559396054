import { Steart } from 'app/models/core/steart.model';
import { ZeWidgetDef, ZeWidgetItem } from './vbl.ze.widgets';

export const KmeZeWidgets: ZeWidgetDef[] = [
	new ZeWidgetDef({
		title: 'Fonds inkl. FRV-Zusatzbewertung und Produkte mit neuen Garantien',
		key: 'ZE_FondsZusatBewUndProd_KME',
		subTitle: '(Bewertung ohne ZE)',
		stearts: [Steart.KME],
		items: [
			new ZeWidgetItem({
				text: <strong>Summe der Zielerfüllungen im indviduellen Arbeitsgebiet 190%</strong>,
				posId: 2235045,
				einheit: '%',
			}),
			new ZeWidgetItem({
				text: <strong>Mindestens in Fonds inklusive FRV-Zusatzbewertung 90%</strong>,
				posId: 2235041,
			}),
			new ZeWidgetItem({
				text: <strong>Mindestens in Kapitalmarktnahe Produkte 90%</strong>,
				posId: 2235043,
			}),
		],
	}),

	new ZeWidgetDef({
		title: 'oder Fonds inkl. FRV-Zusatzbewertung',
		key: 'ZE_Fonds_KME)',
		subTitle: '(Bewertung)',
		stearts: [Steart.KME],
		items: [
			new ZeWidgetItem({
				text: <strong>Individuelles Arbeitsgebietsziel 100%</strong>,
				posId: 2235042,
			}),
		],
	}),
];
