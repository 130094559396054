import { Button } from 'app/components/common/Button';
import { Modal } from 'app/components/Modal';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { AdminNewsEditForm } from './AdminNewsEditForm';
import { NewsVM } from 'app/stores/ui/news.ui.store';
import { useHistory } from 'react-router';
import routes from 'routes';

export const AdminNewNewsButton = observer(() => {
	const { uiStore } = useStore();

	const open = () => {
		uiStore.showModal(uiStore.modalIds.adminNewNews);
	};
	return (
		<>
			<Button type="button" className="button is-secondary" onClick={open}>
				Neue News anlegen
			</Button>
			<AdminNewNewsModal />
		</>
	);
});

export const AdminNewNewsModal = observer(() => {
	const { uiStore } = useStore();
	const history = useHistory();
	const onClose = () => {
		history.push(routes.ADMINNEWS.getPath());
		uiStore.hideModal(uiStore.modalIds.adminNewNews);

	};

	const newNews: NewsVM = new NewsVM({
		// id: 0,
		title: '',
		text: '',
		plaintext: '',
		publishDate: new Date(),
		publishForRoles: [],
		authors: '',
	});

	return (
		<Modal modalId={uiStore.modalIds.adminNewNews} title={'Neue News'} onClose={onClose}>
			<AdminNewsEditForm news={newNews} onClose={onClose} />
		</Modal>
	);
});
