import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { WMHeading } from './PlainTextWochenmail';
import { IWMStyle, WMErrungenschaftBlock, WmTableRow, getErrungenschaftBorderColor } from './Wochenmail';
import colors from 'assets/scss/colors.module.scss';

export const WochenmailAgtErrungenschaften = observer((props: IWMStyle) => {
	const { wmStyles, isText } = props;
	const { wochenmailtUiStore } = useStore();
	const title = `Neue Agentur Errungenschaften`;

	const buttonStyles = {
		background: colors.purple,
		color: colors.white,
		border: `10px solid ${colors.purple}`,
		textDecoration: 'none',
	};

	return (
		<>
			{wochenmailtUiStore.loaded && wochenmailtUiStore.showAgtErrugenshaften && (
				<>
					{isText ? (
						<>
							<WMHeading title={title} />
							{wochenmailtUiStore.changedAgtErrungenschaften.map((er) => (
								<span key={er.errungenschaft.key}>
									{er && (
										<>
											{' '}
											* {er.errungenschaft.modal.title}: {er.countCurrentKw}  <br />
										</>
									)}
								</span>
							))}
							<br />
							<br />
						</>
					) : (
						<WmTableRow>
							<table width={'100%'} style={{ ...wmStyles.innerTableStyle, borderCollapse: 'collapse' }}>
								<tbody>
									<tr key={'start'}>
										<td style={wmStyles.headingTdStyle}>
											<h2 style={{ ...wmStyles.headingStyle, textAlign: 'center' }}>{title}</h2>
											<p>Anzahl Agenturen welche die folgende(n) Errungenschafte(n) diese Wochen erreicht haben</p>

										</td>
									</tr>
									{wochenmailtUiStore.changedAgtErrungenschaften.map((er, i) => (
										<tr
											key={er.errungenschaft.key + i.toString()}
											style={{
												borderStyle: 'solid',
												borderWidth: '2px',
												borderColor: 'transparent',
												borderBottomColor: getErrungenschaftBorderColor(er.errungenschaft.key),
											}}
										>
											<td style={{ verticalAlign: 'top', paddingTop: '14px', whiteSpace: 'nowrap' }}>

												<strong>{er.errungenschaft.modal.title}</strong>
											</td>
											<td style={{ verticalAlign: 'top', paddingTop: '14px', whiteSpace: 'nowrap' }}>{er.countCurrentKw}</td>
										</tr>
									))}

									<tr key={'end'}>
										<td style={wmStyles.headingTdStyle}>
											<br />
											<br />
											<a style={buttonStyles} href="https://dvs-app.de?mtm_campaign=wochenmail_errungenschaften">
												<strong>Zum Bericht</strong>
											</a>
										</td>
									</tr>
								</tbody>
							</table>
						</WmTableRow>
					)}
				</>
			)}
		</>
	);

	/*
	return (
		<>
			{wochenmailtUiStore.loaded && wochenmailtUiStore.showAgtErrugenshaften && (
				<>
					{isText ? (
						<>
							<WMHeading title={title} />
							{wochenmailtUiStore.changedAgtErrungenschaften.map((agt) => (
								<>
									* {agt.agt.name} ({agt.agt.agtId}) <br />
									{agt.changes.map((e) => (
										<>
											-- {e.def.modal.title} <br />
										</>
									))}
									<br />
								</>
							))}
							<br />
							<br />
						</>
					) : (
						<WmTableRow>
							<table width={'100%'} style={wmStyles.innerTableStyle}>
								<tbody>
									<tr>
										<td style={wmStyles.headingTdStyle}>
											<h2 style={{ ...wmStyles.headingStyle, textAlign: 'center' }}>{title}</h2>
										</td>
									</tr>
									{wochenmailtUiStore.changedAgtErrungenschaften.map((agt) => (
										<>
											<tr key={agt.agt.agtId}>
												<td style={{ verticalAlign: 'top', paddingTop: '14px', whiteSpace: 'nowrap' }}>
													<strong>{agt.agt.name}</strong> ({agt.agt.agtId})
												</td>
											</tr>
											<tr>
												<td>
													{agt.changes.map((e) => (
														<WMErrungenschaftBlock item={e} key={e.def.key} />
													))}
												</td>
											</tr>
										</>
									))}
								</tbody>
							</table>
						</WmTableRow>
					)}
				</>
			)}
		</>
		);
		*/
});
