import { Button } from 'app/components/common/Button';
import { Icon } from 'app/components/common/Icon';
import { Modal } from 'app/components/Modal';
import { useStore } from 'app/context';

export const CoronaLeitfadenButton = () => {
	const { uiStore } = useStore();
	const modalId = uiStore.modalIds.coronaLiquiFaden;
	const onClick = () => {
		uiStore.showModal(modalId);
	};

	return (
		<>
			<LiquiTextModal />
			<Button className="button is-primary is-inverted is-small" onClick={onClick}>
				<Icon iconClass="book" />
				<span>Liquiditäts Leitfaden</span>
			</Button>
		</>
	);
};

export const LiquiTextModal = () => {
	const { uiStore } = useStore();
	const modalId = uiStore.modalIds.coronaLiquiFaden;
	const onClose = () => {
		uiStore.hideModal(modalId);
	};
	return (
		<Modal modalId={modalId} title="Liquiditäts Leitfaden" onClose={onClose}>
			<div className="content ">
				<h3>Corona Liquiditätscheck - Hilfen bei finanziellen Engpässen </h3>
				{/* <ul>
				<li><a {{action "jumpTo" "01"}} href="#">01 Hausbank kontaktieren / Dispo prüfen</a></li>
				<li><a {{action "jumpTo" "02"}} href="#">02 Steuerlast senken bzw. stunden</a></li>
				<li><a {{action "jumpTo" "03"}} href="#">03 Entgeltvorzahlungen im Krankheitsfall (Umlage U1</a>)</li>
				<li><a {{action "jumpTo" "04"}} href="#">04 Corona Soforthilfen für Selbständige</a></li>
				<li><a {{action "jumpTo" "05"}} href="#">05 Bürgschaften / Liquiditätskredite durch die Förderbanken (KfW, Landesförderbanken</a>)</li>
				<li><a {{action "jumpTo" "06"}} href="#">06 Kurzarbeit und Kurzarbeitergeld</a></li>
			</ul> */}

				<h4 id="01">01 Hausbank kontaktieren / Dispo prüfen</h4>
				<p>Falls Sie einen kurzfristigen(!) Liquiditätsengpass erwarten sollten Sie mit Ihrer Hausbank sprechen und prüfen ob Ihr Dispo ausreichend ist und so ggf. unnötig hohe Zinsen vermeiden.</p>

				<h4 id="02">02 Steuerlast senken bzw. stunden</h4>
				<p>Sprechen Sie mit Ihrem Steuerberate oder Finanzamt.</p>
				<p>
					Folgende Möglichkeiten sollten Sie in Betracht ziehen:
					<ul className="dotted">
						<li>Anstehende Steuerzahlungen stunden oder in Raten zahlen</li>
						<li>Herabsetzung oder Aussetzung laufender Vorauszahlungen zur Einkommenssteuer bzw. Körperschaftssteuer. </li>
					</ul>
				</p>

				<h4 id="03">03 Entgeltvorzahlungen im Krankheitsfall (Umlage U1)</h4>
				<strong>Nur für Rechtsformen mit mehr als einem Mitarbeiter relevant.</strong>
				<p>Wenn Mitarbeiter krankgeschrieben werden, ist der Arbeitgeber verpflichtet, ihnen den Lohn weiter zu zahlen. Für Unternehmen mit weniger als 30 Mitarbeitern übernimmt hier die Krankenkassen 40-80 % der Kosten.</p>
				<p>Bei Mitarbeiter, die wegen einer Coronavirus-Infektion in Quarantäne müssen, werden dem Arbeitgeber von der zuständigen Behörde die ausgezahlten Entgelte in voller Höher erstattet. (§ 56 Infektionsschutzgesetz)</p>
				<p>
					Weitere Infos{' '}
					<a href="https://www.gruenderlexikon.de/news/buchfuehrung/kranke-mitarbeiter-und-unternehmer-profitieren-von-der-neuen-entgeltfortzahlungsversicherung-84233122" target="_blank" rel="noreferrer">
						hier.
					</a>
				</p>

				<h4 id="04">04 Corona-Soforthilfen für Selbständige</h4>
				<p>
					Um das wirtschaftliche Überleben möglichst vieler Unternehmen zu sichern, haben alle Bundesländer Hilfsprogramme ins Leben gerufen.  Auf der folgenden Seite erhalten Sie insbesondere einen Überblick über die Soforthilfen, als direkten Zuschüssen, die vielfach
					nicht zurückgezahlt werden müssen.
					<a href="https://www.gruenderlexikon.de/news/kurz-notiert/corona-soforthilfen-der-bundeslaender-im-ueberblick-84233716" target="_blank" rel="noreferrer">
						Gründerlexikon: Soforthilfen der Bundesländer
					</a>
				</p>

				<h4 id="05">05 Bürgschaften / Liquiditätskredite durch die Förderbanken (KfW, Landesförderbanken)</h4>
				<p>Prinzipiell wurden von der Regierung Überbrückungshilfen zur finanziellen Absicherung in Aussicht gestellt. Konkrete Corona Programme gibt es aber noch nicht.</p>

				<h4 id="06">06 Kurzarbeit und Kurzarbeitergeld</h4>
				<strong>Nur für Rechtsformen mit mehr als einem Mitarbeiter relevant.</strong>
				<p>
					Die Agentur für Arbeit zahlt Kurzarbeitergeld, wenn nicht mehr genügend Aufträge vorhanden sind um die Mitarbeiter gemäß ihrer arbeitsvertraglich vereinbarten Arbeitszeit auszulasten. So soll verhindert werden das temporäre Auftragsschwankungen wie in der
					Coronokrise nicht sofort zur Entlassung der Arbeitnehmer führen.
				</p>
				<p>Berechnet wird das Kurzarbeitergeld nach dem Netto-Entgeltausfall. Die Beschäftigten erhalten grundsätzlich 60 % der Nettoentgeltdifferenz. Lebt ein Kind im Haushalt sind es 67 %. Kurzarbeitergelt ist auf maximal 12 Monate begrenzt.</p>

				<strong>Erleichterungen bei der Kurzarbeit in der Coronakrise:</strong>
				<p>
					Vor der Corona-Pandemie wurde der Antrag auf Kurzarbeitergeld nur genehmigt, wenn mindestens ein Drittel der Beschäftigten keine Arbeit mehr hatte. Nun reichen 10 % der Beschäftigten aus (die von einer Kürzung um mind. 10 % des Bruttogehalts betroffen sein
					müssen), um den Zuschuss zu beantragen. Wichtig ist, dass es sich um eine vorübergehende und nicht vermeidbare Maßnahme handelt. Mit anderen Worten müssen alle anderen Optionen wie Urlaub, Überstundenabbau und Home-Office vorher ausgeschöpft sein. Mehr
					Informationen zum Kurzarbeitergeld finden Sie auf der Website der 
					<a href="https://www.arbeitsagentur.de/news/corona-virus-informationen-fuer-unternehmen-zum-kurzarbeitergeld" target="_blank" rel="noreferrer">
						Arbeitsagentur
					</a>
					.
				</p>

				<strong>Beantragung von Kurzarbeit</strong>
				<p>
					Kurzarbeitergeld muss bei der zuständigen Arbeitsagentur beantragt werden. Das ist auch 
					<a href="https://www.arbeitsagentur.de/unternehmen/finanziell/kurzarbeitergeld-bei-entgeltausfall" target="_blank" rel="noreferrer">
						online
					</a>{' '}
					möglich.
				</p>
			</div>
		</Modal>
	);
};
