import Widget, { ChildWidget } from 'app/components/common/Widget';
import { observer } from 'mobx-react';
import { useStore } from 'app/context';
import { ZeWidgetDef, ZeWidgetItem } from 'app/models/gp/zewidgets/vbl.ze.widgets';
import { ZielErgebnisBalken, ZielErgebnisBalkenModel } from 'app/components/charts/ZielErgebnisBalken';
import React, { useEffect, useState } from 'react';
import { WidgetNavigate } from 'app/components/common/WidgetNavigate';
import { UserModel } from 'app/models/core/user.model';

interface IBnrZeWidget {
	def: ZeWidgetDef;
	disableCollapse?: boolean;
	widgetKey: string;
	year: number;
	useRpt2?: boolean;
	betreuer?: UserModel;
}

export const BnrZeWidget = observer((props: IBnrZeWidget) => {
	const rptPath = props.useRpt2 ? props.def.rptPath2 : props.def.rptPath;

	return (
		<>
			<Widget
				title={props.def.title}
				subtitle={props.def.subTitle}
				disableCollapse={props.disableCollapse}
				action={<>{<WidgetNavigate text={'Zum Bericht'} path={rptPath} />}</>}
				widgetId={props.widgetKey}
			>
				<div className="child-widgets">
					{props.def.items.map((def) => (
						<BnrZeItem key={def.planKey || def.posId.toString()} {...def} year={props.year} betreuer={props.betreuer} />
					))}
				</div>
			</Widget>
		</>
	);
});

export const EmptyBnrWidget = observer((props: IBnrZeWidget) => {
	const rptPath = props.useRpt2 ? props.def.rptPath2 : props.def.rptPath;
	return (
		<Widget title={props.def.title} disableCollapse={props.disableCollapse} action={<>{<WidgetNavigate text={'Zum Bericht'} path={rptPath} />}</>} widgetId={props.widgetKey}>
			<>TODO</>
		</Widget>
	);
});

export const TeamZeWidget = observer((props: IBnrZeWidget) => {
	const { teamUiStore, widgetUiStore } = useStore();
	const [visible, setVisible] = useState(false);
	const [betreuer, setBetreuer] = useState<UserModel>();

	const [rptPath, setRptPath] = useState<string>('');

	useEffect(() => {
		setVisible(false);

		let _rptPath = props.useRpt2 ? props.def.rptPath2 : props.def.rptPath;

		const widget = widgetUiStore.widgetItems.find((x) => x.widgetKey === props.widgetKey);
		if (widget && teamUiStore.currentSteart && widget.stearts && widget.stearts.includes(teamUiStore.currentSteart.steart)) {
			if (teamUiStore.current) {
				const _betreuer = teamUiStore.current.user;
				const currentUser = teamUiStore.agtUserUiStore.userStore.session.currentUser!.asUserModel;
				setBetreuer(_betreuer);
				// - [ ] Team EH Widget
				// - GSL alle
				// - VBL und Spezi nur eigene
				// - LPV alle bis auf BFG, SPS. Initial nix
				// - LSP nur BFG und SPS. Initial nix
				if (currentUser.isGSL) {
					setVisible(true);
				}
				if (currentUser.isVBL && currentUser.bnrId === _betreuer.bnrId) {
					setVisible(true);
				}
				if (currentUser.isSpezi && currentUser.bnrId === _betreuer.bnrId) {
					setVisible(true);
				}
				if (currentUser.isLeiter) {
					return;
				}
				_rptPath += '?betreuerBnrId=' + teamUiStore.currentBnrId;
			}
		}
		setRptPath(_rptPath || '');
	}, [teamUiStore.currentSteart, teamUiStore.currentBnrId, props.useRpt2, props.def.rptPath2, props.def.rptPath, props.widgetKey, widgetUiStore.widgetItems, teamUiStore]);

	return (
		<>
			{visible && (
				<Widget
					title={props.def.title}
					subtitle={props.def.subTitle}
					disableCollapse={props.disableCollapse}
					action={<>{<WidgetNavigate text={'Zum Bericht'} path={rptPath} />}</>}
					widgetId={props.widgetKey}
				>
					<div className="child-widgets">
						{props.def.items.map((def) => (
							<BnrZeItem key={def.planKey || def.posId.toString()} {...def} year={props.year} betreuer={betreuer} />
						))}
					</div>
				</Widget>
			)}
		</>
	);
});

interface IBnrZeItem {
	// extends ZeWidgetItem
	year: number;
	betreuer?: UserModel;
	einheit?: string;
	decimals?: number;
	rptPath?: string;
	title?: string;
	subtitle?: string;
	text: string | JSX.Element;
	planKey?: string;
	posId: number[];
	// _posId: number | number[];
}

export const BnrZeItem = observer((props: IBnrZeItem) => {
	const { bnrProdUiStore } = useStore();
	const [model, setModel] = useState<ZielErgebnisBalkenModel>();

	useEffect(() => {
		let betreuer = bnrProdUiStore.session.currentUser!.asUserModel;
		if (props.betreuer) {
			betreuer = props.betreuer;
		}
		if (props.planKey) {
			bnrProdUiStore.getZeForPosIdForBetreuerByPlanKey(betreuer, props.planKey, props.year).then((res) => {
				setModel(res);
			});
		} else if (props.posId) {
			bnrProdUiStore.getZeForPosIdForBetreuerByPosId(betreuer, props.posId, props.year).then((res) => {
				setModel(res);
			});
		} else {
			setModel(undefined);
		}
	}, [bnrProdUiStore, props.posId, props.year, props.betreuer, props.planKey]);

	return (
		<>
			{model && (
				<ChildWidget title={props.title} subtitle={props.subtitle} action={<WidgetNavigate text={'Zum Bericht'} path={props.rptPath} />}>
					{props.text}
					<ZielErgebnisBalken model={model} einheit={props.einheit} decimals={props.decimals} />
				</ChildWidget>
			)}
		</>
	);
});
