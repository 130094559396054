import { observer } from 'mobx-react';
import { useStore } from 'app/context';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { RadioButtonList } from 'app/components/common/RadioButtonList';
import { Button } from 'app/components/common/Button';
import routes from 'routes';
import { TemplateSelectorModal } from 'app/components/documents/TemplateSelectorModal';
import { TemplateVM } from 'app/stores/ui/template.ui.store';
import { DokumentUploadButton } from 'app/components/documents/DokumentUploadButton';

export const AgtPlanungNavi = observer(() => {
	const { agtPlanUiStore, agenturUiStore, documentUiStore, uiStore } = useStore();

	const buttons = agtPlanUiStore.planYears.map((y) => {
		return {
			label: y.toString(),
			value: y,
		};
	});

	const history = useHistory();

	const onSelect = (val: number | string) => {
		const y = parseInt(val as string);
		setYear(y);
		history.push(routes.AGENTUR.getPath(agenturUiStore.current!.agtId.toString(), 'planung', y.toString()));
	};

	const [year, setYear] = useState(agtPlanUiStore.currentYear);
	useEffect(() => {
		setYear(agtPlanUiStore.currentYear);
	}, [agtPlanUiStore.currentYear]);

	const addDok = (template: TemplateVM) => {
		documentUiStore.addDokumentToPlan(template, agtPlanUiStore.current!.agt, agtPlanUiStore.current!.plan);
	};

	const openExportModal = () => {
		uiStore.showModal(uiStore.modalIds.apExport);
	};

	return (
		<div className="panel-navigation grid is-col-bigger-right">
			<div className="is-left">
				<RadioButtonList includeDefaultClasses={false} buttons={buttons} onSelect={onSelect} value={year} classModifier="planung-year-select" />
			</div>
			<div className="is-right">
				<div className="planung-actions">
					{agtPlanUiStore.current && (
						<>
							<TemplateSelectorModal onSelect={addDok} agt={agtPlanUiStore.current.agt} plan={agtPlanUiStore.current.plan} />
							<Button className="button" onClick={openExportModal}>
								Exportieren
							</Button>
							<DokumentUploadButton agt={agtPlanUiStore.current.agt} plan={agtPlanUiStore.current.plan} isButton={true} />
						</>
					)}
				</div>
			</div>
		</div>
	);
});
