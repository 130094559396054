import { useStore } from 'app/context';
import { Field, Form, Formik } from 'formik';
import { observer } from 'mobx-react';

import * as Yup from 'yup';
import { notify } from 'app/components/common/notify';
import FormField from 'app/components/form/FormField';
import { Button } from 'app/components/common/Button';
import { BnrTransferVM } from 'app/stores/ui/admin.bnrtransfer.ui.store';
import { SteartSelectField } from 'app/components/form/SteartSelectField';
import { formatDateLong } from 'app/utils';
import { AdminBnrTransferables } from './AdminBnrTransferables';

const adminBnrTransferEditFormSchema = Yup.object({
	adminComment: Yup.string(),
	sourceEmail: Yup.string().email(),
	sourceHasLeft: Yup.boolean(),
	sourceName: Yup.string(),
	sourceSteart: Yup.number().required('Stellenart ist erforderlich'),
	targetComment: Yup.string(),
	sourceComment: Yup.string(),
	transferBranchenplanung: Yup.boolean(),
	transferDoks: Yup.boolean(),
}).required();

type AdminBnrTransferEditFormType = Yup.InferType<typeof adminBnrTransferEditFormSchema>;

const valOrEmptyString = (val: any) => {
	if (val) {
		return val;
	}
	return '';
};

interface IUserEditForm {
	transfer: BnrTransferVM;
	onClose: () => void;
}
export const AdminBnrTransferEditForm = observer((props: IUserEditForm) => {
	const { adminBnrTransferUiStore } = useStore();
	const vm = props.transfer;

	const handleSubmit = async (values: AdminBnrTransferEditFormType) => {
		const t = vm.transfer;
		t.sourceComment = values.sourceComment!;
		t.adminComment = values.adminComment!;
		t.sourceEmail = values.sourceEmail!;
		t.sourceHasLeft = values.sourceHasLeft ? values.sourceHasLeft : false;
		t.sourceName = values.sourceName!;
		t.sourceSteart = values.sourceSteart;
		t.targetComment = values.targetComment!;
		t.transferBranchenplanung = values.transferBranchenplanung ? values.transferBranchenplanung : false;
		t.transferDoks = values.transferDoks ? values.transferDoks : false;
		try {
			await adminBnrTransferUiStore.save(vm);
			notify('Daten wurden gespeichert', '', 'info');
			return true;
		} catch (ex) {
			notify('Fehler beim Anlegen/Speichern des Benutzers', '', 'error');
			return false;
		}
	};

	return (
		<>
			<Formik
				validationSchema={adminBnrTransferEditFormSchema}
				initialValues={{
					sourceComment: valOrEmptyString(vm.transfer.sourceComment),
					adminComment: valOrEmptyString(vm.transfer.adminComment),
					sourceEmail: valOrEmptyString(vm.transfer.sourceEmail),
					sourceHasLeft: vm.transfer.sourceHasLeft !== undefined ? vm.transfer.sourceHasLeft : false,
					sourceName: valOrEmptyString(vm.transfer.sourceName),
					sourceSteart: vm.transfer.sourceSteart,

					targetComment: valOrEmptyString(vm.transfer.targetComment),
					transferBranchenplanung: vm.transfer.transferBranchenplanung !== undefined ? vm.transfer.transferBranchenplanung : true,
					transferDoks: vm.transfer.transferDoks !== undefined ? vm.transfer.transferDoks : true,
				}}
				onSubmit={handleSubmit}
			>
				{({ errors, touched, isValid, values }) => {
					const sendUserInvite = async () => {
						let email = `mailto:?to=${vm.transfer.sourceEmail}&subject=${'DVS Datenübernahme'}&body=${vm.emailBody}`;
						window.location.href = email;
					};

					const triggerTransfer = async () => {
						adminBnrTransferUiStore.adminBnrTransferStore.session.incApiCallCount();
						await adminBnrTransferUiStore.adminBnrTransferStore.transfer(vm.transfer.id);
						adminBnrTransferUiStore.adminBnrTransferStore.session.decApiCallCount();
						notify('Datenübernahme abgeschloßen', '', 'info');
						setTimeout(() => {
							window.location.reload();
						}, 1000);
					};

					return (
						<Form className="form">
							<div className="pad-1rem">
								<div className="title">Quelle</div>
								<FormField className="is-inline" label="Quell-BnrId">
									<span>{vm.transfer.sourceBnrId}</span>
								</FormField>

								<FormField className="is-inline" error={errors.sourceName} touched={touched.sourceName} label="Name">
									<Field type="text" name="sourceName" placeholder="Name" disabled={vm.hasTransfered} />
								</FormField>
								<FormField className="is-inline" error={errors.sourceEmail} touched={touched.sourceEmail} label="Email">
									<Field type="text" name="sourceEmail" placeholder="Email" disabled={vm.hasTransfered} />
								</FormField>
								<FormField className="is-inline" error={errors.sourceSteart} touched={touched.sourceSteart} label="Stellenart">
									<SteartSelectField name={'sourceSteart'} disabled={vm.hasTransfered} />
								</FormField>

								<FormField className="is-inline" label="Hat Allianz verlassen">
									<Field type="checkbox" name={'sourceHasLeft'} disabled={vm.hasTransfered} />
								</FormField>

								<hr className="divider" />

								{vm.canSendTransferRequestMail && (
									<FormField>
										<div className="title">Anfrage</div>
										Durch einen Klick auf den folgenden Button, senden Sie dem Mitarbeiter eine Anfrage zur Datenübernahme. Sobald er diese bestätigt, kann die Datenübernahme durchgeführt werden: <br />
										<Button type="button" className="button is-primary" onClick={sendUserInvite} disabled={!!errors.sourceEmail || vm.hasTransfered}>
											{values.sourceEmail} Einladen
										</Button>
									</FormField>
								)}
								<FormField className="title" error={errors.sourceComment} touched={touched.sourceComment} label="Antwort auf Anfrage">
									<Field type="text" as="textarea" className="input" name="sourceComment" placeholder="Antwort auf Anfrage" disabled={vm.hasTransfered} />
								</FormField>
								<hr />
								<div className="title">Ziel</div>
								<FormField className="is-inline" label="BnrId">
									{vm.transfer.targetBnrId}
								</FormField>
								<FormField className="is-inline" label="User">
									{vm.targetUser.name} ({vm.targetUser.email})
								</FormField>
								<FormField className="is-inline" label="Bensl">
									{vm.targetUser.bensl}
								</FormField>

								<FormField className="title" error={errors.targetComment} touched={touched.targetComment} label="Anfrage">
									<Field type="text" as="textarea" className="input" name="targetComment" placeholder="Anfrage" disabled={vm.hasTransfered} />
								</FormField>
								<hr />
								<FormField className="title" error={errors.adminComment} touched={touched.adminComment} label="Kommentar (nur für Admins)">
									<Field type="text" as="textarea" className="input" name={'adminComment'} placeholder="Kommentar (nur für Admins)" disabled={vm.hasTransfered} />
								</FormField>
								<hr />
								<div className="title">Zu übertragene Daten: </div>
								<FormField>
									<Field type="checkbox" name={'transferDoks'} disabled={vm.hasTransfered} />
									<span className="pad-1rem">Dokumente (Planungen, Dokumente, Uploads und Maßnahmen)</span>
								</FormField>
								<FormField>
									<Field type="checkbox" name={'transferBranchenplanung'} disabled={vm.hasTransfered} />
									<span className="pad-1rem">Branchenplanung</span>
								</FormField>
								<div className="pad-top-1rem">
									{!vm.hasTransfered && (values.sourceHasLeft || vm.transfer.state === 'approved') && (
										<Button type="button" className="button is-primary" onClick={triggerTransfer}>
											Datenübernahme starten
										</Button>
									)}
								</div>
							</div>

							{vm.transfer.state === 'approved' && (
								<div className="pad-1rem">
									<div className="title">Status</div>
									<span className="tag is-green">Anfrage wurde bestätigt</span>
									{vm.transfer.sourceComment && <div className="quoted-text">{vm.transfer.sourceComment}</div>}
								</div>
							)}
							{vm.transfer.state === 'rejected' && (
								<div className="pad-1rem">
									<div className="title">Status</div>
									<span className="tag is-red">Anfrage wurde abgelehnt</span>
									{vm.transfer.sourceComment && <div className="quoted-text">{vm.transfer.sourceComment}</div>}
								</div>
							)}

							{vm.hasTransfered && (
								<div className="pad-1rem">
									<div className="title">Daten wurden am {formatDateLong(vm.transfer.transferDate)} übertragen!</div>

									<Button type="button" className="button is-small" onClick={triggerTransfer}>
										Dev Only: repeat transfer
									</Button>
								</div>
							)}

							{!vm.hasTransfered && (
								<div className="pad-1rem">
									<hr />
									<div className="title">Übertragbare Daten</div>
									<AdminBnrTransferables sourceBnrId={vm.transfer.sourceBnrId} targetBnrId={vm.transfer.targetBnrId} />
									<div className="pad-top-1rem">
										<Button type="button" className="button is-secondary" onClick={props.onClose}>
											Abbrechen
										</Button>
										<Button type="submit" className="button is-primary " disabled={!isValid}>
											Speichern
										</Button>
									</div>
								</div>
							)}
						</Form>
					);
				}}
			</Formik>
		</>
	);
});
