import { observer } from 'mobx-react';
import FormField from '../form/FormField';
import { useStore } from 'app/context';
import { useEffect, useState } from 'react';
import { SelectInput } from '../common/Select';

export const AgenturListFilterLoad = observer(() => {
	const { agenturListUiStore } = useStore();
	const [name, setName] = useState<string>('');
	const loadFilter = (n: string, value: any) => {
		setName(value.label);
		agenturListUiStore.currentFilter.load(value.label);
	};
	useEffect(() => {
		if (agenturListUiStore.currentFilter.loadedFilterName) {
			setName(agenturListUiStore.currentFilter.loadedFilterName);
		}
	}, [agenturListUiStore.currentFilter.loadedFilterName]);

	return (
		<>
			<FormField className={''} label="Filter Laden">
				<SelectInput
					isClearable={false}
					name={'agtFilterName'}
					isMulti={false}
					emptyText={'Filter Laden'}
					value={name}
					onChange={loadFilter}
					opts={agenturListUiStore.currentFilter.savedFiltersOpts}
				/>
			</FormField>
		</>
	);
});
