import { Einheit } from 'app/models/core/einheit.model';
import { BnrGpPosModel } from '../bnr.gp.pos.model';
import { PosUnDef } from '../gp';
const vblPosDef: BnrGpPosModel[] = [];

vblPosDef.push(new BnrGpPosModel({ year: 2023, posId: 2255001, group: 'vss', posText: 'Weiterbildungspfl. BNR (IDD)', planKey: 'idd', einheit: Einheit.bew }));
vblPosDef.push(new BnrGpPosModel({ year: 2023, posId: PosUnDef, group: 'vss', posText: 'Weiterbildungsz.Fonds', planKey: 'weiterbildungfonds', einheit: Einheit.bew }));
vblPosDef.push(new BnrGpPosModel({ year: 2023, posId: 2255023, group: 'vss', posText: 'Unfall NSt_Digi', planKey: 'unfall_nst', einheit: Einheit.lfNST }));
vblPosDef.push(new BnrGpPosModel({ year: 2023, posId: 2255020, group: 'vss', posText: 'KH PKW LfNst Digi', planKey: 'khpkwlfnst', einheit: Einheit.lfNST }));
vblPosDef.push(new BnrGpPosModel({ year: 2023, posId: 2255030, group: 'vss', posText: 'Leben KNP AKS NStDig', planKey: 'lebenakspvnst', einheit: Einheit.lfNST }));

vblPosDef.push(new BnrGpPosModel({ year: 2023, posId: 2255047, group: 'eh', posText: 'Sach langfr. Neustück', planKey: 'sachlangfristigenst', einheit: Einheit.lfNST }));

vblPosDef.push(new BnrGpPosModel({ year: 2023, posId: 2255171, group: 'neh', posText: 'Kranken Bew o. Dyn', planKey: 'krankenzusatzbew', einheit: Einheit.bew }));
vblPosDef.push(new BnrGpPosModel({ year: 2023, posId: 2255141, group: 'neh', posText: 'F-Sach Bewertung', planKey: 'fsachokraftbew', einheit: Einheit.bew }));
vblPosDef.push(new BnrGpPosModel({ year: 2023, posId: 2255121, group: 'neh', posText: 'P-Sach o.Kr_U_ Bew Digi', planKey: 'psachokrunfbew', einheit: Einheit.bew }));
vblPosDef.push(new BnrGpPosModel({ year: 2023, posId: 2255131, group: 'neh', posText: 'Unfall Bew. o. Anp Digi', planKey: 'unfallbew', einheit: Einheit.bew }));
vblPosDef.push(new BnrGpPosModel({ year: 2023, posId: 2255161, group: 'neh', posText: 'Fonds Bewertung', planKey: 'fondsbew', einheit: Einheit.bew }));
vblPosDef.push(new BnrGpPosModel({ year: 2023, posId: PosUnDef, group: 'neh', posText: 'Aktiv Depot Digital', planKey: 'aktivdepotdigital', einheit: Einheit.bew }));
vblPosDef.push(new BnrGpPosModel({ year: 2023, posId: 2255155, group: 'neh', posText: 'Leben Ris. Bew Digi', planKey: 'lebenrisbewoze', einheit: Einheit.bew }));
vblPosDef.push(new BnrGpPosModel({ year: 2023, posId: 3855021, group: 'gpplus', posText: 'Kranken Bew o. Dyn', planKey: 'krankenneuebefkvgv', einheit: Einheit.bew }));

export const VblPositionen = vblPosDef;
