import { TeilhaberModel } from 'app/models/personal/teilhaber.model';
import { BaseCachedEntityStore } from './core/base.cached.entity.store';

export class PersonalTeilhaberStore extends BaseCachedEntityStore<TeilhaberModel> {
	apiPath = 'api/teilhaber';
	idProp: string = 'identifier';
	createInstances(opts: any): TeilhaberModel {
		return new TeilhaberModel(opts);
	}

	async findByAgtId(agtId: number) {
		const items = await this.findAll();

		return items.filter((i) => i.agtId === agtId);
	}
}
