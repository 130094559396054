import { Field } from 'formik';
import { SegementStates } from 'app/models/segment/segment.model';

export const SegmentSelectField = ({ ...props }) => {
	var { agtId, disabled, ...fieldProps } = props;

	return (
		<div className="select">
			<Field {...fieldProps} as="select" className="input" disabled={props.disabled === true}>
				<option value={0} disabled={true}>
					Keine Auswahl{' '}
				</option>
				{SegementStates.map((p) => (
					<option key={p.state} value={p.state}>
						{p.label}
					</option>
				))}
			</Field>
		</div>
	);
};
