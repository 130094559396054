import { action, computed, IObservableArray, makeAutoObservable, makeObservable, observable, runInAction } from 'mobx';
import _ from 'lodash';

export enum AggregrationType {
	empty = 'empty',
	avg = 'avg',
	percentCountOfTotal = 'percentCountOfTotal',
	sum = 'sum',
	count = 'count',
}

export interface ITableAggregateColumn<T, P> {
	render?: (tm: TableModel<T, P>) => JSX.Element;
	path: string | string[];
	format?: { (val: any): string };
	aggType?: AggregrationType;
	aggIf?: (val: any) => boolean;
	visible?: boolean;
	aggCustomSymbol?: React.ReactNode;
	aggInfo?: React.ReactNode;
	aggModifier?: string;
}

export class TableAggregrateColumn<T, P> {
	constructor(opts: ITableAggregateColumn<T, P>, tm: TableModel<T, P>) {
		makeAutoObservable(this);
		this.tm = tm;
		this.format = opts.format;
		this.path = opts.path;
		this.render = opts.render;
		if (opts.aggType) {
			this.aggType = opts.aggType;
		}
		if (opts.aggIf) {
			this.aggIf = opts.aggIf;
		}
		if (opts.visible !== undefined) {
			this.visible = opts.visible;
		}
		if (opts.aggCustomSymbol) {
			this.aggCustomSymbol = opts.aggCustomSymbol;
		}
		if (opts.aggInfo) {
			this.aggInfo = opts.aggInfo;
		}
		if (opts.aggModifier) {
			this.aggModifier = opts.aggModifier;
		}
	}

	aggIf(val: any) {
		if (val > 0) {
			return true;
		}
		return false;
	}

	visible: boolean = true;

	path: string | string[];
	aggType: AggregrationType = AggregrationType.sum;
	aggCustomSymbol?: React.ReactNode;
	aggInfo?: React.ReactNode;
	aggModifier?: string;
	format?: { (val: any): string };
	tm: TableModel<T, P>;
	render?: (tm: TableModel<T, P>, aggValue?: number) => JSX.Element;
	doFormat(val: any) {
		if (!this.format) {
			return val;
		}
		return this.format(val);
	}
	@computed
	get aggValue() {
		if (this.aggType === AggregrationType.sum) {
			let sum = 0;
			this.tm.data.forEach((d) => {
				const prop = this.path! as string;
				const val = _.get(d, prop);
				if (this.aggIf(val)) {
					sum += val;
				}
			});
			return sum;
		}
		if (this.aggType === AggregrationType.avg) {
			let sum = 0;
			let count = 0;
			this.tm.data.forEach((d) => {
				const prop = this.path! as string;
				const val = _.get(d, prop);
				if (this.aggIf(val)) {
					count++;
					sum += val;
				}
			});
			// return Math.round(sum / count);
			return Math.round((sum * 100) / count) / 100;
		}
		if (this.aggType === AggregrationType.percentCountOfTotal) {
			let count = 0;
			let countTotal = 0;
			this.tm.data.forEach((d) => {
				const prop = this.path! as string;
				const val = _.get(d, prop);
				countTotal++;
				if (this.aggIf(val)) {
					count++;
				}
			});
			// return Math.round(sum / count);
			return Math.round((count * 100) / countTotal);
		}

		if (this.aggType === AggregrationType.count) {
			let count = 0;
			this.tm.data.forEach((d) => {
				const prop = this.path! as string;
				const val = _.get(d, prop);
				if (this.aggIf(val)) {
					count++;
				}
			});
			return count;
		}
		return undefined;
	}
}

export interface ITableColumn<T, P> {
	label: string | string[];
	icon?: string;
	path: string | string[];
	sortBy?: string | rowSortable<T>;
	editable?: boolean;
	editType?: 'number' | 'checkbox' | 'segment';
	render?: (row: TableRow<T>) => JSX.Element;
	format?: { (val: any): string } | { (val: any): string }[] | { (val: any): JSX.Element } | { (val: any): JSX.Element }[];
	agg?: ITableAggregateColumn<T, P> | ITableAggregateColumn<T, P>[];
	width?: number;
	cellModifier?: string;
	labelModifier?: string;
}

export class TableColumn<T, P> {
	constructor(opts: ITableColumn<T, P>, tm: TableModel<T, P>) {
		makeAutoObservable(this);
		this.label = opts.label;
		this.icon = opts.icon;
		this.format = opts.format;
		this.path = opts.path;
		this.sortBy = opts.sortBy;
		this.width = opts.width;
		this.cellModifier = opts.cellModifier;
		this.labelModifier = opts.labelModifier;

		this.render = opts.render;
		if (opts.agg) {
			if (Array.isArray(opts.agg)) {
				this.aggs = opts.agg.map((o) => new TableAggregrateColumn(o, tm));
			} else {
				this.aggs = [];
				const agg = new TableAggregrateColumn(opts.agg, tm);
				this.aggs.push(agg);
			}
		}
		if (opts.editable === true) {
			this.editable = true;
			if (opts.editType) {
				this.editType = opts.editType;
			}
		}
	}
	label: string | string[];
	icon?: string;
	cellModifier?: string;
	labelModifier?: string;
	width?: number;
	path: string | string[];
	aggs?: TableAggregrateColumn<T, P>[];
	editType: 'number' | 'checkbox' | 'segment' = 'number';

	@computed
	get keyPath() {
		if (Array.isArray(this.path)) {
			return this.path[0];
		}
		return this.path;
	}

	sortBy?: string | rowSortable<T>;
	format?: { (val: any): string } | { (val: any): string }[] | { (val: any): JSX.Element } | { (val: any): JSX.Element }[];

	doFormat(val: any, index: number) {
		if (!this.format) {
			return val;
		}
		if (Array.isArray(this.format)) {
			let fmt = this.format[index];
			if (!fmt) {
				fmt = this.format[0];
			}
			return fmt(val);
		} else {
			return this.format(val);
		}
	}

	render?: (row: TableRow<T>) => JSX.Element;

	@observable
	editable: boolean = false;
}

export type rowSortable<T> = (data: T) => any

export class TableRow<T> {
	constructor(data: T) {
		makeObservable(this);
		this.data = data;
	}
	@observable data: T;
	@observable selected: boolean = false;
	@observable hovering: boolean = false;

	@observable isGroupStart: boolean = false;
	@observable groupLabel: string = '';

	@action
	setData(path: string, val: any) {
		_.set(this.data as any, path, val);
	}
}

export class TableModel<T, P> {
	constructor() {
		makeObservable(this);
		this.rows = observable([]);
	}
	idProperty: string = 'id';
	idType: 'number' | 'string' = 'number';
	@observable columns: TableColumn<T, P>[] = [];
	@observable rows: IObservableArray<TableRow<T>>;

	_showIndx: boolean = false;

	showHeader: boolean = true;

	groupHeaderRow?: ITableColumn<T, P>;

	@computed
	get showSum() {
		const aggCols = this.columns.filter((c) => c.aggs);
		if (aggCols.length > 0) {
			return true;
		}
		return false;
	}

	@observable selectedId?: P;
	@action
	setSelectedId(id?: P) {
		this.selectedId = id;
		this.doSelectRow();
	}

	@observable hoverId?: P;
	@action
	setHoverId(id?: P) {
		this.hoverId = id;
		this.doHoverRow();
	}

	@observable sortDisabled: boolean = false;
	@observable sortBy?: string | rowSortable<T>;
	@observable sortAsc: boolean = true;

	@computed
	get columnCount() {
		if (this._showIndx) {
			return this.columns.length + 1;
		}
		return this.columns.length;
	}

	@computed
	get showFooter() {
		return this.showSum || this.hasEditbableColumns;
	}

	@observable editDisabled: boolean = false;
	@computed
	get hasEditbableColumns() {
		if (this.editDisabled) {
			return false;
		}
		const cols = this.columns.filter((x) => x.editable);
		if (cols.length > 0) {
			return true;
		}
		return false;
	}

	@observable editMode: boolean = false;
	@action
	setEditMode(val: boolean) {
		this.editMode = val;
	}

	@action
	setSortBy(path: (string | rowSortable<T>)) {
		if (this.sortBy === path) {
			this.sortAsc = !this.sortAsc;
			return;
		}
		this.sortBy = path;
		this.sortAsc = true;
	}

	@action sort() {
		if (!this.sortBy) {
			this.group(this.rows);
			return;
		}
		const s = this.sortBy;

		// let sorted = _.sortBy(this.rows, (r) => {
		// 	const v = _.get(r.data, s);
		// 	return v === undefined || v === null ? undefined : v;
		// });

		const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
		let sorted = this.rows.sort((a, b) => {
			let x, y;

			if (typeof s === 'string') {
				x = _.get(a.data, s);
				y = _.get(b.data, s);
				// handle login date
				if (x instanceof Date) x = x.getTime();
				if (y instanceof Date) y = y.getTime();
			} else {
				x = s(a.data);
				y = s(b.data);
			}


			return collator.compare(x, y);
		});

		if (!this.sortAsc) {
			sorted = _.reverse(sorted);
		}
		this.onSort();
		this.group(sorted);
	}

	@action group(rows: TableRow<T>[]) {
		if (!this.groupHeaderRow) {
			this.rows.replace(rows);
			return;
		}
		const groupPath = this.groupHeaderRow.path;
		rows.forEach((row, i) => {
			row.isGroupStart = true;
			const currVal = _.get(row.data, groupPath);
			row.groupLabel = currVal;
			if (i === 0) {
				return;
			}
			const previousRow = this.rows[i - 1];
			const prevVal = _.get(previousRow.data, groupPath);
			if (currVal === prevVal) {
				row.isGroupStart = false;
			}
		});
		this.rows.replace(rows);
	}

	onSort = () => { };

	@action
	setCols(cols: ITableColumn<T, P>[]) {
		this.columns = [];
		cols.forEach((c) => {
			const tc = new TableColumn<T, P>(c, this);
			this.columns.push(tc);
		});
	}

	@observable data: T[] = [];
	@action
	setRowData(data: T[]) {
		this.data = data;
		//this.rows.clear();
		const rows: TableRow<T>[] = [];
		this.data.forEach((d, i) => {
			const tr = new TableRow<T>(d);
			rows.push(tr);
		});
		this.rows.replace(rows);
		this.sort();
	}

	extraData: any;
	@action
	setExtraData(x: any) {
		this.extraData = x;
	}

	@computed
	get formData() {
		return observable({
			extra: this.extraData,
			data: this.data,
		});
	}

	onRowClick = (row: TableRow<T>) => { };
	onRowShiftClick = (row: TableRow<T>) => { };

	@action
	doSelectRow() {
		const id = this.selectedId;
		this.rows.forEach((d) => {
			runInAction(() => {
				if (this.compareById(d.data, id)) {
					d.selected = true;
					return;
				}
				d.selected = false;
			});
		});
	}

	@action
	doHoverRow() {
		const id = this.hoverId;
		this.rows.forEach((d) => {
			runInAction(() => {
				if (this.compareById(d.data, id)) {
					d.hovering = true;
					return;
				}
				d.hovering = false;
			});
		});
	}

	compareById(row1: T, id?: P) {
		const p = this.idProperty;
		return (row1 as any)[p] === id;
	}
}
