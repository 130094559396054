import { computed, makeObservable, observable, runInAction } from 'mobx';
import { MapsStore } from '../maps.store';
import { SessionStore } from '../session.store';
import { AgenturExtendedVM, AgenturListUiStore } from './agt.list.ui.store';
import { AgtUserUiStore } from './agt.user.ui.store';
import { ISelectOpt } from 'app/components/common/Select';
import _, { map } from 'lodash';
import { ConvexHullGrahamScan } from 'app/utils/convex.hull.graham.scan';
import { AgtType } from 'app/models/agentur.model';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import colors from 'assets/scss/colors.module.scss';

const mapColors: string[] = [
	'#0077C8',
	'#F2A900',
	'#1E8927',
	'#E15200',
	'#7C6394',
	'#FF934F',
	'#158390',
	'#FFE8B0',
	'#FCD25B',
	'#F7C7C3',
	'#E4003A',
	'#CCDD61',
	'#916836',
	'#B1DADD',
	'#5B5D30',
	'#B71E3F',
	'#5A3982',
	'#49648C',
	'#96DCFA',
	'#407D71',
	'#5A5360',
];
const mapRedFill = '#ED493F';

export class GroupedAgtVM {
	constructor(agts: AgenturExtendedVM[]) {
		this.agts = agts;
	}
	agts: AgenturExtendedVM[] = [];

	@computed
	get count() {
		return this.agts.length;
	}

	@computed
	get lat() {
		return this.agts[0].lat!;
	}

	@computed
	get lng() {
		return this.agts[0].lng!;
	}
}

export function encodeSVG(rawSvgString: string): string {
	const symbols = /[\r\n%#()<>?\[\\\]^`{|}]/g;

	// Use single quotes instead of double to avoid URI encoding
	rawSvgString = rawSvgString
		.replace(/'/g, '"')
		.replace(/>\s+</g, '><')
		.replace(/\s{2,}/g, ' ');

	return 'data:image/svg+xml;utf-8,' + rawSvgString.replace(symbols, encodeURIComponent);
}

export class BaseMapLegend implements ISelectOpt {
	constructor(opts: ISelectOpt[]) {
		this.opts = opts;
	}
	opts: ISelectOpt[];
	label = 'Standard';
	value = 'standard';
	getOptValue = (agentur: AgenturExtendedVM) => {
		return agentur.gsId;
	};
	getOpt = (agentur: AgenturExtendedVM) => {
		const optVal = this.getOptValue(agentur);
		return this.opts.find((o) => o.value === optVal);
	};
	getIndex = (agentur: AgenturExtendedVM) => {
		const optVal = this.getOptValue(agentur);
		return this.opts.findIndex((o) => o.value === optVal);
	};
	getColorByIndex = (indx: number) => {
		if (this.value === 'standard') {
			return mapRedFill;
		}

		return mapColors[indx] || colors.greyLight;
	};
	getSvgByIndx = (indx: number) => {
		const color = this.getColorByIndex(indx);

		return `<svg width="26" height="35" viewBox="0 0 26 35" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M12.0675 34.0142L12.0626 34.0066L12.0575 33.9992C10.4619 31.6842 9.08054 29.7074 7.88538 27.997C5.36668 24.3925 3.67481 21.9712 2.54628 20.0626C1.72038 18.6658 1.21366 17.571 0.910149 16.5128C0.607527 15.4576 0.5 14.4145 0.5 13.1122C0.5 6.14355 6.03708 0.5 13 0.5C19.8969 0.5 25.5 6.14525 25.5 13.1122C25.5 14.4132 25.3885 15.4556 25.08 16.5102C24.7705 17.5684 24.2556 18.6633 23.421 20.0607C22.3202 21.9038 20.6951 24.2235 18.3129 27.624C17.0558 29.4185 15.5878 31.514 13.8748 33.9992L13.8697 34.0066L13.8648 34.0142C13.452 34.6619 12.4803 34.6619 12.0675 34.0142Z" 
		fill="${color}" stroke="#000000" stroke-opacity="0.2"/>		
		<circle cx="13" cy="13" r="4.5" fill="black" fill-opacity="0.5"/>
		</svg>`;
	};
	@computed
	get legendDescription() {
		const desc: ISelectOpt[] = [];
		this.opts.forEach((element, indx) => {
			const label = element.label;
			const svg = this.getSvgByIndx(indx);
			desc.push({
				label,
				value: svg,
			});
		});
		return desc;
	}
}

export class GsMapLegend extends BaseMapLegend {
	constructor(opts: ISelectOpt[]) {
		super(opts);
	}
	label = 'GS';
	value = 'gs';
	getOptValue = (agentur: AgenturExtendedVM) => {
		return agentur.gsId;
	};
}

export class SegmentierungMapLegend extends BaseMapLegend {
	constructor(opts: ISelectOpt[]) {
		super(opts);
	}
	label = 'Segmentierung';
	value = 'seg';
	getOptValue = (agentur: AgenturExtendedVM) => {
		return agentur.segment as string;
	};
}

export class EdpMapLegend extends BaseMapLegend {
	constructor(opts: ISelectOpt[]) {
		super(opts);
	}
	label = 'EDP';
	value = 'edp';
	getOptValue = (agentur: AgenturExtendedVM) => {
		const u = agentur.users.find((u) => u.isEDP);
		if (u) {
			return u.bnrId.toString();
		}
		return '';
	};
}

export const DefaultLegend = new BaseMapLegend([]);

export class AgenturMapsUiStore {
	session: SessionStore;
	mapsStore: MapsStore;
	@observable
	agenturListUiStore: AgenturListUiStore;
	@observable
	agtUserUiStore: AgtUserUiStore;
	constructor(session: SessionStore, mapsStore: MapsStore, agenturListUiStore: AgenturListUiStore, agtUserUiStore: AgtUserUiStore) {
		makeObservable(this);
		this.session = session;
		this.mapsStore = mapsStore;
		this.agenturListUiStore = agenturListUiStore;
		this.agtUserUiStore = agtUserUiStore;
	}

	@observable
	higlightedAgtId?: number;

	@computed
	get legendOpts(): BaseMapLegend[] {
		if (!this.agenturListUiStore || !this.agenturListUiStore.currentFilter.gsOpts) {
			return [];
		}
		return [
			DefaultLegend,
			new GsMapLegend(this.agenturListUiStore.currentFilter.gsOpts),
			new SegmentierungMapLegend(this.agenturListUiStore.currentFilter.segmentOpts),
			new EdpMapLegend(this.edpOpts),
		];
	}

	@observable
	currentLegend: BaseMapLegend = DefaultLegend;

	@computed
	get mapableItems() {
		return this.agenturListUiStore.items.filter((x) => x.agtType === AgtType.agt);
	}

	@computed
	get mapableItemsWithoutCoordinates() {
		return this.mapableItems.filter((x) => x.lat === 0 || x.lng === 0);
	}

	@computed
	get filteredMapItems() {
		return this.agenturListUiStore.filteredItems.filter((x) => x.agtType === AgtType.agt);
	}

	@computed
	get filtereGroupedMapItems() {
		const items = this.agenturListUiStore.filteredItems.reduce((grouped, item) => {
			const key = `${item.lat},${item.lng}`;
			if (!grouped[key]) {
				grouped[key] = [];
			}
			grouped[key].push(item);
			return grouped;
		}, {} as { [key: string]: AgenturExtendedVM[] });
		const keys = _.keys(items);
		const res: GroupedAgtVM[] = [];
		keys.forEach((k) => {
			const i = items[k];
			res.push(new GroupedAgtVM(i));
		});
		return res;
	}

	@computed
	get edpOpts() {
		let edps = this.agtUserUiStore.users.filter((u) => u.isEDP);
		edps = _.sortBy(edps);
		return edps.map((i) => {
			return {
				label: i.lastName,
				value: i.bnrId.toString(),
			};
		});
	}

	getNumberMarker(number: string, color?: string) {
		const colorStr = color ? color : mapRedFill;
		const svg = `<svg width="26" height="35" viewBox="0 0 26 35" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M12.0675 34.0142L12.0626 34.0066L12.0575 33.9992C10.4619 31.6842 9.08054 29.7074 7.88538 27.997C5.36668 24.3925 3.67481 21.9712 2.54628 20.0626C1.72038 18.6658 1.21366 17.571 0.910149 16.5128C0.607527 15.4576 0.5 14.4145 0.5 13.1122C0.5 6.14355 6.03708 0.5 13 0.5C19.8969 0.5 25.5 6.14525 25.5 13.1122C25.5 14.4132 25.3885 15.4556 25.08 16.5102C24.7705 17.5684 24.2556 18.6633 23.421 20.0607C22.3202 21.9038 20.6951 24.2235 18.3129 27.624C17.0558 29.4185 15.5878 31.514 13.8748 33.9992L13.8697 34.0066L13.8648 34.0142C13.452 34.6619 12.4803 34.6619 12.0675 34.0142Z" 
		fill="${colorStr}" stroke="#000000" stroke-opacity="0.2"/>		
		<text x="50%" y="40%" text-anchor="middle" fill="#111111" opacity="0.5" font-size="14px" font-family="Arial" font-weight="bold" dy=".3em">${number}</text>
		</svg>`;

		return svg;
	}

	_markers: google.maps.marker.AdvancedMarkerElement[] = [];
	_cluster?: MarkerClusterer = undefined;

	getMarkers(map: google.maps.Map, onSelect: (agt: AgenturExtendedVM | undefined) => void) {
		this._markers.forEach((m) => (m.map = null));
		this._markers = [];
		const parser = new DOMParser();
		let currentOpenInfoWindow: any = null;
		this.filtereGroupedMapItems.forEach((group: GroupedAgtVM) => {
			if (!group.lat || !group.lng) return;
			let title = group.count.toString();
			let pin;
			let svg;
			let isHightlighted = false;
			if (this.higlightedAgtId && group.agts.map((agt) => agt.agtId).includes(this.higlightedAgtId)) {
				isHightlighted = true;
			}

			if (group.agts.length === 1) {
				const agentur = group.agts[0];

				if (this.currentLegend.value !== DefaultLegend.value) {
					const indx = this.currentLegend.getIndex(agentur);
					let svg = this.currentLegend.getSvgByIndx(indx);

					pin = parser.parseFromString(svg, 'image/svg+xml').documentElement;
					const opt = this.currentLegend.getOpt(agentur);
					if (opt) {
						title += '\n ' + this.currentLegend.label + ': ' + opt.label;
					}
				}
			} else {
				svg = this.getNumberMarker(group.count.toString());
			}

			if (svg) {
				if (isHightlighted) {
					svg = svg.replace('<svg ', '<svg transform="scale(2)" ');
				}
				pin = parser.parseFromString(svg, 'image/svg+xml').documentElement;
			}

			if (!pin && isHightlighted) {
				pin = new google.maps.marker.PinElement({
					scale: 2,
				}).element;
			}

			const m = new google.maps.marker.AdvancedMarkerElement({
				position: { lat: group.lat, lng: group.lng },
				map: map,
				title,
				content: pin,
			});

			// if (group.agts.length === 1) {
			// 	m.addListener('click', () => {
			// 		this.higlightedAgtId = group.agts[0].agtId;
			// 		onSelect(group.agts[0]);
			// 	});
			// 	this._markers.push(m);
			// } else {
			const infowindow = new google.maps.InfoWindow({
				content: '',
				ariaLabel: 'Uluru',
			});
			m.addListener('click', async () => {
				runInAction(() => {
					this.activeGroup = group;
				});
				// wait for component to render
				await new Promise((res) => setTimeout(res, 100));
				const infowindowContent = document.getElementById('infowindowcontent');

				if (infowindowContent) {
					const htmlContent = infowindowContent.innerHTML;
					infowindow.setContent(htmlContent);
					if (currentOpenInfoWindow) {
						currentOpenInfoWindow.close();
					}
					infowindow.open({
						anchor: m,
						map,
					});
					// Step 3: Update the currently opened InfoWindow to the new one
					currentOpenInfoWindow = infowindow;
				}
			});
			// }
			this._markers.push(m);
		});

		return this._markers;
	}

	@observable
	activeGroup?: GroupedAgtVM;

	drawLayer(map: google.maps.Map) {
		return;
		const legend = new GsMapLegend(this.agenturListUiStore.currentFilter.gsOpts);
		legend.opts.forEach((opt, i) => {
			const gsItems = this.filteredMapItems.filter((a) => a.gsId === opt.value);
			const color = legend.getColorByIndex(i);
			const convexHull = new ConvexHullGrahamScan();
			gsItems.forEach((agt) => {
				if (!agt.lat || !agt.lng) return;
				convexHull.addPoint(agt.lat, agt.lng);
			});

			let hullPoints = convexHull.getHull().map((h) => {
				return { lat: h.x, lng: h.y };
			});

			const ploygon = new google.maps.Polygon({
				paths: hullPoints,
				strokeColor: color,
				strokeOpacity: 0.5,
				strokeWeight: 4,
				fillColor: color,
				fillOpacity: 0.15,
			});

			ploygon.setMap(map);
		});
	}
}
