import { Loader } from '@googlemaps/js-api-loader';
import { BaseEntityStore } from './core/base.entity.store';
import { SessionStore } from './session.store';

const loader = new Loader({
	apiKey: 'AIzaSyAS6Sik2T9tlF-aWgWWg44Lkup6TiwDOI4',
	version: 'weekly',
});

interface IMapSettings {
	zoom: number;
	lat: number;
	lng: number;
	mapId: string;
}

export class MapsStore extends BaseEntityStore<any> {
	mapsLib?: google.maps.MapsLibrary;
	markerLib?: google.maps.MarkerLibrary;
	loadingPromise?: Promise<any>;
	geocoder?: google.maps.Geocoder;
	constructor(session: SessionStore) {
		super(session);
		this.loadingPromise = loader.load().then(async () => {
			this.mapsLib = (await google.maps.importLibrary('maps')) as google.maps.MapsLibrary;
			this.markerLib = (await google.maps.importLibrary('marker')) as google.maps.MarkerLibrary;
			return true;
		});
	}

	async ensureInitialised() {
		await this.loadingPromise;
	}

	saveMapSettings(map: google.maps.Map) {
		const center = map.getCenter();
		const zoom = map.getZoom();
		if (!center) {
			return;
		}

		const s: IMapSettings = {
			zoom: zoom || 8,
			lat: center.lat(),
			lng: center.lng(),
			mapId: 'agenturen'
		};
		localStorage.setItem('mapSettings', JSON.stringify(s));
	}

	getMapSettings(): IMapSettings {
		const s = localStorage.getItem('mapSettings');
		const defaultSettings = {
			lat: 51.0939,
			lng: 10.2656,
			zoom: 8,
			mapId: 'agenturen'
		};
		if (!s) {
			return defaultSettings;
		}
		const settings = JSON.parse(s);
		if (!settings.lat || !settings.lng || !settings.zoom) {
			return defaultSettings;
		}
		return {
			lat: settings.lat,
			lng: settings.lng,
			zoom: settings.zoom,
			mapId: 'agenturen'
		};
	}
}
