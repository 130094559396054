import BaseContainer from 'app/containers/BaseContainer';
import { observer } from 'mobx-react';
import { MainMenu } from './MainMenu';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useStore } from 'app/context';
import { useEffect } from 'react';
import { runInAction } from 'mobx';
import { EnsureAdmin } from './AdminHomeContainer';
import { AdminNewNewsButton } from 'app/components/admin/news/AdminNewNewsButton';
import { AdminNewsList } from 'app/components/admin/news/AdminNewsList';
import { AdminNewsDetail } from 'app/components/admin/news/AdminNewsDetail';

interface AdminNewsParmTypes {
	id?: string;
}

const NewsActions = () => {
	return (
		<>
			<AdminNewNewsButton />
		</>
	);
};

const AdminNewsContaniner = observer((props: RouteComponentProps) => {
	const { session, adminNewsUiStore } = useStore();

	const currentUser = session.currentUser;

	const { id } = useParams<AdminNewsParmTypes>();

	useEffect(() => {
		if (id) {
			adminNewsUiStore.loadById(id);
		} else {
			runInAction(() => {
				adminNewsUiStore.current = undefined;
			});
		}
	}, [adminNewsUiStore, id]);

	const NewsNav = () => {
		return (
			<div className="admin-navbar">
				<div className="is-left">
					<strong>News</strong>
				</div>
				<div className="is-right">
					<NewsActions />{' '}
				</div>
			</div>
		);
	};

	return (
		<BaseContainer sideMenu={<MainMenu {...props} />} navbar={<NewsNav />}>
			{currentUser && (
				<>
					<EnsureAdmin />
					<AdminNewsList />
					{id && <AdminNewsDetail />}


				</>
			)}
		</BaseContainer>
	);
});

export default AdminNewsContaniner;