import { observer } from 'mobx-react';
import { useStore } from 'app/context';
import { runInAction } from 'mobx';
import React, { useEffect, useState } from 'react';
import { Modal } from 'app/components/Modal';
import { formatDate } from 'app/utils';
import { Button } from 'app/components/common/Button';
import { ConfirmModal } from 'app/components/common/ConfirmModal';
import { PersonalVM } from 'app/models/personal/personal.vm';

interface IPersonsalViewProps {
	id?: string;
	onClose: () => void;
	onEdit: (id: string) => void;
	onDelete: (id: string) => void;
}

export const AgenturPersonViewModal = observer((props: IPersonsalViewProps) => {
	const { uiStore, personalUiStore } = useStore();
	const modalId = uiStore.modalIds.mitarbeiterView;

	const [personal, setPersonal] = useState<PersonalVM | undefined>();
	useEffect(() => {
		if (props.id) {
			personalUiStore.findById(props.id).then((p) => {
				setPersonal(p);
			});
		} else {
			setPersonal(undefined);
		}
	}, [personalUiStore, props.id]);

	const onEdit = () => {
		if (personal) {
			props.onEdit(personal.id);
		}
	};

	const onDelete = async () => {
		if (personal) {
			props.onDelete(personal.id);
		}
	};

	const showConfirmDlg = () => {
		runInAction(() => {
			uiStore.showModal(uiStore.modalIds.confirmModal);
		});
	};

	return (
		<>
			<Modal modalId={modalId} modifier="is-paddingless is-table-modal" title={'Mitarbeiterdetails'} onClose={() => props.onClose()}>
				{personal && (
					<>
						<table className="table is-compact">
							<tbody>
								<tr>
									<td>
										<strong>Name</strong>
									</td>
									<td>
										{personal.nachname}, {personal.vorname}
									</td>
								</tr>
								<tr>
									<td>
										<strong>Kat</strong>
									</td>
									<td>{personal.stellenBezeichnung}</td>
								</tr>
								{personal.bnrId > 0 && (
									<tr>
										<td>
											<strong>BNR</strong>
										</td>
										<td>
											{personal.bnr} / {personal.bnrId}
										</td>
									</tr>
								)}
								{personal.canShowGeburtstag && (
									<>
										<tr>
											<td>
												<strong>Geburtsdatum</strong>
											</td>
											<td>{formatDate(personal.gebDat)}</td>
										</tr>
										<tr>
											<td>
												<strong>Alter</strong>
											</td>
											<td>{personal.age}</td>
										</tr>
									</>
								)}
								<tr>
									<td>
										<strong>Gründung / Eintritt</strong>
									</td>
									<td>{formatDate(personal.beitrittDat)}</td>
								</tr>
								<tr>
									<td>
										<strong>Jubiläum</strong>
									</td>
									<td>{formatDate(personal.anniversaryDate)}</td>
								</tr>
								<tr>
									<td>
										<strong>Pensionierung</strong>
									</td>
									<td>{formatDate(personal.retirementDate)}</td>
								</tr>
							</tbody>
						</table>

						{personal.isEditable && (
							<div className="modal-footer">
								<Button type="button" className="button is-primary " onClick={onEdit}>
									Bearbeiten
								</Button>
								<Button type="submit" className="button is-secondary" onClick={showConfirmDlg}>
									Löschen
								</Button>
							</div>
						)}
					</>
				)}
			</Modal>
			<ConfirmModal onConfirm={onDelete} onCancel={() => {}}>
				Sind Sie sicher, dass Sie diesen Mitarbeiter löschen wollen?
			</ConfirmModal>
		</>
	);
});
