import { Button } from 'app/components/common/Button';
import { useStore } from 'app/context';
import { DocumentVM } from 'app/stores/ui/document.ui.store';
import { formatDateLong } from 'app/utils';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import Widget, { IWidgetConsumer } from '../common/Widget';
import { WidgetNavigate } from '../common/WidgetNavigate';
import { DocumentSharedBy } from './DocumentViewer';
import { DocumentViewerModal } from './DocumentViewerModal';

interface IDocumentItem {
	doc: DocumentVM;
}
export const DocumentItem = observer((props: IDocumentItem) => {
	const { documentUiStore, uiStore } = useStore();

	const viewDoc = () => {
		runInAction(() => {
			documentUiStore.setCurrentModalDoc(props.doc);
			uiStore.showModal(uiStore.modalIds.documentViewModal);
		});
	};
	return (
		<>
			<div className={`document-widget-item is-${props.doc.readState}`}>
				<div className="read-state"></div>
				<Button className="button is-blank" onClick={viewDoc}>
					<div className="title">{props.doc.doc.title}</div>
					<div className="sharing">
						<span>Geteilt</span>
						<DocumentSharedBy doc={props.doc} showAgt={true} showPlan={true} />
					</div>
					<div className="modified">
						Zuletzt geändert: <strong>{formatDateLong(props.doc.doc.modified)}</strong>
					</div>
				</Button>
			</div>
		</>
	);
});

export const SharedDocumentsHomeWidget = observer((props: IWidgetConsumer) => {
	const { documentUiStore } = useStore();
	useEffect(() => {
		documentUiStore.load().then(() => {
			runInAction(() => {
				documentUiStore.currentFilter.reset();
				documentUiStore.currentFilter.onlySharedForMe = true;
			});
		});
	}, [documentUiStore]);

	const rptPath = '/dokumente?onlySharedForMe=true';
	return (
		<>
			<Widget modifier="is-paddingless" title={'Für mich geteilte Dokumente'} action={<WidgetNavigate text={'Alle'} path={rptPath} />} widgetId={props.widgetKey}>
				{documentUiStore.last5Shared.map((n) => (
					<DocumentItem key={n.id} doc={n} />
				))}
			</Widget>
			<DocumentViewerModal showAgt={true} showPlan={true} />
		</>
	);
});
