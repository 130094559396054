import Widget, { IWidgetConsumer } from 'app/components/common/Widget';
import { observer } from 'mobx-react';
import { ZielErgebnisBalken, ZielErgebnisBalkenModel } from 'app/components/charts/ZielErgebnisBalken';
import { useStore } from 'app/context';
import React, { useEffect, useState } from 'react';
import { GPMAXYEAR } from 'app/stores/ui/berichte.ui.store';

export const AgenturEhGesamtbewertungWidget = observer((props: IWidgetConsumer) => {
	const { agenturUiStore } = useStore();
	const agt = agenturUiStore.current;

	const { agtProdUiStore } = useStore();
	const [model, setModel] = useState<ZielErgebnisBalkenModel>();
	const [title, setTitle] = useState<string>('EH Gesamtbewertung');

	useEffect(() => {
		if (agt) {
			setTitle('EH Gesamtbewertung');
			// console.log('GPMAXYEAR', GPMAXYEAR);
			// debugger;

			agtProdUiStore.findByPlanKeyAndAgtId('gesamtbewertung', agt.agtId, GPMAXYEAR).then((res) => {
				if (res && res.posVal) {
					const ze = new ZielErgebnisBalkenModel(res.posVal.ziel, res.posVal.ergebnis);
					setModel(ze);
					if (agt.isPro3) {
						setTitle('Eingangshürde ' + res.fahrstuhl!);
					}
				} else {
					setModel(new ZielErgebnisBalkenModel(0, 0));
				}
			});
		}

		return () => {
			setModel(undefined);
			setTitle('EH Gesamtbewertung');
		};
	}, [agtProdUiStore, agt]);

	return (
		<>
			{model && (
				<Widget title={title} widgetId={props.widgetKey}>
					<>
						<ZielErgebnisBalken model={model} />
					</>
				</Widget>
			)}
		</>
	);
});
