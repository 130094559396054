import { BaseCachedEntityStore } from './core/base.cached.entity.store';
import { browserName, osVersion, osName, fullBrowserVersion, getUA } from 'react-device-detect';
import { notify } from 'app/components/common/notify';
import { action, observable } from 'mobx';
import moment from 'moment';

export class UserSettings {
	constructor(opts: any) {
		this.opts = opts;
	}
	opts: any;
}

export class WochemailMailRequest {
	kw: string = '';
	subject: string = '';
	bodyHtml: string = '';
	bodyText: string = '';
	force: boolean = false;
	changeSummary: any;
}

const add = (msg: string, txt1: string, txt2?: string) => {
	msg += '<br/>' + txt1;
	if (txt2) {
		msg += ' ' + txt2;
	}
	return msg;
};

class HistoryItem {
	constructor(path: string, search: string) {
		this.path = path;
		this.search = search;
	}
	path: string;
	search: string;
	date: Date = new Date();
	isSame(path: string, search: string) {
		return this.path === path && this.search === search;
	}
}

export class UserSettingsStore extends BaseCachedEntityStore<UserSettings> {
	apiPath = 'api/documents';
	idProp: string = 'id';
	createInstances(opts: any): UserSettings {
		return new UserSettings(opts);
	}

	@observable
	history: HistoryItem[] = [];

	@action
	addHistoryItem(path: string, search: string) {
		const lastItem = this.history[this.history.length - 1];
		if (lastItem) {
			if (lastItem.isSame(path, search)) {
				return;
			}
		}
		this.history.push(new HistoryItem(path, search));
	}

	async setEmailNotification(val: boolean) {
		var url = 'api/user/enableEmailNotification/' + val;
		await this.callRefreshTokenIfNecessary();
		const header = this.getAuthHeader();
		this.session.incApiCallCount();
		return this.axios({
			method: 'POST',
			url: url,
			headers: header.headers,
		}).finally(() => {
			this.session.decApiCallCount();
		});
	}

	async sendFeedback(msg: string) {
		msg = add(msg, '');
		msg = add(msg, '--------------');
		msg = add(msg, 'Name:', this.session.currentUser!.name);
		msg = add(msg, 'Bensl:', this.session.currentUser!.bensl);
		msg = add(msg, 'BnrId:', this.session.currentUser!.bnrId.toString());
		msg = add(msg, 'Email:', this.session.currentUser!.email);
		msg = add(msg, 'Steart:', this.session.currentUser!.steart.name.toString());
		msg = add(msg, 'Brauser:', browserName + ' ' + fullBrowserVersion);
		msg = add(msg, 'OS:', osName + ' ' + osVersion.toString());
		msg = add(msg, 'User Agent:', getUA);
		msg = add(msg, '--------------');
		msg = add(msg, 'History:');
		this.history.reverse().forEach((i) => {
			msg = add(msg, moment(i.date).format('YYYYMMDD HH:mm'), i.path + '' + i.search);
		});

		var url = 'api/feedback/report-problem';
		await this.callRefreshTokenIfNecessary();
		const header = this.getAuthHeader();
		this.session.incApiCallCount();
		return this.axios({
			method: 'POST',
			url: url,
			headers: header.headers,
			data: {
				message: msg,
			},
		}).finally(() => {
			this.session.decApiCallCount();
		});
	}

	async sendWochenMail(data: WochemailMailRequest) {
		var url = 'api/feedback/send-wochenmail';
		await this.callRefreshTokenIfNecessary();
		const header = this.getAuthHeader();
		this.session.incApiCallCount();
		return this.axios({
			method: 'POST',
			url: url,
			headers: header.headers,
			data,
		}).finally(() => {
			this.session.decApiCallCount();
		});
	}

	async changPassword(oldPassword: string, newPassword: string) {
		var url = 'api/auth/change-password';
		await this.callRefreshTokenIfNecessary();
		const header = this.getAuthHeader();
		this.session.incApiCallCount();
		return await this.axios({
			method: 'POST',
			url: url,
			headers: header.headers,
			data: {
				oldPassword: oldPassword,
				newPassword: newPassword,
			},
		})
			.catch((x) => {
				if (x.response.status === 422) {
					notify('Passwort setzen fehlgeschlagen', 'Passwort muss mindestens 8 Zeichen, mindestens eine Zahl, Groß- und Kleinbuchstaben und mindestens 1 Sonderzeichen enthalten');
				} else if (x.response.status === 400) {
					notify('Passwort setzen fehlgeschlagen', x.response.data.message);
				} else {
					notify('Passwort setzen fehlgeschlagen', 'Unbekannter Fehler');
				}
				return false;
			})
			.finally(() => {
				this.session.decApiCallCount();
			});
	}
}
