import { computed, makeObservable, observable } from 'mobx';
import { BaseCachedEntityStore } from './core/base.cached.entity.store';

export class KvAgtEigenePlanung {
	constructor(opts: any) {
		makeObservable(this);
		this.id = opts.id;
		this.agtId = opts.agtId;
		if (opts.aid) {
			this.agtId = opts.aid;
		}
		this.bnrId = opts.bnrId;
		this.bm = opts.bm;

		this.key = opts.key;
		this.key2 = opts.key2;
		if (opts.valueNum) {
			this.valueNum = opts.valueNum;
		}
		if (opts.n) {
			this.valueNum = opts.n;
		}
	}

	agtId: number;
	bnrId: number;
	bm: number;
	group: string = 'eigene_planung';
	id: string;
	key: string;
	key2: string;
	@computed
	get planKey() {
		return this.key2;
	}
	@observable
	valueNum: number = 0;
}

export class KvAgtEigenePlanungStore extends BaseCachedEntityStore<KvAgtEigenePlanung> {
	apiPath = 'api/kv_agentur_ms/eigene_planung';
	idProp: string = 'id';

	createInstances(opts: any): KvAgtEigenePlanung {
		if (!opts) {
			debugger;
		}
		return new KvAgtEigenePlanung(opts);
	}

	disableFindForStearts = [123];

	async findByPlanKey(plankey: string, year: number) {
		const data = await this.findAll();
		return data.filter((p) => p.planKey === plankey && p.bm === year);
	}
}
