import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { observer } from 'mobx-react';
import { Button } from 'app/components/common/Button';
import { Icon } from 'app/components/common/Icon';

interface IInputControl {
	value: any;
	label?: string;
	id?: string;
	name: string;
	onChange: (name: string, value: any) => void;
	type?: string;
	numbersOnly?: boolean;
	debounceTime?: number;
	placeholder?: string;
	inline?: boolean;
	onEnter?: () => void;
	allowClear?: boolean;
	autoFocus?: boolean;
	icon?: string;
	modifier?: string;
}

export const InputControl: React.FC<IInputControl> = observer((props: IInputControl) => {
	const type = props.type ? props.type : 'text';
	const id = props.id ? props.id : type + '' + props.name;
	const numbersOnly = props.numbersOnly || false;
	const debounceTime = props.debounceTime || 0;
	const [value, setValue] = useState<string>('');
	useEffect(() => {
		setValue(props.value);
	}, [props.value]);

	const changeHandler = useCallback(
		(event: any) => {
			const name = event.target.name;
			let value = event.target.value;
			if (numbersOnly) {
				value = value.replace(/\D/, '');
			}
			props.onChange(name, value);
		},
		[numbersOnly, props],
	);

	const debouncedEventHandler = useMemo(() => debounce(changeHandler, debounceTime), [debounceTime, changeHandler]);

	const onChange = (event: any) => {
		setValue(event.target.value);
		debouncedEventHandler(event);
	};
	const clearValue = (targetName: string) => {
		setValue('');
		props.onChange(props.name, '');
	};

	const onKeyDown = (e: any) => {
		if (e.key === 'Enter' && props.onEnter) {
			props.onEnter();
		}
	};

	return (
		<div className={`form-control ${props.inline ? 'is-inline' : ''} ${props.icon ? 'has-icon' : ''} ${props.modifier || ''}`}>
			{props.label && <label htmlFor={id}>{props.label || props.name}</label>}
			{props.icon && <Icon iconClass={props.icon} />}
			<input
				className="input"
				id={id}
				name={props.name}
				onChange={onChange}
				type={type}
				value={value}
				placeholder={props.placeholder}
				onKeyPress={onKeyDown}
				autoFocus={props.autoFocus || false}
			/>
			{props.allowClear && value && (
				<Button className="clear-button" onClick={() => clearValue(props.name)}>
					<Icon iconClass="times-circle" />
				</Button>
			)}
		</div>
	);
});
