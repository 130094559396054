import { observer } from 'mobx-react';
import { useStore } from 'app/context';
import { AgtPlanungNavi } from './planung/AgtPlanungNavi';
import { TaskViewerModal } from '../tasks/TaskViewModal';
import { AgtPlanungThemenAuswahlModal } from './planung/AgtPlanungThemenAuswahlModal';
import { AgtPlanungExportModal } from './planung/AgtPlanungExportModal';
import { useEffect, useState } from 'react';
import { WidgetsVm } from 'app/models/widgets/widget.vm';
import { WidgetScope } from 'app/models/widgets/widget.setting.model';
import { WidgetContainer } from '../common/WidgetContainer';

export const AgenturPlanung = observer(() => {
	const { agenturUiStore, widgetUiStore } = useStore();

	const [vm, setVm] = useState<WidgetsVm>();
	const agt = agenturUiStore.current;
	useEffect(() => {
		widgetUiStore.findByScope(WidgetScope.agtplanung).then((res) => {
			setVm(new WidgetsVm(res));
		});
	}, [widgetUiStore]);
	return (
		<>
			<AgtPlanungNavi />
			{agt && (
				<div className="grid is-col-bigger-left pad-1rem is-planung-grid">
					{vm && <WidgetContainer vm={vm} />}
					<TaskViewerModal showAgt={false} showPlan={false} />
					<AgtPlanungThemenAuswahlModal />
					<AgtPlanungExportModal />
				</div>
			)}
		</>
	);
});
