export enum SegmentState {
	calcuated = 'calcuated',
	unternehmer = 'unternehmer',
	potential = 'potential',
	breitenagentur = 'breitenagentur',
	entwicklungsagentur = 'entwicklungsagentur',
	starter = 'starter',
}

export const SegementStates = [
	{
		state: SegmentState.calcuated,
		label: 'Berechnet',
	},
	{
		state: SegmentState.unternehmer,
		label: 'Unternehmeragentur',
	},
	{
		state: SegmentState.potential,
		label: 'Potentialagentur',
	},
	{
		state: SegmentState.breitenagentur,
		label: 'Breitenagentur',
	},
	{
		state: SegmentState.entwicklungsagentur,
		label: 'Entwicklungsagentur',
	},
	{
		state: SegmentState.starter,
		label: 'Starter',
	},
];
