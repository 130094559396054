import { Field } from 'formik';
import { useStore } from 'app/context';

export const InspIdSelectField = ({ ...props }) => {
	var { ...fieldProps } = props;
	const { agenturListUiStore } = useStore();

	return (
		<div className="select">
			<Field {...fieldProps} as="select">
				{agenturListUiStore.inspIdOptions.map((p) => (
					<option key={p.value} value={p.value}>
						{p.label}
					</option>
				))}
			</Field>
		</div>
	);
};
