import { SteartTag } from 'app/components/agt/common/SteartTag';
import { Icon } from 'app/components/common/Icon';
import { Table } from 'app/components/table/table';
import { TableModel, TableRow } from 'app/components/table/table.model';
import { useStore } from 'app/context';
import { BnrProdPosModel } from 'app/models/berichte/bnr.prod.pos.model';
import { Steart } from 'app/models/core/steart.model';
import { UserModel } from 'app/models/core/user.model';
import { ZeWidgetDef } from 'app/models/gp/zewidgets/vbl.ze.widgets';
import { formatNum, formatPercent } from 'app/utils';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import routes from 'routes';
import { BnrZeWidget } from './BnrZeWidget';
import { Button } from 'app/components/common/Button';
import { BnrGpDoksModal } from './BnrGpDoksModal';
import { Einheit } from 'app/models/core/einheit.model';
import _ from 'lodash';
import { BoniStufenCell } from 'app/components/shared/Bonistufe.cell';

interface IAgtProdGpGroupTable {
	year: number;
	betreuerBnrId?: number;
}

export const GPTitleCell = (row: TableRow<BnrProdPosModel>) => {
	const [showInfo, setShowInfo] = useState(true);

	const toggleShowInfo = () => {
		setShowInfo(!showInfo);
	};

	// special case for Multis Akuma positions
	const posId = row.data.posDef.posId[0];
	const isSubtitle = [2320126, 2320127].includes(posId);

	return (
		<div className="gp-pos-title">
			<div className={`gp-title ${isSubtitle ? 'color-grey' : ''}`}>
				<span>
					{row.data.posDef.posText}
					{` `}
				</span>
				{/* {row.data.posDef.posDesc && (
					<>
						<Button className="button is-small is-icon is-paddingless" onClick={(e) => toggleShowInfo}>
							<Icon iconClass={showInfo ? 'times-circle color-grey' : 'info-circle color-primary'} />
						</Button>
					</>
				)} */}
				{showInfo && row.data.posDef.posDesc && <div className="gp-desc">{row.data.posDef.posDesc}</div>}
			</div>
		</div>
	);
};

export const BnrProdGpUebersicht = observer((props: IAgtProdGpGroupTable) => {
	const { bnrProdUiStore, agenturListUiStore, agtUserUiStore, agenturStore, uiStore } = useStore();
	const history = useHistory();

	const [loaded, setLoadad] = useState<boolean>(false);
	const [zeWidgets, setZeWidgets] = useState<ZeWidgetDef[]>([]);
	const [betreuer, setBetreuer] = useState<UserModel>();

	const [isMulti, setIsMulti] = useState<boolean>(false);

	const formatPerc = (val: number) => {
		return `${formatPercent(val, 1)}%`;
	};

	const renderToGPTableRow = (row: TableRow<BnrProdPosModel>, path: string, formatFunc: (val: number) => string) => {
		if (row.data.posDef.einheit.id === Einheit.quali) {
			if (path === 'posVal.ziel') {
				return <div>Laut Kriterienkatalog</div>;
			}
			return <></>;
		}
		const val = _.get(row.data, path);

		// Hide Multis DigiId and MAZ ZE and ZE Zeit
		const postext = row.data.posDef.posText;
		if (postext.indexOf('Neue Digi-ID') >= 0 || postext.indexOf('Neuanmeldungen Meine Allianz') >= 0) {
			if (isMulti && (path === 'posVal.ze' || path === 'posVal.zeZeit')) {
				return <span></span>;
			}
			// just one decimal
			return <>{formatPercent(val || 0, 1)}%</>;
		}
		// Multis Cross-selling

		if (postext === 'davon Akuma Prime' || postext === 'davon Akuma Planer') {
			if (path === 'posVal.ze' || path === 'posVal.zeZeit') {
				return <span></span>;
			}
		}
		return <>{formatFunc(val || 0)}</>;
	};

	const tm = useMemo(() => {
		const tm = new TableModel<BnrProdPosModel, number>();
		const cols = [
			{
				label: 'Position',
				path: 'posDef.posText',
				sortBy: 'posDef.group.name',
				render: GPTitleCell,
			},
			{
				label: 'BoniStufe',
				path: 'boniStufe',
				render: BoniStufenCell,
				format: formatNum,
			},
			{
				label: 'Zielwert',
				path: 'posVal.ziel',
				// format: formatZiel,
				render: (row: TableRow<BnrProdPosModel>) => renderToGPTableRow(row, 'posVal.ziel', formatNum),
			},
			{
				label: 'Eigener Zielwert',
				path: 'eigenesZiel',
				format: formatNum,
				editable: true,
				render: (row: TableRow<BnrProdPosModel>) => renderToGPTableRow(row, 'eigenesZiel', formatNum),
			},
			{
				label: 'Ergebnis',
				path: 'posVal.ergebnis',
				render: (row: TableRow<BnrProdPosModel>) => renderToGPTableRow(row, 'posVal.ergebnis', formatNum),
			},
			{
				label: 'ZE',
				path: 'posVal.ze',
				render: (row: TableRow<BnrProdPosModel>) => renderToGPTableRow(row, 'posVal.ze', formatPerc),
			},
			{
				label: 'ZE (Zeit)',
				path: 'posVal.zeZeit',
				render: (row: TableRow<BnrProdPosModel>) => renderToGPTableRow(row, 'posVal.zeZeit', formatPerc),
			},
			// {
			// 	label: '',
			// 	path: 'data.posDef.planKey',
			// 	render: (row: TableRow<BnrProdPosModel>) => {
			// 		return <> {row.data.posDef.agtPosDef && <Icon iconClass={'list-alt'} />}</>;
			// 	},
			// },
		];

		if (isMulti) {
			// remove Eigener Zielwert from cols
			cols.splice(3, 1);
		} else {
			// remove bonistufe from cols
			cols.splice(1, 1);
		}

		tm.groupHeaderRow = {
			label: 'posDef.group.name',
			path: 'posDef.group.name',
		};
		tm.setCols(cols);
		tm.sortDisabled = true;
		tm.editDisabled = true;
		tm.idProperty = 'posDef.posId';
		tm.idType = 'number';

		tm.onRowClick = (row: TableRow<BnrProdPosModel>) => {
			if (row.data.posDef.agtPosDef) {
				history.push(
					routes.BERICHTE.getPath('production', 'agtpos', row.data.posDef.year.toString(), row.data.posDef.agtPosDef.group.id, row.data.posDef.agtPosDef.planKey),
				);
			} else if (row.data.posDef.einheit.id === Einheit.quali) {
				uiStore.showModal(uiStore.modalIds.gpDoks);
			}
		};
		return tm;
	}, [history, isMulti, uiStore]);

	useEffect(() => {
		if (agenturStore.session!.currentUser!.isMulti) {
			setIsMulti(true);
		}

		let _zeWidgts = bnrProdUiStore.gpStore.zeWidgets;
		let bnrId = bnrProdUiStore.session.currentUser!.bnrId;
		if (agenturListUiStore.currentFilter.singleSelectedBetreuerId) {
			bnrId = agenturListUiStore.currentFilter.singleSelectedBetreuerId;
		}
		if (props.betreuerBnrId) {
			bnrId = props.betreuerBnrId;
		}
		agtUserUiStore.getUserByBnrId(bnrId).then((u) => {
			if (u) {
				setBetreuer(u);

				bnrProdUiStore.findAllForBetreuer(u, props.year).then((res) => {
					tm.setRowData(res);
					_zeWidgts = bnrProdUiStore.gpStore.getZeWidgetBySteart(u.steart.steart);
					setZeWidgets(_zeWidgts);
					setLoadad(true);
				});
			} else {
				tm.setRowData([]);
				setZeWidgets([]);
				setLoadad(true);
				setBetreuer(undefined);
			}
		});
	}, [agenturStore, tm, props.year, agenturListUiStore.currentFilter.singleSelectedBetreuerId, bnrProdUiStore, bnrProdUiStore.gpStore, agtUserUiStore, props.betreuerBnrId]);

	const openGpDoks = () => {
		uiStore.showModal(uiStore.modalIds.gpDoks);
	};

	return (
		<>
			<div className="table-container pad-bottom-3rem">
				<div className="grid pad-1rem">
					{betreuer && (
						<>
							<div className="note-box align-center">
								<div className="note-icon">
									<Icon iconClass="info-circle color-primary" />
								</div>

								<div className="grid is-col-auto align-center">
									<div className="note-message">
										GP für <SteartTag modifier="is-inline" id={betreuer.steart.id} /> {betreuer.name} ({betreuer.bnrId})
									</div>
									{isMulti && (
										<div className="text-right">
											<Button className="button is-primary" onClick={openGpDoks}>
												Kriterien Definieren
											</Button>
										</div>
									)}
								</div>
							</div>
							{betreuer.steart.steart === Steart.GSL && (
								<div className="note-box is-red">
									<h1>GP für GSL noch in Arbeit</h1>
								</div>
							)}
							{isMulti && <BnrGpDoksModal year={props.year} user={betreuer} />}
						</>
					)}
					{!betreuer && <>Bitte Betreuer oder Agentur auswählen</>}
				</div>

				<div className="grid is-col-half pad-1rem border-top border-bottom">
					{zeWidgets.map((zeWidget) => (
						<BnrZeWidget key={zeWidget.title} def={zeWidget} disableCollapse={true} widgetKey={zeWidget.key} useRpt2={true} year={props.year} betreuer={betreuer} />
					))}
				</div>

				{loaded && betreuer && <Table modifier="align-top" stickyHeader={true} tm={tm} />}
			</div>
		</>
	);
});
