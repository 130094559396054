import { Button } from 'app/components/common/Button';
import { CheckboxControl } from 'app/components/common/CheckboxControl';
import { InputControl } from 'app/components/common/InputControl';
import { ISelectOpt, SelectInput } from 'app/components/common/Select';
import { useStore } from 'app/context';
import { AdminUserSteartOpts } from 'app/models/core/steart.model';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';

const diffTypeOpts: ISelectOpt[] = [
	{ label: 'updated', value: 'updated' },
	{ label: 'created', value: 'created' },
	{ label: 'deleted', value: 'deleted' },
	{ label: 'createddeleted', value: 'createddeleted' },
];

export const AdminBnrDiffFilter = observer(() => {
	const { adminBnrDiffUiStore } = useStore();

	const [kwOpts, setKwOpts] = useState<ISelectOpt[]>([]);

	useEffect(() => {
		adminBnrDiffUiStore.loadKws().then(() => {
			setKwOpts(adminBnrDiffUiStore.kwOpts);
			adminBnrDiffUiStore.checkKwsAndLoad();
		});
	}, [adminBnrDiffUiStore, adminBnrDiffUiStore.kws]);

	const onFromKwChange = (name: string, opt: any) => {
		runInAction(() => {
			if (opt) {
				adminBnrDiffUiStore.currentFromKw = parseInt(opt.value);
			} else {
				adminBnrDiffUiStore.currentFromKw = undefined;
			}
		});
		adminBnrDiffUiStore.checkKwsAndLoad();
	};

	const onToKwChange = (name: string, opt: any) => {
		runInAction(() => {
			if (opt) {
				adminBnrDiffUiStore.currentToKw = parseInt(opt.value);
			} else {
				adminBnrDiffUiStore.currentToKw = undefined;
			}
		});
		adminBnrDiffUiStore.checkKwsAndLoad();
	};

	const onSteartChange = (name: string, val: ISelectOpt[]) => {
		runInAction(() => {
			adminBnrDiffUiStore.stearts = val.map((v) => v.value);
		});
	};

	const onDiffTypeChange = (name: string, val: ISelectOpt[]) => {
		runInAction(() => {
			adminBnrDiffUiStore.diffType = val.map((v) => v.value);
		});
	};

	const onTextChange = (name: string, val: string) => {
		runInAction(() => {
			adminBnrDiffUiStore.fullText = val;
		});
	};

	const toggleTwoOnThree = (name: string, val: boolean) => {
		runInAction(() => {
			adminBnrDiffUiStore.showOnly2On3BnrIds = val;
		});
	};

	const toggleHasAccount = (name: string, val: boolean) => {
		runInAction(() => {
			adminBnrDiffUiStore.hasUserAccount = val;
		});
	};

	const bulkSendMail = () => {
		adminBnrDiffUiStore.bulkSendMail();
	};

	return (
		<div className="admin-users-filter pad-1rem grid is-col-half">
			<div className="left grid">
				<div className="text-input">
					<SelectInput modifier="is-inline" name="fromKw" label="fromKw" value={adminBnrDiffUiStore.currentFromKw} onChange={onFromKwChange} opts={kwOpts} />
				</div>

				<div className="text-input">
					<SelectInput
						modifier="is-inline"
						name="steart"
						label="Steart"
						isMulti={true}
						value={adminBnrDiffUiStore.stearts}
						onChange={onSteartChange}
						opts={AdminUserSteartOpts}
					/>
				</div>
				<div className="text-input">
					<InputControl
						inline={true}
						label="Filter"
						name="fullText"
						value={adminBnrDiffUiStore.fullText}
						onChange={onTextChange}
						debounceTime={500}
						placeholder={'Textsuche: Name, Bensl, BnrId'}
					/>
				</div>
			</div>
			<div className="right grid">
				<div className="text-input">
					<SelectInput modifier="is-inline" name="toKw" label="toKw" value={adminBnrDiffUiStore.currentToKw} onChange={onToKwChange} opts={kwOpts} />
				</div>
				<div className="text-input">
					<SelectInput
						modifier="is-inline"
						name="diff"
						label="diff"
						isMulti={true}
						value={adminBnrDiffUiStore.diffType}
						onChange={onDiffTypeChange}
						opts={diffTypeOpts}
					/>
				</div>
				<div className="text-input">
					<CheckboxControl label="Nur xx2xxxx BnrIds" name="twoOnThre" value={adminBnrDiffUiStore.showOnly2On3BnrIds} onChange={toggleTwoOnThree} />
				</div>
				<div className="text-input">
					<CheckboxControl label="Mit Account" name="twoOnThre" value={adminBnrDiffUiStore.hasUserAccount} onChange={toggleHasAccount} />
				</div>
			</div>

			<div className="active-filters">
				{adminBnrDiffUiStore.filtered.length} / {adminBnrDiffUiStore._items.length} BNRIds
				<Button type="button" className="button is-secondary" disabled={adminBnrDiffUiStore.invitableUserCount === 0} onClick={bulkSendMail}>
					Einladungsmail an {adminBnrDiffUiStore.invitableUserCount} Benutzer schicken
				</Button>
			</div>
		</div>
	);
});
