import Widget, { IWidgetConsumer } from 'app/components/common/Widget';
import { observer } from 'mobx-react';
import { useStore } from 'app/context';
import { AgtBewertungsVerlauf } from 'app/components/berichte/bestand/AgtBewrtungsverlauf';
import routes from 'routes';
import { useEffect, useState } from 'react';
import { WidgetNavigate } from 'app/components/common/WidgetNavigate';

export const AgenturBewertungsverlaufWidget = observer((props: IWidgetConsumer) => {
	const { agenturUiStore } = useStore();
	const agt = agenturUiStore.current;
	const [rptPath, setRptPath] = useState<string | undefined>();
	useEffect(() => {
		if (agenturUiStore.currentAgtId) {
			setRptPath(routes.AGENTUR.getPath(agenturUiStore.currentAgtId?.toString(), 'berichte', 'bestand', 'bestandundbewertung'));
		} else {
			setRptPath(undefined);
		}
	}, [agenturUiStore.currentAgtId]);
	return (
		<>
			<Widget title={'Bewertungsverlauf'} action={<WidgetNavigate text={'Zum Bericht'} path={rptPath} />} widgetId={props.widgetKey}>
				{agt && <AgtBewertungsVerlauf agt={agt} />}
			</Widget>
		</>
	);
});
