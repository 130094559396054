
import { KvBnrMsModel } from 'app/models/kv.bnr.ms.model';
import { BaseCachedEntityStore } from './core/base.cached.entity.store';
import { WochenmailChangeSummary } from './ui/wochenmail.ui.store';

export class KvWochenmailModel extends KvBnrMsModel {
    constructor(opts: any) {
        super(opts);
        this.summary = new WochenmailChangeSummary();
        if (this.valueJson) {

            try {
                const opts = JSON.parse(this.valueJson);
                this.summary.agt = opts.agt;
                this.summary.bds = opts.bds;
                this.summary.bnr = opts.bnr;
                this.summary.endingExprts = opts.endingExprts;
                this.summary.jbls = opts.jbls;
                this.summary.newShrdDcs = opts.newShrdDcs;
                this.summary.tasksDue = opts.tasksDue;

            } catch (ex) {
                console.error('Invalid JSON for KvWochenmailModel', this.valueJson);
            }
        }
    }
    summary: WochenmailChangeSummary;
}

export class AdminWochenmailKwStore extends BaseCachedEntityStore<KvWochenmailModel> {
    idProp: string = 'id';
    apiPath = 'api/kv_bnr_ms/wochenmail';

    createInstances(opts: any): KvWochenmailModel {
        return new KvWochenmailModel(opts);
    }
}
