import { Button } from 'app/components/common/Button';
import { Modal } from 'app/components/Modal';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { AdminBnrTransferNewForm } from './AdminBnrTransferNewForm';

export const AdminBnrTransferNewButton = observer(() => {
	const { uiStore } = useStore();

	const open = () => {
		uiStore.showModal(uiStore.modalIds.adminNewBnrTransfer);
	};
	return (
		<>
			<Button type="button" className="button is-small" onClick={open}>
				Neue Datenübernahme anlegen
			</Button>
			<AdminBnrTransferNewModel />
		</>
	);
});

export const AdminBnrTransferNewModel = observer(() => {
	const { uiStore } = useStore();
	const onClose = () => {
		uiStore.hideModal(uiStore.modalIds.adminNewBnrTransfer);
	};

	return (
		<Modal modalId={uiStore.modalIds.adminNewBnrTransfer} title={'Neue Datenübernahme'}>
			<AdminBnrTransferNewForm onClose={onClose} />
		</Modal>
	);
});
