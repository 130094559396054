import React, { useEffect, useMemo } from 'react';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { Table } from './../../table/table';
import { ITableColumn, TableModel, TableRow } from './../../table/table.model';
import { runInAction } from 'mobx';
import { useHistory } from 'react-router';
import routes from 'routes';
import { BnrTransferVM } from 'app/stores/ui/admin.bnrtransfer.ui.store';
import { formatDate } from 'app/utils';

export const AdminBnrTransfersList = observer(() => {
	const { adminBnrTransferUiStore } = useStore();
	const history = useHistory();

	const tm = useMemo(() => {
		const viewDoc = (doc: BnrTransferVM) => {
			runInAction(() => {
				history.push(routes.ADMINBNRTRANSFER.getPath(doc.transfer.id));
			});
		};

		const tm = new TableModel<BnrTransferVM, number>();
		let cols: ITableColumn<BnrTransferVM, number>[] = [
			{
				label: 'Quelle BnrId',
				path: 'transfer.sourceBnrId',
			},
			{
				label: 'Quelle Name',
				path: 'transfer.sourceName',
			},
			{
				label: 'Quelle Email',
				path: 'transfer.sourceEmail',
			},
			{
				label: 'Ziel BnrId',
				path: 'transfer.targetBnrId',
			},
			{
				label: 'Erstellt',
				path: 'transfer.created',
				format: (val: any) => {
					return formatDate(val);
				},
			},
			{
				label: 'Erstellt',
				path: 'transfer.modified',
				format: (val: any) => {
					return formatDate(val);
				},
			},
			{
				label: 'Erledigt',
				path: 'transfer.transferDate',
				format: (val: any) => {
					return formatDate(val);
				},
			},
			{
				label: 'Status',
				path: 'overAllState',
			},
		];

		tm.onRowClick = (row: TableRow<BnrTransferVM>) => {
			viewDoc(row.data);
		};

		tm.setCols(cols);
		tm.sortBy = 'name';
		tm.idProperty = 'id';
		tm.idType = 'string';
		return tm;
	}, [history]);

	useEffect(() => {
		adminBnrTransferUiStore.load().then((items) => {
			tm.setRowData(adminBnrTransferUiStore.filtered);
			console.log('adminBnrTransferUiStore.items', adminBnrTransferUiStore.items.length);
		});
	}, [adminBnrTransferUiStore, adminBnrTransferUiStore.filtered, tm]);

	return (
		<>
			<Table tm={tm}></Table>
		</>
	);
});
