import { useStore } from 'app/context';
import { passwordRegex } from 'app/utils';
import { Field, Form, Formik } from 'formik';
import { observer } from 'mobx-react';
import * as Yup from 'yup';
import { Button } from './common/Button';
import { notify } from './common/notify';
import FormField from './form/FormField';

const changePasswordSchmema = Yup.object({
	oldPassword: Yup.string().required('Bitte eingeben'),
	password1: Yup.string().matches(passwordRegex, 'Passwort muss mindestens 8 Zeichen umfassen, Groß- und Kleinbuchstaben beinhalten und mindestens 1 Sonderzeichen enthalten').required('Passwort ist erforderlich').default(''),
	password2: Yup.string()
		.oneOf([Yup.ref('password1')], 'Die Passwörter müssen übereinstimmen')
		.required('Bitte wiederholen Sie das Passwort')
		.default(''),
}).required();

type ChangePassword = Yup.InferType<typeof changePasswordSchmema>;

export const ChangePasswordForm = observer(() => {
	const { userSettingsStore, uiStore } = useStore();

	const handleSubmit = async (values: ChangePassword) => {
		if (await userSettingsStore.changPassword(values.oldPassword, values.password1)) {
			notify('Passwort gesetzt', 'Sie können sich absofort mit dem neuen Passwort anmelden', 'info');
			uiStore.hideModal(uiStore.modalIds.changePassword);
		}
	};

	return (
		<>
			<Formik
				validationSchema={changePasswordSchmema}
				initialValues={{
					oldPassword: '',
					password1: '',
					password2: '',
				}}
				onSubmit={handleSubmit}
			>
				{({ errors, touched, isValid }) => {
					return (
						<Form>
							<div className="grid">
								<FormField className="title" error={errors.oldPassword} touched={touched.oldPassword} label={'Altes Passwort'}>
									<Field type="password" name="oldPassword" placeholder="Bitte altes Passwort eingeben" />
								</FormField>
								<FormField className="title" error={errors.password1} touched={touched.password1} label={'Neues Passwort'}>
									<Field type="password" name="password1" placeholder="Bitte neues Passwort eingeben" />
								</FormField>
								<FormField className="title" error={errors.password2} touched={touched.password2} label={'Neues Passwort bestätigen'}>
									<Field type="password" name="password2" placeholder="Bitte neues Passwort bestätigen" />
								</FormField>

								<Button type="submit" className="button is-primary is-block" isFormInvalid={!isValid}>
									Passwort setzen
								</Button>
							</div>
						</Form>
					);
				}}
			</Formik>
		</>
	);
});
