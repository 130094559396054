import { useEffect, useState } from 'react';
import { useField, useFormikContext } from 'formik';
import { AgtSelector } from '../shared/AgtSelector';
import { AgenturModel } from 'app/models/agentur.model';
import { useStore } from 'app/context';

export const AgtSelectField = ({ ...props }) => {
	const { agenturStore } = useStore();

	const [agt, setAgt] = useState<AgenturModel | undefined>();
	const { setFieldValue } = useFormikContext();

	const [field] = useField(props as any);
	const onSelect = (agt?: AgenturModel) => {
		setAgt(agt);
		if (agt) {
			setFieldValue(field.name, agt?.agtId);
		} else {
			setFieldValue(field.name, 0);
		}
	};

	useEffect(() => {
		if (field.value) {
			agenturStore.findById(field.value as number).then((agt) => {
				setAgt(agt);
			});
		}
	}, [field, agenturStore]);
	return <AgtSelector onSelect={onSelect} selectedAgt={agt} defaultText={'Bitte Agentur auswählen'} />;
};
export default AgtSelectField;
