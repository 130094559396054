import { Button } from 'app/components/common/Button';
import { Icon } from 'app/components/common/Icon';

export const LeadNowVideoButton = () => {
	const onClick = () => {
		const url = 'https://amisonline.allianz.de/protected/agentur/marketingundwerbung/internetagenturcenter/leadmanagement-lead-now/index.html';
		window.open(url, '_blank', 'noopener,noreferrer');
	};

	return (
		<Button className="button is-primary is-inverted is-small" title="Liquiditäts Leitfaden" onClick={onClick}>
			<Icon iconClass="video-camera" aria-hidden="true" />
			<span>Erklärflim</span>
		</Button>
	);
};
