import { AgenturModel } from 'app/models/agentur.model';
import { AgtPlanModel } from 'app/stores/agt.plan.store';
import { DocumentVM } from 'app/stores/ui/document.ui.store';
import { observer } from 'mobx-react';
import React from 'react';
import { FileUpload } from '../common/FileUpload';

interface IDokumentUpload {
	agt?: AgenturModel;
	plan?: AgtPlanModel;
	doc?: DocumentVM;
	isButton?: boolean;
}

export const DokumentUploadButton = observer((props: IDokumentUpload) => {
	return (
		<FileUpload
			isButton={props.isButton}
			maxSize={20000000}
			// maxFiles={1}
			multiple={false}
			dropZoneActiveText={'Datei loslassen'}
			dropZoneText={props.isButton ? 'Datei Hochladen' : 'Datei hier her ziehen oder klicken zur Dateiauswahl'}
			uploadingText={'Upload läuft'}
			agtId={props.agt?.agtId}
			planId={props.plan?.id}
			dokId={props.doc?.id}
			type={'plan'}
		/>
	);
});
