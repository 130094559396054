import Widget from 'app/components/common/Widget';
import { Table } from 'app/components/table/table';
import { TableModel, ITableColumn } from 'app/components/table/table.model';
import { useStore } from 'app/context';
import { formatNum } from 'app/utils';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';

export const AdminUsageMatomoDevices = observer(() => {
	const { adminUsageUiStore } = useStore();

	useEffect(() => {
		adminUsageUiStore.loadMatomoGeneral();
	}, [adminUsageUiStore]);

	const tmDevices = useMemo(() => {
		const tm = new TableModel<any, number>();
		let cols: ITableColumn<any, number>[] = [
			{
				label: 'Device',
				path: 'name',
			},
			{
				label: 'Value',
				path: 'value',
				format: formatNum,
			},
			{
				label: '%',
				path: 'perc',
			},
		];

		tm.setCols(cols);
		// tm.sortBy = 'name';
		tm.idProperty = 'id';
		tm.idType = 'string';
		return tm;
	}, []);

	useEffect(() => {
		const matomoData = adminUsageUiStore.matomoVisitsData;
		if (!matomoData) return;

		let tmDeviceData: any[] = [];
		matomoData.devices.forEach((device: any) => {
			tmDeviceData.push({
				name: device.label,
				value: device.nb_visits,
				perc: ((device.nb_visits / matomoData.totalVisits) * 100).toFixed(1),
			});
		});
		tmDevices.setRowData(tmDeviceData);
	}, [adminUsageUiStore.matomoVisitsData, tmDevices]);

	return (
		<>
			<div className="pad-1rem">
				<Widget title="Nutzung per Gerätetyp" modifier="is-paddingless" action={<span className="tag is-blue">Matomo</span>}>
					<Table tm={tmDevices} />
				</Widget>
			</div>
		</>
	);
});
