import { Einheit } from 'app/models/core/einheit.model';
import { AgtGpPosModel } from 'app/models/gp/agt.gp.pos.model';
import { allExcept, allExceptBasis, GP, onlyBasis, PosUnDef } from '../gp';
const agtPosDef: AgtGpPosModel[] = [];

agtPosDef.push(
	new AgtGpPosModel({
		year: 2022,
		posId: 2255852,
		planKey: 'produktivitaet',
		einheit: Einheit.bew,
		nonPro3GpPos: { posId: 30, group: 'eh', posText: 'Produktivität' },
		pro3GpPos: { posId: 1130030, group: 'eh', posText: 'Produktivität ' },
		hideFromTreeMenu: true,
	}),
);

agtPosDef.push(
	new AgtGpPosModel({
		year: 2023,
		posId: 2255001,
		planKey: 'idd',
		pro3GpPos: { posId: 1, group: 'kunde', posText: 'Weiterbildungspflicht. AGT' },
		einheit: Einheit.stck,
	}),
);
agtPosDef.push(
	new AgtGpPosModel({
		year: 2023,
		posId: 1130002,
		planKey: 'mazneu',
		pro3GpPos: { posId: 2, group: 'kunde', posText: 'Neue MAZ Anmeldungen' },
		einheit: Einheit.stck,
	}),
);
// ONLY WHEN VALUE EXISTS IN DATA
agtPosDef.push(
	new AgtGpPosModel({
		year: 2023,
		posId: 2255001,
		planKey: 'nps',
		pro3GpPos: { posId: 4, group: 'kunde', posText: 'Kundenzufriedenheit NPS' },
		einheit: Einheit.minuten,
	}),
);
// ONLY WHEN VALUE EXISTS IN DATA
agtPosDef.push(
	new AgtGpPosModel({
		year: 2023,
		posId: 2255001,
		planKey: 'nps_anzahl',
		pro3GpPos: { posId: 6, group: 'kunde', posText: 'Anz Kundenbewertungen NPS' },
		einheit: Einheit.minuten,
	}),
);

agtPosDef.push(
	new AgtGpPosModel({
		year: 2023,
		posId: 2255001,
		planKey: 'unternehmensschutz',
		pro3GpPos: { posId: 7, group: 'vss', posText: 'Unternehmensschutz' },
		einheit: Einheit.stck,
	}),
);

agtPosDef.push(
	new AgtGpPosModel({
		year: 2023,
		posId: 2255022,
		planKey: 'unfall_nst',
		pro3GpPos: { posId: 9, group: 'vss', posText: 'Unfall NSt_Digi' },
		nonPro3GpPos: { posId: 1117009, group: 'vss', posText: 'Unfall NSt_Digi' },
		einheit: Einheit.stck,
	}),
);

agtPosDef.push(
	new AgtGpPosModel({
		year: 2023,
		posId: 2250801,
		planKey: 'kus_nst_digi',
		pro3GpPos: { posId: 2250801, group: 'vss', posText: 'KUS Nst. Digi' },
		nonPro3GpPos: { posId: 1110011, group: 'vss', posText: 'KUS Nst. Digi' },
		einheit: Einheit.minuten,
	}),
);

agtPosDef.push(
	new AgtGpPosModel({
		year: 2023,
		posId: 4,
		group: 'vss',
		planKey: 'khpkwlfnst',
		pro3GpPos: { posId: 1130014, group: 'vss', posText: 'Kraft-Haft-PKW LfNst Digi' },
		nonPro3GpPos: { posId: 1117014, group: 'vss', posText: 'Kraft-Haft-PKW LfNst Digi' },
		einheit: Einheit.stck,
		visibleForGps: allExceptBasis,
	}),
);
agtPosDef.push(
	new AgtGpPosModel({
		year: 2023,
		posId: 1110014,
		group: 'vss',
		planKey: 'khpkwlfnstBASIS',
		nonPro3GpPos: { posId: 1110014, group: 'vss', posText: 'Kraft-Haft-PKW LfNst Digi' },
		einheit: Einheit.stck,
		visibleForGps: onlyBasis,
	}),
);

agtPosDef.push(
	new AgtGpPosModel({
		year: 2023,
		posId: 2255020,
		group: 'vss',
		planKey: 'khpkwlfnst_unterjaehrig',
		pro3GpPos: { posId: PosUnDef, group: 'vss', posText: 'Kraft-Haft-PKW LfNst unterj. Digi' },
		einheit: Einheit.stck,
	}),
);

agtPosDef.push(
	new AgtGpPosModel({
		year: 2023,
		posId: 2255020,
		group: 'vss',
		planKey: 'khpkwlfnst_fj',
		pro3GpPos: { posId: PosUnDef, group: 'vss', posText: 'Kraft-Haft-PKW FJ. Digi' },
		einheit: Einheit.stck,
	}),
);

agtPosDef.push(
	new AgtGpPosModel({
		year: 2023,
		posId: 1130020,
		group: 'vss',
		planKey: 'lebenakspvnst',
		pro3GpPos: { posId: 20, group: 'vss', posText: 'VAG: Leben AKS- Pf V NSt Digi' },
		einheit: Einheit.stck,
		boniStufen: 2,
	}),
);

agtPosDef.push(
	new AgtGpPosModel({
		year: 2023,
		posId: 2255151,
		planKey: 'lebenrisbewoze',
		pro3GpPos: { posId: 22, group: 'vss', posText: 'VAG:Leben Ris. Bew Digi' },
		einheit: Einheit.bew,
	}),
);

agtPosDef.push(
	new AgtGpPosModel({
		year: 2023,
		posId: 2255030,
		planKey: 'lebenknp',
		einheit: Einheit.stck,
		pro3GpPos: { posId: 25, group: 'vss', posText: 'VAG: Leben KnP NSt Digi ' },
	}),
);

agtPosDef.push(
	new AgtGpPosModel({
		year: 2023,
		group: 'vss',
		posId: 2255151,
		planKey: 'kvpflegelfnstea',
		pro3GpPos: { posId: 27, group: 'vss', posText: 'KV-Pflege-LFNST-EA_Digi' },
		einheit: Einheit.stck,
		boniStufen: 2,
	}),
);

// Eingangshürde
agtPosDef.push(
	new AgtGpPosModel({
		year: 2023,
		posId: 2255129,
		planKey: 'gesamtbewertung',
		einheit: Einheit.bew,
		pro3GpPos: { posId: 2255852, group: 'eh', posText: 'Gesamtbewertung', planKey: 'gesamtbewertung' },
		nonPro3GpPos: { posId: 2256851, group: 'eh', posText: 'Gesamtbewertung', planKey: 'gesamtbewertung' },
		//visibleForGps: allExceptPro3,
		//boniStufe: +1, +2
	}),
);
agtPosDef.push(
	new AgtGpPosModel({
		year: 2023,
		posId: 2255129,
		planKey: 'sachlangfristigenst',
		einheit: Einheit.stck,
		pro3GpPos: { posId: 1117040, group: 'eh', posText: 'Sach langfr. Neustück' },
		visibleForGps: allExcept([GP.Basisvariante, GP.Pro3]),
		//boniStufe: +1, +2
	}),
);
agtPosDef.push(
	new AgtGpPosModel({
		year: 2023,
		posId: 1110040,
		planKey: 'sachlangfristigenstBASIS',
		einheit: Einheit.stck,
		pro3GpPos: { posId: 1110040, group: 'eh', posText: 'Sach langfr. Neustück' },
		visibleForGps: onlyBasis,
		//boniStufe: +1, +2
	}),
);

// Nach Eingangshürde
agtPosDef.push(
	new AgtGpPosModel({
		year: 2023,
		posId: 2255129,
		planKey: 'psachokrunfbzuwbt',
		einheit: Einheit.bew,
		pro3GpPos: { posId: 1130129, group: 'neh', posText: 'P-Sach o.Kr/U BZUW-BT' },
		nonPro3GpPos: { posId: 129, group: 'neh', posText: 'P-Sach o.Kr/U BZUW-BT' },
	}),
);

// // Versicherung und Vorsorge
agtPosDef.push(
	new AgtGpPosModel({
		year: 2023,
		posId: 2255129,
		planKey: 'psachokrunfbew',
		einheit: Einheit.bew,
		pro3GpPos: { posId: 121, group: 'vuv', posText: 'P-Sach o.Kr/U oAnP BEW Digi' },
		//boniStufe: +1, +2
	}),
);
agtPosDef.push(
	new AgtGpPosModel({
		year: 2023,
		posId: 2255129,
		planKey: 'unfallbew',
		einheit: Einheit.bew,
		pro3GpPos: { posId: 131, group: 'vuv', posText: 'Unfall Bewertung o. Anp Bew' },
		//boniStufe: +1, +2
		boniStufen: 3,
	}),
);

agtPosDef.push(
	new AgtGpPosModel({
		year: 2023,
		posId: 2255141,
		planKey: 'fsachokraftbew',
		pro3GpPos: { posId: 151, group: 'vuv', posText: 'F-Sach o. Kraft o. Anp. Bew' },
		einheit: Einheit.bew,
		//boniStufe: +1, +2
		boniStufen: 3,
	}),
);

agtPosDef.push(
	new AgtGpPosModel({
		year: 2023,
		posId: 2255161,
		planKey: 'vagrentelebenbewzoze',
		einheit: Einheit.bew,
		pro3GpPos: { posId: 161, group: 'vuv', posText: 'VAG Rente/Leben BEW_Digi' },
	}),
);

agtPosDef.push(
	new AgtGpPosModel({
		year: 2023,
		posId: 2255211,
		planKey: 'krankenzusatzbew',
		einheit: Einheit.bew,
		pro3GpPos: { posId: 1130211, group: 'vuv', posText: 'Kranken Zusatz Bew Digi' },
		nonPro3GpPos: { posId: 1117211, group: 'vuv', posText: 'Kranken Zusatz Bew Digi' },
		// boniStufe +1, +2
		boniStufen: 3,
		visibleForGps: allExceptBasis,
	}),
);
agtPosDef.push(
	new AgtGpPosModel({
		year: 2023,
		posId: 2255211,
		planKey: 'krankenzusatzbewBASIS',
		einheit: Einheit.bew,
		pro3GpPos: { posId: 1130211, group: 'vuv', posText: 'Kranken Zusatz Bew Basis' },
		nonPro3GpPos: { posId: 1110211, group: 'vuv', posText: 'Kranken Zusatz Bew Basis' },
		boniStufen: 3,
		visibleForGps: onlyBasis,
	}),
);

// GP PLUS
agtPosDef.push(
	new AgtGpPosModel({
		year: 2023,
		// group: 'gpplus',
		posId: 2255151,
		planKey: 'lebenknpkleiner80bew',
		pro3GpPos: { posId: 1180110, group: 'gpplus', posText: 'VAG:Leben KnP Bew Digi ' },
		einheit: Einheit.bew,
	}),
);
agtPosDef.push(
	new AgtGpPosModel({
		year: 2023,
		posId: 1180130,
		planKey: 'lebenknpebdigi',
		pro3GpPos: { posId: 1180130, group: 'gpplus', posText: 'VAG: Leben EB Digi ' },
		einheit: Einheit.bew,
	}),
);

agtPosDef.push(
	new AgtGpPosModel({
		year: 2023,
		posId: 2255171,
		planKey: 'wiederanlagesumme',
		pro3GpPos: { posId: 1180150, group: 'gpplus', posText: 'VAG: Wiederanlage' },
		einheit: Einheit.bew,
	}),
);

agtPosDef.push(
	new AgtGpPosModel({
		year: 2023,
		posId: 2255171,
		planKey: 'wiederanlagesummeufk',
		einheit: Einheit.bew,
		pro3GpPos: { posId: 1180151, group: 'gpplus', posText: 'Wiederanlage U/F' },
	}),
);

agtPosDef.push(
	new AgtGpPosModel({
		year: 2023,
		posId: 2255171,
		planKey: 'wiederanlagesummeleben',
		einheit: Einheit.bew,
		pro3GpPos: { posId: 1180152, group: 'gpplus', posText: 'VAG: Wiederanlage Leben' },
	}),
);

agtPosDef.push(
	new AgtGpPosModel({
		year: 2022,
		posId: 2255171,
		planKey: 'krankenbkvbew',
		einheit: Einheit.bew,
		pro3GpPos: { posId: 1180220, group: 'gpplus', posText: 'Kranken bKV BEW' },
	}),
);

agtPosDef.push(
	new AgtGpPosModel({
		year: 2023,
		posId: 2255171,
		planKey: 'krankenneuebefkvgv',
		einheit: Einheit.bew,
		pro3GpPos: { posId: 1180230, group: 'gpplus', posText: 'Kranken_neue_bef_bKV_GV' },
	}),
);

agtPosDef.push(
	new AgtGpPosModel({
		year: 2023,
		posId: 3501200,
		planKey: 'weiterbildungfonds',
		einheit: Einheit.std,
		pro3GpPos: { posId: 1180300, group: 'gpplus', posText: 'Weiterbildungsz. Fonds' },
	}),
);

agtPosDef.push(
	new AgtGpPosModel({
		year: 2023,
		posId: 2255171,
		planKey: 'fondsbew',
		einheit: Einheit.bew,
		pro3GpPos: { posId: 1180301, group: 'gpplus', posText: 'Fonds Bewertung' },
	}),
);

agtPosDef.push(
	new AgtGpPosModel({
		year: 2023,
		posId: 2255171,
		planKey: 'nettomittelzufluss',
		einheit: Einheit.bew,
		pro3GpPos: { posId: 1180311, group: 'gpplus', posText: 'Nettomittelzufluss o. VZ' },
	}),
);

agtPosDef.push(
	new AgtGpPosModel({
		year: 2023,
		posId: 2255171,
		planKey: 'baufinanzierungbew',
		einheit: Einheit.bew,
		pro3GpPos: { posId: 1180401, group: 'gpplus', posText: 'Baufinanzierung Bew' },
		boniStufen: 3,
	}),
);

agtPosDef.push(
	new AgtGpPosModel({
		year: 2023,
		posId: 2255171,
		planKey: 'risikiolvbaufi',
		einheit: Einheit.stck,
		pro3GpPos: { posId: 1180404, group: 'gpplus', posText: 'Risiko-LV i.V.m. AZ Baufi' },
	}),
);

agtPosDef.push(
	new AgtGpPosModel({
		year: 2023,
		posId: 2255171,
		planKey: 'baufinanzierungwuro',
		einheit: Einheit.bew,
		pro3GpPos: { posId: 1180421, group: 'gpplus', posText: 'Baufinanzierung Bew WüRo' },
		// boniStufen: 3,
	}),
);

agtPosDef.push(
	new AgtGpPosModel({
		year: 2023,
		posId: 2255171,
		planKey: 'bausparenneustueck',
		einheit: Einheit.stck,
		pro3GpPos: { posId: 1180450, group: 'gpplus', posText: 'Bausparen Neustück' },
	}),
);

agtPosDef.push(
	new AgtGpPosModel({
		year: 2023,
		posId: 2255171,
		planKey: 'bausparenbewertung',
		einheit: Einheit.bew,
		pro3GpPos: { posId: 1180451, group: 'gpplus', posText: 'Bausparen Bewertung' },
		boniStufen: 3,
	}),
);

// Incentives: Basis
agtPosDef.push(
	new AgtGpPosModel({
		year: 2023,
		posId: 2255171,
		planKey: 'krankenoptionstarife',
		einheit: Einheit.stck,
		nonPro3GpPos: { posId: 1180800, group: 'incfuerfach', posText: 'Kranken Optionstarife Dig' },
		visibleForGps: onlyBasis,
	}),
);
agtPosDef.push(
	new AgtGpPosModel({
		year: 2023,
		posId: 2255171,
		planKey: 'krankenbkVbew',
		einheit: Einheit.stck,
		nonPro3GpPos: { posId: 1180810, group: 'incfuerfach', posText: 'Kranken bKV Bew' },
		visibleForGps: onlyBasis,
	}),
);

export const AgtPositionen2023 = agtPosDef;
