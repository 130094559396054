import Widget from 'app/components/common/Widget';
import { Table } from 'app/components/table/table';
import { TableModel, ITableColumn } from 'app/components/table/table.model';
import { useStore } from 'app/context';
import { formatNum } from 'app/utils';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';

export const AdminUsageMatomoGeneral = observer(() => {
	const { adminUsageUiStore } = useStore();

	useEffect(() => {
		adminUsageUiStore.loadMatomoGeneral();
	}, [adminUsageUiStore]);

	const tmVisits = useMemo(() => {
		const tm = new TableModel<any, number>();
		let cols: ITableColumn<any, number>[] = [
			{
				label: 'Metric',
				path: 'name',
			},
			{
				label: 'Value',
				path: 'value',
			},
		];

		tm.setCols(cols);
		// tm.sortBy = 'name';
		tm.idProperty = 'id';
		tm.idType = 'string';
		return tm;
	}, []);

	useEffect(() => {
		const matomoData = adminUsageUiStore.matomoVisitsData;
		if (!matomoData) return;

		let tmData: any[] = [
			{
				name: 'Sitzungen',
				value: formatNum(matomoData.totalVisits),
			},
			{
				name: 'Aufenthaltsdauer',
				value: (matomoData.averageTime / 60).toFixed(2),
			},
			{
				name: 'Aktionen pro Besuch',
				value: matomoData.actionsPerVisit,
			},
			{
				name: 'Seitenansichten',
				value: formatNum(matomoData.pageViews),
			},
		];
		tmVisits.setRowData(tmData);
	}, [adminUsageUiStore, adminUsageUiStore.matomoWeekData, tmVisits]);

	return (
		<>
			<div className="pad-1rem">
				<Widget title="Allgemeine Nutzung" modifier="is-paddingless" action={<span className="tag is-blue">Matomo</span>}>
					<Table tm={tmVisits} />
				</Widget>
			</div>
		</>
	);
});
