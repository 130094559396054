import { Button } from 'app/components/common/Button';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router';
import routes from 'routes';

export const AdminNewTemplateButtons = observer(() => {
	const history = useHistory();
	const open = () => {
		history.push(routes.ADMINTEMPLATES.getPath('new', '0'));
	};
	return (
		<>
			<Button type="button" className="button is-primary" onClick={open}>
				Neue Vorlage
			</Button>
		</>
	);
});
