import { BESTANDPOS_ID } from 'app/stores/ui/agt.bestand.ui.store';
import { computed } from 'mobx';
import moment from 'moment';
import { AgenturModel } from '../agentur.model';
import { AgtBestandPosModel } from './agt.bestand.pos.model';

// aka 1 Year history
// daten kommen aus pos_agt_ms
// monats daten sind hier kummulativ
export class AgtBestandHistoryVM {
	constructor(agt: AgenturModel, bm: any) {
		this.agtId = agt.agtId;
		this.agt = agt;
		this.bm = bm;
		this.bm = moment(bm + '01').toDate();
		this.bewertung = new AgtBestandPosModel({
			agt: this.agt,
			title: 'Bewertung', // kumulativ über das jahr
			posId: 32,
		});
		this.production = new AgtBestandPosModel({
			agt: this.agt,
			title: 'Production aka bewertung pro monat. wird berechner',
			posId: 888,
		});
		this.bestand = new AgtBestandPosModel({
			agt: this.agt,
			title: 'Sach gesamt (ohne AGCS/mit ESA)',
			posId: BESTANDPOS_ID,
			zuwachsPosId: 33,
		});
	}

	agtId: number;
	agt: AgenturModel;
	bewertung: AgtBestandPosModel;
	production: AgtBestandPosModel;
	bestand: AgtBestandPosModel;

	@computed
	get bmLabel() {
		return moment(this.bm).format('YYYY-MM');
	}

	bm: Date;

	@computed
	get year() {
		return moment(this.bm).year();
	}

	@computed
	get month() {
		return moment(this.bm).month();
	}
}
