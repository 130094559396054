const data = [
	{ provId: 1, name: 'Altes Provisionssystem 12 Monate Haftzeit         ' },
	{ provId: 2, name: 'Ausgleichsmodell 24 Monate Haftzeit               ' },
	{ provId: 3, name: 'Umstiegsmodell 36 Monate Haftzeit                 ' },
	{ provId: 4, name: 'Strategiemodell 36 Monate Haftzeit                ' },
	{ provId: 5, name: 'neue Formate 36 Monate Haftzeit                   ' },
	{ provId: 6, name: 'Pro3-Vergütungsmodell 36 Monate Haftzeit          ' },
];

export class ProvSysModel {
	constructor(opts: any) {
		this.provId = opts.provId;
		this.name = opts.name;
	}
	provId: number;
	name: string;
}

export const ProvSysModels = data.map((d) => new ProvSysModel(d));
