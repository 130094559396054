import { AgenturNameCell } from 'app/components/shared/AgenturName.cell';
import { Table } from 'app/components/table/table';
import { ITableColumn, TableModel, TableRow } from 'app/components/table/table.model';
import { useStore } from 'app/context';
import { AgtDigiVM, DigiFaktorSchema, IDigiFaktorSchema } from 'app/stores/ui/agt.digifaktor.ui.store';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Bar, BarChart, ReferenceLine, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from 'recharts';
import { AgtDigfaktorChart } from './AgtDigifaktorChart';
import { AgtDigfaktor } from './AgtDigifkator';
import colors from 'assets/scss/colors.module.scss';
import { EChartsOption, ECharts, init } from 'echarts';
import _, { debounce } from 'lodash';

export const DigifaktorList = observer(() => {
	return <div className="pad-1rem">Neue Daten vsl. 2024</div>;

	/*
	const { agtDigigFaktorStore: kvDigigFaktorStore, berichteUiiStore, agenturListUiStore } = useStore();
	const [chartKey, setChartKey] = useState('charty');
	const [dfSchema, setDfSchema] = useState<any>([]);
	const [avg, setAvg] = useState<number>(0);

	const tm = useMemo(() => {
		const tm = new TableModel<AgtDigiVM, number>();
		const cols: ITableColumn<AgtDigiVM, number>[] = [
			{
				label: 'Agentur',
				path: 'agt.name',
				sortBy: 'agt.name',
				render: AgenturNameCell,
			},
		];
		DigiFaktorSchema.forEach((d) => {
			if (d.path === 'DF' || d.path === berichteUiiStore.menu.current?.currentPath || berichteUiiStore.menu.current?.currentPath === 'DF') {
				cols.push({
					label: d.label,
					path: d.path,
				});
			}
		});
		tm.sortBy = 'agt.name';
		tm.setCols(cols);
		tm.onSort = () => {
			setChartKey('chart' + new Date().getTime());
		};
		tm.idProperty = 'agtId';
		tm.idType = 'number';
		return tm;
	}, [berichteUiiStore.menu]);

	useEffect(() => {
		const _dfSchema = DigiFaktorSchema.find((f) => f.path === berichteUiiStore.menu.current?.currentPath);
		setDfSchema(_dfSchema);
		if (_dfSchema) {
			const _avg = kvDigigFaktorStore.getAvgByPath(_dfSchema!.path);
			setAvg(_avg);
		}

		kvDigigFaktorStore.findAllCurrent().then((res) => {
			res = agenturListUiStore.applyFilterOnAgt(res) as AgtDigiVM[];

			if (berichteUiiStore.selectedAgt) {
				const agtId = berichteUiiStore.selectedAgt.agtId;
				res = res.filter((d) => d.agtId === agtId);
			}
			tm.setRowData(res);
			tm.sort(); // important -> otherwise the charts labels will not match the rows.
		});
	}, [tm, kvDigigFaktorStore, berichteUiiStore.selectedAgt, berichteUiiStore.menu, dfSchema, agenturListUiStore.filterHasChanged, agenturListUiStore]);

	const chartHeight = 30 + (tm.data.length + 1) * 30;

	if (tm.data.length === 0) {
		return <></>;
	}

	return (
		<>
			<div className="table-container">
				{berichteUiiStore.selectedAgt && (
					<>
						<AgtDigfaktorChart />
						<AgtDigfaktor />
					</>
				)}

				{!berichteUiiStore.selectedAgt && (
					<>
						<div className="table-container">
							<div className="pad-1-rem">
								<DigifaktorBarChart rowData={tm.rows} dfSchema={dfSchema} />
							</div>

							<div className="pad-1rem">
								<ResponsiveContainer width="100%" height={chartHeight} debounce={300}>
									<BarChart data={tm.rows} layout="vertical" barSize={15} key={chartKey} margin={{ top: 30, bottom: 30, left: 30, right: 50 }}>
										<XAxis type="number" domain={dfSchema!.domain} />
										<YAxis type="category" width={200} dataKey="data.agt.name" />
										<ReferenceLine x={avg} stroke={colors.grey} label={{ value: 'Durchschnitt: ' + avg, position: 'insideTopRight', offset: -15 }} />
										<Tooltip content={<DfTooltip />} />
										<Bar dataKey={'data.' + dfSchema!.path} fill={colors.turquoise} />
									</BarChart>
								</ResponsiveContainer>
							</div>
							<Table stickyHeader={true} tm={tm}></Table>
						</div>
					</>
				)}
			</div>
		</>
	);
	*/
});

const DfTooltip = (props: TooltipProps<any, any>) => {
	const { active, payload } = props;
	if (active && payload && payload.length) {
		const row = payload[0].payload as TableRow<AgtDigiVM>;
		const data = row.data;

		return (
			<div className="custom-tooltip">
				<p className="label">{`${data.agt.name} (${data.agt.agtId})`}</p>
			</div>
		);
	}
	return null;
};

interface IDigifaktorBarChart {
	rowData: TableRow<AgtDigiVM>[];
	dfSchema: IDigiFaktorSchema;
}

const DigifaktorBarChart = observer((props: IDigifaktorBarChart) => {
	const { rowData, dfSchema } = props;
	const chartRef = useRef<HTMLDivElement>(null);
	const chartHeight = 30 + (rowData.length + 1) * 30;

	// TODO: Add average line

	useEffect(() => {
		const dim = ['name', dfSchema.path];
		const seriesOptions: echarts.SeriesOption = {
			type: 'bar',
			stack: 'a',
			label: {
				show: true,
				formatter: (params) => {
					// @ts-ignore
					return `${params.value[params.encode.x[0]]}`;
				},
			},
			emphasis: {
				focus: 'series',
			},
		};

		const seriesData = {
			...seriesOptions,
			name: dfSchema.label,
			color: colors.turquoise,
		};

		if (rowData.length === 0) return;
		const sortedRowData = _.sortBy(rowData, (r) => r.data.agt.name).reverse();
		const dataSetSource = sortedRowData.map((agtData) => {
			const name = agtData.data.agt.name;
			const valsArr = DigiFaktorSchema.map((v) => (agtData.data as any)[v.path]);
			return [name, ...valsArr];
		});

		let option: EChartsOption = {
			tooltip: {
				trigger: 'axis',
				valueFormatter: (value) => `${value}%`,
			},
			xAxis: {
				type: 'value', // will type 'value' make average markLine work?
				axisLabel: {
					fontSize: 14,
					// formatter: '{value}%',
				},
			},
			yAxis: {
				type: 'category',
				axisLabel: {
					fontSize: 14,
					padding: [0, 5, 0, 0],
				},
			},
			dataset: {
				dimensions: dim,
				source: dataSetSource,
			},
			grid: {
				containLabel: true,
			},
			series: seriesData,
		};
		let chart: ECharts | null = null;
		if (chartRef.current !== null) {
			chart = init(chartRef.current, null, { renderer: 'svg' });
			chart.setOption(option);
		}
		const handleResize = debounce(() => {
			chart?.resize();
		}, 300);
		window.addEventListener('resize', handleResize);

		return () => {
			chart?.dispose();
			window.removeEventListener('resize', handleResize.cancel);
		};
	}, [dfSchema.label, dfSchema.path, rowData]);

	return (
		<>
			<div style={{ width: '100%', height: chartHeight }} ref={chartRef}></div>
		</>
	);
});

const DigifaktorStackedChart = observer((props: IDigifaktorBarChart) => {
	const { rowData } = props;
	const chartRef = useRef<HTMLDivElement>(null);
	const chartHeight = 30 + (rowData.length + 1) * 30;

	useEffect(() => {
		const dim = ['name', ...DigiFaktorSchema.map((s) => s.path)];
		const legendData = DigiFaktorSchema.map((s) => ({ name: s.label }));
		const seriesOptions: echarts.SeriesOption = {
			type: 'bar',
			stack: 'a',
			label: {
				show: true,
				formatter: (params) => {
					// @ts-ignore
					return `${params.value[params.encode.x[0]]}`;
				},
			},
			emphasis: {
				focus: 'series',
			},
		};

		const seriesData = DigiFaktorSchema.map((s) => {
			return {
				...seriesOptions,
				name: s.label,
				// color: s.color,
			};
		});
		console.log('TMMMM DATA', rowData);
		if (rowData.length === 0) return;
		const dataSetSource = rowData.map((agtData) => {
			const name = agtData.data.agt.name;
			const valsArr = DigiFaktorSchema.map((v) => (agtData.data as any)[v.path]);
			return [name, ...valsArr];
		});

		let option: EChartsOption = {
			tooltip: {
				trigger: 'axis',
				valueFormatter: (value) => `${value}%`,
			},
			xAxis: {
				axisLabel: {
					fontSize: 14,
					formatter: '{value}%',
				},
			},
			yAxis: {
				type: 'category',
				axisLabel: {
					fontSize: 14,
					padding: [0, 5, 0, 0],
				},
			},
			dataset: {
				dimensions: dim,
				source: dataSetSource,
			},
			legend: {
				bottom: 10,
				data: legendData,
			},
			grid: {
				containLabel: true,
			},
			series: seriesData,
		};
		let chart: ECharts | null = null;
		if (chartRef.current !== null) {
			chart = init(chartRef.current);
			chart.setOption(option);
		}
		return () => {
			chart?.dispose();
		};
	}, []);

	return (
		<>
			<div style={{ width: '100%', height: chartHeight }} ref={chartRef}></div>
		</>
	);
});
