import { BaseCachedEntityStore } from './core/base.cached.entity.store';

export class BnrAgenturModel {
	constructor(opts: any) {
		this.bnrId = opts.bnrId;
		this.agtId = opts.agtId;
	}
	bnrId: number;
	agtId: number;
}

export class BnrAgenturStore extends BaseCachedEntityStore<BnrAgenturModel> {
	apiPath = 'api/vbl/agentur';
	idProp: string = 'id';
	createInstances(opts: any): BnrAgenturModel {
		return new BnrAgenturModel(opts);
	}
}
