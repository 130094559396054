import { useStore } from 'app/context';
import { Field, Form, Formik } from 'formik';
import { observer } from 'mobx-react';
import * as Yup from 'yup';
import FormField from 'app/components/form/FormField';
import { Button } from 'app/components/common/Button';
import { useHistory } from 'react-router';
import { SteartMultiSelectField } from 'app/components/form/SteartMultiSelectField';
import { notify } from 'app/components/common/notify';
import routes from 'routes';
import { runInAction } from 'mobx';
import { TextEditorField } from 'app/components/form/TextEditorField';
import moment from 'moment';
import { NewsVM } from 'app/stores/ui/news.ui.store';
import { DatePickerField } from 'app/components/form/DatePickerField';

const adminNewsEditSchema = Yup.object({
	title: Yup.string().required(),
	authors: Yup.string(),
	text: Yup.string().required(),
	publishDate: Yup.date(),
	publishForRoles: Yup.array(),
}).required();

type AdminNewsEdit = Yup.InferType<typeof adminNewsEditSchema>;

interface IAdminNewsEditForm {
	news: NewsVM;
	onClose?: () => void;
}
export const AdminNewsEditForm = observer((props: IAdminNewsEditForm) => {
	const { adminNewsUiStore } = useStore();
	const history = useHistory();
	const model = props.news.news;

	const handleSubmit = async (values: AdminNewsEdit) => {
		model.title = values.title;
		model.text = values.text ? values.text : '';
		model.publishDate = values.publishDate!;
		model.publishForRoles = values.publishForRoles ? values.publishForRoles : [];
		model.authors = values.authors ? values.authors : '';

		await adminNewsUiStore.save(props.news);
		runInAction(() => {
			adminNewsUiStore.current = undefined;
		});
		notify('News wurde gespeichret', '', 'info');
		onClose();
	};

	const onClose = () => {
		adminNewsUiStore.current = undefined;
		history.push(routes.ADMINNEWS.getPath());
		if (props.onClose) {
			props.onClose();
		}
	};

	return (
		<>
			<Formik
				validationSchema={adminNewsEditSchema}
				initialValues={{
					title: model.title,
					text: model.text,
					authors: model.authors,
					publishDate: model.publishDate,
					publishForRoles: model.publishForRoles,
				}}
				onSubmit={handleSubmit}
			>
				{({ errors, touched, isValid, values }) => {
					return (
						<Form>
							<div className="pad-1rem">
								<FormField className="is-inline" error={errors.title} touched={touched.title} label="Titel">
									<Field type="text" name="title" placeholder="Titel eingeben" />
								</FormField>
								<FormField className="is-inline" error={errors.text} touched={touched.text} label="Text (HTML)">
									<Field className="input" type="text" as="textarea" name={'text'} placeholder="Text eingeben" />
								</FormField>


								<FormField className="is-inline" label="Sichtbar ab">
									<DatePickerField name="publishDate" />
								</FormField>

								{/* <FormField className="is-inline" error={errors.authors} touched={touched.authors} label="Autoren">
                                    <Field className="input" type="text" as="textarea" name={'authors'} placeholder="Autoren eingeben" />
                                </FormField> */}

								<FormField className="is-inline" label="Freigegeben für">
									<SteartMultiSelectField name="publishForRoles" />
								</FormField>
								{/* {Object.values(errors).map((msg) => (
									<>
										<div className="error">{msg)}</div>
										<br />
									</>
								))} */}
							</div>

							<div className="pad-1rem grid  border-top">
								<div className="is-right">
									<Button type="button" className="button is-secondary" onClick={onClose}>
										Abbrechen
									</Button>
									<Button type="submit" className="button is-primary " disabled={!isValid}>
										Speichern
									</Button>
								</div>
							</div>
						</Form>
					);
				}}
			</Formik>
			Aktuelle KW: {moment().format('YYYYww')}
		</>
	);
});
