import { BnrGpPosModel } from '../gp/bnr.gp.pos.model';
import { computed, makeObservable } from 'mobx';
import { BnrZielModel } from 'app/stores/bnr.ziel.store';

interface IBnrPosModel {
	posDef: BnrGpPosModel;
	posVal?: BnrZielModel;
	eigenesZiel: number;
	boniStufe?: number;
	boniStufenZiele?: number[];
}

export class BnrProdPosModel {
	constructor(opts: IBnrPosModel) {
		makeObservable(this);
		this.posDef = opts.posDef;
		this.posVal = opts.posVal;
		if (this.posDef.calcFunc && this.posVal) {
			this.posVal = this.posVal.clone();
			this.posVal.ziel = this.posDef.calcFunc(this.posVal.ziel);
			// nur das ziel sollte im moment angepasst werden :(
			// this.posVal.ergebnis = this.posDef.calcFunc(this.posVal.ergebnis);
		}

		this.eigenesZiel = opts.eigenesZiel;
		this.boniStufe = opts.boniStufe;
		this.boniStufenZiele = opts.boniStufenZiele;
	}
	posDef: BnrGpPosModel;
	posVal?: BnrZielModel;
	fahrstuhl?: string;
	boniStufe?: number;
	boniStufenZiele?: number[];
	eigenesZiel?: number;

	getLimited(z: number) {
		if (z < 40) {
			z = 40;
		}
		if (z > 180) {
			z = 180;
		}
		return z;
	}

	@computed
	get zeZeitLimited() {
		let z = 0;
		if (this.posVal) {
			z = this.posVal.zeZeit;
		}
		return this.getLimited(z);
	}

	@computed
	get zeJahrLimited() {
		let z = 0;
		if (this.posVal) {
			z = this.posVal.ze;
		}
		return this.getLimited(z);
	}
}
