import { makeObservable, observable } from 'mobx';
import { BaseEntityStore } from './core/base.entity.store';

export class BnrTransferModel {
	constructor(opts: any) {
		makeObservable(this);
		this.adminComment = opts.adminComment;
		this.anonHash = opts.anonHash;
		this.created = opts.created;
		this.id = opts.id;
		this.modified = opts.modified;
		this.sourceBnrId = opts.sourceBnrId;
		this.sourceComment = opts.sourceComment;
		this.sourceEmail = opts.sourceEmail;
		this.sourceHasApproved = opts.sourceHasApproved;
		this.sourceHasLeft = opts.sourceHasLeft;
		this.sourceName = opts.sourceName;
		this.sourceSteart = opts.sourceSteart;
		this.state = opts.state;
		this.targetBnrId = opts.targetBnrId;
		this.targetComment = opts.targetComment;
		this.transferBranchenplanung = opts.transferBranchenplanung;
		this.transferDate = opts.transferDate;
		this.transferDoks = opts.transferDoks;
		this.transferEigenePlanung = opts.transferEigenePlanung;
		this.transferResult = opts.transferResult;
		this.transferTasks = opts.transferTasks;
	}
	@observable adminComment: string;
	@observable anonHash: string;
	@observable created: Date;
	@observable id: string;
	@observable modified: Date;
	@observable sourceBnrId: number;
	@observable sourceComment: string;
	@observable sourceEmail: string;
	@observable sourceHasApproved: boolean;
	@observable sourceHasLeft: boolean;
	@observable sourceName: string;
	@observable sourceSteart: number;
	@observable state: string;
	@observable targetBnrId: number;
	@observable targetComment: string;
	@observable transferBranchenplanung: boolean;
	@observable transferDate?: Date;
	@observable transferDoks: boolean;
	@observable transferEigenePlanung: boolean;
	@observable transferResult: any;
	@observable transferTasks: boolean;

	serialize() {
		return {
			id: this.id,
			sourceBnrId: this.sourceBnrId,
			targetBnrId: this.targetBnrId,
			state: this.state,
			sourceName: this.sourceName,
			sourceEmail: this.sourceEmail,
			sourceSteart: this.sourceSteart,
			sourceHasLeft: this.sourceHasLeft,
			sourceHasApproved: this.sourceHasApproved,
			sourceComment: this.sourceComment,
			targetComment: this.targetComment,
			adminComment: this.adminComment,
			anonHash: this.anonHash,
			transferDoks: this.transferDoks,
			transferTasks: this.transferTasks,
			transferBranchenplanung: this.transferBranchenplanung,
			transferEigenePlanung: this.transferEigenePlanung,
		};
	}
}

export class AdminBnrTransferStore extends BaseEntityStore<BnrTransferModel> {
	apiPath = 'api/admin/bnrtransfer';
	idProp: string = 'id';
	createInstances(opts: any): BnrTransferModel {
		return new BnrTransferModel(opts);
	}

	async check(sourceBnrId: number, targetBnrId: number) {
		await this.callRefreshTokenIfNecessary();
		const head = this.getAuthHeader();
		const res = await this.axios({
			method: 'GET',
			headers: head.headers,
			url: this.apiPath + '/check?sourceBnrId=' + sourceBnrId + '&targetBnrId=' + targetBnrId,
		});
		return res.data;
	}

	async transfer(id: string) {
		await this.callRefreshTokenIfNecessary();
		const head = this.getAuthHeader();
		const res = await this.axios({
			method: 'GET',
			headers: head.headers,
			url: this.apiPath + '/execTransfer?id=' + id,
		});
		return res.data;
	}
}
