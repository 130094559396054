import { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStore } from 'app/context';
import { ISankeyData, SankeyChart } from 'app/components/charts/SankeyChart';
import colors from 'assets/scss/colors.module.scss';
import { AgtAkumaPlanerVM } from 'app/stores/ui/agt.akumaplaner.ui.store';
import { EChartsOption } from 'echarts';
import _ from 'lodash';
import { Button } from 'app/components/common/Button';
import { Modal } from 'app/components/Modal';
import { AkumaPlanerLegend } from './AkumaPlanerLegend';
import { Icon } from 'app/components/common/Icon';

interface IAkumaPlanerSankeyChartType {}

interface IAkumaPlanerSankeyData extends ISankeyData {
	paths?: string[];
}

interface IKWData {
	gesamt: number;
	gpWochen: number;
	zu: number;
	km: number;
	nu: number;
	zagt: number;
	zma: number;
	nb?: number;
}

const planerSankeySchema: IAkumaPlanerSankeyData[] = [
	{ source: 'Kunden mit Zuweisung und übertragen', target: 'Zuweisung nur Agentur', targetColor: colors.darkYellow, paths: ['zagt'] },
	{ source: 'Kunden mit Zuweisung und übertragen', target: 'Zuweisung nur Mitarbeiter', targetColor: colors.purple, paths: ['zma'] },
	{ source: 'Zuweisung', target: 'Kunden mit Zuweisung und übertragen', targetColor: colors.green, paths: ['zu'] },

	{ source: 'Zuweisung', target: 'Kunden mit Zuweisung aber nicht übertragen', targetColor: colors.blue, paths: ['nu'] },
	{ source: 'Zuweisung', target: 'Keine Maßnahme', targetColor: colors.greyLight, paths: ['km'] },

	{ source: 'Gesamt', target: 'Zuweisung', sourceColor: colors.turquoise, targetColor: colors.richBlue, paths: ['km', 'nu', 'zagt', 'zma'] },
	{ source: 'Gesamt', target: 'Nicht bearbeitet', sourceColor: colors.turquoise, targetColor: colors.red, paths: ['nb'] },
];

function getChartData(schema: IAkumaPlanerSankeyData[], data: any): ISankeyData[] {
	const ret: ISankeyData[] = [];

	// iterate through sankey schema nodes
	schema.forEach((node) => {
		node.value = 0;

		node.paths?.forEach((path) => {
			node.value += data[path];
		});

		ret.push({ ...node });
	});

	return ret;
}

export const AkumaPlanerSankeyChart = observer((props: IAkumaPlanerSankeyChartType) => {
	const { agtAkumaPlanerUiStore, berichteUiiStore, agenturListUiStore, uiStore } = useStore();

	const [chartData, setChartData] = useState<ISankeyData[]>();
	const [itemsTotal, setItemsTotal] = useState<number>(0);

	const [showNichtBearbeitet, setNichtBearbeitet] = useState(false);
	const toggleNichtBearbeitete = () => {
		setNichtBearbeitet(!showNichtBearbeitet);
	};

	useEffect(() => {
		let kwdata = agtAkumaPlanerUiStore.currentKWItems;
		if (berichteUiiStore.selectedAgt) {
			const agtId = berichteUiiStore.selectedAgt.agtId;
			kwdata = agtAkumaPlanerUiStore.filterByAgtIdandKw(agtId);
			kwdata = _.sortBy(kwdata, 'bm').reverse();
		} else {
			kwdata = agenturListUiStore.applyFilterOnAgt(kwdata) as AgtAkumaPlanerVM[];
		}

		let data: IKWData = {
			gesamt: 0,
			gpWochen: 0,
			zu: 0,
			km: 0,
			nu: 0,
			zagt: 0,
			zma: 0,
			nb: 0,
		};

		kwdata.forEach((i) => {
			data.gesamt += i.gesamt;
			data.gpWochen += i.gpWochen;
			data.zu += i.zu;
			data.km += i.km;
			data.nu += i.nu;
			data.zagt += i.zagt;
			data.zma += i.zma;
			data.nb! += i.nb;
		});

		let schema = planerSankeySchema;
		if (!showNichtBearbeitet) {
			schema = schema.filter((s) => s.target !== 'Nicht bearbeitet');
		}

		const cdata = getChartData(schema, data);
		setChartData(cdata);
		setItemsTotal(data.gesamt);
	}, [
		agenturListUiStore.filterHasChanged,
		agenturListUiStore,
		agenturListUiStore.items,
		agtAkumaPlanerUiStore,
		agtAkumaPlanerUiStore.currentKWItems,
		berichteUiiStore.selectedAgt,
		showNichtBearbeitet,
	]);

	const labelFormat = (params: any) => {
		if (itemsTotal === 0) {
			return ``;
		}
		const perc: string = itemsTotal > 0 ? `{italic|(${Math.round((params.value / itemsTotal) * 100)}%)}` : '';
		return `{bold|${params.name}} ${params.value} ${perc}`;
	};

	const opts: EChartsOption = {
		series: {
			right: 300,
			label: {
				overflow: 'break',
			},
			labelLayout: {
				width: 220,
			},
		},
	};

	const openModal = () => {
		uiStore.showModal(uiStore.modalIds.akumaLegend);
	};
	const closeModal = () => {
		uiStore.hideModal(uiStore.modalIds.akumaLegend);
	};

	return (
		<>
			{chartData && (
				<>
					<SankeyChart data={chartData} labelFormatter={labelFormat} opts={opts} />
					<div>
						<Button className="button is-primary is-small is-inverted" onClick={openModal}>
							<Icon iconClass="info-circle" />
							<span>Legende</span>
						</Button>
						<Button
							className="button is-primary is-small is-inverted"
							onClick={() => {
								toggleNichtBearbeitete();
							}}
						>
							{showNichtBearbeitet ? 'Nicht bearbeitete Kunden ausblenden' : 'Nicht bearbeitete Kunden einblenden'}
						</Button>
					</div>
					<Modal modalId={uiStore.modalIds.akumaLegend} title={'Erläuterung der Dargestellten Kennzahlen'} onClose={closeModal}>
						<AkumaPlanerLegend />
					</Modal>
				</>
			)}
		</>
	);
});
