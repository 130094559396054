import React from 'react';
import BaseContainer from 'app/containers/BaseContainer';
import { observer } from 'mobx-react';
import { MainMenu } from './MainMenu';
import { RouteComponentProps } from 'react-router-dom';
import { SettingsForm } from 'app/components/SettingsForm';

const SettingsContainer = observer((props: RouteComponentProps) => {
	return (
		<BaseContainer sideMenu={<MainMenu {...props} />} navbar={<div>Einstellungen</div>}>
			<SettingsForm />
		</BaseContainer>
	);
});

export default SettingsContainer;
