import _ from 'lodash';
import { KvAgtEigenePlanung } from 'app/stores/kv.agt.eigene.planung.store';
import { AgtGpPosModel } from '../gp/agt.gp.pos.model';
import { AgtZielModel } from 'app/stores/agt.ziel.store';
import { AgenturModel } from '../agentur.model';
import { computed, makeObservable, observable } from 'mobx';

interface IAgtPosModel {
	agt: AgenturModel;
	posDef: AgtGpPosModel;
	posVal?: AgtZielModel;
	eigenerVal?: KvAgtEigenePlanung;
	boniStufe?: number;
	boniStufenZiele?: number[];
}

export class AgtProdPosVM {
	constructor(opts: IAgtPosModel) {
		makeObservable(this);
		this.agt = opts.agt;
		this.agtId = opts.agt.agtId;
		this.posDef = opts.posDef;
		this.posVal = opts.posVal;
		this.eigenerVal = opts.eigenerVal;
		this.boniStufe = opts.boniStufe;
		this.boniStufenZiele = opts.boniStufenZiele;
	}
	agtId: number;
	agt: AgenturModel;
	posDef: AgtGpPosModel;
	posVal?: AgtZielModel;
	fahrstuhl?: string;
	boniStufe?: number;
	boniStufenZiele?: number[];

	@observable
	eigenerVal?: KvAgtEigenePlanung;

	@computed
	get valueOrZer0() {
		if (this.eigenerVal && this.eigenerVal.valueNum) {
			if (_.isNumber(this.eigenerVal.valueNum)) {
				return this.eigenerVal.valueNum;
			}
		}
		return 0;
	}

	getLimited(z: number) {
		if (z < 40) {
			z = 40;
		}
		if (z > 180) {
			z = 180;
		}
		return z;
	}

	@computed
	get zeZeitLimited() {
		let z = 0;
		if (this.posVal) {
			z = this.posVal.zeZeit;
		}
		return this.getLimited(z);
	}

	@computed
	get zeJahrLimited() {
		let z = 0;
		if (this.posVal) {
			z = this.posVal.ze;
		}
		return this.getLimited(z);
	}
}
