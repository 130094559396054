import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { InputControl } from '../common/InputControl';
import { runInAction } from 'mobx';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { CheckboxControl } from '../common/CheckboxControl';
import { Button } from '../common/Button';
import { Icon } from '../common/Icon';
import FocusTrap from 'focus-trap-react';

interface IDocumentFilter {
	agtId?: number;
	planYear?: number;
	bnrGpYear?: number;
	bnrId?: number;
	isBnrGp?: boolean;
	gpBnrId?: number;
	hideForm?: boolean;
}

export const DocumentFilter = observer((props: IDocumentFilter) => {
	const { documentUiStore } = useStore();
	const [showOptions, setShowOptions] = useState(false);

	useEffect(() => {
		runInAction(() => {
			documentUiStore.currentFilter.agtId = props.agtId;
			documentUiStore.currentFilter.planYear = props.planYear;
			documentUiStore.currentFilter.bnrId = props.bnrId;
			documentUiStore.currentFilter.gpBnrId = props.gpBnrId;
			documentUiStore.currentFilter.isBnrGp = props.isBnrGp === true;
			documentUiStore.currentFilter.bnrGpYear = props.bnrGpYear;
		});
	}, [documentUiStore.currentFilter, props.agtId, props.planYear, props.bnrId]);

	const onTextChange = (name: string, val: string) => {
		runInAction(() => {
			documentUiStore.currentFilter.fullText = val;
		});
	};

	const onOptionChange = (name: string, val: boolean) => {
		runInAction(() => {
			_.set(documentUiStore.currentFilter, name, val);
			documentUiStore.currentFilter.saveToLocalStorage();
		});
	};

	const removeOption = (name: string) => {
		runInAction(() => {
			_.set(documentUiStore.currentFilter, name, false);
			documentUiStore.currentFilter.saveToLocalStorage();
		});
	};

	const resetFilterScoped = () => {
		documentUiStore.currentFilter.resetScopedNotLocalStorage();
		documentUiStore.currentFilter.saveToLocalStorage();
	};
	return (
		<div className="documents-filter">
			{!props.hideForm && (
				<>
					{/* {documentUiStore.currentFilter.agtId} {'- '} {documentUiStore.currentFilter.planYear} */}
					<div className="text-input">
						<InputControl name="fullText" value={documentUiStore.currentFilter.fullText} onChange={onTextChange} debounceTime={500} placeholder={'Textsuche'} />
					</div>
					<div className="toggle">
						<Button className="button" onClick={() => setShowOptions(true)}>
							Filter öffnen
						</Button>
					</div>
					<div className="filter-count">{documentUiStore.currentItems.length} Dokumente</div>
					{!showOptions && (
						<div className="active-filters">
							{documentUiStore.currentFilter.isJpg && (
								<Button className="button is-small is-active-filter-button" onClick={() => removeOption('isJpg')}>
									<Icon iconClass="times" />
									<span>Jahresgepräch</span>
								</Button>
							)}
							{documentUiStore.currentFilter.isAp && (
								<Button className="button is-small is-active-filter-button" onClick={() => removeOption('isAp')}>
									<Icon iconClass="times" />
									<span>Planungstool</span>
								</Button>
							)}
							{documentUiStore.currentFilter.onlyUnread && (
								<Button className="button is-small is-active-filter-button" onClick={() => removeOption('onlyUnread')}>
									<Icon iconClass="times" />
									<span>Ungelesene Dokumente</span>
								</Button>
							)}
							{documentUiStore.currentFilter.onlySharedForMe && (
								<Button className="button is-small is-active-filter-button" onClick={() => removeOption('onlySharedForMe')}>
									<Icon iconClass="times" />
									<span>FÜR mich geteilte Dokumente</span>
								</Button>
							)}
							{documentUiStore.currentFilter.onlySharedByMe && (
								<Button className="button is-small is-active-filter-button" onClick={() => removeOption('onlySharedByMe')}>
									<Icon iconClass="times" />
									<span>VON mir geteilte Dokumente</span>
								</Button>
							)}
							{documentUiStore.currentFilter.notShared && (
								<Button className="button is-small is-active-filter-button" onClick={() => removeOption('notShared')}>
									<Icon iconClass="times" />
									<span>Nicht geteilte Dokumente</span>
								</Button>
							)}
							{documentUiStore.currentFilter.withUploads && (
								<Button className="button is-small is-active-filter-button" onClick={() => removeOption('withUploads')}>
									<Icon iconClass="times" />
									<span>Mit Anhang / Dateien</span>
								</Button>
							)}
						</div>
					)}
					{showOptions && (
						<>
							<div className="generic-overlay"></div>
							<FocusTrap>
								<div className="filter-options-list">
									<CheckboxControl alignment="left" name="isJpg" value={documentUiStore.currentFilter.isJpg} onChange={onOptionChange} label={'Jahresgespräch'} />
									<CheckboxControl alignment="left" name="isAp" value={documentUiStore.currentFilter.isAp} onChange={onOptionChange} label={'Planungstool'} />
									<CheckboxControl
										alignment="left"
										name="onlyUnread"
										value={documentUiStore.currentFilter.onlyUnread}
										onChange={onOptionChange}
										label={'Ungelesene Dokumente'}
									/>
									<CheckboxControl
										alignment="left"
										name="onlySharedForMe"
										value={documentUiStore.currentFilter.onlySharedForMe}
										onChange={onOptionChange}
										label={'FÜR mich geteilte Dokumente'}
									/>
									<CheckboxControl
										alignment="left"
										name="onlySharedByMe"
										value={documentUiStore.currentFilter.onlySharedByMe}
										onChange={onOptionChange}
										label={'VON mir geteilte Dokumente'}
									/>
									<CheckboxControl
										alignment="left"
										name="notShared"
										value={documentUiStore.currentFilter.notShared}
										onChange={onOptionChange}
										label={'Nicht geteilte Dokumente'}
									/>
									<CheckboxControl
										alignment="left"
										name="withUploads"
										value={documentUiStore.currentFilter.withUploads}
										onChange={onOptionChange}
										label={'Mit Anhang / Dateien'}
									/>
									<Button className="button is-icon close-filters" onClick={() => setShowOptions(false)}>
										<Icon iconClass="times" />
									</Button>

									<div className="footer">
										<Button className="button is-primary" onClick={() => setShowOptions(false)}>
											Fertig
										</Button>
										<Button className="button is-inverted" onClick={resetFilterScoped}>
											Filter zurücksetzen
										</Button>
									</div>
								</div>
							</FocusTrap>
						</>
					)}
				</>
			)}
		</div>
	);
});
