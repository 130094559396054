import BaseContainer from 'app/containers/BaseContainer';
import { observer } from 'mobx-react';
import { MainMenu } from './MainMenu';
import { RouteComponentProps } from 'react-router-dom';
import { useStore } from 'app/context';
import { NotizenList } from 'app/components/documents/NotizenList';
import { NotizViewerModal } from 'app/components/documents/NotizViewerModal';
import { Button } from 'app/components/common/Button';

const NotizenContainer = observer((props: RouteComponentProps) => {
	const { notizenUiStore, uiStore } = useStore();

	const addDok = async () => {
		const vm = await notizenUiStore.addNotiz();
		notizenUiStore.setCurrentModalDoc(vm);
		uiStore.showModal(uiStore.modalIds.notizViewModal);
	};
	return (
		<BaseContainer sideMenu={<MainMenu {...props} />} navbar={<div>Notizen</div>}>
			<div className="pad-1rem is-right">
				<Button className="button is-primary is-inverted" onClick={addDok}>
					Neue Notiz anlegen
				</Button>
			</div>
			<NotizenList />
			<NotizViewerModal />
		</BaseContainer>
	);
});

export default NotizenContainer;
