import { ErrungenschaftState, IErrungenschaftModal } from 'app/models/errungenshaften/errungenschaften.model';
import { observer } from 'mobx-react';
import { Icon } from '../common/Icon';
import { ErrungenschaftIcon } from './ErrungenshaftIcon';

export interface IAgtErrungenschaftGSB {
	key: string;
	state: ErrungenschaftState;
	modal: IErrungenschaftModal;

	icon: string;
	valType: string;
	gsbLevels: number[];
	gsbVal: number;
}

export const ErrungenschaftGSB = observer((props: IAgtErrungenschaftGSB) => {
	return (
		<>
			<ErrungenschaftIcon {...props} />
			<div className="debug">
				{props.key}
				{props.state !== ErrungenschaftState.off && <Icon iconClass={props.icon} />}
				{props.modal.title} ({props.state})<strong> {props.gsbVal} </strong> {props.gsbLevels[0]} / {props.gsbLevels[1]}/ {props.gsbLevels[2]}
			</div>
		</>
	);
});
