import BaseContainer from 'app/containers/BaseContainer';
import { observer } from 'mobx-react';
import { MainMenu } from './MainMenu';
import { RouteComponentProps } from 'react-router-dom';
import { GsvbList } from 'app/components/admin/gsvb/GsvbList';
import { EnsureAdmin } from './AdminHomeContainer';

const AdminGsvbContainer = observer((props: RouteComponentProps) => {
	return (
		<BaseContainer classModifier="admin-container" sideMenu={<MainMenu {...props} />} navbar={<div>GS / VB</div>} actionControls={<></>}>
			<EnsureAdmin />
			<GsvbList />
		</BaseContainer>
	);
});

export default AdminGsvbContainer;
