import Widget, { IWidgetConsumer } from 'app/components/common/Widget';
import { observer } from 'mobx-react';
import { AgenturErrungenschaften } from 'app/components/errungenschaften/AgenturErrungenschaften';
import { useStore } from 'app/context';

export const AgenturErrungenschaftenWidget = observer((props: IWidgetConsumer) => {
	const { agenturUiStore } = useStore();
	return (
		<>
			{agenturUiStore.current && agenturUiStore.current.supportsErrungenschaften && (
				<Widget title={'Errungenschaften'} widgetId={props.widgetKey}>
					<AgenturErrungenschaften />
				</Widget>
			)}
		</>
	);
});
