import { UserModel } from 'app/models/core/user.model';
import { BaseCachedEntityStore } from './core/base.cached.entity.store';

export interface IUpdateBnrId {
	oldBnrId: number;
	newBnrId: number;
	transferData: boolean;
}


export class BnrMulitDiff {
	constructor(opts: any) {
		this.bnrId = opts.bnrId;
		this.name = opts.name;
		this.stat = opts.stat;

	}
	bnrId?: number;
	name?: string;
	stat?: string;

}

export class AdminUserStore extends BaseCachedEntityStore<UserModel> {
	apiPath = 'api/user';
	idProp: string = 'userId';
	createInstances(opts: any): UserModel {
		return new UserModel(opts);
	}

	async sendUserInvite(bensl: string) {
		await this.callRefreshTokenIfNecessary();
		const header = this.getAuthHeader();
		await this.axios({
			url: 'api/auth/invite-new-user',
			method: 'POST',
			headers: header.headers,
			data: {
				username: bensl,
			},
		});
	}

	async updateUserBnrId(req: IUpdateBnrId) {
		await this.callRefreshTokenIfNecessary();
		const header = this.getAuthHeader();
		const res = await this.axios({
			url: 'api/user/updateBnrId',
			method: 'POST',
			headers: header.headers,
			data: req,
		});
		return new UserModel(res.data);
	}

	async triggerImport() {
		await this.callRefreshTokenIfNecessary();
		const header = this.getAuthHeader();
		await this.axios({
			url: 'api/import-data/import/userupdate',
			method: 'POST',
			headers: header.headers,
			data: {},
		});
	}

	async updateOrgaInfo() {
		await this.callRefreshTokenIfNecessary();
		const header = this.getAuthHeader();
		await this.axios({
			url: 'api/user/updateOrgaInfo',
			method: 'POST',
			headers: header.headers,
			data: {},
		});
	}

	async updateOrgaInfoWithMultisAgentur() {
		await this.callRefreshTokenIfNecessary();
		const header = this.getAuthHeader();
		await this.axios({
			url: 'api/user/updateOrgaInfoWithMultisAgentur',
			method: 'POST',
			headers: header.headers,
			data: {},
		});
	}





	async updateAgtGeo() {
		await this.callRefreshTokenIfNecessary();
		const header = this.getAuthHeader();
		await this.axios({
			url: 'api/user/updateAgtGeo',
			method: 'POST',
			headers: header.headers,
			data: {},
		});
	}

	async fillCache() {
		await this.callRefreshTokenIfNecessary();
		const header = this.getAuthHeader();
		await this.axios({
			url: 'api/user/fillcache',
			method: 'POST',
			headers: header.headers,
			data: {},
		});
	}

	async emptyCache() {
		await this.callRefreshTokenIfNecessary();
		const header = this.getAuthHeader();
		await this.axios({
			url: 'api/user/emptycache',
			method: 'POST',
			headers: header.headers,
			data: {},
		});
	}

	async missingMultis() {
		await this.callRefreshTokenIfNecessary();
		const header = this.getAuthHeader();
		const res = await this.axios({
			url: 'api/user/missingMultis',
			method: 'POST',
			headers: header.headers,
			data: {},
		});
		return res.data.map((d: any) => new BnrMulitDiff(d));

	}

	async findUserByBnrId(bnrId: number) {
		await this.callRefreshTokenIfNecessary();
		const header = this.getAuthHeader();
		return await this.axios({
			url: 'api/user/bnr/' + bnrId,
			method: 'GET',
			headers: header.headers,
		})
			.catch((res) => {
				return {
					data: {
						userId: undefined,
					},
				};
			})
			.then((res: any) => {
				return new UserModel(res.data);
			});
	}

	async resetAllPasswords() {
		await this.callRefreshTokenIfNecessary();
		const header = this.getAuthHeader();
		return await this.axios({
			url: 'api/admin/stuff/resetallpasswords',
			method: 'POST',
			headers: header.headers,
		})
			.catch((res) => {
				return {

				};
			})
			.then((res: any) => {

			});
	}
}
