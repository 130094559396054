import React from 'react';
import BaseContainer from 'app/containers/BaseContainer';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router-dom';

const PrintingContainer = observer((props: RouteComponentProps) => {
	return <BaseContainer>Printing....</BaseContainer>;
});

export default PrintingContainer;
