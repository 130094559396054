import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { notify } from 'app/components/common/notify';
import { ImportJobVM } from 'app/stores/ui/admin.imports.ui.store';
import { useEffect, useState } from 'react';
import { AdminFileUpload } from '../AdminFileUpload';
import { AupFile, ImportDataType } from 'app/stores/admin.imports.store';
import _ from 'lodash';
import { AdminImportFiles } from '../AdminImportFiles';

interface IUserEditForm {
	job: ImportJobVM;
	onClose: () => void;
}
export const AdminImportForm = observer((props: IUserEditForm) => {
	const { adminImportsUiStore } = useStore();
	const dataType = props.job.job.dataType;

	const [files, setFiles] = useState<AupFile[]>([]);

	const onUploadDone = () => {
		notify('Upload erfolgreich', '', 'info');
		adminImportsUiStore.getFilesByType(dataType).then((res) => {
			res = _.sortBy(res, 'fileName').reverse();
			setFiles(res);
		});

	};

	useEffect(() => {
		adminImportsUiStore.getFilesByType(dataType).then((res) => {
			res = _.sortBy(res, 'fileName').reverse();
			setFiles(res);
		});
	}, [adminImportsUiStore]);

	return (
		<>
			<div className="pad-1rem">
				<div className="form-control is-inline">
					<label>Dateien</label>

					<AdminFileUpload
						isButton={false}
						maxSize={200000000}
						// maxFiles={1}
						dataType={dataType}
						// kw={values.kw}
						multiple={true}
						dropZoneActiveText={'Datei loslassen'}
						dropZoneText={'Dateien Hochladen'}
						uploadingText={'Upload läuft'}
						onDone={onUploadDone}
					/>
				</div>
			</div>

			<div className="pad-1rem">
				<AdminImportFiles files={files} job={props.job} />
			</div>
		</>
	);
});
