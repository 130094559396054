import { Einheit } from 'app/models/core/einheit.model';
import { BnrGpPosModel } from '../bnr.gp.pos.model';
import { PosUnDef } from '../gp';
const posDef: BnrGpPosModel[] = [];

//ok
posDef.push(new BnrGpPosModel({ year: 2023, posId: 2240001, group: 'vss', posText: 'Weiterbildungspfl. BNR (IDD)', planKey: 'idd', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2023, posId: PosUnDef, group: 'vss', posText: 'Weiterbildungsz. Fonds', planKey: 'idd_fonds', einheit: Einheit.bew }));

posDef.push(new BnrGpPosModel({ year: 2023, posId: 2240011, group: 'vss', posText: 'KV-Pflege-LFNST-EA Digi', planKey: 'kvpflege', einheit: Einheit.lfNST })); //
//nok - are we rounding 99,999,999 up to 100,000,000 ?
posDef.push(new BnrGpPosModel({ year: 2023, posId: 2240021, group: 'vss', posText: 'Kranken HKV Pers. Digi', planKey: 'krankenbkwbew', einheit: Einheit.bew })); //

//ok
posDef.push(new BnrGpPosModel({ year: 2023, posId: 2240041, group: 'eh', posText: 'Kranken Bew. o. Dyn.', planKey: 'wb_krankenbewodyn', einheit: Einheit.bew })); //
posDef.push(new BnrGpPosModel({ year: 2023, posId: 2240042, group: 'eh', posText: 'Kranken Voll Bew o. Dyn', einheit: Einheit.bew })); //

posDef.push(new BnrGpPosModel({ year: 2023, posId: 2240111, group: 'neh', posText: 'Kranken Bew o.Dyn Digi', planKey: 'wb_krankenbewodyn', einheit: Einheit.bew })); //
posDef.push(new BnrGpPosModel({ year: 2023, posId: 2240121, group: 'neh', posText: 'Kranken HKV Bew Digi', einheit: Einheit.bew })); //
posDef.push(new BnrGpPosModel({ year: 2023, posId: 2240131, group: 'neh', posText: 'KV u. Pfl. Zusatz Bew Dig', planKey: 'wb_krankenzusatzbewodyn', einheit: Einheit.bew })); //
posDef.push(new BnrGpPosModel({ year: 2023, posId: 2240141, group: 'neh', posText: 'Kranken bKV Bew', planKey: 'wb_krankenbkvbew', einheit: Einheit.bew })); //
posDef.push(new BnrGpPosModel({ year: 2023, posId: 2240153, group: 'neh', posText: 'Kranken_neue_bef_bKV-GV', planKey: 'wb_krankenbkvbewnew', einheit: Einheit.bew })); //

posDef.push(new BnrGpPosModel({ year: 2023, posId: 3840011, group: 'gpplus', posText: 'Kranken Pflege Bew Digi', planKey: 'kranken_pflege_bew_digi', einheit: Einheit.stck })); //
posDef.push(new BnrGpPosModel({ year: 2023, posId: 3840021, group: 'gpplus', posText: 'Kranken Optionstarife Dig', planKey: 'wb_krankenoptionstarifenst', einheit: Einheit.stck })); //

export const KvsPositionen = posDef;
