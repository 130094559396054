import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';

interface ICheckboxControl {
	value: boolean;
	label?: string;
	alignment?: 'left' | 'right';
	id?: string;
	name: string;
	onChange: (name: string, value: boolean) => void;
	placeholder?: string;
}

export const CheckboxControl = observer((props: ICheckboxControl) => {
	const type = 'checkbox';
	const id = props.id ? props.id : type + '' + props.name;
	const [value, setValue] = useState<boolean>(props.value);
	useEffect(() => {
		setValue(props.value);
	}, [props.value]);
	const onChange = (event: any) => {
		setValue(event.target.checked);
		props.onChange(props.name, event.target.checked);
	};
	return (
		<div className={`form-control is-inline is-checkbox ${props.alignment ? 'is-aligned-' + props.alignment : ''}`}>
			{props.label && <label htmlFor={id}>{props.label || props.name}</label>}
			<input id={id} name={props.name} onChange={onChange} type={type} checked={value} placeholder={props.placeholder} />
		</div>
	);
});
