import React, { useCallback, useEffect, useMemo } from 'react';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router';
import routes from 'routes';
import { UserStearts } from 'app/models/core/steart.model';
import { AgenturExtendedVM } from 'app/stores/ui/agt.list.ui.store';
import { ITableColumn, TableModel, TableRow } from 'app/components/table/table.model';
import { Table } from 'app/components/table/table';

export const AgenturenBetreungList = observer(() => {
	const { agenturListUiStore, agenturUiStore, berichteUiiStore } = useStore();
	const history = useHistory();

	const tm = useMemo(() => {
		const tm = new TableModel<AgenturExtendedVM, number>();
		const cols: ITableColumn<AgenturExtendedVM, number>[] = [
			{
				label: 'Agentur',
				path: 'name',
			},
			{
				label: 'VTNR',
				path: 'agtId',
			},
		];
		UserStearts.forEach((s) => {
			cols.push({
				label: s.name,
				path: s.name + '.name',
				sortBy: s.name + '.lastName',
			});
		});

		tm.setCols(cols);
		tm.sortBy = 'name';
		tm.idProperty = 'agtId';
		tm.idType = 'number';
		return tm;
	}, []);

	const selectAgt = useCallback(
		(agt: AgenturExtendedVM) => {
			history.push(routes.AGENTUR.getPath(agt.agtId.toString(), agenturUiStore.currentPanel, berichteUiiStore.menu.current?.path));
		},
		[agenturUiStore.currentPanel, berichteUiiStore.menu, history],
	);

	useEffect(() => {
		const filtered = agenturListUiStore.applyFilter(agenturListUiStore.items, { ignoreAgtId: true }) as AgenturExtendedVM[];
		tm.setRowData(filtered);
		if (agenturListUiStore.currentFilter.singleSelectedAgtId) {
			const agt = filtered.find((a) => a.agtId === agenturListUiStore.currentFilter.singleSelectedAgtId);
			if (agt) {
				selectAgt(agt);
			}
		}
	}, [tm, agenturListUiStore.items, agenturListUiStore.currentFilter.changedString, agenturListUiStore, selectAgt]);

	tm.onRowClick = (row: TableRow<AgenturExtendedVM>) => {
		selectAgt(row.data);
	};

	useEffect(() => {
		if (agenturListUiStore.items.length >= 0) {
			tm.setSelectedId(agenturUiStore.currentAgtId);
		}
	}, [tm, agenturListUiStore, agenturUiStore.currentAgtId, agenturListUiStore.items.length]);

	return (
		<>
			<Table modifier="left-col-bold" tm={tm} stickyHeader={true}></Table>
		</>
	);
});
