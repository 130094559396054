import { Icon } from 'app/components/common/Icon';
import { AgenturModel } from 'app/models/agentur.model';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { AgtSelector } from '../shared/AgtSelector';
import { BaseTreeStore, AgtBaseTreeStore } from 'app/stores/core/base.tree.store';
import { Button } from 'app/components/common/Button';
import { useStore } from 'app/context';
import { BnrSelector } from '../shared/BnrSelector';
import { UserModel } from 'app/models/core/user.model';
import Config from 'Config';

interface ITreePanelMenu {
	treeStore: AgtBaseTreeStore | BaseTreeStore;
}

export const TreePanelMenu = observer((props: ITreePanelMenu) => {
	const treeStore = props.treeStore;
	const { uiStore, agenturListUiStore } = useStore();
	const [agt, setAgt] = useState<AgenturModel | undefined>(treeStore.selectedAgt);
	const [bnr, setBnr] = useState<UserModel | undefined>(treeStore.selectedBnr);

	const onSelectAgt = (agt?: AgenturModel) => {

		agenturListUiStore.currentFilter.selectSingleAgtId(agt ? agt.agtId : undefined);
		agenturListUiStore.currentFilter.setBetreuerId(undefined);
	};

	const onSelectBnr = (bnr?: UserModel) => {
		const betreuteBnrId = bnr ? bnr.bnrId : undefined;
		agenturListUiStore.currentFilter.setBetreuerId(betreuteBnrId);
		agenturListUiStore.currentFilter.selectSingleAgtId(undefined);
	};
	const onToggleFullscreen = () => {
		uiStore.toggleFullScreenPanel();
	};

	useEffect(() => {
		setAgt(treeStore.selectedAgt);
	}, [treeStore.selectedAgt]);

	useEffect(() => {
		setBnr(treeStore.selectedBnr);
	}, [treeStore.selectedBnr]);
	return (
		<div className="tree-panel-menu">
			{!treeStore.showFilter && (
				<>
					{!treeStore.isInAgt && (
						<>
							<AgtSelector onSelect={onSelectAgt} selectedAgt={agt} />
							{Config.showGslStuff && <BnrSelector onSelect={onSelectBnr} selectedBnr={bnr} />}
						</>
					)}
				</>
			)}
			<Button className="button is-header-button is-hidden-mobile" onClick={onToggleFullscreen}>
				<Icon iconClass={uiStore.fullScreenPanel ? 'compress-arrows-alt' : 'expand-arrows-alt'} />
			</Button>
		</div>
	);
});
