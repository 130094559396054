import { useEffect, useState } from 'react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { useStore } from 'app/context';
import { useHistory } from 'react-router';
import { observer } from 'mobx-react';
import FormField from 'app/components/form/FormField';
import { Button } from 'app/components/common/Button';
import { IBnrTransferParamTypes } from 'app/containers/BnrTransferContainer';
import { BnrTransferRequest } from 'app/stores/bnrtransfer.store';
import routes from 'routes';

const bnrTransferSchema = Yup.object({
	sourceComment: Yup.string(),
	transferBranchenplanung: Yup.boolean().required(),
	transferDoks: Yup.boolean().required(),
}).required();

type BnrTransfer = Yup.InferType<typeof bnrTransferSchema>;

interface IBnrTransferForm {
	transfer: BnrTransferRequest;
}

export const BnrTransferFormLoader = observer((props: IBnrTransferParamTypes) => {
	const { bnrtransferStore } = useStore();
	const [transfer, setTransfer] = useState<BnrTransferRequest | undefined>();
	const [loading, setIsLoading] = useState<boolean>(false);
	useEffect(() => {
		if (props.id) {
			bnrtransferStore.findByAnonHash(props.id).then((t) => {
				setTransfer(t);
				setIsLoading(false);
			});
		}
	}, [bnrtransferStore, props.id]);

	return (
		<>
			{loading && <>Lade Datenübernahme...</>}
			{transfer && <BnrTransferForm transfer={transfer} />}

			{!loading && !transfer && (
				<>
					{/* <Form className="auth-form"> */}
					<div className="auth-form-header">
						<div className="title">DVS</div>
					</div>
					Diese Datenübernahme existiert nicht oder wurde bereits abgeschlossen.
					{/* </Form> */}
				</>
			)}
		</>
	);
});

// 1 http://localhost:8090/api/transfer/?id=20c3c505-0ecb-438f-9636-0b0407a82739&_=1625136411282
//   http://localhost:8090/api/transfer?id=20c3c505-0ecb-438f-9636-0b0407a82739

export const BnrTransferForm = observer((props: IBnrTransferForm) => {
	const { bnrtransferStore } = useStore();
	const transfer = props.transfer;
	const history = useHistory();

	async function handleSubmit(values: BnrTransfer) {}

	return (
		<Formik
			validationSchema={bnrTransferSchema}
			initialValues={{
				sourceComment: '',
				transferBranchenplanung: true,
				transferDoks: true,
			}}
			onSubmit={handleSubmit}
		>
			{({ errors, touched, isValid, values }) => {
				const getValues = () => {
					transfer.sourceComment = values.sourceComment;
					transfer.transferDoks = values.transferDoks;
					transfer.transferBranchenplanung = values.transferBranchenplanung;
				};

				const onReject = async () => {
					getValues();
					transfer.state = 'rejected';
					await bnrtransferStore.save(transfer);
					history.push(routes.BNRTRANSFERDONE.getPath(transfer.state));
				};
				const onAccept = async () => {
					getValues();
					transfer.state = 'approved';
					await bnrtransferStore.save(transfer);
					history.push(routes.BNRTRANSFERDONE.getPath(transfer.state));
				};

				return (
					<Form className="auth-form">
						<div className="auth-form-header">
							<div className="title">DVS Datenübernahme</div>
						</div>
						<div>
							Hallo {transfer.sourceName}, <br />
							Sie haben ein Anfrage von ihrem Nachfolger {transfer.targetName} ({transfer.targetBnrId}) zur Übernahme ihre DVS-Daten erhalten.
							<br />
							<br />
							Wenn Sie damit einverstanden sind, dann bestätigen Sie bitte die Übernahme der folgenden Positionen aus Ihrem DVS Datenbestand. <br />
						</div>
						<div>
							<strong>Folgenden Daten können übernommen werden: </strong>
							<FormField className="title" label="Dokumente (Planungen, Dokumente, Uploads und Maßnahmen)">
								<Field type="checkbox" name={'transferDoks'} />
							</FormField>

							<FormField className="title" label="Branchenplanung">
								<Field type="checkbox" name={'transferBranchenplanung'} />
							</FormField>
							<FormField className="title" error={errors.sourceComment} touched={touched.sourceComment} label="Antwort auf Anfrage">
								<Field type="text" as="textarea" name="sourceComment" placeholder="Antwort auf Anfrage" />
							</FormField>
						</div>
						<div>
							<div>
								<Button type="button" className="button is-secondary" disabled={!isValid} onClick={onReject}>
									Datenübernahme ablehnen
								</Button>
								<Button type="button" className="button is-primary " disabled={!isValid} onClick={onAccept}>
									Datenübernahme bestätigen
								</Button>
							</div>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
});
