import { Steart } from 'app/models/core/steart.model';
import { ZeWidgetDef, ZeWidgetItem } from './vbl.ze.widgets';

export const BsgZeWidgets: ZeWidgetDef[] = [
	new ZeWidgetDef({
		title: 'Firmen Sach Gesamt',
		subTitle: '(Bewertung)',
		key: 'firmen_sach_gesamt_bsg',
		stearts: [Steart.BSG],
		items: [
			new ZeWidgetItem({
				text: <strong>Individuelles Arbeitsgebietsziel 100%</strong>,
				posId: [2215041, 2216041],
			}),
		],
	}),
	new ZeWidgetDef({
		title: 'oder Firmen Sach Gesamt und Unfall ',
		subTitle: '(Bewertung ohne Anpassung)',
		key: 'firmen_sach_gesamt_und_unfall_bsg',
		stearts: [Steart.BSG],
		items: [
			new ZeWidgetItem({
				text: <strong>Individuelles Arbeitsgebietsziel 200%</strong>,
				posId: [2215042, 2216042],
			}),
		],
	}),
];
