import { CoronaCheckFormItem } from 'app/models/corona/corona.check.form.item';
import { LeadnowCheckFormItem } from 'app/models/leadnowcheck/leadnow.check.form.item';
import { observer } from 'mobx-react';

interface IFieldRow {
	formItem: LeadnowCheckFormItem | CoronaCheckFormItem;
}
export const CheckFormThema = observer((props: IFieldRow) => {
	const formItem = props.formItem;
	return (
		<>
			<strong>{formItem.label}</strong>
			{formItem.details && <p>{formItem.details}</p>}
		</>
	);
});
