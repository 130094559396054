import { Steart } from 'app/models/core/steart.model';
import { ZeWidgetDef, ZeWidgetItem } from './vbl.ze.widgets';

export const VasZeWidgets: ZeWidgetDef[] = [
	new ZeWidgetDef({
		title: 'Leben und Fonds inklusive FRV-Zusatzbewertung',
		subTitle: '(Bewertung ohne ZE)',
		key: 'ZE_Rente_Leben_undFonds_VAS',
		stearts: [Steart.VAS],
		items: [
			new ZeWidgetItem({
				text: <strong>Summe der Zielerfüllungen im individuellen Arbeitsgebiet 190%</strong>,
				posId: 2225045,
				einheit: '%',
			}),
			new ZeWidgetItem({
				text: <strong>Mindestens in Leben 90%</strong>,
				posId: 2225041,
			}),
			new ZeWidgetItem({
				text: <strong>Mindestens in Fonds inklusive FRV-Zusatzbewertung 90%</strong>,
				posId: 2225043,
			}),
		],
	}),
	new ZeWidgetDef({
		title: 'oder Leben',
		subTitle: '(Bewertung ohne ZE)',
		key: 'ZE_Rente_Leben_VAS',
		stearts: [Steart.VAS],
		items: [
			new ZeWidgetItem({
				text: <strong>individuelles Arbeitsgebietsziel 100%</strong>,
				posId: 2225042,
			}),
		],
	}),
];
