import { useStore } from 'app/context';
import { ApModel } from 'app/models/documents/ap.model';
import { AgtZielModel } from 'app/stores/agt.ziel.store';
import { DocumentVM } from 'app/stores/ui/document.ui.store';
import { formatCurrency, formatPercent } from 'app/utils';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { ZielErgebnisBalken, ZielErgebnisBalkenModel } from '../charts/ZielErgebnisBalken';

interface IAgtPlanungDoc {
	doc: DocumentVM;
	agtId: number;
	showAgt: boolean;
	showPlan: boolean;
}

export const ApZielErreichungsBalken = observer((props: IAgtPlanungDoc) => {
	const ap = props.doc.doc.content as ApModel;
	const { agtProdUiStore } = useStore();
	const [zeBalken, setZeBalken] = useState<ZielErgebnisBalkenModel>();
	const [posVal, setPosVal] = useState<AgtZielModel>();
	const [bewZe, setBewZe] = useState<number>();

	useEffect(() => {
		let isMounted = true;
		if (!props.doc.agtId) {
			return;
		}

		agtProdUiStore.findByPlanKeyAndAgtId('gesamtbewertung', props.agtId, props.doc.planYear!).then((res) => {
			if (!isMounted) {
				return;
			}
			if (res && res.posVal) {
				setPosVal(res.posVal);
				const model = new ZielErgebnisBalkenModel(100, res.posVal.ze);
				setZeBalken(model);
				setBewZe(ap.gesamtbewertung / res.posVal.ziel);
			}
		});
		return () => {
			isMounted = false;
		};
	}, [ap.baq, ap.gesamtbewertung, agtProdUiStore, props.doc.agtId, props.agtId, props.doc.planYear]);

	return (
		<>
			{ap.isPlanned && (
				<>
					{zeBalken && posVal && bewZe && (
						<>
							Zielerreichung: {formatCurrency(posVal.ziel)} GP-EH (100%)
							<ZielErgebnisBalken model={zeBalken} />
							{formatCurrency(ap.gesamtbewertung)} per.Ziel VT ({formatPercent(bewZe)})
						</>
					)}
					<br />
					<br />
					BAQ: {formatPercent(ap.baq)} %
					<br />
					Gesamtbewertung: {formatCurrency(ap.gesamtbewertung)}
					<br />
				</>
			)}
		</>
	);
});
