import { Icon } from 'app/components/common/Icon';
import { Table } from 'app/components/table/table';
import { ITableColumn, TableModel, TableRow } from 'app/components/table/table.model';
import { useStore } from 'app/context';
import { GsVbVM } from 'app/stores/ui/gsvb.ui.store';
import { useEffect } from 'react';

export const GsvbList = () => {
	const { gsvbUiStore } = useStore();

	const tm = new TableModel<GsVbVM, number>();
	let cols: ITableColumn<GsVbVM, number>[] = [
		{
			label: 'GSL',
			path: 'bnr.name',
		},
		{
			label: 'BnrId',
			path: 'bnr.bnrId',
		},
		{
			label: '# Teammitglieder',
			path: 'team.length',
		},
		{
			label: '# Agt',
			path: 'agtIds.length',
		},
		{
			label: '# Vbl Agt',
			path: 'vblAgtIds.length',
		},
		{
			label: '# Agt VB Unbetreut',
			path: 'vbUnbetreut',
			render: (row: TableRow<GsVbVM>) => {
				return (
					<>
						{row.data.vbUnbetreut !== 0 && (
							<>
								<Icon iconClass={'check'} /> {row.data.vbUnbetreut}{' '}
							</>
						)}
					</>
				);
			},
		},
		{
			label: '# Unbetreute AgtIds',
			path: 'vbUnbetreuteAgtIds',
			render: (row: TableRow<GsVbVM>) => {
				return (
					<>
						{row.data.vbUnbetreuteAgtIds.map((a) => (
							<span key={a}>{a},</span>
						))}
					</>
				);
			},
		},
	];

	tm.setCols(cols);
	tm.sortBy = 'bnr.bnrId';
	tm.sortAsc = false;
	tm.idProperty = 'bnr.bnrId';

	useEffect(() => {
		gsvbUiStore.load().then((res) => {
			tm.setRowData(res);
		});
	}, [tm, gsvbUiStore]);

	return (
		<>
			<Table tm={tm} />
		</>
	);
};
