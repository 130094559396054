import { action, makeObservable, observable } from 'mobx';

interface ISelectValue {
	value: any;
	label: string;
}
interface IInputItem {
	title: string;
	id: string;
	value: number;
	data?: any;
	inputType?: 'string' | 'checkbox' | 'select';
	selectItems?: ISelectValue[];
}

const bspStornoquoteStrategieOpts: ISelectValue[] = [
	{ value: 7, label: '0 bis 3.5' },
	{ value: 6, label: '3.51 bis 4' },
	{ value: 5, label: '4.01 bis 4.5' },
	{ value: 4, label: '4.51 bis 5' },
	{ value: 3, label: '5.01 bis 7' },
	{ value: 2, label: '7.01 bis 7.5' },
	{ value: 0, label: 'über 7.5' },
];

const bspStornoquoteStrategieLOpts: ISelectValue[] = [
	{ value: 7, label: '0 bis 3.5' },
	{ value: 6, label: '3.51 bis 4' },
	{ value: 5, label: '4.01 bis 4.5' },
	{ value: 4, label: '4.51 bis 5' },
	{ value: 3, label: '5.01 bis 5.5' },
	{ value: 2, label: '5.51 bis 6' },
	{ value: 1, label: '6.01 bis 6.5' },
	{ value: 0, label: 'über 6.5' },
];
const bspStornoquoteDVOpts: ISelectValue[] = [
	{ value: 7, label: '0 bis 2.5' },
	{ value: 6, label: '2.51 bis 3' },
	{ value: 5, label: '3.01 bis 3.5' },
	{ value: 4, label: '3.51 bis 4' },
	{ value: 3, label: '4.01 bis 4.5' },
	{ value: 2, label: '4.51 bis 5' },
	{ value: 1, label: '5.01 bis 5.5' },
	{ value: 0, label: 'über 5.5' },
];
const bspStornoquoteNichtDVOpts: ISelectValue[] = [
	{ value: 7, label: '0 bis 3.5' },
	{ value: 6, label: '3.51 bis 4' },
	{ value: 5, label: '4.01 bis 4.5' },
	{ value: 4, label: '4.51 bis 5' },
	{ value: 3, label: '5.01 bis 7' },
	{ value: 2, label: '7.01 bis 7.5' },
	{ value: 0, label: 'über 7.5' },
];

const yesNoOpts: ISelectValue[] = [
	{ value: 1, label: 'Ja' },
	{ value: 0, label: 'Nein' },
];
export class ApInputItem {
	constructor(opts: IInputItem) {
		makeObservable(this);
		this.title = opts.title;
		this.id = opts.id;
		this.value = opts.value;
		if (opts.inputType) {
			this.inputType = opts.inputType;
		}
		if (opts.selectItems) {
			this.selectItems = opts.selectItems;
		}
		if (opts.data && opts.data[this.id]) {
			this.value = opts.data[this.id];
		}
	}

	inputType: 'string' | 'checkbox' | 'select' = 'string';
	selectItems?: ISelectValue[];

	@observable
	title: string;
	@observable
	id: string;
	@observable
	value: number;
	@observable
	children: ApInputItem[] = [];

	serialize(data: any) {
		data[this.id] = this.value;
		// if (this.inputType === 'checkbox'){
		// }
		// data[this.id] = this.value;
	}
}

export class ApInputBase {
	constructor(data: any) {
		makeObservable(this);
	}

	@observable
	inventoryData: ApInputItem[] = [];
	@observable
	expenses: ApInputItem[] = [];
	@observable
	receipts: ApInputItem[] = [];

	@action
	addBesonderHeiten(data: any) {
		// disabled as it was not being used in V1 anymore
		// const besonderheiten = new ApInputItem({ title: 'Besonderheiten', id: 'besonderheiten', value: -1, data });
		// this.inventoryData.push(besonderheiten);
		// besonderheiten.children.push(new ApInputItem({ title: 'Anteil Leben an Gesamtbewertung in %', id: 'anteilLeben', value: 0, data }));
		// besonderheiten.children.push(new ApInputItem({ title: 'Anteil Sach o. Kraft an Gesamtbewertung in %', id: 'anteilSachKraft', value: 0, data }));
		// besonderheiten.children.push(new ApInputItem({ title: 'Anteil Kraft an Gesamtbewertung in %', id: 'anteilKraft', value: 0, data }));
	}

	getValueById(p: string) {
		let x = this.inventoryData.find((i) => i.id === p);
		if (x) {
			return x.value;
		}
		x = this.expenses.find((i) => i.id === p);
		if (x) {
			return x.value;
		}
		x = this.receipts.find((i) => i.id === p);
		if (x) {
			return x.value;
		}
		return 0;
	}

	@action
	setValueById(p: string, values: any) {
		let x = this.inventoryData.find((i) => i.id === p);
		if (x) {
			return (x.value = values[p] as number);
		}
		x = this.expenses.find((i) => i.id === p);
		if (x) {
			return (x.value = values[p] as number);
		}
		x = this.receipts.find((i) => i.id === p);
		if (x) {
			return (x.value = values[p] as number);
		}
		return 0;
	}

	@action
	setData(def: ApInputDefintion, data: any) {
		def.inventoryData.forEach((i) => {
			this.inventoryData.push(new ApInputItem({ ...i, data }));
		});
		this.addBesonderHeiten(data);

		def.expenses.forEach((i) => {
			this.expenses.push(new ApInputItem({ ...i, data }));
		});

		def.receipts.forEach((i) => {
			this.receipts.push(new ApInputItem({ ...i, data }));
		});
	}

	serialize() {
		const data: any = {};
		// data.id = id;
		this.inventoryData.forEach((d) => {
			d.serialize(data);
			if (d.children.length > 0) {
				d.children.forEach((cd) => cd.serialize(data));
			}
		});

		this.expenses.forEach((d) => {
			d.serialize(data);
			if (d.children.length > 0) {
				d.children.forEach((cd) => cd.serialize(data));
			}
		});

		this.receipts.forEach((d) => {
			d.serialize(data);
			if (d.children.length > 0) {
				d.children.forEach((cd) => cd.serialize(data));
			}
		});

		return data;
	}
}

class ApInputDefintion {
	inventoryData: IInputItem[] = [];
	expenses: IInputItem[] = [];
	receipts: IInputItem[] = [];

	genOptions(count: number, prefix?: string) {
		let options: ISelectValue[] = [];
		for (let i = 1; i <= count; i++) {
			const label = prefix ? prefix + i : i.toString();
			options.push({ value: i, label });
		}
		return options;
	}
}

export class ApUmstiegInput extends ApInputDefintion {
	constructor() {
		super();

		this.inventoryData.push({ title: 'Kraft-Bestand', id: 'kraftBestand', value: 0 });

		this.inventoryData.push({ title: 'HV-Stufe AP Privat', id: 'hvStufeAPPrivat', value: 1, inputType: 'select', selectItems: this.genOptions(4, 'HV') });
		this.inventoryData.push({ title: 'HV-Stufe AP Firmen', id: 'hvStufeAPFirmen', value: 1, inputType: 'select', selectItems: this.genOptions(4, 'HV') });
		this.inventoryData.push({ title: 'HV-Stufe FP', id: 'hvStufeAF', value: 1, inputType: 'select', selectItems: this.genOptions(6, 'HV') });
		this.inventoryData.push({ title: 'AP-Satz Leben in Promille', id: 'apSatzLeben', value: 0 });
		this.inventoryData.push({ title: 'BeOp Faktor', id: 'beOpFaktor', value: 100 });
		// this.addBesonderHeiten(data);

		this.expenses.push({ title: 'Personalkosten AD', id: 'personalkostenAD', value: 0 });
		this.expenses.push({ title: 'Personalkosten ID', id: 'personalkostenID', value: 0 });
		this.expenses.push({ title: 'Miete', id: 'miete', value: 0 });
		this.expenses.push({ title: 'Sonstiges', id: 'sonstigeAusgaben', value: 0 });

		this.receipts.push({ title: 'Bonifikationen', id: 'boni', value: 0 });
		this.receipts.push({ title: 'Pensenprovisionen', id: 'pensenProvisionen', value: 0 });
		this.receipts.push({ title: 'Sonstiges', id: 'sonstigeEinnahmen', value: 0 });
	}
}

export class ApAusgleichInput extends ApInputDefintion {
	constructor() {
		super();

		this.inventoryData.push({ title: 'HV-Stufe', id: 'hvStufe', value: 1, inputType: 'select', selectItems: this.genOptions(6, 'HV') });
		this.inventoryData.push({ title: 'AP-Satz Leben in Promille', id: 'apSatzLeben', value: 0 });
		this.inventoryData.push({ title: 'BeOp Faktor', id: 'beOpFaktor', value: 100 });
		// this.addBesonderHeiten(data);

		this.expenses.push({ title: 'Personalkosten AD', id: 'personalkostenAD', value: 0 });
		this.expenses.push({ title: 'Personalkosten ID', id: 'personalkostenID', value: 0 });
		this.expenses.push({ title: 'Miete', id: 'miete', value: 0 });
		this.expenses.push({ title: 'Sonstiges', id: 'sonstigeAusgaben', value: 0 });

		this.receipts.push({ title: 'Bonifikationen', id: 'boni', value: 0 });
		this.receipts.push({ title: 'Leistungsprovisionen', id: 'leistung', value: 0 });
		this.receipts.push({ title: 'Sonstiges', id: 'sonstigeEinnahmen', value: 0 });
	}
}

export class ApPro3Input extends ApInputDefintion {
	constructor() {
		super();
		this.inventoryData.push({ title: 'Teilnahme am LVRG', id: 'teilnahmeLVRG', value: 0, inputType: 'select', selectItems: yesNoOpts });
		this.inventoryData.push({ title: 'Tabstufe AP', id: 'tabstufeAP', value: 1, inputType: 'select', selectItems: this.genOptions(4) });
		this.inventoryData.push({ title: 'Tabstufe FP', id: 'tabstufeFP', value: 1, inputType: 'select', selectItems: this.genOptions(3) });
		// this.addBesonderHeiten(data);

		this.expenses.push({ title: 'Personalkosten AD', id: 'personalkostenAD', value: 0 });
		this.expenses.push({ title: 'Personalkosten ID', id: 'personalkostenID', value: 0 });
		this.expenses.push({ title: 'Miete', id: 'miete', value: 0 });
		this.expenses.push({ title: 'Sonstiges', id: 'sonstigeAusgaben', value: 0 });

		this.receipts.push({ title: 'Bonifikationen', id: 'boni', value: 0 });
		this.receipts.push({ title: 'Sonstiges', id: 'sonstigeEinnahmen', value: 0 });
	}
}

export class ApStrategieInput extends ApInputDefintion {
	constructor() {
		super();
		this.inventoryData.push({ title: 'BSP-Bestand', id: 'bspBestand', value: 0 });
		this.inventoryData.push({ title: 'HV-Stufe', id: 'hvStufe', value: 1, inputType: 'select', selectItems: this.genOptions(4, 'HV') });
		this.inventoryData.push({ title: 'BSP-Stornoquote in %', id: 'bspStornoquote', value: 7, inputType: 'select', selectItems: bspStornoquoteStrategieOpts });
		this.inventoryData.push({ title: 'BeOp Faktor', id: 'beOpFaktor', value: 100 });
		// this.addBesonderHeiten(data);

		this.expenses.push({ title: 'Personalkosten AD', id: 'personalkostenAD', value: 0 });
		this.expenses.push({ title: 'Personalkosten ID', id: 'personalkostenID', value: 0 });
		this.expenses.push({ title: 'Miete', id: 'miete', value: 0 });
		this.expenses.push({ title: 'Sonstiges', id: 'sonstigeAusgaben', value: 0 });

		this.receipts.push({ title: 'Bonifikationen', id: 'boni', value: 0 });
		this.receipts.push({ title: 'Leistungsprovisionen', id: 'leistung', value: 0 });
		this.receipts.push({ title: 'Sonstiges', id: 'sonstigeEinnahmen', value: 0 });
	}
}
export class ApStrategieLInput extends ApInputDefintion {
	constructor() {
		super();
		this.inventoryData.push({ title: 'BSP-Bestand', id: 'bspBestand', value: 0 });
		this.inventoryData.push({ title: 'HV-Stufe', id: 'hvStufe', value: 1, inputType: 'select', selectItems: this.genOptions(4, 'HV') });
		this.inventoryData.push({ title: 'BSP-Stornoquote in %', id: 'bspStornoquote', value: 7, inputType: 'select', selectItems: bspStornoquoteStrategieLOpts });
		this.inventoryData.push({ title: 'BeOp Faktor', id: 'beOpFaktor', value: 100 });
		// this.addBesonderHeiten(data);

		this.expenses.push({ title: 'Personalkosten AD', id: 'personalkostenAD', value: 0 });
		this.expenses.push({ title: 'Personalkosten ID', id: 'personalkostenID', value: 0 });
		this.expenses.push({ title: 'Miete', id: 'miete', value: 0 });
		this.expenses.push({ title: 'Sonstiges', id: 'sonstigeAusgaben', value: 0 });

		this.receipts.push({ title: 'Bonifikationen', id: 'boni', value: 0 });
		this.receipts.push({ title: 'Leistungsprovisionen', id: 'leistung', value: 0 });
		this.receipts.push({ title: 'Sonstiges', id: 'sonstigeEinnahmen', value: 0 });
	}
}

export class ApStrategieBInput extends ApInputDefintion {
	constructor() {
		super();
		this.inventoryData.push({ title: 'BSP-Bestand DV', id: 'bspBestandDV', value: 0 });
		this.inventoryData.push({ title: 'BSP-Bestand nicht DV', id: 'bspBestandNichtDV', value: 0 });
		this.inventoryData.push({ title: 'BSP-Stornoquote DV in %', id: 'bspStornoquoteDV', value: 7, inputType: 'select', selectItems: bspStornoquoteDVOpts });
		this.inventoryData.push({ title: 'BSP-Stornoquote nicht DV in %', id: 'bspStornoquoteNichtDV', value: 7, inputType: 'select', selectItems: bspStornoquoteNichtDVOpts });
		this.inventoryData.push({ title: 'HV-Stufe', id: 'hvStufe', value: 1, inputType: 'select', selectItems: this.genOptions(6, 'HV') });
		this.inventoryData.push({ title: 'BeOp Faktor', id: 'beOpFaktor', value: 100 });

		// this.addBesonderHeiten(data);

		this.expenses.push({ title: 'Personalkosten AD', id: 'personalkostenAD', value: 0 });
		this.expenses.push({ title: 'Personalkosten ID', id: 'personalkostenID', value: 0 });
		this.expenses.push({ title: 'Miete', id: 'miete', value: 0 });
		this.expenses.push({ title: 'Sonstiges', id: 'sonstigeAusgaben', value: 0 });

		this.receipts.push({ title: 'Bonifikationen', id: 'boni', value: 0 });
		this.receipts.push({ title: 'Leistungsprovisionen', id: 'leistung', value: 0 });
		this.receipts.push({ title: 'Sonstiges', id: 'sonstigeEinnahmen', value: 0 });
	}
}
