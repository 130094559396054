import { Button } from 'app/components/common/Button';
import FormField from 'app/components/form/FormField';
import { useStore } from 'app/context';
import { AgenturModel } from 'app/models/agentur.model';
import { BpAgtPosModel } from 'app/models/branchenplanung/bp.agt.pos.det.model';
import { FilterBranchenPosDefBySteart } from 'app/models/branchenplanung/bp.pos.def';
import { AgtBpVM } from 'app/stores/ui/agt.branchenplanung.ui.store';
import { Field, Form, Formik, useFormikContext } from 'formik';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { bpBaseSchema } from './BranchenplanungList';
import { formatCurrency, formatNum, formatPercentTwoDig } from 'app/utils';
import { EinheitModel } from 'app/models/core/einheit.model';
import { Icon } from 'app/components/common/Icon';
import { runInAction } from 'mobx';
import _ from 'lodash';
import { AgtBpBaseModel } from 'app/stores/agt.bp.store';

interface IAgtBranchenplanungEdit {
	agt: AgenturModel;
	year: number;
	showErgebnisse: boolean;
}

interface IEditableBpField {
	posDef: BpAgtPosModel;
	editMode: boolean;
	einheit: EinheitModel;
}

const EditableBpField = observer((props: IEditableBpField) => {
	const posDef = props.posDef;
	const prop = posDef.prop;
	const [value, setValue] = useState<any>();
	const [err, setErr] = useState<string>();
	const [touchy, setTouchy] = useState<boolean>();
	const { errors, touched, values, handleChange } = useFormikContext();

	useEffect(() => {
		const e = (errors as any)[prop];
		setErr(e);
		const t = (touched as any)[prop];
		setTouchy(t);
		let v = (values as any)[prop];
		if (!v) {
			// prevent this warning:
			// https://github.com/mui-org/material-ui/issues/4904
			v = '';
		}

		setValue(v);
	}, [touched, errors, values, prop]);

	const onChange = (e: any) => {
		setValue(e.target.value);
		handleChange(e);
	};

	return props.editMode ? (
		<FormField key={prop} className={'has-unit-display'} error={err} touched={touchy}>
			<Field type="number" name={prop} value={value} onChange={onChange} />
			<div className="unit-display">
				<BpUnitDisplay model={posDef} />
			</div>
		</FormField>
	) : (
		<div className="tag is-blue">
			<>
				{formatCurrency(value || 0, 2, false)} {posDef.einheit.id !== 'bew' && <BpUnitDisplay model={posDef} />}
			</>
		</div>
	);
});

interface IOtherBpFieldValues {
	posDef: BpAgtPosModel;
	data: AgtBpVM;
}

const OtherBpFieldValues = observer((props: IOtherBpFieldValues) => {
	const { session } = useStore();
	const posDef = props.posDef;
	const prop = posDef.prop;

	const others = posDef.getOtherVisibleStearts(session.currentUser!.steart.steart);
	return (
		<>
			{others.map((s: any) => {
				let v;
				const o = (props.data as any)[s.name];
				if (o) {
					v = o[prop];
				}
				return (
					<div className={`tag color-${s.name}`} key={s.name}>
						{s.name} {!v ? <Icon iconClass="minus" /> : formatNum(v)}
					</div>
				);
			})}
		</>
	);
});

interface IRingValue {
	data: AgtBpBaseModel;
	planKey: string;
	format?: any;
}

export const RingValue = observer((props: IRingValue) => {
	const [value, setValue] = useState(0);
	const data = props.data;
	const format = props.format ? props.format : formatNum;
	useEffect(() => {
		const val = _.get(data, props.planKey);
		setValue(val);
	}, [data, props.planKey]);
	return <>{value > 0 && <> {format(value)}</>}</>;
});

interface IBpUnitDisplay {
	model: BpAgtPosModel;
}

export const BpUnitDisplay = observer((props: IBpUnitDisplay) => {
	const { agtBranchenplanungUiStore } = useStore();
	const einheit = props.model.getEinheitForFak(agtBranchenplanungUiStore.currentUmrechnungsFaktor);

	return <>{einheit.shortText}</>;
});

export const AgtBranchenplanungEdit = observer((props: IAgtBranchenplanungEdit) => {
	const { agtBranchenplanungUiStore, session, uiStore } = useStore();
	const [data, setData] = useState<AgtBpVM>();
	const [editMode, setEditMode] = useState<boolean>(false);

	useEffect(() => {
		setEditMode(uiStore.branchenPlanungEdit.enabled);
	}, [uiStore.branchenPlanungEdit.enabled]);

	const visiblePosDefs = FilterBranchenPosDefBySteart(session.currentUser!.steart);

	useEffect(() => {
		agtBranchenplanungUiStore.findByYearAndAgtId(props.year, props.agt.agtId).then((d) => {
			if (!d) {
				// hack to fix BP not available bug
				d = agtBranchenplanungUiStore.createEmptyBP(props.agt, props.year);
			}

			// d.setUmrechFaktor(agtBranchenplanungUiStore.currentUmrechnungsFaktor);
			setData(d);
		});
	}, [agtBranchenplanungUiStore, props.year, props.agt]);

	const handleSubmit = (values: AgtBpVM) => {
		values!.ensureNumberValues();
		visiblePosDefs.forEach((bpPosDef) => {
			// copy from values to data
			const prop = bpPosDef.prop;
			runInAction(() => {
				let v = (values as any)[prop];
				if (v) {
					let v2 = parseFloat(v);
					(data as any)[prop] = v2;
				} else {
					(data as any)[prop] = null;
				}
			});
		});
		agtBranchenplanungUiStore.saveMany([data!]).then(() => {
			setEditMode(false);
			uiStore.branchenPlanungEdit.endEdit();
		});
	};

	return (
		<>
			{/* {agtBranchenplanungUiStore.currentUmrechnungsFaktor} */}
			{data && (
				<Formik validationSchema={bpBaseSchema} initialValues={data} onSubmit={handleSubmit} validateOnMount={true} enableReinitialize={true}>
					{({ isValid, resetForm, values, setFieldValue }) => {
						const onCancel = () => {
							resetForm();
							setEditMode(false);
							uiStore.branchenPlanungEdit.endEdit();
						};
						return (
							<Form className="bp-single-edit table-container">
								{/* data fake: {data.umrechFaktor} */}
								<table className="table allow-wrap">
									<thead>
										<tr>
											<th>Branche</th>
											<th>Meine Planung</th>
											<th>Ziele</th>
											{props.showErgebnisse && (
												<>
													<th>GP/Ring Ziel</th>
													<th>Ergebnis</th>
													<th>Meine ZE</th>
													<th>ZE Zeit</th>
												</>
											)}
										</tr>
									</thead>

									<tbody>
										{visiblePosDefs.map((bpPosDef) => {
											const fak = agtBranchenplanungUiStore.currentUmrechnungsFaktor;
											const einheit = bpPosDef.getEinheitForFak(fak);

											return (
												<tr key={bpPosDef.prop}>
													<td>
														<strong>{bpPosDef.posText}</strong>
													</td>
													<td>
														<EditableBpField posDef={bpPosDef} einheit={einheit} editMode={editMode} />
													</td>
													<td>
														<OtherBpFieldValues posDef={bpPosDef} data={data} />
													</td>
													{props.showErgebnisse && (
														<>
															<td>
																<RingValue planKey={bpPosDef.planKey} data={data.ringZiel} />
															</td>
															<td>
																<RingValue planKey={bpPosDef.planKey} data={data.ringErgebnis} />
															</td>
															<td>
																<RingValue planKey={bpPosDef.planKey} data={data.ringZE} format={formatPercentTwoDig} />
															</td>

															<td>
																<RingValue planKey={bpPosDef.planKey} data={data.ringZEZeit} format={formatPercentTwoDig} />
															</td>
														</>
													)}
												</tr>
											);
										})}
									</tbody>
								</table>
								<div className="pad-1rem has-text-right">
									{editMode && (
										<>
											<Button type="submit" className="button is-primary " isFormInvalid={!isValid}>
												Speichern
											</Button>
											<Button type="button" className="button is-secondary " onClick={onCancel}>
												Abbrechen
											</Button>
										</>
									)}
								</div>
							</Form>
						);
					}}
				</Formik>
			)}
		</>
	);
});
