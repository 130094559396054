import * as React from 'react';
import classNames from 'classnames';
import { Button } from 'app/components/common/Button';
import { Icon } from 'app/components/common/Icon';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';

interface IProps {
	navbar?: React.ReactNode;
	sideMenu?: React.ReactNode;
	sidePanel?: React.ReactNode;
	actionControls?: React.ReactNode;
	classModifier?: string;
	children?: React.ReactNode;
}

const BaseContainer: React.FC<IProps> = observer(({ children, navbar, sideMenu, sidePanel, classModifier, actionControls }) => {
	const { uiStore } = useStore();
	const mobileOpen = uiStore.showMobileMenu;
	const classes = classNames(
		'app-container',
		classModifier,
		{ 'has-navbar': navbar },
		{ 'has-main-menu': sideMenu },
		{ 'has-side-panel': sidePanel },
		{ 'mobile-menu-open': mobileOpen },
	);

	const burgerClick = () => {
		uiStore.toggleMobileMenu();
	};

	return (
		<div className={classes}>
			{navbar && (
				<div className="navbar">
					<div className="burger">
						<Button onClick={burgerClick}>{mobileOpen ? <Icon iconClass="times" /> : <Icon iconClass="bars" />}</Button>
					</div>
					{navbar}
				</div>
			)}
			{actionControls && <div className="action-controls">{actionControls}</div>}
			{sideMenu && sideMenu}
			<div className={`app-content`}>{children}</div>
		</div>
	);
});

export default BaseContainer;
