import { KvBnrModel } from 'app/models/kv.bnr.model';
import { action, computed } from 'mobx';
import { BaseCachedEntityStore } from './core/base.cached.entity.store';
import { ISavedFilter } from './ui/agt.list.filter.ui.store';

export class KvVBnrAgtFilterModel extends KvBnrModel {
	@computed
	get filters() {
		if (!this.valueString) {
			return [] as ISavedFilter[];
		}
		const j = this.valueString;
		const items = JSON.parse(j)
		return items as ISavedFilter[];

	}

	@action
	setFilters(filters: ISavedFilter[]) {
		this.valueString = JSON.stringify(filters);
	}
}

export class KvBnrAgtFilterStore extends BaseCachedEntityStore<KvVBnrAgtFilterModel> {
	idProp: string = 'id';
	apiPath = 'api/kv_bnr/agt_filter';
	disableFindForStearts = [123];

	createInstances(opts: any): KvVBnrAgtFilterModel {
		return new KvVBnrAgtFilterModel(opts);
	}
}
