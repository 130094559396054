import { Button } from 'app/components/common/Button';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router';
import routes from 'routes';

interface IAdminImportNewButton {
    path: string;
    title: string;
}

export const AdminImportNewButton = observer((props: IAdminImportNewButton) => {
    const history = useHistory();
    const open = () => {
        history.push(routes.ADMINIMPORTS.getPath('new', props.path));
    };
    return (
        <>
            <Button type="button" className="button is-small" onClick={open}>
                {props.title}
            </Button>
        </>
    );
});
