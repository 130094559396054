import BaseContainer from 'app/containers/BaseContainer';
import { observer } from 'mobx-react';
import { MainMenu } from './MainMenu';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useStore } from 'app/context';
import { AdminBnrDiffList } from 'app/components/admin/users/AdminBnrDiffList';
import { AdminBnrDiffFilter } from 'app/components/admin/users/AdminBnrDiffFilter';
import { AdminBnrDiffDetail } from 'app/components/admin/users/AdminBnrDiffDetail';
import { useEffect } from 'react';
import { EnsureAdmin } from './AdminHomeContainer';

export interface AdminBnrDiffParamTypes {
	bnrId?: string;
}

const AdminBnrDiffContainer = observer((props: RouteComponentProps) => {
	const { session, adminBnrDiffUiStore } = useStore();
	const { bnrId } = useParams<AdminBnrDiffParamTypes>();

	const currentUser = session.currentUser;

	const AdminUsersNavbar = observer(() => {
		return (
			<div className="admin-navbar">
				<div className="is-left">
					<strong>BNÄRnderungen</strong>
				</div>
				<div className="is-right"></div>
			</div>
		);
	});

	useEffect(() => {
		if (bnrId) {
			const n = parseInt(bnrId);
			adminBnrDiffUiStore.loadBnrId(n);
		}
	}, [bnrId, adminBnrDiffUiStore._items, adminBnrDiffUiStore.kws, adminBnrDiffUiStore]);

	return (
		<BaseContainer classModifier="admin-container admin-users-container" sideMenu={<MainMenu {...props} />} navbar={<AdminUsersNavbar />}>
			{currentUser && (
				<>
					<div className="table-container">
						<EnsureAdmin />
						<AdminBnrDiffFilter />
						<AdminBnrDiffList />
						<AdminBnrDiffDetail />
					</div>
				</>
			)}
		</BaseContainer>
	);
});

export default AdminBnrDiffContainer;
