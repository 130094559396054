import { useStore } from 'app/context';
import { IUploadOptions } from 'app/stores/bnr.upload.store';
import React, { useCallback, useState } from 'react';
import { DropzoneOptions, FileRejection, useDropzone } from 'react-dropzone';
import { Icon } from './Icon';
import { notify } from './notify';
import ProgressRing from './ProgressRing';
import _ from 'lodash';
import { observer } from 'mobx-react';
import { DocumentVM } from 'app/stores/ui/document.ui.store';

const allowedMimeTypes = [
	'application/zip',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
	'application/octet-stream',
	'application/vnd.ms-excel.sheet.macroEnabled.12',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	'application/vnd.ms-excel',
	'application/x-zip-compressed',
	'text/plain',
	'application/msword',
	'application/pdf',
	'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
	'application/vnd.ms-outlook',
	'image/jpeg',
	'image/png',
	'application/vnd.openxmlformats-officedocument.presentationml.presentation',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

export type FileUploaderProps = Pick<DropzoneOptions, 'minSize' | 'maxSize' | 'maxFiles' | 'multiple'> &
	IUploadOptions & {
		dropZoneText: string;
		uploadingText: string;
		dropZoneActiveText: string;
		// storageFolder: string;
		isButton?: boolean;
		icon?: string;
		inline?: boolean;
		onUploadDone?: () => void;
	};

export const FileUpload = observer((props: FileUploaderProps) => {
	const { uiStore, documentUiStore } = useStore();
	const { dropZoneText, uploadingText, dropZoneActiveText } = props;
	const accept = allowedMimeTypes.join(', ');
	const [progress, setProgress] = useState(0);
	const onDrop = useCallback(
		async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
			if (fileRejections && fileRejections.length > 0) {
				fileRejections.forEach((file: any) => {
					file.errors.forEach((err: any) => {
						switch (err.code) {
							case 'file-too-large':
								notify(`Datei ist zu groß. Dateien dürfen maximal 20MB groß sein.`, '', 'error');
								break;

							case 'file-invalid-type':
								notify(`Ungültiger Dateityp. Es werden nur Office Dateien, PDF, Bilder (JPG/PNG) und Zip Datei unterstützt.`, '', 'error');
								break;
							default:
								notify(`Fehler beim Hochladen: ${err.message}`, '', 'error');
								break;
						}
					});
				});
				return;
			}

			let dokId = props.dokId;

			let createdDokument: DocumentVM | undefined;

			if (!dokId) {
				const title = acceptedFiles.map((f: any) => f.name).join(', ');
				// create a new dokument
				createdDokument = await documentUiStore.createUploadDokument(title, props.agtId, props.planId);
				if (createdDokument) {
					dokId = createdDokument.id;
				} else {
					console.warn('dokument was not createad');
				}
			}

			const totalBytes = _.sumBy(acceptedFiles, 'size');
			let currentBytes = acceptedFiles.map(() => 0);

			const promises = acceptedFiles.map((file: any, i: number) => {
				const uploadOpts = {
					dokId,
					agtId: props.agtId,
					planId: props.planId,
					type: props.type,
				};

				uiStore.setIsUploading(true);
				return documentUiStore.uploadFile(file, uploadOpts, (bytes: number) => {
					currentBytes[i] = bytes;
					let sumCurrent = _.sumBy(currentBytes);
					const progress = (sumCurrent / totalBytes) * 100;
					console.log(progress, sumCurrent, totalBytes);
					setProgress(progress);
				});
			});

			await Promise.all(promises)
				.catch((err) => {
					console.error('Es ist ein unerwartet Fehler beim Hochladen aufgetreten.', err);
					notify('Es ist ein unerwartet Fehler beim Hochladen aufgetreten.', '', 'error');
					if (createdDokument) {
						documentUiStore.deleteById(createdDokument.id);
					}
				})
				.finally(() => {
					uiStore.setIsUploading(false);
					if (props.onUploadDone) {
						props.onUploadDone();
					}
				});
		},
		[documentUiStore, props, uiStore],
	);
	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept });

	return (
		<>
			{uiStore.isUploading && <ProgressRing total={100} value={progress} label="Hochgeladen" />}
			<div {...getRootProps()} className={`file-uploader ${props.isButton ? 'is-button' : ''} ${props.inline ? 'is-inline' : ''}`}>
				<input {...getInputProps()} accept={accept} />
				<div className="drop-area">
					<div className="message">
						{uiStore.isUploading && (
							<div className="overlay">
								<div className="icon">
									<Icon iconClass="spinner fa-pulse" />
								</div>
								<div className="text">{uploadingText}</div>
							</div>
						)}

						{isDragActive && (
							<div className="overlay">
								<div className="icon">
									<Icon iconClass="download" />
								</div>
								<div className="text">{dropZoneActiveText}</div>
							</div>
						)}

						<div className="body">
							<div className="icon">
								<Icon iconClass={props.icon ? props.icon : 'upload'} />
							</div>
							{dropZoneText && <div className="text">{dropZoneText}</div>}
						</div>
					</div>
				</div>
			</div>
		</>
	);
});
