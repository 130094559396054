import Widget, { IWidgetConsumer } from 'app/components/common/Widget';
import { observer } from 'mobx-react';
import { useStore } from 'app/context';
import { useEffect, useState } from 'react';
import { BESTANDPOS_ID } from 'app/stores/ui/agt.bestand.ui.store';
import { formatCurrency, formatDate } from 'app/utils';
import routes from 'routes';
import { WidgetNavigate } from 'app/components/common/WidgetNavigate';
import { AgtBestandPosModel } from 'app/models/berichte/agt.bestand.pos.model';

export const AgenturBestandWidget = observer((props: IWidgetConsumer) => {
	const { agtBestandUiStore, agenturUiStore } = useStore();
	const agt = agenturUiStore.current;

	const { agtProdUiStore } = useStore();
	const [model, setModel] = useState<AgtBestandPosModel>();
	const [rptPath, setRptPath] = useState<string | undefined>();
	useEffect(() => {
		if (agenturUiStore.currentAgtId) {
			setRptPath(routes.AGENTUR.getPath(agenturUiStore.currentAgtId?.toString(), 'berichte', 'bestand', 'bestandundbewertung'));
		} else {
			setRptPath(undefined);
		}
	}, [agenturUiStore.currentAgtId]);

	useEffect(() => {
		if (agt) {
			agtBestandUiStore.find5YearHistoryByAgtId(agt.agtId).then((res) => {
				const sachBestand = res.filter((x) => x.posId === BESTANDPOS_ID)[0];
				setModel(sachBestand);
			});
		}
	}, [agtProdUiStore, agtBestandUiStore, agt]);

	return (
		<>
			{model && (
				<Widget title={'Bestand'} modifier="is-paddingless" action={<WidgetNavigate text={'Zum Bericht'} path={rptPath} />} widgetId={props.widgetKey}>
					<>
						<table className="table last-col-right">
							<tbody>
								<tr>
									<td>
										<strong>
											Sach gesamt <br />
											(ohne AGCS/mit ESA)
										</strong>
									</td>
									<td>Stichtag {formatDate(model.bm, 'DD.MM.YYYY')}</td>
								</tr>
								<tr>
									<td>
										<strong>Beitrag Stich.</strong>
									</td>
									<td>{formatCurrency(model.val.bt)}</td>
								</tr>
								<tr>
									<td>
										<strong>Beitrag Zuwachs</strong>
									</td>
									<td>{formatCurrency(model.zuwachs.bt)}</td>
								</tr>
								<tr>
									<td>
										<strong>Stück Stich.</strong>
									</td>
									<td>{formatCurrency(model.val.st)}</td>
								</tr>
								<tr>
									<td>
										<strong>Stück Zuwachs</strong>
									</td>
									<td>{formatCurrency(model.zuwachs.st)}</td>
								</tr>
							</tbody>
						</table>
					</>
				</Widget>
			)}
		</>
	);
});
