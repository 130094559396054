import { Table } from 'app/components/table/table';
import { AggregrationType, TableModel } from 'app/components/table/table.model';
import { useStore } from 'app/context';
import { AgtProdPosVM } from 'app/models/berichte/agt.prod.pos.vm';
import { formatNum, formatPercent } from 'app/utils';
import { observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { Tooltip, TooltipProps } from 'recharts';
import { ScatterPlotChart, ScatterPlotModel, ScatterPlotVarationModel } from 'app/components/charts/ScatterPlotChart';
import { AgenturNameCell } from 'app/components/shared/AgenturName.cell';
import { BoniStufenCell } from 'app/components/shared/Bonistufe.cell';
import { IGPYear } from './ProdZwischenstand';

export const eigenPlanungSchema = Yup.object().shape({
	data: Yup.array().of(
		Yup.object().shape({
			eigenerVal: Yup.object().shape({
				valueNum: Yup.number()
					.typeError('Nur numerische Werte sind erlaubt')
					.nullable()
					.transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
			}),
		}),
	),
});

export interface IAgenturPositionenEdit {
	tm: TableModel<AgtProdPosVM, number>;
	planKey: string;
	onSubmit: () => void;
}

export const ProdPositionen = observer((props: IGPYear) => {
	const { berichteUiiStore, agtProdUiStore, agenturListUiStore } = useStore();
	const [loaded, setLoadad] = useState<boolean>(false);

	const node = berichteUiiStore.menu.current;
	const planKey = node ? node.currentPath : 'foo';

	const formatPerc = (val: number) => {
		return `${formatPercent(val, 2)}%`;
	};

	const tm = useMemo(() => {
		const numFormat = agtProdUiStore.getNumberFormatByPlanKeyAndYear(planKey, props.year)
		const tm = new TableModel<AgtProdPosVM, number>();
		const cols = [
			// {
			// 	label: 'bm',
			// 	path: 'posVal.bm',
			// },
			{
				label: 'Agentur',
				path: 'agt.name',
				render: AgenturNameCell,
			},
			{
				label: 'Bonistufe',
				path: 'boniStufe',
				render: BoniStufenCell,
			},
			{
				label: 'Zielwert',
				path: 'posVal.ziel',
				format: numFormat,
				agg: [
					{
						path: 'posVal.ziel',
						format: numFormat,
					},
					{
						path: 'posVal.ziel',
						format: numFormat,
						aggType: AggregrationType.avg,
					},
					{
						path: 'posVal.ziel',
						format: numFormat,
						aggType: AggregrationType.count,
					},
				],
			},
			{
				label: 'Eigener Zielwert',
				path: 'eigenerVal.valueNum',
				format: numFormat,
				editable: true,
				agg: [
					{
						path: 'eigenerVal.valueNum',
						format: numFormat,
					},
					{
						path: 'eigenerVal.valueNum',
						format: numFormat,
						aggType: AggregrationType.avg,
					},
					{
						path: 'eigenerVal.valueNum',
						format: numFormat,
						aggType: AggregrationType.count,
					},
				],
			},
			{
				label: 'Ergebnis',
				path: 'posVal.ergebnis',
				format: numFormat,
				agg: [
					{
						path: 'posVal.ergebnis',
						format: numFormat,
					},
					{
						path: 'posVal.ergebnis',
						format: numFormat,
						aggType: AggregrationType.avg,
					},
					{
						path: 'posVal.ergebnis',
						format: numFormat,
						aggType: AggregrationType.count,
					},
				],
			},
			{
				label: 'ZE',
				path: 'posVal.ze',
				format: formatPerc,
				agg: [
					{
						path: 'empty',
						// format: formatPerc,
						aggType: AggregrationType.empty,
					},
					{
						path: 'posVal.ze',
						format: formatPerc,
						aggType: AggregrationType.avg,
					},
					{
						path: 'posVal.ze',
						format: formatNum,
						aggType: AggregrationType.count,
					},
				],
			},
			{
				label: 'ZE (Zeit)',
				path: 'posVal.zeZeit',
				format: formatPerc,
				agg: [
					{
						path: 'empty',
						// format: formatPerc,
						aggType: AggregrationType.empty,
					},
					{
						path: 'posVal.zeZeit',
						format: formatPerc,
						aggType: AggregrationType.avg,
					},
					{
						path: 'posVal.zeZeit',
						format: formatNum,
						aggType: AggregrationType.count,
					},
				],
			},
		];
		tm.setCols(cols);
		tm.sortBy = 'name';
		tm.idProperty = 'agtId';
		tm.idType = 'number';

		return tm;
	}, []);

	const onSubmit = async () => {
		await agtProdUiStore.saveMany(tm.data, planKey, props.year);
	};

	useEffect(() => {
		agtProdUiStore.findByPlanKey(planKey, props.year).then((res) => {
			res = agenturListUiStore.applyFilterOnAgt(res) as AgtProdPosVM[];
			if (berichteUiiStore.selectedAgt) {
				const agtId = berichteUiiStore.selectedAgt.agtId;
				res = res.filter((d) => d.agtId === agtId);
			}
			tm.setRowData(res);
			setLoadad(true);
		});
	}, [tm, setLoadad, agtProdUiStore, planKey, berichteUiiStore.selectedAgt, props.year, agenturListUiStore.filterHasChanged, agenturListUiStore]);

	return (
		<>
			{loaded && (
				<>
					<ProdPositionenTb planKey={planKey} onSubmit={onSubmit} tm={tm} />
				</>
			)}
		</>
	);
});

export const ProdPositionenTb = observer(function (props: IAgenturPositionenEdit) {
	const tm = props.tm;
	const fillColor = (ze: number) => {
		if (!ze) {
			return '#8884d8';
		}

		if (ze < 60) {
			return 'red';
		}
		if (ze < 100) {
			return 'orange';
		}

		return 'green';
	};
	const chartOpts = new ScatterPlotModel({
		tableModel: tm,
		variations: [
			new ScatterPlotVarationModel({
				key: 'jahr',
				labelX: 'Zielerreichung',
				pathX: 'zeJahrLimited',
				pathY: 'posVal.ergebnis',
				buttonLabel: 'Jahr',
				labelY: 'Ergebnis',
				plotColor: fillColor,
				plotColorProp: 'posVal.ze',
				domainX: [60, 140],
			}),
			new ScatterPlotVarationModel({
				key: 'zeit',
				labelX: 'Zielerreichung',
				pathX: 'zeZeitLimited',
				pathY: 'posVal.ergebnis',
				buttonLabel: 'Zeit',
				labelY: 'Ergebnis',
				plotColor: fillColor,
				plotColorProp: 'posVal.zeZeit',
				domainX: [60, 140],
			}),
		],
	});

	return (
		<>
			<div className="table-container">
				<div className="pad-1rem">
					<ScatterPlotChart {...chartOpts} tooltip={<Tooltip content={<AgtPosTooltip />} />} />
				</div>
				<Table tm={props.tm} schema={eigenPlanungSchema} stickyHeader={true} stickyFooter={true} onSubmit={props.onSubmit} />
			</div>
		</>
	);
});

const AgtPosTooltip = (props: TooltipProps<any, any>) => {
	const { active, payload } = props;
	if (active && payload && payload.length) {
		const data = payload[0].payload as AgtProdPosVM;
		return (
			<div className="custom-tooltip">
				<p className="label">{`${data.agt.name} (${data.agt.agtId})`}</p>
				{data.posVal && (
					<>
						<p className="label">Ergebnis: {data.posVal.ergebnis}</p>
						<p className="label">Ziel: {data.posVal.ziel}</p>
						<p className="label">Zielerreichung: {data.posVal.ze}</p>
						<p className="label">ZE*: {data.posVal.zeStern}</p>
						<p className="label">Zeit ZE: {data.posVal.zeZeit}</p>
					</>
				)}
			</div>
		);
	}

	return null;
};
