import { SessionStore } from '../session.store';
import { AgtMenu, MultiAgtMenu, MenuModel } from 'app/models/core/menu.model';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import routes from 'routes';
import { AgenturExtendedVM, AgenturListUiStore } from './agt.list.ui.store';

export class AgenturUiStore {
	agenturListUiStore: AgenturListUiStore;
	session: SessionStore;

	constructor(sessionStore: SessionStore, agenturStore: AgenturListUiStore) {
		makeObservable(this);
		this.agenturListUiStore = agenturStore;
		this.session = sessionStore;
	}

	@computed
	get currentAgtId() {
		if (this.current) {
			return this.current.agtId;
		}
		return undefined;
	}

	@observable current?: AgenturExtendedVM;
	@observable currentPanel?: string;
	@observable currentLevel1?: string;
	@observable currentLevel2?: string;

	// @observable topMenu: MenuModel = AgtMenu;
	@computed
	get topMenu(): MenuModel {
		if (this.session.currentUser?.isMulti) {
			return MultiAgtMenu;
		}

		return AgtMenu;
	}

	@action
	async loadById(agtId?: string, panel?: string, level1?: string, level2?: string) {
		this.currentPanel = panel;
		this.currentLevel1 = level1;
		this.currentLevel2 = level2;
		this.topMenu.setActivePath(panel!);
		if (!agtId) {
			this.current = undefined;
			return;
		}
		let agtIdI = parseInt(agtId);
		if (this.current && this.current.agtId === agtIdI) {
			return;
		}
		await this.agenturListUiStore.load();
		const agt = this.agenturListUiStore.items.find((a) => a.agtId === agtIdI);
		// const agt = ;(agtIdI);
		runInAction(() => {
			this.current = agt;
			if (!agt) {
				return;
			}
			this.topMenu.items.forEach((i) => {
				const agtPath = routes.AGENTUR.getPath(agt!.agtId.toString(), i._path);
				i.updatePath(agtPath);
			});
			this.topMenu.setActivePath(panel!);
		});
	}

	onLogout() {
		this.agenturListUiStore.onLogout();
	}
}
