import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { InputControl } from '../common/InputControl';
import { runInAction } from 'mobx';
import _ from 'lodash';
import { useState } from 'react';
import { CheckboxControl } from '../common/CheckboxControl';
import { Button } from '../common/Button';
import { Icon } from '../common/Icon';

export const TasksFilter = observer(() => {
	const { taskUiStore } = useStore();
	const [showOptions, setShowOptions] = useState(false);

	const onTextChange = (name: string, val: string) => {
		runInAction(() => {
			taskUiStore.currentFilter.fullText = val;
		});
	};

	const onOptionChange = (name: string, val: boolean) => {
		runInAction(() => {
			_.set(taskUiStore.currentFilter, name, val);
			if (val && name === 'isOpen') {
				_.set(taskUiStore.currentFilter, 'isClosed', !val);
			}
			if (val && name === 'isClosed') {
				_.set(taskUiStore.currentFilter, 'isOpen', !val);
			}
		});
	};

	const removeOption = (name: string) => {
		runInAction(() => {
			_.set(taskUiStore.currentFilter, name, false);
		});
	};
	return (
		<div className="documents-filter">
			<div className="text-input">
				<InputControl name="fullText" value={taskUiStore.currentFilter.fullText} onChange={onTextChange} debounceTime={500} placeholder={'Textsuche'} />
			</div>
			<div className="toggle">
				<Button className="button" onClick={() => setShowOptions(true)} data-tooltip="Filtern öffnen" data-tooltip-position="bottom">
					Filter öffnen
				</Button>
			</div>
			<div className="filter-count">{taskUiStore.currentItems.length} Maßnahmen</div>

			{!showOptions && (
				<div className="active-filters">
					{taskUiStore.currentFilter.isOpen && (
						<Button className="button is-small is-active-filter-button" onClick={() => removeOption('isOpen')}>
							<Icon iconClass="times" />
							<span>Offen</span>
						</Button>
					)}
					{taskUiStore.currentFilter.isClosed && (
						<Button className="button is-small is-active-filter-button" onClick={() => removeOption('isClosed')}>
							<Icon iconClass="times" />
							<span>Erledigt</span>
						</Button>
					)}
				</div>
			)}

			{showOptions && (
				<>
					<div className="generic-overlay"></div>
					<div className="filter-options-list">
						<CheckboxControl alignment="left" name="isOpen" value={taskUiStore.currentFilter.isOpen} onChange={onOptionChange} label={'Offen'} />
						<CheckboxControl alignment="left" name="isClosed" value={taskUiStore.currentFilter.isClosed} onChange={onOptionChange} label={'Erledigt'} />
						<CheckboxControl
							alignment="left"
							name="hideArchived"
							value={taskUiStore.currentFilter.hideArchived}
							onChange={onOptionChange}
							label={'Archiviert Maßnahmen ausblenden'}
						/>
						<Button className="button is-icon close-filters" onClick={() => setShowOptions(false)}>
							<Icon iconClass="times" />
						</Button>
					</div>
				</>
			)}
		</div>
	);
});
