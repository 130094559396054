import { BrandenBurgerTorData, ThemaModel, ThemenGroupModel } from 'app/models/brandenburger-tor/brandeburger.tor.data';
import { BaseCachedEntityStore } from './core/base.cached.entity.store';
import { GPMAXYEAR } from './ui/berichte.ui.store';

export class AgtMultiThemaModel {
	constructor(opts: any) {
		this.group = opts.group;
		this.key = opts.key;
		this.label = opts.label;
	}

	group: string;
	key: string;
	label: string;
}

export class AgtJahresthemenModel {
	constructor(opts: any, groups: ThemenGroupModel[]) {
		this.agtId = opts.agtId;
		this.year = opts.year;
		this._themen = JSON.parse(opts.themen);

		this._themen.forEach((key) => {
			let thema: AgtMultiThemaModel;
			groups.forEach((g) => {
				g.children.forEach((t) => {
					if (t.key === key && t.showMulti) {
						thema = new AgtMultiThemaModel({
							key,
							label: t.title,
							group: g.title,
						});
						this.themen.push(thema);
					}
				});
			});
		});
	}

	agtId: number;
	year: number;
	_themen: string[] = [];
	themen: AgtMultiThemaModel[] = [];
}

export class AgenturJahresthemenStore extends BaseCachedEntityStore<AgtJahresthemenModel> {
	apiPath = 'api/agt/jahresthemen/' + GPMAXYEAR;
	idProp: string = 'agtId';
	createInstances(opts: any): AgtJahresthemenModel {
		return new AgtJahresthemenModel(opts, this.brandenBurgerTorGroupsByYear);
	}

	get brandenBurgerTorGroupsByYear() {
		return BrandenBurgerTorData.filter((i) => i.year === GPMAXYEAR) as ThemenGroupModel[];

		//     let themen:ThemaModel[] = [];
		//     groups.forEach(g =>  {
		//         const f = (g.children as ThemaModel[]).filter(t=>t.showMulti);
		//         themen=   themen.concat(f)
		//     })

		//     return themen;
	}
}
