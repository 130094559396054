import { Einheit } from 'app/models/core/einheit.model';
import { BnrGpPosModel } from '../bnr.gp.pos.model';

const posDef: BnrGpPosModel[] = [];

posDef.push(new BnrGpPosModel({ year: 2024, posId: 2235001, group: 'vss', posText: 'Weiterbildungspfl. BNR ', planKey: 'idd', einheit: Einheit.minuten }));
posDef.push(new BnrGpPosModel({ year: 2024, posId: 2235002, group: 'vss', posText: 'Weiterbildungsz. Fonds', planKey: 'weiterbildungfonds', einheit: Einheit.minuten }));
posDef.push(new BnrGpPosModel({ year: 2024, posId: 2235021, group: 'vss', posText: 'NMZ o. P EUR', planKey: 'nettomittelzufluss', einheit: Einheit.lfNST }));

posDef.push(new BnrGpPosModel({ year: 2024, posId: 2235042, group: 'eh', posText: 'Fonds Bew+FRVZ oGeno 100%', planKey: 'fondsbewfrvz100', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2024, posId: 2235041, group: 'eh', posText: 'Fonds Bew+FRVZ oGeno 90%', planKey: 'fondsbewfrvz90', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2024, posId: 2235044, group: 'eh', posText: 'Leben KnP Bew oGeno 100%', planKey: 'lebelknpbew100', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2024, posId: 2235043, group: 'eh', posText: 'Leben KnP Bew oGeno 90%', planKey: 'lebelknpbew90', einheit: Einheit.bew }));

posDef.push(new BnrGpPosModel({ year: 2024, posId: 2235101, group: 'neh', posText: 'Fonds Bew o. Geno', planKey: 'wb_fondsbewertung', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2024, posId: 2235111, group: 'neh', posText: 'Firmenfonds NMZ o. Geno ', planKey: 'wb_firmenfondsogeno', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2024, posId: 2235121, group: 'neh', posText: 'Leben KnP80 Bew Digi', planKey: 'wb_lebenknpbewoze', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2024, posId: 2235131, group: 'neh', posText: 'Leben EB Digi o. Geno', planKey: 'wb_lebenebbew', einheit: Einheit.bew }));

posDef.push(new BnrGpPosModel({ year: 2024, posId: 3835021, group: 'gpplus', posText: 'Wiederanlage', planKey: 'wb_wiederanlage', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2024, posId: 3835031, group: 'gpplus', posText: 'Firmen Fonds Bew o.Geno', planKey: 'firmenfondsbew', einheit: Einheit.bew }));

export const KmePositionen = posDef;
