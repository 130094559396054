import { observer } from 'mobx-react';
import { Icon } from 'app/components/common/Icon';
import { SegementStates, SegmentState } from 'app/models/segment/segment.model';
import { AgenturModel } from 'app/models/agentur.model';
import { useStore } from 'app/context';
import moment from 'moment';

interface ISegmentName {
	agt: AgenturModel;
	showCalculated?: boolean;
}
export const SegmentName = observer((props: ISegmentName) => {
	const { agtSegmentUiStore } = useStore();

	const showCalculated = props.showCalculated === undefined ? false : props.showCalculated;

	const segment = agtSegmentUiStore.items.find((x) => x.agtId === props.agt.agtId && x.year === moment().year());
	if (!segment) return null;

	let state = SegementStates.find((s) => s.state === segment.displaySegment);
	if (showCalculated) {
		state = SegementStates.find((s) => s.state === segment.calcSegement);
	}

	const label = state?.label;

	return <>{label}</>;
});

interface ISegmentTag {
	agt: AgenturModel;
	showCalculated?: boolean;
}
export const SegmentTag = observer((props: ISegmentTag) => {
	const { agtSegmentUiStore } = useStore();
	const segment = agtSegmentUiStore.items.find((x) => x.agtId === props.agt.agtId && x.year === moment().year());
	if (!segment) return null;

	const { showCalculated } = props;
	const hasBeenEdited = showCalculated === false && segment.eigenerSegement !== SegmentState.calcuated;

	return (
		<div className={`segment-table-tag ${showCalculated ? 'is-calculated' : ''} is-${showCalculated ? segment.calcSegement : segment.displaySegment} ${hasBeenEdited ? 'is-custom' : ''}`}>
			<div className="segment-icon">
				<Icon iconClass={hasBeenEdited ? 'marker' : 'calculator'} faModifier="far" />
			</div>
			<div className="segment-value">
				<SegmentName agt={props.agt} showCalculated={showCalculated} />
			</div>
		</div>
	);
});
