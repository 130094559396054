import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import FormField from 'app/components/form/FormField';
import { observer } from 'mobx-react';
import { useStore } from 'app/context';
import { runInAction } from 'mobx';
import React, { useEffect, useState } from 'react';
import { ISelectOpt, SelectField } from 'app/components/common/Select';
import { FindSteart, Stearts } from 'app/models/core/steart.model';
import { Button } from 'app/components/common/Button';
import { Modal } from 'app/components/Modal';
import moment from 'moment';
import { CustomPersonalModel } from 'app/models/personal/custom.personal.model';

interface IPersonsalViewProps {
	id?: string;
	onChange: () => void;
	onClose: () => void;
}

const customMASchema = Yup.object({
	name: Yup.string().required('Bitte einen Namen eingeben').default(''),
	steart: Yup.number().required().default(0).moreThan(0, 'Bitte eine Stellenart auswählen'),
	bnr: Yup.number().required('Bitte Bnr eingeben').default(0).default(0).moreThan(-1, 'Bitte eine BNR eingeben'),
	gebDate: Yup.string().required('Bitte Geburtsdatum eingeben'),
	beginn: Yup.string().required('Bitt eine Beginndatum eingeben'),
}).required();

type CustomMAS = Yup.InferType<typeof customMASchema>;

export const AgenturPersonEditModal = observer((props: IPersonsalViewProps) => {
	const { agenturUiStore, uiStore, personalCustomStore, personalUiStore, session } = useStore();
	const agt = agenturUiStore.current;
	const modalId = uiStore.modalIds.mitarbeiterEdit;

	const [personal, setPersonal] = useState<CustomPersonalModel | undefined>();

	useEffect(() => {
		if (props.id) {
			personalCustomStore.findById(props.id).then((p) => {
				setPersonal(p);
			});
		} else {
			const cp = new CustomPersonalModel({
				agtId: agt!.agtId,
				bnrId: session.currentUser!.bnrId,
				name: '',
				bnr: 0,
				gebdate: new Date(),
				beginn: new Date(),
			});
			setPersonal(cp);
		}
	}, [personalCustomStore, props.id, session.currentUser, agt]);

	const handleSubmit = async (values: CustomMAS) => {
		if (!personal) {
			return;
		}
		personal.name = values.name.trim();
		personal.steart = FindSteart(parseInt(values.steart.toString()));
		personal.bnr = values.bnr;
		// avoid day being off on the server by adding a few hours
		personal.gebdat = moment(values.gebDate).startOf('day').add(6, 'hours').toDate();
		personal.beginn = moment(values.beginn).toDate();

		await personalCustomStore.save(personal);

		runInAction(() => {
			personalUiStore.loaded = false;
		});

		runInAction(() => {
			props.onChange();
			uiStore.hideModal(modalId);
		});
	};

	const formName = modalId;

	const onClose = () => {
		props.onClose();
		runInAction(() => {
			uiStore.hideModal(modalId);
		});
	};

	const maStearts = Stearts.filter((s) => s.group === 'mitarbeiter').map((s) => {
		return { value: s.id, label: s.longName } as ISelectOpt;
	});

	return (
		<>
			<Modal modalId={modalId} title="Mitarbeiter hinzufügen" onClose={onClose}>
				{personal && (
					<Formik
						validationSchema={customMASchema}
						validateOnMount={true}
						initialValues={{
							name: personal!.name,
							steart: personal!.steart.id,
							bnr: personal!.bnr,
							gebDate: personal!.gebdat ? moment(personal.gebdat).format('YYYY-MM-DD') : moment().subtract(20, 'years').format('YYYY-MM-DD'),
							beginn: personal!.beginn ? moment(personal.beginn).format('YYYY-MM-DD') : moment().startOf('month').format('YYYY-MM-DD'),
						}}
						onSubmit={handleSubmit}
					>
						{({ errors, touched, isValid }) => {
							return (
								<Form id={formName}>
									<div className="pad-1rem">
										<FormField className="is-inline" label={'Name'} error={errors.name} touched={touched.name}>
											<Field type="text" name="name" />
										</FormField>
										<FormField className="is-inline" label={'Stellenart'} error={errors.steart} touched={touched.steart}>
											{/* <Field type="text" name="steart" /> */}
											<SelectField name="steart" opts={maStearts} />
										</FormField>
										<FormField className="is-inline" label={'BNR'} error={errors.bnr} touched={touched.bnr}>
											<Field type="number" name="bnr" />
										</FormField>
										<FormField className="is-inline" label={'Geburtsdatum'}>
											<Field type="date" name="gebDate" />
										</FormField>
										<FormField className="is-inline" label={'Eintritt'}>
											<Field type="date" name="beginn" />
										</FormField>
									</div>
									<div className="modal-footer">
										<Button type="submit" className="button is-primary " disabled={!isValid}>
											Speichern
										</Button>
										<Button type="submit" className="button is-secondary" onClick={onClose}>
											Abbrechen
										</Button>
									</div>
								</Form>
							);
						}}
					</Formik>
				)}
			</Modal>
		</>
	);
});
