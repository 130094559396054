import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { WMHeading } from './PlainTextWochenmail';
import { IWMStyle, WMErrungenschaftBlock, WmTableRow } from './Wochenmail';

export const WochenmailBnrErrungenschaften = observer((props: IWMStyle) => {
	const { wmStyles, isText } = props;
	const { wochenmailtUiStore } = useStore();
	const title = `Ihre Neuen Errungenschaften`;
	return (
		<>
			{wochenmailtUiStore.loaded && wochenmailtUiStore.showBnrErrugenshaften && (
				<>
					{isText ? (
						<>
							<WMHeading title={title} />
							{wochenmailtUiStore.changedBnrErrungenschaften.map((e) => (
								<>
									* {e.def.modal.title}
									<br />
								</>
							))}
							<br />
							<br />
						</>
					) : (
						<WmTableRow>
							<table width={'100%'} style={wmStyles.innerTableStyle}>
								<tbody>
									<tr>
										<td style={wmStyles.headingTdStyle}>
											<h2 style={{ ...wmStyles.headingStyle, textAlign: 'center' }}>{title}</h2>
										</td>
									</tr>
									<tr>
										<td>
											{wochenmailtUiStore.changedBnrErrungenschaften.map((e) => (
												<WMErrungenschaftBlock item={e} key={e.def.key} />
											))}
										</td>
									</tr>
								</tbody>
							</table>
						</WmTableRow>
					)}
				</>
			)}
		</>
	);
});
