import { computed } from 'mobx';
import { AgenturModel } from '../agentur.model';
import { CoronaCheckFormItem } from './corona.check.form.item';
import { CoronaFormItems } from './corona.form.items';
import { CoronaKVModel } from './corona.kv.model';

export class CoronaCheckVM {
	constructor(agt: AgenturModel, items: CoronaKVModel[]) {
		this.agtId = agt.agtId;
		this.agt = agt;
		this.items = CoronaFormItems.map((x) => new CoronaCheckFormItem(x, items));
		this.items.forEach((i) => i.data.agtId === agt.agtId);
	}
	agtId: number;
	agt: AgenturModel;
	items: CoronaCheckFormItem[];

	@computed
	get countNotStarted() {
		return this.items.filter((i) => i.data.notStarted).length;
	}

	@computed
	get countInProgress() {
		return this.items.filter((i) => i.data.inProgress).length;
	}

	@computed
	get countCompleted() {
		return this.items.filter((i) => i.data.completed).length;
	}
}
