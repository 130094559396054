import Config from 'Config';
import { computed, makeObservable, observable } from 'mobx';
import moment from 'moment';
import { BaseCachedEntityStore } from './core/base.cached.entity.store';
import { SendDocPerEmailData } from './ui/document.ui.store';

export class TaskThema {
	constructor(opts: any) {
		makeObservable(this);
		this.key = opts.key;
		this.title = opts.title;
	}
	@observable
	key: string;
	@observable
	title: string;
}

export enum TaskStatus {
	OPEN = 0,
	CLOSED = 1,
}

export class BnrTaskModel {
	constructor(opts: any) {
		makeObservable(this);
		this.agtId = opts.agtId;
		this.bnrId = opts.bnrId;
		this.body = opts.body;
		this.category = opts.category;
		this.created = new Date(opts.created);
		this.modified = new Date(opts.modified);
		if (opts.dueDate || opts.dueDate === 0) {
			this.dueDate = moment(opts.dueDate).toDate();
		} else {
			this.dueDate = moment(new Date()).add(7, 'days').toDate();
		}
		this.id = opts.id;
		this.modified = opts.modified;
		this.planId = opts.planId;

		if (opts.planYear) {
			this.planYear = parseInt(opts.planYear);
		}
		if (opts.thema) {
			const j = JSON.parse(opts.thema);
			this.thema = new TaskThema(j);
		}
		this.reallyEditable = opts.reallyEditable;
		if (opts.status) {
			this.status = opts.status;
		}
		this.text = opts.text;
		this.type1 = opts.type1;
		this.type2 = opts.type2;
		this.refId = opts.refId;
		this.transferDate = opts.transferDate;
		this.transferId = opts.transferId;
		this.transferRefId = opts.transferRefId;
		this.transferText = opts.transferText;
		if (this.modified) {
			const time = moment(this.modified);
			if (time.diff(moment(), 'months') < -12) {
				this.archived = true;
			}
		}
	}

	@computed
	get noDueDate() {
		if (this.dueDate && this.dueDate.getFullYear() === 1970) {
			return true;
		}
		return false;
	}

	@computed
	get kwDueDate() {
		if (this.noDueDate) {
			return undefined;
		}
		return moment(this.dueDate).format('YYYYww');
	}

	archived: boolean = false;
	@observable
	agtId: number;
	@observable
	bnrId: number;
	@observable
	body: string;
	@observable
	category: string;
	created: Date;
	modified: Date;
	@observable
	dueDate?: Date;
	id: string;
	@observable
	planId?: string;
	@observable
	planYear?: number;
	reallyEditable: boolean;
	@observable
	status: TaskStatus = TaskStatus.OPEN;
	@observable
	text: string;
	@observable
	thema?: TaskThema; // JSON "{\"title\":\"Anlass- und Leadmanagement\",\"key\":\"leadmanagement\"}"

	@computed
	get themaKey() {
		if (this.thema) {
			return this.thema.key;
		}
		return undefined;
	}

	@computed
	get yearModified() {
		return moment(this.modified).year();
	}

	type1?: string;
	type2?: string;
	refId?: string;
	transferDate?: Date;
	transferId?: string;
	transferRefId?: string;
	transferText?: string;

	serialize() {
		return {
			agtId: this.agtId,
			bnrId: this.bnrId,
			body: this.body,
			category: this.category,
			created: this.created,
			modified: this.modified,
			dueDate: this.dueDate ? this.dueDate : undefined,
			id: this.id,
			planId: this.planId,

			status: this.status,
			text: this.text,
			thema: this.thema ? JSON.stringify(this.thema) : undefined,
			// type1?: this.type1?,
			// type2?: this.type2?,
		};
	}
}

export class BnrTaskStore extends BaseCachedEntityStore<BnrTaskModel> {
	apiPath = 'api/tasks';
	idProp: string = 'id';
	disableFindForStearts = [123];

	createInstances(opts: any): BnrTaskModel {
		return new BnrTaskModel(opts);
	}

	async downloadDocAsPdf(id: string, fileName: string) {
		const url = '/api/pdf/task/' + id + '?c=' + new Date().getTime();
		// http://localhost:8090/api/pdf/doc/df674db4-437f-4aba-9cff-62fcadf426e7?c=1624021123829&exportType=kbv&_=1624018672467
		const res = await this.get(url, false);
		// const pdfUrl = Config.API_BASE_URL + '/api/pdf/download/' + res.data.outPutFile + '/pdf?c=' + new Date().getTime();
		const pdfUrl = new URL(`/api/pdf/download/${res.data.outPutFile}/pdf?c=${new Date().getTime()}`, Config.API_BASE_URL).toString();
		return pdfUrl;
	}

	async sendDocumentPerEmail(id: string, data: SendDocPerEmailData) {
		const url = id + '/send/';
		return await this.post(url, data);
	}
}
