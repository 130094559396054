import * as Sentry from '@sentry/react';
import { notify } from 'app/components/common/notify';
import { CurrentUserModel } from 'app/models/auth/current.user.model';
import { LoginRequestModel } from 'app/models/auth/login.request.model';
import { AdminMenu, MenuModel } from 'app/models/core/menu.model';
import { action, observable, runInAction } from 'mobx';
import { BaseApiStore } from './core/base.api.store';
import { SessionStore } from './session.store';
import { UiStore } from './ui.store';

export class AuthStore extends BaseApiStore {
	apiPath = 'api/auth';

	constructor(session: SessionStore, uiStore: UiStore) {
		super(session);
		this.uiStore = uiStore;

		const refreshToken = localStorage.getItem('refreshToken');
		const token = localStorage.getItem('token');
		const isReadonlyToken = localStorage.getItem('isReadonlyToken');
		if (refreshToken) {
			this.session.refreshToken = refreshToken;
		}
		if (token) {
			this.session.token = token;
		}

		if (isReadonlyToken) {
			this.session.isReadonlyToken = true;
		}
	}
	uiStore: UiStore;

	@observable
	settingUp: boolean = false;

	@action
	async setup() {
		if (!this.session.token || this.session.isReadonlyToken) {
			return;
		}
		this.settingUp = true;
		await this.callRefreshToken();

		const user = await this.get('api/user/current', false);
		if (user && user.data) {
			runInAction(() => {
				this.session.currentUser = new CurrentUserModel(user.data);
				// log UUID in sentry
				Sentry.setUser({ id: this.session.currentUser.userId });
				if (this.session.currentUser.isAdmin) {
					this.uiStore.mainMenu = AdminMenu;
				} else {
					this.uiStore.mainMenu = this.session.currentUser.steart.menu;
					this.uiStore.mainMenu.items = this.uiStore.mainMenu.items.filter(
						(i) => i.hideForStearts.length === 0 || !i.hideForStearts.includes(this.session.currentUser!.steart.id),
					);

					this.uiStore.mainMenu.items = this.uiStore.mainMenu.items.filter(
						(i) => i.showForStearts.length === 0 || i.showForStearts.includes(this.session.currentUser!.steart.id),
					);

					if (process.env.REACT_APP_ENV === 'production') {
						this.startIdleTimer();
					}
				}
			});
		}
		this.settingUp = false;
	}

	async startIdleTimer() {
		setInterval(() => {
			if (this.secondsSinceLastApiCall > 60 * 60 * 3) {
				// 3 stunden
				notify('Sitzung abgelaufen', 'Bitte erneut anmelden.', 'info');
				setTimeout(() => {
					this.logout();
				}, 2000);
			}
		}, 5000);
	}

	@action
	async logout() {
		this.session.clearLocalStorage();
		this.session.currentUser = undefined;
		this.session.setIsSuperAdmin(false);
		this.uiStore.mainMenu = new MenuModel([]);
		window.location.reload();
	}

	@action
	async logoutForTest() {
		this.session.clearLocalStorage();
		this.session.currentUser = undefined;
		this.uiStore.mainMenu = new MenuModel([]);
		//window.location.reload();
		//
	}

	async login(data: LoginRequestModel) {
		const res = await this.post('login', data);
		if (res.data && res.data.token && res.data.refreshToken) {
			this.session.clearLocalStorage();
			this.session.token = res.data.token;
			this.session.refreshToken = res.data.refreshToken;
			this.session._setTokensToLocalStorage(res.data.token, res.data.refreshToken);
			await this.setup();
		}
		return res;
	}
	async superLogin(data: LoginRequestModel) {
		const res = await this.post('super-login', data);
		if (res.data && res.data.token && res.data.refreshToken) {
			this.session.setIsSuperAdmin(true);
			this.session.token = res.data.token;
			this.session.refreshToken = res.data.refreshToken;
			this.session._setTokensToLocalStorage(res.data.token, res.data.refreshToken);
			await this.setup();
		}
	}
	async register(bensl: string) {
		bensl = bensl.trim();
		const res = await this.post('register', { username: bensl });
		if (!res.data && res.status) {
			switch (res.status) {
				case 400:
					res.message = 'Benutzer existiert nicht';
					break;
				case 422:
					res.message = 'Benutzer darf nicht leer sein';
					break;
				case 404:
					res.message = 'Unbekannter Fehler';
					break;
				default:
					break;
			}
		}
		return res;
	}

	async setPassword(password: string) {
		const res = await this.post('set-password', { password });
		if (!res.data && res.status) {
			switch (res.status) {
				case 422:
					res.message = 'Passwort muss mindestens 8 Zeichen umfassen, Groß- und Kleinbuchstaben beinhalten und mindestens 1 Sonderzeichen enthalten';
					break;
				case 200:
					localStorage.removeItem('isReadonlyToken');
					break;
				default:
					if (!res.message) {
						res.message = 'Passwort setzen fehlgeschlagen';
					}
					break;
			}
		} else {
		}
		return res;
	}

	async forgotPassword(username: string) {
		const res = await this.post('reset-password', { username });
		if (!res.data && res.status) {
			switch (res.status) {
				case 422:
					res.message = 'Passwort muss mindestens 8 Zeichen umfassen, Groß- und Kleinbuchstaben beinhalten und mindestens 1 Sonderzeichen enthalten';
					break;
				case 200:
					localStorage.removeItem('isReadonlyToken');
					break;
				default:
					if (!res.message) {
						res.message = 'Passwort setzen fehlgeschlagen';
					}
					break;
			}
		} else {
		}
		return res;
	}

	async verifyPin(bensl: string, pin: string) {
		const res = await this.post('verifypin', { bensl, pin });
		if (!res.data && res.status) {
			switch (res.status) {
				case 400:
					if (res.message === 'LoginLocked') {
						res.message = 'Login ist vorübergehend gesperrt\nVersuche es später noch einmal';
					} else {
						debugger;
					}

					break;
				case 401:
					res.message = 'PIN falsch';
					break;

				default:
					if (!res.message) {
						res.message = 'Passwort setzen fehlgeschlagen';
					}
					break;
			}
		}
		if (res.data && res.data.token && res.data.refreshToken) {
			this.session.isReadonlyToken = false;
			this.session.token = res.data.token;
			this.session.refreshToken = res.data.refreshToken;
			this.session._setTokensToLocalStorage(res.data.token, res.data.refreshToken);
			await this.setup();
		}
		return res;
	}

	async readOnlyLogin(id: string, token: string) {
		var queryParams = '?id=' + id + '&token=' + token;
		const res = await this.get('login-readonly' + queryParams);

		if (res.data && res.data.token && res.data.refreshToken) {
			this.session.token = res.data.token;
			this.session.refreshToken = res.data.refreshToken;
			localStorage.setItem('isReadonlyToken', 'true');
			this.session._setTokensToLocalStorage(res.data.token, res.data.refreshToken);
		}
		return res;
	}
}
