import { observer } from 'mobx-react';
import { useStore } from 'app/context';
import { runInAction } from 'mobx';
import { Modal } from 'app/components/Modal';
import { Button } from 'app/components/common/Button';
import { DocumentVM } from 'app/stores/ui/document.ui.store';
import React, { useEffect, useState } from 'react';
import FormField from '../form/FormField';
import { notify } from '../common/notify';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { AgtPlanExportOpts } from 'app/stores/ui/agt.plan.export.ui.store';
import { TaskVM } from 'app/stores/ui/task.ui.store';
import { Icon } from '../common/Icon';
const isEmailSchema = Yup.string().email();

export const WarnNoExcel = () => {
	return (
		<div className="note-box content">
			<p>
				<Icon iconClass="info-circle" />
			</p>
			<div className="note-message">
				<p>
					<strong>Aktuell ist der direkte Excel-Versand des Planungstools deaktiviert.</strong>
					<br />
					Bitte exportieren Sie das Planungstool über die Exportfunktion, um es anschließend mittels Outlook an den GS-Innendienst versenden zu können.
				</p>
			</div>
		</div>
	);
};

const isEmpty = (v: any) => {
	return !v || v === '';
};

const sendEmailFormSchema = Yup.object({
	to: Yup.string().email('Ungültige Email-Addresse').required('Eingabe einer Email ist erforderlich').default(''),
	cc: Yup.string().test({
		name: 'emails',
		test: function (value: any) {
			if (isEmpty(value)) {
				return true;
			}
			const firstInvalidEmail = value
				.split(';')
				.map((email: string) => email.trim())
				.filter((v: any) => !isEmpty(v))
				.find((v: any) => !isEmailSchema.isValidSync(v));

			return !firstInvalidEmail
				? true
				: this.createError({
						message: `Die Email-Addresse '${firstInvalidEmail}' is ungültig.`,
				  });
		},
	}),
	selfCC: Yup.boolean().required().default(false),
	subject: Yup.string().required('Ein Betreff ist erforderlich').default(''),
	text: Yup.string().required('Ein Nachricht sollte eingegeben werden').min(5, 'Nachricht sollte mindestens aus 5 Zeichen bestehen').default(''),
}).required();

type SendEmail = Yup.InferType<typeof sendEmailFormSchema>;

interface IDocumentSendModalProps {
	modalId?: string;
	exportOpts?: AgtPlanExportOpts;
	task?: TaskVM;
}

export const DocumentSendModal = observer((props: IDocumentSendModalProps) => {
	const { uiStore, documentUiStore, agtPlanExportUiStore, taskUiStore } = useStore();
	const modalId = props.modalId ? props.modalId : uiStore.modalIds.documentSendModal;
	const [doc, setDoc] = useState<DocumentVM>();
	useEffect(() => {
		setDoc(documentUiStore.currentModalDoc);
	}, [documentUiStore.currentModalDoc]);

	let subject = '';
	let text = '';
	if (props.exportOpts) {
		subject = props.exportOpts.title;
	}
	if (props.task) {
		subject = props.task.task.text;
		text = props.task.task.body;
	}

	const handleSubmit = async (values: SendEmail) => {
		if (!doc && !props.exportOpts && !props.task) {
			console.log('oops');
			return;
		}
		let cc = values.cc;
		if (values.selfCC) {
			cc += ';' + documentUiStore.session.currentUser!.email;
		}
		const mailData = {
			to: values.to,
			cc: cc ? cc : '',
			subject: values.subject,
			body: values.text,
		};

		if (props.exportOpts) {
			await agtPlanExportUiStore.multiDocSend(mailData, props.exportOpts);
		}
		if (doc) {
			await documentUiStore.sendDocumentPerEmail(doc.doc.id, mailData);
		}
		if (props.task) {
			await taskUiStore.sendDocumentPerEmail(props.task.id, mailData);
		}

		notify('Email wurde versandt', '', 'info');

		runInAction(() => {
			// props.onConfirm();
			uiStore.hideModal(modalId);
		});
	};
	const onCancel = () => {
		runInAction(() => {
			documentUiStore.setCurrentModalDoc(undefined);
			uiStore.hideModal(modalId);
		});
	};

	return (
		<>
			<Modal modalId={modalId} title={'Dokument per E-Mail senden'} onClose={onCancel}>
				<div>
					<Formik
						validationSchema={sendEmailFormSchema}
						initialValues={{
							to: '',
							selfCC: false,
							cc: '',
							subject: subject,
							text: text,
						}}
						onSubmit={handleSubmit}
					>
						{({ errors, touched, isValid }) => {
							return (
								<Form>
									<div className="grid">
										{doc && doc.isAp && <WarnNoExcel />}

										<FormField error={errors.to} touched={touched.to} label="An *">
											<Field type="text" name="to" autoComplete="email" placeholder="Email-Addresse" />
										</FormField>
										<FormField error={errors.cc} touched={touched.cc} label="CC">
											<Field type="text" name="cc" placeholder="Email-Addressen mit Strichpunkt getrennt" />
										</FormField>

										<FormField error={errors.selfCC} touched={touched.selfCC} label="Selbst in CC setzen">
											<Field type="checkbox" name="selfCC" placeholder="Betreff" />
										</FormField>
										<FormField error={errors.subject} touched={touched.subject} label="Betreff *">
											<Field type="text" name="subject" placeholder="Betreff" />
										</FormField>

										<FormField error={errors.text} touched={touched.text} label="Text *">
											<Field className="input" type="text" as="textarea" name="text" placeholder="Nachricht" />
										</FormField>
									</div>
									<div className="modal-footer">
										<Button type="button" className="button is-secondary" onClick={onCancel}>
											Abbrechen
										</Button>
										<Button type="submit" className="button is-primary ">
											Senden
										</Button>
									</div>
								</Form>
							);
						}}
					</Formik>
				</div>
			</Modal>
		</>
	);
});
