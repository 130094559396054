import { parseDateOrUndef } from 'app/utils';
import { computed } from 'mobx';
import { FindSteart, SteartModel } from '../core/steart.model';
import { PersonalVM } from './personal.vm';

export class CustomPersonalModel {
	constructor(opts: any) {
		this.agtId = opts.agtId;
		this.beginn = new Date(opts.beginn);
		this.bnr = opts.bnr;
		this.bnrId = opts.bnrId;
		this.ende = parseDateOrUndef(opts.ende);
		this.gebdat = parseDateOrUndef(opts.gebdat);
		this.id = opts.id;
		this.jubdat = parseDateOrUndef(opts.jubdat);
		this.kat = opts.kat;
		this.lebend = opts.lebend;
		this.name = opts.name;
		this.steart = FindSteart(opts.stellenart);
	}

	agtId: number;
	beginn: Date;
	bnr: number;
	bnrId: number;
	ende?: Date;
	gebdat?: Date;
	id: string;
	jubdat?: Date;
	kat: string;
	lebend: number;
	name: string;
	steart: SteartModel;

	@computed
	get vorname() {
		const n = this.name;
		if (!n) {
			return '';
		}
		return n.split(' ')[0];
	}

	@computed
	get nachname() {
		const n = this.name;
		if (!n) {
			return '';
		}
		const s = n.split(' ');
		return s[s.length - 1];
	}
	serialize() {
		return {
			agtId: this.agtId,
			beginn: this.beginn,
			bnrId: this.bnrId,
			bnr: this.bnr,
			name: this.name,
			gebdat: this.gebdat,
			stellenart: this.steart.id,
		};
	}

	toPersonalModel(): PersonalVM {
		return new PersonalVM({
			kat: 'custom',
			id: this.id,
			agtId: this.agtId,
			bnrId: this.bnrId,
			gebDat: this.gebdat,
			vorname: this.vorname,
			nachname: this.nachname,
			jubDat: this.jubdat,
			beitrittDat: this.beginn,
			steart: this.steart,
		});
	}
}
