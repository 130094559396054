import { observer } from 'mobx-react';
import Widget, { IWidgetConsumer } from './common/Widget';
import { Link } from 'react-router-dom';
import routes from 'routes';

export const MultisFeedbackWidget = observer((props: IWidgetConsumer) => {
	return (
		<>
			<Widget title={'Ihre Meinung ist uns wichtig'} modifier="is-highlight" widgetId={props.widgetKey}>
				<div className="content">
					<p>
						Helfen Sie uns mit, die DVS nach Ihren Anforderungen zu gestalten. Als Nutzer der DVS ist Ihr Feedback von ausordentlicher Wichtigkeit für die
						Weiterentwicklung.
					</p>
				</div>

				<Link className="button is-turquoise" to={routes.FEEDBACK.getPath()}>
					Jetzt Feedback geben
				</Link>
			</Widget>
		</>
	);
});
