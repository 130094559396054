import { useStore } from 'app/context';
import { TemplateVM } from 'app/stores/ui/template.ui.store';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Button } from '../common/Button';
import { HtmlContent } from '../common/HtmlContent';
import { IModalButton, Modal } from '../Modal';
import { TemplatesEditModal } from './TemplatesEditModal';
import _ from 'lodash';
import { AgenturModel } from 'app/models/agentur.model';
import { AgtPlanModel } from 'app/stores/agt.plan.store';
import { notify } from '../common/notify';
import { ConfirmModal } from '../common/ConfirmModal';

interface ITemplateSelectorModal {
	onSelect: (template: TemplateVM) => void;
	agt?: AgenturModel;
	plan?: AgtPlanModel;
}

export const TemplateSelectorModal = observer((props: ITemplateSelectorModal) => {
	const { uiStore, templateUiStore } = useStore();
	const modalId = uiStore.modalIds.templateSelect;

	const onOpenModal = () => {
		uiStore.showModal(modalId);
	};
	const onCancel = () => {
		uiStore.hideModal(modalId);
	};

	const onSelect = () => {
		if (templateUiStore.current) {
			props.onSelect(templateUiStore.current);
			uiStore.hideModal(modalId);
		}
	};

	const onCreateTemplate = () => {
		const vm = templateUiStore.newTemplate();
		templateUiStore.setCurrent(vm);
		uiStore.showModal(uiStore.modalIds.templateEdit);
	};

	const modalButtons: IModalButton[] = [
		{
			title: 'Neue Vorlage',
			className: 'button is-info',
			onClick: () => {
				onCreateTemplate();
			},
		},
		{
			title: 'Abbrechen',
			className: 'button',
			onClick: () => {
				onCancel();
			},
		},
	];

	return (
		<>
			<Button type="button" className="button is-primary is-inverted" onClick={onOpenModal}>
				Neues Dokument
			</Button>

			<TemplatesEditModal />

			<Modal size="large" modalId={modalId} title={'Neues Dokument erstellen'} buttons={modalButtons} onClose={onCancel}>
				<div className="template-selector-container">
					<TemplateSelector editMode={false} onSelectTemplate={onSelect} {...props} />
				</div>
			</Modal>
		</>
	);
});

interface ITemplateSelector {
	editMode: boolean;
	agt?: AgenturModel;
	plan?: AgtPlanModel;
	onSelectTemplate: () => void;
}

export const TemplateSelector = observer((props: ITemplateSelector) => {
	const { templateUiStore, uiStore } = useStore();
	const [availableTemplates, setAvailableTemplates] = useState<TemplateVM[]>([]);

	useEffect(() => {
		templateUiStore.findAll();
	}, [templateUiStore]);

	useEffect(() => {
		let items = templateUiStore.items;
		items = _.sortBy(items, 'sortString').reverse();

		const canEdit = items.filter((i) => i.canEdit);
		const initial = items.filter((i) => i.isInitialTemplate === true);

		if (!templateUiStore.current) {
			templateUiStore.setCurrent(initial[0]);
		}

		setAvailableTemplates([...initial, ...canEdit]);
	}, [templateUiStore, templateUiStore.items]);

	const onCreate = (template: TemplateVM) => {
		templateUiStore.setCurrent(template);
		props.onSelectTemplate();
	};

	const onEdit = (template: TemplateVM) => {
		templateUiStore.setCurrent(template);
		uiStore.showModal(uiStore.modalIds.templateEdit);
	};

	const onConfirmDelete = (template: TemplateVM) => {
		templateUiStore.setCurrent(template);
		uiStore.showModal(uiStore.modalIds.confirmDeleteTemplate);
	};
	const onDelete = async () => {
		await templateUiStore.deleteTemplate(templateUiStore.current!);
		notify('Vorlage wurde gelöscht', '', 'info');
	};

	return (
		<>
			<ConfirmModal onConfirm={onDelete} onCancel={() => {}} modalId={uiStore.modalIds.confirmDeleteTemplate}>
				Sind Sie sicher, dass Sie dieses Vorlage löschen wollen? Dies kann nicht rückgängig gemacht werden.
			</ConfirmModal>
			<div className="template-selector-tiles">
				<>
					{availableTemplates.map((t) => {
						return (
							<div key={t.id} className={`template-tile`}>
								<div className="title">{t.title}</div>
								<div className="preview">
									<HtmlContent content={t.template.content.text} />
								</div>

								<div className="actions">
									<div className="edit-actions">
										{t.canEdit && (
											<Button className="button is-small is-info is-inverted" onClick={() => onEdit(t)}>
												Vorlage bearbeiten
											</Button>
										)}
										{t.canDelete && (
											<Button className="button is-small is-red is-inverted" onClick={() => onConfirmDelete(t)}>
												Vorlage löschen
											</Button>
										)}
									</div>
									<div className="use-action">
										<Button className="button is-primary is-block" onClick={() => onCreate(t)}>
											Dokument erstellen
										</Button>
									</div>
								</div>
							</div>
						);
					})}
				</>
			</div>
		</>
	);
});
