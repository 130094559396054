import { formatPercent } from 'app/utils';
import { computed } from 'mobx';
import { observer } from 'mobx-react';

export class ZielErgebnisBalkenModel {
	constructor(z: number, e: number) {
		this.z = z;
		this.e = e;
	}

	z: number;
	e: number;

	getWidth = (wVal: number) => {};

	@computed
	get ergebnis() {
		const w = Math.floor((this.e / Math.max(this.e, this.z)) * 100);

		return {
			value: this.e,
			width: isNaN(w) ? 0 : w,
		};
	}

	@computed
	get ziel() {
		const w = Math.floor((this.z / Math.max(this.e, this.z)) * 100);

		return {
			value: this.z,
			width: isNaN(w) ? 0 : w,
		};
	}

	@computed
	get ueberZiel() {
		const over = Math.max(this.e - this.z, 0);
		const w = Math.ceil((over / Math.max(this.e, this.z)) * 100);

		return {
			value: over,
			width: isNaN(w) ? 0 : w,
		};
	}
}

interface IZielErgebnisBalken {
	model: ZielErgebnisBalkenModel;
	einheit?: string;
	decimals?: number;
}

export const ZielErgebnisBalken = observer((props: IZielErgebnisBalken) => {
	const getModifier = () => {
		if (props.model.z === 0 && props.model.e === 0) {
			return 'has-no-values';
		}
		if (props.model.ueberZiel.value > 0) {
			return 'is-over';
		}
		if (props.model.e === 0) {
			return 'is-none';
		}
		return 'is-default';
	};

	const dec = props.decimals || 0;

	return (
		<>
			{props.model && (
				<>
					<div className={`ze-bar ${getModifier()}`}>
						<div className="target" style={{ width: `${props.model.ziel.width}%` }}>
							<span>
								Ziel: {formatPercent(props.model.z, dec)} {props.einheit}
							</span>
						</div>
						<div className="value" style={{ width: `${props.model.ergebnis.width}%` }}>
							<span>
								Ergebnis: {formatPercent(props.model.e, dec)} {props.einheit}
							</span>
						</div>
						{props.model.ueberZiel.width > 0 && <div className="over" style={{ width: `${props.model.ueberZiel.width}%` }}></div>}
					</div>
				</>
			)}
		</>
	);
});
