import { Einheit } from 'app/models/core/einheit.model';
import { BnrGpPosModel } from '../bnr.gp.pos.model';
import { PosUnDef } from '../gp';
const posDef: BnrGpPosModel[] = [];

// ok
posDef.push(new BnrGpPosModel({ year: 2023, posId: 2275001, group: 'vss', posText: 'Weiterbildungspfl. BNR (IDD)', planKey: 'idd', einheit: Einheit.bew }));
// nok
posDef.push(new BnrGpPosModel({ year: 2023, posId: PosUnDef, group: 'vss', posText: 'Weiterbildungsz.Fonds', planKey: 'weiterbildungfonds', einheit: Einheit.bew }));
//ok
posDef.push(new BnrGpPosModel({ year: 2023, posId: 2275011, group: 'vss', posText: 'Leben KNP AKS NSt Digi', planKey: 'lebenakspvnst', einheit: Einheit.lfNST }));
posDef.push(new BnrGpPosModel({ year: 2023, posId: 2275021, group: 'vss', posText: 'Leben Ris. Bew Digi', planKey: 'lebenrisbewoze', einheit: Einheit.lfNST }));
// not in agt gp
posDef.push(new BnrGpPosModel({ year: 2023, posId: 2275031, group: 'vss', posText: 'Kranken HKV Pers. Digi', planKey: 'krankenbkwbew', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2023, posId: 2275041, group: 'vss', posText: 'Nettomittelzufluss o. VZ', planKey: 'nettomittelzufluss', einheit: Einheit.bew }));
// not in agp gp
posDef.push(new BnrGpPosModel({ year: 2023, posId: 2275026, group: 'vss', posText: 'Leben Firmen Risiko', planKey: 'lebenfirmen', einheit: Einheit.lfNST }));

posDef.push(new BnrGpPosModel({ year: 2023, posId: 2275111, group: 'neh', posText: 'Leben KnP80 Bew Digi', planKey: 'vagrentelebenbewzoze', einheit: Einheit.bew }));
// not in agp gp
posDef.push(new BnrGpPosModel({ year: 2023, posId: 2275121, group: 'neh', posText: 'Leben Firmen KnP80 Bew.', planKey: 'lebenknpbew', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2023, posId: 2275131, group: 'neh', posText: 'Fonds Bewertung', planKey: 'fondsbew', einheit: Einheit.bew }));
// nok
posDef.push(new BnrGpPosModel({ year: 2023, posId: PosUnDef, group: 'neh', posText: 'AktivDepot Digital', planKey: 'wb_lebenknpbewoze', einheit: Einheit.bew }));
// ok
posDef.push(new BnrGpPosModel({ year: 2023, posId: 2275151, group: 'neh', posText: 'Kranken bKV Bew', planKey: 'krankenbkvbew', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2023, posId: 2275161, group: 'neh', posText: 'KV u. Pfl. Zusatz Bew Dig', planKey: 'krankenzusatzbew', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2023, posId: 2275171, group: 'neh', posText: 'Bausp./BauFi Bewertung', planKey: 'baufinanzierungbew', einheit: Einheit.bew }));

//not in agp gp
posDef.push(new BnrGpPosModel({ year: 2023, posId: 3875041, group: 'gpplus', posText: 'Kranken Pflege Bew Digi', planKey: 'krankpflegebewdigi', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2023, posId: 3875021, group: 'gpplus', posText: 'Wiederanlage', planKey: 'wiederanlagesummeleben', einheit: Einheit.bew }));
//not in agp gp
posDef.push(new BnrGpPosModel({ year: 2023, posId: 3875031, group: 'gpplus', posText: 'Firmen Fonds Bew', planKey: 'firmenfondsbew', einheit: Einheit.bew }));

export const LpvPositionen = posDef;
