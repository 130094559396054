import { Steart } from 'app/models/core/steart.model';
import { ZeWidgetDef, ZeWidgetItem } from './vbl.ze.widgets';

export const LpvZeWidgets: ZeWidgetDef[] = [
	new ZeWidgetDef({
		title: 'Produktivität Spezialisten',
		key: 'ZE_Prod_LPV',
		subTitle: '(Anzahl VAS, KME, KVS, BBS, UB und Geno-PVS 60% mit Erfüllung der GP-Eingangshürde )',
		stearts: [Steart.LPV],
		items: [
			new ZeWidgetItem({
				text: <></>,
				posId: 2275050,
				einheit: '',
			}),
		],
	}),
];
