import { SegmentName } from 'app/components/anwendungen/segment/SegmentTag';
import { useStore } from 'app/context';
import { AgenturModel } from 'app/models/agentur.model';
import { observer } from 'mobx-react';
import moment from 'moment';

interface IAgentur {
	agt: AgenturModel;
}

export const AgenturSegment = observer((props: IAgentur) => {
	const { agtSegmentUiStore } = useStore();

	const segment = agtSegmentUiStore.items.find((x) => x.agtId === props.agt.agtId && x.year === moment().year());
	if (!segment) return null;

	return (
		<div className={`tag is-small agentur-segment-tag is-${segment.displaySegment}`}>
			<div className="segment-value">
				<SegmentName agt={props.agt} />
			</div>
		</div>
	);
});
