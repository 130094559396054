import { observer } from 'mobx-react';
import { useStore } from 'app/context';
import { IWMStyle, WmTableRow } from './Wochenmail';
import { WMHeading } from './PlainTextWochenmail';
import { HtmlContent } from '../common/HtmlContent';

export const WochenmailNews = observer((props: IWMStyle) => {
	const { wmStyles, isText } = props;
	const { wochenmailtUiStore } = useStore();
	const title = `News`;

	return (
		<>
			{wochenmailtUiStore.loaded && wochenmailtUiStore.showNews && (
				<>
					{isText ? (
						<>
							<WMHeading title={title} />
							{wochenmailtUiStore.last3ItemsWochenmail.map(n => {
								return <span>
									<br />
									{n.news.title}
									<br />
									{n!.news.text}
									<br />
								</span>
							})}

							<br />
							<br />
						</>
					) : (
						<WmTableRow>
							<table width={'100%'} style={wmStyles.innerTableStyle}>
								<tbody>
									<tr>
										<td colSpan={1} style={wmStyles.headingTdStyle}>
											<h2 style={{ ...wmStyles.headingStyle, textAlign: 'center' }}>{title}</h2>
										</td>
									</tr>
									{wochenmailtUiStore.last3ItemsWochenmail.map(n => {
										return <tr key={n.id}>
											<td>
												<strong>{n.news.title}:</strong>
												<br />
												<br />
												<HtmlContent content={n.news.text} />
											</td>
										</tr>
									})}

								</tbody>
							</table>
						</WmTableRow>
					)}
				</>
			)}
		</>
	);
});
