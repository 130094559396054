import { notify } from 'app/components/common/notify';
import { UserModel, UserStatus } from 'app/models/core/user.model';
import _ from 'lodash';
import { action, computed, IObservableArray, makeObservable, observable, runInAction } from 'mobx';
import { AdminUserStore, IUpdateBnrId } from '../admin.user.store';
import Noty from 'noty';
import { FindSteart } from 'app/models/core/steart.model';

class UserFilter {
	constructor() {
		makeObservable(this);
	}

	@observable
	fullText: string = '';

	@observable
	vd?: string;

	@observable
	gs?: string;

	@observable
	steart: string[] = [];

	@observable
	status: UserStatus[] = [];

	@action
	reset() {
		this.fullText = '';
		this.gs = undefined;
		this.vd = undefined;
		this.status = [];
		this.steart = [];
	}
}

export class UserVM {
	constructor(user: UserModel) {
		this.user = user;
	}
	user: UserModel;

	@computed
	get fullText() {
		let s = '';
		if (this.user.name) {
			s += this.user.name.toLowerCase();
		}
		if (this.user.email) {
			s += this.user.email.toLowerCase();
		}
		if (this.user.bensl) {
			s += ' ' + this.user.bensl.toLowerCase();
		}
		if (this.user.bnrId) {
			s += ' ' + this.user.bnrId;
		}
		if (this.user.comment) {
			s += ' ' + this.user.comment;
		}
		if (this.user.steart) {
			s += ' ' + this.user.steartText;
		}
		return s;
	}
}

export class AdminUserUiStore {
	adminUserStore: AdminUserStore;

	constructor(adminUserStore: AdminUserStore) {
		makeObservable(this);
		this.adminUserStore = adminUserStore;
	}

	@observable current?: UserVM;

	@observable
	currentFilter: UserFilter = new UserFilter();

	@computed
	get currentItems() {
		let items = this._items.map((i) => i);
		const f = this.currentFilter;
		if (!f) {
			return items;
		}
		if (this.currentFilter.fullText) {
			const f = this.currentFilter.fullText.toLowerCase();
			items = items.filter((i) => i.fullText.indexOf(f) >= 0);
		}
		if (this.currentFilter.status.length > 0) {
			const numStatus = this.currentFilter.status.map((s) => parseInt(s.toString()));

			items = items.filter((i) => numStatus.includes(i.user.status));
		}

		if (this.currentFilter.vd) {
			items = items.filter((i) => _.includes(i.user.vdList, this.currentFilter.vd));
		}

		if (this.currentFilter.gs) {
			items = items.filter((i) => _.includes(i.user.gsList, this.currentFilter.gs));
		}

		if (this.currentFilter.steart.length > 0) {
			const stearts = this.currentFilter.steart.map((s) => parseInt(s));
			items = items.filter((i) => stearts.includes(i.user.steart.id));
		}

		return _.sortBy(items, (r) => r.user.lastLoginDate).reverse();
	}

	_items: IObservableArray<UserVM> = observable([]);
	loaded: boolean = false;

	@computed
	get invitableUsers() {
		if (this._items.length === this.currentItems.length) {
			return observable([]);
		}
		return this.currentItems.filter((i) => i.user.isInvitable === true);
	}

	@computed
	get invitableUserCount() {
		return this.invitableUsers.length;
	}

	async load() {
		if (this.loaded) {
			return this._items;
		}

		const users = await this.adminUserStore.findAll();

		const res: UserVM[] = [];

		users.forEach((t) => {
			const vm = new UserVM(t);
			res.push(vm);
		});
		runInAction(() => {
			this._items.replace(res);
			this.loaded = true;
		});

		return this._items;
	}

	@action
	async loadUserById(userId?: string) {
		if (!userId) {
			this.current = undefined;
		}
		const users = await this.findAll();
		const u = users.find((u) => u.user.userId === userId);
		runInAction(() => {
			this.current = u;
		});
	}

	@action
	async newUser(bnrId: string, userType: string, bensl: string, steartId?: string) {
		if (!bnrId) {
			this.current = undefined;
			return;
		}
		let u: UserVM;
		if (userType === 'user') {
			const user = new UserModel({
				// @ts-ignore
				userId: undefined,
				bensl,
				bnrId: Number(bnrId),
				userType: userType,
				status: 0,
				// @ts-ignore
				steart: 0,
			});

			const x = await this.adminUserStore.findUserByBnrId(user.bnrId);
			if (x) {
				user.email = x.email;
				user.firstName = x.firstName;
				user.lastName = x.lastName;
				user.tel = x.tel;
				user.mobil = x.mobil;
				user.steart = x.steart;
			}
			if (steartId) {
				user.steart = FindSteart(parseInt(steartId));
			}


			u = new UserVM(user);
		} else {
			const user = new UserModel({
				// @ts-ignore
				userId: undefined,
				bnrId: 123,
				userType: 'admin',
				status: 0,
				steart: 0,
			});
			u = new UserVM(user);
		}
		runInAction(() => {
			this.current = u;
		});
	}

	async findAll() {
		return this.load();
	}

	@computed
	get vdOpts() {
		const users = _.uniqBy(this._items, 'user.firstVd');
		const items = users.map((u) => u.user.firstVd);
		return items.map((i) => {
			return {
				label: i,
				value: i,
			};
		});
	}

	@computed
	get gsOpts() {
		const users = _.uniqBy(this._items, 'user.firstGs');
		const items = users.map((u) => u.user.firstGs);
		return items.map((i) => {
			return {
				label: i,
				value: i,
			};
		});
	}

	@action
	async triggerImport() {
		await this.adminUserStore.triggerImport();
	}

	@action
	async sendUserInvite(user: UserModel) {
		if (!user.isInvitable) {
			if (!window.confirm('Laut Regeln, sollten diesem Benutzer keine Einladung geschickt werden dürfen. Trotzdem schicken?')) {
				return;
			}
		}
		await this.adminUserStore.sendUserInvite(user.bensl);
		notify(`Einladung an ${user.name}  ${user.bensl} gesendet`, '');
	}

	@action
	async bulkSendMail() {
		await this._bulkSendMail(this.currentItems.map((u) => u.user));
	}

	@action
	async updateUserBnrId(data: IUpdateBnrId) {
		const user = await this.adminUserStore.updateUserBnrId(data);
		if (!user) {
			return;
		}
		const vm = new UserVM(user);
		this._items.replace(this._items.filter((x) => x.user.bnrId !== data.oldBnrId));
		this._items.push(vm);
	}

	@action
	async _bulkSendMail(items: UserModel[]) {
		const CONFIRM_INVITABLE_TEXT =
			'Es wird nur an Benutzer gesendet die einladbar sind. (Status:Erstellt, AnzAgt > 0, Gültige VD,GS und Steart, Letzte Einladung vor mehr als einer Woche) ';

		if (window.confirm('Sicher?? ' + CONFIRM_INVITABLE_TEXT) !== true) {
			return;
		}
		const invitables = items.filter((i) => i.isInvitable);
		const total = invitables.length;
		let c = 0;

		for (const user of invitables) {
			await this.adminUserStore.sendUserInvite(user.bensl);
			Noty.closeAll();
			c++;
			notify(`Einladung an ${user.name}  ${user.bensl} gesendet (${c} / ${total})`, 'Bulk', 'info', 1000);
		}
		notify('fertig', 'Bulk', 'info');
	}

	async updateOrgaInfo() {
		await this.adminUserStore.updateOrgaInfo();
		this.loaded = false;
		this.adminUserStore.loaded = false;
		await this.findAll();

	}
	async updateOrgaInfoWithMultisAgentur() {
		await this.adminUserStore.updateOrgaInfoWithMultisAgentur();
		this.loaded = false;
		this.adminUserStore.loaded = false;
		await this.findAll();

	}

	async save(user: UserVM) {
		const isNew = !user.user.userId;
		const res = await this.adminUserStore.save(user.user);
		debugger;
		await this.updateOrgaInfo();

		if (isNew) {
			const u = await this.adminUserStore.getById(res.userId);
			const vm = new UserVM(u ? u : res);
			this._items.push(vm);
			return vm;
		} else {
			runInAction(() => {
				this.loaded = false;
				this.load();
			});
		}
		return user;
	}

	async findUserByBnrId(bnrId: number) {
		return await this.adminUserStore.findUserByBnrId(bnrId);
	}

	async findUserByBensl(bensl: string) {
		const items = await this.load();
		return items.find((u) => u.user.bensl.toLowerCase() === bensl.trim().toLowerCase());
	}
}
