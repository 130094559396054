import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { WidgetScope } from 'app/models/widgets/widget.setting.model';
import { WidgetContainer } from '../common/WidgetContainer';
import { useEffect, useState } from 'react';
import { WidgetsVm } from 'app/models/widgets/widget.vm';
import { DocumentViewerModal } from '../documents/DocumentViewerModal';
import { TaskViewerModal } from '../tasks/TaskViewModal';
import { runInAction } from 'mobx';

export const TeamOverview = observer(() => {
	const { teamUiStore } = useStore();
	const member = teamUiStore.current;

	const { widgetUiStore, documentUiStore } = useStore();
	const [vm, setVm] = useState<WidgetsVm>();

	useEffect(() => {
		widgetUiStore.findByScope(WidgetScope.team).then((res) => {
			runInAction(() => {
				documentUiStore.currentFilter.reset();
				documentUiStore.currentFilter.bnrId = teamUiStore.currentBnrId;
			});
			setVm(new WidgetsVm(res));
		});
	}, [widgetUiStore, teamUiStore, teamUiStore.currentBnrId, documentUiStore.currentFilter]);

	return (
		<>
			<div className="dashboard has-bigger-left is-agentur">{member && vm && <WidgetContainer vm={vm} />}</div>
			<DocumentViewerModal showPlan={true} showAgt={false} />
			<TaskViewerModal showPlan={true} showAgt={false} />
		</>
	);
});
