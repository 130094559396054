import { useStore } from 'app/context';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import routes from 'routes';
import Panel from '../../Panel';
import { AdminUserEditForm } from './AdminUserEditForm';
import { UserVM } from 'app/stores/ui/admin.user.ui.store';
export const AdminUserDetail = observer(() => {
	const { adminUserUiStore } = useStore();

	const [user, setUser] = useState<UserVM>()
	const history = useHistory();

	useEffect(() => {
		setUser(adminUserUiStore.current)
	}, [adminUserUiStore.current])

	const onClose = () => {
		runInAction(() => {
			adminUserUiStore.current = undefined;
			history.push(routes.ADMINUSERS.getPath());
		});
	};

	return (
		<>
			{user && (
				<Panel title={user.user.name} subtitle={user.user.steart.name} header={<> </>} onClose={onClose}>
					{user && <AdminUserEditForm user={user} onClose={onClose} />}
				</Panel>
			)}
		</>
	);
});
