import { FindSteart, SteartModel } from 'app/models/core/steart.model';
import { computed } from 'mobx';
import { BaseEntityStore } from './core/base.entity.store';

export class BnrDiff {
	constructor(opts: any) {
		this.bnrId = opts.bnrId;
		this.diff = opts.diff;
		this.fromValue = opts.fromValue;
		this.toValue = opts.toValue;

		if (this.fromValue) {
			const f = this.fromValue.split(';');
			this.fromSteartId = parseInt(f[0]);
			this.fromSteart = FindSteart(this.fromSteartId);
			this.fromName = f[1];
		}

		if (this.toValue) {
			const f = this.toValue.split(';');
			this.toSteartId = parseInt(f[0]);
			this.toSteart = FindSteart(this.toSteartId);
			this.toName = f[1];
		}
	}
	bnrId: number;
	diff: string;
	fromValue: string;
	toValue: string;

	fromSteart?: SteartModel;
	fromSteartId?: number;
	fromName?: string;
	toSteart?: SteartModel;
	toSteartId?: number;
	toName?: string;

	@computed
	get fullText() {
		let s = ' ' + this.bnrId.toString();
		s += ' ' + this.fromName;
		s += ' ' + this.toName;
		if (this.fromSteart) {
			s += ' ' + this.fromSteart.name;
		}
		if (this.toSteart) {
			s += ' ' + this.toSteart.name;
		}
		return s;
	}
}

export class AdminBnrDiffStore extends BaseEntityStore<BnrDiff> {
	apiPath = 'api/vbl/bnrDiff';
	idProp: string = 'id';
	createInstances(opts: any): BnrDiff {
		return new BnrDiff(opts);
	}

	async findDiff(fromKw: number, toKw: number): Promise<BnrDiff[]> {
		const res = await this.get('api/vbl/bnrdiff/' + fromKw + '/' + toKw, false);
		if (res && res.data) {
			return res.data.map((d: any) => this.createInstances(d));
		} else {
			if (res) {
				console.warn(res);
			}
		}
		return [];
	}

	async getValidKws(): Promise<number[]> {
		const res = await this.get('api/vbl/bnrdiff/kw', false);
		if (res && res.data) {
			return res.data.map((d: any) => d);
		} else {
			if (res) {
				console.warn(res);
			}
		}
		return [];
	}
}
