import { AdminUsageTreeNodes } from 'app/models/admin.usage.tree.nodes';
import { Stearts } from 'app/models/core/steart.model';
import { TreeMenuModel } from 'app/models/core/tree.node.model';
import { computed, makeObservable, observable, reaction, runInAction } from 'mobx';
import moment from 'moment';
import routes from 'routes';
import { AdminTrackingStore } from '../admin.tracking.store';
import { BaseTreeStore } from '../core/base.tree.store';
import { SessionStore } from '../session.store';
import { AdminUsageFilter } from './../../components/admin/usage/AdminUsageFilter';
import { AdminUserUiStore, UserVM } from './admin.user.ui.store';

export interface IAgtReportStats {
	category: string;
	geschaeftsstelle: string;
	steart: number;
	total: number;
	type: string;
	vertriebsdirektion: string;
	withContent: number;
	ym: number;
}

const trackingRolesArr = ['vbl', 'spezi', 'leiterspezi', 'multi'];
export const trackingRoles = Stearts.filter((s) => trackingRolesArr.includes(s.group));

export const vdIds = ['L', 'F', 'K', 'H', 'N', 'B', 'M', 'S', 'X'].sort();

export const gsIds = [
	'X',
	'LA',
	'L',
	'KO',
	'HAL',
	'KI',
	'EF',
	'IN',
	'J',
	'WUE',
	'D',
	'FR',
	'F',
	'H',
	'PA',
	'HN',
	'Z',
	'N',
	'NB',
	'R',
	'OG',
	'WM',
	'KBN',
	'M',
	'B',
	'BT',
	'MD',
	'NR',
	'DE',
	'AC',
	'SHL',
	'HB',
	'MZ',
	'E',
	'HH',
	'BB',
	'W',
	'C',
	'SB',
	'MS',
	'GI',
	'DD',
	'DO',
	'KS',
	'RT',
	'MEI',
	'S',
	'GOE',
	'GP',
	'P',
	'SN',
	'WI',
	'OL',
	'A',
	'CB',
	'UL',
	'KA',
	'RO',
	'BZ',
	'X',
].sort();

export const gsByVd = [
	{
		vdId: 'B',
		items: ['B', 'BB', 'CB', 'DE', 'MD', 'NB', 'P', 'SN'],
	},
	{
		vdId: 'F',
		items: ['F', 'GI', 'KO', 'KS', 'MZ', 'SB', 'WI'],
	},
	{
		vdId: 'H',
		items: ['GOE', 'H', 'HB', 'HH', 'KI', 'OL', 'OS'],
	},
	{
		vdId: 'K',
		items: ['AC', 'D', 'DO', 'E', 'KBN', 'MS', 'NR', 'W'],
	},
	{
		vdId: 'L',
		items: ['BZ', 'C', 'DD', 'EF', 'HAL', 'J', 'L', 'MEI', 'SHL', 'Z'],
	},
	{
		vdId: 'M',
		items: ['A', 'IN', 'KE', 'LA', 'M', 'RO', 'WM'],
	},
	{
		vdId: 'N',
		items: ['BT', 'N', 'PA', 'R', 'WUE'],
	},
	{
		vdId: 'S',
		items: ['FR', 'GP', 'HN', 'KA', 'OG', 'RT', 'S', 'UL'],
	},
];

export interface IBPTableRow {
	ym: number | string;
}

class AdminUsageFilterState {
	constructor() {
		makeObservable(this);
	}

	@observable
	vdId?: string;

	@observable
	startDate: Date = moment().startOf('year').toDate();

	@observable
	endDate: Date = moment().toDate();
}

export interface IUserTotalsData {
	name: string;
	registered: number;
	total: number;
	percent: number;
}

export class AdminUsageUiStore extends BaseTreeStore {
	adminTrackingStore: AdminTrackingStore;
	adminUserUiStore: AdminUserUiStore;

	constructor(session: SessionStore, adminTrackingStore: AdminTrackingStore, adminUserUiStore: AdminUserUiStore) {
		super(session);
		makeObservable(this);

		this.adminTrackingStore = adminTrackingStore;
		this.adminUserUiStore = adminUserUiStore;

		reaction(
			() => this.session.currentUser,
			() => this.fillNodesFromUser(),
			{ fireImmediately: true },
		);
	}

	filterComponent = (<AdminUsageFilter />);

	currentFilter: AdminUsageFilterState = new AdminUsageFilterState();

	menu: TreeMenuModel = new TreeMenuModel(AdminUsageTreeNodes);

	fillNodesFromUser() {
		if (!this.session.currentUser) {
			return;
		}

		//this.AGENTURROUTE = routes;
		this.OUTEROUTE = routes.ADMINTUSAGE;
	}

	get vdOpts() {
		return vdIds.map((vd) => {
			return {
				label: vd,
				value: vd,
			};
		});
	}

	@computed
	get filteredGsIds() {
		if (this.currentFilter.vdId) {
			return gsByVd.find((gs) => gs.vdId === this.currentFilter.vdId!)!.items;
		}
		return [];
	}

	/************************************************ */
	/* PlanungsDocumente */

	async loadPlanDocStats() {
		if (this.agtPlanDocStats && this.agtPlanDocStats.length > 0) {
			return;
		}
		return this.adminTrackingStore.getAgtPlanDocStats().then((res) => {
			runInAction(() => {
				this.agtPlanDocStats = res;
			});
		});
	}

	@computed
	get dateFilteredPlanDocs() {
		if (!this.agtPlanDocStats) {
			return [];
		}
		return this.agtPlanDocStats.filter((d) => d.ym >= moment(this.currentFilter.startDate).valueOf() && d.ym <= moment(this.currentFilter.endDate).valueOf());
	}

	_groupSumVD = (docs: IAgtReportStats[]) => {
		const agtJpgData: any = [];
		// filter to date range
		// filter out steart or VD null
		docs = docs.filter((d) => d.vertriebsdirektion !== null);
		// seperate monthly
		const months = Array.from(new Set(docs.map((d) => d.ym))).sort();
		// calculate JPG/VD date
		months.forEach((month) => {
			let monthObj: any = {
				ym: month,
				sum: 0,
			};

			vdIds.forEach((vd) => {
				monthObj[vd.toLowerCase()] = 0;
				const testArray = docs!.filter((d) => d.ym === month && d.vertriebsdirektion === vd);
				testArray.forEach((test) => {
					const withContent = test ? test.withContent : 0;
					monthObj[vd.toLowerCase()] += withContent;
					monthObj.sum += withContent;
				});
			});
			agtJpgData.push(monthObj);
		});
		return agtJpgData;
	};

	_groupSumGs = (docs: IAgtReportStats[]) => {
		if (!this.currentFilter.vdId) {
			return [];
		}
		let filteredGsIds = this.filteredGsIds;

		const months = Array.from(new Set(docs.map((d) => d.ym))).sort();

		const gsJpgData: any[] = [];
		months.forEach((month) => {
			let monthObj: any = {
				ym: month,
				sum: 0,
			};
			filteredGsIds.forEach((g) => {
				monthObj[g.toLowerCase()] = 0;
				const testArray = docs!.filter((d: any) => d.ym === month && d.geschaeftsstelle === g);
				testArray.forEach((test: any) => {
					const withContent = test ? test.withContent : 0;
					monthObj[g.toLowerCase()] += withContent;
					monthObj.sum += withContent;
				});
			});
			gsJpgData.push(monthObj);
		});
		return gsJpgData;
	};

	@computed
	get agtVdJpgStats() {
		let docs = this.dateFilteredPlanDocs.filter((x) => x.category === 'jpg');
		return this._groupSumVD(docs);
	}

	@computed
	get agtVdApStats() {
		let docs = this.dateFilteredPlanDocs.filter((x) => x.category === 'ap');
		return this._groupSumVD(docs);
	}

	@computed
	get agtGsJpgStats() {
		const docs = this.dateFilteredPlanDocs.filter((x) => x.category === 'jpg');
		return this._groupSumGs(docs);
	}

	@computed
	get agtGsJApStats() {
		const docs = this.dateFilteredPlanDocs.filter((x) => x.category === 'ap');
		return this._groupSumGs(docs);
	}

	@observable
	agtPlanDocStats?: IAgtReportStats[];

	/************************************************ */
	/* BranchenPlanung */

	@observable
	agtBranchenPlanungStats?: IAgtReportStats[];

	loadBranchenPlanung() {
		if (this.agtBranchenPlanungStats && this.agtBranchenPlanungStats.length > 0) {
			return;
		}
		this.adminTrackingStore.getAgtBPStats().then((res) => {
			this.agtBranchenPlanungStats = res;
		});
	}

	@computed
	get dateFilteredBranchenPlanungen() {
		if (!this.agtBranchenPlanungStats) {
			return [];
		}
		return this.agtBranchenPlanungStats.filter((d) => d.ym >= moment(this.currentFilter.startDate).valueOf() && d.ym <= moment(this.currentFilter.endDate).valueOf());
	}

	@computed
	get agtBpStatsByRole() {
		let agtBp = this.dateFilteredBranchenPlanungen;
		if (this.currentFilter.vdId) {
			agtBp = agtBp.filter((x) => x.vertriebsdirektion === this.currentFilter.vdId!);
		}
		// BP Stats by Role
		const agtRoleData: any = [];
		const months = Array.from(new Set(agtBp.map((d) => d.ym))).sort();
		// calculate BP/VD date
		months.forEach((month) => {
			let monthObj: any = {
				ym: month,
				sum: 0,
			};

			trackingRoles.forEach((role) => {
				monthObj[role.name.toLowerCase()] = 0;
				const testArray = agtBp!.filter((d) => d.ym === month && d.steart === role.steart);
				testArray.forEach((test) => {
					const withContent = test ? test.withContent : 0;
					monthObj[role.name.toLowerCase()] += withContent;
					monthObj.sum += withContent;
				});
			});
			agtRoleData.push(monthObj);
		});

		return agtRoleData;
	}

	@computed
	get agtBpByVd() {
		const agtBpData: any = [];
		let agtBpStats = this.dateFilteredBranchenPlanungen;
		const months = Array.from(new Set(agtBpStats.map((d) => d.ym))).sort();
		// calculate BP/VD date
		months.forEach((month) => {
			let monthObj: any = {
				ym: month,
				sum: 0,
			};
			vdIds.forEach((vd) => {
				monthObj[vd.toLowerCase()] = 0;
				const testArray = agtBpStats!.filter((d) => d.ym === month && d.vertriebsdirektion === vd);
				testArray.forEach((test) => {
					const withContent = test ? test.withContent : 0;
					monthObj[vd.toLowerCase()] += withContent;
					monthObj.sum += withContent;
				});
			});
			agtBpData.push(monthObj);
		});

		return agtBpData;
	}

	@computed
	get agtBpByGs() {
		const agtBpData: any = [];
		let agtBpStats = this.dateFilteredBranchenPlanungen;
		agtBpStats = agtBpStats.filter((x) => x.vertriebsdirektion === this.currentFilter.vdId!);
		const months = Array.from(new Set(agtBpStats.map((d) => d.ym))).sort();
		// calculate BP/VD date
		months.forEach((month) => {
			let monthObj: any = {
				ym: month,
				sum: 0,
			};
			this.filteredGsIds.forEach((g) => {
				monthObj[g.toLowerCase()] = 0;
				const testArray = agtBpStats!.filter((d: any) => d.ym === month && d.geschaeftsstelle === g);
				testArray.forEach((test: any) => {
					const withContent = test ? test.withContent : 0;
					monthObj[g.toLowerCase()] += withContent;
					monthObj.sum += withContent;
				});
			});
			agtBpData.push(monthObj);
		});

		return agtBpData;
	}

	/************************************************ */
	/* User Logins */
	@observable
	allusers: UserVM[] = [];

	loadLoginData() {
		if (this.allusers.length > 0) {
			return;
		}
		this.adminUserUiStore.load().then((users) => {
			runInAction(() => {
				this.allusers = users;
			});
		});
	}

	@computed
	get relevantUsersForLogin() {
		let userList = this.allusers.filter((u) => u.user.steart !== undefined);
		userList = userList.filter((u) => {
			return u.user.steart.id > 0 && u.user.status === 1 && trackingRolesArr.includes(u.user.steart.group);
		});
		return userList;
	}

	@computed
	get loginsProRolleJeVd() {
		let userList = this.relevantUsersForLogin;

		if (this.currentFilter.vdId) {
			userList = userList.filter((u) => u.user.vdList.includes(this.currentFilter.vdId!));
		}

		const startDate = this.currentFilter.startDate;
		const endDate = this.currentFilter.endDate;

		const data: any[] = [];
		vdIds.forEach((vd) => {
			// if (vdFilter && vd !== vdFilter) return;
			if (this.currentFilter.vdId && this.currentFilter.vdId !== vd) {
				return;
			}

			const trObj: any = {
				vd: vd.toUpperCase(),
				sum: 0,
				score: 0,
			};

			trackingRoles.forEach((role) => {
				const vdRoleQuery = userList.filter(
					(u) => u.user.steart.id === role.steart && u.user.vdList.indexOf(vd) >= 0 && moment(u.user.lastLoginDate).isBetween(startDate, endDate),
				);
				trObj[role.name.toLowerCase()] = vdRoleQuery.length;
				trObj.sum += vdRoleQuery.length;
			});

			const vdActiveQuery = userList.filter((u) => u.user.vdList.indexOf(vd) >= 0 && u.user.status === 1);
			trObj.score = Math.round((trObj.sum / vdActiveQuery.length) * 100);
			data.push(trObj);
		});
		return data;
	}

	@computed
	get loginsProRolleJeGs() {
		let userList = this.relevantUsersForLogin;

		if (this.currentFilter.vdId) {
			userList = userList.filter((u) => u.user.vdList.includes(this.currentFilter.vdId!));
		}

		const startDate = this.currentFilter.startDate;
		const endDate = this.currentFilter.endDate;

		const data: any[] = [];

		this.filteredGsIds.forEach((gs) => {
			const trObj: any = {
				gs: gs.toUpperCase(),
				sum: 0,
				score: 0,
			};

			trackingRoles.forEach((role) => {
				const gsRoleQuery = userList.filter(
					(u) => u.user.steart.id === role.steart && u.user.gsList.indexOf(gs) >= 0 && moment(u.user.lastLoginDate).isBetween(startDate, endDate),
				);
				trObj[role.name.toLowerCase()] = gsRoleQuery.length;
				trObj.sum += gsRoleQuery.length;
			});

			const gsActiveQuery = userList.filter((u) => u.user.gsList.indexOf(gs) >= 0 && u.user.status === 1);
			trObj.score = Math.round((trObj.sum / gsActiveQuery.length) * 100);
			data.push(trObj);
		});

		return data;
	}

	/************************************************ */
	/* Registered Users */

	@computed
	get relevantUsersForReg() {
		let userList = this.allusers.filter((u) => u.user.steart !== undefined);
		userList = userList.filter((u) => {
			return u.user.isReportable && u.user.steart.id > 0 && trackingRolesArr.includes(u.user.steart.group);
		});
		return userList;
	}

	@computed
	get registerdUserByRole() {
		let userList = this.relevantUsersForReg;

		if (this.currentFilter.vdId) {
			userList = userList.filter((u) => u.user.vdList.includes(this.currentFilter.vdId!));
		}

		let fnkData: IUserTotalsData[] = trackingRoles.map((r) => {
			return {
				name: r.name,
				registered: 0,
				total: 0,
				percent: 0,
			};
		});

		userList.forEach((el) => {
			// Add user to funktion
			const fnkItem = fnkData.find((fnk) => fnk.name === el.user.steartText);
			if (fnkItem) {
				fnkItem.total++;
				if (el.user.status === 1) {
					fnkItem.registered++;
				}
				fnkItem.percent = Math.round((fnkItem.registered / fnkItem.total) * 100);
			}
		});
		return fnkData;
	}

	@computed
	get registerdUserByVD() {
		let userList = this.relevantUsersForReg;

		let filteredVdIds = vdIds;
		if (this.currentFilter.vdId) {
			userList = userList.filter((u) => u.user.vdList.includes(this.currentFilter.vdId!));
			filteredVdIds = filteredVdIds.filter((vd) => vd === this.currentFilter.vdId!);
		}
		let vdData: IUserTotalsData[] = filteredVdIds.map((v) => {
			return {
				name: v,
				registered: 0,
				total: 0,
				percent: 0,
			};
		});
		userList.forEach((el) => {
			el.user.vdList.forEach((v) => {
				if (!v && filteredVdIds.indexOf(v) < 0) {
					return;
				}

				const arrayItem = vdData.find((vd) => vd.name === v);
				if (arrayItem) {
					arrayItem.total++;
					if (el.user.status === 1) {
						arrayItem.registered++;
					}
					arrayItem.percent = Math.round((arrayItem.registered / arrayItem.total) * 100);
				}
			});
		});

		return vdData;
	}

	@computed
	get registerdUserByGs() {
		let userList = this.relevantUsersForReg;

		let filteredGsIds = this.filteredGsIds;
		if (this.currentFilter.vdId) {
			userList = userList.filter((u) => u.user.vdList.includes(this.currentFilter.vdId!));
			filteredGsIds = gsByVd.find((gs) => gs.vdId === this.currentFilter.vdId)!.items;
		}
		let gsData: IUserTotalsData[] = filteredGsIds.map((g) => {
			return {
				name: g,
				registered: 0,
				total: 0,
				percent: 0,
			};
		});

		userList.forEach((el) => {
			el.user.gsList.forEach((g) => {
				if (!g && filteredGsIds.indexOf(g) < 0) {
					return;
				}

				const arrayItem = gsData.find((gs) => gs.name === g);
				if (arrayItem) {
					arrayItem.total++;
					if (el.user.status === 1) {
						arrayItem.registered++;
					}
					arrayItem.percent = Math.round((arrayItem.registered / arrayItem.total) * 100);
				}
			});
		});
		return gsData;
	}

	/************************************************ */
	/* Matomo */

	@observable
	matomoVisitsData?: any;
	@observable
	matomoWeekData?: any;

	loadMatomoGeneral() {
		this.adminTrackingStore
			.getMatomoVisitsData(moment(this.currentFilter.startDate).format('YYYY-MM-DD'), moment(this.currentFilter.endDate).format('YYYY-MM-DD'))
			.then((res) => {
				runInAction(() => {
					this.matomoVisitsData = res;
				});
			});
		this.adminTrackingStore
			.getMatomoWeekData(moment(this.currentFilter.startDate).format('YYYY-MM-DD'), moment(this.currentFilter.endDate).format('YYYY-MM-DD'))
			.then((res) => {
				runInAction(() => {
					this.matomoWeekData = res;
				});
			});
	}

	@computed
	get calcWeeks() {
		let current = moment(this.currentFilter.startDate).clone().startOf('week');
		let ret = [current.format('YYYY-MM-DD')];

		while (current.add(1, 'week').isSameOrBefore(this.currentFilter.endDate)) {
			ret.push(current.format('YYYY-MM-DD'));
		}

		return ret;
	}
}
