import { PosVal } from './pos.val';

export class PosAktModel {
	constructor(opts: any) {
		this.agtId = opts.agtId;
		this.bm = opts.bm;
		this.ergBt = opts.ergBt;
		this.ergSt = opts.ergSt;
		this.posId = opts.posId;
		this.bjvj = opts.bjvj;
	}
	agtId: number;
	bm: number;
	ergBt: number;
	ergSt: number;
	posId: number;
	bjvj: 'BJ' | 'VJ';

	getPosval() {
		return new PosVal(this.ergBt, this.ergSt);
	}
}
