import { FindSteart, SteartModel } from 'app/models/core/steart.model';
import { BaseCachedEntityStore } from './core/base.cached.entity.store';

export class SteartStore extends BaseCachedEntityStore<SteartModel> {
	apiPath = 'api/steart';
	idProp: string = 'id';
	createInstances(opts: any): SteartModel {
		const s = FindSteart(parseInt(opts.steart));
		if (s.id > 1) {
			return s;
		}
		const o = {
			steart: opts.steart,
			name: opts.steartKat,
			_name: opts.steartWa,
		};
		return new SteartModel(o);
	}

	findSteart(id: number) {
		if (id <= 1) {
			return;
		}
		const s = this._items.find((x) => x.id === id);
		if (s) {
			return s;
		}
		const x = new SteartModel({ steart: id });
		this._items.push(x);
		return x;
	}
}
