import { AgenturNameCell } from 'app/components/shared/AgenturName.cell';
import { Table } from 'app/components/table/table';
import { TableModel } from 'app/components/table/table.model';
import { useStore } from 'app/context';
import { PersonalVM } from 'app/models/personal/personal.vm';
import { formatDate } from 'app/utils';
import { observer } from 'mobx-react';
import { useEffect, useMemo } from 'react';

export const Pensionsliste = observer(() => {
	const { personalUiStore, berichteUiiStore, agenturListUiStore } = useStore();

	const tm = useMemo(() => {
		const tm = new TableModel<PersonalVM, number>();
		const cols = [
			{
				label: 'Agentur',
				path: 'agt.name',
				render: AgenturNameCell,
			},
			{
				label: 'Vorname',
				path: 'vorname',
			},
			{
				label: 'Nachname',
				path: 'nachname',
			},
			{
				label: 'Geburtsdatum',
				path: 'gebDat',
				format: formatDate,
			},
			{
				label: 'Beitritt',
				path: 'beitrittDat',
				format: formatDate,
			},
			{
				label: 'Pensionierungsdatum',
				path: 'retirementDate',
				format: formatDate,
			},
		];
		tm.setCols(cols);
		tm.sortBy = 'retirementDate';
		tm.idProperty = 'id';
		tm.idType = 'number';
		return tm;
	}, []);

	useEffect(() => {
		personalUiStore.findAll().then((res) => {
			res = res.filter((p) => p.willReachRetirementAge);
			res = agenturListUiStore.applyFilterOnAgt(res) as PersonalVM[];

			if (berichteUiiStore.selectedAgt) {
				const agtId = berichteUiiStore.selectedAgt.agtId;
				res = res.filter((d) => d.agtId === agtId);
			}
			tm.setRowData(res);
		});
	}, [tm, personalUiStore, berichteUiiStore.selectedAgt, agenturListUiStore.filterHasChanged, agenturListUiStore]);
	return (
		<>
			<div className="table-container">
				<Table tm={tm} stickyHeader={true}></Table>
			</div>
		</>
	);
});
