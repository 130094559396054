import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { notify } from 'app/components/common/notify';
import { ImportJobVM } from 'app/stores/ui/admin.imports.ui.store';
import { useEffect, useState } from 'react';
import { AdminFileUpload } from '../AdminFileUpload';
import { AupFile, ImportDataType, ImportJobModel, ImportJobStatus } from 'app/stores/admin.imports.store';
import _ from 'lodash';
import { AdminImportFiles } from '../AdminImportFiles';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import FormField from 'app/components/form/FormField';
import { Button } from 'app/components/common/Button';
import moment from 'moment';


const NewGpFormSchema = Yup.object({
	kw: Yup.string().required('KW ist erfoderlich').default(''),
}).required();

type NewGpForm = Yup.InferType<typeof NewGpFormSchema>;

interface IUserEditForm {
	job: ImportJobVM;
	onClose: () => void;
}

export const AdminImportFormWithBM = observer((props: IUserEditForm) => {
	const { adminImportsUiStore } = useStore();
	// const job = props.job;
	const jobType = props.job!.job.dataType;

	const [files, setFiles] = useState<AupFile[]>([]);
	const [job, setJob] = useState<ImportJobVM>();
	const handleSubmit = async (values: NewGpForm) => {
		const j = new ImportJobModel({
			created: new Date(),
			dataType: props.job!.job.dataType,
			duration: -1,
			status: ImportJobStatus.new,
		});
		j.meta = {
			kw: values.kw
		};
		const vm = new ImportJobVM(j)
		setJob(vm)

		notify('BM wurde festeglegt auf' + values.kw, '', 'info');
	};

	const onUploadDone = () => {
		notify('Upload erfolgreich', '', 'info');
		adminImportsUiStore.getFilesByType(jobType).then((res) => {
			res = _.sortBy(res, 'fileName').reverse();
			setFiles(res);
		});
	};

	useEffect(() => {
		adminImportsUiStore.getFilesByType(jobType).then((res) => {
			res = _.sortBy(res, 'fileName').reverse();
			setFiles(res);
		});
	}, [adminImportsUiStore, job]);

	return (
		<>
			<Formik
				validationSchema={NewGpFormSchema}
				initialValues={{
					kw: moment().format('YYYYMM'),
				}}
				onSubmit={handleSubmit}
			>
				{({ errors, touched, isValid, values }) => {

					return (
						<Form>
							<div className="pad-1rem">
								<FormField className="is-inline" error={errors.kw} touched={touched.kw} label="Monat der Datenlieferung (z.B. 202404)">
									<Field type="text" name="kw" placeholder="YYYYMM" />
								</FormField>
								<Button type="submit" className={'button is-primary'} >
									BM Festlegen
								</Button>
							</div>
							{job && (
								<div className="pad-1rem">

									<div className="form-control is-inline">
										<label>Dateien</label>

										<AdminFileUpload
											isButton={false}
											maxSize={200000000}
											// maxFiles={1}
											dataType={props.job.job.dataType}
											// kw={values.kw}
											multiple={true}
											dropZoneActiveText={'Datei loslassen'}
											dropZoneText={'Dateien Hochladen'}
											uploadingText={'Upload läuft'}
											onDone={onUploadDone}
										/>
									</div>
									<div className="pad-1rem">

										<AdminImportFiles files={files} job={job} />
									</div>
								</div>

							)}

						</Form>
					);
				}}
			</Formik>
		</>
	);
});
