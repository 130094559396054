import { useStore } from 'app/context';
import { Field, Form, Formik } from 'formik';
import { observer } from 'mobx-react';
import * as Yup from 'yup';
import { Button } from './common/Button';
import { notify } from './common/notify';
import FormField from './form/FormField';

const feedbackSchema = Yup.object({
	text: Yup.string()
		.required('Bitte eine Nachricht eingeben')
		.test('text', 'Sie dürfen maximal 200 Zeichen eingeben', (val: any) => {
			val = val ? val : '';
			return val.length < 200;
		}),
}).required();

type Feedback = Yup.InferType<typeof feedbackSchema>;
export const FeedbackForm = observer(() => {
	const { userSettingsStore } = useStore();

	const sendMail = () => {
		window.location.href = `mailto:DVS.feedback@allianz.de?body=%0A%0A${navigator.appVersion}`;
	};

	const handleSubmit = async (values: Feedback) => {
		const msg = values.text.toString(); // clone
		values.text = '';
		await userSettingsStore.sendFeedback(msg);
		notify('Feedback', 'Ihre Nachricht wurde versandt', 'info');
	};

	return (
		<>
			<div className="pad-1rem content">
				Liebe DVS- Benutzer,
				<br />
				<br />
				die DVS wird mit einem agilen Projektansatz entwickelt. Dies bedeutet, dass die DVS noch nicht völlig ausgereift ist, wir aber in der Lage sind auf Ihr Feedback einzugehen und zeitnah umzusetzen.
				<br />
				<br />
				Uns interessiert jegliches Feedback. Sowohl postiv als auch negativ.
				<br />
				<br />
				Wenn Sie uns Fehlermeldungen senden, dann interessieren uns insbesondere die folgenden Informationen:
				<ul className={'list-style-dash'}>
					<li>Was haben Sie gemacht, als der Fehler auftrat?</li>
					<li>Tritt der Fehler immer, bei dieser Aktion oder nur das eine Mal auf?</li>
					<li>Wird eine Fehlermeldung angezeigt?</li>
					<li>Welches Betriebssystem (z.B. Windows, iOS, Android) und welchen Browser (IE, Safari, etc.) haben Sie verwendet?</li>
					<li>
						Gerne können Sie uns Screenshots auch direkt an{' '}
						<a href="mailto:DVS.feedback@allianz.de" onClick={sendMail}>
							DVS.feedback@allianz.de
						</a>{' '}
						senden
					</li>
				</ul>
				<br />
				<strong>NEU: Beantragung Datenübernahme Ihres Vorgängers</strong>
				<br />
				<p>Um den Prozess der Datenübernahme anzustoßen, bitten wir Sie uns dies über die Feedbackfunktion mitzuteilen.</p>
				<p>Sie haben die Möglichkeit Ihre eigenen agenturbezogene Daten aus der Vorfunktion zu übernehmen oder die Daten Ihres Vorgängers/Ihrer Vorgängerin zu beantragen.</p>
				<p>Dazu benötigen wir den Namen und die Mail-Adresse Ihres Vorgängers/Ihrer Vorgängerin oder welche Funktion sie zuletzt ausgeübt haben.</p>
				<p>Bitte beachten Sie, dass Ihre Vorgänger/Ihre Vorgängerin der Datenübergabe zustimmen muss. Die Zustimmung oder Ablehnung wird von der DVS eingeholt.</p>
				<ul className={'list-style-dash'}>
					<li>Name und Emailaddresse</li>
					<li>BNR ID</li>
					<li>Stellenart</li>
				</ul>
				<br />
				Danke für Ihre Mithilfe. Wir freuen uns auf Ihr Feedback
				<br />
				Ihr DVS Team
			</div>

			<Formik
				validationSchema={feedbackSchema}
				initialValues={{
					text: '',
				}}
				onSubmit={handleSubmit}
			>
				{({ errors, touched, isValid }) => {
					return (
						<Form>
							<div className="pad-1rem">
								<FormField error={errors.text} touched={touched.text} label="Nachricht">
									<Field className="input" type="text" as="textarea" name="text" placeholder="Bitte eine Nachricht eingeben" />
								</FormField>
							</div>
							<div className="pad-1rem">
								<Button type="submit" className="button is-primary is-block" isFormInvalid={!isValid}>
									Nachricht senden
								</Button>
							</div>
						</Form>
					);
				}}
			</Formik>
		</>
	);
});
