import { Icon } from 'app/components/common/Icon';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

interface IDfItemDef {
	label: string;
	key: string;
	group: string;
	barMax?: number;
	linkPdf?: string;
}

const onePages: IDfItemDef[] = [
	{ label: 'Monatliche Visits Homepage', key: 'MVTHP', group: 'sichtbarkeit', barMax: 35, linkPdf: 'dva_onepager/dva_vt_hompage.pdf' },
	{ label: 'Facebook', key: 'FACEBOOK', group: 'sichtbarkeit', barMax: 35, linkPdf: 'dva_onepager/dva_facebook.pdf' },
	{ label: 'Google SEO Paket', key: 'GOOGLE_SEO', group: 'sichtbarkeit', barMax: 10, linkPdf: 'dva_onepager/dva_googleseo.pdf' },
	{ label: 'Online Werbebanner', key: 'BANNER', group: 'sichtbarkeit', barMax: 5, linkPdf: 'dva_onepager/dva_onlinewerbebanner.pdf' },

	{ label: 'XING', key: 'XING', group: 'beratung', barMax: 20, linkPdf: 'dva_onepager/dva_xing.pdf' },
	{ label: 'Online Terminierung', key: 'OT', group: 'beratung', barMax: 20, linkPdf: 'dva_onepager/dva_onlineterminierung.pdf' },
	{ label: 'mAZ', key: 'MAZ', group: 'beratung', barMax: 10, linkPdf: 'dva_onepager/dva_meineallianz.pdf' },
	{ label: 'eSign', key: 'ESIGN', group: 'beratung', barMax: 5, linkPdf: 'dva_onepager/dva_esign.pdf' },

	{ label: 'eKomi', key: 'EKOMI', group: 'vertrauen', barMax: 40, linkPdf: 'dva_onepager/dva_ekomi.pdf' },

	{ label: 'Webex', key: 'GTM', group: 'erreichbarkeit', barMax: 10, linkPdf: 'dva_onepager/dva_webex.pdf' },
	{ label: 'Whatsapp', key: 'WA', group: 'erreichbarkeit', barMax: 20, linkPdf: 'dva_onepager/dva_whatsapp.pdf' },
];

export const DigifatktorOnePager = observer(() => {
	const { berichteUiiStore } = useStore();
	const currentPath = berichteUiiStore.menu.currentPath;
	const [link, setLink] = useState<string | undefined>();
	const [label, setLabel] = useState<string | undefined>();

	useEffect(() => {
		const item = onePages.find((i) => i.key === currentPath);
		if (item && item.linkPdf) {
			setLabel(item.label);
			setLink(item.linkPdf);
		} else {
			setLink(undefined);
			setLabel(undefined);
		}
	}, [currentPath]);

	if (!link) {
		return <></>;
	}

	return (
		<>
			<a href={link} target="_blank" rel="noreferrer" className="button is-small has-icon is-inverted">
				<span>ONEPAGER {label}</span> <Icon iconClass="external-link-square" />
			</a>
		</>
	);
});
