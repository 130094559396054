import Widget, { IWidgetConsumer } from 'app/components/common/Widget';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { AgtExpertisenList } from '../expertise/AgtExpertisenList';
import { AgtCustomExpertisenEdit } from '../expertise/AgtCustomExpertisenEdit';

export const AgenturExpertisenWidget = observer((props: IWidgetConsumer) => {
	const { agenturUiStore } = useStore();
	const agt = agenturUiStore.current;
	return (
		<Widget title={'Expertise'} modifier="is-paddingless" widgetId={props.widgetKey}>
			{agt && (
				<div>
					<AgtExpertisenList />
					<AgtCustomExpertisenEdit />
				</div>
			)}
		</Widget>
	);
});
