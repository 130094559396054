import { Modal } from 'app/components/Modal';
import { Button } from 'app/components/common/Button';
import { notify } from 'app/components/common/notify';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';

const AdminOrgaUpdate = observer(() => {
	const { adminUserUiStore, uiStore } = useStore();
	const onClose = () => {
		uiStore.hideModal(uiStore.modalIds.adminUpdateOrga);
	};

	const updateOrgaInfo = async () => {
		await adminUserUiStore.updateOrgaInfo();
		notify('Orga Update wurde ausgeführt', 'Info');
		onClose();
	};

	const updateOrgaInfoWithMultisAgentur = async () => {
		await adminUserUiStore.updateOrgaInfoWithMultisAgentur();
		notify('Orga Update MIT Multis wurde gestartet', 'Info');
		onClose();
	};
	return (
		<>
			<div className="content">
				<p>
					<strong>Orga Update beinhaltet:</strong>
				</p>
				<ul>
					<li>Fehler in den Gebieten der AUP Lieferung korrigieren</li>
					<li>VD und GS Info der Benutzer korrigieren</li>
					<li>Setzen der Stearten für die Benutzer aus der AUP Lieferung.</li>
					<li>Agentur Count aktualisieren (nur für Admin relevant)</li>
					<li>Sicherstellen, dass Agentur zu Benutzer Beziehung korrekt ist</li>
				</ul>
				<p>
					Bei Orga Update mit Multi: Wie oben nur das zusätzlich der Mulits Agentur Job nochmal ausgeführt wird. Dadurch werden alle Benutzer/Agenturen Beziehungen und
					Stearten - Relationen sichergestellt.
				</p>
				<p>Orga Update MIT Multi Bezug bitte ausführen wenn, neuer Multi angelegt wurde, Multi deaktiviert wurde oder die Steart eines Multis sich geändert hat.</p>
			</div>

			<div className="modal-footer">
				<Button className="button" onClick={updateOrgaInfo}>
					Orga Update OHNE Multi Bezug
				</Button>
				<Button className="button" onClick={updateOrgaInfoWithMultisAgentur}>
					Orga Update MIT Multi Bezug
				</Button>
			</div>
		</>
	);
});

export const AdminUpdateOrgaModal = observer(() => {
	const { uiStore } = useStore();
	const open = () => {
		uiStore.showModal(uiStore.modalIds.adminUpdateOrga);
	};

	return (
		<>
			<Button type="button" className="button is-small" onClick={open}>
				Orga Update
			</Button>
			<Modal title="Orga Update" modalId={uiStore.modalIds.adminUpdateOrga}>
				<AdminOrgaUpdate />
			</Modal>
		</>
	);
});
