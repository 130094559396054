import { AgenturModel } from 'app/models/agentur.model';
import { makeObservable, observable } from 'mobx';
import { formatNum } from 'app/utils';
import { Bar, Label, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { AgtBestandHistoryVM } from 'app/models/berichte/agt.bestand.history.vm';
import colors from 'assets/scss/colors.module.scss';
import { AgtBestandPosModel } from 'app/models/berichte/agt.bestand.pos.model';

interface IBtStueckTimeModel {
	title: string;
	agentur?: AgenturModel;
	data?: AgtBestandHistoryVM[];
	fill: string;
	btKey: string;
	btLabel: string;
	stKey: string;
	stLabel: string;
	timeKey: string;
	timeLabel: string;
}
export class BtStueckTimeModel {
	constructor(opts: IBtStueckTimeModel) {
		makeObservable(this);
		this.title = opts.title;
		if (opts.agentur) {
			this.agentur = opts.agentur;
		}
		if (opts.data) {
			this.data = opts.data;
		}
		this.fill = opts.fill;
		this.btKey = opts.btKey;
		this.btLabel = opts.btLabel;
		this.stKey = opts.stKey;
		this.stLabel = opts.stLabel;
		this.timeKey = opts.timeKey;
		this.timeLabel = opts.timeLabel;
	}
	title: string;
	agentur?: AgenturModel;
	@observable
	data: AgtBestandHistoryVM[] | AgtBestandPosModel[] = [];
	fill: string = 'blue';
	btKey: string;
	btLabel: string;
	stKey: string;
	stLabel: string;
	timeKey: string;
	timeLabel: string;
}

export const BtStueckTimeModelChart = (parms: BtStueckTimeModel) => {
	// Width at 99% makes responsiveness work when scaling the screen down
	return (
		<ResponsiveContainer width={'99%'} height={300} debounce={300}>
			<ComposedChart
				data={parms.data}
				margin={{
					top: 20,
					right: 20,
					bottom: 20,
					left: 20,
				}}
			>
				<XAxis dataKey={parms.timeKey} />
				<YAxis yAxisId={1} dataKey={parms.btKey} orientation="left" tickFormatter={formatNum}>
					<Label value={parms.btLabel} offset={-10} position="insideLeft" angle={-90} />
				</YAxis>
				<YAxis yAxisId={2} dataKey={parms.stKey} orientation="right" label={{ value: parms.stLabel, angle: -90, offset: 20, position: 'insideRight' }} tickFormatter={formatNum} />
				<Bar name={parms.btLabel} yAxisId={1} dataKey={parms.btKey} fill={colors.primary} barSize={40} />
				<Line name={parms.stLabel} yAxisId={2} connectNulls type="monotone" dataKey={parms.stKey} fill={colors.black} stroke={colors.black} />
				<Legend />
				<Tooltip />
			</ComposedChart>
		</ResponsiveContainer>
	);
};
