import { Button } from 'app/components/common/Button';
import { Icon } from 'app/components/common/Icon';
import { useStore } from 'app/context';
import { HandlunsgsempfehlungModel, ThemaModel, ThemenGroupModel } from 'app/models/brandenburger-tor/brandeburger.tor.data';
import { PlanThemenTreeVM } from 'app/stores/ui/agt.plan.themen.ui.store';
import { autorun, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { useEffect, useRef, useState } from 'react';
import { TaskViewerModal } from 'app/components/tasks/TaskViewModal';
import { TaskThema } from 'app/stores/bnr.task.store';
import { AgtPlanTasks } from './AgtPlanungsTasks';
import { AddEigenesThemaButton, AddEigenesThemaModal } from './AgtPlanungThemenAddThema';
import { AgtPlanungsZiele } from './AgtPlanungsZieleWidget';
import { InputControl } from 'app/components/common/InputControl';
import { Modal } from 'app/components/Modal';

interface ICreateTaskButton {
	text?: string;
	tree: PlanThemenTreeVM;
	group: ThemenGroupModel;
	thema: ThemaModel;
	empfehlung?: HandlunsgsempfehlungModel;
}
const CreateTaskButton = observer((props: ICreateTaskButton) => {
	const { taskUiStore, uiStore, agtPlanThemenUiStore } = useStore();

	const addMassnahme = () => {
		const { agt, plan } = agtPlanThemenUiStore.agtPlanUiStore.current!;
		const task = taskUiStore.addMassnahme(agt, plan);
		runInAction(() => {
			props.thema.selected = true;
			if (props.empfehlung) {
				task.task.text = props.empfehlung.title;
			} else {
				task.task.text = 'Neue Maßnahme für ' + props.thema.title;
			}
			task.task.thema = new TaskThema({
				key: props.thema.key,
				title: props.thema.title,
			});
		});
		taskUiStore.setCurrentModalTask(task);
		uiStore.showModal(uiStore.modalIds.taskViewModal);
	};

	return (
		<>
			<Button className="button" onClick={addMassnahme}>
				{props.text && <span>{props.text}</span>}
				{props.empfehlung && !props.text && <span>{props.empfehlung.title}</span>}
			</Button>
		</>
	);
});

interface IThemenGroupItem {
	tree: PlanThemenTreeVM;
	group: ThemenGroupModel;
}

interface IHiglight {
	highlight: boolean;
}

const HighlightIcon = observer((props: IHiglight) => {
	// return <>{props.highlight && <Icon iconClass="trash" />}</>;
	return (
		<>
			{props.highlight && (
				<span className="highlight-icon">
					<Icon iconClass="dot-circle" />
				</span>
			)}
		</>
	);
});

const ThemenGroupItem = observer((props: IThemenGroupItem) => {
	const [isActive, setIsActive] = useState<boolean>(false);
	const group = props.group;
	const tree = props.tree;
	useEffect(() => {
		if (tree.currentGroupKey === group.key) {
			setIsActive(true);
		} else {
			setIsActive(false);
		}
	}, [tree.currentGroupKey, group.key]);
	const setActive = () => {
		tree.setCurrentGroup(group);
	};

	return (
		<>
			<div className={`themen-list-item ${isActive ? 'is-active' : ''}`}>
				<HighlightIcon highlight={group.highlight} />
				<Button disabled={isActive} onClick={setActive} className="button">
					<span>{group.title}</span>
				</Button>
				<Icon iconClass="chevron-right" />
			</div>
		</>
	);
});

interface IThemaItem {
	tree: PlanThemenTreeVM;
	group: ThemenGroupModel;
	thema: ThemaModel;
}

const ThemaItem = observer((props: IThemaItem) => {
	const [isActive, setIsActive] = useState<boolean>(false);
	const { uiStore } = useStore();

	const thema = props.thema;
	const tree = props.tree;

	useEffect(() => {
		if (tree.currentThemaKey === thema.key) {
			setIsActive(true);
		} else {
			setIsActive(false);
		}
	}, [tree.currentThemaKey, thema.key]);

	const setActive = () => {
		tree.setCurrentThema(thema);
	};

	const onCheckChange = (name: string, val: boolean) => {
		runInAction(() => {
			thema.selected = val;
		});
	};

	const modalId = uiStore.modalIds.apThemaDeleteCustom + thema.key;
	const deleteThema = () => {
		uiStore.showModal(modalId);
	};

	return (
		<>
			<div className={`themen-list-item has-checkbox ${thema.isEigenesThema ? 'has-trash' : ''} ${isActive ? 'is-active' : ''}`}>
				<HighlightIcon highlight={thema.highlight} />
				<Button className="button is-icon" onClick={() => onCheckChange(thema.key, !thema.selected)}>
					{thema.selected ? <Icon iconClass="check-square" /> : <Icon iconClass="square" faModifier="far" />}
				</Button>
				<Button type="button" disabled={isActive} onClick={setActive} className="button ">
					<span>{thema.title}</span>
				</Button>
				{thema.isEigenesThema && (
					<Button className="button is-icon" onClick={deleteThema}>
						<Icon iconClass={'trash'} />
					</Button>
				)}
				<Icon iconClass="chevron-right" />
				<ConfirmDeleteThemaModal {...props} modalId={modalId} />
			</div>
		</>
	);
});

interface IConfirmDeleteThemaModal extends IThemaItem {
	modalId: string;
}

const ConfirmDeleteThemaModal = observer((props: IConfirmDeleteThemaModal) => {
	const { uiStore, agtPlanThemenUiStore } = useStore();

	const [canDelete, setCanDelete] = useState(false);
	useEffect(() => {
		const tasks = agtPlanThemenUiStore.treeTasks.filter((t) => t.task.thema && t.task.thema.key === props.thema.key);
		setCanDelete(false);
		if (tasks.length === 0) {
			setCanDelete(true);
		}
	}, [agtPlanThemenUiStore.treeTasks, props.thema.key]);

	const modalId = props.modalId;

	const onConfirm = async () => {
		runInAction(() => {
			props.group.children.remove(props.thema);
			uiStore.hideModal(modalId);
		});
	};
	const onCancel = () => {
		runInAction(() => {
			uiStore.hideModal(modalId);
		});
	};

	return (
		<>
			<Modal modalId={modalId} title={'Bestätigung erforderlich'} onClose={onCancel}>
				<div>
					<div>
						{canDelete && <>Bitte bestätigen Sie das Löschen dieses Themas</>}

						{!canDelete && <>Dieses Thema kann nicht gelöscht werden, da ihm noch Maßnahmen zugeordnet sind.</>}
					</div>
					<div className="modal-footer">
						<Button type="button" className="button is-red " onClick={onConfirm} disabled={!canDelete}>
							Löschen
						</Button>
						<Button type="submit" className="button is-secondary" onClick={onCancel}>
							Abbrechen
						</Button>
					</div>
				</div>
			</Modal>
		</>
	);
});

interface IEmpfehlungeItem {
	tree: PlanThemenTreeVM;
	group: ThemenGroupModel;
	thema: ThemaModel;
	empfehlung: HandlunsgsempfehlungModel;
}

const EmpfehlungItem = observer((props: IEmpfehlungeItem) => {
	const empfehlung = props.empfehlung;
	return (
		<>
			<div className="themen-list-item is-create-task">
				<HighlightIcon highlight={empfehlung.highlight} />
				<CreateTaskButton {...props}></CreateTaskButton>
				<Icon iconClass="plus-circle" faModifier="far" />
			</div>
		</>
	);
});

interface IAgtPlanungThemenAuswahl {
	tree: PlanThemenTreeVM;
}

export const ThemenGroupsColumn = observer((props: IAgtPlanungThemenAuswahl) => {
	const tree = props.tree;
	return (
		<>
			{tree.themenGroups.map((group) => (
				<ThemenGroupItem key={group.key} tree={tree} group={group} />
			))}
		</>
	);
});

export const ThemenColumn = observer((props: IAgtPlanungThemenAuswahl) => {
	const tree = props.tree;
	return (
		<>
			{tree.currentThemen.map((thema) => (
				<ThemaItem key={thema.key} tree={tree} group={tree.currentGroup!} thema={thema} />
			))}
			{tree.currentGroup && tree.currentGroup.isEigene && (
				<>
					<AddEigenesThemaButton tree={tree} group={tree.currentGroup!} />
					<AddEigenesThemaModal tree={tree} group={tree.currentGroup!} />
				</>
			)}
		</>
	);
});

export const EmpfehlungenColumn = observer((props: IAgtPlanungThemenAuswahl) => {
	const tree = props.tree;
	return (
		<>
			{tree.currentThema && (
				<>
					{tree.currentEmpfehlungen.length > 0 && <>Erstellen Sie Maßnahmen aus den folgenden Handlungsempfehlungen:</>}
					{tree.currentEmpfehlungen.map((empf) => (
						<EmpfehlungItem key={empf.key} tree={tree} group={tree.currentGroup!} thema={tree.currentThema!} empfehlung={empf} />
					))}
					{tree.currentGroup && tree.currentGroup.isEigene && (
						<>
							<span>Es gibt keine Handlungsempfehlungen für eigene Themen</span>
							<div className="themen-list-item is-create-task">
								<CreateTaskButton {...props} thema={tree.currentThema} group={tree.currentGroup} text={`Neue Maßnahme für ${tree.currentThema.title} anlegen`} />
								<Icon iconClass="plus-circle" faModifier="far" />
							</div>
						</>
					)}
				</>
			)}
		</>
	);
});

export const TasksColumn = observer((props: IAgtPlanungThemenAuswahl) => {
	const { agtPlanThemenUiStore } = useStore();
	return <AgtPlanTasks tasks={agtPlanThemenUiStore.treeTasksFiltered} />;
});

const Search = observer((props: IAgtPlanungThemenAuswahl) => {
	const { agtPlanThemenUiStore } = useStore();
	const [search, setSearch] = useState(agtPlanThemenUiStore.treeSearchText);

	const onTextChange = (name: string, val: string) => {
		runInAction(() => {
			setSearch(val);
			agtPlanThemenUiStore.setTreeSearchText(val);
		});
	};

	return (
		<>
			<div className="text-input">
				<InputControl name="fullText" value={search} onChange={onTextChange} debounceTime={250} placeholder={'Themen und Massnahmen durchsuchen'} allowClear={true} />
			</div>
		</>
	);
});

export const AgtPlanungThemenAuswahl = observer((props: IAgtPlanungThemenAuswahl) => {
	const { agtPlanUiStore, agtPlanThemenUiStore } = useStore();
	const tree = props.tree;

	// scroll the table into view when used
	const themenTableRef = useRef<HTMLDivElement>(null);
	useEffect(() => {
		return autorun(
			() => {
				if (themenTableRef.current && (tree.currentThema || tree.currentGroup)) {
					themenTableRef.current.scrollIntoView();
				}
			},
			{ delay: 50 },
		);
	});

	return (
		<>
			<div className="header-section">
				<div className="ziele-section">
					<div className="label">Ziele</div>
					<AgtPlanungsZiele ziele={agtPlanUiStore.current!.plan.ziele} />
				</div>
				<div className="search-section">
					<div className="label">Suche</div>
					<Search {...props} />
				</div>
			</div>

			<div ref={themenTableRef} className="scroller"></div>
			<div className={`themen-auswahl ${agtPlanThemenUiStore.treeSearchText ? 'has-active-search' : ''}`}>
				<div className="themen">
					<div className="themen-col">
						<div className="header">Gruppe</div>
						<div className="themen-list">
							<ThemenGroupsColumn tree={tree} />
						</div>
					</div>
					<div className="themen-col">
						<div className="header">Themen</div>
						<div className="themen-list">
							<ThemenColumn tree={tree} />
						</div>
					</div>
					<div className="themen-col">
						<div className="header">Handlungsempfehlungen</div>
						<div className="themen-list">
							<EmpfehlungenColumn tree={tree} />
						</div>
					</div>
				</div>

				<div className="massnahmen">
					<div className="themen-col">
						<div className="header">Maßnahmen</div>
						<div className="themen-list">
							<TasksColumn tree={tree} />
						</div>
					</div>
				</div>
			</div>

			<TaskViewerModal showAgt={false} showPlan={false} />
		</>
	);
});
