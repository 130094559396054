import React from 'react';
import BaseContainer from 'app/containers/BaseContainer';
import { observer } from 'mobx-react';
import { MainMenu } from './MainMenu';
import { RouteComponentProps } from 'react-router-dom';
import { FeedbackForm } from 'app/components/FeedbackForm';

const FeedbackContainer = observer((props: RouteComponentProps) => {
	return (
		<BaseContainer sideMenu={<MainMenu {...props} />} navbar={<div>Feedback</div>}>
			<FeedbackForm />
		</BaseContainer>
	);
});

export default FeedbackContainer;
