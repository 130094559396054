import { computed } from 'mobx';
import { FindSteart, SteartModel } from '../core/steart.model';
import { PersonalVM } from './personal.vm';

export class ZweitverkaeuferModel {
	constructor(opts: any) {
		this.id = opts.id;
		this.agtId = opts.agtId;
		this.angelegt = opts.angelegt;
		this.bart = opts.bart;
		this.beendet = opts.beendet;
		this.beginn = new Date(opts.beginn);
		this.begzuord = new Date(opts.begzuord);
		this.bgznbNr = opts.bgznbNr;
		this.bnr = opts.bnr;
		this.bnrId = opts.bnrId;
		this.bnrcId = opts.bnrcId;
		this.countBnrVtnr = opts.countBnrVtnr;
		this.einsatzqBnrb = opts.einsatzqBnrb;
		this.einsatzqBnrc = opts.einsatzqBnrc;
		this.ende = new Date(opts.ende);
		this.endzuord = new Date(opts.endzuord);
		this.gebdat = new Date(opts.gebdat);
		this.jubdat = new Date(opts.jubdat);
		this.kat = opts.kat;
		this.lebend = opts.lebend;
		this.name = opts.name;
		this.rkBnrVtnr = opts.rkBnrVtnr;
		this.steart = FindSteart(opts.stellenart);
		this.taetqPnr = opts.taetqPnr;
		this.teilzeitqBnrb = opts.teilzeitqBnrb;
		this.teilzeitqBnrc = opts.teilzeitqBnrc;
	}

	agtId: number;
	angelegt: number;
	bart: number;
	beendet: number;
	beginn: Date;
	begzuord: Date;
	bgznbNr: number;
	bnr: number;
	bnrId: number;
	bnrcId: number;
	countBnrVtnr: number;
	einsatzqBnrb: number;
	einsatzqBnrc: number;
	ende: Date;
	endzuord: Date;
	gebdat: Date;
	id: string;
	jubdat: Date;
	kat: string;
	lebend: number;
	name: string;
	rkBnrVtnr: number;
	steart: SteartModel;
	taetqPnr: number;
	teilzeitqBnrb: number;
	teilzeitqBnrc: number;

	@computed
	get identifier() {
		const agtId = this.agtId;
		if (!agtId) {
			return this.name + '-' + this.gebdat;
		}
		return this.agtId + '-' + this.bnr;
	}

	@computed
	get vorname() {
		const n = this.name;
		if (!n) {
			return '';
		}
		const s = n.split(',');
		return s[s.length - 1];
	}

	@computed
	get nachname() {
		const n = this.name;
		if (!n) {
			return '';
		}
		return n.split(',')[0];
	}

	toPersonalModel(): PersonalVM {
		return new PersonalVM({
			kat: this.kat,
			id: this.id,
			agtId: this.agtId,
			bnrId: this.bnrId,
			bnr: this.bnr,
			gebDat: this.gebdat,
			vorname: this.vorname,
			nachname: this.nachname,
			beitrittDat: this.beginn,
			steart: this.steart,
			jubDat: this.jubdat,
		});
	}
}
