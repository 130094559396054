import { useEffect, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { AgtPlanModel, AgtPlanThemaModel } from 'app/stores/agt.plan.store';
import { TaskVM } from 'app/stores/ui/task.ui.store';

interface ITaskThemaSelectField {
	name: string;
	plan?: AgtPlanModel;
	task: TaskVM;
}

export const TaskThemaSelectField = (props: ITaskThemaSelectField) => {
	const task = props.task;
	const [val, setVal] = useState<string>('');
	const { getFieldMeta, setFieldValue } = useFormikContext();
	const themen = props.plan ? props.plan.themen : undefined;
	const tt = task.task.thema;
	if (tt && themen) {
		const ttExists = themen!.themen.find((t) => t.key === tt.key);
		if (!ttExists) {
			themen.themen.push(new AgtPlanThemaModel(tt));
		}
	}

	useEffect(() => {
		const v = getFieldMeta(props.name).value as string;
		setVal(v);
	}, [setVal, getFieldMeta, props.name]);

	const onSelect = (val: any) => {
		setVal(val);
		setFieldValue(props.name, val);
	};

	return (
		<>
			{themen && !task.isNew && (
				<div className="select">
					<>
						<Field name={props.name} as="select" value={val} onSelect={onSelect}>
							<option value={undefined}>Keine Auswahl </option>
							{themen.themen.map((p) => (
								<option key={p.key} value={p.key}>
									{p.title}
								</option>
							))}
						</Field>
					</>
				</div>
			)}

			{task.isNew && task.task.thema && <>{task.task.thema.title}</>}
		</>
	);
};
