import { observer } from 'mobx-react';
import { IAgtErrungenschaftYesNoGSB } from './BnrErrungenschaften';
import { ErrungenschaftIcon, ErrungenschaftIconType } from './ErrungenshaftIcon';

interface IAgtErrungenschaftYesNo extends IAgtErrungenschaftYesNoGSB {
	icon: ErrungenschaftIconType;
}

export const ErrungenschaftYesNo = observer((props: IAgtErrungenschaftYesNo) => {
	return <ErrungenschaftIcon {...props} />;
});
