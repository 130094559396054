import { computed } from 'mobx';
import moment from 'moment';
import { AgenturModel } from '../agentur.model';
import { AgtBestandPosModel, IAgtBestandPosDef } from './agt.bestand.pos.model';
import { PosBjModel } from './pos.bj.model';

const bmToIndex = (bm: Date): number => {
	const today = moment().year();
	const mom = moment(bm).year();
	return today - mom;
};

export class AgtBestandPos5YearVM {
	constructor(agt: AgenturModel, posDef: IAgtBestandPosDef, items: PosBjModel[], zuwachsItems: PosBjModel[] = []) {
		this.agtId = agt.agtId;
		this.agt = agt;
		this.title = posDef.title;
		this.posDef = posDef;

		items = items.sort((a, b) => b.bm - a.bm);
		items.forEach((item) => {
			const bm = moment(item.bm + '01');
			const bmpPos = new AgtBestandPosModel({ agt, posId: posDef.posId, zuwachsPosId: posDef.zuwachsPosId, title: this.title, bm: bm.toDate() });
			bmpPos.val = item.getPosval();
			const zw = zuwachsItems.find((i) => i.posId === posDef.zuwachsPosId && bm.isSame(moment(i.bm) + '01', 'd'));
			if (zw) {
				bmpPos.zuwachs = zw.getPosval();
			}
			const indx = bmToIndex(bm.toDate());
			(this as any)['year' + indx] = bmpPos;
		});
	}
	posDef: IAgtBestandPosDef;

	agtId: number;
	agt: AgenturModel;
	title: string;
	year0?: AgtBestandPosModel;
	year1?: AgtBestandPosModel;
	year2?: AgtBestandPosModel;
	year3?: AgtBestandPosModel;
	year4?: AgtBestandPosModel;

	@computed
	get asArray(): AgtBestandPosModel[] {
		let res: AgtBestandPosModel[] = [];
		for (let i = 0; i <= 4; i++) {
			if ((this as any)['year' + i] !== undefined) {
				res.push((this as any)['year' + i]);
			}
		}
		res = res.sort((a, b) => moment(a.bm).diff(b.bm, 'days'));
		return res;
	}
}
