import { ApCommissionCalcContent, ApModel, ApVermittler } from 'app/models/documents/ap.model';
import { computed } from 'mobx';
import { BaseEntityStore } from './core/base.entity.store';

export class CommisionCalcModel {
	constructor(opts: any) {
		this.erforderlicheGesamtbewertung = opts.erforderlicheGesamtbewertung;
		this.baq = opts.baq;
	}
	erforderlicheGesamtbewertung: number;
	baq: number;
}

export class CommisionCalcRes {
	constructor(opts: any) {
		this.abschlussprovision = opts.abschlussprovision;
		this.baq = opts.baq;
		this.bestandssicherungsprovision = opts.bestandssicherungsprovision;
		this.folgeprovision = opts.folgeprovision;
		this.gesamtbewertung = opts.gesamtbewertung;
	}
	abschlussprovision: number;
	baq: number;
	bestandssicherungsprovision: number;
	folgeprovision: number;
	gesamtbewertung: number;
}

export class ApGoal {
	name: string = '';
	level: number = 0;
	periods: ApGoalPeriod[] = [];
}

export class ApGoalPeriod {
	constructor(opts: any) {
		this.period = opts.period;
		this.planned = opts.planned;
		this.realized = opts.realized;
		this.completed = opts.completed;
		this.newContract = opts.newContract;
		this.followUp = opts.followUp;
	}

	period: 'year' | 'quarter' | 'month' | 'week';
	planned: number;
	realized: number;
	completed: number;
	newContract: number;
	followUp: number;

	@computed
	get periodSort() {
		switch (this.period) {
			case 'year':
				return 4;

			case 'quarter':
				return 3;

			case 'month':
				return 2;
			case 'week':
				return 1;
			default:
				return 0;
		}
	}

	@computed
	get periodLabel() {
		switch (this.period) {
			case 'year':
				return 'Jahr';

			case 'quarter':
				return 'Quartal';

			case 'month':
				return 'Monat';
			case 'week':
				return 'Woche';
			default:
				return '?';
		}
	}
}

export class BnrPlanungsToolCommisionCalcStore extends BaseEntityStore<CommisionCalcModel> {
	apiPath = 'api/commission-calc';
	idProp: string = 'id';
	createInstances(opts: any): CommisionCalcModel {
		return new CommisionCalcModel(opts);
	}

	async apCalcCommision(doc: ApCommissionCalcContent) {
		const url = 'api/commission-calc';
		await this.callRefreshTokenIfNecessary();
		const header = this.getAuthHeader();
		const res = await this.axios({
			headers: header.headers,
			url,
			method: 'POST',
			data: doc.serialize(),
		});
		return new CommisionCalcRes(res.data);
	}

	async apGoalsCalc(doc: ApModel, vermittler?: ApVermittler) {
		const url = 'api/goals-calc';
		await this.callRefreshTokenIfNecessary();
		const header = this.getAuthHeader();
		const data = doc.serialize();
		if (vermittler) {
			// vermittler is only passed for goals-calc in vermittlre ziele form before save
			// modifiing the ApModel triggers a re-render, making the form unusable
			data.vermittler.forEach((v) => {
				if (v.id === vermittler.id) {
					v.quoteAnzahlNeustueckJeTermin = vermittler.quoteAnzahlNeustueckJeTermin;
					v.quoteDurchschnittBewertungNeutsueck = vermittler.quoteDurchschnittBewertungNeutsueck;
					v.quoteDurchgefuehrteTermineMitAbschluss = vermittler.quoteDurchgefuehrteTermineMitAbschluss;
					v.quoteAnzahlNeustueckJeTermin = vermittler.quoteAnzahlNeustueckJeTermin;
					v.quoteDurchgefuehrteTermine = vermittler.quoteDurchgefuehrteTermine;
					v.vorPlan = vermittler.vorPlan;
					v.plan = parseFloat(vermittler.plan.toString());
				}
			});
		}

		const res = await this.axios({
			headers: header.headers,
			url,
			method: 'POST',
			data,
		});
		const d = res.data;
		const goals: ApGoal[] = [];
		const agency = new ApGoal();
		agency.name = 'agency';
		agency.level = d.goalsLevel.sum;
		agency.periods = d.goals.agency.map((p: any) => new ApGoalPeriod(p));
		goals.push(agency);

		doc.vermittler.forEach((v) => {
			const vgoal = new ApGoal();
			vgoal.name = v.id;
			vgoal.level = d.goalsLevel[v.id];
			vgoal.periods = d.goals[v.id].map((p: any) => new ApGoalPeriod(p));
			goals.push(vgoal);
		});
		return goals;
	}
}
