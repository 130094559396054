import Widget from 'app/components/common/Widget';
import { Table } from 'app/components/table/table';
import { TableModel, ITableColumn } from 'app/components/table/table.model';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';
import { gsByVd, gsIds, vdIds } from 'app/stores/ui/admin.usage.ui.store';
import moment from 'moment';

export const AdminUsageMatomoLogins = observer(() => {
	const { adminUsageUiStore } = useStore();

	useEffect(() => {
		adminUsageUiStore.loadLoginData();
		adminUsageUiStore.loadMatomoGeneral();
	}, [adminUsageUiStore]);

	return (
		<>
			{!adminUsageUiStore.currentFilter.vdId && <AdminUsageMatomoLoginsByVD />}
			{adminUsageUiStore.currentFilter.vdId && <AdminUsageMatomoLoginsByGS />}
		</>
	);
});

export const AdminUsageMatomoLoginsByVD = observer(() => {
	const { adminUsageUiStore } = useStore();

	const tmVd = useMemo(() => {
		const tm = new TableModel<any, number>();
		let cols: ITableColumn<any, number>[] = [
			{
				label: 'Woche',
				path: 'kw',
			},
		];

		vdIds.forEach((vd) => {
			cols.push({
				label: vd.toUpperCase(),
				path: vd.toLowerCase(),
			});
		});

		cols.push({
			label: 'Summe',
			path: 'sum',
		});

		tm.setCols(cols);
		tm.sortBy = 'name';
		tm.idProperty = 'id';
		tm.idType = 'string';
		return tm;
	}, []);

	useEffect(() => {
		const matomoData = adminUsageUiStore.matomoWeekData;
		const totalsByVd = adminUsageUiStore.registerdUserByVD;
		const totalsByGs = adminUsageUiStore.registerdUserByGs;
		const totalsByFunktion = adminUsageUiStore.registerdUserByRole;
		const weeks = adminUsageUiStore.calcWeeks;

		if (!matomoData || !totalsByVd || !totalsByGs || !totalsByFunktion) return;

		let _vdData: any[] = [];

		let normObjVd: any = {
			sum: 0,
		};

		// create data for weeks
		weeks.forEach((week, index) => {
			const weekString = `KW ${moment(week).format('W')} (${week})`;

			// VD Data
			let retObjVd: any = {};
			let sumVd = 0;

			vdIds.forEach((vd) => {
				let q = matomoData[index].find((el: any) => el.Events_EventAction === 'VD' && el.Events_EventName === vd);
				if (q) {
					const val = q ? q.nb_events : 0;
					retObjVd[vd.toLowerCase()] = val;
					normObjVd[vd.toLowerCase()] = (normObjVd[vd.toLowerCase()] || 0) + val;
					sumVd += val;
				}
			});
			normObjVd.sum += sumVd;
			_vdData.push({
				...retObjVd,
				kw: weekString,
				sum: sumVd,
			});
		});

		// VD: Data by week complete, calculate norms
		let normAngemeldet: any = {};
		let normGesamt: any = {};

		totalsByVd.forEach((t) => {
			const vd = t.name.toLowerCase();
			normAngemeldet[vd] = (t.registered > 0 ? normObjVd[vd] / t.registered : 0).toFixed(2);
			normGesamt[vd] = (t.total > 0 ? normObjVd[vd] / t.total : 0).toFixed(2);
		});

		_vdData.push(
			{
				kw: 'Summe',
				...normObjVd,
			},
			{
				kw: 'Norm. Angemeldet',
				...normAngemeldet,
			},
			{
				kw: 'Norm. Gesamt',
				...normGesamt,
			},
		);

		tmVd.setRowData(_vdData);
	}, [
		tmVd,
		adminUsageUiStore.matomoWeekData,
		adminUsageUiStore.registerdUserByGs,
		adminUsageUiStore.registerdUserByRole,
		adminUsageUiStore.registerdUserByVD,
		adminUsageUiStore.calcWeeks,
	]);

	return (
		<div className="pad-1rem">
			<Widget title="Logins je VD" modifier="is-paddingless" action={<span className="tag is-blue">Matomo</span>}>
				<Table tm={tmVd} />
			</Widget>
		</div>
	);
});

export const AdminUsageMatomoLoginsByGS = observer(() => {
	const { adminUsageUiStore } = useStore();

	const tmGs = useMemo(() => {
		const tm = new TableModel<any, number>();
		let cols: ITableColumn<any, number>[] = [
			{
				label: 'Woche',
				path: 'kw',
			},
		];

		let filteredGsIds = adminUsageUiStore.currentFilter.vdId ? gsByVd.find((gs) => gs.vdId === adminUsageUiStore.currentFilter.vdId)!.items : gsIds;
		filteredGsIds.forEach((vd) => {
			cols.push({
				label: vd.toUpperCase(),
				path: vd.toLowerCase(),
			});
		});

		cols.push({
			label: 'Summe VD',
			path: 'sum',
		});

		tm.setCols(cols);
		tm.sortBy = 'name';
		tm.idProperty = 'id';
		tm.idType = 'string';
		return tm;
	}, [adminUsageUiStore.currentFilter.vdId]);

	useEffect(() => {
		const matomoData = adminUsageUiStore.matomoWeekData;
		const totalsByVd = adminUsageUiStore.registerdUserByVD;
		const totalsByGs = adminUsageUiStore.registerdUserByGs;
		const totalsByFunktion = adminUsageUiStore.registerdUserByRole;
		const weeks = adminUsageUiStore.calcWeeks;

		if (!matomoData || !totalsByVd || !totalsByGs || !totalsByFunktion) return;

		let filteredGsIds = gsByVd.find((gs) => gs.vdId === adminUsageUiStore.currentFilter.vdId)!.items;

		let _gsData: any[] = [];
		let normObjGs: any = {
			sum: 0,
		};

		// create data for weeks
		weeks.forEach((week, index) => {
			const weekString = `KW ${moment(week).format('W')} (${week})`;

			let retObjGs: any = {};
			let sumGs = 0;
			let hasData = false;

			filteredGsIds.forEach((gs) => {
				let q = matomoData[index].find((el: any) => el.Events_EventAction === 'GS' && el.Events_EventName === gs);
				if (q) {
					hasData = true;
					const val = q ? q.nb_events : 0;
					retObjGs[gs.toLowerCase()] = val;
					normObjGs[gs.toLowerCase()] = (normObjGs[gs.toLowerCase()] || 0) + val;
					sumGs += val;
				}
			});
			if (hasData) {
				_gsData.push({
					...retObjGs,
					kw: weekString,
					sum: sumGs,
				});
			}
		});
		// GS: Data by week complete, calculate norms
		let normAngemeldetGs: any = {};
		let normGesamtGs: any = {};

		totalsByGs.forEach((t) => {
			const gs = t.name.toLowerCase();
			normAngemeldetGs[gs] = (t.registered > 0 ? normObjGs[gs] / t.registered : 0).toFixed(2);
			normGesamtGs[gs] = (t.total > 0 ? normObjGs[gs] / t.total : 0).toFixed(2);
		});

		_gsData.push(
			{
				kw: 'Summe',
				...normObjGs,
			},
			{
				kw: 'Norm. Angemeldet',
				...normAngemeldetGs,
			},
			{
				kw: 'Norm. Gesamt',
				...normGesamtGs,
			},
		);
		tmGs.setRowData(_gsData);
	}, [
		tmGs,
		adminUsageUiStore.currentFilter.vdId,
		adminUsageUiStore.matomoWeekData,
		adminUsageUiStore.registerdUserByGs,
		adminUsageUiStore.registerdUserByRole,
		adminUsageUiStore.registerdUserByVD,
		adminUsageUiStore.calcWeeks,
	]);

	return (
		<div className="pad-1rem">
			<Widget title={`Logins in VD ${adminUsageUiStore.currentFilter.vdId} nach GS`} modifier="is-paddingless" action={<span className="tag is-blue">Matomo</span>}>
				<Table tm={tmGs} />
			</Widget>
		</div>
	);
});
