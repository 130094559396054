import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import colors from 'assets/scss/colors.module.scss';
import { ECharts, EChartsOption, init } from 'echarts';
import { debounce } from 'lodash';

interface IProgressRing {
	value: number;
	color?: string;
	total: number;
	text?: string | number;
	label?: string;
	classModifier?: string;
}

const ProgressRing: React.FC<IProgressRing> = observer((props: IProgressRing) => {
	const SIZE = 50;
	const chartRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		let option: EChartsOption = {
			tooltip: {
				show: false,
			},
			legend: {
				show: false,
			},
			itemStyle: {
				borderRadius: 10,
			},
			emphasis: {
				disabled: true,
			},
			series: [
				{
					name: 'progress',
					type: 'pie',
					radius: ['70%', '90%'],
					labelLine: {
						show: false,
					},
					data: [
						{ value: props.value, itemStyle: { color: props.color || colors.blue } },
						{ value: props.total - props.value, itemStyle: { color: colors.border } },
					],
				},
			],
		};
		let chart: ECharts | null = null;
		if (chartRef.current !== null) {
			chart = init(chartRef.current, null, { renderer: 'svg' });
			chart.setOption(option);
		}

		const handleResize = debounce(() => {
			chart?.resize();
		}, 300);
		window.addEventListener('resize', handleResize);

		return () => {
			chart?.dispose();
			window.removeEventListener('resize', handleResize.cancel);
		};
	}, [chartRef, props.value, props.total, props.color]);

	return (
		<div className={`progress-ring ${props.classModifier || ''}`}>
			<div className="graphic">
				<div style={{ width: SIZE * 2 + 10, height: SIZE * 2 + 10 }} ref={chartRef}></div>
				{props.text || props.text === 0 ? <div className="text">{props.text}</div> : <></>}
			</div>
			{props.label && <div className="label">{props.label}</div>}
		</div>
	);
});

export default ProgressRing;
