import { AgenturModel } from 'app/models/agentur.model';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { SessionStore } from '../session.store';
import moment from 'moment';
import { AgenturStore } from '../agentur.store';
import { AgtPlanModel, AgtPlanStore } from '../agt.plan.store';
import Config from 'Config';

interface IAgtPlanYearModel {
	agt: AgenturModel;
	plan: AgtPlanModel;
}

export class AgtPlanYearVM {
	constructor(opts: IAgtPlanYearModel) {
		makeObservable(this);
		this.agtId = opts.agt.agtId;
		this.agt = opts.agt;
		this.plan = opts.plan;
	}
	agtId: number;
	@observable
	agt: AgenturModel;

	@observable
	plan: AgtPlanModel;

	@computed
	get year() {
		return this.plan.year;
	}
}

export class AgtPlanUiStore {
	session: SessionStore;
	agenturStore: AgenturStore;
	agtPlanStore: AgtPlanStore;

	constructor(session: SessionStore, agenturStore: AgenturStore, agtPlanStore: AgtPlanStore) {
		makeObservable(this);
		this.session = session;
		this.agenturStore = agenturStore;
		this.agtPlanStore = agtPlanStore;
	}

	@observable current?: AgtPlanYearVM;
	@computed
	get currentYear() {
		if (this.current) {
			return this.current.year;
		}
		return moment().year();
	}

	@computed
	get currentAgtId() {
		if (this.current) {
			return this.current.agtId;
		}
		return undefined;
	}

	@action
	async setCurrent(agtId: number, year: number) {
		if (this.currentAgtId === agtId && this.currentYear === year) {
			return;
		}
		await this.load();

		let current = this.items.find((i) => i.agt.agtId === agtId && i.year === year);
		if (!current) {
			// should not happen!!
		}
		if (!current!.plan.id) {
			await this.ensurePlanung(current!);
		}

		runInAction(() => {
			this.current = current;
		});
	}

	async ensurePlanung(planVm: AgtPlanYearVM) {
		const plan = await this.agtPlanStore.save(planVm.plan);
		console.log('created new plan with id ', plan.id, 'for ', planVm.year, planVm.year);
		runInAction(() => {
			planVm.plan = plan;
		});
	}

	async ensureCurrentYearPlanungExists(agtId: number) {
		if (agtId === 0) {
			return;
		}
		const currentYear = moment().year();
		await this.load();
		// carefull: the items of agt.plan.ui.store are not necessarily created yet
		// thats why we need to check agtPlanStore
		let cy = this.agtPlanStore._items.find((i) => i.agtId === agtId && i.year === currentYear);
		if (cy) {
			return;
		}
		const plan = new AgtPlanModel({
			agtId: agtId,
			bnrId: this.session.currentUser!.bnrId,
			title: currentYear.toString(),
		});
		await this.agtPlanStore.save(plan);
		this.agtPlanStore.clearCache();
		this.loaded = false;
		await this.agtPlanStore.findAll();
		await this.load();
	}

	@computed
	get planYears(): number[] {
		const currYear = moment().year();
		const res: number[] = [];
		for (let y = currYear - 2; y <= currYear; y++) {
			res.push(y);
		}
		if (Config.showFuturePlanYears) {
			if (moment().month() > 10) {
				res.push(currYear + 1);
			}
		}
		return res;
	}

	loaded: boolean = false;
	items: AgtPlanYearVM[] = [];

	async load() {
		if (this.loaded) {
			return this.items;
		}

		const data = await Promise.all([this.agenturStore.findAll(), this.agtPlanStore.findAll()]);
		const agts = data[0] as AgenturModel[];
		let plans = data[1] as AgtPlanModel[];
		plans = plans.filter((p) => this.planYears.includes(p.year));
		const res: AgtPlanYearVM[] = [];
		for (const agt of agts) {
			for (const y of this.planYears) {
				let plan = plans.find((p) => p.year === y && p.agtId === agt.agtId);
				if (!plan) {
					plan = new AgtPlanModel({
						agtId: agt.agtId,
						bnrId: this.session.currentUser!.bnrId,
						title: y.toString(),
					});
				}
				const yp = new AgtPlanYearVM({ agt, plan });
				res.push(yp);
			}
		}

		this.items = res;
		this.loaded = true;
		return res;
	}

	openJpgVorbereitungsMail(plan: AgtPlanModel) {
		const year = plan.year;
		const prevYear = year - 1;

		let eSubject = encodeURI('Jahresgespräch ' + year);
		let link2 = this.agtPlanStore.buildPublicUrl('Jahresplanungsgespraech_2024_Handlungsempfehlungen.pdf');
		if (link2.indexOf('/') === 0) {
			link2 = 'https://dvs-app.de' + link2;
		}

		let eBody = encodeURI(
			'Liebe(r) ,' +
				'\n' +
				'\n' +
				'in Kürze werden wir unser Jahresplanungsgespräch (JPG) führen.' +
				'\n' +
				'Bitte bereiten Sie sich darauf vor, so dass wir die eingeplante Zeit effektiv nutzen können.' +
				'\n' +
				'Seit diesem Jahr folgt das Jahresplanungsgespräch einer neuen Struktur, die stärker die Ziele der Agenturinhaber und die Themen über das gesamte Jahr berücksichtigt.' +
				'\n' +
				'Schauen Sie sich hierfür im Vorfeld die in Themen unter dem folgenden Link an und setzen für sich erste Schwerpunkte, um Ihren Erfolg dieses Jahr sicherzustellen. Dazu ist es auch wichtig, dass Sie sich überlegen, an welcher Stelle Sie meine bzw. die Unterstützung der Geschäftsstelle benötigen. Gemeinsam werden wir auf dieser Grundlage unsere gemeinsame Zusammenarbeit aufbauen und die für Sie relevanten Themen angehen.' +
				'\n' +
				'Themenübersicht: ' +
				link2 +
				'\n\n' +
				//'Vorlage Jahresplanungsgrespräch: ' +link2 +'\n\n' +
				'Im Einzelnen wird das Jahresplanungsgespräch aus den folgenden Komponenten bestehen:' +
				'\n\n' +
				'Rückblick auf ' +
				prevYear +
				':' +
				'\n' +
				'• Wie hat sich Ihre Agentur qualitativ und quantitativ entwickelt?' +
				'\n' +
				'• Was hat Sie persönlich darüber hinaus bewegt?' +
				'\n\n' +
				'Ausblick auf ' +
				year +
				':' +
				'\n' +
				'• Welche Ziele haben Sie für sich und Ihre Agentur?' +
				'\n' +
				'• Mit welchen Themen wollen Sie diese Ziele konkret verfolgen?' +
				'\n' +
				'• Befüllung des Planungstools' +
				'\n\n' +
				'Ich wünsche Ihnen schon jetzt gute Geschäftsergebnisse und ein erfolgreiches Jahr ' +
				year +
				'.',
		);

		let email = 'mailto:?subject=' + eSubject + '&body=' + eBody;
		window.location.href = email;
	}
}
