import { useEffect, useMemo } from 'react';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { Table } from './../../table/table';
import { ITableColumn, TableModel } from './../../table/table.model';
import { BnrBlacklistModel } from 'app/stores/admin.bnrblacklist.store';

export const AdminBnrBlacklistList = observer(() => {
	const { adminBnrBlacklistUiStore } = useStore();
	// const history = useHistory();

	const tm = useMemo(() => {
		// const viewDoc = (doc: BnrBlacklistModel) => {
		// 	runInAction(() => {
		// 		history.push(routes.ADMINTEMPLATES.getPath('edit', doc.template.id));
		// 	});
		// };

		const tm = new TableModel<BnrBlacklistModel, number>();
		let cols: ITableColumn<BnrBlacklistModel, number>[] = [
			{
				label: 'BnrId',
				path: 'bnrId',
			},
			{
				label: 'Kommentart',
				path: 'comment',
			},
		];

		// tm.onRowClick = (row: TableRow<BnrBlacklistModel>) => {
		// 	viewDoc(row.data);
		// };

		tm.setCols(cols);
		tm.sortBy = 'bnrId';
		tm.sortAsc = false;
		tm.idProperty = 'bnrId';
		tm.idType = 'string';
		return tm;
	}, []);

	useEffect(() => {
		adminBnrBlacklistUiStore.findAll().then(() => {
			tm.setRowData(adminBnrBlacklistUiStore.currentItems);
		});
		tm.setRowData(adminBnrBlacklistUiStore.currentItems);
	}, [adminBnrBlacklistUiStore, adminBnrBlacklistUiStore.currentItems, tm]);

	return (
		<>
			<Table tm={tm}></Table>
		</>
	);
});
