import { computed, makeObservable, observable, runInAction } from "mobx";
import { KvAgtGoogleProfileStore } from "../kv.agt.googleprofile.store";
import { AgenturStore } from "../agentur.store";
import { ISelectOpt } from "app/components/common/Select";
import { formatBM } from "app/utils";
import { AgenturModel } from "app/models/agentur.model";
import { KvAgtMsModel } from "app/models/kv.agt.ms.model";
import _ from "lodash";


export class GoogleProfileBm {
    bm: number;
    bew: number;
    bewAvg: number;
    imgCount: number;
    qCount: number;
    constructor(bm: number, bew: number, bewAvg: number, imgCount: number, qCount: number) {

        this.bm = bm;
        this.bew = bew;
        this.bewAvg = bewAvg;
        this.imgCount = imgCount;
        this.qCount = qCount;
    }
}


export class GoogleProfileVM {
    agt: AgenturModel;
    @observable
    items: GoogleProfileBm[] = [];
    constructor(agt: AgenturModel, kvs: KvAgtMsModel[]) {
        makeObservable(this);
        this.agt = agt;
        for (const kv of kvs) {
            if (!kv.valueString) {
                continue;
            }
            const j = JSON.parse(kv.valueString);
            const kw = new GoogleProfileBm(kv.bm, j.bew, j.bewAvg, j.imgCount, j.qCount);
            this.items.push(kw);
        }


    }

    @computed
    get bew() {
        return this.items[0].bew;
    }

    @computed
    get bewAvg() {
        return this.items[0].bewAvg;
    }

    @computed
    get imgCount() {
        return this.items[0].imgCount;
    }

    @computed
    get qCount() {
        return this.items[0].qCount;
    }
}

export class AgtGoogleProfileUiStore {
    kgAgtGoogleProfileStore: KvAgtGoogleProfileStore;
    agenturStore: AgenturStore;

    constructor(agenturStore: AgenturStore, kgAgtGoogleProfileStore: KvAgtGoogleProfileStore) {
        makeObservable(this);

        this.kgAgtGoogleProfileStore = kgAgtGoogleProfileStore;
        this.agenturStore = agenturStore;
    }
    @observable
    bms: number[] = [];


    @computed
    get bmOpts(): ISelectOpt[] {
        return this.bms.map((bm) => {
            return {
                value: bm,
                label: formatBM(bm),
            };
        });
    }

    @observable
    allVmItems: GoogleProfileVM[] = [];


    @observable
    bmCurrent?: number;

    @computed
    get currentBMItems() {
        const kvVmItems: GoogleProfileVM[] = [];
        this.allVmItems.forEach((vm) => {
            const f = vm.items.filter((i) => {
                if (this.bmCurrent == i.bm) {
                    return true;
                }
                return false;
            });
            const r = new GoogleProfileVM(vm.agt, []);
            runInAction(() => {
                r.items = f;
            });
            kvVmItems.push(r);
        });

        return kvVmItems;
    }

    async load() {
        if (this.allVmItems.length > 0) {
            return;
        }
        const data = await this.kgAgtGoogleProfileStore.findAll();
        const agts = await this.agenturStore.findAll();
        const res: GoogleProfileVM[] = [];
        for (const agt of agts) {
            const kvs = data.filter((d) => d.agtId === agt.agtId);
            if (kvs.length > 0) {
                const vm = new GoogleProfileVM(agt, kvs);
                res.push(vm);
            }
        }
        runInAction(() => {
            this.bms = _.uniq(_.map(data, 'bm')).sort();
            this.bmCurrent = this.bms[0];
            this.allVmItems = res;
        });
    }




}