import { useEffect, useMemo } from 'react';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { Table } from './../../table/table';
import { ITableColumn, TableModel, TableRow } from './../../table/table.model';
import { runInAction } from 'mobx';
import { useHistory } from 'react-router';
import routes from 'routes';
import { ImportJobVM } from 'app/stores/ui/admin.imports.ui.store';
import { formatDateLong } from 'app/utils';
import { Icon } from 'app/components/common/Icon';
import { Button } from 'app/components/common/Button';

export const AdminImportJobsList = observer(() => {
	const { adminImportsUiStore } = useStore();
	const history = useHistory();

	const tm = useMemo(() => {
		const viewDoc = (doc: ImportJobVM) => {
			runInAction(() => {
				history.push(routes.ADMINIMPORTS.getPath('job', doc.job.id));
			});
		};

		const tm = new TableModel<ImportJobVM, number>();
		let cols: ITableColumn<ImportJobVM, number>[] = [
			{
				label: 'Quelle',
				path: 'job.dataType',
			},
			{
				label: 'Datum',
				path: 'job.created',
				format: (val) => formatDateLong(val),
			},
			{
				label: 'KW',
				path: 'KW',
			},
			{
				label: 'Status',
				path: 'job.status',
			},
			{
				label: 'Dauer',
				path: 'job.duration',
			},
			{
				label: 'Fehler',
				path: 'fehler',
				render: (row: TableRow<ImportJobVM>) => <>{row.data.job.hasError && <Icon iconClass={'bomb'} />}</>,
			},
		];

		tm.onRowClick = (row: TableRow<ImportJobVM>) => {
			viewDoc(row.data);
		};

		tm.setCols(cols);
		tm.sortBy = 'job.created';
		tm.sortAsc = false;
		tm.idProperty = 'id';
		tm.idType = 'string';
		return tm;
	}, [history]);

	useEffect(() => {
		adminImportsUiStore.findAll();
		tm.setRowData(adminImportsUiStore.currentItems);
	}, [adminImportsUiStore, adminImportsUiStore.currentItems, tm]);
	const toggleAutoRefresh = () => {
		adminImportsUiStore.toggleAutoRefresh();
	};

	return (
		<>
			<div className="pad-1rem">
				<Button className="button is-small" onClick={toggleAutoRefresh}>
					{adminImportsUiStore.autoRefreshEnabled && <>Stop Autorefresh</>}
					{!adminImportsUiStore.autoRefreshEnabled && <>Start Autorefresh</>}
				</Button>{' '}
				{adminImportsUiStore.autoRefreshEnabled && <Icon iconClass="circle-notch fa-spin" />}
			</div>
			<Table stickyHeader={true} tm={tm}></Table>
		</>
	);
});
