import { useStore } from 'app/context';
import { WochemailMailRequest } from 'app/stores/user.settings.store';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { Button } from '../common/Button';
import { CheckboxControl } from '../common/CheckboxControl';
import { notify } from '../common/notify';

interface IWochenmailSend {
	kw: string;
	htmlRef: React.RefObject<HTMLDivElement>;
	textRef: React.RefObject<HTMLDivElement>;
}


export const WochenmailSend = observer((props: IWochenmailSend) => {
	const { wochenmailtUiStore, userSettingsStore, agtErrungenschaftenUiStore } = useStore();

	const [sent, setSent] = useState(false);
	const [force, setforce] = useState(false);

	const req = new WochemailMailRequest();
	req.kw = agtErrungenschaftenUiStore.currentKw;
	req.subject = 'DVS Update ';
	req.bodyText = 'Normal Body text';
	req.bodyHtml = '<strong>This is Html</strong>';


	const onButtonClick = async () => {
		if (!wochenmailtUiStore.hasChangesThisWeek && !force) {
			console.log('nothing to send. done');
			setSent(true);
			// nothing to do..
			return;
		}
		const htmlContent = props.htmlRef.current!.innerHTML;
		req.bodyHtml = htmlContent;
		req.bodyText = props.textRef.current!.innerText;
		req.force = force;
		req.changeSummary = JSON.stringify(wochenmailtUiStore.changesThisWeekSummary)
		console.log("change summary " + JSON.stringify(wochenmailtUiStore.changesThisWeekSummary));

		const res = await userSettingsStore.sendWochenMail(req);
		console.log('Wochenmail Send result ' + JSON.stringify(res.data));
		notify('WochenMail', res.data.message);
		setSent(true);
	};

	return (
		<>
			{wochenmailtUiStore.loaded && (
				<>
					{!wochenmailtUiStore.hasChangesThisWeek && <strong>No Changes: Nothing will be sent</strong>}
					<Button className="button crawlSendMail" onClick={onButtonClick}>
						Senden
					</Button>
					<CheckboxControl alignment="left" name="isJpg" value={force} onChange={(name, val) => setforce(val)} label={'Erneuten versand erzwingen'} />
					{sent && <div className={'crawlMailSent'}>Versichtickt</div>}
				</>
			)}

			{/* <div className={'crawlMailSent'}>Versichtickt</div> */}
		</>
	);
});
