import { TableModel, ITableColumn, TableRow } from 'app/components/table/table.model';
import { formatNum, formatPercentTwoDig } from 'app/utils';
import { Table } from 'app/components/table/table';
import { useStore } from 'app/context';
import * as Yup from 'yup';
import { observer } from 'mobx-react';
import { useMemo, useEffect, useState } from 'react';

import { AgenturNameCell } from 'app/components/shared/AgenturName.cell';
import { AgtSegmentVM } from 'app/stores/ui/agt.segment.ui.store';
import { Icon } from 'app/components/common/Icon';
import { SegmentChart } from './SegmentChart';
import { SegmentTag } from './SegmentTag';

export const segmentSchema = Yup.object().shape({
	eigenerSegement: Yup.string(),
});

export const formatBool = (val: any) => {
	return <input type="checkbox" checked={val} disabled={true} />;
};

export const SegmentList = observer(() => {
	const { agtSegmentUiStore, anwendungenUiStore, session, agenturListUiStore } = useStore();
	const user = session.currentUser!;
	const [loaded, setLoadad] = useState<boolean>(false);

	const year = anwendungenUiStore.currentSchwerpunktYear;

	const tm = useMemo(() => {
		const tm = new TableModel<AgtSegmentVM, number>();

		const cols: ITableColumn<AgtSegmentVM, number>[] = [
			{
				label: 'Agentur',
				path: 'agt.name',
				sortBy: 'agt.name',
				render: AgenturNameCell,
			},
			{
				label: 'BAQ *',
				path: 'baq',
				sortBy: 'baq',
				format: formatPercentTwoDig,
			},
			{
				label: 'Bestand',
				path: 'bestand',
				sortBy: 'bestand',
				format: formatNum,
			},
			{
				label: 'Einstufung DVS',
				path: 'calcSegement',
				sortBy: 'calcSegement',
				render: (row: TableRow<AgtSegmentVM>) => {
					return <SegmentTag agt={row.data.agt} showCalculated={true} />;
				},
			},
			{
				label: 'Eigene Einstufung',
				path: 'eigenerSegement',
				sortBy: 'eigenerSegement',
				editable: user.isVbl,
				editType: 'segment',
				render: (row: TableRow<AgtSegmentVM>) => {
					return <SegmentTag agt={row.data.agt} showCalculated={false} />;
				},
			},
		];

		tm.sortBy = 'agt.name';
		tm.setCols(cols);
		tm.idProperty = 'agtId';
		tm.idType = 'number';
		return tm;
	}, [user.isVbl]);

	useEffect(() => {
		agtSegmentUiStore.findByYear(year).then((res) => {
			res = agenturListUiStore.applyFilterOnAgt(res) as AgtSegmentVM[];

			if (anwendungenUiStore.selectedAgt) {
				const agtId = anwendungenUiStore.selectedAgt.agtId;
				res = res.filter((d) => d.agtId === agtId);
			}
			tm.setRowData(res);
			setLoadad(true);
		});
	}, [tm, year, setLoadad, agtSegmentUiStore, anwendungenUiStore.selectedAgt, agenturListUiStore.filterHasChanged, agenturListUiStore]);

	const onSave = async () => {
		await agtSegmentUiStore.saveMany(tm.data);
	};

	return (
		<>
			{loaded && (
				<>
					<div className="table-container">
						<SegmentChart tableModel={tm} />
						<SegementDisclaimer />
						<Table stickyFooter={true} tm={tm} schema={segmentSchema} onSubmit={onSave} />
					</div>
				</>
			)}
		</>
	);
});

export const SegementDisclaimer = () => {
	return (
		<div className="pad-1rem color-grey">
			<Icon iconClass="info-circle" faModifier="fal" /> BAQ wird als Durschnitt der letzten drei Jahre dargestellt.
		</div>
	);
};
