import { Einheit } from 'app/models/core/einheit.model';
import { BnrGpPosModel } from '../bnr.gp.pos.model';

const vblPosDef: BnrGpPosModel[] = [];

vblPosDef.push(new BnrGpPosModel({ year: 2024, posId: 2255001, group: 'vss', posText: 'Weiterbildungspfl. BNR', planKey: 'idd', einheit: Einheit.bew }));
vblPosDef.push(new BnrGpPosModel({ year: 2024, posId: 2255023, group: 'vss', posText: 'Leben EB Digi ', planKey: 'lebenebdigi', einheit: Einheit.bew }));
vblPosDef.push(new BnrGpPosModel({ year: 2024, posId: 2255145, group: 'vss', posText: 'Unternehmensschutz ', planKey: 'unternehmensschutz', einheit: Einheit.bew }));

vblPosDef.push(new BnrGpPosModel({ year: 2024, posId: 2255121, group: 'neh', posText: 'P-Sach o.Kr_U_ Bew Digi', planKey: 'psachokrunfbew', einheit: Einheit.bew }));
vblPosDef.push(new BnrGpPosModel({ year: 2024, posId: 2255131, group: 'neh', posText: 'Unfall Bew o. Anp. Digi', planKey: 'unfallbew', einheit: Einheit.bew }));
vblPosDef.push(new BnrGpPosModel({ year: 2024, posId: 2255135, group: 'neh', posText: 'KH-PkW LfNST Digi', planKey: 'khpkwlfnst', einheit: Einheit.lfNST }));
vblPosDef.push(new BnrGpPosModel({ year: 2024, posId: 2255141, group: 'neh', posText: 'F-Sach Bewertung', planKey: 'fsachokraftbew', einheit: Einheit.bew }));
vblPosDef.push(new BnrGpPosModel({ year: 2024, posId: 2255151, group: 'neh', posText: 'Rente/Leben Bew Digi', planKey: 'rentelebenbewdigi', einheit: Einheit.bew }));
vblPosDef.push(new BnrGpPosModel({ year: 2024, posId: 2255161, group: 'neh', posText: 'Fonds Bewertung', planKey: 'fondsbew', einheit: Einheit.bew }));
vblPosDef.push(new BnrGpPosModel({ year: 2024, posId: 2255171, group: 'neh', posText: 'Kranken Bew o.Dyn Digi', planKey: 'krankenzusatzbew', einheit: Einheit.bew }));

vblPosDef.push(new BnrGpPosModel({ year: 2024, posId: 3855011, group: 'gpplus', posText: 'Kranken Pflege Bew Digi', planKey: 'krankenpflegebewdigi', einheit: Einheit.bew }));
vblPosDef.push(new BnrGpPosModel({ year: 2024, posId: 3855021, group: 'gpplus', posText: 'Kranken HKV Pers. Digi', planKey: 'krankenhkvpersdigi', einheit: Einheit.bew }));
vblPosDef.push(new BnrGpPosModel({ year: 2024, posId: 3855031, group: 'gpplus', posText: 'Leben Ris. Bew Digi', planKey: 'lebenrisbewdigi', einheit: Einheit.bew }));
vblPosDef.push(new BnrGpPosModel({ year: 2024, posId: 3855041, group: 'gpplus', posText: 'PS+Unfall Digi', planKey: 'lebenrisbewdigi', einheit: Einheit.bew }));

export const VblPositionen = vblPosDef;
