import { computed } from 'mobx';
import { FindSteart, Steart, SteartModel } from '../core/steart.model';
import { UserModel } from '../core/user.model';

export interface IUserModel {
	userId: string;
	bensl: string;
	firstName: string;
	lastName: string;
	email: string;
	userCategory: string;
	tel: string;
	mobil: string;
	bnrId: number;
	status: number;
	bnrSteart: number;
	name: string;
	comment: string;
	registeredInDvs: boolean;
	emailNotifications: boolean;
	hasAupData: boolean;
	vdList: string[];
	gsList: string[];
	authorities: IAuthoriryModel[];
	lastCrawlDate?: Date;
}

export interface IAuthoriryModel {
	name: string;
}

export class CurrentUserModel {
	constructor(opts: IUserModel) {
		this.userId = opts.userId;
		this.bensl = opts.bensl;
		this.firstName = opts.firstName;
		this.lastName = opts.lastName;
		this.email = opts.email;
		this.authorities = opts.authorities;
		this.isAdmin = !!this.authorities.find((a) => a.name === 'ROLE_ADMIN');
		this.userCategory = opts.userCategory;
		this.tel = opts.tel;
		this.mobil = opts.mobil;
		this.bnrId = opts.bnrId;
		this.status = opts.status;
		this.steart = FindSteart(opts.bnrSteart);
		if (opts.bnrId === 123) {
			this.steart = FindSteart(123);
		}
		this.name = opts.name;
		this.comment = opts.comment;
		this.registeredInDvs = opts.registeredInDvs;
		this.emailNotifications = opts.emailNotifications;
		this.hasAupData = opts.hasAupData;
		this.gsList = opts.gsList;
		this.vdList = opts.vdList;
		if (opts.lastCrawlDate) {
			this.lastCrawlDate = new Date(opts.lastCrawlDate)
		}
	}

	authorities: IAuthoriryModel[] = [];
	isAdmin: boolean;
	userId: string;
	bensl: string;
	firstName: string;
	lastName: string;
	email: string;
	userCategory: string;
	tel: string;
	mobil: string;
	bnrId: number;
	status: number;
	// bnrSteart: number;
	steart: SteartModel;
	name: string;
	comment: string;
	registeredInDvs: boolean;
	hasAupData: boolean;
	emailNotifications: boolean;
	lastCrawlDate?: Date;

	@computed
	get isVbl() {
		return this.steart.steart === Steart.VBL;
	}

	@computed
	get isGsl() {
		return this.steart.steart === Steart.GSL;
	}

	@computed
	get isSpezi() {
		return this.steart.steart !== Steart.VBL;
	}

	@computed
	get isLeiter() {
		return this.steart.steart === Steart.LPV || this.steart.steart === Steart.LSV;
	}

	@computed
	get isMulti() {
		return this.steart.steart === Steart.EDP || this.steart.steart === Steart.EDT || this.steart.steart === Steart.LDP;
	}

	@computed
	get canSeeProdZwischenstand() {
		return this.steart.steart === Steart.VBL || this.steart.steart === Steart.GSL;
	}

	/*
	 [
	  "F",
	  null
	],*/
	vdList: string[];
	/*
	"gsList": [
	  "KO",
	  null
	]

	*/
	gsList: string[];

	@computed
	get asUserModel() {
		return new UserModel({
			userId: this.userId,
			bensl: this.bensl,
			firstName: this.firstName,
			lastName: this.lastName,
			email: this.email,
			userCategory: this.userCategory,
			tel: this.tel,
			mobil: this.mobil,
			bnrId: this.bnrId,
			status: this.status,
			bnrSteart: this.steart.id,
			name: this.name,
			comment: this.comment,
			registeredInDvs: this.registeredInDvs,
			hasAupData: this.hasAupData,
		});
	}
}
