import { ApModel, ApVermittler } from 'app/models/documents/ap.model';
import { DocumentVM } from 'app/stores/ui/document.ui.store';
import { observer } from 'mobx-react';
import { Button } from '../common/Button';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import FormField from '../form/FormField';
import { useStore } from 'app/context';
import { useEffect, useState } from 'react';
import { ApGoal } from 'app/stores/bnr.planungstool.store';
import { ApGoalsTable } from './ApGoalsTable';
import { formatCurrency } from 'app/utils';

interface IApVermittlerZieleForm {
	doc: DocumentVM;
	vermittler: ApVermittler;
}

const yupNum = Yup.number().typeError('Nur Zahleneingaben sind erlaubt').required('Eingabe erforderlich').default(0);

const apVermittlerZieleSchema = Yup.object({
	vorPlan: yupNum,
	plan: yupNum,
	absence: yupNum,
	quoteDurchschnittBewertungNeutsueck: yupNum,
	quoteAnzahlNeustueckJeTermin: yupNum,
	quoteDurchgefuehrteTermineMitAbschluss: yupNum,
	quoteDurchgefuehrteTermine: yupNum,
	quoteFolgetermine: yupNum,
}).required();

type VermittlerZiele = Yup.InferType<typeof apVermittlerZieleSchema>;

interface IApVermittlerCalc {
	doc: DocumentVM;
	vermittler: ApVermittler;
	plan: number;
	vorPlan: number;
}
export const ApVermittlerCalc = observer((props: IApVermittlerCalc) => {
	const vermittler = props.vermittler;
	const ap = props.doc.doc.content as ApModel;
	const [deltaVor, setDeltaVor] = useState(0);
	const [deltaFuer, setDeltaFuer] = useState(0);

	useEffect(() => {
		let bewertungVorPlanungszeitraumIst = 0;
		let bewertungFuePlanungszeitraumIst = 0;
		ap.vermittler.forEach((v) => {
			if (v.id === vermittler.id) {
				bewertungFuePlanungszeitraumIst += parseFloat(props.plan.toString());
				bewertungVorPlanungszeitraumIst += parseFloat(props.vorPlan.toString());
			} else {
				bewertungFuePlanungszeitraumIst += parseFloat(v.plan.toString());
				bewertungVorPlanungszeitraumIst += parseFloat(v.vorPlan.toString());
			}
		});
		setDeltaFuer(ap.bewertungFuerPlanungszeitraum - bewertungFuePlanungszeitraumIst);
		setDeltaVor(ap.bewertungVorPlanungszeitraum - bewertungVorPlanungszeitraumIst);
	}, [props.plan, props.vorPlan, ap.bewertungFuerPlanungszeitraum, ap.bewertungVorPlanungszeitraum, ap.vermittler, vermittler.id]);

	return (
		<>
			<div>
				<FormField className="is-inline" label="Noch zu verteilen vor Planungszeitraum in €">
					<span>{formatCurrency(deltaVor)} €</span>
				</FormField>
				<FormField className="is-inline" label="Noch zu verteilen für Planungszeitraum in €">
					<span>{formatCurrency(deltaFuer)} €</span>
				</FormField>
			</div>
		</>
	);
});

interface IApVermiitlerGoalsTable {
	doc: DocumentVM;
	values: VermittlerZiele;
	vermittler: ApVermittler;
}
export const ApVermitlerGoalsTable = observer((props: IApVermiitlerGoalsTable) => {
	const { documentUiStore } = useStore();
	const ap = props.doc.doc.content as ApModel;
	const [goals, setGoals] = useState<ApGoal>();

	useEffect(() => {
		let isActive = true;
		const vermittler = new ApVermittler(props.values);
		vermittler.id = props.vermittler.id;

		documentUiStore.apCalcGoalsForVermittler(ap, vermittler).then((res) => {
			if (!isActive) {
				return;
			}
			setGoals(res!);
		});
		return () => {
			isActive = false;
		};
	}, [props.values, ap, documentUiStore, props.vermittler.id]);

	return (
		<>
			<div>{goals && <ApGoalsTable goals={goals} />}</div>
		</>
	);
});

export const ApVermittlerZieleForm = observer((props: IApVermittlerZieleForm) => {
	const { uiStore, documentUiStore } = useStore();
	const ap = props.doc.doc.content as ApModel;
	const vermittler = props.vermittler;

	const onCancel = () => {
		uiStore.hideModal(uiStore.modalIds.apEditVermittler);
	};

	const handleSubmit = async (values: VermittlerZiele) => {
		vermittler.vorPlan = values.vorPlan;
		vermittler.plan = values.plan;
		vermittler.absence = values.absence;
		vermittler.quoteDurchschnittBewertungNeutsueck = values.quoteDurchschnittBewertungNeutsueck;
		vermittler.quoteAnzahlNeustueckJeTermin = values.quoteAnzahlNeustueckJeTermin;
		vermittler.quoteDurchgefuehrteTermineMitAbschluss = values.quoteDurchgefuehrteTermineMitAbschluss;
		vermittler.quoteDurchgefuehrteTermine = values.quoteDurchgefuehrteTermine;
		vermittler.quoteFolgetermine = values.quoteFolgetermine;

		await documentUiStore.save(props.doc);
		uiStore.hideModal(uiStore.modalIds.apEditVermittler);
	};

	return (
		<>
			<Formik
				validationSchema={apVermittlerZieleSchema}
				initialValues={{
					vorPlan: vermittler.vorPlan,
					plan: vermittler.plan,
					absence: vermittler.absence,
					quoteDurchschnittBewertungNeutsueck: vermittler.quoteDurchschnittBewertungNeutsueck,
					quoteAnzahlNeustueckJeTermin: vermittler.quoteAnzahlNeustueckJeTermin,
					quoteDurchgefuehrteTermineMitAbschluss: vermittler.quoteDurchgefuehrteTermineMitAbschluss,
					quoteDurchgefuehrteTermine: vermittler.quoteDurchgefuehrteTermine,
					quoteFolgetermine: vermittler.quoteFolgetermine,
				}}
				onSubmit={handleSubmit}
			>
				{({ errors, touched, values, isValid, handleChange }) => {
					return (
						<Form className="ap-form grid">
							<ApVermittlerCalc {...props} plan={values.plan} vorPlan={values.vorPlan} />

							<div className="ap-section">
								{ap.startMonth > 1 && (
									<FormField className="is-inline" error={errors.vorPlan} touched={touched.vorPlan} label="Bewertung vor Planung in €">
										<Field type="text" name="vorPlan" placeholder="Bewertung VOR Planung in €" />
									</FormField>
								)}
								<FormField className="is-inline" error={errors.plan} touched={touched.plan} label="Bewertung für Planung in €">
									<Field type="text" name="plan" placeholder="Bewertung FÜR Planung in €" />
								</FormField>
								<FormField className="is-inline" error={errors.absence} touched={touched.absence} label="Fehlwochen">
									<Field type="text" name="absence" placeholder="Fehlwochen" />
								</FormField>
							</div>
							<div className="ap-section">
								<strong className="ap-section-title">Quoten</strong>
								<FormField className="is-inline" error={errors.quoteDurchschnittBewertungNeutsueck} touched={touched.quoteDurchschnittBewertungNeutsueck} label="Durchschnittliche Bewertung je Neustück in €">
									<Field type="text" name="quoteDurchschnittBewertungNeutsueck" placeholder="Durchschnittliche Bewertung je Neustück in €" />
								</FormField>
								<FormField className="is-inline" error={errors.quoteAnzahlNeustueckJeTermin} touched={touched.quoteAnzahlNeustueckJeTermin} label="Anzahl der Neustücke je Termin mit Abschluss">
									<Field type="text" name="quoteAnzahlNeustueckJeTermin" placeholder="Anzahl der Neustücke je Termin mit Abschluss" />
								</FormField>
								<FormField className="is-inline" error={errors.quoteDurchgefuehrteTermineMitAbschluss} touched={touched.quoteDurchgefuehrteTermineMitAbschluss} label="Quote der durchgeführten Termine mit Abschluss">
									<Field type="text" name="quoteDurchgefuehrteTermineMitAbschluss" placeholder="Quote der durchgeführten Termine mit Abschluss" />
								</FormField>
								<FormField className="is-inline" error={errors.quoteDurchgefuehrteTermine} touched={touched.quoteDurchgefuehrteTermine} label="Quote der duchgeführten Termine">
									<Field type="text" name="quoteDurchgefuehrteTermine" placeholder="Quote der duchgeführten Termine" />
								</FormField>
								<FormField className="is-inline" error={errors.quoteFolgetermine} touched={touched.quoteFolgetermine} label="Quote der Folgetermine">
									<Field type="text" name="quoteFolgetermine" placeholder="Quote der Folgetermine" />
								</FormField>
							</div>
							<div className="ap-section">
								<strong className="ap-section-title">Termindurchführung</strong>
								<ApVermitlerGoalsTable {...props} values={values} />
							</div>
							<div className="modal-footer">
								<Button type="button" className="button is-secondary" onClick={onCancel}>
									Abbrechen
								</Button>
								<Button type="submit" className="button is-primary ">
									Speichern
								</Button>
							</div>
						</Form>
					);
				}}
			</Formik>
		</>
	);
});
