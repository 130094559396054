import { Icon } from 'app/components/common/Icon';
import { observer } from 'mobx-react';
import { Button } from './Button';

export interface IRadioButtonListItem {
	label?: string;
	icon?: string;
	classModifier?: string;
	disabled?: boolean;
	value: string | number;
}

interface IRadioButtonListProps {
	value: string | number;
	buttons: IRadioButtonListItem[];
	onSelect: (value: string | number) => void;
	disabled?: boolean;
	classModifier?: string;
	includeDefaultClasses?: boolean;
}

interface IRadioButtonListItemProps {
	label?: string;
	icon?: string;
	classModifier?: string;
	value: string | number;
	selected: boolean;
	onSelect: (value: string | number) => void;
	disabled?: boolean;
}

const RadioButtonItem = observer((props: IRadioButtonListItemProps) => {
	return (
		<Button className={`button ${props.selected ? 'is-active' : ''} ${props.classModifier || ''}`} onClick={() => props.onSelect(props.value)} disabled={props.disabled}>
			{props.icon && <Icon iconClass={props.icon} />}
			{props.label && <span>{props.label}</span>}
		</Button>
	);
});

export const RadioButtonList = observer((props: IRadioButtonListProps) => {
	const classes = props.includeDefaultClasses === false ? '' : 'button-group is-radio-button-group has-text-centered';
	return (
		<div className={`${classes} ${props.classModifier || ''}`}>
			{props.buttons.map((b, i) => {
				const selected = b.value === props.value;
				return <RadioButtonItem key={i} {...b} selected={selected} onSelect={props.onSelect} />;
			})}
		</div>
	);
});
