import { TreeNode } from 'app/components/common/TreeNode';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';

export const AdminUsageTreeMenu = observer(() => {
	const { adminUsageUiStore } = useStore();
	const tree = adminUsageUiStore.menu;

	return (
		<div className="tree-nodes-container">
			<TreeNode nodes={tree.nodes} treeStore={adminUsageUiStore} />
		</div>
	);
});
