import React from 'react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import FormField from '../form/FormField';
import { Button } from '../common/Button';
import routes from './../../../routes';
import { Link } from 'react-router-dom';
import { useStore } from 'app/context';
import { notify } from '../common/notify';

const forgotPasswordFormSchema = Yup.object({
	bensl: Yup.string().required('Bensl ist erforderlich').default(''),
}).required();

type ForgotPassword = Yup.InferType<typeof forgotPasswordFormSchema>;

export const ForgotPasswordForm: React.FC = () => {
	const { authStore } = useStore();

	async function handleSubmit(values: ForgotPassword) {
		const username = values.bensl;
		const title = 'Passwort zurücksetzen:';
		const msg = 'E-Mail zum zurücksetzen des Passworts wurde versendet an Benutzer ' + username + ' (Sofern das Konto existiert und aktiv ist)';
		await authStore.forgotPassword(values.bensl);
		notify(title, msg, 'info');
	}

	return (
		<Formik
			validationSchema={forgotPasswordFormSchema}
			initialValues={{
				bensl: '',
			}}
			onSubmit={handleSubmit}
		>
			{({ errors, touched, isValid }) => {
				return (
					<Form className="auth-form">
						<div className="auth-form-header">
							<div className="title">DVS</div>
						</div>

						<div>
							<FormField className="title" error={errors.bensl} touched={touched.bensl} label="BENSL">
								<Field type="text" name="bensl" autoComplete="username" placeholder="Benutzername (BENSL)" autoFocus={true} />
							</FormField>
						</div>

						<div>
							<Button type="submit" className="button is-primary is-block" isFormInvalid={!isValid}>
								Passwort zurücksetzen
							</Button>
							<Link className="button is-block" to={routes.LOGIN.getPath()}>
								Zurück
							</Link>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
};
