import Widget, { IWidgetConsumer } from 'app/components/common/Widget';
import { observer } from 'mobx-react';
import { useStore } from 'app/context';
import React, { useEffect } from 'react';
import { Button } from 'app/components/common/Button';
import { useHistory } from 'react-router';
import routes from 'routes';
import { AgtPlanTasks } from 'app/components/agt/planung/AgtPlanungsTasks';

const AllAgtTasks = () => {
	const history = useHistory();
	const { agenturUiStore } = useStore();
	const onClick = () => {
		if (agenturUiStore.currentAgtId) {
			history.push(routes.AGENTUR.getPath(agenturUiStore.currentAgtId?.toString(), 'massnahmen'));
		}
	};

	return (
		<Button className="button is-widget-header" onClick={onClick}>
			Alle
		</Button>
	);
};

export const AgenturCurrentTasksWidget = observer((props: IWidgetConsumer) => {
	const { taskUiStore } = useStore();
	useEffect(() => {
		taskUiStore.load();
	}, [taskUiStore]);

	const tasks = taskUiStore.currentOpenAgtTasks;

	return (
		<Widget title={'Offene Maßnahmen'} action={<AllAgtTasks />} widgetId={props.widgetKey}>
			{tasks.length > 0 ? <AgtPlanTasks tasks={tasks} /> : <div>Keine offene Maßnahmen</div>}
		</Widget>
	);
});
