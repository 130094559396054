import { BESTANDPOS_ID } from 'app/stores/ui/agt.bestand.ui.store';
import { computed } from 'mobx';
import { AgenturModel } from '../agentur.model';
import { AgtBestandPosModel } from './agt.bestand.pos.model';

export class AgtBestandUndBewertungVM {
	constructor(agt: AgenturModel) {
		this.agtId = agt.agtId;
		this.agt = agt;

		this.bestand = new AgtBestandPosModel({
			agt: this.agt,
			title: 'Sach gesamt (ohne AGCS/mit ESA)',
			posId: 34,
			zuwachsPosId: 33,
		});

		this.bestandVJ = new AgtBestandPosModel({
			agt: this.agt,
			title: 'Sach gesamt (ohne AGCS/mit ESA)',
			posId: BESTANDPOS_ID,
			zuwachsPosId: 33,
		});

		this.bewertung = new AgtBestandPosModel({
			agt: this.agt,
			title: 'Bewertung',
			posId: 32,
		});

		this.bewertungVJ = new AgtBestandPosModel({
			agt: this.agt,
			title: 'Bewertung',
			posId: 32,
		});
	}

	agtId: number;
	agt: AgenturModel;
	bestand: AgtBestandPosModel;
	bestandVJ: AgtBestandPosModel;
	bewertung: AgtBestandPosModel;
	bewertungVJ: AgtBestandPosModel;

	@computed
	get bewertungsveraenderung() {
		let bt = this.bewertung.val.bt;
		const btVJ = this.bewertungVJ.val.bt;
		const x = this.calcDelta(bt, btVJ);
		if (x === undefined) {
			return x;
		}
		return x - 100;
	}
	@computed
	get bewertungsveraenderungLimited() {
		if (this.bewertungsveraenderung) {
			if (this.bewertungsveraenderung > 100) return 100;
			if (this.bewertungsveraenderung < -100) return -100;
		}
		return this.bewertungsveraenderung;
	}

	@computed
	get bewertungDeltaVJ() {
		if (this.bewertung && this.bewertungVJ) {
			return this.bewertung.val.bt - this.bewertungVJ.val.bt;
		}
		return undefined;
	}

	calcDelta(bt: number, btVJ: number) {
		if (!bt || !btVJ || btVJ === 0) {
			return undefined;
		}
		bt = btVJ + bt;
		let w = (bt / btVJ - 1) * 100;
		w = Math.round(w * 100) / 100;
		return w;
	}
}
