import BaseContainer from 'app/containers/BaseContainer';
import { MainMenu } from './MainMenu';
import { RouteComponentProps } from 'react-router-dom';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { AgtQueryStringSetter, BaseTreePathLoader } from './BerichteContainer';
import { AdminUsageTreeMenu } from 'app/components/admin/usage/AdminUsageTreeMenu';
import { AdminUsagePanel } from 'app/components/admin/usage/AdminUsagePanel';

const AnwendungeNavBar = observer(() => {
	return <div>Admin DVS Nutzung</div>;
});
const AdminUsageContainer = (props: RouteComponentProps) => {
	const { adminUsageUiStore } = useStore();

	return (
		<BaseContainer sideMenu={<MainMenu {...props} />} navbar={<AnwendungeNavBar />}>
			<BaseTreePathLoader store={adminUsageUiStore} />
			<AgtQueryStringSetter />
			<AdminUsageTreeMenu />
			<AdminUsagePanel />
		</BaseContainer>
	);
};
export default AdminUsageContainer;
