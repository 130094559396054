import { TaskNewButton } from 'app/components/tasks/TaskNewButton';
import { useStore } from 'app/context';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { TasksFilter } from '../tasks/TasksFilter';
import { TasksList } from '../tasks/TasksList';
import { TaskViewerModal } from '../tasks/TaskViewModal';

export const AgenturTasks = observer(() => {
	const { agenturUiStore, taskUiStore } = useStore();
	const agt = agenturUiStore.current;

	useEffect(() => {
		runInAction(() => {
			taskUiStore.currentFilter.reset();
			taskUiStore.currentFilter.agtId = agenturUiStore.currentAgtId;
		});
	}, [agenturUiStore.currentAgtId, taskUiStore.currentFilter]);

	return (
		<div className="is-agentur">
			{agt && (
				<>
					<div className="pad-1rem grid is-col-half">
						<div className="is-left">
							<TasksFilter />
						</div>
						<div className="is-right">
							<TaskNewButton />
						</div>
					</div>
					<TasksList agtId={agt.agtId} />
					<TaskViewerModal showAgt={false} showPlan={true} />

					{/* <DocumentFilter />
					<br />
					<DocumentList agtId={agt.agtId} />
					<DocumentShareModal />
					<DocumentSendModal />

					<DocumentViewerModal showPlan={true} showAgt={false} /> */}
				</>
			)}
		</div>
	);
});
