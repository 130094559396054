const values = [
	{
		key: 'idd',
		title: 'IDD',
		children: [
			{
				key: 'lernkartenidd',
				title: 'Lernkarten IDD',
			},
			{
				key: 'lernkartenappnowprivate',
				title: 'Lernkarten ABP.NOW Privat',
			},
			{
				key: 'lernkartenfirmen',
				title: 'Lernkarten ABP.NOW Firmen',
			},
			{
				key: 'weiterbildungnachhalten',
				title: 'Weiterbildung nachhalten',
			},
		],
	},
	{
		key: 'abpprivat',
		title: 'ABP Privat',
		children: [
			{
				key: 'selbstverpflichtungeinsatz',
				title: 'Selbstverpflichtung zum Einsatz',
			},
			{
				key: 'verkaufsbegleitung',
				title: 'Verkaufsbegleitung',
			},
			{
				key: 'uebungszirkel',
				title: 'Übungszirkel',
			},
			{
				key: 'vertreterschulen',
				title: 'Vertreter schulen Vertreter (Modellagentur)',
			},
			{
				key: 'schwerpunktagentur',
				title: 'Schwerpunktagentur',
			},
			{
				key: 'interaktionzweitverkaeufer',
				title: 'Interaktion mit Zweitverkäufer',
			},
			{
				key: 'kundenstatus',
				title: 'Kundenstatus für jeden Kunden',
			},
		],
	},
	{
		key: 'abpfirmen',
		title: 'ABP Firmen',
		children: [
			{
				key: 'selbstverpflichtungeinsatz',
				title: 'Selbstverpflichtung zum Einsatz',
			},
			{
				key: 'verkaufsbegleitung',
				title: 'Verkaufsbegleitung',
			},
			{
				key: 'uebungszirkel',
				title: 'Übungszirkel',
			},
			{
				key: 'vertreterschulen',
				title: 'Vertreter schulen Vertreter (Modellagentur)',
			},
			{
				key: 'schwerpunktagentur',
				title: 'Schwerpunktagentur',
			},
			{
				key: 'interaktionzweitverkaeufer',
				title: 'Interaktion mit Zweitverkäufer',
			},
			{
				key: 'firmenstatus',
				title: 'Firmenstatus für jeden Kunden',
			},
			{
				key: 'durchfuehrungfirmenjahresgespraech',
				title: 'Durchführung Firmenjahresgespräch',
			},
		],
	},
	{
		key: 'onlineberatung',
		title: 'Online Beratung',
		children: [
			{
				key: 'gtmlizenz',
				title: 'GTM-Lizenz',
			},
			{
				key: 'startekursgtm',
				title: 'Starterkurs GTM',
			},
			{
				key: 'da20',
				title: 'Digitale Agentur 2.0',
			},
			{
				key: 'uebungszirkel',
				title: 'Übungszirkel',
			},
			{
				key: 'verterterschulungverterter',
				title: 'Vertreter schulen Vertreter',
			},
			{
				key: 'aktivesangebtoamkunden',
				title: 'Aktives Angebot an Kunden',
			},
		],
	},
	{
		key: 'beratungstechnik',
		title: 'Beratungstechnik',
		children: [
			{
				key: 'agenturausstattung',
				title: 'Agenturausstattung',
			},
			{
				key: 'erlernungknowhow',
				title: 'Aufbau von Know-How',
			},
			{
				key: 'aaaseminare',
				title: 'AAA-Seminare/Webinare',
			},
			{
				key: 'amisboxenstop',
				title: 'AMIS Boxenstopp',
			},
		],
	},
	{
		key: 'aktionenonline',
		title: 'Aktionen Online',
		children: [
			{
				key: 'downloadnachamis',
				title: 'Download in AMIS',
			},
			{
				key: 'festlegungderzustaendigkeiten',
				title: 'Festlegung der Zuständigkeiten',
			},
			{
				key: 'einstellungvonaufgaben',
				title: 'Einstellen von Aufgaben',
			},
			{
				key: 'nachhaltenderbearbeitung',
				title: 'Nachhalten der Bearbeitung',
			},
		],
	},
	{
		key: 'spezialisteneinsatz',
		title: 'Spezialisteneinsatz',
		children: [
			{
				key: 'tarifschulungen',
				title: 'Tarifschulungen',
			},
			{
				key: 'themenschulungen',
				title: 'Themenschulungen',
			},
			{
				key: 'verstarktenutzungdse',
				title: 'Verstärkte Nutzung DSE',
			},
			{
				key: 'interktionmitdemzweitverkauefer',
				title: 'Interaktion mit Zweitverkäufer',
			},
		],
	},
	{
		key: 'beratungsstandards',
		title: 'Beratungsstandards',
		children: [
			{
				key: 'kundenversprechen',
				title: 'Kundenversprechen',
			},
			{
				key: 'ganzheitlicheberatung',
				title: 'Ganzheitliche Beratung',
			},
			{
				key: 'definierenundleben',
				title: 'Definieren und Leben',
			},
			{
				key: 'verlaesslichkeit',
				title: 'Verlässlichkeit',
			},
			{
				key: 'engagement',
				title: 'Engagement',
			},
			{
				key: 'adressatengrechtesprache',
				title: 'Adressatengrechte Sprache',
			},
		],
	},
	{
		key: 'vornachbereitung',
		title: 'Vor-/Nachbereitung',
		children: [
			{
				key: 'kdvpflege',
				title: 'KDV-Pflege',
			},
			{
				key: 'kontaktnachabschluss',
				title: 'Kontakt nach Abschluss',
			},
			{
				key: 'reaktionszeiten',
				title: 'Reaktionszeiten',
			},
			{
				key: 'zeitmanagement',
				title: 'Zeitmanagement',
			},
			{
				key: 'routenplanung',
				title: 'Routenplanung',
			},
			{
				key: 'unterstuetzungimschadensundleistungsfall',
				title: 'Unterstützung im Schadens- und Leistungsfall',
			},
		],
	},
];
export default values;
