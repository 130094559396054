import { ErrungenschaftState } from 'app/models/errungenshaften/errungenschaften.model';
import { KvBnrMsModel } from 'app/models/kv.bnr.ms.model';
import { action, computed, observable } from 'mobx';
import { BaseCachedEntityStore } from './core/base.cached.entity.store';
import { KvHistoryEntry } from './kv.agentur.year.errungenschaften.store';

export class KvBnrYearErrungenschaftenModel extends KvBnrMsModel {
	constructor(opts: any) {
		super(opts);
		if (this.valueJson) {
			try {
				this.history = JSON.parse(this.valueJson);
			} catch (ex) {
				console.error('Invalid JSON for KvBnrYearErrungenschaftenModel', this.valueJson);
			}
		}
	}
	@computed
	get state() {
		return this.valueString as ErrungenschaftState;
	}

	@computed
	get year() {
		return this.bm;
	}

	@observable
	history?: KvHistoryEntry[];

	@action
	addHistoryEntry() {
		if (!this.history) {
			this.history = [];
		}
		this.history.push(KvHistoryEntry.fromErrungenschaftModel(this));
		this.valueJson = JSON.stringify(this.history);
	}
}

export class KvBnrYearErrungenschaftenStore extends BaseCachedEntityStore<KvBnrYearErrungenschaftenModel> {
	idProp: string = 'id';
	apiPath = 'api/kv_bnr_ms/errungenschaften';
	disableFindForStearts = [123];

	createInstances(opts: any): KvBnrYearErrungenschaftenModel {
		return new KvBnrYearErrungenschaftenModel(opts);
	}
}
