import BaseContainer from 'app/containers/BaseContainer';
import { observer } from 'mobx-react';
import { MainMenu } from './MainMenu';
import { RouteComponentProps } from 'react-router-dom';
import { AdminCrawlerTest } from 'app/components/admin/crawl/AdminCrawlerTest';

const AdminCrawlContainer = observer((props: RouteComponentProps) => {
    return (
        <BaseContainer classModifier="admin-container" sideMenu={<MainMenu {...props} />} navbar={<div>GS / VB</div>} actionControls={<></>}>
            <AdminCrawlerTest />
        </BaseContainer>
    );
});

export default AdminCrawlContainer;
