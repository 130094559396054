import { useEffect } from 'react';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { Table } from './../../table/table';
import { ITableColumn, TableModel, TableRow } from './../../table/table.model';
import { BnrDiffVM } from 'app/stores/ui/admin.bnr.diff.ui.store';
import { runInAction } from 'mobx';
import { useHistory } from 'react-router';
import routes from 'routes';

export const AdminBnrDiffList = observer(() => {
	const { adminBnrDiffUiStore } = useStore();
	const history = useHistory();

	const tm = new TableModel<BnrDiffVM, number>();
	let cols: ITableColumn<BnrDiffVM, number>[] = [
		{
			label: 'BnrId',
			path: 'diff.bnrId',
		},
		{
			label: 'Diff',
			path: 'diff.diff',
		},
		{
			label: 'Steart',
			path: 'diff.fromSteart.name',
			render: (row: TableRow<BnrDiffVM>) => {
				return (
					<>
						{row.data.diff.fromSteart && <s>{row.data.diff.fromSteart.name}</s>}
						{row.data.diff.toSteart && <> {row.data.diff.toSteart.name}</>}
					</>
				);
			},
		},
		{
			label: 'Name',
			path: 'diff.fromName',
			render: (row: TableRow<BnrDiffVM>) => {
				return (
					<>
						{row.data.diff.fromName && <s>{row.data.diff.fromName}</s>}
						{row.data.diff.toName && <> {row.data.diff.toName}</>}
					</>
				);
			},
		},
		{
			label: 'DVS Benutzer',
			path: 'user.user.email',
			render: (row: TableRow<BnrDiffVM>) => {
				if (!row.data.user) {
					return <></>;
				}
				return (
					<>
						{row.data.user.user.email} {row.data.user.user.bensl} [{row.data.user.user.steartText}]
					</>
				);
			},
		},
		{
			label: 'Status',
			path: 'user.user.statusText',
			render: (row: TableRow<BnrDiffVM>) => {
				if (!row.data.user) {
					return <></>;
				}
				return <>{row.data.user.user.statusText}</>;
			},
		},
		{
			label: 'AnzAgt (benutzer)',
			path: 'user.user.agtCount',
		},
		{
			label: 'Comment',
			path: 'user.user.comment',
			render: (row: TableRow<BnrDiffVM>) => {
				if (!row.data.user) {
					return <></>;
				}
				return <pre>{row.data.user.user.comment}</pre>;
			},
		},
	];

	tm.onRowClick = (row: TableRow<BnrDiffVM>) => {
		runInAction(() => {
			history.push(routes.ADMINBNRDIFF.getPath(row.data.diff.bnrId.toString()));
		});
	};

	tm.setCols(cols);
	tm.sortBy = 'user.lastLoginDate';
	tm.sortAsc = false;
	tm.idProperty = 'id';
	tm.idType = 'string';

	useEffect(() => {
		tm.setRowData(adminBnrDiffUiStore.filtered);
	}, [adminBnrDiffUiStore, adminBnrDiffUiStore.filtered, adminBnrDiffUiStore.rerenderTrigger, tm]);

	return (
		<>
			<Table stickyHeader={true} tm={tm}></Table>
		</>
	);
});
