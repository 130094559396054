import { computed } from 'mobx';
import moment from 'moment';
import { AgenturModel } from '../agentur.model';
import { SteartModel } from '../core/steart.model';

export class PersonalVM {
	constructor(opts: any) {
		this._kat = opts.kat;
		this.id = opts.id;
		this.agtId = opts.agtId;
		this.bensl = opts.bensl;
		this.bnrId = opts.bnrId;
		this.bnr = opts.bnr;
		this.vorname = opts.vorname;
		this.nachname = opts.nachname;
		this.gebDat = opts.gebDat;
		this.beitrittDat = opts.beitrittDat;
		this.steart = opts.steart;
		this.jubDat = opts.jubDat;
		this.agt = new AgenturModel({});
	}

	agt: AgenturModel;
	_kat: string;
	id: string;
	bnrId: number;
	bnr: number;
	vorname: string;
	nachname: string;
	agtId: number;
	bensl?: string;
	gebDat: Date;
	beitrittDat: Date;
	steart: SteartModel;
	canShowGeburtstag: boolean = true;

	private jubDat: Date;

	@computed
	get apId() {
		if (this._kat === 'teilhaber' || !this.agtId) {
			return this.vorname + ' ' + this.nachname + '-' + this.gebDat;
		}
		return this.agtId + '-' + this.bnr;
	}

	@computed
	get apBensl() {
		if (this._kat === 'teilhaber') {
			return this.apId;
		}
		return this.bensl;
	}

	@computed
	get apKat() {
		if (this._kat === 'teilhaber') {
			return 'vertreter';
		}
		return this._kat;
	}

	@computed
	get fullName() {
		return this.nachname + ', ' + this.vorname;
	}

	@computed
	get stellenBezeichnung() {
		if (this._kat === 'teilhaber') {
			if (this.agt && this.agt.vertKat) {
				return this.agt.vertKat.name;
			}
			return 'Teilhaber';
		}
		if (this.steart && this.steart.steart > 0) {
			return this.steart.steart.toString();
		}

		return this._kat;
	}

	@computed
	get isEditable() {
		return this._kat === 'custom';
	}

	@computed
	get age() {
		const today = moment();
		const geb = moment(this.gebDat);
		return today.diff(geb, 'years');
	}

	@computed
	get startOfWeek() {
		return moment().startOf('week').format('YYYY-MM-DD');
	}

	@computed
	get nextBirthday() {
		const age = moment(this.startOfWeek).diff(this.gebDat, 'years');
		moment(age).format('YYYY-MM-DD');
		const nextYearDate = moment(this.gebDat).add(age + 1, 'years');
		return nextYearDate.toDate();
	}

	@computed
	get startOfWeekMom() {
		return moment().startOf('day').startOf('week');
	}

	@computed
	get nextBirthdayInDays() {
		return moment(this.nextBirthday).startOf('day').diff(moment(), 'days') + 1;
	}
	@computed
	get nextBirthdayInDaysForCalc() {
		let days = moment(this.nextBirthday).startOf('day').diff(this.startOfWeekMom, 'days');
		if (days > 300) {
			days = days - 365;
		}
		return days;
	}

	@computed
	get hasUpcomingBirthday() {
		const year = moment(this.gebDat).year();
		if (year <= 1900 || !this.gebDat) {
			return false;
		}
		const days = this.nextBirthdayInDaysForCalc;
		if (days <= 12 * 7 && days >= -2) {
			// 3 monate zukunft - 2 tage vergangeheit
			return true;
		}
		return false;
	}

	@computed
	get hasUpcomingBirthday14() {
		if (!this.gebDat) return false;
		const days = this.nextBirthdayInDaysForCalc;
		if (days <= 14 && days >= -2) {
			// 14 tage in die Zukunft - 2 tage vergangeheit
			return true;
		}
		return false;
	}

	@computed
	get gebDatMonthDay() {
		const geb = moment(this.gebDat);
		return geb.format('MM-DD');
	}

	@computed
	get beitrittDatMonthDay() {
		const geb = moment(this.beitrittDat);
		return geb.format('MM-DD');
	}

	@computed
	get workingYears() {
		if (!this.beitrittDat) {
			return 0;
		}
		const jub = moment(this.beitrittDat);
		const today = moment().add(1, 'years');
		return today.diff(jub, 'years');
	}

	@computed
	get anniversaryDate() {
		let anniversaryDate = null;
		let jubel = moment(this.beitrittDat);
		if (this.jubDat && this.jubDat.getFullYear() > 1900) {
			jubel = moment(this.jubDat);
		}

		const thisYear = moment().startOf('year');
		const workingYears = this.workingYears;
		// const workingYears = thisYear.year() - jubel.year();
		if (workingYears >= 10) {
			if (workingYears % 5 === 0) {
				anniversaryDate = jubel.add(workingYears, 'years');
			} else {
				anniversaryDate = jubel
					.clone()
					.set('year', thisYear.get('year'))
					.add(5 - (workingYears % 5), 'years');
			}
		} else {
			anniversaryDate = jubel.add(5, 'years');
		}

		return anniversaryDate.toDate();
	}

	@computed
	get hasAnniversary() {
		// Anniversary is only if teilhaber is working since 10 years (after that every 5 years is anniversary)
		const workingYears = this.workingYears;
		if (workingYears >= 10) {
			if (workingYears % 5 === 0) {
				return true;
			}
		}
		return false;
	}

	@computed
	get nextAnniversaryDate() {
		const age = moment(this.startOfWeek).diff(this.anniversaryDate, 'years');
		moment(age).format('YYYY-MM-DD');
		if (age === 0) {
			return this.anniversaryDate;
		}
		const nextYearDate = moment(this.anniversaryDate).add(age + 1, 'years');
		return nextYearDate.toDate();
	}

	@computed
	get nextAnnivesaryInDays() {
		return moment(this.nextAnniversaryDate).diff(this.startOfWeek, 'days');
	}

	@computed
	get hasUpcomingAnniversary() {
		const days = this.nextAnnivesaryInDays;
		if (days <= 12 * 7 && days >= -2) {
			// 3 monate zukunft - 2 tage vergangeheit
			return true;
		}
		return false;
	}

	@computed
	get willReachRetirementAge() {
		return this.age >= 55;
	}

	@computed
	get retirementDate() {
		const gebDat = moment(this.gebDat);
		// Retirement date is the first day of the next month after his 65th birthday
		const retirementDate = gebDat.add(65, 'years').add(1, 'months').startOf('months');
		return retirementDate.toDate();
	}

	@computed
	get retirementDateMonthDay() {
		return moment(this.retirementDate).format('MM-DD');
	}

	// workingYears: Ember.computed('jubel', function () {
	// 	var jubelYear = moment(this.get('jubel'));
	// 	var nextYear = moment().add(1, 'years');
	// 	return nextYear.diff(jubelYear, 'years');
	// }),

	@computed
	get identifier() {
		return this.fullName + '-' + this.gebDat;
	}
	// identifier: Ember.computed('bensl', 'gebDat', function () {
	// 	return this.get('name') + '-' + this.get('gebDat');
	// }),
	// kat: Ember.computed('bensl', function () {
	// 	return 'vertreter';
	// }),
	// agtId: Ember.computed.alias('vtnr_id'),
	// bensl: Ember.computed.alias('thBensl'),
	// vtnr_id: null,
	// gebDat: Ember.computed('thGebdat', function () {
	// 	var dat = this.get('thGebdat');
	// 	if (Ember.isEmpty(dat)) {
	// 		return;
	// 	}
	// 	return new Date(dat);
	// }),
	// gebDateMD: Ember.computed('gebDat', function () {
	// 	return moment(this.get('gebDat')).format('MM-DD');
	// }),
	// alter: Ember.computed('gebDat', function () {
	// 	return 0;
	// }),

	// jubel: Ember.computed('thZubdat', function () {
	// 	//  jubiläum
	// 	var dat = this.get('thZubdat');
	// 	if (Ember.isEmpty(dat)) {
	// 		return;
	// 	}
	// 	return new Date(dat);
	// }),
	// beginn: Ember.computed('thBeginn', function () {
	// 	//  jubiläum
	// 	var dat = this.get('thBeginn');
	// 	if (Ember.isEmpty(dat)) {
	// 		return;
	// 	}
	// 	return new Date(dat);
	// }),

	// anniversaryDate: Ember.computed('jubel', function () {
	// 	var anniversaryDate = null;
	// 	var jubel = moment(this.get('jubel'));
	// 	var thisYear = moment().startOf('year');
	// 	//var workingYears = thisYear.diff(jubel.clone().startOf('year'), 'years');
	// 	var workingYears = this.get('workingYears');
	// 	if (workingYears >= 10) {
	// 		if (workingYears % 5 === 0) {
	// 			anniversaryDate = jubel.add(workingYears, 'years');
	// 		} else {
	// 			anniversaryDate = jubel
	// 				.clone()
	// 				.set('year', thisYear.get('year'))
	// 				.add(5 - (workingYears % 5), 'years');
	// 		}
	// 	} else {
	// 		anniversaryDate = jubel.add(5, 'years');
	// 	}
	// 	return anniversaryDate.toDate();
	// }),

	// anniversaryDateMD: Ember.computed('anniversaryDate', function () {
	// 	return moment(this.get('anniversaryDate')).format('YYYY-MM-DD');
	// }),

	// workingYears: Ember.computed('jubel', function () {
	// 	var jubelYear = moment(this.get('jubel'));
	// 	var nextYear = moment().add(1, 'years');
	// 	return nextYear.diff(jubelYear, 'years');
	// }),

	// workingYearsText: Ember.computed('workingYears', function () {
	// 	let wy = this.get('workingYears');
	// 	if (Ember.isEmpty(wy)) {
	// 		return;
	// 	}
	// 	if (wy <= 1) {
	// 		return 'Erstes Jahr';
	// 	}
	// 	return wy + ' Jahre';
	// }),

	// hasAnniversary: Ember.computed('workingYears', function () {
	// 	// Anniversary is only if teilhaber is working since 10 years (after that every 5 years is anniversary)
	// 	var workingYears = this.get('workingYears');
	// 	if (workingYears >= 10) {
	// 		if (workingYears % 5 === 0) {
	// 			return true;
	// 		}
	// 	}
	// }),

	// nextBirthday: null,
	// nextBirthdayIn: null,
	// _hasUpcomingDate: function (dateProp, isBirthday) {
	// 	let birthday = moment(this.get(dateProp));
	// 	if (!this.get(dateProp) || birthday.year() === 1900) {
	// 		return false;
	// 	}
	// 	var today = moment().startOf('week').format('YYYY-MM-DD');

	// 	const age = moment(today).diff(birthday, 'years');
	// 	moment(age).format('YYYY-MM-DD');

	// 	let nextBirthday = moment(birthday).add(age, 'years');
	// 	moment(nextBirthday).format('YYYY-MM-DD');
	// 	nextBirthday = moment(birthday).add(age + 1, 'years');
	// 	const days = nextBirthday.diff(today, 'days');

	// 	if (isBirthday) {
	// 		this.set('nextBirthday', nextBirthday);
	// 		this.set('nextBirthdayIn', days);
	// 	}

	// 	if (days <= 12 * 7 || days >= 365) {
	// 		// 3 monate zukunft - 2 tage vergangeheit
	// 		return true;
	// 	}

	// 	return false;
	// },

	// hasUpcomingAnniversary: Ember.computed('hasAnniversary', 'anniversaryDate', function () {
	// 	if (!this.get('hasAnniversary') || this.get('workingYears') > 60) {
	// 		return false;
	// 	}
	// 	return this._hasUpcomingDate('anniversaryDate');
	// }),

	// hasUpcomingBirthday: Ember.computed('gebDat', function () {
	// 	return this._hasUpcomingDate('gebDat', true);
	// }),

	// retirementDate: Ember.computed('gebDat', function () {
	// 	var gebDat = moment(this.get('gebDat'));

	// 	// Retirement date is the first day of the next month after his 65th birthday
	// 	var retirementDate = gebDat.add(65, 'years').add(1, 'months').startOf('months');

	// 	return retirementDate.toDate();
	// }),

	// willReachRetirementAge: Ember.computed('age', function () {
	// 	return this.get('age') >= 55;
	// }),

	// birthdayToday: Ember.computed('gebDat', function () {
	// 	var gebDat = moment(this.get('gebDat'));
	// 	var today = moment();
	// 	var gDay = gebDat.date();
	// 	var gMonth = gebDat.month();
	// 	var tDay = today.date();
	// 	var tMonth = today.month();
	// 	if (gDay === tDay && gMonth === tMonth) {
	// 		return true;
	// 	}
	// 	return false;
	// }),

	// age: Ember.computed('gebDat', 'birthdayToday', function () {
	// 	var gebDat = moment(this.get('gebDat'));
	// 	var nextYear = moment().add(1, 'years');

	// 	if (this.get('birthdayToday')) {
	// 		nextYear = moment().endOf('day');
	// 	}
	// 	return nextYear.diff(gebDat, 'years');
	// }),

	// birthdayText: Ember.computed('age', 'nextBirthdayIn', function () {
	// 	let age = this.get('age');
	// 	let today = moment().startOf('day');
	// 	let days = this.get('nextBirthday');

	// 	if (days.diff(today, 'days') < 0) {
	// 		age -= 1;
	// 	}

	// 	if (Ember.isEmpty(age)) {
	// 		return;
	// 	}
	// 	return age + '. Geburtstag';
	// }),

	// retirementDateMD: Ember.computed('retirementDate', function () {
	// 	return moment(this.get('retirementDate')).format('YYYY-MM-DD');
	// }),

	// name: Ember.computed('thVname', 'thZname', function () {
	// 	return this.get('thVname') + ' ' + this.get('thZname');
	// }),
}
