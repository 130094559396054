import { Icon } from 'app/components/common/Icon';
import { TreeNodeModel } from 'app/models/core/tree.node.model';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { Link, useLocation } from 'react-router-dom';
import { ButtonHTMLAttributes, useEffect, useState } from 'react';
import { BaseTreeStore, AgtBaseTreeStore } from 'app/stores/core/base.tree.store';
import { useQueryParams } from 'use-query-params';
import { QueryParmDef } from 'app/containers/BerichteContainer';
import { useStore } from 'app/context';
import { SteartModel } from 'app/models/core/steart.model';

interface ITreeNodesProps {
	treeStore: AgtBaseTreeStore | BaseTreeStore;
	nodes: TreeNodeModel[];
}

interface ITreeNodeProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	node: TreeNodeModel;
}

interface ITreeNodeItemProps {
	node: TreeNodeModel;
	treeStore: AgtBaseTreeStore | BaseTreeStore;
}

export const TreeNodeToggle = observer((props: ITreeNodeProps) => {
	const node = props.node;
	return (
		<button className="button is-blank tree-node-toggle" onClick={() => node.setOpen(!node.open)}>
			{props.children}
		</button>
	);
});

export const TreeNodeItem = observer((props: ITreeNodeItemProps) => {
	const { session } = useStore();

	const location = useLocation<any>();
	const node = props.node;
	const hasChildren = node.nodes.length > 0;

	const [query] = useQueryParams(QueryParmDef);
	const [steart, setSteart] = useState<SteartModel | undefined>(session.currentSteart);
	const [isVisibleForRole, setIsVisibleForRole] = useState(true);

	const classes = classNames('tree-node', `level-${node.level}`, {
		'is-active': node.active,
		'is-open': node.open,
		'has-children': hasChildren,
	});
	useEffect(() => {
		setSteart(session.currentSteart);
		if (steart) {
			setIsVisibleForRole(props.node.isVisibleForRole(steart.steart));
		} else {
			setIsVisibleForRole(true);
		}
	}, [session.currentSteart, props.node, steart]);

	if (!isVisibleForRole) {
		return <></>;
	}

	return node.level === 1 || hasChildren ? (
		<li key={node.path} className={classes}>
			<TreeNodeToggle node={node}>
				{node.level === 1 && node.icon ? (
					<span className="node-icon">
						<Icon iconClass={node.icon} />
					</span>
				) : (
					<span className="toggle-icon">{node.open ? <Icon iconClass="caret-down" /> : <Icon iconClass="caret-right" />}</span>
				)}
				<span className="tree-node-title">{node.name}</span>
			</TreeNodeToggle>
			{node.open && <TreeNode nodes={node.nodes} treeStore={props.treeStore} />}
		</li>
	) : (
		<Link key={node.path} className={`tree-node-link ${classes}`} to={props.treeStore.getPath(node, location, query)}>
			{node.name}
		</Link>
	);
});

export const TreeNode = observer((props: ITreeNodesProps) => {
	const { session } = useStore();
	// const steart = session.currentUser!.steart.steart;
	const [steart, setSteart] = useState<SteartModel | undefined>(session.currentSteart);
	const [nodes, setNodes] = useState(props.nodes);
	useEffect(() => {
		setSteart(session.currentSteart);
		let n = props.nodes;
		n = n.filter((n) => n.hideFromAll === false);
		if (props.treeStore.selectedAgt) {
			n = n.filter((n) => n.hideFromAgt === false);
		}
		if (steart) {
			n = n.filter((n) => n.isVisibleForRole(steart.steart));
		}
		setNodes(n);
	}, [props.treeStore.selectedAgt, props.nodes, session.currentSteart, steart]);

	return (
		<ul className="tree-nodes">
			{nodes.map((node, i) => (
				<TreeNodeItem key={i} node={node} treeStore={props.treeStore} />
			))}
		</ul>
	);
});
