import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import moment from 'moment';
import { createRef, useEffect } from 'react';
import { WochenmailAgtErrungenschaften } from './WochenmailAgtErrungenschaften';
import { WochenmailBirthdays } from './WochenmailBirthdays';
import { WochenmailBnrErrungenschaften } from './WochenmailBnrErrungenschaften';
import { WochenmailJubel } from './WochenmailJubel';
import { WochenmailSend } from './WochenmailSend';
import { WochenmailSharedDocs } from './WochenmailSharedDocs';
import { WochenmailTaskStats } from './WochenmailTasksStats';
import colors from 'assets/scss/colors.module.scss';
import { Button } from '../common/Button';
import { PlainTextWochenmail } from './PlainTextWochenmail';
import { WochenmailExpertisen } from './WochenmailExpertise';
import { WochenmailNews } from './WochenmailNews';
import { formatDateLong } from 'app/utils';

interface IWMTableRow {
	children: React.ReactNode;
}

export const WmTableRow: React.FC<IWMTableRow> = ({ children }) => {
	const colStyle = {
		padding: '40px',
		borderBottom: `10px solid ${colors.almostWhite}`,
	};
	return (
		<tr>
			<td style={colStyle} colSpan={2}>
				{children}
			</td>
		</tr>
	);
};

export interface IWMStyle {
	wmStyles?: any;
	isText?: boolean;
}

export const Wochenmail = observer(() => {
	const { session, wochenmailtUiStore } = useStore();

	useEffect(() => {
		wochenmailtUiStore.load();
	}, [wochenmailtUiStore]);

	const onClick = () => {
		wochenmailtUiStore.toggleShowAllSections();
	};

	const kw = moment().format('YYYYww');
	const displayKw = moment().format('ww - YYYY');

	const htmlRef = createRef<HTMLDivElement>();
	const textRef = createRef<HTMLDivElement>();

	const tableWidth = '600px';

	const wochenContainerStyle = {
		backgroundColor: colors.almostWhite,
		width: '100%',
		padding: '40px',
		fontFamily: `Allianz Neo, sans-serif`,
	};

	const tableStyle = {
		backgroundColor: colors.white,
		width: tableWidth,
		fontFamily: `Allianz Neo, sans-serif`,
	};
	const innerTableStyle = {
		width: tableWidth,
		fontFamily: `Allianz Neo, sans-serif`,
		borderCollapse: 'separate',
		borderSpacing: '5px',
	};

	const headingStyle = {
		fontSize: '24px',
		fontFamily: `Allianz Neo, sans-serif`,
		verticalAlign: 'center',
	};

	const headingTdStyle = {
		paddingBottom: '30px',
		textAlign: 'center',
	};

	const tdStyle = {
		padding: '40px',
	};

	const blockStyle = {
		borderWidth: '10px',
		borderStyle: 'solid',
		paddingTop: '20px',
		paddingBottom: '20px',
		fontFamily: `Allianz Neo, Arial, Sans-Serif`,
	};

	const blueBlock = {
		...blockStyle,
		borderColor: colors.blue,
		backgroundColor: '#D9EBF7',
	};

	const yellowBlock = {
		borderColor: colors.warning,
		backgroundColor: '#FFF8E6',
		...blockStyle,
	};

	const greenBlock = {
		borderColor: colors.green,
		backgroundColor: '#E4EDDA',
		...blockStyle,
	};

	const wmStyles = {
		wochenContainerStyle,
		tableStyle,
		innerTableStyle,
		headingStyle,
		headingTdStyle,
		tdStyle,
		blueBlock,
		yellowBlock,
		greenBlock,
	};

	const buttonStyles = {
		background: colors.primary,
		color: colors.white,
		border: `10px solid ${colors.primary}`,
		textDecoration: 'none',
	};

	return (
		<>
			<strong>KW: {kw}</strong>
			<br />
			{session.currentUser!.bnrId} {session.currentUser!.bensl} {session.currentUser!.steart.name} <br />
			{session.currentUser!.lastCrawlDate && <>
				lastCrawlDate: {formatDateLong(session.currentUser!.lastCrawlDate)}
			</>}
			<br />
			<Button type={'button'} className={'button crawlSendMail'} onClick={onClick}>
				Toggle Section Display (nur für devs)
			</Button>
			<WochenmailSend kw={kw} htmlRef={htmlRef} textRef={textRef} />
			<div className={'htmlContent'} ref={htmlRef}>
				<table style={wochenContainerStyle}>
					<tbody>
						<tr>
							<td></td>
							<td width={tableWidth}>
								<table style={tableStyle}>
									<tbody>
										<WmTableRow>
											<table width="100%">
												<tbody>
													<tr>
														<td>
															<h2 style={headingStyle}>DVS Wochenbericht</h2>
														</td>
														<td>
															<h2 style={{ ...headingStyle, textAlign: 'right' }}>KW {displayKw}</h2>
														</td>
													</tr>
												</tbody>
											</table>
										</WmTableRow>

										<WochenmailNews wmStyles={wmStyles} />
										<WochenmailTaskStats wmStyles={wmStyles} />
										<WochenmailSharedDocs wmStyles={wmStyles} />
										<WochenmailBnrErrungenschaften wmStyles={wmStyles} />
										<WochenmailAgtErrungenschaften wmStyles={wmStyles} />

										{wochenmailtUiStore.isVblOrLeiter && (
											<>
												<WochenmailExpertisen wmStyles={wmStyles} />
												<WochenmailBirthdays wmStyles={wmStyles} />
												<WochenmailJubel wmStyles={wmStyles} />
											</>
										)}

										<WmTableRow>
											<p>
												Mehr in der{' '}
												<a style={buttonStyles} href="https://dvs-app.de?mtm_campaign=wochenmail">
													<strong>DVS APP</strong>
												</a>
											</p>
										</WmTableRow>
									</tbody>
								</table>
							</td>
							<td></td>
						</tr>
						<tr>
							<td></td>
							<td>
								<div style={{ padding: '20px', textAlign: 'center' }}>
									<small>
										Falls Sie diese E-Mail in Zukunft nicht mehr erhalten möchten, <br /> können Sie die in den Einstellungen Ihrer DVS abbestellen.
									</small>
								</div>
							</td>
							<td></td>
						</tr>
					</tbody>
				</table>
			</div>
			<br />
			<br />
			<br />
			<code>
				<div className={'textContent'} ref={textRef}>
					<PlainTextWochenmail displayKw={displayKw} />
				</div>
			</code>
		</>
	);
});

export const getErrungenschaftBorderColor = (key: string) => {
	switch (key) {
		case 'bnr.docs.created':
		case 'bnr.tasks.completed':
		case 'bnr.dvs.experte':
		case 'bnr.docs.shared':
			return colors.blue;
		case 'bnr.jpgs.created':
		case 'bnr.bp.planned':
		case 'bnr.planwert':
		case 'bnr.meisterplaner':
		case 'agt.jpg':
		case 'agt.ap':
		case 'agt.digifaktor':
			return colors.warning;
		case 'bnr.zielerfueller':
		case 'bnr.agt.produktivitaet':
		case 'bnr.gp.eh':
		case 'agt.idd':
		case 'agt.produktivitaet':
			return colors.turquoise;
		case 'agt.gesamtbewertung':
		case 'agt.bestandswachstum':
			return colors.orange;
		default:
			return colors.blue;
	}
};

export const WMErrungenschaftBlock = (props: any) => {
	const { item } = props;

	const styles = {
		border: '4px solid',
		borderColor: getErrungenschaftBorderColor(item.def.key),
		padding: '10px',
		display: 'inline-block',
	};
	const borderStyle = {
		display: 'inline-block',
		borderRight: '4px solid transparent',
		borderBottom: '4px solid transparent',
	};

	return (
		<>
			<span style={borderStyle}>
				<span style={styles}>{item.def.modal.title}</span>
			</span>
			&nbsp;
		</>
	);
};
