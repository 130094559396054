import { action, computed, makeObservable, reaction } from 'mobx';
import moment from 'moment';
import { BaseCachedEntityStore } from './core/base.cached.entity.store';
import { AgtGpStore } from './agt.gp.store';
import { SessionStore } from './session.store';
import { calcZielErriechung, calcZielErriechungZeit } from 'app/utils';

export class ZeModel {
	constructor(opts: any) {
		makeObservable(this);
		const mom = moment(opts.stichtag);
		this.stichtag = mom.toDate();
		this.ergebnis = opts.ergebnis;
		this.ziel = opts.ziel;
	}
	stichtag: Date;
	ergebnis: number;
	ziel: number;

	@action
	addZiel(z: number) {
		if (z) {
			this.ziel = this.ziel + z;
		}
	}

	@action
	addErgebnis(e: number) {
		if (e) {
			this.ergebnis = this.ergebnis + e;
		}
	}

	@computed
	get monat() {
		return moment(this.stichtag).month() + 1;
	}

	@computed
	get ze() {
		const z = this.ziel;
		if (z === 0) {
			return 0;
		}
		const e = this.ergebnis;
		let ze = (e / z) * 100;
		ze = Math.round(ze * 100) / 100;
		return ze;
	}

	@computed
	get zeZeit() {
		const z = (this.ziel * this.monat) / 12;
		if (z === 0) {
			return -1;
		}
		const e = this.ergebnis;
		let ze = (e / z) * 100;
		ze = Math.round(ze * 100) / 100;
		return ze;
	}
}

export class AgtZielModel {
	constructor(opts: any) {
		makeObservable(this);
		this.agtId = opts.agtId;
		this.id = opts.id;
		this.bm = opts.bm;
		this.ergebnis = opts.ergebnis;
		this.posId = opts.posId;
		this.ziel = opts.ziel;
		const mom = moment(opts.stichtag);
		this.stichtag = mom.toDate();
		// year is now being provided by the server. was too expensive for multis
		this.year = opts.year;
		// this.year = moment(opts.bm + '01').year();
		// if (opts.year) {
		// }
	}
	id: string;
	agtId: number;
	posId: number;
	bm: string;
	stichtag: Date = new Date();
	year: number;
	ergebnis: number;
	ziel: number;

	@computed
	get monat() {
		return moment(this.stichtag).month() + 1;
	}

	@computed
	get ze() {
		return calcZielErriechung(this.ziel, this.ergebnis);
	}

	@computed
	get zeStern() {
		if (this.ze < 60) {
			return 60;
		}
		if (this.ze > 160) {
			return 160;
		}
		return this.ze;
	}

	@computed
	get zeZeit() {
		return calcZielErriechungZeit(this.ziel, this.ergebnis, this.monat);
	}
}

export class AgtZielStore extends BaseCachedEntityStore<AgtZielModel> {
	apiPath = 'api/agt/posziel';
	idProp: string = 'id';
	gpStore: AgtGpStore;

	datenStand: Date = new Date();
	datenStandProp?: string = 'stichtag';

	constructor(session: SessionStore, gpStore: AgtGpStore) {
		super(session);
		this.gpStore = gpStore;
		reaction(
			() => this.gpStore.session.currentUser,
			() => {
				this.registerPosIds(this.gpStore.allPosIds);
			},
			{ fireImmediately: false },
		);
	}

	createInstances(opts: any): AgtZielModel {
		return new AgtZielModel(opts);
	}

	posIds: number[] = [];
	@action
	registerPosIds(ids: number[]) {
		this.posIds = this.posIds.concat(ids);
		this.apiPath = 'api/agt/posziel/' + this.posIds.join(',');
	}

	disableFindForStearts = [123];

	async findAll(): Promise<AgtZielModel[]> {
		const data = await this.__findAll();
		if (!data || data.length === 0) {
			console.warn('Keine Agt Ziel Daten');
			return [];
		}
		return data;
	}

	async findByIds(ids: number[]) {
		const items = await this.findAll();
		return items.filter((d) => ids.includes(d.posId));
	}

	async findByPlanKey(planKey: string, year: number) {
		const gpPos = this.gpStore.findAgtPositionByPlanKey(planKey, year);
		let data = await this.findAll();
		data = data.filter((x) => x.year === year);
		// if (planKey === 'idd') {
		// 	debugger;
		// }
		return data.filter((d) => gpPos && gpPos.posIds.includes(d.posId));
	}

	async findByPosId(posId: number, year: number) {
		const data = await this.findAll();
		return data.filter((d) => d.posId === posId && d.year === year);
	}
}
