import Widget, { IWidgetConsumer } from 'app/components/common/Widget';
import { observer } from 'mobx-react';
import { useStore } from 'app/context';
import { SteartTag } from 'app/components/agt/common/SteartTag';
import { useEffect, useState } from 'react';
import { UserModel } from 'app/models/core/user.model';

export const AgenturBetreuerWidget = observer((props: IWidgetConsumer) => {
	const { agenturUiStore } = useStore();

	const [users, setUsers] = useState<UserModel[]>([]);

	useEffect(() => {
		const agt = agenturUiStore.current;
		setUsers([]);
		if (agt && agt.users.length > 0) {
			setUsers(agt.users);
		}
	}, [agenturUiStore, agenturUiStore.current?.users, agenturUiStore.current?.users.length]);

	return (
		<Widget title={'Betreuer'} widgetId={props.widgetKey}>
			<div className="agt-betreuer-widget">
				{users.map((u) => {
					return (
						<span className="betreuer-widget-item" key={u.bnrId}>
							<SteartTag id={u.steart.id} />
							<span className="name">
								{u.name} ({u.bnrId})
							</span>
						</span>
					);
				})}
			</div>
		</Widget>
	);
});
