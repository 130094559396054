import { useStore } from 'app/context';
import { TemplateVM } from 'app/stores/ui/template.ui.store';
import { observer } from 'mobx-react';
import { DocumentList } from '../documents/DocumentList';
import { DocumentSendModal } from '../documents/DocumentSendModal';
import { DocumentFilter } from '../documents/DocumentsFilter';
import { DocumentShareModal } from '../documents/DocumentShareModal';
import { DocumentViewerModal } from '../documents/DocumentViewerModal';
import { DokumentUploadButton } from '../documents/DokumentUploadButton';
import { TemplateSelectorModal } from '../documents/TemplateSelectorModal';

export const AgenturDokumente = observer(() => {
	const { agenturUiStore, documentUiStore, uiStore } = useStore();
	const agt = agenturUiStore.current;

	const addDok = async (template: TemplateVM) => {
		const vm = await documentUiStore.addDokumentToPlan(template, agenturUiStore.current);
		documentUiStore.setCurrentModalDoc(vm);
		uiStore.showModal(uiStore.modalIds.documentViewModal);
	};

	return (
		<div className="is-agentur">
			{agt && (
				<>
					<div className="grid is-col-half pad-1rem">
						<div className="is-left">
							<DocumentFilter agtId={agenturUiStore.currentAgtId} />
						</div>
						<div className="is-right">
							<DokumentUploadButton isButton={true} agt={agt} />
							<TemplateSelectorModal onSelect={addDok} />
						</div>
					</div>
					<DocumentList agtId={agt.agtId} />
					<DocumentShareModal />
					<DocumentSendModal />
					<DocumentViewerModal showPlan={true} showAgt={false} />
				</>
			)}
		</div>
	);
});
