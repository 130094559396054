import { Field } from 'formik';
import { UserStatusSelectOpts } from 'app/models/core/user.model';

export const UserStatusSelectField = ({ ...props }) => {
	var { ...fieldProps } = props;

	return (
		<div className="select">
			<Field {...fieldProps} as="select">
				{UserStatusSelectOpts.map((p) => (
					<option key={p.value} value={p.value.toString()}>
						{p.label}
					</option>
				))}
			</Field>
		</div>
	);
};
