import { ISelectOpt } from 'app/components/common/Select';
import { AdminBnrSteartIds } from 'app/models/core/steart.model';
import { action, computed, IObservableArray, makeObservable, observable, runInAction } from 'mobx';
import { AdminBnrDiffStore, BnrDiff } from '../admin.bnrdiff.store';
import { AdminUserUiStore, UserVM } from './admin.user.ui.store';
import { UserModel } from 'app/models/core/user.model';
import { BnrMulitDiff } from '../admin.user.store';

export class BnrDiffVM {
	constructor(diff: BnrDiff, user?: UserVM) {
		makeObservable(this);
		this.diff = diff;
		this.user = user;
	}
	@observable
	diff: BnrDiff;

	@observable
	user?: UserVM;

	@computed
	get hasUser() {
		return this.user && this.user.user.bnrId === this.diff.bnrId;
	}

	@computed
	get has2OnPos3() {
		const x = this.diff.bnrId.toString().substring(2, 3);
		return x === '2';
	}

	@computed
	get fullText() {
		let s = this.diff.fullText;

		if (this.user) {
			s += this.user.fullText;
		}

		return s.toLowerCase();
	}
}


export class BnrMulitDiffVM {
	constructor(diff: BnrMulitDiff, user?: UserVM) {
		this.diff = diff;
		this.user = user;

	}
	@observable
	diff: BnrMulitDiff;

	@observable
	user?: UserVM;

	@computed
	get hasUser() {
		return this.user && this.user.user.bnrId === this.diff.bnrId;
	}
}




export class AdminBnrDiffUiStore {
	adminBnrDiffStore: AdminBnrDiffStore;
	adminUserUiStore: AdminUserUiStore;

	constructor(adminBnrDiffStore: AdminBnrDiffStore, adminUserUiStore: AdminUserUiStore) {
		makeObservable(this);
		this.adminBnrDiffStore = adminBnrDiffStore;
		this.adminUserUiStore = adminUserUiStore;
	}

	@observable current?: BnrDiffVM;

	canUpdateToBnrId(newBnrId: number) {
		if (this.current) {
			if (this.current.diff.bnrId === newBnrId) {
				return false;
			}
			if (this.current.diff.diff === 'deleted') {
				return false;
			}
		}
		return true;
	}

	@computed
	get currentNameToSearchFor() {
		if (!this.current) {
			return null;
		}
		if (this.current.user) {
			return this.current.user.user.email;
		}
		if (this.current.diff.toName) {
			return this.current.diff.toName;
		}
		return this.current.diff.fromName;
	}

	@computed
	get currentBnrId() {
		if (this.current) {
			return this.current.diff.bnrId;
		}
		return null;
	}

	@observable currentPossibleUserName: string = '';

	@computed
	get possibleMatchingUsers() {
		if (!this.currentPossibleUserName) {
			return [];
		}
		const users = this.adminUserUiStore._items;
		const filterName = this.currentPossibleUserName.toLowerCase().trim();
		let filtered = users.filter((x) => x.user.name && x.fullText.toLowerCase().indexOf(filterName) >= 0);
		const maxRows = 10;
		if (filtered.length > maxRows) {
			return filtered.slice(0, maxRows);
		}
		return filtered;
	}

	@action
	async loadBnrId(bnrId?: number) {
		if (!bnrId) {
			this.current = undefined;
		}
		if (this._items.length === 0) {
			await this.checkKwsAndLoad();
		}
		const u = this._items.find((x) => x.diff.bnrId === bnrId);
		runInAction(() => {
			this.current = u;
		});
	}

	async getMissingMulits() {
		const users = await this.adminUserUiStore.load();
		const items = await this.adminUserUiStore.adminUserStore.missingMultis();

		const res: BnrMulitDiffVM[] = [];
		items.forEach((diff: BnrMulitDiff) => {
			const u = users.find(u => u.user.bnrId === diff.bnrId);
			res.push(new BnrMulitDiffVM(diff, u));

		});
		const bnrIds = res.map(i => i.diff.bnrId);

		const usersWithNotAgt = users.filter(u => u.user.isMulti && u.user.agtCount === 0 && !bnrIds.includes(u.user.bnrId));

		usersWithNotAgt.forEach(u => {
			const d = new BnrMulitDiffVM({ bnrId: u.user.bnrId, stat: 'noagts' }, u);
			res.push(d);

		})


		return res;
	}

	@observable currentFromKw?: number;

	@observable currentToKw?: number;

	@observable
	stearts?: number[];

	@observable
	diffType?: string[];

	@observable
	fullText: string = '';

	@observable
	showOnly2On3BnrIds: boolean = true;

	@observable
	hasUserAccount: boolean = false;

	@observable
	kws: number[] = [];

	@computed
	get kwOpts(): ISelectOpt[] {
		return this.kws.map((kw) => {
			const y = kw.toString().substring(0, 4);
			const w = kw.toString().substring(4);
			return {
				value: kw,
				label: y + '-' + w,
			};
		});
	}

	async loadKws() {
		if (this.kws.length > 0) {
			return;
		}
		await this.adminBnrDiffStore.getValidKws().then((kws) => {
			runInAction(() => {
				this.kws = kws.sort().reverse();

				if (this.kws.length > 1) {
					this.currentFromKw = this.kws[1];
					this.currentToKw = this.kws[0];
				}
			});
		});
		return this.kws;
	}

	@observable
	_items: IObservableArray<BnrDiffVM> = observable([]);

	@computed
	get filtered() {
		let items = this._items as BnrDiffVM[];
		if (this.stearts) {
			// items = items.filter((i) => (i.diff.fromSteart && this.stearts!.includes(i.diff.fromSteart)) || (i.diff.toSteart && this.stearts!.includes(i.diff.toSteart)));
			items = items.filter((i) => (i.diff.fromSteartId && this.stearts!.includes(i.diff.fromSteartId)) || (i.diff.toSteartId && this.stearts!.includes(i.diff.toSteartId)));
		}
		if (this.diffType) {
			items = items.filter((i) => this.diffType!.includes(i.diff.diff));
		}
		if (this.fullText && this.fullText.length > 2) {
			const f = this.fullText.toLowerCase();
			items = items.filter((i) => i.fullText.indexOf(f) >= 0);
		}
		if (this.showOnly2On3BnrIds) {
			items = items.filter((i) => i.has2OnPos3);
		}
		if (this.hasUserAccount) {
			items = items.filter((i) => !!i.user);
		}

		return items;
	}

	@computed
	get invitableUsers() {
		return this.filtered.filter((i) => i.user && i.user.user.isInvitable === true);
	}

	@computed
	get invitableUserCount() {
		return this.invitableUsers.length;
	}

	@action
	async bulkSendMail() {
		const users: UserModel[] = this.invitableUsers.filter((i) => i.user!).map((i) => i.user!.user!);
		await this.adminUserUiStore._bulkSendMail(users);
	}

	@computed
	get rerenderTrigger() {
		return this.filtered.map((i) => i.diff.bnrId).join(' ');
	}

	loaded: boolean = false;

	async checkKwsAndLoad() {
		if (this.currentFromKw && this.currentFromKw > 0 && this.currentToKw && this.currentToKw > 0) {
			await this.load(this.currentFromKw, this.currentToKw);
		}
	}

	async load(fromKw: number, toKw: number) {
		const p = await Promise.all([this.adminUserUiStore.load(), this.adminBnrDiffStore.findDiff(fromKw, toKw)]);
		let items = p[1];
		const users = p[0];

		const res: BnrDiffVM[] = [];

		items = items.filter((i) => (i.fromSteartId && AdminBnrSteartIds!.includes(i.fromSteartId)) || (i.toSteartId && AdminBnrSteartIds!.includes(i.toSteartId)));

		items.forEach((t) => {
			const u = users.find((u) => u.user.bnrId === t.bnrId);
			res.push(new BnrDiffVM(t, u));
		});
		runInAction(() => {
			this._items.replace(res);
			this.loaded = true;
		});

		return this._items;
	}
}
