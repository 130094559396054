import BaseContainer from 'app/containers/BaseContainer';
import { observer } from 'mobx-react';
import { MainMenu } from './MainMenu';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useStore } from 'app/context';
import React, { useEffect } from 'react';
import { AdminBnrTransfersList } from 'app/components/admin/bnrtransfer/AdminBnrTransfersList';
import { AdminBnrTransferDetail } from 'app/components/admin/bnrtransfer/AdminBnrTransferDetail';
import { AdminBnrTransferNewButton } from 'app/components/admin/bnrtransfer/AdminBnrTransferNewButton';
import { AdminBnrTransferablesFilter } from 'app/components/admin/bnrtransfer/AdminBnrTransferablesFilter';
import { EnsureAdmin } from './AdminHomeContainer';

export interface AdminBnrTransferParamTypes {
	id?: string;
}

const BnrTransferContainer = observer((props: RouteComponentProps) => {
	const { session, adminBnrTransferUiStore } = useStore();

	const currentUser = session.currentUser;

	const { id } = useParams<AdminBnrTransferParamTypes>();

	useEffect(() => {
		if (id) {
			adminBnrTransferUiStore.loadById(id);
		}
	}, [adminBnrTransferUiStore, id]);

	const TransferNavbar = () => {
		return (
			<div className="admin-navbar">
				<div className="is-left">
					<strong>Datenübernahme</strong>
				</div>
				<div className="is-right">
					<AdminBnrTransferNewButton />
				</div>
			</div>
		);
	};

	return (
		<BaseContainer sideMenu={<MainMenu {...props} />} navbar={<TransferNavbar />}>
			{currentUser && (
				<>
					<EnsureAdmin />
					<AdminBnrTransferDetail />
					<AdminBnrTransferablesFilter />
					<AdminBnrTransfersList />
				</>
			)}
		</BaseContainer>
	);
});

export default BnrTransferContainer;
