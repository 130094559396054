import { Einheit } from 'app/models/core/einheit.model';
import { BnrGpPosModel } from '../bnr.gp.pos.model';
import { PosUnDef } from '../gp';
const posDef: BnrGpPosModel[] = [];

posDef.push(new BnrGpPosModel({ year: 2023, posId: 2215001, group: 'vss', posText: 'Weiterbildungspfl. BNR (IDD)', planKey: 'idd', einheit: Einheit.stck }));
posDef.push(new BnrGpPosModel({ year: 2023, posId: PosUnDef, group: 'vss', posText: 'Weiterbildungsz. Fond', planKey: 'idd_fonds', einheit: Einheit.stck }));
posDef.push(new BnrGpPosModel({ year: 2023, posId: 2215010, group: 'vss', posText: 'Fzg. in Kleinflotten/FPM', planKey: 'fzgkleinflotten', einheit: Einheit.stck }));
posDef.push(new BnrGpPosModel({ year: 2023, posId: 2215030, group: 'vss', posText: 'Unternehmenschutz', planKey: 'unternehmensschutz', einheit: Einheit.stck }));
posDef.push(new BnrGpPosModel({ year: 2023, posId: 2215035, group: 'vss', posText: 'Unfall Bew o. Anp. Digi', planKey: 'unfallbew', einheit: Einheit.bew }));
// missing from agt gp
posDef.push(new BnrGpPosModel({ year: 2023, posId: 2215041, group: 'eh', posText: 'F-Sach Bewertung', planKey: 'firmensachbewertung', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2023, posId: 2215042, group: 'eh', posText: 'Unfall Bewertung o. Anp.', planKey: 'unfallbew', einheit: Einheit.bew }));
// missing from agt gp
posDef.push(new BnrGpPosModel({ year: 2023, posId: 2215111, group: 'neh', posText: 'Firmen Sach o. Kr. Bew', planKey: 'firmensachbew', einheit: Einheit.bew }));
// missing from agt gp
posDef.push(new BnrGpPosModel({ year: 2023, posId: 2215121, group: 'neh', posText: 'Unfall Bew o. Anp.', planKey: 'unfallbew', einheit: Einheit.bew }));
// missing from agt gp
posDef.push(new BnrGpPosModel({ year: 2023, posId: 2215131, group: 'neh', posText: 'Firmen Rechtschutz', planKey: 'firmenrechtschutz', einheit: Einheit.bew }));
// missing from agt gp
posDef.push(new BnrGpPosModel({ year: 2023, posId: 2215151, group: 'neh', posText: 'Unfall Anschlussgeschäft', planKey: 'unfallanschl', einheit: Einheit.bew }));
// missing from agt gp
posDef.push(new BnrGpPosModel({ year: 2023, posId: 2215141, group: 'neh', posText: 'F-Sach o.Kr. BZUW-Beitrag', planKey: 'fsachokrbzwu', einheit: Einheit.bew }));

export const BsgPositionen = posDef;
