import { BtStueckTimeModel, BtStueckTimeModelChart } from 'app/components/charts/BtStueckTimeChart';
import { useStore } from 'app/context';
import { AgenturModel } from 'app/models/agentur.model';
import { useEffect, useState } from 'react';

interface IAgt {
	agt?: AgenturModel;
}

export const AgtBewertungsverlaufMonatsDelta = (props: IAgt) => {
	const { berichteUiiStore, agtBestandUiStore: bestandUiStore } = useStore();
	const [model, setModel] = useState<BtStueckTimeModel>();
	let agt: AgenturModel | undefined;
	if (props.agt) {
		agt = props.agt;
	} else {
		agt = berichteUiiStore.selectedAgt;
	}

	useEffect(() => {
		if (agt) {
			const _model = new BtStueckTimeModel({
				title: 'Bewertung',
				fill: 'green',
				btKey: 'production.zuwachs.bt',
				btLabel: 'Bewertung',
				stKey: 'production.zuwachs.st',
				stLabel: 'Stück',
				timeKey: 'bmLabel',
				timeLabel: 'Monat',
			});
			bestandUiStore.find1YearHistoryByAgtId(agt.agtId).then((res) => {
				_model.agentur = agt;
				_model.data = res;
				setModel(_model);
			});
		}
	}, [agt, bestandUiStore]);
	return <>{agt && model && <BtStueckTimeModelChart {...model} />}</>;
};
