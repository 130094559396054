import { Table } from 'app/components/table/table';
import { ITableColumn, TableModel, TableRow } from 'app/components/table/table.model';
import { useStore } from 'app/context';
import { UserLoginVM } from 'app/stores/ui/admin.user.login.ui.store';
import { formatDateLong } from 'app/utils';
import { observer } from 'mobx-react';
import { useEffect } from 'react';

export const AdminUserLoginTrackingList = observer(() => {
    const { adminUserLoginUiStore } = useStore();

    const tm = new TableModel<UserLoginVM, number>();
    let cols: ITableColumn<UserLoginVM, number>[] = [
        {
            label: 'bensl',
            path: 'track.bensl',
        },
        {
            label: 'Datum',
            path: 'track.created',
            render: (row: TableRow<UserLoginVM>) => {
                return (<>{formatDateLong(row.data.track.created)} </>)
            }
        },
        {
            label: 'Action',
            path: 'track.action',
        },
        {
            label: 'Steart',
            path: 'user.user.steart.steart',
        },
        {
            label: 'Name',
            path: 'user.user.name',
        },

    ];

    tm.setCols(cols);
    tm.sortBy = 'bnr.bnrId';
    tm.sortAsc = false;
    tm.idProperty = 'bnr.bnrId';

    useEffect(() => {
        adminUserLoginUiStore.load();
    }, [adminUserLoginUiStore]);

    useEffect(() => {
        if (adminUserLoginUiStore.loaded) {
            tm.setRowData(adminUserLoginUiStore._items);
        }
    }, [adminUserLoginUiStore.loaded, adminUserLoginUiStore._items]);

    return (
        <>
            <div>Anzahl Zeilen: {adminUserLoginUiStore._items.length}</div>
            <Table tm={tm} />
        </>
    );
});





