import _ from 'lodash';
import { computed, makeAutoObservable, makeObservable, observable } from 'mobx';
import moment from 'moment';
import { FindSteart, SpeziStearts, Steart, SteartModel } from './steart.model';

export interface IUserModel {
	id?: string;
	userId: string;
	bensl: string;
	firstName: string;
	lastName: string;
	email: string;
	userCategory: string;
	tel: string;
	mobil: string;
	bnrId: number;
	status: number;
	bnrSteart: number;
	name: string;
	comment: string;
	registeredInDvs: boolean;
	emailNotifications?: boolean;
	hasAupData: boolean;
	attemmptsCount?: number;
	lastAttemptDate?: Date;
	lastLoginDate?: Date;
	lastPasswordResetDate?: Date;
	last2FADate?: Date;
	vdList?: string[];
	gsList?: string[];
	userType?: string;
	agtCount?: number;
	created?: Date;
	history?: string[];
	demoBnrId?: string;
	lastCrawlDate?: Date;
}

export interface IAuthoriryModel {
	name: string;
}

export class UserHistory {
	constructor(h: string) {
		if (!h || !h.split) {
			return;
		}
		const s = h.split(';');
		const d = parseInt(s[0]);
		this.date = new Date(d);
		this.action = s[1];
		if (s.length >= 2) {
			this.data = s[2];
		}
	}
	date?: Date;
	action: string = '';
	data?: string;
}

export enum UserStatus {
	created = 0,
	active = 1,
	deleted = 2,
	login_locked = 3,
	blocked = 4,
}

const UserStatusText = (status: number | UserStatus) => {
	switch (status) {
		case UserStatus.created:
			return 'Erstellt' + ` [${status}]`;
		case UserStatus.active:
			return 'Aktiv' + ` [${status}]`;
		case UserStatus.deleted:
			return 'Gelöscht' + ` [${status}]`;
		case UserStatus.login_locked:
			return 'login_locked' + ` [${status}]`;
		case UserStatus.blocked:
			return 'Blockiert' + ` [${status}]`;
		default:
			return 'Unbekannt:' + ` [${status}]`;;
	}
};

export const UserStatusSelectOpts = [
	{ value: 0, label: UserStatusText(0) },
	{ value: 1, label: UserStatusText(1) },
	{ value: 2, label: UserStatusText(2) },
	{ value: 3, label: UserStatusText(3) },
	{ value: 4, label: UserStatusText(4) },
];

export class UserModel {
	constructor(opts: IUserModel) {
		this.userId = opts.userId;
		if (opts.id) {
			this.userId = opts.id;
		}
		if (opts.bensl) {
			this.bensl = opts.bensl.toUpperCase();
		}

		this.firstName = opts.firstName;
		this.lastName = opts.lastName;
		this.email = opts.email;
		this.userCategory = opts.userCategory;
		this.tel = opts.tel;
		this.mobil = opts.mobil;
		this.bnrId = opts.bnrId;
		this.status = opts.status;
		if (opts.bnrSteart) {
			this.steart = FindSteart(opts.bnrSteart);
			if (!this.steart) {
				console.log('unkown steart for ' + opts.bnrId);
			}
		} else {
			this.steart = FindSteart(opts.bnrId);
			// console.log('no steart for ' + opts.bnrId);
		}
		this._name = opts.name;
		this.comment = opts.comment;
		this.registeredInDvs = opts.registeredInDvs;
		this.emailNotifications = opts.emailNotifications;
		this.hasAupData = opts.hasAupData;
		this.attemmptsCount = opts.attemmptsCount;
		this.agtCount = opts.agtCount;
		if (opts.lastAttemptDate) {
			this.lastAttemptDate = new Date(opts.lastAttemptDate);
		}
		if (opts.lastLoginDate) {
			this.lastLoginDate = new Date(opts.lastLoginDate);
		}
		if (opts.lastPasswordResetDate) {
			this.lastPasswordResetDate = new Date(opts.lastPasswordResetDate);
		}
		if (opts.last2FADate) {
			this.last2FADate = new Date(opts.last2FADate);
		}
		if (opts.gsList) {
			this.gsList = opts.gsList;
		}
		if (opts.vdList) {
			this.vdList = opts.vdList;
		}
		if (opts.userType) {
			this.userType = opts.userType;
		}
		if (opts.created) {
			this.created = new Date(opts.created);
		}
		if (opts.history && opts.history.length > 0) {
			this.history = opts.history.map((s) => new UserHistory(s));
			this.history = _.sortBy(this.history, 'date').reverse();
		}
		this.demoBnrId = opts.demoBnrId;

		if (opts.lastCrawlDate) {
			this.lastCrawlDate = new Date(opts.lastCrawlDate)
		}
	}

	userType: string = 'user';

	history: UserHistory[] = [];

	authorities: IAuthoriryModel[] = [];
	userId: string;
	bensl: string = '';
	@observable
	firstName: string;
	@observable
	lastName: string;
	@observable
	_name: string;

	demoBnrId?: string;

	@computed
	get name(): string {
		if (!this.userId) {
			return 'Neuer Benutzer';
		}
		if (this.lastName && this.firstName) {
			return this.firstName + ' ' + this.lastName;
		}
		return this._name;
	}

	@computed
	get lebend() {
		if (this.agtCount === 0) { return false };
		if (this.status !== 1) { return false; }
		return true;

	}

	email: string;
	userCategory: string;
	tel: string;
	mobil: string;
	@observable
	bnrId: number;
	status: UserStatus;
	@observable
	steart: SteartModel;
	comment: string;
	registeredInDvs: boolean;
	emailNotifications?: boolean;
	hasAupData: boolean;
	agtCount?: number;
	lastCrawlDate?: Date;

	@computed
	get isInvitable() {
		if (this.status !== UserStatus.created) {
			return false;
		}
		// if (this.status !== UserStatus.created && !this.isMulti) {
		// 	return false;
		// }
		if (!this.agtCount || this.agtCount === 0) {
			return false;
		}
		if (this.steart.steart === Steart.DVSADMIN || this.steart.id < 123) {
			return false;
		}

		if (this.lastInviteDaysAgo !== undefined && this.lastInviteDaysAgo < 7) {
			return false;
		}

		if (this.vdList.includes('X')) {
			return false;
		}

		if (this.gsList.includes('X')) {
			return false;
		}

		if (this.steartText.toLowerCase() === 'unbekannt') {
			return false;
		}

		return true;
	}

	@computed
	get isReportable() {
		if (this.vdList.includes('X')) {
			return false;
		}

		if (this.gsList.includes('X')) {
			return false;
		}
		return true;
	}

	@computed
	get lastInviteDaysAgo() {
		if (this.lastInviteDate) {
			const last = moment(this.lastInviteDate);
			return moment().diff(last, 'days');
		} else {
			return undefined;
		}
	}

	@computed
	get lastCrawlDaysAge() {
		if (this.lastCrawlDate) {
			const last = moment(this.lastCrawlDate);
			return moment().diff(last, 'days');
		} else {
			return undefined;
		}
	}

	@computed
	get lastInviteDate() {
		const i = this.history.find((x) => x.action === 'sendNewUserInvite');
		if (i) {
			return i.date;
		}
		return undefined;
	}

	@computed
	get isVBL() {
		return this.steart.steart === Steart.VBL;
	}

	@computed
	get isSpezi() {
		return SpeziStearts.includes(this.steart);
	}

	@computed
	get isGSL() {
		return this.steart.steart === Steart.GSL;
	}

	@computed
	get isLeiter() {
		return this.steart.steart === Steart.LPV || this.steart.steart === Steart.LSV;
	}

	@computed
	get isMulti() {
		return this.steart.steart === Steart.EDP || this.steart.steart === Steart.EDT || this.steart.steart === Steart.LDP;
	}

	@computed
	get isEDP() {
		return this.steart.steart === Steart.EDP;
	}

	vdList: string[] = [];
	gsList: string[] = [];

	attemmptsCount?: number;
	lastAttemptDate?: Date;
	lastLoginDate?: Date;
	lastPasswordResetDate?: Date;
	last2FADate?: Date;
	created?: Date;

	@computed
	get firstVd() {
		return this.vdList[0];
	}

	@computed
	get firstGs() {
		return this.gsList[0];
	}

	@computed
	get statusText() {
		return UserStatusText(this.status);
	}

	@computed
	get steartText() {
		return this.steart.name;
	}

	serialize() {
		let steart = this.steart.id;
		if (!this.userId && !this.steart) {
			steart = 934; // won't be saved. just to bypass validation
		}
		return {
			email: this.email,
			status: this.status,
			bensl: this.bensl,
			bnrId: this.bnrId,
			bnrSteart: steart,
			type: this.userType, // only for new
			firstName: this.firstName,
			lastName: this.lastName,
			tel: this.tel,
			mobil: this.mobil,
			comment: this.comment,
			demoBnrId: this.demoBnrId
		};
	}
}
