import { useHistory } from 'react-router';
import { Button } from './Button';
import { observer } from 'mobx-react';

interface IWidgetNavigate {
	text: string;
	path?: string;
}
export const WidgetNavigate = observer((props: IWidgetNavigate) => {
	const history = useHistory();
	const onClick = () => {
		if (props.path) {
			history.push(props.path);
		}
	};
	if (!props.path) {
		return <></>;
	}

	return (
		<Button className="button is-widget-header" onClick={onClick}>
			{props.text}
		</Button>
	);
});
