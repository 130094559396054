import { useStore } from 'app/context';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router';
import routes from 'routes';
import Panel from '../../Panel';
import { AdminNewsEditForm } from './AdminNewsEditForm';

export const AdminNewsDetail = observer(() => {
	const { adminNewsUiStore } = useStore();

	const history = useHistory();

	const onClose = () => {
		runInAction(() => {
			adminNewsUiStore.current = undefined;
			history.push(routes.ADMINNEWS.getPath());
		});
	};

	return (
		<>
			{adminNewsUiStore.current && (
				<Panel title={adminNewsUiStore.current.news.title} header={<> </>} onClose={onClose}>
					{adminNewsUiStore.current && (
						<>
							<AdminNewsEditForm news={adminNewsUiStore.current} />
						</>
					)}
				</Panel>
			)}
		</>
	);
});