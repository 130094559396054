import { AgenturModel } from 'app/models/agentur.model';
import { TableRow } from '../table/table.model';

export interface IAgtCell {
	agt?: AgenturModel;
}

export const AgenturNameCell = (row: TableRow<IAgtCell>) => {
	return (
		<>
			{row.data.agt && (
				<>
					{row.data.agt.name} ({row.data.agt.agtId}){' '}
				</>
			)}
		</>
	);
};
