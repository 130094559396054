import { observer } from 'mobx-react';
import { useStore } from 'app/context';
import { BerichtePanel } from '../berichte/BerichtePanel';
import React from 'react';
import { BerichteTreeMenu } from '../berichte/BerichteTreeMenu';

export const AgenturBerichte = observer(() => {
	const { agenturUiStore } = useStore();
	const agt = agenturUiStore.current;
	return (
		<>
			<BerichteTreeMenu />
			{agt && (
				<>
					<div>
						<BerichtePanel />
						<br />
					</div>
				</>
			)}
		</>
	);
});
