import { CustomPersonalModel } from 'app/models/personal/custom.personal.model';
import { BaseCachedEntityStore } from './core/base.cached.entity.store';

export class PersonalCustomStore extends BaseCachedEntityStore<CustomPersonalModel> {
	apiPath = 'api/mitarbeiter/custom';
	idProp: string = 'id';
	createInstances(opts: any): CustomPersonalModel {
		return new CustomPersonalModel(opts);
	}

	async findById(id: number | string) {
		const items = await this.findAll();
		return items.find((d) => d.id === id);
	}
}
