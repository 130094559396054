import { Einheit } from 'app/models/core/einheit.model';
import { BnrGpPosModel } from '../bnr.gp.pos.model';
import { PosUnDef } from '../gp';
const posDef: BnrGpPosModel[] = [];

// posDef.push(
// 	new BnrGpPosModel({
// 		year: 2023,
// 		posId: 2320001,
// 		group: 'gwp',
// 		posText: 'Gesetzliche Weiterbildungspflicht',
// 		planKey: 'idd',
// 		einheit: Einheit.bew,
// 		posDesc: `Voraussetzung für die Bonifikation ist der Nachweis von 15 Weiterbildungsstunden im Geschäftsjahr`,
// 	}),
// );

posDef.push(
	new BnrGpPosModel({
		year: 2024,
		posId: PosUnDef,
		group: 'ausMaxi',
		posText: 'Ausbau der vertrieblichen Nutzung Sozialer Medien',
		planKey: 'ausbau_social',
		einheit: Einheit.quali,
		posDesc: `Vertriebliche Nutzung von Social Media:
			u.a. Facebook & Instagram (rechtl. Grundlagen, Fan-Generierung, 
			Reaktionsmuster, Werbe- anzeigenerstellung, Verknüpfung mit Vertreter-HP
			und Geschäftspartnern), LinkedIn und Xing.`,
	}),
);
posDef.push(
	new BnrGpPosModel({
		year: 2024,
		posId: PosUnDef,
		group: 'ausMaxi',
		posText: 'Systematische Vertriebsunterstützung I',
		planKey: 'vertriebsunterstz_i',
		einheit: Einheit.quali,
		posDesc: `Aktive Unterstützung der ABV Hauptverwaltung bei strategischen Schwerpunkten zur systematischen Vertriebssteuerung in den Agenturen`,
	}),
);
posDef.push(
	new BnrGpPosModel({
		year: 2024,
		posId: PosUnDef,
		group: 'ausMaxi',
		posText: 'Kundenansprache und Agenturprozesse',
		planKey: 'agtproz',
		einheit: Einheit.quali,
		posDesc: `Kundenansprache und Agenturprozesse
		Multiplikator:in und Ansprechpartner:in für Führungs- kräfte für den strategischen Einsatz von Kunden- kontaktmanagement und Agenturprozesse unter Berücksichtigung aller digitalen und analogen Ansprachewege und Agenturabläufe.
		Verantwortung für die Nutzung und Etablierung von Ausbaustufen der Anwendung AKUMA bei Anwender:innen durch gezielte Einsteuerung von Neuerungen und Best-Practices. Laufendes Feedback an die betreuenden Fachbereiche.`,
	}),
);
posDef.push(
	new BnrGpPosModel({
		year: 2024,
		posId: PosUnDef,
		group: 'ausMaxi',
		posText: 'Mitarbeiterentwicklung',
		planKey: 'mitarbeiterentwicklung',
		einheit: Einheit.quali,
		posDesc: `Fachliche und persönliche Weiterentwicklung der im Verantwortungsbereich
				zugeordneten Mitarbeiter:innen`,
	}),
);

posDef.push(
	new BnrGpPosModel({
		year: 2024,
		posId: PosUnDef,
		group: 'max10k',
		posText: 'Systematische Vertriebsunterstützung II',
		planKey: 'vertriebsunterstz_ii',
		einheit: Einheit.quali,
		posDesc: `Multiplikator:in und erster Ansprechpartner:in der Geschäftsstellen für die DVS. Promotor für
		die nachhaltige Implementierung der DVS als strategisches Instrument zur Zielplanung in den
		Geschäftsstellen`,
	}),
);
posDef.push(
	new BnrGpPosModel({
		year: 2024,
		posId: 2320105,
		group: 'max10k',
		posText: 'Neue Digi-ID im Bestand (E-Mailadressen und Mobilnummern)',
		planKey: 'digiid_bestand',
		einheit: Einheit.proz,
		posDesc: `Umwandlung Kund:innen ohne Digi ID in Kund:innen mit Digi ID im Bestand. Basis Jahresendbestand Vorjahr`,
	}),
);
posDef.push(
	new BnrGpPosModel({
		year: 2024,
		posId: 2320110,
		group: 'max10k',
		posText: 'Neuanmeldungen Meine Allianz',
		planKey: 'neunanmeldungen_maz',
		einheit: Einheit.proz,
		posDesc: `Steigerung der Durchdringung von Kund:innen mit Meine Allianz (mAZ) im Bestand.`,
	}),
);

posDef.push(
	new BnrGpPosModel({
		year: 2024,
		posId: 2320110,
		group: 'max10k',
		posText: 'Unterstützung in der Umstellung auf AMIS.NOW',
		planKey: 'amisnow',
		einheit: Einheit.quali,
		posDesc: `Multiplikator:in und Ansprechpartner:in für Führungskräfte über die strategische und fachliche Ausrichtung des Vertriebsarbeitsplatzes. Verantwortung für die Etablierung von Kommunikations- und Schulungskonzepten
		in enger Zusammenarbeit mit den zentralen Projekt- teams von AMIS.NOW. Einsteuerung und Nachhalte entwickelter und abgestimmter Maßnahmen.`,
	}),
);

posDef.push(
	new BnrGpPosModel({
		year: 2024,
		posId: 2320125,
		group: 'max10k',
		posText: 'Steigerung der Cross-Selling-Quote und Kundenbindung',
		planKey: 'crossselling',
		einheit: Einheit.proz,
		posDesc: `Anzahl GV/HV mit wiederkehrender AKUMA Nutzung in der VD plus Anzahl GV/HV an AKUMA.Prime (Wiederkehrende AKUMA Planer Nutzung von mindestens 6 Wochen im Jahr 2024)
						Stichtag der Bemessungsgrundlage für das Jahresziel ist der GV/HV Bestand zum 01.01.2024`,
	}),
);
posDef.push(
	new BnrGpPosModel({
		year: 2024,
		posId: 2320127,
		group: 'max10k',
		posText: 'davon Akuma Prime',
		planKey: 'crossselling_akumaprime',
		einheit: Einheit.proz,
	}),
);
posDef.push(
	new BnrGpPosModel({
		year: 2024,
		posId: 2320126,
		group: 'max10k',
		posText: 'davon Akuma Planer',
		planKey: 'crossselling_akumaplaner',
		einheit: Einheit.proz,
	}),
);

posDef.push(
	new BnrGpPosModel({
		year: 2024,
		posId: PosUnDef,
		group: 'max10k',
		posText: 'Hardwarebestellung im Verantwortungsbereich inkl. Neueinstellungen',
		planKey: 'steigerung_kundenvertrauen_i',
		einheit: Einheit.quali,
		posDesc: `Hardwarebestellung im Verantwortungsbereich inkl. Neueinstellungen. 
		Maßnahmen entwickeln, um ein unter wirtschaft- lichen Gesichtspunkten nachhaltiges Bestell- verhalten zu fördern und zu controllen. Berücksichtigung von Handlungsrichtlinien und Leitfäden für regelmäßig anfallende Bestellvorgänge Empfehlen von zukunftsorientierten, technischen Verkaufsmöglichkeiten für den Vertriebsweg.`,
	}),
);

posDef.push(
	new BnrGpPosModel({
		year: 2024,
		posId: PosUnDef,
		group: 'max10k',
		posText: 'Forcierung der Themenübergabe an die web-DSE',
		planKey: 'forcethemen',
		einheit: Einheit.quali,
		posDesc: `Bekanntmachung und Multiplikation der Themen der web-DSE durch die EDP zur stärkeren Frequentierung durch die Agenturen`,
	}),
);

posDef.push(
	new BnrGpPosModel({
		year: 2024,
		posId: 2320140,
		boniStufen: 2,
		group: 'max10k',
		posText: 'Verantwortung von Schulungsmaßnahmen',
		planKey: 'agtproz_ii',
		einheit: Einheit.proz,
		posDesc: `
		Anzahl qualifizierter Schulungen durch EDT und EDP im Verantwortungsbereich unter Berücksichtigung des Stellenvolumens anhand der Planstellen für
		EDT und EDP
		Bemessungsgrundlage ist die festgelegte Anzahl an Planstellen zum 01.01.2024 je VD.`,
	}),
);

export const LdpPositionen = posDef;
