import React from 'react';
import { IWidget, WidgetCol, WidgetScope } from './widget.setting.model';
import { BnrBranchenplanungSummaryWidget, TeamBranchenplanungSummaryWidget } from 'app/components/anwendungen/branchenplanung/BnrBranchenplanungSummary';
import { DigiFaktorChartWidget } from 'app/components/berichte/digifaktor/DigifaktorChart';
import { ProdZwischenstandWidget } from 'app/components/berichte/prod/ProdZwischenstand';
import { GeburtstageWidget } from 'app/components/shared/GeburtstageWidget';
import { JubelWidget } from 'app/components/shared/JubelWidget';
import { AgenturProvModelWidget } from 'app/components/agt/overview/AgenturProvModelWidget';
import { AgenturKontaktDatenWidget } from 'app/components/agt/overview/AgenturKontaktWidget';
import { AgenturExpertisenWidget } from 'app/components/agt/overview/AgenturExpertisenWidget';
import { AgenturPersonalWidget } from 'app/components/agt/overview/AgenturPersonalWidget';
import { AgenturBetreuerWidget } from 'app/components/agt/overview/AgenturBetreuerWidget';
import { AgenturBewertungsverlaufWidget } from 'app/components/agt/overview/AgenturBewertungsverlaufWidget';
import { AgenturEhGesamtbewertungWidget } from 'app/components/agt/overview/AgenturEhGesamtbewertungWidget';
import { AgenturEhSachbewertungWidget } from 'app/components/agt/overview/AgenturEhSachbewertungWidget';
import { AgenturBestandWidget } from 'app/components/agt/overview/AgenturBestandWidget';
import { BnrZeWidget, EmptyBnrWidget, TeamZeWidget } from 'app/components/berichte/prod/BnrZeWidget';
import { VasZeWidgets } from '../gp/zewidgets/vas.ze.widgets';
import { LeiterSpeziSteartIds, MultiSteartIds, SpeziSteartIds, Steart } from '../core/steart.model';
import { VblZeWidgets, ZeWidgetDef } from '../gp/zewidgets/vbl.ze.widgets';
import { BbsZeWidgets } from '../gp/zewidgets/bbs.ze.widget';
import { KmeZeWidgets } from '../gp/zewidgets/kme.ze.widgets';
import { KvsZeWidgets } from '../gp/zewidgets/kvs.ze.widgets';
import { LpvZeWidgets } from '../gp/zewidgets/lpv.ze.widgets';
import { LsvZeWidgets } from '../gp/zewidgets/lsv.ze.widgets';
import { LDPZeWidgets, EDPZeWidgets, EDTZeWidgets } from '../gp/zewidgets/multis.ze.widget';

import { TasksStatsHomeWidget } from 'app/components/tasks/TasksStatsHomeWidget';
import { NotizenHomeWidget } from 'app/components/documents/NotizenHomeWidget';
import { SharedDocumentsHomeWidget } from 'app/components/documents/SharedDocumentsHomeWidget';
import { AgenturLast5DocsWidget } from 'app/components/agt/overview/AgenturLast5DocsWidget';
import { AgenturCurrentTasksWidget } from 'app/components/agt/overview/AgenturCurrentTasksWidget';
import { AgenturErrungenschaftenWidget } from 'app/components/agt/overview/AgenturErrungenschaftenWidget';
import { AgtSchwerpunktWidget } from 'app/components/agt/planung/AgtSchwerpunktWidget';
import { AgtBranchenPlanungWidget } from 'app/components/agt/planung/AgtBranchenPlanungWidget';
import { AgtClusterWidget } from 'app/components/agt/planung/AgtClusterWidget';
import { AgtPlanungsZieleWidget } from 'app/components/agt/planung/AgtPlanungsZieleWidget';
import { AgtPlanungsThemenWidget } from 'app/components/agt/planung/AgtPlanungsThemenWidget';
import { AgtPlanungDocs } from 'app/components/agt/planung/AgtPlanungsDocs';
import { BnrErrungenschaftenWidget } from 'app/components/errungenschaften/BnrErrungenschaftenWidget';
import { GPMAXYEAR } from 'app/stores/ui/berichte.ui.store';
import { TeamMemberWidget } from 'app/components/team/TeamMemberWidget';
import { TeamLast5DocsWidget } from 'app/components/team/TeamLast5DocsWidget';
import { GslZeWidgets } from '../gp/zewidgets/gsl.ze.widgets';
import { AgenturModel } from '../agentur.model';
import { AgenturTempInfoWidget } from 'app/components/agt/overview/AgenturTempInfoWidget';
import { BsgZeWidgets } from '../gp/zewidgets/bsg.ze.widgets';
import { AdaOverviewWidget } from 'app/components/agt/overview/AdaOverviewWidget';
import { AgenturThemenWidget } from 'app/components/agt/overview/AgenturThemenWidget';
import { NewsWidget } from 'app/components/news/NewsWidget';
import { MultisFeedbackWidget } from 'app/components/MultisFeedbackWidget';

const allExceptMultis = [Steart.VBL, ...SpeziSteartIds, ...LeiterSpeziSteartIds];

const BnrZeWidgetsToContainerWidgets = (items: ZeWidgetDef[]): IWidget[] => {
	return items.map((item, i) => {
		item.rptPath = `/berichte/production/gp/${GPMAXYEAR}/overview`;
		if (item.key === 'Breitenmass') {
			item.rptPath2 = `/berichte/production/gp/${GPMAXYEAR}/prod-zwischenstand`;
		}
		// if (item.key.indexOf('Bestandszuwachs') >= 0) {
		// 	item.rptPath2 = `/berichte/bestand/bestandundbewertung`;
		// }

		let component = <BnrZeWidget key={item.title} def={item} widgetKey={'Home' + item.key} year={GPMAXYEAR} />;

		if (item.key === 'xxx') {
			component = <EmptyBnrWidget key={item.title} def={item} widgetKey={'Home' + item.key} year={GPMAXYEAR} />;
		}

		return {
			scope: WidgetScope.home,
			widgetKey: 'Home' + item.key,
			enabled: true,
			opts: {
				sort: 2 + i,
				col: WidgetCol.right,
			},
			stearts: item.stearts,
			component,
		};
	});
};

const TeamZeWidgetsToContainerWidgets = (items: ZeWidgetDef[]): IWidget[] => {
	return items.map((item, i) => {
		item.rptPath = `/berichte/production/gp/${GPMAXYEAR}/overview`;
		if (item.key === 'Breitenmass') {
			item.rptPath2 = `/berichte/production/gp/${GPMAXYEAR}/prod-zwischenstand`;
		}
		// if (item.key.indexOf('Bestandszuwachs') >= 0) {
		// 	item.rptPath2 = `/berichte/bestand/bestandundbewertung`;
		// }
		if (item.items.length > 1) {
			item.rptPath = undefined;
		}
		return {
			scope: WidgetScope.team,
			widgetKey: 'Team' + item.key + item.stearts,
			enabled: true,
			opts: {
				sort: 2 + i,
				col: WidgetCol.right,
			},
			stearts: item.stearts,
			component: <TeamZeWidget key={item.title} def={item} widgetKey={'Team' + item.key + item.stearts} year={GPMAXYEAR} />,
		};
	});
};

const hideForTempAgt = (agt: AgenturModel) => {
	return agt.shouldBeTemp;
};

let widgetItems: IWidget[] = [
	/////////////////////////////////////////////////////
	/// HOME LEFT

	// Multis feedback widget
	{
		scope: WidgetScope.home,
		widgetKey: 'MultisFeedback',
		enabled: true,
		opts: {
			sort: 0,
			col: WidgetCol.left,
		},
		component: <MultisFeedbackWidget widgetKey={'MultisFeedback'} />,
		stearts: MultiSteartIds,
	},
	{
		scope: WidgetScope.home,
		widgetKey: 'HomeNews',
		enabled: true,
		opts: {
			sort: 0,
			col: WidgetCol.left,
		},
		component: <NewsWidget widgetKey={'HomeNews'} />,
	},
	{
		scope: WidgetScope.home,
		widgetKey: 'HomeTaskStats',
		enabled: true,
		opts: {
			sort: 1,
			col: WidgetCol.left,
		},
		component: <TasksStatsHomeWidget widgetKey={'HomeTaskStats'} />,
	},
	{
		scope: WidgetScope.home,
		widgetKey: 'HomeBpSummary',
		enabled: true,
		opts: {
			sort: 2,
			col: WidgetCol.left,
		},
		component: <BnrBranchenplanungSummaryWidget widgetKey={'HomeBpSummary'} />,
		stearts: [Steart.VBL],
	},
	{
		scope: WidgetScope.home,
		widgetKey: 'HomeNotizen',
		enabled: true,
		opts: {
			sort: 3,
			col: WidgetCol.left,
		},
		component: <NotizenHomeWidget widgetKey={'HomeNotizen'} />,
	},
	{
		scope: WidgetScope.home,
		widgetKey: 'HomeSharedDocuments',
		enabled: true,
		opts: {
			sort: 4,
			col: WidgetCol.left,
		},
		component: <SharedDocumentsHomeWidget widgetKey={'HomeSharedDocuments'} />,
	},
	/////////////////////////////////////////////////////
	/// HOME RIGHT

	{
		scope: WidgetScope.home,
		widgetKey: 'HomeBnrErrungenschaften',
		enabled: true,
		opts: {
			sort: 1,
			col: WidgetCol.right,
		},
		component: <BnrErrungenschaftenWidget widgetKey={'HomeBnrErrungenschaften'} />,
		stearts: [Steart.VBL],
	},
	{
		scope: WidgetScope.home,
		widgetKey: 'HomeProdZwischendstand',
		enabled: true,
		opts: {
			sort: 2,
			col: WidgetCol.right,
		},
		component: <ProdZwischenstandWidget widgetKey={'HomeProdZwischendstand'} />,
		stearts: [Steart.VBL, Steart.GSL],
	},

	/// ZE WIDGETS go here

	{
		scope: WidgetScope.home,
		widgetKey: 'HomeDigiFaktor',
		enabled: true,
		opts: {
			sort: 10,
			col: WidgetCol.right,
		},
		component: <DigiFaktorChartWidget widgetKey={'HomeDigiFaktor'} />,
		stearts: [Steart.VBL],
	},

	{
		scope: WidgetScope.home,
		widgetKey: 'HomeGeburtstage',
		enabled: true,
		opts: {
			sort: 11,
			col: WidgetCol.right,
		},
		component: <GeburtstageWidget widgetKey={'HomeGeburtstage'} />,
		stearts: [Steart.VBL, Steart.LPV, Steart.LSV],
	},

	{
		scope: WidgetScope.home,
		widgetKey: 'HomeJubel',
		enabled: true,
		opts: {
			sort: 12,
			col: WidgetCol.right,
		},
		component: <JubelWidget widgetKey={'HomeJubel'} />,
		stearts: [Steart.VBL, Steart.LPV, Steart.LSV],
	},
	/////////////////////////////////////////////////////
	/// Agentur Overiew LEFT
	{
		scope: WidgetScope.agt,
		widgetKey: 'AgtTempAgtInfo',
		enabled: true,
		opts: {
			sort: 1,
			col: WidgetCol.left,
		},
		component: <AgenturTempInfoWidget widgetKey={'AgtTempInfo'} />,
		hideFor: (agt: AgenturModel) => {
			return !agt.shouldBeTemp;
		},
	},
	{
		scope: WidgetScope.agt,
		widgetKey: 'AgtProvModel',
		enabled: true,
		opts: {
			sort: 1,
			col: WidgetCol.left,
		},
		component: <AgenturProvModelWidget widgetKey={'AgtProvModel'} />,
	},
	{
		scope: WidgetScope.agt,
		widgetKey: 'AgtKontaktDaten',
		enabled: true,
		opts: {
			sort: 2,
			col: WidgetCol.left,
		},
		component: <AgenturKontaktDatenWidget widgetKey={'AgtKontaktDaten'} />,
	},
	{
		scope: WidgetScope.agt,
		widgetKey: 'AgtExpertisen',
		enabled: true,
		opts: {
			sort: 3,
			col: WidgetCol.left,
		},
		component: <AgenturExpertisenWidget widgetKey={'AgtExpertisen'} />,
		hideFor: hideForTempAgt,
	},
	{
		scope: WidgetScope.agt,
		widgetKey: 'AgtPersonal',
		enabled: true,
		opts: {
			sort: 4,
			col: WidgetCol.left,
		},
		component: <AgenturPersonalWidget widgetKey={'AgtPersonal'} />,
		hideFor: hideForTempAgt,
	},
	{
		scope: WidgetScope.agt,
		widgetKey: 'AgtBetreuer',
		enabled: true,
		opts: {
			sort: 5,
			col: WidgetCol.left,
		},
		component: <AgenturBetreuerWidget widgetKey={'AgtBetreuer'} />,
	},
	{
		scope: WidgetScope.agt,
		widgetKey: 'AgtBewertungsverlauf',
		enabled: true,
		opts: {
			sort: 6,
			col: WidgetCol.left,
		},
		component: <AgenturBewertungsverlaufWidget widgetKey={'AgtBewertungsverlauf'} />,
		stearts: allExceptMultis,
		hideFor: hideForTempAgt,
	},
	/////////////////////////////////////////////////////
	/// Agentur Overiew RIGHT
	{
		scope: WidgetScope.agt,
		widgetKey: 'AgtGesamtbewertung',
		enabled: true,
		opts: {
			sort: 1,
			col: WidgetCol.right,
		},
		component: <AgenturEhGesamtbewertungWidget widgetKey={'AgtGesamtbewertung'} />,
		stearts: allExceptMultis,
		hideFor: hideForTempAgt,
	},
	{
		scope: WidgetScope.agt,
		widgetKey: 'AgtEhSachbewertung',
		enabled: true,
		opts: {
			sort: 2,
			col: WidgetCol.right,
		},
		component: <AgenturEhSachbewertungWidget widgetKey={'AgtEhSachbewertung'} />,
		stearts: allExceptMultis,
		hideFor: hideForTempAgt,
	},

	{
		scope: WidgetScope.agt,
		widgetKey: 'AgtErrungenschaften',
		enabled: true,
		opts: {
			sort: 3,
			col: WidgetCol.right,
		},
		component: <AgenturErrungenschaftenWidget widgetKey={'AgtErrungenschaften'} />,
		// stearts: ,
		hideFor: hideForTempAgt,
	},

	{
		scope: WidgetScope.agt,
		widgetKey: 'AgtBestand',
		enabled: true,
		opts: {
			sort: 4,
			col: WidgetCol.right,
		},
		component: <AgenturBestandWidget widgetKey={'AgtBestand'} />,
		stearts: allExceptMultis,
		hideFor: hideForTempAgt,
	},
	{
		scope: WidgetScope.agt,
		widgetKey: 'AgtGeburstage',
		enabled: true,
		opts: {
			sort: 5,
			col: WidgetCol.right,
		},
		component: <GeburtstageWidget widgetKey={'AgtGeburstage'} />,
		stearts: allExceptMultis,
		hideFor: hideForTempAgt,
	},
	{
		scope: WidgetScope.agt,
		widgetKey: 'AgtJubel',
		enabled: true,
		opts: {
			sort: 6,
			col: WidgetCol.right,
		},
		component: <JubelWidget widgetKey={'AgtJubel'} />,
		stearts: allExceptMultis,
		hideFor: hideForTempAgt,
	},
	{
		scope: WidgetScope.agt,
		widgetKey: 'AgtThemenOverview',
		enabled: true,
		opts: {
			sort: 1,
			col: WidgetCol.right,
		},
		component: <AgenturThemenWidget widgetKey={'AgtThemenOverview'} />,
		stearts: MultiSteartIds,
		hideFor: hideForTempAgt,
	},
	{
		scope: WidgetScope.agt,
		widgetKey: 'AgtLast5Docs',
		enabled: true,
		opts: {
			sort: 7,
			col: WidgetCol.right,
		},
		component: <AgenturLast5DocsWidget widgetKey={'AgtLast5Docs'} />,
	},
	{
		scope: WidgetScope.agt,
		widgetKey: 'AgtCurrentTasks',
		enabled: true,
		opts: {
			sort: 8,
			col: WidgetCol.right,
		},
		component: <AgenturCurrentTasksWidget widgetKey={'AgtCurrentTasks'} />,
		stearts: allExceptMultis,
	},
	/////////////////////////////////////////////////////
	/// Agentur Planung Left
	{
		scope: WidgetScope.agtplanung,
		widgetKey: 'AgtPlanungDocs',
		enabled: true,
		opts: {
			sort: 1,
			col: WidgetCol.left,
		},
		component: <AgtPlanungDocs />,
	},

	/////////////////////////////////////////////////////
	/// Agentur Planung RIGHT
	{
		scope: WidgetScope.agtplanung,
		widgetKey: 'AgtClusterWidget',
		enabled: true,
		opts: {
			sort: 2,
			col: WidgetCol.right,
		},
		component: <AgtClusterWidget widgetKey={'AgtClusterWidget'} />,
	},
	{
		scope: WidgetScope.agtplanung,
		widgetKey: 'AgtPlanSchwerpunkt',
		enabled: true,
		opts: {
			sort: 1,
			col: WidgetCol.right,
		},
		component: <AgtSchwerpunktWidget widgetKey={'AgtPlanSchwerpunkt'} />,
	},
	{
		scope: WidgetScope.agtplanung,
		widgetKey: 'AgtPlanBranchenplanung',
		enabled: true,
		opts: {
			sort: 2,
			col: WidgetCol.right,
		},
		component: <AgtBranchenPlanungWidget widgetKey={'AgtPlanBranchenplanung'} />,
		stearts: allExceptMultis,
	},
	{
		scope: WidgetScope.agtplanung,
		widgetKey: 'AgtPlanZiele',
		enabled: true,
		opts: {
			sort: 3,
			col: WidgetCol.right,
		},
		component: <AgtPlanungsZieleWidget widgetKey={'AgtPlanZiele'} />,
		stearts: allExceptMultis,
	},
	{
		scope: WidgetScope.agtplanung,
		widgetKey: 'AgtPlanThemen',
		enabled: true,
		opts: {
			sort: 4,
			col: WidgetCol.right,
		},
		component: <AgtPlanungsThemenWidget widgetKey={'AgtPlanThemen'} />,
		stearts: [Steart.VBL],
	},
	{
		scope: WidgetScope.agtplanung,
		widgetKey: 'AgtThemenOverview',
		enabled: true,
		opts: {
			sort: 4,
			col: WidgetCol.right,
		},
		component: <AgenturThemenWidget widgetKey={'AgtThemenOverview'} />,
		stearts: MultiSteartIds,
	},

	/////////////////////////////////////////////////////
	/// TEAM Overview LEFT
	{
		scope: WidgetScope.team,
		widgetKey: 'TeamDetail',
		enabled: true,
		opts: {
			sort: 1,
			col: WidgetCol.left,
		},
		component: <TeamMemberWidget widgetKey={'TeamDetail'} />,
	},
	{
		scope: WidgetScope.team,
		widgetKey: 'TeamBranchenPlanung',
		enabled: true,
		opts: {
			sort: 1,
			col: WidgetCol.left,
		},
		component: <TeamBranchenplanungSummaryWidget widgetKey={'TeamBranchenPlanung'} />,
	},
	{
		scope: WidgetScope.team,
		widgetKey: 'TeamLast5Docs',
		enabled: true,
		opts: {
			sort: 1,
			col: WidgetCol.right,
		},
		component: <TeamLast5DocsWidget widgetKey={'TeamLast5Docs'} />,
	},
	// {
	// 	scope: WidgetScope.team,
	// 	widgetKey: 'TeamEH',
	// 	enabled: true,
	// 	opts: {
	// 		sort: 1,
	// 		col: WidgetCol.right,
	// 	},
	// 	component: <TeamEHWidget widgetKey={'TeamEH'} />,
	// },

	/////////////////////////////////////////////////////
	/// ADA Overview LEFT
	{
		scope: WidgetScope.ada,
		widgetKey: 'AdaOverview',
		enabled: true,
		opts: {
			sort: 1,
			col: WidgetCol.left,
		},
		component: <AdaOverviewWidget widgetKey={'AdaOverview'} />,
	},
	{
		scope: WidgetScope.ada,
		widgetKey: 'AgtKontaktDaten',
		enabled: true,
		opts: {
			sort: 2,
			col: WidgetCol.left,
		},
		component: <AgenturKontaktDatenWidget widgetKey={'AgtKontaktDaten'} />,
	},

	/////////////////////////////////////////////////////
	/// ADA Overview RIGHT
	{
		scope: WidgetScope.ada,
		widgetKey: 'AgtBetreuer',
		enabled: true,
		opts: {
			sort: 5,
			col: WidgetCol.right,
		},
		component: <AgenturBetreuerWidget widgetKey={'AgtBetreuer'} />,
	},
];

widgetItems = widgetItems.concat(BnrZeWidgetsToContainerWidgets(GslZeWidgets));
widgetItems = widgetItems.concat(BnrZeWidgetsToContainerWidgets(VblZeWidgets));
widgetItems = widgetItems.concat(BnrZeWidgetsToContainerWidgets(BbsZeWidgets));
widgetItems = widgetItems.concat(BnrZeWidgetsToContainerWidgets(LsvZeWidgets));
widgetItems = widgetItems.concat(BnrZeWidgetsToContainerWidgets(LpvZeWidgets));
widgetItems = widgetItems.concat(BnrZeWidgetsToContainerWidgets(KmeZeWidgets));
widgetItems = widgetItems.concat(BnrZeWidgetsToContainerWidgets(KvsZeWidgets));
widgetItems = widgetItems.concat(BnrZeWidgetsToContainerWidgets(VasZeWidgets));
widgetItems = widgetItems.concat(BnrZeWidgetsToContainerWidgets(BsgZeWidgets));
widgetItems = widgetItems.concat(BnrZeWidgetsToContainerWidgets(LDPZeWidgets));
widgetItems = widgetItems.concat(BnrZeWidgetsToContainerWidgets(EDTZeWidgets));
widgetItems = widgetItems.concat(BnrZeWidgetsToContainerWidgets(EDPZeWidgets));

widgetItems = widgetItems.concat(TeamZeWidgetsToContainerWidgets(VblZeWidgets));
widgetItems = widgetItems.concat(TeamZeWidgetsToContainerWidgets(BbsZeWidgets));
widgetItems = widgetItems.concat(TeamZeWidgetsToContainerWidgets(LsvZeWidgets));
widgetItems = widgetItems.concat(TeamZeWidgetsToContainerWidgets(LpvZeWidgets));
widgetItems = widgetItems.concat(TeamZeWidgetsToContainerWidgets(KmeZeWidgets));
widgetItems = widgetItems.concat(TeamZeWidgetsToContainerWidgets(KvsZeWidgets));
widgetItems = widgetItems.concat(TeamZeWidgetsToContainerWidgets(VasZeWidgets));
widgetItems = widgetItems.concat(TeamZeWidgetsToContainerWidgets(BsgZeWidgets));
widgetItems = widgetItems.concat(TeamZeWidgetsToContainerWidgets(LDPZeWidgets));
widgetItems = widgetItems.concat(TeamZeWidgetsToContainerWidgets(EDTZeWidgets));
widgetItems = widgetItems.concat(TeamZeWidgetsToContainerWidgets(EDPZeWidgets));

export const WidgetItems = widgetItems;
