export enum GP {
	Variante53_54 = 1115,
	Variante40 = 1114,
	Basisvariante = 1110,
	Variante70 = 1117,
	Variante90 = 1119,
	Pro3 = 1130,
	Variante60 = 1116,
	Variante10 = 1111,
	Variante30 = 1113,
	SV = 1190,
	Undef = 0,
}

export const allGps = [GP.Basisvariante, GP.Variante10, GP.Variante30, GP.Variante40, GP.Variante53_54, GP.Variante60, GP.Variante70, GP.Variante90, GP.Pro3, GP.SV];
export const pro3GP = [GP.Pro3];
export const allExceptPro3andSV = allGps.filter((gp) => gp !== GP.Pro3 && gp !== GP.SV);
export const allExceptSV = allGps.filter((gp) => gp !== GP.SV);
export const onlyBasis = [GP.Basisvariante];
export const onlyPro3 = [GP.Pro3];
export const onlyPro3AndSV = [GP.Pro3, GP.SV];
export const allExceptBasis = allGps.filter((gp) => gp !== GP.Basisvariante);
export const none = [];

export const allExcept = (gps: GP[]) => {
	return allGps.filter((gp) => !gps.includes(gp));
};

export const PosUnDef = -3000;

let pscnt = PosUnDef;
export const PosTodo = () => {
	return pscnt--;
}
