import { KvBnrModel } from 'app/models/kv.bnr.model';
import { computed } from 'mobx';
import { BaseCachedEntityStore } from './core/base.cached.entity.store';

export class BpEigenesZielModel extends KvBnrModel {
	@computed
	get year() {
		return parseInt(this.key2);
	}

	@computed
	get planKey() {
		return this.key;
	}
}

export class KvBnrBpEigenesZielStore extends BaseCachedEntityStore<BpEigenesZielModel> {
	idProp: string = 'id';
	apiPath = 'api/kv_bnr/bp_meinziel';
	disableFindForStearts = [123];

	createInstances(opts: any): BpEigenesZielModel {
		return new BpEigenesZielModel(opts);
	}

	async findByYearAndPlanyKey(year: number, prop: string) {
		const items = await this.findAll();
		return items.filter((x) => x.year === year && x.planKey === prop);
	}

	async findByYear(year: number) {
		const items = await this.findAll();
		return items.filter((x) => x.year === year);
	}
}
