import { useFormikContext } from 'formik';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { IRadioButtonListItem, RadioButtonList } from '../common/RadioButtonList';

interface IFieldRow {
	name: string;
}

export const TaskStatusField = observer((props: IFieldRow) => {
	const [val, setVal] = useState<string>('');
	const { getFieldMeta, setFieldValue } = useFormikContext();

	let buttons: IRadioButtonListItem[] = [
		{ label: 'Offen', value: '0' },
		{ label: 'Erledigt', value: '1' },
	];

	useEffect(() => {
		const v = getFieldMeta(props.name).value as number;
		setVal(v.toString());
	}, [setVal, getFieldMeta, props.name]);

	const onSelect = (val: any) => {
		setVal(val);
		setFieldValue(props.name, parseInt(val));
	};

	return (
		<>
			<RadioButtonList classModifier="is-vertical" buttons={buttons} value={val} onSelect={onSelect} />
		</>
	);
});
