import BaseContainer from 'app/containers/BaseContainer';
import { MainMenu } from './MainMenu';
import { RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import { ResetAllPasswords } from 'app/components/admin/users/AdminResetAllPasswords';

const AnwendungeNavBar = observer(() => {
	return <div>Admin Panik</div>;
});
const AdminPanikContainer = (props: RouteComponentProps) => {
	return (
		<BaseContainer sideMenu={<MainMenu {...props} />} navbar={<AnwendungeNavBar />}>
			<div className="pad-1rem">
				<ResetAllPasswords />
			</div>
		</BaseContainer>
	);
};
export default AdminPanikContainer;
