import { ButtonHTMLAttributes } from 'react';

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	isFormInvalid?: boolean;
	type?: 'button' | 'submit' | 'reset';
}
export const Button = (props: IButtonProps) => {
	const { isFormInvalid, ...rest } = props;

	return (
		<button {...rest} disabled={props.disabled || isFormInvalid} type={props.type || 'button'}>
			{/* {props.disabled && <div className="debug">disabled</div>}
			{isFormInvalid && <div className="debug">form invalid</div>} */}

			{props.children}
		</button>
	);
};
