import { Button } from 'app/components/common/Button';
import { useStore } from 'app/context';
import { AgenturModel } from 'app/models/agentur.model';
import { Field, Form, Formik } from 'formik';
import { observer } from 'mobx-react';
import { schwerpunktSchema } from './SchwerpunktList';
import { useEffect, useState } from 'react';
import { AgtSchwerpunktVM } from 'app/stores/ui/agt.schwerpunkt.ui.store';
import { runInAction } from 'mobx';

interface IAgtBranchenplanungEdit {
	agt: AgenturModel;
	year: number;
}

export const AgtSchwerpunktEdit = observer((props: IAgtBranchenplanungEdit) => {
	const { agtSchwerpunktUiStore, uiStore } = useStore();
	const [data, setData] = useState<AgtSchwerpunktVM>();
	const [roles, setRoles] = useState<string[]>([]);
	const [selectedRoles, setSelectedRoles] = useState<string[]>([]);

	useEffect(() => {
		setRoles(agtSchwerpunktUiStore.getRolesByYear(props.year));

		agtSchwerpunktUiStore.findByYearAndAgtId(props.year, props.agt.agtId).then((d) => {
			if (!d) {
				d = agtSchwerpunktUiStore.newAgtSchwerpunkt(props.agt, props.year);
			}
			setData(d);
			if (d) {
				setSelectedRoles(d.selectedRolesAsList);
			} else {
				setSelectedRoles([]);
			}
		});
		return () => uiStore.schwerpunktEdit.endEdit();
	}, [agtSchwerpunktUiStore, props.agt, props.year, props.agt.agtId, uiStore.schwerpunktEdit]);

	const handleSubmit = (values: AgtSchwerpunktVM) => {
		if (!data) {
			return;
		}
		runInAction(() => {
			Object.assign(data, values);
		});

		agtSchwerpunktUiStore.save(data).then(() => {
			runInAction(() => {
				setSelectedRoles(data.selectedRolesAsList);
			});
			uiStore.schwerpunktEdit.endEdit();
		});
	};

	return (
		<>
			{/* This line is needed to get the form to rerender on prop change */}
			{uiStore.schwerpunktEdit.enabled && <></>}
			{data && (
				<Formik validationSchema={schwerpunktSchema} initialValues={data} onSubmit={handleSubmit} validateOnMount={true} enableReinitialize={true}>
					{({ isValid, resetForm }) => {
						const onCancel = () => {
							resetForm();
							uiStore.schwerpunktEdit.endEdit();
						};

						return (
							<Form>
								{!uiStore.schwerpunktEdit.enabled &&
									selectedRoles.map((r) => (
										<span className={`tag color-${r}`} key={r}>
											{r}
										</span>
									))}
								{uiStore.schwerpunktEdit.enabled && (
									<>
										<div className="schwerpunkt-edit-list">
											{roles.map((role) => {
												const prop = 'schwerpunkt' + role;
												return (
													<div className="schwerpunkt-edit-item" key={prop}>
														<Field type="checkbox" name={prop} />
														<span>{role}</span>
													</div>
												);
											})}
										</div>
									</>
								)}
								<div className="has-text-right">
									{uiStore.schwerpunktEdit.enabled && (
										<>
											<Button type="submit" className="button is-primary " isFormInvalid={!isValid}>
												Speichern
											</Button>
											<Button type="button" className="button is-secondary " onClick={onCancel}>
												Abbrechen
											</Button>
										</>
									)}
								</div>
							</Form>
						);
					}}
				</Formik>
			)}
		</>
	);
});
