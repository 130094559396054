import { useEffect, useMemo } from 'react';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { Table } from './../../table/table';
import { ITableColumn, TableModel, TableRow } from './../../table/table.model';
import { runInAction } from 'mobx';
import { useHistory } from 'react-router';
import routes from 'routes';
import { formatDateLong } from 'app/utils';
import { Icon } from 'app/components/common/Icon';
import { TemplateVM } from 'app/stores/ui/template.ui.store';

export const AdminTemplatesList = observer(() => {
	const { adminTemplatesUiStore } = useStore();
	const history = useHistory();

	const tm = useMemo(() => {
		const viewDoc = (doc: TemplateVM) => {
			runInAction(() => {
				history.push(routes.ADMINTEMPLATES.getPath('edit', doc.template.id));
			});
		};

		const tm = new TableModel<TemplateVM, number>();
		let cols: ITableColumn<TemplateVM, number>[] = [
			{
				label: 'Datum',
				path: 'template.created',
				format: (val) => formatDateLong(val),
			},
			{
				label: 'Status',
				path: 'template.content.title',
			},
			{
				label: 'Für Rollen ',
				path: 'template.content.title',
				render: (row: TableRow<TemplateVM>) => {
					return (
						<>
							{row.data.activeStearts.map((s) => (
								<span key={s.steart.id}>{s.steart.name} </span>
							))}
						</>
					);
				},
			},
			{
				label: 'Sichtbar/Aktiv(todo)',
				path: 'template.content.title',
				render: (row: TableRow<TemplateVM>) => {
					return <>{row.data.template.content.visible && <Icon iconClass={'check'} />}</>;
				},
			},
		];

		tm.onRowClick = (row: TableRow<TemplateVM>) => {
			viewDoc(row.data);
		};

		tm.setCols(cols);
		tm.sortBy = 'job.created';
		tm.sortAsc = false;
		tm.idProperty = 'id';
		tm.idType = 'string';
		return tm;
	}, [history]);

	useEffect(() => {
		adminTemplatesUiStore.findAll();
		tm.setRowData(adminTemplatesUiStore.currentItems);
	}, [adminTemplatesUiStore, adminTemplatesUiStore.currentItems, tm]);

	return (
		<>
			<Table tm={tm}></Table>
		</>
	);
});
