import { Icon } from 'app/components/common/Icon';
import { observer } from 'mobx-react';

export const AkumaPlanerLegend = observer(() => {
	return (
		<div className="pad-1rem">
			<div className="note-box content">
				<p>
					<Icon iconClass="info-circle" />
				</p>
				<div className="note-message">
					<p>
						<strong>Erläuterung der Dargestellten Kennzahlen</strong>
					</p>

					<p>
						<strong>„Gesamt“:</strong> Anzahl der Kunden, die im AKUMA Planer zur Bearbeitung eingestellt wurden
					</p>
					<p>
						<strong>„Kundenzuweisung und Übertragung &rsaquo;= 50%“:</strong> Anzahl der Wochenscheiben, in denen mind. 50% der Kunden bearbeitet und an AMIS.NOW
						Aufgaben übertragen wurden
					</p>
					<p>
						<strong>„Nicht bearbeitet“:</strong> Anzahl der Kunden, die im AKUMA Planer nicht bearbeitet wurden
					</p>
					<p>
						<strong>„Keine Maßnahme“:</strong> Anzahl der Kunden, die auf „Keine Maßnahme“ gesetzt wurden
					</p>
					<p>
						<strong>„Kunden mit Zuweisung, nicht übertragen“:</strong> Anzahl der Kunden, die der Agentur oder einem Mitarbeiter zugeordnet, aber nicht an AMIS.NOW
						Aufgaben übertragen wurden
					</p>
					<p>
						<strong>„Kunden mit Zuweisung und Übertragung“:</strong> Anzahl der Kunden, die der Agentur oder einem Mitarbeiter zugeordnet und an AMIS.NOW Aufgaben
						übertragen wurden
					</p>
					<p>
						<strong>„Zuweisung Agentur“:</strong> Anzahl der Kunden, die der Agentur zugeordnet und an AMIS.NOW Aufgaben übertragen wurden
					</p>
					<p>
						<strong>„Zuweisung Mitarbeiter“:</strong> Anzahl der Kunden, die einem Mitarbeiter zugeordnet und an AMIS.NOW Aufgaben übertragen wurden
					</p>
				</div>
			</div>
		</div>
	);
});
