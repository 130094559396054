import { KvBnrModel } from 'app/models/kv.bnr.model';
import { action, computed, makeObservable, observable } from 'mobx';
import { BaseCachedEntityStore } from './core/base.cached.entity.store';

export interface IWidgetOpts {
	sort: number;
	col: number;
}

const pad = (number: number) => {
	let res = number.toString();
	if (number <= 99) {
		res = ('0' + number).slice(-4);
	}
	return res;
};
class WidgetOpts {
	constructor(opts: any) {
		makeObservable(this);
		if (opts.sort !== undefined) {
			this.sort = opts.sort;
		}
		if (opts.col !== undefined) {
			this.col = opts.col;
		}
		if (opts.open !== undefined) {
			this.open = opts.open;
		}
	}

	@observable sort: number = 11;
	@observable col: number = 0;
	@observable open: boolean = true;

	@computed
	get sortString() {
		return pad(this.col) + '_' + pad(this.sort);
	}
}

export class KvWidgetSettingModel extends KvBnrModel {
	constructor(opts: any) {
		super(opts);
		this.group = 'widget_settings';
		if (opts.valueString) {
			const x = JSON.parse(opts.valueString);
			this.opts = new WidgetOpts(x);
		} else {
			this.opts = new WidgetOpts({});
		}
	}
	@computed
	get scope() {
		// home / agt
		return this.key;
	}

	@computed
	get widgetKey() {
		return this.key2;
	}

	@computed
	get enabled() {
		return this.valueNum === 1;
	}

	opts: WidgetOpts;

	@action
	setEnabled(enable: boolean) {
		this.valueNum = enable ? 1 : 0;
	}
	@action
	setOpts(opts: WidgetOpts) {
		this.opts.col = opts.col;
		this.opts.sort = opts.sort;
		this.opts.open = opts.open;
	}

	serialize() {
		const json = JSON.stringify(this.opts);
		return {
			id: this.id,
			bnrId: this.bnrId,
			group: this.group,
			key: this.key,
			key2: this.key2,
			valueNum: this.valueNum,
			valueString: json,
		};
	}
}

export class KvBnrWidgetSettingsStore extends BaseCachedEntityStore<KvWidgetSettingModel> {
	idProp: string = 'id';
	apiPath = 'api/kv_bnr/widget_settings';

	createInstances(opts: any): KvWidgetSettingModel {
		return new KvWidgetSettingModel(opts);
	}
}
