import { Einheit } from 'app/models/core/einheit.model';
import { BnrGpPosModel } from '../bnr.gp.pos.model';
import { PosUnDef } from '../gp';
const posDef: BnrGpPosModel[] = [];

posDef.push(
	new BnrGpPosModel({
		year: 2023,
		posId: 2320001,
		group: 'gwp',
		posText: 'Gesetzliche Weiterbildungspflicht',
		planKey: 'idd',
		einheit: Einheit.bew,
		posDesc: `Voraussetzung für die Bonifikation ist der Nachweis von 15 Weiterbildungsstunden im Geschäftsjahr`,
	}),
);

posDef.push(
	new BnrGpPosModel({
		year: 2023,
		posId: PosUnDef,
		group: 'ausMaxi',
		posText: 'Ausbau der vertrieblichen Nutzung Sozialer Medien',
		planKey: 'ausbau_social',
		einheit: Einheit.quali,
		posDesc: `Vertriebliche Nutzung von Social Media:
			u.a. Facebook & Instagram (rechtl. Grundlagen, Fan-Generierung, 
			Reaktionsmuster, Werbe- anzeigenerstellung, Verknüpfung mit Vertreter-HP
			und Geschäftspartnern), LinkedIn und Xing.`,
	}),
);
posDef.push(
	new BnrGpPosModel({
		year: 2023,
		posId: PosUnDef,
		group: 'ausMaxi',
		posText: 'Systematische Vertriebsunterstützung I',
		planKey: 'vertriebsunterstz_i',
		einheit: Einheit.quali,
		posDesc: `Aktive Unterstützung der ABV Hauptverwaltung bei strategischen Schwerpunkten zur systematischen Vertriebssteuerung in den Agenturen`,
	}),
);
posDef.push(
	new BnrGpPosModel({
		year: 2023,
		posId: PosUnDef,
		group: 'ausMaxi',
		posText: 'Kundenansprache und Agenturprozesse I',
		planKey: 'agtproz_i',
		einheit: Einheit.quali,
		posDesc: `Multiplikator:in und Ansprechpartner:in für Führungs- kräfte für den strategischen Einsatz von Kunden- kontaktmanagement und Agenturprozesse unter Berücksichtigung aller digitalen und analogen Ansprachewege und Agenturabläufe.
		Verantwortung für die Nutzung und Etablierung von Ausbaustufen der Anwendung AKUMA bei Anwender:innen durch gezielte Einsteuerung von Neuerungen und Best-Practices. Laufendes Feedback an die betreuende Fachabteilung.`,
	}),
);
posDef.push(
	new BnrGpPosModel({
		year: 2023,
		posId: PosUnDef,
		group: 'ausMaxi',
		posText: 'Mitarbeiterentwicklung',
		planKey: 'mitarbeiterentwicklung',
		einheit: Einheit.quali,
		posDesc: `Fachliche und persönliche Weiterentwicklung der im Verantwortungsbereich
				zugeordneten Mitarbeiter:innen`,
	}),
);

posDef.push(
	new BnrGpPosModel({
		year: 2023,
		posId: PosUnDef,
		group: 'max10k',
		posText: 'Systematische Vertriebsunterstützung II',
		planKey: 'vertriebsunterstz_ii',
		einheit: Einheit.quali,
		posDesc: `Multiplikator:in und erster Ansprechpartner:in der Geschäftsstellen für die DVS. Promotor für
		die nachhaltige Implementierung der DVS als strategisches Instrument zur Zielplanung in den
		Geschäftsstellen`,
	}),
);
posDef.push(
	new BnrGpPosModel({
		year: 2023,
		posId: 2320105,
		group: 'max10k',
		posText: 'Neue Digi-ID im Bestand (E-Mailadressen und Mobilnummern)',
		planKey: 'digiid_bestand',
		einheit: Einheit.proz,
		posDesc: `Umwandlung Kund:innen ohne Digi ID in Kund:innen mit Digi ID im Bestand. Basis Jahresendbestand Vorjahr`,
	}),
);
posDef.push(
	new BnrGpPosModel({
		year: 2023,
		posId: 2320110,
		group: 'max10k',
		posText: 'Neuanmeldungen Meine Allianz',
		planKey: 'neunanmeldungen_maz',
		einheit: Einheit.proz,
		posDesc: `Anzahl Erfüller der Position "Meine Allianz" im Geschäftsplan der GV/HV`,
	}),
);

posDef.push(
	new BnrGpPosModel({
		year: 2023,
		posId: 2320115,
		group: 'max10k',
		posText: 'Kundenansprache und Agenturprozesse II',
		planKey: 'agtproz_ii',
		einheit: Einheit.proz,
		posDesc: 'Anteil teilnehmender Agenturen an Akuma Prime zur Gesamtanzahl aller Agenturen in der VD zum Stichtag 31.12.2023.',
	}),
);
posDef.push(
	new BnrGpPosModel({
		year: 2023,
		posId: PosUnDef,
		group: 'max10k',
		posText: 'Steigerung des Kundenvertrauens I: Kundenbewertungsinstrumente eKomi & Google',
		planKey: 'steigerung_kundenvertrauen_i',
		einheit: Einheit.quali,
		posDesc: `Kundenbewertungsinstrumente eKo & Google. 
			Maßnahmen entwickeln und umsetzen, die dazu führen, dass die Kundenbewertungsinstrumente zu einem regelmäßig genutzten After-Sales-Prozess werden.`,
	}),
);

posDef.push(
	new BnrGpPosModel({
		year: 2023,
		posId: 2320125,
		group: 'max10k',
		posText: 'Steigerung des Kundenvertrauens II: Einsatz "Allianz Empfehlen"',
		planKey: 'steigerung_kundenvertrauen_ii',
		einheit: Einheit.proz,
		posDesc: `Einsatz "Allianz Empfehlen". 
		Anzahl Leads aus dem Programm "Allianz Empfehlen" umgelegt auf alle Agenturen in der VD im Zeitraum 01.01.2023 bis 31.12.2023. (Stichtag Gesamtagenturanzahl 01.01.2023)`,
	}),
);

posDef.push(
	new BnrGpPosModel({
		year: 2023,
		posId: PosUnDef,
		group: 'max10k',
		posText: 'Hardwarebestellung im Verantwortungsbereich inkl. Neueinstellungen',
		planKey: 'steigerung_kundenvertrauen_i',
		einheit: Einheit.quali,
		posDesc: `Hardwarebestellung im Verantwortungsbereich inkl. Neueinstellungen. 
		Maßnahmen entwickeln, um ein unter wirtschaft- lichen Gesichtspunkten nachhaltiges Bestell- verhalten zu fördern und zu controllen. Berücksichtigung von Handlungsrichtlinien und Leitfäden für regelmäßig anfallende Bestellvorgänge Empfehlen von zukunftsorientierten, technischen Verkaufsmöglichkeiten für den Vertriebsweg.`,
	}),
);
posDef.push(
	new BnrGpPosModel({
		year: 2023,
		posId: PosUnDef,
		group: 'max10k',
		posText: 'Forcierung der Themenübergabe an die web-DSE',
		planKey: 'forcethemen',
		einheit: Einheit.quali,
		posDesc: `Bekanntmachung und Multiplikation der Themen der web-DSE durch die EDP zur stärkeren Frequentierung durch die Agenturen`,
	}),
);

posDef.push(
	new BnrGpPosModel({
		year: 2023,
		posId: 2320140,
		group: 'max10k',
		posText: 'Agenturprozesse und Kundenansprache III',
		planKey: 'agtproz_kundenansprache_iii',
		einheit: Einheit.proz,
		posDesc: `Anzahl der Agenturen in der VD mit abgeschlos- senem "Meine Agentur der Zukunft" Coaching- prozess im Zeitraum 01.01.2023 bis 31.12.2023`,
	}),
);

export const LdpPositionen = posDef;
