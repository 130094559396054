import { ApCommissionCalcContent, ApModel } from 'app/models/documents/ap.model';
import { DocumentVM } from 'app/stores/ui/document.ui.store';
import { observer } from 'mobx-react';
import { useState, useEffect } from 'react';
import { Button } from '../common/Button';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import FormField from '../form/FormField';
import { useStore } from 'app/context';
import { ApInputItem } from 'app/models/documents/ap.input.models';
import { runInAction } from 'mobx';
import { SelectField } from '../common/Select';
import { CommisionCalcRes } from 'app/stores/bnr.planungstool.store';
import { formatCurrency, formatPercent } from 'app/utils';

interface IApJahresZielForm {
	doc: DocumentVM;
}
const yupNum = Yup.number().typeError('Nur Zahleneingaben sind erlaubt').required('Eingabe erforderlich').default(0);

const apCalcFreeSchema = Yup.object({
	profit: yupNum,
	kraftBestand: yupNum,
	hvStufeAPPrivat: yupNum,
	hvStufeAPFirmen: yupNum,
	hvStufeAF: yupNum,
	apSatzLeben: yupNum,
	beOpFaktor: yupNum,
	personalkostenAD: yupNum,
	personalkostenID: yupNum,
	miete: yupNum,
	sonstigeAusgaben: yupNum,
	boni: yupNum,
	pensenProvisionen: yupNum,
	sonstigeEinnahmen: yupNum,
	hvStufe: yupNum,
	teilnahmeLVRG: yupNum,
	tabstufeAP: yupNum,
	tabstufeFP: yupNum,
	bspBestand: yupNum,
	bspStornoquote: yupNum,
	leistung: yupNum,
	bspBestandDV: yupNum,
	bspBestandNichtDV: yupNum,
	bspStornoquoteDV: yupNum,
	bspStornoquoteNichtDV: yupNum,
	anteilLeben: yupNum,
	anteilSachKraft: yupNum,
	anteilKraft: yupNum,
}).required();

type CalcCalc = Yup.InferType<typeof apCalcFreeSchema>;

const setValuesToModel = (values: CalcCalc, model: ApCommissionCalcContent) => {
	model.enableCalc = true;
	model.profit = values.profit;
	model.inputs.setValueById('kraftBestand', values);
	model.inputs.setValueById('kraftBestand', values);
	model.inputs.setValueById('hvStufeAPPrivat', values);
	model.inputs.setValueById('hvStufeAPFirmen', values);
	model.inputs.setValueById('hvStufeAF', values);
	model.inputs.setValueById('apSatzLeben', values);
	model.inputs.setValueById('beOpFaktor', values);
	model.inputs.setValueById('personalkostenAD', values);
	model.inputs.setValueById('personalkostenID', values);
	model.inputs.setValueById('miete', values);
	model.inputs.setValueById('sonstigeAusgaben', values);
	model.inputs.setValueById('boni', values);
	model.inputs.setValueById('pensenProvisionen', values);
	model.inputs.setValueById('sonstigeEinnahmen', values);
	model.inputs.setValueById('hvStufe', values);
	model.inputs.setValueById('teilnahmeLVRG', values);
	model.inputs.setValueById('tabstufeAP', values);
	model.inputs.setValueById('tabstufeFP', values);
	model.inputs.setValueById('bspBestand', values);
	model.inputs.setValueById('bspStornoquote', values);
	model.inputs.setValueById('leistung', values);
	model.inputs.setValueById('bspBestandDV', values);
	model.inputs.setValueById('bspBestandNichtDV', values);
	model.inputs.setValueById('bspStornoquoteDV', values);
	model.inputs.setValueById('bspStornoquoteNichtDV', values);
	model.inputs.setValueById('anteilLeben', values);
	model.inputs.setValueById('anteilSachKraft', values);
	model.inputs.setValueById('anteilKraft', values);
};

interface ICommisionCalced {
	values: CalcCalc;
	ap: ApModel;
}

export const CommisionCalced = observer((props: ICommisionCalced) => {
	const { documentUiStore } = useStore();
	const [calc, setCalc] = useState<CommisionCalcRes>(new CommisionCalcRes({}));

	useEffect(() => {
		const values = props.values;
		const ap = props.ap;
		const content = new ApCommissionCalcContent({});
		content._initialInputs = values;
		content.id = ap.commissionCalcContent.id;
		content.bestand = ap.commissionCalcContent.bestand;
		content.setInputs();
		setValuesToModel(values, content);
		documentUiStore.apCalcCommision(content).then((res) => {
			setCalc(res);
		});
	}, [props.values, props.ap, documentUiStore]);

	return (
		<>
			<div>
				<div className="form-control is-inline">
					<label>Abschlussprovision in €:</label>
					<span>{formatCurrency(calc.abschlussprovision)} €</span>
				</div>
				<div className="form-control is-inline">
					<label>Folgeprovision in €:</label>
					<span>{formatCurrency(calc.folgeprovision)} €</span>
				</div>
				<div className="form-control is-inline">
					<label>Erforderliche Gesamtbewertung in €</label>
					<span>{formatCurrency(calc.gesamtbewertung)} €</span>
				</div>
				<div className="form-control is-inline">
					<label>BAQ in %</label>
					<span>{formatPercent(calc.baq, 2)} %</span>
				</div>
			</div>
		</>
	);
});

export const ApJahresZielCalcForm = observer((props: IApJahresZielForm) => {
	const { uiStore, documentUiStore } = useStore();
	const ap = props.doc.doc.content as ApModel;
	const content = ap.commissionCalcContent;

	const onCancel = () => {
		uiStore.hideModal(uiStore.modalIds.apEditJahresZiel);
	};

	const handleSubmit = async (values: CalcCalc) => {
		runInAction(async () => {
			setValuesToModel(values, ap.commissionCalcContent);

			runInAction(() => {
				const content = new ApCommissionCalcContent({});
				content._initialInputs = values;
				content.id = ap.commissionCalcContent.id;
				content.bestand = ap.commissionCalcContent.bestand;
				content.setInputs();
				setValuesToModel(values, content);
			});
			const res = await documentUiStore.apCalcCommision(content);
			ap.setGesamtbewertung(res.gesamtbewertung);

			await documentUiStore.save(props.doc);
			uiStore.hideModal(uiStore.modalIds.apEditJahresZiel);
		});
	};

	return (
		<>
			<Formik
				validationSchema={apCalcFreeSchema}
				initialValues={{
					profit: ap.commissionCalcContent.profit,
					kraftBestand: ap.commissionCalcContent.inputs.getValueById('kraftBestand'),
					hvStufeAPPrivat: ap.commissionCalcContent.inputs.getValueById('hvStufeAPPrivat'),
					hvStufeAPFirmen: ap.commissionCalcContent.inputs.getValueById('hvStufeAPFirmen'),
					hvStufeAF: ap.commissionCalcContent.inputs.getValueById('hvStufeAF'),
					apSatzLeben: ap.commissionCalcContent.inputs.getValueById('apSatzLeben'),
					beOpFaktor: ap.commissionCalcContent.inputs.getValueById('beOpFaktor'),
					personalkostenAD: ap.commissionCalcContent.inputs.getValueById('personalkostenAD'),
					personalkostenID: ap.commissionCalcContent.inputs.getValueById('personalkostenID'),
					miete: ap.commissionCalcContent.inputs.getValueById('miete'),
					sonstigeAusgaben: ap.commissionCalcContent.inputs.getValueById('sonstigeAusgaben'),
					boni: ap.commissionCalcContent.inputs.getValueById('boni'),
					pensenProvisionen: ap.commissionCalcContent.inputs.getValueById('pensenProvisionen'),
					sonstigeEinnahmen: ap.commissionCalcContent.inputs.getValueById('sonstigeEinnahmen'),
					hvStufe: ap.commissionCalcContent.inputs.getValueById('hvStufe'),
					teilnahmeLVRG: ap.commissionCalcContent.inputs.getValueById('teilnahmeLVRG'),
					tabstufeAP: ap.commissionCalcContent.inputs.getValueById('tabstufeAP'),
					tabstufeFP: ap.commissionCalcContent.inputs.getValueById('tabstufeFP'),
					bspBestand: ap.commissionCalcContent.inputs.getValueById('bspBestand'),
					bspStornoquote: ap.commissionCalcContent.inputs.getValueById('bspStornoquote'),
					leistung: ap.commissionCalcContent.inputs.getValueById('leistung'),
					bspBestandDV: ap.commissionCalcContent.inputs.getValueById('bspBestandDV'),
					bspBestandNichtDV: ap.commissionCalcContent.inputs.getValueById('bspBestandNichtDV'),
					bspStornoquoteDV: ap.commissionCalcContent.inputs.getValueById('bspStornoquoteDV'),
					bspStornoquoteNichtDV: ap.commissionCalcContent.inputs.getValueById('bspStornoquoteNichtDV'),
					anteilLeben: ap.commissionCalcContent.inputs.getValueById('anteilLeben'),
					anteilSachKraft: ap.commissionCalcContent.inputs.getValueById('anteilSachKraft'),
					anteilKraft: ap.commissionCalcContent.inputs.getValueById('anteilKraft'),
				}}
				onSubmit={handleSubmit}
			>
				{({ errors, touched, values, isValid, handleChange, getFieldMeta }) => {
					return (
						<Form className="grid">
							<div className="ap-section">
								<FormField className="is-inline" error={errors.profit} touched={touched.profit} label="Geplanter Gewinn vor Steuern">
									<Field type="text" name="profit" placeholder="profit" />
								</FormField>
							</div>
							<div className="ap-section">
								<strong className="ap-section-title">Bestandsdaten ({content.inputs.inventoryData.length})</strong>
								{content.inputs.inventoryData.map((input: ApInputItem) => {
									const m = getFieldMeta(input.id);
									return (
										<FormField key={input.id} className="is-inline" error={m.error} touched={m.touched} label={input.title}>
											{input.inputType === 'string' && <Field type="text" name={input.id} placeholder={input.title} />}
											{input.inputType === 'select' && input.selectItems && <SelectField name={input.id} opts={input.selectItems} />}
										</FormField>
									);
								})}
							</div>
							<div className="ap-section">
								<strong className="ap-section-title">Ausgaben p.a.({content.inputs.expenses.length})</strong>
								{content.inputs.expenses.map((input: ApInputItem) => {
									const m = getFieldMeta(input.id);
									return (
										<FormField key={input.id} className="is-inline" error={m.error} touched={m.touched} label={input.title}>
											{input.inputType === 'string' && <Field type="text" name={input.id} placeholder={input.title} />}
											{input.inputType === 'select' && input.selectItems && <SelectField name={input.id} opts={input.selectItems} />}
										</FormField>
									);
								})}
							</div>
							<div className="ap-section">
								<strong className="ap-section-title">Einnahmen p.a. ({content.inputs.receipts.length})</strong>
								{content.inputs.receipts.map((input: ApInputItem) => {
									const m = getFieldMeta(input.id);
									return (
										<FormField key={input.id} className="is-inline" error={m.error} touched={m.touched} label={input.title}>
											{input.inputType === 'string' && <Field type="text" name={input.id} placeholder={input.title} />}
											{input.inputType === 'select' && input.selectItems && <SelectField name={input.id} opts={input.selectItems} />}
										</FormField>
									);
								})}
							</div>
							<div className="ap-section">
								<CommisionCalced values={values} ap={ap} />
							</div>
							<div className="modal-footer">
								<Button type="button" className="button is-secondary" onClick={onCancel}>
									Abbrechen
								</Button>
								<Button type="submit" className="button is-primary ">
									Speichern
								</Button>
							</div>
						</Form>
					);
				}}
			</Formik>
		</>
	);
});
