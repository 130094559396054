import { AgtBranchenplanungEdit } from 'app/components/anwendungen/branchenplanung/AgtBranchenplanungEdit';
import Widget, { IWidgetConsumer } from 'app/components/common/Widget';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import moment from 'moment';

// interface IAgtBranchenPlanungWidget {
// 	agt: AgenturModel;
// 	year: number;
// }

export const AgtBranchenPlanungWidget = observer((props: IWidgetConsumer) => {
	const { uiStore, agenturUiStore, agtPlanUiStore } = useStore();
	const agt = agenturUiStore.current;
	const currentYear = agtPlanUiStore.current ? agtPlanUiStore.current.year : moment().year();

	return (
		<>
			{agt && (
				<Widget title="Branchenplanung" modifier="is-paddingless is-branchenplanung-widget" edit={uiStore.branchenPlanungEdit} widgetId={props.widgetKey}>
					<AgtBranchenplanungEdit agt={agt} year={currentYear} showErgebnisse={false} />
				</Widget>
			)}
		</>
	);
});
