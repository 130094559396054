import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';

interface IDocumentCategoryName {
	category: string;
}

export const DocumentCategoryName = observer((props: IDocumentCategoryName) => {
	const { templateUiStore } = useStore();
	const [cat, setCat] = useState<string | undefined>(props.category);
	useEffect(() => {
		switch (props.category) {
			case 'upload':
				setCat('Datei');
				return;
			case 'jpg':
				setCat('Jahresgespräch');
				return;
			case 'ap':
				setCat('Planungstool');
				return;
			case 'notiz':
				setCat('Notiz');
				return;
			default:
				break;
		}

		templateUiStore.findByCategoryName(props.category).then((res) => {
			setCat(res);
		});
	}, [props.category, templateUiStore]);

	return <>{cat}</>;
});
