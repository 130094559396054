import { Icon } from 'app/components/common/Icon';
import { useStore } from 'app/context';
import { EditMode } from 'app/stores/ui.store';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useState } from 'react';

interface IProps {
	title: string;
	action?: JSX.Element;
	subtitle?: string;
	modifier?: string;
	disableCollapse?: boolean;
	edit?: EditMode;
	editIcon?: string;
	children: React.ReactNode;
	widgetId?: string;
}

export interface IWidgetConsumer {
	widgetKey?: string;
}

const Widget: React.FC<IProps> = observer((props: IProps) => {
	const { widgetUiStore } = useStore();
	const { title, subtitle, modifier, action, disableCollapse, children, edit } = props;
	const [open, setOpen] = useState<boolean>(widgetUiStore.getWidgetOpenState(props.widgetId));
	const editIcon = props.editIcon ? props.editIcon : 'pencil';

	useEffect(() => {
		setOpen(widgetUiStore.getWidgetOpenState(props.widgetId));
	}, [props.widgetId, widgetUiStore]);

	const toggleOpen = () => {
		setOpen(!open);
		widgetUiStore.saveWidgetState(props.widgetId, !open);
	};

	const toggleEdit = () => {
		if (!edit) {
			return;
		}
		edit.toggle();
	};

	return (
		<div className={`widget ${modifier ? modifier : ''} ${open ? 'is-open' : ''} ${disableCollapse ? 'no-collapse' : ''}`}>
			{title && (
				<div className="header">
					{!disableCollapse && (
						<div className="toggle">
							<button className="button is-icon" onClick={toggleOpen}>
								{open ? <Icon iconClass="caret-down" /> : <Icon iconClass="caret-right" />}
							</button>
						</div>
					)}
					<div className="title-area">
						<div className="title">{title}</div>
						{subtitle && <div className="subtitle">{subtitle}</div>}
					</div>
					{edit && (
						<>
							<button className="button is-icon" onClick={toggleEdit}>
								{edit.enabled ? <Icon iconClass="times" /> : <Icon iconClass={editIcon} />}
							</button>
						</>
					)}
					{action && <div className="title-actions">{action}</div>}
				</div>
			)}
			{open && <div className="body">{children}</div>}
		</div>
	);
});

export default Widget;

export interface IChildWidget {
	title?: string;
	subtitle?: string;
	action?: JSX.Element;
	modifier?: string;
	children: React.ReactNode;
}

export const ChildWidget = observer((props: IChildWidget) => {
	const { children, action, title, subtitle, modifier } = props;

	return (
		<>
			<div className={`widget is-child-widget ${modifier ? modifier : ''}`}>
				{title && (
					<div className="header">
						<div className="title-area">
							<div className="title">{title}</div>
							{subtitle && <div className="subtitle">{subtitle}</div>}
						</div>
						{action && <div className="title-actions">{action}</div>}
					</div>
				)}
				<div className="body">{children}</div>
			</div>
		</>
	);
});
