import { action, autorun, computed, makeObservable, observable } from 'mobx';
import { AgenturModel } from '../agentur.model';
import { ApAusgleichInput, ApInputBase, ApPro3Input, ApStrategieBInput, ApStrategieInput, ApStrategieLInput, ApUmstiegInput } from './ap.input.models';

export class ApModel {
	constructor(opts: any) {
		makeObservable(this);
		this.agtId = opts.agtId;
		this.gesamtbewertung = opts.gesamtbewertung ? opts.gesamtbewertung : 0;
		this.jahresplan = opts.jahresplan ? opts.jahresplan : 0;
		this.baq = opts.baq ? opts.baq : 0;
		this.bewertungVorPlanungszeitraum = opts.bewertungVorPlanungszeitraum ? opts.bewertungVorPlanungszeitraum : 0;
		this.bewertungFuerPlanungszeitraum = opts.bewertungFuerPlanungszeitraum ? opts.bewertungFuerPlanungszeitraum : 0;
		this.startMonth = opts.startMonth ? opts.startMonth : 1;
		this.endMonth = opts.endMonth ? opts.endMonth : 12;
		this.commissionCalcContent = new ApCommissionCalcContent(opts.commissionCalcContent);

		if (opts.vermittler) {
			this.vermittler = opts.vermittler.map((v: any) => new ApVermittler(v));
		}

		autorun(() => {
			const bestand = this.commissionCalcContent.bestand ? parseFloat(this.commissionCalcContent.bestand.toString()) : 0;
			const gesamtbewertung = this.gesamtbewertung ? parseFloat(this.gesamtbewertung.toString()) : 0;
			this.baq = this.calcBac(bestand, gesamtbewertung);
		});
	}

	calcBac(bestand: number, gesamtbewertung: number) {
		if (bestand > 0 && gesamtbewertung > 0) {
			return (gesamtbewertung / bestand) * 100;
		} else {
			return 0;
		}
	}

	@action
	setGesamtbewertung(bew: number) {
		bew = parseFloat(bew.toString());
		this.gesamtbewertung = bew;
		this.jahresplan = bew;
		this.bewertungFuerPlanungszeitraum = bew;
	}

	@observable
	agtId: number;
	@observable
	gesamtbewertung: number;
	@observable
	jahresplan: number;
	@observable
	baq: number;
	@observable
	bewertungVorPlanungszeitraum: number;
	@observable
	bewertungFuerPlanungszeitraum: number;
	@observable
	startMonth: number = 1;
	@observable
	endMonth: number = 12;
	@observable
	commissionCalcContent: ApCommissionCalcContent;
	@observable
	vermittler: ApVermittler[] = [];

	// only for interface compatibility
	text: string = '';

	@computed
	get hasValidId() {
		return this.commissionCalcContent.id !== 'notdefined';
	}

	@action
	setProvsysId(agt: AgenturModel) {
		console.log('fixing ap from', this.commissionCalcContent.id, 'to', agt.apProvsysId);
		this.commissionCalcContent.id = agt.apProvsysId;
		this.commissionCalcContent.setInputs();
	}

	@computed
	get isPlanned() {
		return this.baq > 0 && this.gesamtbewertung > 0;
	}

	@computed
	get bewertungVorPlanungszeitraumIst() {
		let bewertungVorPlanungszeitraumIst = 0;
		this.vermittler.forEach((v) => {
			bewertungVorPlanungszeitraumIst += parseFloat(v.vorPlan.toString());
		});
		return bewertungVorPlanungszeitraumIst;
	}

	@computed
	get bewertungVorPlanungszeitraumDelta() {
		return parseFloat(this.bewertungVorPlanungszeitraum.toString()) - parseFloat(this.bewertungVorPlanungszeitraumIst.toString());
	}

	@computed
	get bewertungFuerPlanungszeitraumIst() {
		let bewertungFuerPlanungszeitraumIst = 0;
		this.vermittler.forEach((v) => {
			bewertungFuerPlanungszeitraumIst += parseFloat(v.plan.toString());
		});
		return bewertungFuerPlanungszeitraumIst;
	}
	@computed
	get bewertungFuerPlanungszeitraumDelta() {
		return parseFloat(this.bewertungFuerPlanungszeitraum.toString()) - parseFloat(this.bewertungFuerPlanungszeitraumIst.toString());
	}

	static fromJson(j?: string) {
		if (!j || j === '') {
			return new ApModel({});
		}
		const o = JSON.parse(j);
		return new ApModel(o);
	}
	toJSON() {
		return JSON.stringify(this.serialize());
	}

	serialize() {
		return {
			agtId: this.agtId,
			gesamtbewertung: this.gesamtbewertung,
			jahresplan: this.jahresplan,
			baq: this.baq,
			bewertungVorPlanungszeitraum: this.bewertungVorPlanungszeitraum,
			bewertungFuerPlanungszeitraum: this.bewertungFuerPlanungszeitraum,
			startMonth: this.startMonth,
			endMonth: this.endMonth,
			commissionCalcContent: this.commissionCalcContent.serialize(),
			vermittler: this.vermittler.map((v) => v.serialize()),
		};
	}
}

export class ApVermittler {
	constructor(opts: any) {
		makeObservable(this);
		this.name = opts.name;
		this.id = opts.id;
		this.bnr = opts.bnr;
		this.art = opts.art;
		this.kat = opts.kat;
		this.absence = opts.absence ? opts.absence : 0;
		this.quoteDurchschnittBewertungNeutsueck = opts.quoteDurchschnittBewertungNeutsueck ? opts.quoteDurchschnittBewertungNeutsueck : 300;
		this.quoteDurchgefuehrteTermineMitAbschluss = opts.quoteDurchgefuehrteTermineMitAbschluss ? opts.quoteDurchgefuehrteTermineMitAbschluss : 33;
		this.quoteAnzahlNeustueckJeTermin = opts.quoteAnzahlNeustueckJeTermin ? opts.quoteAnzahlNeustueckJeTermin : 1.5;
		this.bensl = opts.bensl;
		this.quoteDurchgefuehrteTermine = opts.quoteDurchgefuehrteTermine ? opts.quoteDurchgefuehrteTermine : 80;
		this.vorPlan = opts.vorPlan ? opts.vorPlan : 0;
		this.quoteFolgetermine = opts.quoteFolgetermine ? opts.quoteFolgetermine : 25;
		this.plan = opts.plan ? opts.plan : 0;
	}

	@computed
	get isSalesPerson() {
		if (this.art === 'nope') {
			return false;
		}
		return true;
	}

	art: string; // vertreter
	kat: string; // vertreter
	@observable
	bnr: number; // vertreter
	absence: number;
	quoteDurchschnittBewertungNeutsueck: number;
	quoteDurchgefuehrteTermineMitAbschluss: number;
	quoteAnzahlNeustueckJeTermin: number;
	bensl: string;
	quoteDurchgefuehrteTermine: number;
	@observable
	vorPlan: number;
	@observable
	plan: number;
	name: string;
	quoteFolgetermine: number;
	id: string;

	@computed
	get label() {
		if (this.art === 'Vertreter') {
			return 'Vertreter';
		}
		if (this.art === 'nope') {
			return `[${this.bnr}] ${this.kat}`;
		}
		return `[${this.bnr}] ${this.art}`;
	}

	serialize() {
		return {
			art: this.art,
			kat: this.kat,
			absence: this.absence,
			bnr: this.bnr,
			quoteDurchschnittBewertungNeutsueck: this.quoteDurchschnittBewertungNeutsueck,
			quoteDurchgefuehrteTermineMitAbschluss: this.quoteDurchgefuehrteTermineMitAbschluss,
			quoteAnzahlNeustueckJeTermin: this.quoteAnzahlNeustueckJeTermin,
			bensl: this.bensl,
			quoteDurchgefuehrteTermine: this.quoteDurchgefuehrteTermine,
			vorPlan: this.vorPlan,
			name: this.name,
			quoteFolgetermine: this.quoteFolgetermine,
			id: this.id,
			plan: this.plan,
		};
	}
}

export class ApCommissionCalcContent {
	constructor(opts: any) {
		makeObservable(this);
		if (!opts) {
			opts = {
				bestand: 0,
				profit: 0,
				inputs: {},
			};
		}
		if (opts.enableCalc) {
			this.enableCalc = true;
		}
		this.id = opts.id ? opts.id : 'notdefined'; // umstige etc..
		this.agtId = opts.agtId;
		this.bestand = opts.bestand;
		this.profit = opts.profit;
		this._initialInputs = opts.inputs;
		// to avoid compiler error
		this.inputs = new ApInputBase(this._initialInputs);
		this.setInputs();
	}
	setInputs() {
		switch (this.id.toLowerCase()) {
			case 'umstieg':
				this.inputs.setData(new ApUmstiegInput(), this._initialInputs);
				// this.inputs = new ApUmstiegInput(this._initialInputs);
				break;
			case 'ausgleich':
				this.inputs.setData(new ApAusgleichInput(), this._initialInputs);
				break;
			case 'pro3':
				this.inputs.setData(new ApPro3Input(), this._initialInputs);
				break;
			case 'strategiel':
				this.inputs.setData(new ApStrategieLInput(), this._initialInputs);
				break;
			case 'strategie':
				this.inputs.setData(new ApStrategieInput(), this._initialInputs);
				break;
			case 'strategieb':
				this.inputs.setData(new ApStrategieBInput(), this._initialInputs);
				break;
			default:
				// this.inputs.setData(new ApInputBase(),this._initialInputs));
				console.log('no id in ap', this.id);
		}
	}

	_initialInputs: any;

	@observable
	enableCalc: boolean = false;
	id: string;
	@observable
	bestand: number;
	@observable
	profit: number;
	agtId: number;
	@observable
	inputs: ApInputBase;
	serialize() {
		if (!this.bestand) {
			console.log('bestand is missing');
		}
		return {
			enableCalc: this.enableCalc,
			agtId: this.agtId,
			id: this.id,
			bestand: this.bestand,
			profit: this.profit,
			inputs: this.inputs.serialize(),
		};
	}
}

/* 
{
    "jahresplan":94000,
    "baq":10.285904371728206,
    "commissionCalcContent":{
        "bestand":913872,
        "inputs":{
            "sonstigeAusgaben":40,
            "apSatzLeben":0,
            "hvStufeAF":1,
            "boni":100,
            "personalkostenID":20,
            "miete":30,
            "pensenProvisionen":101,
            "hvStufeAPPrivat":1,
            "personalkostenAD":10,
            "hvStufeAPFirmen":1,
            "beOpFaktor":100,
            "kraftBestand":12,
            "sonstigeEinnahmen":20
        },
        "enableCalc":true,
        "id":"umstieg",
        "profit":100000,
        "agtId":702100310
    },
    "bewertungVorPlanungszeitraum":0,
    "startMonth":1,
    "bewertungFuerPlanungszeitraum":94000,
    "vermittler":[
        {
            "art":"vertreter",
        "absence":0,
        "quoteDurchschnittBewertungNeutsueck":300,
        "quoteDurchgefuehrteTermineMitAbschluss":33,
        "quoteAnzahlNeustueckJeTermin":1.5,
        "bensl":"1996-08-01 00:00:00",
        "quoteDurchgefuehrteTermine":80,
        "vorPlan":0,
        "name":"Helmut Amesreiter",
        "quoteFolgetermine":25,
        "id":"Helmut Amesreiter-Sun Oct 04 1964 00:00:00 GMT+0100 (Mitteleuropäische Sommerzeit)",
        "plan":94000,
        "kat":"vertreter"
    }
    ],
    "gesamtbewertung":94000,
    "endMonth":12,
    "agtId":702100310}

*/

/* 
Freie Eingabe
{
    
    "jahresplan":200000,
    "baq":38.915881376610386,
    "commissionCalcContent":{
        "bestand":513929,
        "inputs":{
            "sonstigeAusgaben":0,
            "personalkostenAD":0,
            "boni":0,"personalkostenID":0,
            "tabstufeAP":1,
            "teilnahmeLVRG":0,
            "miete":0,
            "tabstufeFP":1,
            "sonstigeEinnahmen":0
        },
        "enableCalc":false,
        "id":"pro3",
        "profit":20000,
        "agtId":702130570
    },"
    bewertungVorPlanungszeitraum":0,
    "startMonth":1,
    "bewertungFuerPlanungszeitraum":200000,
    "vermittler":[
        {
            "art":"vertreter",
            "absence":0,
            "quoteDurchschnittBewertungNeutsueck":300,
            "quoteDurchgefuehrteTermineMitAbschluss":33,
            "quoteAnzahlNeustueckJeTermin":1.5,
            "bensl":"2018-08-01 00:00:00",
            "quoteDurchgefuehrteTermine":80,
            "vorPlan":0,
            "name":"Sezai Alpaslan",
            "quoteFolgetermine":25,
            "id":"Sezai Alpaslan-Mon Feb 06 1989 00:00:00 GMT+0100 (Mitteleuropäische Normalzeit)",
            "plan":200000,
            "kat":"vertreter"
        }],
    "gesamtbewertung":200000,
    "endMonth":12,
    "agtId":702130570
} */
