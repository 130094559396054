// create index document_bnr_id_index
// 	on document (bnr_id);

// create index document_id_bnr_id_index
// 	on document (id, bnr_id);

// create index shared_document_bnr_id_index
// 	on shared_document (bnr_id);a

// create index shared_document_doc_id_index
// 	on shared_document (doc_id);

import { ApModel } from 'app/models/documents/ap.model';
import Config from 'Config';
import FileSaver from 'file-saver';
import { computed, makeObservable, observable } from 'mobx';
import moment from 'moment';
import { BaseCachedEntityStore } from './core/base.cached.entity.store';
import { AgtPlanExportOpts } from './ui/agt.plan.export.ui.store';
import { SendDocPerEmailData } from './ui/document.ui.store';

// standard
// contentJson: "{\"text\":\"Das Jahr 2019 ist für die Agentur incl. Umzug in neue Räume erfolgreich verlaufen. Aktuell sind für das Jahr 2020 die Themen Personal und Neugeschäft incl. Fremdkundenakquise priorisiert.  Mit Herrn Zylinski wird ein Innendienstazubi seine Praxisphase in der Agentur ab März absolvieren<p><br /></p>\"}"

// ap
// contentJson: '{\n  "agtId": 979211610,\n  "bewertungVorPlanungszeitraum": "0",\n  "bewertungFuerPlanungszeitraum": "190000",\n  "startMonth": 1,\n  "endMonth": 12,\n  "gesamtbewertung": 190000,\n  "baq": 16.793872888059997,\n  "jahresplan": 190000,\n  "vermittler": [\n    {\n      "id": "Rainer Lippert-Tue Feb 02 1971 01:00:00 GMT+0100 (Mitteleuropäische Normalzeit)",\n      "name": "Rainer Lippert",\n      "absence": "5",\n      "vorPlan": 0,\n      "plan": "190000",\n      "quoteDurchschnittBewertungNeutsueck": "600",\n      "quoteAnzahlNeustueckJeTermin": "1.5",\n      "quoteDurchgefuehrteTermineMitAbschluss": "50",\n      "quoteDurchgefuehrteTermine": "80",\n      "quoteFolgetermine": "25",\n      "kat": "vertreter",\n      "art": "vertreter",\n      "bensl": "2009-01-01 00:00:00"\n    }\n  ],\n  "commissionCalcContent": {\n    "id": "umstieg",\n    "agtId": 979211610,\n    "bestand": 1131365,\n    "profit": null,\n    "enableCalc": false,\n    "inputs": {\n      "kraftBestand": "0",\n      "hvStufeAPPrivat": 1,\n      "hvStufeAPFirmen": 1,\n      "hvStufeAF": 1,\n      "apSatzLeben": "0",\n      "beOpFaktor": "100",\n      "personalkostenAD": "0",\n      "personalkostenID": "0",\n      "miete": "0",\n      "sonstigeAusgaben": "0",\n      "boni": "0",\n      "pensenProvisionen": "0",\n      "sonstigeEinnahmen": "0"\n    }\n  }\n}';

// kbv

export class DocumentTextContent {
	constructor(opts: any) {
		this.text = opts.text;
		this.bnrGpYear = opts.bnrGpYear;
		this.gpBnrId = opts.gpBnrId;
	}
	text: string;
	bnrGpYear?: number;
	gpBnrId?: number;

	static fromJson(j?: string) {
		if (!j || j === '') {
			return new DocumentTextContent({ text: '' });
		}
		try {
			const o = JSON.parse(j);
			return new DocumentTextContent(o);
		} catch (ex) {
			console.error('could no parse json: ', j);
			return new DocumentTextContent({ text: '' });
		}
	}

	toJSON() {
		return JSON.stringify({
			text: this.text,
			bnrGpYear: this.bnrGpYear,
			gpBnrId: this.gpBnrId,
		});
	}
}

export enum DocumentStatus {
	CREATED = 0,
	DRAFT = 1, // only if user saved document manually once
	FINAL = 2,
	DELETED = 3,
}

const parsDocStatus = (val: number) => {
	if (val >= 0 && val <= 3) {
		return val;
	}
	throw new Error('invalid document status' + val);
};

export class BnrDocumentShareModel {
	constructor(opts: any) {
		this.id = opts.id;
		this.docId = opts.docId;
		this.bnrId = opts.bnrId;
		this.created = opts.created;
		this.dateHasRead = opts.dateHasRead;
	}

	id: string;
	bnrId: number;
	created: Date;
	dateHasRead: Date;
	docId: string;
}

export class BnrDocumentModel {
	constructor(opts: any) {
		makeObservable(this);
		this.agtId = opts.agtId;
		this.bnrId = opts.bnrId;
		this.id = opts.id;
		this.planId = opts.planId;
		this.planYear = opts.planYear ? parseInt(opts.planYear) : undefined;
		this.category = opts.category;
		if (!opts.category) {
			this.category = 'notdefcat';
		}
		this.created = opts.created;
		this.modified = opts.modified;
		this.editable = opts.reallyEditable;
		this.title = opts.title;
		this.transferDate = opts.transferDate;
		this.transferId = opts.transferId;
		this.transferRefId = opts.transferRefId;
		this.transferText = opts.transferText;
		if (this.category === 'ap') {
			this.content = ApModel.fromJson(opts.contentJson);
		} else {
			this.content = DocumentTextContent.fromJson(opts.contentJson);
			if (opts.text) {
				this.content.text = opts.text;
			}
		}
		this.status = parsDocStatus(opts.status);
		if (opts.sharedWith) {
			this.sharedWith = opts.sharedWith.map((s: any) => new BnrDocumentShareModel(s));
		}
		if (this.category === 'kbvr') {
			this.editable = false;
		}
	}

	@observable
	id: string;

	bnrId: number;
	category: string;

	planId: string;
	planYear?: number;
	agtId?: number;

	// read-only
	// editable vs. reallyEditable: the later also checks if transferId is present
	editable: boolean;
	title: string;
	created: Date;
	modified: Date;
	transferDate?: Date;
	transferId?: string;
	transferRefId?: string;
	transferText?: string;

	@computed
	get yearCreated() {
		return moment(this.created).year();
	}
	@computed
	get yearModified() {
		return moment(this.modified).year();
	}

	@computed
	get kwModified() {
		return moment(this.modified).format('YYYYww');
	}

	content: DocumentTextContent | ApModel;

	serialize() {
		return {
			id: this.id,
			planId: this.planId,
			planYear: this.planYear,
			agtId: this.agtId,
			vblId: this.bnrId,
			category: this.category,
			// thema: this.thema,
			title: this.title,
			// datum: this.datum,
			// state: this.state,
			contentJson: this.content.toJSON(),
		};
	}
	status: DocumentStatus;
	@observable
	sharedWith: BnrDocumentShareModel[] = [];

	//documentDate: is not being used anymore.
	// reallyEditable -> see constructor
}

export enum DocExportType {
	kbv = 'kbv',
	agt = 'agt',
	ap = 'ap',
	task = 'task',
}

export class BnrDocumentStore extends BaseCachedEntityStore<BnrDocumentModel> {
	apiPath = 'api/documents';
	idProp: string = 'id';
	disableFindForStearts = [123];

	createInstances(opts: any): BnrDocumentModel {
		return new BnrDocumentModel(opts);
	}

	async downloadDocAsPdf(id: string, fileName: string, exportType: DocExportType) {
		const url = '/api/pdf/doc/' + id + '?c=' + new Date().getTime() + '&exportType=' + exportType;
		// http://localhost:8090/api/pdf/doc/df674db4-437f-4aba-9cff-62fcadf426e7?c=1624021123829&exportType=kbv&_=1624018672467
		const res = await this.get(url, false);
		const pdfUrl = Config.API_BASE_URL + '/api/pdf/download/' + res.data.outPutFile + '/pdf?c=' + new Date().getTime();
		return pdfUrl;
	}

	async sendDocumentPerEmail(id: string, data: SendDocPerEmailData) {
		const url = id + '/send/';
		return await this.post(url, data);
	}

	async multiDocExport(data: AgtPlanExportOpts) {
		const url = '/api/pdf/?c=' + new Date().getTime();

		await this.callRefreshTokenIfNecessary();
		const header = this.getAuthHeader();
		const res = await this.axios({
			headers: header.headers,
			url,
			method: 'POST',
			data: data.serialize(),
			responseType: 'blob', // Important
		});
		const fileName = 'DVS_Download_' + new Date().getTime() + '.zip';
		var blob = new Blob([res.data], { type: res.data.type });
		FileSaver.saveAs(blob, fileName);
	}

	async multiDocSend(mailData: SendDocPerEmailData, data: AgtPlanExportOpts) {
		const url = '/api/pdf/send?c=' + new Date().getTime();

		await this.callRefreshTokenIfNecessary();
		const header = this.getAuthHeader();

		const postData = data.serialize();
		Object.assign(postData, mailData);
		// @ts-ignore
		postData.message = mailData.body;

		await this.axios({
			headers: header.headers,
			url,
			method: 'POST',
			data: postData,
		});
	}
}
