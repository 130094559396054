import Widget, { IWidgetConsumer } from 'app/components/common/Widget';
import { useStore } from 'app/context';
import { IBnrDataItem } from 'app/stores/ui/agt.digifaktor.ui.store';
import { useEffect, useState } from 'react';
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import colors from 'assets/scss/colors.module.scss';
import { WidgetNavigate } from 'app/components/common/WidgetNavigate';
import { observer } from 'mobx-react';

export const DigfaktorChart = () => {
	return <div className="pad-1rem">Neue Daten vsl. 2024</div>;

	/*

	const { agtDigigFaktorStore: kvDigigFaktorStore } = useStore();

	const [data, setData] = useState<IBnrDataItem[]>([]);


	useEffect(() => {
		kvDigigFaktorStore.getBnrOverview().then((data) => {
			setData(data);
		});
	}, [kvDigigFaktorStore]);
	if (data.length === 0) {
		return <></>;
	}
	return (
		<>
			<ResponsiveContainer width={'99%'} height={250} debounce={300}>
				<BarChart data={data}>
					<XAxis type={'category'} dataKey={'label'} />
					<YAxis dataKey="value" type={'number'} domain={[0, 120]} />
					<Bar dataKey={'value'} stackId="a" fill={colors.turquoise} label={<ZielLabel />} />
				</BarChart>
			</ResponsiveContainer>
		</>
	);

	*/
};

const ZielLabel = (props: any) => {
	const { x, y, width, fill, value } = props;

	return (
		<text x={width / 2 + x} y={y} dy={-10} fill={fill} fontSize={14} textAnchor="middle">
			{value}
		</text>
	);
};

export const DigiFaktorChartWidget = observer((props: IWidgetConsumer) => {
	const rptPath = `berichte/kunde/digi/DF`;
	return (
		<Widget title={'Zukunftsfaktor: Digitalisierung'} action={<WidgetNavigate text={'Zum Bericht'} path={rptPath} />} widgetId={props.widgetKey}>
			<DigfaktorChart />
		</Widget>
	);
});
