import React, { useEffect, useMemo } from 'react';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { Table } from './../table/table';
import { ITableColumn, TableModel, TableRow } from './../table/table.model';
import { DocumentVM } from 'app/stores/ui/document.ui.store';
import { formatDate } from 'app/utils';
import { AgenturNameCell } from '../shared/AgenturName.cell';
import { FileSize } from '../common/FileSize';
import { runInAction } from 'mobx';
import { DocumentCategoryName } from './DocumentCategoryName';
import { DocumentShareState } from './DocumentShareState';

interface IDocumentList {
	agtId?: number;
	bnrId?: number;
	listTm?: ITableColumn<DocumentVM, number>[];
}

interface IUploads {
	doc: DocumentVM;
}
export const Uploads = observer((props: IUploads) => {
	const doc = props.doc;
	if (doc.uploads.length === 0) {
		return <> </>;
	}
	return (
		<div className="no-wrap">
			{doc.uploads.length} (<FileSize fileSize={doc.uploadSize} />)
		</div>
	);
});

export const DocumentList = observer((props: IDocumentList) => {
	const { documentUiStore, uiStore } = useStore();

	const tm = useMemo(() => {
		const viewDoc = (doc: DocumentVM) => {
			runInAction(() => {
				documentUiStore.setCurrentModalDoc(doc);
				uiStore.showModal(uiStore.modalIds.documentViewModal);
			});
		};

		const tm = new TableModel<DocumentVM, number>();

		let cols: ITableColumn<DocumentVM, number>[];
		if (props.listTm) {
			cols = props.listTm;
		} else {
			cols = [
				{
					label: 'Geteilt',
					path: 'sharedState',
					render: (row: TableRow<DocumentVM>) => {
						return (
							<>
								<DocumentShareState doc={row.data} />
								{row.data.isSharedBy && !row.data.gelesen && <span className="tag is-blue is-small is-inverted">NEU</span>}
							</>
						);
					},
				},
			];
			if (!props.agtId) {
				cols.push({
					label: 'Agentur',
					path: 'agt.name',
					render: AgenturNameCell,
				});
			}
			cols = cols.concat([
				{
					label: 'Typ',
					path: 'doc.category',
					render: (row: TableRow<DocumentVM>) => {
						return <DocumentCategoryName category={row.data.doc.category} />;
					},
				},
				{
					label: 'Plan',
					path: 'plan.year',
				},
				{
					label: 'Titel',
					path: 'doc.title',
					render: (row: TableRow<DocumentVM>) => {
						return (
							<div className="table-ellipsis" title={row.data.doc.title}>
								{row.data.doc.title}
							</div>
						);
					},
				},
				{
					label: 'Anhänge',
					path: 'uploads.length',
					render: (row: TableRow<DocumentVM>) => {
						return <Uploads doc={row.data} />;
					},
				},
				{
					label: 'Erstellt',
					path: 'doc.created',
					format: (val: any) => {
						return formatDate(val);
					},
				},
				{
					label: 'Geändert',
					path: 'doc.modified',
					format: (val: any) => {
						return formatDate(val);
					},
				},
			]);
		}

		tm.onRowClick = (row: TableRow<DocumentVM>) => {
			viewDoc(row.data);
		};

		tm.setCols(cols);
		tm.sortBy = 'name';
		tm.idProperty = 'id';
		tm.idType = 'string';
		return tm;
	}, [props.agtId, documentUiStore, uiStore, props.listTm]);

	useEffect(() => {
		documentUiStore.findAll().then(() => {
			tm.setRowData(documentUiStore.currentItems);
		});
	}, [documentUiStore, documentUiStore.currentItems, tm]);

	return (
		<>
			<Table tm={tm}></Table>
		</>
	);
});
