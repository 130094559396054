import { Button } from 'app/components/common/Button';
import { Icon } from 'app/components/common/Icon';
import { AgtProdPosVM } from 'app/models/berichte/agt.prod.pos.vm';
import { formatNum } from 'app/utils';
import { useState } from 'react';
import { TableRow } from '../table/table.model';
import { BnrProdPosModel } from 'app/models/berichte/bnr.prod.pos.model';

export const BoniStufenCell = (row: TableRow<AgtProdPosVM | BnrProdPosModel>) => {
	const [showStufen, setShowStufen] = useState(false);

	if (!row.data.boniStufenZiele || row.data.boniStufenZiele.length === 0) {
		return <></>;
	}

	const toggle = (e: any) => {
		setShowStufen(!showStufen);
		e.stopPropagation();
	};

	const ziele = row.data.boniStufenZiele;
	const ergebnis = row.data.posVal?.ergebnis || 0;

	return (
		<div className="bonistufen-cell">
			<Button className="bonistufen-button" onClick={toggle}>
				<span className="stars">
					{ziele.map((ziel, i) => {
						return <Icon key={`${row.data.posDef._posId}-${ziel}-${i}`} iconClass="star" faModifier={ergebnis >= ziel ? 'fas color-primary' : 'far color-grey'} />;
					})}
				</span>
			</Button>

			{showStufen && ziele && (
				<>
					<div className="flyout is-bonistufe" onClick={toggle}>
						<div className="title">Bonistufen:</div>
						{ziele.map((z, i) => (
							<div key={`z-${i}`}>
								{i + 1}: {formatNum(z)}
							</div>
						))}
					</div>
				</>
			)}
		</div>
	);
};
