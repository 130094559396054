import FormField from 'app/components/form/FormField';
import { ApGoal, ApGoalPeriod } from 'app/stores/bnr.planungstool.store';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useState } from 'react';
import { Table } from '../table/table';
import { TableModel } from '../table/table.model';

interface IApGoalsTable {
	goals: ApGoal;
}

export const ApGoalsTable = observer((props: IApGoalsTable) => {
	const [zielNiveau, setZielNiveau] = useState(0);

	const tm = useMemo(() => {
		const tm = new TableModel<ApGoalPeriod, string>();
		tm.sortBy = 'periodSort';
		tm.idProperty = 'name';
		tm.idType = 'string';
		const cols = [
			{
				label: 'Zeitraum',
				path: 'periodLabel',
			},
			{
				label: 'Geplant',
				path: 'planned',
			},
			{
				label: 'Durchgeführt',
				path: 'realized',
			},
			{
				label: 'Abschluss',
				path: 'completed',
			},
			{
				label: 'Folgetermine',
				path: 'followUp',
			},
			{
				label: 'Neustück',
				path: 'newContract',
			},
		];
		tm.setCols(cols);

		return tm;
	}, []);

	useEffect(() => {
		tm.setRowData(props.goals!.periods);
		setZielNiveau(props.goals!.level);
	}, [props.goals, tm]);

	return (
		<>
			<Table tm={tm}></Table>
			<div className="pad-1rem">
				<FormField className="is-inline" label="Zielniveau">
					<span>{zielNiveau} %</span>
				</FormField>
			</div>
		</>
	);
});
