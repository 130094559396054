import { AgenturModel } from 'app/models/agentur.model';
import { BaseEntityStore } from './core/base.entity.store';
import Config from 'Config';
import routes from 'routes';

const path = require('path-browserify');

export class ExportPdfTable {
	title: string = '';
	isHtml: boolean = true;
	data: any;
	forcePageBreak: boolean = false;
}

export interface IPrintPdfOpts {
	tables: ExportPdfTable[];
	versionDate?: string;
	title?: string;
	agt?: AgenturModel;
}

export class PdfStore extends BaseEntityStore<any> {
	apiPath = 'api/pdf/berichte';
	idProp: string = 'inspId';
	createInstances(opts: any): any {
		return opts;
	}

	getPdfLink(fileName: string) {

		const url = new URL(`/api/pdf/download/${fileName}/pdf?c=${new Date().getTime()}`, Config.API_BASE_URL).toString();

		// const p = Config.API_BASE_URL + path.join('/api/pdf/download', fileName, 'pdf?c=' + new Date().getTime());
		console.log('pdf link', url)
		return url;
	}
	getPrintinProgressUrl() {
		return ('#' + routes.printing.getPath()) as any;
	}

	async printPdf(opts: IPrintPdfOpts) {
		let toSend = { parts: opts.tables, versionDate: opts.versionDate, title: opts.title, agency: opts.agt };
		const windowReference = window.open();
		windowReference!.opener = null;
		windowReference!.location = this.getPrintinProgressUrl();

		// const toSend = this.get('datalayer.pdfDownload').prepareModelsForDownload(
		// 	this.get('tableModels'),
		// 	this.get('titles'),
		// 	this.get('agt'),
		// 	this.get('documentTitle'),
		// 	this.get('versionDateKey'),
		// );

		const res = await this.post('', toSend);
		const url = this.getPdfLink(res.data.outPutFile);
		console.log(url);
		windowReference!.location = url as any;
	}
}
