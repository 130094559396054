import Widget, { IWidgetConsumer } from 'app/components/common/Widget';
import { observer } from 'mobx-react';
import { useStore } from 'app/context';
import { formatDate } from 'app/utils';
import React, { useEffect } from 'react';
import { Button } from 'app/components/common/Button';
import { DocumentVM } from 'app/stores/ui/document.ui.store';
import { useHistory } from 'react-router';
import routes from 'routes';

const AllAgtDocs = () => {
	const history = useHistory();
	const { agenturUiStore } = useStore();
	const onClick = () => {
		if (agenturUiStore.currentAgtId) {
			history.push(routes.AGENTUR.getPath(agenturUiStore.currentAgtId?.toString(), 'dokumente'));
		}
	};

	return (
		<Button className="button is-widget-header" onClick={onClick}>
			Alle
		</Button>
	);
};

export const AgenturLast5DocsWidget = observer((props: IWidgetConsumer) => {
	const { uiStore, documentUiStore } = useStore();
	useEffect(() => {
		documentUiStore.load();
	}, [documentUiStore]);

	const openDoc = (doc: DocumentVM) => {
		documentUiStore.setCurrentModalDoc(doc);
		uiStore.showModal(uiStore.modalIds.documentViewModal);
	};

	if (documentUiStore.last5.length === 0) {
		return (
			<Widget title={'Letzte Dokumente'} action={<AllAgtDocs />} widgetId={props.widgetKey}>
				<div>Keine Dokumente</div>
			</Widget>
		);
	}

	return (
		<Widget title={'Letzte Dokumente'} modifier="is-paddingless" action={<AllAgtDocs />} widgetId={props.widgetKey}>
			<table className="table">
				<tbody>
					{documentUiStore.last5.map((d) => {
						return (
							<tr key={d.id}>
								<td>{formatDate(d.doc.modified)}</td>
								<td>{d.modalTitle}</td>
								<td className="has-text-right">
									<Button
										className="button is-text is-small"
										onClick={() => {
											openDoc(d);
										}}
									>
										Anzeigen
									</Button>
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</Widget>
	);
});
