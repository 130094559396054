import { BaseEntityStore } from './core/base.entity.store';

export class BnrBlacklistModel {
	constructor(opts: any) {
		this.bnrId = opts.bnrId;
		this.comment = opts.comment;
	}
	bnrId: number;
	comment?: string;
}

export class AdminBnrBlacklistStore extends BaseEntityStore<BnrBlacklistModel> {
	apiPath = 'api/admin/bnrblacklist';
	idProp: string = 'id';
	createInstances(opts: any): BnrBlacklistModel {
		return new BnrBlacklistModel(opts);
	}
}
