import { Icon } from 'app/components/common/Icon';
import { AgenturNameCell } from 'app/components/shared/AgenturName.cell';
import { Table } from 'app/components/table/table';
import { AggregrationType, TableModel, TableRow } from 'app/components/table/table.model';
import { useStore } from 'app/context';
import { AgtRingOverview, AgtRingPos } from 'app/stores/ui/agt.ring.ui.store';
import { formatNum } from 'app/utils';
import { observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import { RingDisclaimer } from './RingDisclaimer';

export interface IGPYear {
	year: number;
}
export const RingOveriew = observer((props: IGPYear) => {
	const { berichteUiiStore } = useStore();

	return (
		<>
			{berichteUiiStore.selectedAgt ? (
				<>
					<AgtRingOveriew {...props} />
				</>
			) : (
				<>
					<BnrRingOveriew {...props} />
				</>
			)}
		</>
	);
});

export const AgtRingOveriew = observer((props: IGPYear) => {
	const { agtRingUiStore, berichteUiiStore, agenturListUiStore } = useStore();
	const [achievedMedal, setAchievedMeal] = useState<string | undefined>();
	const tm = useMemo(() => {
		const tm = new TableModel<AgtRingPos, number>();
		const cols = [
			{
				label: '',
				path: 'pos.name',
			},
			{
				label: 'Gold',
				path: 'gold.ziel',
				format: formatNum,
				agg: [
					{
						path: 'hasGold',
						format: formatNum,
						aggType: AggregrationType.count,
					},
				],
			},
			{
				label: '',
				path: 'hasGold',
				render: (row: TableRow<AgtRingPos>) => {
					return <>{row.data.hasGold && <>{<Icon iconClass={'check'} />}</>}</>;
				},
			},
			{
				label: 'Silber',
				path: 'silber.ziel',
				format: formatNum,
				agg: [
					{
						path: 'hasSilber',
						format: formatNum,
						aggType: AggregrationType.count,
					},
				],
			},
			{
				label: '',
				path: 'hasSilber',
				render: (row: TableRow<AgtRingPos>) => {
					return <>{row.data.hasSilber && <>{<Icon iconClass={'check'} />}</>}</>;
				},
			},
			{
				label: 'Bronze',
				path: 'bronze.ziel',
				format: formatNum,
				agg: [
					{
						path: 'hasBronze',
						format: formatNum,
						aggType: AggregrationType.count,
					},
				],
			},
			{
				label: '',
				path: 'hasGold',
				render: (row: TableRow<AgtRingPos>) => {
					return <>{row.data.hasBronze && <>{<Icon iconClass={'check'} />}</>}</>;
				},
			},
			{
				label: 'Ergebnis',
				path: 'ergebnis',
				format: formatNum,
				agg: [
					{
						path: 'achievedMedal',
						aggType: AggregrationType.sum,
						visible: true,
						render: (tm: TableModel<AgtRingPos, number>) => {
							return <Trophy achievedMedal={achievedMedal} />;
						},
					},
				],
			},
		];
		tm.setCols(cols as any);
		tm.sortBy = 'retirementDate';
		tm.idProperty = 'id';
		tm.idType = 'number';
		return tm;
	}, [achievedMedal]);

	useEffect(() => {
		agtRingUiStore.findRingOverview(props.year).then((res) => {
			res = agenturListUiStore.applyFilterOnAgt(res) as AgtRingOverview[];

			tm.setRowData([]);
			if (berichteUiiStore.selectedAgt) {
				const agtId = berichteUiiStore.selectedAgt.agtId;
				const data = res.find((d) => d.agt.agtId === agtId);
				if (data) {
					setAchievedMeal(data.achievedMedal);
					tm.setRowData(data.agtRingPos);
				}
			}
		});
	}, [tm, agtRingUiStore, berichteUiiStore.selectedAgt, berichteUiiStore.menu.currentPath, agenturListUiStore.filterHasChanged, agenturListUiStore, props.year]);
	return (
		<>
			<div className="table-container">
				<RingDisclaimer />
				<Table tm={tm} stickyHeader={true}></Table>
			</div>
		</>
	);
});

interface ITrophy {
	achievedMedal?: string;
}
const Trophy = observer((props: ITrophy) => {
	return (
		<>
			{props.achievedMedal && (
				<>
					<span className={`medal is-large is-${props.achievedMedal}`}>{<Icon iconClass={'trophy'} />}</span>
				</>
			)}
		</>
	);
});

export const BnrRingOveriew = observer((props: IGPYear) => {
	const { agtRingUiStore, berichteUiiStore, agenturListUiStore } = useStore();

	const tm = useMemo(() => {
		const tm = new TableModel<AgtRingOverview, number>();
		const cols = [
			{
				label: 'Agentur',
				path: 'agt.name',
				render: AgenturNameCell,
			},
			{
				label: 'Gold',
				path: 'goldCount',
			},
			{
				label: 'Silber',
				path: 'silberCount',
			},
			{
				label: 'Bronze',
				path: 'bronzeCount',
			},
			{
				label: '',
				path: 'achievedMedal',
				render: (row: TableRow<AgtRingOverview>) => {
					return <Trophy achievedMedal={row.data.achievedMedal} />;
				},
			},
		];
		tm.setCols(cols);
		tm.sortBy = 'retirementDate';
		tm.idProperty = 'id';
		tm.idType = 'number';
		return tm;
	}, []);

	useEffect(() => {
		agtRingUiStore.findRingOverview(props.year).then((res) => {
			res = agenturListUiStore.applyFilterOnAgt(res) as AgtRingOverview[];
			if (berichteUiiStore.selectedAgt) {
				const agtId = berichteUiiStore.selectedAgt.agtId;
				res = res.filter((d) => d.agt.agtId === agtId);
			}
			tm.setRowData(res);
		});
	}, [tm, agtRingUiStore, berichteUiiStore.selectedAgt, berichteUiiStore.menu.currentPath, agenturListUiStore.filterHasChanged, agenturListUiStore, props.year]);
	return (
		<>
			<div className="table-container">
				<RingDisclaimer />
				<Table tm={tm} stickyHeader={true}></Table>
			</div>
		</>
	);
});
