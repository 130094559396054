import { ErrungenschaftModel, ErrungenschaftType, IErrungenschaft } from './errungenschaften.model';
import {
	BnrErrungenschaftAgtProd,
	BnrErrungenschaftBranchenPlanned,
	BnrErrungenschaftDocsCreated,
	BnrErrungenschaftDocsShared,
	BnrErrungenschaftGPEH,
	BnrErrungenschaftJPGScreated,
	BnrErrungenschaftTasksCompleted,
} from 'app/components/errungenschaften/BnrErrungenschaften';
import moment from 'moment';
import { LeiterSpeziSteartIds, MultiSteartIds, MultiStearts, SpeziSteartIds, Steart } from '../core/steart.model';
import {
	AkumaPrimeErrungenschaftIcon,
	DigiidErrungenschaftenIcon,
	MazErrungenschaftenIcon,
	WeweErrungenschaftenIcon,
} from 'app/components/errungenschaften/AgenturErrungenschaften';

const bnrErrungenschaften: IErrungenschaft[] = [
	// VSPs werden dynamisch hinzugefügt.
	{
		key: 'bnr.docs.created',
		component: (m: ErrungenschaftModel) => <BnrErrungenschaftDocsCreated {...m} />,
		valType: ErrungenschaftType.gsb,
		// visibleForRoles = all
		gsbLevels: [50, 20, 10],
		pathToReport: '/dokumente',
		modal: {
			title: 'Erstellte Dokumente',
			description: 'Anzahl erstellter Dokumente in der DVS für das aktuelle Planjahr.<br/> Gold: 50 <br /> Silber: 20 <br /> Bronze: 10',
		},
	},
	{
		key: 'bnr.docs.shared',
		component: (m: ErrungenschaftModel) => <BnrErrungenschaftDocsShared {...m} />,
		valType: ErrungenschaftType.gsb,
		// visibleForRoles = all
		gsbLevels: [25, 10, 5],
		pathToReport: '/dokumente',
		modal: {
			title: 'Geteilte Dokumente',
			description: 'Anzahl geteilter Dokumente in der DVS für das aktuelle Planjahr. <br/>Gold: 25 <br />Silber: 10 <br /> Bronze: 5',
		},
	},
	{
		key: 'bnr.tasks.completed',
		component: (m: ErrungenschaftModel) => <BnrErrungenschaftTasksCompleted {...m} />,
		valType: ErrungenschaftType.gsb,
		visibleForRoles: [Steart.VBL],
		gsbLevels: [50, 20, 10],
		pathToReport: '/massnahmen',
		modal: {
			title: 'Maßnahmen',
			description: 'Anzahl erledigter Maßnahmen in der DVS für das aktuelle Planjahr.<br/> Gold: 50 <br /> Silber: 20 <br /> Bronze: 10',
		},
	},
	{
		key: 'bnr.jpgs.created',
		component: (m: ErrungenschaftModel) => <BnrErrungenschaftJPGScreated {...m} />,
		valType: ErrungenschaftType.gsb,
		visibleForRoles: [Steart.VBL],
		gsbLevels: [90, 80, 70],
		pathToReport: '/agentur',
		modal: {
			title: 'JPGs',
			description: 'Quote der geführten Jahresplanungsgespräche für das aktuelle Planjahr <br/> Gold: 90% <br />Silber: 80% <br />Bronze: 70%',
		},
	},
	{
		key: 'bnr.gp.eh',
		component: (m: ErrungenschaftModel) => <BnrErrungenschaftGPEH {...m} />,
		valType: ErrungenschaftType.boolean,
		visibleForRoles: [Steart.VBL, ...SpeziSteartIds, ...LeiterSpeziSteartIds, Steart.LPV],
		pathToReport: 'berichte/production/gp/' + moment().year() + '/overview',
		modal: {
			title: 'GP-EH',
			description: 'Erreichung der persönlichen Geschäftsplan-Eingangshürde',
		},
	},

	{
		key: 'bnr.bp.planned',
		component: (m: ErrungenschaftModel) => <BnrErrungenschaftBranchenPlanned {...m} />,
		valType: ErrungenschaftType.gsb,
		visibleForRoles: [Steart.VBL, ...SpeziSteartIds],
		gsbLevels: [90, 80, 70],
		pathToReport: 'anwendungen/bp/' + moment().year() + '/overview',
		modal: {
			title: 'BP',
			description: 'Quote der durchgeführten Branchenplanungen für das aktuelle Planjahr <br/> Gold: 90% <br />Silber: 80% <br />Bronze: 70%',
		},
	},
	{
		key: 'bnr.agt.produktivitaet',
		component: (m: ErrungenschaftModel) => <BnrErrungenschaftAgtProd {...m} />,
		valType: ErrungenschaftType.boolean,
		visibleForRoles: [Steart.VBL],
		pathToReport: 'berichte/production/gp/' + moment().year() + '/prod-zwischenstand',
		modal: {
			title: 'Ist-Wert Bewertung',
			description: 'Ist-Wert-Bewertung > Planwert bei 80% der Agenturen',
		},
	},

	{
		key: 'bnr.agt.akumaprime',
		component: (m: ErrungenschaftModel) => <AkumaPrimeErrungenschaftIcon {...m} />,
		valType: ErrungenschaftType.boolean,
		visibleForRoles: [Steart.VBL, ...MultiSteartIds],
		pathToReport: 'berichte/kunde/akumaprime/uebersicht/',
		modal: {
			title: 'AKUMA Prime',
			description: '≥ 25% Agenturen mit AKUMA.Prime innerhalb der VD',
		},
	},

	// NEW:
	// AKUMA.Prime
	// visibleForRoles: [Steart.VBL, ...MultiSteartIds],

	// AKUMA
	// visibleForRoles: [Steart.VBL, ...MultiSteartIds],

	// Poition MAZ
	// visibleForRoles: [Steart.VBL, ...MultiSteartIds],

	// Position Digi-ID
	// visibleForRoles: [Steart.VBL, ...MultiSteartIds],

	// Position wEWE
	// visibleForRoles: [Steart.VBL, ...MultiSteartIds],
];

export const BnrErrungenschaftenDefinition = bnrErrungenschaften;

export const BnrSecretErrungenschaften: IErrungenschaft[] = [
	{
		key: 'bnr.meisterplaner',
		component: (m: ErrungenschaftModel) => <div>Nix zu sehen </div>,
		valType: ErrungenschaftType.boolean,
		visibleForRoles: [Steart.VBL, ...SpeziSteartIds],
		pathToReport: '',
		modal: {
			title: 'Meisterplaner',
			description: 'Errungenschaft GOLD für BPs, JPGs sowie IST-Wert Bewertung',
		},
	},
	{
		key: 'bnr.zielerfueller',
		component: (m: ErrungenschaftModel) => <div>Nix zu sehen </div>,
		valType: ErrungenschaftType.boolean,
		visibleForRoles: [Steart.VBL, ...SpeziSteartIds],
		pathToReport: '',
		modal: {
			title: 'Zielerfüller',
			description: 'Alle eigenen GP Ziele sowie Vertriebsschwerpunktziele erreicht',
		},
	},
	{
		key: 'bnr.dvs.experte',
		component: (m: ErrungenschaftModel) => <div>Nix zu sehen </div>,
		valType: ErrungenschaftType.boolean,
		// visibleForRoles = all
		pathToReport: '',
		modal: {
			title: 'DVS Experte',
			description: 'Errungenschaft GOLD für Dokumente erstellt, Dokumente geteilt, sowie Maßnahmen erledigt',
		},
	},
];
