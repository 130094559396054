import { BaseApiStore } from './base.api.store';
import _ from 'lodash';

export class BaseEntityStore<T> extends BaseApiStore {
	createInstances(opts: any): T {
		throw new Error('create not implemented');
	}
	idProp: string = 'id';

	async findAll(): Promise<T[]> {
		const res = await this.get('');
		if (!res.data) {
			return [];
		}
		if (Array.isArray(res.data)) {
			return res.data.map((d: any) => this.createInstances(d));
		} else {
			return [this.createInstances(res.data)];
		}
	}

	async findById(id: number | string) {
		const res = await this.get(id.toString());
		if (!res.data) {
			return undefined;
		}
		return this.createInstances(res.data);
	}

	async deleteById(id: number | string) {
		await this.delete(id.toString());
	}

	async save(data: T) {
		return this._save(data);
	}

	async _save(data: T) {
		const id = (data as any)[this.idProp];
		let _data = data as any;

		let p;
		if (_data && _.isFunction(_data.serialize)) {
			_data = _data.serialize();
		}
		if (id) {
			p = this.put(id, _data);
		} else {
			p = this.post(id, _data);
		}
		const res = await p;
		return this.createInstances(res.data);
	}
}
