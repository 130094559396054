import { UmrechFaktor } from 'app/stores/agt.bp.store';
import { RingPosDef } from 'app/stores/ui/agt.ring.ui.store';
import Config from 'Config';
import { computed } from 'mobx';
import { Einheit, EinheitModel, FindEinheit } from '../core/einheit.model';
import { FindSteart, Steart, SteartFromId, SteartModel } from '../core/steart.model';

export interface IBpAgtPosModel {
	planKey: string;
	posText: string;
	einheit: Einheit;
	bewFaktor: number;
	perms: Steart[];
	isConvertible: boolean;
	ringPosDef?: RingPosDef;
}

export class BpAgtPosModel {
	constructor(opts: IBpAgtPosModel) {
		this.planKey = opts.planKey;
		this.posText = opts.posText;
		this.einheit = FindEinheit(opts.einheit);
		this.bewFaktor = opts.bewFaktor;
		this.isConvertible = opts.isConvertible;
		this.perms = opts.perms.map((s) => FindSteart(SteartFromId(s)));
		this.ringPosDef = opts.ringPosDef;
	}
	@computed
	get prop() {
		return this.planKey;
	}

	planKey: string;
	posText: string;
	einheit: EinheitModel;
	bewFaktor: number;
	perms: SteartModel[];
	isConvertible: boolean;
	ringPosDef?: RingPosDef;

	getEinheitForFak(fak: UmrechFaktor) {
		if (!this.isConvertible) {
			return this.einheit;
		}
		if (fak === UmrechFaktor.bewertung) {
			return FindEinheit(Einheit.bew);
		}
		return this.einheit;
	}

	getVisibleStearts(curr: Steart) {
		let others = this.perms.filter((x) => x.id === curr);
		if (curr === Steart.LSV || curr === Steart.LPV) {
			// dürfen nur VBL sehen, sofern definiert
			return others.filter((x) => x.steart === Steart.VBL);
		}
		if (curr === Steart.GSL) {
			return others.filter((x) => [Steart.VBL, Steart.LPV, Steart.LSV].includes(x.steart));
		}
		others = others.filter((x) => x.steart !== Steart.LPV && x.steart !== Steart.LSV);
		return others;
	}

	getOtherVisibleStearts(curr: Steart) {
		let others = this.perms.filter((x) => x.id !== curr);
		if (curr === Steart.LSV || curr === Steart.LPV) {
			// dürfen nur VBL sehen, sofern definiert
			return others.filter((x) => x.steart === Steart.VBL);
		}
		if (curr === Steart.GSL) {
			// GSL sehen nur VBL
			return others.filter((x) => [Steart.VBL, Steart.LPV, Steart.LSV].includes(x.steart));
		}
		if (Config.showGslStuff) {
			others = others.filter((x) => x.steart !== Steart.LPV && x.steart !== Steart.LSV);
		} else {
			others = others.filter((x) => x.steart !== Steart.LPV && x.steart !== Steart.LSV && x.steart !== Steart.GSL);
		}
		return others;
	}

	canOthersSee(curr: Steart) {
		// let mine = this.perms.filter((x) => x.id === curr);
		let all = this.perms;
		if (curr === Steart.LSV || curr === Steart.LPV) {
			// dürfen nur VBL sehen, sofern definiert
			return all.filter((x) => x.steart === Steart.VBL);
		}
		if (curr === Steart.GSL) {
			return all.filter((x) => x.steart === Steart.VBL);
		}
		all = all.filter((x) => x.steart !== Steart.LPV && x.steart !== Steart.LSV);
		const currCan = all.filter((x) => x.id === curr).length > 0;
		return currCan && all.length > 1;
	}
}
