import { useStore } from 'app/context';
import { Field, Form, Formik } from 'formik';
import { observer } from 'mobx-react';
import React from 'react';
import { Button } from '../common/Button';
import FormField from '../form/FormField';

import * as Yup from 'yup';
import { TemplateVM } from 'app/stores/ui/template.ui.store';
import { TextEditorField } from '../form/TextEditorField';
import { notify } from '../common/notify';
import { runInAction } from 'mobx';

const templateEditFormSchema = Yup.object({
	title: Yup.string().required('Bezeichnung ist erfoderlich').default('').notOneOf(['Notiz', 'Aktennote', 'Entscheidungsvorlage'], 'Titel nicht erlaubt'),
	visible: Yup.boolean().required().default(true),
	text: Yup.string().default(''),
}).required();

type Template = Yup.InferType<typeof templateEditFormSchema>;

interface ITemplateEditForm {
	template: TemplateVM;
	onClose: () => void;
}
export const TemplateEditForm = observer((props: ITemplateEditForm) => {
	const { templateUiStore } = useStore();

	const handleSubmit = async (values: Template) => {
		const isNew = props.template.id ? false : true;
		const template = props.template.template;
		template.content.title = values.title;
		template.content.text = values.text;
		template.content.visible = values.visible;
		const vm = await templateUiStore.saveTemplate(props.template);
		templateUiStore.setCurrent(vm);
		if (isNew) {
			runInAction(() => {
				templateUiStore.items.push(vm);
			});
		}
		notify('Vorlage wurde gespeichert', '', 'info');
		props.onClose();
	};

	return (
		<>
			<Formik
				validationSchema={templateEditFormSchema}
				initialValues={{
					title: props.template.title,
					visible: true,
					text: props.template.template.content.text,
				}}
				onSubmit={handleSubmit}
			>
				{({ errors, touched, isValid }) => {
					return (
						<Form className="templates-edit-form">
							<FormField error={errors.title} touched={touched.title} label="Bezeichnung *">
								<Field type="text" name="title" autoComplete="email" placeholder="Bezeichnung" />
							</FormField>

							<FormField error={errors.text} touched={touched.text} label="Textvorlage">
								<TextEditorField name="text" />
							</FormField>

							<div className="modal-footer">
								<Button type="button" className="button" onClick={props.onClose}>
									Abbrechen
								</Button>
								<Button type="submit" className="button is-primary ">
									Speichern
								</Button>
							</div>
						</Form>
					);
				}}
			</Formik>
		</>
	);
});
