import BaseContainer from 'app/containers/BaseContainer';
import { observer } from 'mobx-react';
import { MainMenu } from './MainMenu';
import { RouteComponentProps } from 'react-router-dom';
import { useStore } from 'app/context';
import { AdminNotificationsForm } from 'app/components/admin/notifications/AdminNotificationsForm';
import { EnsureAdmin } from './AdminHomeContainer';

const AdminNotificationsContainer = observer((props: RouteComponentProps) => {
	const { session } = useStore();

	const currentUser = session.currentUser;

	return (
		<BaseContainer classModifier="admin-container" sideMenu={<MainMenu {...props} />} navbar={<div>Notifications</div>} actionControls={<></>}>
			<EnsureAdmin />
			{currentUser && <AdminNotificationsForm />}
		</BaseContainer>
	);
});

export default AdminNotificationsContainer;
