import React, { useEffect, useState } from 'react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import FormField from '../form/FormField';
import { useStore } from 'app/context';
import { Button } from '../common/Button';
import routes from './../../../routes';
import { useHistory } from 'react-router';
import { passwordRegex } from 'app/utils';
import { AuthError } from './RegisterForm';
import { IResponse } from 'app/stores/core/base.api.store';
import { notify } from '../common/notify';

const setPasswordFormSchema = Yup.object()
	.shape({
		password1: Yup.string().matches(passwordRegex, 'Passwort muss mindestens 8 Zeichen umfassen, Groß- und Kleinbuchstaben beinhalten und mindestens 1 Sonderzeichen enthalten').required('Passwort ist erforderlich').default(''),
		password2: Yup.string()
			.oneOf([Yup.ref('password1')], 'Die Passwörter müssen übereinstimmen')
			.required('Bitte wiederholen Sie das Passwort')
			.default(''),
	})
	.required();

type SetPassword = Yup.InferType<typeof setPasswordFormSchema>;

export interface ISetPasswordProps {
	id: string;
	token: string;
}

export const SetPasswordForm = (props: ISetPasswordProps) => {
	const [response, setResponse] = useState<IResponse | undefined>();
	const authStore = useStore().authStore;
	const history = useHistory();

	useEffect(() => {
		authStore.readOnlyLogin(props.id, props.token).then((res) => {
			if (res.status !== 200) {
				notify('Passwort setzen', 'Ungültige Anforderung', 'error');
				history.push(routes.LOGIN.getPath());
			}
		});
	}, [props.id, props.token, authStore, history]);

	async function handleSubmit(values: SetPassword) {
		const res = await authStore.setPassword(values.password1);
		if (!res.data && res.status) {
			setResponse(res);
		} else {
			notify('Password gesetzt', 'Melden Sie sich mit Ihrem BenSl und neuen Passwort an', 'success');
			history.push(routes.LOGIN.getPath());
		}
	}

	return (
		<Formik
			validationSchema={setPasswordFormSchema}
			initialValues={{
				password1: '',
				password2: '',
			}}
			onSubmit={handleSubmit}
		>
			{({ errors, touched, isValid }) => {
				return (
					<Form className="auth-form">
						<div className="auth-form-header">
							<div className="title">DVS</div>
						</div>
						<div>Bitte geben Sie hier Ihr neues Passwort ein:</div>

						<div>
							<FormField className="title" error={errors.password1} touched={touched.password1} label={'Passwort'}>
								<Field type="password" name="password1" autoComplete="username" placeholder="Passwort" autoFocus={true} />
							</FormField>
							<FormField className="title" error={errors.password2} touched={touched.password2} label={'Passwort wiederholen'}>
								<Field type="password" name="password2" autoComplete="password" placeholder="Passwort wiederholen" />
							</FormField>
						</div>

						{response && <AuthError {...response} />}

						<div>
							<Button type="submit" className="button is-primary is-block" isFormInvalid={!isValid}>
								Passwort setzen
							</Button>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
};
