import _ from 'lodash';
import { computed, makeObservable, observable } from 'mobx';
import { AgtAkumaPrimeUiStore, AgtAkumaPrimeKwVM, IAkumaPrimeProps, AkumaPrimeVal } from './agt.akumaprime.ui.store';
import { AkumaPrimeModel } from '../kv.agentur.year.akumaprime.store';

export class BnrAkumaPrimeKwVM implements IAkumaPrimeProps {
	constructor(items: AgtAkumaPrimeKwVM[]) {
		if (items.length > 0) {
			this.kw = items[0].kw;
		} else {
			this.kw = 0;
		}

		this.einordnungSach = AkumaPrimeVal.getBnrAvg('einordnungSach', items);
		this.einordnungLeben = AkumaPrimeVal.getBnrAvg('einordnungLeben', items);
		this.einordnungKranken = AkumaPrimeVal.getBnrAvg('einordnungKranken', items);
		this.einordnungSonstige = AkumaPrimeVal.getBnrAvg('einordnungSonstige', items);
		this.einordnungNichtKontaktieren = AkumaPrimeVal.getBnrAvg('einordnungNichtKontaktieren', items);
		this.einordnungNichtEingeordnet = AkumaPrimeVal.getBnrAvg('einordnungNichtEingeordnet', items);
		this.einordnungBestehendeAktion = AkumaPrimeVal.getBnrAvg('einordnungBestehendeAktion', items);

		this.beziehungWenigerGut = AkumaPrimeVal.getBnrAvg('beziehungWenigerGut', items);
		this.beziehungGut = AkumaPrimeVal.getBnrAvg('beziehungGut', items);
		this.beziehungMittel = AkumaPrimeVal.getBnrAvg('beziehungMittel', items);
		this.beziehungUnbekannt = AkumaPrimeVal.getBnrAvg('beziehungUnbekannt', items);

		this.terminierungExtern = AkumaPrimeVal.getBnrAvg('terminierungExtern', items);
		this.terminierungAgentur = AkumaPrimeVal.getBnrAvg('terminierungAgentur', items);
	}

	einordnungSach: AkumaPrimeVal;
	einordnungLeben: AkumaPrimeVal;
	einordnungKranken: AkumaPrimeVal;
	einordnungSonstige: AkumaPrimeVal;
	einordnungNichtKontaktieren: AkumaPrimeVal;
	einordnungNichtEingeordnet: AkumaPrimeVal;
	einordnungBestehendeAktion: AkumaPrimeVal;

	einordnungsQuote: number = 0;

	beziehungWenigerGut: AkumaPrimeVal;
	beziehungGut: AkumaPrimeVal;
	beziehungMittel: AkumaPrimeVal;
	beziehungUnbekannt: AkumaPrimeVal;

	terminierungExtern: AkumaPrimeVal;
	terminierungAgentur: AkumaPrimeVal;

	kw: number;

	items: AkumaPrimeModel[] = [];

}

export class BnrAkumaPrimeUiStore {
	agtAkumaPrimeUiStore: AgtAkumaPrimeUiStore;
	constructor(agtAkumaPrimeUiStore: AgtAkumaPrimeUiStore) {
		makeObservable(this);
		this.agtAkumaPrimeUiStore = agtAkumaPrimeUiStore;
	}

	@observable
	items: AgtAkumaPrimeKwVM[] = [];

	calc() {
		const count = this.items.length;
		if (count === 0) {
			return;
		}

		this.einordnungsQuoteAvg = this.calcAvgByPath('einordnungsQuote');
		this.einordnungsQuoteAvgNot = 100 - this.einordnungsQuoteAvg;

		this.avg = new BnrAkumaPrimeKwVM(this.items);
	}

	calcAvgByPath(p: string) {
		const items = this.items;
		const count = items.length;
		const sum = _.sumBy(items, p);
		return sum / count;
	}

	@observable
	avg: BnrAkumaPrimeKwVM = new BnrAkumaPrimeKwVM([]);

	@observable
	einordnungsQuoteAvg: number = 0;

	@observable
	einordnungsQuoteAvgNot: number = 100;
}
