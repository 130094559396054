import { BaseEntityStore } from './core/base.entity.store';

export class BnrTransferRequest {
	constructor(opts: any) {
		this.created = opts.created;
		this.id = opts.id;
		this.modified = opts.modified;
		this.sourceBnrId = opts.sourceBnrId;
		this.sourceName = opts.sourceName;
		this.targetBnrId = opts.targetBnrId;
		this.targetName = opts.targetName;
		this.sourceComment = opts.sourceComment;
		this.transferDoks = opts.transferDoks !== undefined ? opts.transferDoks : true;
		this.transferBranchenplanung = opts.transferBranchenplanung !== undefined ? opts.transferBranchenplanung : true;
		this.state = opts.state;
	}

	created: Date;
	id: string;
	modified?: Date;
	sourceBnrId: number;
	anonHash?: string;
	sourceName: string;
	state: string;
	targetBnrId: number;
	targetName: string;
	sourceComment: string;

	transferDoks: boolean;
	transferBranchenplanung: boolean;
}

export class BnrtransferStore extends BaseEntityStore<BnrTransferRequest> {
	apiPath = 'api/transfer';
	idProp: string = 'id';
	createInstances(opts: any): BnrTransferRequest {
		return new BnrTransferRequest(opts);
	}

	async findByAnonHash(anonHash: string) {
		await this.callRefreshTokenIfNecessary();
		const head = this.getAuthHeader();
		const res = await this.axios({
			method: 'GET',
			headers: head.headers,
			url: '/api/transfer/?id=' + anonHash,
		});
		if (res && res.data) {
			const d = new BnrTransferRequest(res.data);
			d.anonHash = anonHash;
			return d;
		}
		return undefined;
	}
}
