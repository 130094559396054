import { useEffect, useState } from 'react';
import { Field } from 'formik';
import { useStore } from 'app/context';
import { AgtPlanModel } from 'app/stores/agt.plan.store';
import _ from 'lodash';

export const PlanSelectField = ({ ...props }) => {
	var { agtId, ...fieldProps } = props;
	const { agtPlanStore, agtPlanUiStore } = useStore();

	const [plans, setPlans] = useState<AgtPlanModel[]>([]);

	useEffect(() => {
		let isActive = true;

		if (agtId === 0) {
			setPlans([]);
		} else {
			agtPlanUiStore.ensureCurrentYearPlanungExists(agtId).then(() => {
				agtPlanStore.findByAgtId(agtId).then((plans) => {
					if (isActive) {
						setPlans(_.sortBy(plans, 'year').reverse());
					}
				});
			});
		}
		return () => {
			isActive = false;
		};
	}, [agtId, agtPlanUiStore, agtPlanStore]);

	return (
		<div className="select">
			<Field {...fieldProps} as="select">
				<option value={0}>Keine Auswahl </option>
				{plans.map((p) => (
					<option key={p.year} value={p.year}>
						{p.year}
					</option>
				))}
			</Field>
		</div>
	);
};
