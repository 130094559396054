import { computed, runInAction } from 'mobx';
import { KvBnrWidgetSettingsStore } from '../kv.bnr.widget.settings.store';
import { WidgetScope, WidgetSettingModel } from 'app/models/widgets/widget.setting.model';
import { WidgetItems } from 'app/models/widgets/widget.items';
import { WidgetsVm } from 'app/models/widgets/widget.vm';

export class WidgetUiStore {
	kvBnrWidgetSettingsStore: KvBnrWidgetSettingsStore;
	constructor(kvBnrWidgetSettingsStore: KvBnrWidgetSettingsStore) {
		this.kvBnrWidgetSettingsStore = kvBnrWidgetSettingsStore;
	}

	onLogout() {
		this.widgets = [];
	}

	widgets: WidgetSettingModel[] = [];

	@computed
	get widgetItems() {
		if (!this.kvBnrWidgetSettingsStore.session.currentUser) {
			console.warn('no user logged in.');
			return [];
		}
		const steart = this.kvBnrWidgetSettingsStore.session.currentUser!.steart.steart;
		return WidgetItems.filter((w) => w.stearts === undefined || w.stearts.includes(steart) || w.scope === WidgetScope.team);
	}

	@computed
	get bestandsZuwachsWidget() {
		return this.widgetItems.find((w) => w.widgetKey.toLowerCase().indexOf('bestandszuwachs') >= 0);
	}

	async load() {
		if (this.widgets.length > 0) {
			return;
		}
		let res: WidgetSettingModel[] = [];
		const items = await this.kvBnrWidgetSettingsStore.findAll();

		for (const widget of this.widgetItems) {
			let item = items.find((i) => i.widgetKey === widget.widgetKey && i.scope === widget.scope);
			let w = new WidgetSettingModel(widget);
			if (item) {
				runInAction(() => {
					w.id = item!.id;
				});
				w.setEnabled(item.enabled);
				w.setOpts(item.opts);
			} else {
				const x = await this.kvBnrWidgetSettingsStore.save(w);
				w.id = x.id;
			}
			res.push(w);
		}
		this.widgets = res;
	}

	@computed
	async findByScope(scope: WidgetScope) {
		await this.load();
		return this.widgets.filter((w) => w.scope === scope);
	}

	async save(vm: WidgetsVm) {
		for (const w of vm.widgets) {
			w.data.bnrId = this.kvBnrWidgetSettingsStore.session.currentUser!.bnrId;
			w.applyForSave();
			await this.kvBnrWidgetSettingsStore.save(w.data);
		}
		this.widgets = [];
		await this.load();
	}

	// Widget open state
	getWidgetOpenState(widgetId?: string) {
		if (!widgetId) {
			return true;
		}
		const w = this.widgets.find((i) => i.widgetKey === widgetId);
		if (w) {
			return w.opts.open;
		}
		return true;
	}

	saveWidgetState(widgetId: string | undefined, state: boolean) {
		if (!widgetId) {
			return;
		}
		const w = this.widgets.find((i) => i.widgetKey === widgetId);
		if (w) {
			runInAction(() => {
				w.bnrId = this.kvBnrWidgetSettingsStore.session.currentUser!.bnrId;
				w.opts.open = state;
			});
			this.kvBnrWidgetSettingsStore.save(w);
		}
		return;
	}
}
