import { Field } from 'formik';
import { SteartOpts } from 'app/models/core/steart.model';

export const SteartSelectField = ({ ...props }) => {
	var { agtId, ...fieldProps } = props;

	return (
		<div className="select">
			<Field {...fieldProps} as="select">
				{SteartOpts.map((p) => (
					<option key={p.value} value={p.value.toString()}>
						{p.label}
					</option>
				))}
			</Field>
		</div>
	);
};
