import { useStore } from "app/context";
import { useFormik } from "formik";

export const AdminCrawlerTest = () => {
    const { adminImportsUiStore } = useStore();

    const formik = useFormik({
        initialValues: {
            bensl: "m1229",
            kw: "202347",
            forceSend: false // Add the initial value for the checkbox
        },
        onSubmit: (values) => {
            adminImportsUiStore.crawlOne(values.bensl, values.kw, values.forceSend.toString()).then((res) => console.log(res));
            // Handle form submission
        }
    });

    return (
        <div>
            <form onSubmit={formik.handleSubmit}>
                <label htmlFor="bensl">Bensl:</label>
                <input
                    type="text"
                    id="bensl"
                    name="bensl"
                    value={formik.values.bensl}
                    onChange={formik.handleChange}
                />
                <label htmlFor="kw">KW:</label>
                <input
                    type="text"
                    id="kw"
                    name="kw"
                    value={formik.values.kw}
                    onChange={formik.handleChange}
                />
                <label htmlFor="forceSend">Force Send:</label>
                <input
                    type="checkbox"
                    id="forceSend"
                    name="forceSend"
                    checked={formik.values.forceSend}
                    onChange={formik.handleChange}
                />
                <button type="submit">Submit</button>
            </form>
        </div>
    );
}

