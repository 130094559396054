const values = [
	{
		key: 'kundenwerben',
		title: 'Kunden werben Kunden',
		children: [
			{
				key: 'facebookpost',
				title: 'Facebook-Post',
			},
			{
				key: 'homepagemodul',
				title: 'Homepage-Modul',
			},
			{
				key: 'einbindungintermine',
				title: 'Einbindung in Termin',
			},
			{
				key: 'serienmail',
				title: 'Serienmail',
			},
			{
				key: 'kundeninfobrief',
				title: 'Kundeninfobrief',
			},
			{
				key: 'auslageinagt',
				title: 'Auslage in Agentur',
			},
		],
	},
	{
		key: 'leadmanagement',
		title: 'Anlassmanagement',
		children: [
			{
				key: 'zustaendigkeitnachzugangsweg',
				title: 'Zuständigkeit nach Zugangsweg',
			},
			{
				key: 'bearbeitungsstatndards',
				title: 'Bearbeitungsstandards',
			},
			{
				key: 'sea',
				title: 'SEO (Search Engine Optimization)',
			},
			{
				key: 'smartjourney',
				title: 'Smart Journey',
			},
			{
				key: 'kaueferportal',
				title: 'Käuferportal',
			},
			{
				key: 'elpoko',
				title: 'elPoko',
			},
		],
	},
	{
		key: 'agenturauftritt',
		title: 'Agenturauftritt',
		children: [
			{
				key: 'aussenwerbung',
				title: 'Außenwerbung',
			},
			{
				key: 'moebilierung',
				title: 'Möbilierung',
			},
			{
				key: 'aufteilung',
				title: 'Aufteilung (Beratungsraum etc.)',
			},
			{
				key: 'dekoration',
				title: 'Dekoration',
			},
			{
				key: 'schaufenster',
				title: 'Schaufenster',
			},
			{
				key: 'prospekteundgeschbenke',
				title: 'Prospekte und Werbegeschenke',
			},
		],
	},
	{
		key: 'homepage',
		title: 'Homepage',
		children: [
			{
				key: 'aktualitaet',
				title: 'Aktualität',
			},
			{
				key: 'module',
				title: 'Module',
			},
			{
				key: 'freimodule',
				title: 'Freimodule',
			},
			{
				key: 'einbidunginsignatur',
				title: 'Einbindung in Signatur',
			},
			{
				key: 'whatsapp',
				title: 'WhatsApp',
			},
			{
				key: 'seo',
				title: 'SEO (Search Engine Optimization)',
			},
		],
	},
	{
		key: 'socialmedia',
		title: 'Social Media',
		children: [
			{
				key: 'socialmediatoolbox',
				title: 'Social Media Toolbox',
			},
			{
				key: 'xing',
				title: 'Xing',
			},
			{
				key: 'linkedin',
				title: 'LinkedIn',
			},
			{
				key: 'whatsapp',
				title: 'WhatsApp',
			},
			{
				key: 'facebook',
				title: 'Facebook',
			},
			{
				key: 'pflegesocialmediaactivitaten',
				title: 'Pflege Social Media Aktivitäten',
			},
		],
	},
	{
		key: 'mazavp',
		title: 'mAZ/AVP',
		children: [
			{
				key: 'ansprache',
				title: 'Ansprache',
			},
			{
				key: 'kundenmailing',
				title: 'Kundenmailing (Brief)',
			},
			{
				key: 'serienmail',
				title: 'Serienmail (Email)',
			},
			{
				key: 'einbindungbuerokraft',
				title: 'Einbindung Bürokraft (Bsp. Schnellanmeldung Laufkundschaft)',
			},
			{
				key: 'bestandteilberatungsgespraech',
				title: 'Fixer Bestandteil Beratungsgespärch (ABP)',
			},
			{
				key: 'telefonaktion',
				title: 'Telefonaktion (Bsp. Aktionen Online)',
			},
		],
	},
	{
		key: 'kukoma',
		title: 'KuKoMa',
		children: [
			{
				key: 'einrichtungkukoma',
				title: 'Einrichtung Kukoma',
			},
			{
				key: 'zustaendigkeitkukoma',
				title: 'Zuständigkeit Kukoma',
			},
			{
				key: 'nachhaltigkeitkukoma',
				title: 'Nachhaltigkeit Kukoma',
			},
			{
				key: 'vorgehebeinichterreichtenkunden',
				title: 'Vorgehen bei nicht erreichten Kunden',
			},
			{
				key: 'abgleichmitaktionenonline',
				title: 'Abgleich mit Aktionen Online',
			},
			{
				key: 'kundenueberjahreskontaktmonatinformieren',
				title: 'Kunden über Jahreskontaktmonat informieren',
			},
		],
	},
	{
		key: 'ekomi',
		title: 'eKomi',
		children: [
			{
				key: 'ablaufekomi',
				title: 'Ablauf eKomi',
			},
			{
				key: 'kundenauswahlekomi',
				title: 'Kundenauswahl eKomi',
			},
			{
				key: 'bestandteilberatungsgespärchekomi',
				title: 'Bestandteil Beratungsgespärch (ABP / eKomi)',
			},
			{
				key: 'einbindungaufhomepageekomi',
				title: 'Einbindung auf Homepage eKomi',
			},
			{
				key: 'erreichunghoehererstufenekomi',
				title: 'Erreichung höherer Stufen',
			},
			{
				key: 'sicherstellungvonmind50bewertungen',
				title: 'Sicherstellung von mindestens 50 Bewertungen p.a.',
			},
		],
	},
	{
		key: 'telefoniekvm',
		title: 'Telefonie KVM',
		children: [
			{
				key: 'anmeldungivr',
				title: 'Anmeldung inkl. IVR',
			},
			{
				key: 'auswahlkundenoutbound',
				title: 'Auswahl Potentialkunden für Outbound',
			},
			{
				key: 'festlegungzeitenfuerkvmrufumleitung',
				title: 'Festlegung der Zeiten für KVM-Rufumleitung',
			},
			{
				key: 'nutzungpassiverterminierung',
				title: 'Nutzung passiver Terminierung (Freischaltung Outlook)',
			},
			{
				key: 'besuchkvmhalle',
				title: 'Besuch KVM-Halle',
			},
			{
				key: 'bearbeitungsstatndardsrueckmeldungkvm',
				title: 'Bearbeitungsstandards bei Rückmeldung KVM',
			},
		],
	},
];

export default values;
