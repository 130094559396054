import { observer } from 'mobx-react';

export enum TagColors {
	primary = 'primary',
	green = 'green',
	yellow = 'yellow',
	purple = 'purple',
	red = 'red',
	turquoise = 'turquoise',
}

interface IDottedTag {
	text?: string;
	color?: TagColors;
	modifier?: string;
}

export const DottedTag = observer((props: IDottedTag) => {
	const { color, text, modifier } = props;

	return (
		<div className={`dotted-tag is-${color || 'default'} ${modifier || ''}`}>
			<span className="dot"></span>
			{text && <span className="text">{text}</span>}
		</div>
	);
});
