import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { AgenturModel } from 'app/models/agentur.model';
import { autorun, runInAction } from 'mobx';
import React, { useEffect, useMemo, useState } from 'react';
import { Button } from '../common/Button';
import { Modal } from '../Modal';
import { Table } from '../table/table';
import { TableModel, TableRow } from '../table/table.model';
import { Icon } from 'app/components/common/Icon';
import { InputControl } from '../common/InputControl';
import FormField from '../form/FormField';

interface IAgtSelectorProps {
	selectedAgt?: AgenturModel;
	onSelect: (agt?: AgenturModel) => void;
	defaultText?: string;
}

export const AgtSelector = observer((props: IAgtSelectorProps) => {
	const { uiStore, session } = useStore();
	const [agt, setAgt] = useState<AgenturModel | undefined>();
	const defaultText = props.defaultText ? props.defaultText : session.currentUser!.steart.name;
	const [text, setText] = useState<string>('');

	useEffect(() => {
		setAgt(props.selectedAgt);
		if (agt) {
			setText(`${agt.name} (${agt.agtId})`);
		} else {
			setText(defaultText);
		}
	}, [text, agt, props.selectedAgt, defaultText]);
	const modalId = uiStore.modalIds.agtSelect;

	const selectAgt = () => {
		runInAction(() => {
			uiStore.showModal(modalId);
		});
	};
	const onReset = () => {
		runInAction(() => {
			uiStore.hideModal(modalId);
			setAgt(undefined);
			props.onSelect(undefined);
		});
	};

	const onSelect = (agt?: AgenturModel) => {
		runInAction(() => {
			uiStore.hideModal(modalId);
			setAgt(agt);
			props.onSelect(agt);
		});
	};

	return (
		<>
			<div className={`agt-selector  ${agt ? 'is-active' : ''}`}>
				<Button className={`button is-agt-selector`} onClick={selectAgt}>
					<span className="agt-name">{text}</span>
					<Icon iconClass="caret-down" />
				</Button>
				{agt && (
					<Button className="button is-agt-select-reset" onClick={onReset}>
						<Icon iconClass="times" />
					</Button>
				)}
			</div>

			<Modal modifier="is-paddingless is-full-height" title="Agenturauswahl" modalId={modalId}>
				<AgtSelectorList onSelect={onSelect} />
			</Modal>
		</>
	);
});

export const AgtSelectorList = observer((props: IAgtSelectorProps) => {
	const { agenturStore } = useStore();

	const tm = useMemo(() => {
		const tm = new TableModel<AgenturModel, number>();
		const cols = [
			{
				label: 'Agentur',
				path: 'name',
			},
			{
				label: 'VTNR',
				path: 'agtId',
			},
			{
				label: 'GP',
				path: 'gpShort',
				render: (row: TableRow<AgenturModel>) => {
					return <>{row.data.gpShort}</>;
				},
			},
		];
		tm.showHeader = false;
		tm.setCols(cols);
		tm.sortBy = 'name';
		tm.idProperty = 'agtId';
		tm.idType = 'number';

		tm.onRowClick = (row: TableRow<AgenturModel>) => {
			props.onSelect(row.data);
		};

		return tm;
	}, [props]);

	const [agtfilterText, setAgtfilterText] = useState('');
	const onTextChange = (name: string, val: string) => {
		setAgtfilterText(val);
	};

	useEffect(() => {
		const allAgt = agenturStore.agenturenWithoutAda;
		const filteredAgt = agtfilterText.length >= 3 ? allAgt.filter((agt) => agt.name.toLowerCase().includes(agtfilterText.toLowerCase())) : allAgt;

		tm.setRowData(filteredAgt);
	}, [agenturStore.agenturenWithoutAda, agtfilterText, tm]);

	useEffect(() => {
		return autorun(() => {
			if (props.selectedAgt) {
				tm.setSelectedId(props.selectedAgt.agtId);
			} else {
				tm.setSelectedId(undefined);
			}
		});
	});

	return (
		<div className="agt-selector-list">
			<div className="filter">
				<FormField className={'is-inline'} label="Freitext">
					<InputControl name="fullText" value={agtfilterText} onChange={onTextChange} debounceTime={250} placeholder={'Suchen'} icon="search" />
				</FormField>
			</div>
			<div className="list">
				<Table tm={tm}></Table>
			</div>
		</div>
	);
});
