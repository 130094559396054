import { SegmentName } from 'app/components/anwendungen/segment/SegmentTag';
import { Icon } from 'app/components/common/Icon';
import { ITableColumn, TableRow } from 'app/components/table/table.model';
import { AgenturExtendedVM } from 'app/stores/ui/agt.list.ui.store';
import moment from 'moment';
import { SteartModel } from '../core/steart.model';
import { AgtType } from '../agentur.model';
import { observer } from 'mobx-react';
import Config from 'Config';

const year = moment().year();

export const getAgtListCols = (steart: SteartModel): ITableColumn<AgenturExtendedVM, number>[] => {
	let ret = VblAgenturListCols;
	if (steart.isLeiter || steart.isDVSAdmin) {
		ret = LeiterAgenturListCols;
	}
	if (steart.isMulti) {
		ret = MultiAgenturListCols;
	}
	if (steart.isSpezi) {
		ret = SpeziAgenturListCols;
	}
	if (steart.name === 'EDP') {
		// remove EDP column for EPDs
		ret = MultiAgenturListCols.filter((c) => c.label !== 'EDP');
	}
	if (steart.id === 123) {
		ret = baseAgtCols;
	}

	return ret;
};

export interface IGpText {
	vm: AgenturExtendedVM;
}

export const GpShort = observer((props: IGpText) => {
	const vm = props.vm;
	return (
		<>
			{vm.agtType === AgtType.agt && vm.gpShort}
			{vm.agtType === AgtType.ada && <span className="tag is-small is-green is-marginless">ADA</span>}
		</>
	);
});

export const baseAgtCols: ITableColumn<AgenturExtendedVM, number>[] = [
	{
		label: 'Agentur',
		path: 'name',
		render: (row: TableRow<AgenturExtendedVM>) => {
			return (
				<>
					{row.data.shouldBeTemp && (
						<span>
							<Icon iconClass={'drafting-compass'} faModifier="fal" />{' '}
						</span>
					)}
					<span>{row.data.name}</span>
					{!row.data.visTeilnahme && Config.showVisTeilnahme && (
						<span data-tooltip="Keine VIS-Teilnahme" data-tooltip-size="small">
							{' '}
							<Icon iconClass={'exclamation-circle'} faModifier="far color-red" />
						</span>
					)}
				</>
			);
		},
	},
	{
		label: 'GP',
		path: 'gpShort',
		render: (row: TableRow<AgenturExtendedVM>) => {
			return <GpShort vm={row.data} />;
		},
	},
	{
		label: 'VTNR',
		path: 'agtId',
	},
];

export const VblAgenturListCols: ITableColumn<AgenturExtendedVM, number>[] = [
	...baseAgtCols,
	{
		label: 'JPG ' + year,
		path: 'jpgDone',
		render: (row: TableRow<AgenturExtendedVM>) => {
			return <>{row.data.jpgDone && <Icon iconClass={'check'} />}</>;
		},
	},
	{
		label: 'PT ' + year,
		path: 'apDone',
		render: (row: TableRow<AgenturExtendedVM>) => {
			return <>{row.data.apDone && <Icon iconClass={'check'} />}</>;
		},
	},
	{
		label: 'BP ' + year,
		path: 'bp.myCountWithValues',
		render: (row: TableRow<AgenturExtendedVM>) => {
			if (!row.data.bp) {
				return <></>;
			}
			const bp = row.data.bp;
			return (
				<>
					{bp.myCountWithValues} / {bp.myPoses.length}
				</>
			);
		},
	},
	{
		label: `BP Spezi ` + year,
		path: 'bp.otherCountWithValues',
		render: (row: TableRow<AgenturExtendedVM>) => {
			if (!row.data.bp) {
				return <></>;
			}
			const bp = row.data.bp;
			return (
				<>
					{bp.otherCountWithValues} / {bp.otherPoses.length}
				</>
			);
		},
	},
	{
		label: 'Schwerpunkt ' + year,
		path: 'vdId',
		sortBy: 'schwerpunkt.firstSelected',
		render: (row: TableRow<AgenturExtendedVM>) => {
			if (!row.data.schwerpunkt) {
				return <></>;
			}
			const sp = row.data.schwerpunkt;

			return (
				<div className="agt-list-schwerpunkt">
					{sp.selectedRolesAsList.map((r) => (
						<span className={`tag color-${r}`} key={r}>
							{r}
						</span>
					))}
				</div>
			);
		},
	},
	{
		label: 'Segmentierung ' + year,
		path: 'segment',
		render: (row: TableRow<AgenturExtendedVM>) => {
			return <SegmentName agt={row.data} />;
		},
	},
];

export const SpeziAgenturListCols = VblAgenturListCols.map((c) => {
	// change label for Spezis
	if (c.path === 'bp.otherCountWithValues') {
		c.label = `BP VBL ` + year;
	}
	return c;
});

export const LeiterAgenturListCols: ITableColumn<AgenturExtendedVM, number>[] = [
	...baseAgtCols,
	{
		label: 'Schwerpunkt ' + year,
		path: 'vdId',
		sortBy: 'schwerpunkt.firstSelected',
		render: (row: TableRow<AgenturExtendedVM>) => {
			if (!row.data.schwerpunkt) {
				return <></>;
			}
			const sp = row.data.schwerpunkt;

			return (
				<div className="agt-list-schwerpunkt">
					{sp.selectedRolesAsList.map((r) => (
						<span className={`tag color-${r}`} key={r}>
							{r}
						</span>
					))}
				</div>
			);
		},
	},
	{
		label: 'Segmentierung ' + year,
		path: 'segment',
		render: (row: TableRow<AgenturExtendedVM>) => {
			return <SegmentName agt={row.data} />;
		},
	},
];
export const MultiAgenturListCols: ITableColumn<AgenturExtendedVM, number>[] = [
	...baseAgtCols,
	{
		label: 'Bürotyp',
		path: 'buerotyp',
	},
	{
		label: 'Themen JPG',
		path: 'jahresthemen',
		sortBy: 'jahresthemenSort',

		render: (row: TableRow<AgenturExtendedVM>) => {
			const th = row.data.jahresthemen;
			if (!th) {
				return <></>;
			}
			return (
				<>
					{th.themen.map((t) => (
						<div key={t.key}>{t.label}</div>
					))}
				</>
			);
		},
	},
	{
		label: 'EDP',
		path: 'users',
		render: (row: TableRow<AgenturExtendedVM>) => {
			const edp = row.data.users?.filter((u) => u.steart.name === 'EDP');
			return (
				<>
					{edp.map((r, i) => (
						<span key={r.bensl + i}>{r.name}</span>
					))}
				</>
			);
		},
	},

	{
		label: 'Schwerpunkt ' + year,
		path: 'vdId',
		sortBy: 'schwerpunkt.firstSelected',
		render: (row: TableRow<AgenturExtendedVM>) => {
			if (!row.data.schwerpunkt) {
				return <></>;
			}
			const sp = row.data.schwerpunkt;

			return (
				<div className="agt-list-schwerpunkt">
					{sp.selectedRolesAsList.map((r) => (
						<span className={`tag color-${r}`} key={r}>
							{r}
						</span>
					))}
				</div>
			);
		},
	},
	{
		label: 'Mietmodell',
		path: 'mietmodell',
	},
	{
		label: 'Steart',
		path: 'steartNameShortOrLongOrId',
	},
	{
		label: 'Geräte',
		path: 'anzgeraete',
	},
	{
		label: 'VTNR Typ',
		path: 'vtnrType',
	},
];
