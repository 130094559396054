import { TableModel, ITableColumn, AggregrationType, TableRow } from 'app/components/table/table.model';
import { NumberInput, Table } from 'app/components/table/table';
import { useStore } from 'app/context';
import { AgtBpVM } from 'app/stores/ui/agt.branchenplanung.ui.store';
import { formatNum, formatDelta } from 'app/utils';
import { observer } from 'mobx-react';
import _ from 'lodash';
import * as Yup from 'yup';
import { useMemo, useEffect, useState } from 'react';
import { AgenturNameCell } from 'app/components/shared/AgenturName.cell';
import { SteartTag } from 'app/components/agt/common/SteartTag';

const defaultFormType = Yup.number()
	.transform((value, originalValue) => {
		return Number.isNaN(value) ? 0 : value;
	})
	.nullable()
	.default(0)
	.typeError('Nur numerische Werte sind erlaubt');

export const bpBaseSchema = Yup.object().shape({
	gesamtBewertung: defaultFormType,
	bestandsZuwachs: defaultFormType,
	leben: defaultFormType,
	fonds: defaultFormType,
	kranken: defaultFormType,
	baufi: defaultFormType,
	bauspar: defaultFormType,
	psachOhneKraftUnfall: defaultFormType,
	kfzHaftPkw: defaultFormType,
	unfall: defaultFormType,
	firmenSachOhneKraft: defaultFormType,
});

export const bpPlanungSchema = Yup.object().shape({
	eigeneZiele: Yup.object().shape({
		gesamtBewertung: defaultFormType,
		bestandsZuwachs: defaultFormType,
		leben: defaultFormType,
		fonds: defaultFormType,
		kranken: defaultFormType,
		baufi: defaultFormType,
		bauspar: defaultFormType,
		psachOhneKraftUnfall: defaultFormType,
		kfzHaftPkw: defaultFormType,
		unfall: defaultFormType,
		firmenSachOhneKraft: defaultFormType,
	}),
	data: Yup.array().of(
		Yup.object().shape({
			gesamtBewertung: defaultFormType,
			bestandsZuwachs: defaultFormType,
			leben: defaultFormType,
			fonds: defaultFormType,
			kranken: defaultFormType,
			baufi: defaultFormType,
			bauspar: defaultFormType,
			psachOhneKraftUnfall: defaultFormType,
			kfzHaftPkw: defaultFormType,
			unfall: defaultFormType,
			firmenSachOhneKraft: defaultFormType,
		}),
	),
});

interface IMeinZielWertField {
	tm: TableModel<AgtBpVM, number>;
	planKey: string;
}

const MeinZielWertAggColumn = observer((props: IMeinZielWertField) => {
	const { tm, planKey } = props;
	let delta;
	const currVal = _.get(tm.extraData, planKey);
	const currColl = tm.columns.find((c) => c.path === props.planKey);
	if (currColl) {
		const ag = currColl.aggs!.find((a) => a.aggType === AggregrationType.sum);
		if (ag) {
			const sum = ag.aggValue;
			if (sum && currVal && planKey !== 'bestandsZuwachs') {
				delta = sum - currVal;
			}
		}
	}

	if (tm.editMode) {
		return (
			<>
				<span>Mein Zielwert:</span>
				<div className="grid is-col-auto align-center justify-start">
					<span>
						<NumberInput {...props} basePath={'extra'} path={planKey} targetThing={tm.extraData} />
					</span>
					{delta && (
						<>
							<span className={`tag is-marginless ${delta < 0 ? 'is-red' : 'is-green'}`}>Delta {formatDelta(delta)}</span>
						</>
					)}
				</div>
			</>
		);
	}

	return (
		<>
			<div className="grid is-col-auto align-center justify-start">
				<span>Mein Zielwert: {currVal ? formatNum(currVal) : '-'}</span>
				{delta ? (
					<span className={`tag is-small is-marginless ${delta < 0 ? 'is-red' : 'is-green'}`}>Delta {formatDelta(delta)}</span>
				) : (
					<span className={`tag is-small is-marginless`}>Delta -</span>
				)}
			</div>
		</>
	);
});

export const BranchenplanungList = observer(() => {
	const { agtBranchenplanungUiStore, anwendungenUiStore, session, agenturListUiStore } = useStore();
	const [loaded, setLoadad] = useState<boolean>(false);
	// const [showButtons, setShowButtons] = useState<boolean>();

	const [isGsl] = useState<boolean>(session.currentUser!.isGsl);

	const steart = session.currentUser!.steart;
	const planKey = anwendungenUiStore.currentBpPlanKey;
	const year = anwendungenUiStore.currentBpYear;
	const bpPos = anwendungenUiStore.currentBpPos;

	const tm = useMemo(() => {
		const tm = new TableModel<AgtBpVM, number>();
		if (!bpPos) {
			return tm;
		}

		const cols: ITableColumn<AgtBpVM, number>[] = [
			{
				label: 'Agentur',
				path: 'agt.name',
				sortBy: 'agt.name',
				render: AgenturNameCell,
			},
		];
		let aggIf = (val: any) => {
			return val >= 0;
		};

		if (bpPos.planKey === 'bestandsZuwachs') {
			aggIf = (val: any) => {
				return val >= 5;
			};
		}

		cols.push({
			label: ['Meine Planung', bpPos.posText],
			path: bpPos.prop,
			format: formatNum,
			editable: true,
			agg: [
				{
					path: bpPos.prop,
					format: formatNum,
					aggType: AggregrationType.sum,
					aggIf,
					visible: bpPos.planKey === 'bestandsZuwachs' ? false : true,
				},
				{
					path: bpPos.prop,
					format: formatNum,
					aggType: AggregrationType.avg,
					aggIf,
					visible: bpPos.planKey === 'bestandsZuwachs' ? false : true,
				},
				{
					path: bpPos.prop,
					format: formatNum,
					aggType: AggregrationType.count,
					aggIf,
				},
				{
					path: 'agtId',
					render: (tm) => {
						return <MeinZielWertAggColumn tm={tm} planKey={planKey} />;
					},
				},
			],
		});

		if (isGsl) {
			const path = steart.name.toUpperCase() + '.' + planKey;
			const pathSteart = steart.name.toUpperCase() + '.' + planKey + '_steart';
			cols.push({
				label: ['Planung', 'GS*'],
				path: [path, pathSteart],
				format: formatNum,
				render: (row: TableRow<AgtBpVM>) => {
					const val = _.get(row.data, path);
					const steart = _.get(row.data, pathSteart);
					return (
						<>
							{formatNum(val)} <SteartTag id={steart} />
						</>
					);
				},
				agg: [
					{
						path,
						format: formatNum,
						aggType: AggregrationType.sum,
						aggIf,
						visible: bpPos.planKey === 'bestandsZuwachs' ? false : true,
					},
					{
						path,
						format: formatNum,
						aggType: AggregrationType.avg,
						aggIf,
						visible: bpPos.planKey === 'bestandsZuwachs' ? false : true,
					},
					{
						path,
						format: formatNum,
						aggType: AggregrationType.count,
						aggIf,
					},
				],
			});
		}
		bpPos.getOtherVisibleStearts(steart.steart).forEach((otherSteart) => {
			const path = otherSteart.name.toUpperCase() + '.' + planKey;

			cols.push({
				label: ['Planung', otherSteart.name],
				path,
				format: formatNum,
				agg: [
					{
						path,
						format: formatNum,
						aggType: AggregrationType.sum,
						aggIf,
						visible: bpPos.planKey === 'bestandsZuwachs' ? false : true,
					},
					{
						path,
						format: formatNum,
						aggType: AggregrationType.avg,
						aggIf,
						visible: bpPos.planKey === 'bestandsZuwachs' ? false : true,
					},
					{
						path,
						format: formatNum,
						aggType: AggregrationType.count,
						aggIf,
					},
				],
			});
		});

		if (bpPos.ringPosDef || bpPos.planKey === 'gesamtBewertung') {
			let labelPre = 'Ring';
			if (bpPos.planKey === 'gesamtBewertung') {
				labelPre = 'GP';
			}

			cols.push({
				label: [labelPre, 'Zielwert'],
				path: 'ringZiel.' + bpPos.prop,
				format: formatNum,
				agg: [
					{
						path: 'ringZiel.' + bpPos.prop,
						format: formatNum,
						aggType: AggregrationType.sum,
						aggIf,
						visible: bpPos.planKey === 'bestandsZuwachs' ? false : true,
					},
					{
						path: 'ringZiel.' + bpPos.prop,
						format: formatNum,
						aggType: AggregrationType.avg,
						aggIf,
						visible: bpPos.planKey === 'bestandsZuwachs' ? false : true,
					},
					{
						path: 'ringZiel.' + bpPos.prop,
						format: formatNum,
						aggType: AggregrationType.count,
						aggIf,
					},
				],
			});
			cols.push({
				label: [labelPre, 'Ergebnis '],
				path: 'ringErgebnis.' + bpPos.prop,
				format: formatNum,
				agg: [
					{
						path: 'ringErgebnis.' + bpPos.prop,
						format: formatNum,
						aggType: AggregrationType.sum,
						aggIf,
						visible: bpPos.planKey === 'bestandsZuwachs' ? false : true,
					},
					{
						path: 'ringErgebnis.' + bpPos.prop,
						format: formatNum,
						aggType: AggregrationType.avg,
						aggIf,
						visible: bpPos.planKey === 'bestandsZuwachs' ? false : true,
					},
					{
						path: 'ringErgebnis.' + bpPos.prop,
						format: formatNum,
						aggType: AggregrationType.count,
						aggIf,
					},
				],
			});
		}

		tm.sortBy = 'agt.name';
		tm.setCols(cols);
		tm.idProperty = 'agtId';
		tm.idType = 'number';
		return tm;
	}, [bpPos, isGsl, steart.steart, steart.name, planKey]);

	// const onFak = useCallback(() => {
	// 	const fak = agtBranchenplanungUiStore.currentUmrechnungsFaktor;
	// 	tm.data.forEach((d) => d.setUmrechFaktor(fak));
	// 	tm.extraData._setUmrechFaktor(fak);
	// }, [agtBranchenplanungUiStore, tm]);

	useEffect(() => {
		if (!bpPos) {
			return;
		}
		agtBranchenplanungUiStore.findByYear(year).then((res) => {
			res = agenturListUiStore.applyFilterOnAgt(res) as AgtBpVM[];
			if (anwendungenUiStore.selectedAgtId) {
				const agtId = anwendungenUiStore.selectedAgtId;
				res = res.filter((d) => d.agtId === agtId);
			}
			agtBranchenplanungUiStore.getEigenePlanungByYear(year).then((extra) => {
				// careful. extra needs to be set before data..
				tm.setExtraData(extra);
				// res.forEach((bp) => bp.setUmrechFaktor(agtBranchenplanungUiStore.currentUmrechnungsFaktor));
				tm.setRowData(res);
				// onFak();
				setLoadad(true);
			});
			// setShowButtons(bpPos.isConvertible);
		});
	}, [tm, year, setLoadad, agtBranchenplanungUiStore, anwendungenUiStore.selectedAgtId, bpPos, agenturListUiStore.filterHasChanged, agenturListUiStore]);

	const onSave = async () => {
		await agtBranchenplanungUiStore.saveMany(tm.data);
		await agtBranchenplanungUiStore.saveEigenePlanung(tm.extraData, year, planKey);
	};

	return (
		<>
			{/* {showButtons && (
				<div className="panel-body-header">
					<BpUmrechnungsToggle onSelect={onFak} />
				</div>
			)} */}

			{loaded && (
				<>
					{isGsl && (
						<div className="note-box align-center content has-margin">
							* Planung GS: Es wird der erste vorhandene Planwert von den folgenden Betreuern übernommen: 1. GSL (Meine Planung) 2. VBL 3. LPV/LSV
						</div>
					)}
					<Table stickyHeader={true} stickyFooter={true} tm={tm} schema={bpPlanungSchema} onSubmit={onSave} />
				</>
			)}
		</>
	);
});
