import BaseContainer from 'app/containers/BaseContainer';
import { observer } from 'mobx-react';
import { MainMenu } from './MainMenu';
import { RouteComponentProps } from 'react-router-dom';
import { DocumentList } from 'app/components/documents/DocumentList';
import { DocumentSendModal } from 'app/components/documents/DocumentSendModal';
import { DocumentViewerModal } from 'app/components/documents/DocumentViewerModal';
import { DocumentShareModal } from 'app/components/documents/DocumentShareModal';
import { DocumentFilter } from 'app/components/documents/DocumentsFilter';
import { useStore } from 'app/context';
import React, { useEffect } from 'react';
import { runInAction } from 'mobx';
import { TemplateSelectorModal } from 'app/components/documents/TemplateSelectorModal';
import { TemplateVM } from 'app/stores/ui/template.ui.store';
import { DokumentUploadButton } from 'app/components/documents/DokumentUploadButton';
import qs from 'qs';

const DokumenteContainer = observer((props: RouteComponentProps) => {
	const query = qs.parse(props.location.search, { ignoreQueryPrefix: true });

	const { documentUiStore, uiStore } = useStore();
	useEffect(() => {
		runInAction(() => {
			documentUiStore.currentFilter.reset();
			if (query.onlySharedForMe === 'true') {
				documentUiStore.currentFilter.onlySharedForMe = true;
			}
		});
	}, [documentUiStore, documentUiStore.currentFilter, query.onlySharedForMe]);

	const addDok = async (template: TemplateVM) => {
		const vm = await documentUiStore.addDokumentToPlan(template);
		documentUiStore.setCurrentModalDoc(vm);
		uiStore.showModal(uiStore.modalIds.documentViewModal);
	};

	return (
		<BaseContainer sideMenu={<MainMenu {...props} />} navbar={<div>Dokumente</div>}>
			<div className="pad-1rem grid is-col-half">
				<div className="is-left">
					<DocumentFilter />
				</div>
				<div className="is-right">
					<DokumentUploadButton isButton={true} />
					<TemplateSelectorModal onSelect={addDok} />
				</div>
			</div>
			<DocumentList />
			<DocumentShareModal />
			<DocumentSendModal />
			<DocumentViewerModal showAgt={true} showPlan={true} />
		</BaseContainer>
	);
});

export default DokumenteContainer;
