import Widget, { IWidgetConsumer } from 'app/components/common/Widget';
import { observer } from 'mobx-react';
import { useStore } from 'app/context';
import { SteartTag } from 'app/components/agt/common/SteartTag';

export const TeamMemberWidget = observer((props: IWidgetConsumer) => {
	const { teamUiStore } = useStore();
	const member = teamUiStore.current;

	if (!member) {
		return <></>;
	}

	return (
		<Widget title={'Details'} modifier={'is-paddingless'} widgetId={props.widgetKey}>
			<div className="team-member-widget">
				<table className="table is-text">
					<tbody>
						<tr>
							<td>
								<strong>{member.name}</strong>
							</td>
							<td>
								<SteartTag id={member.steart.id} />
							</td>
						</tr>
						{member.user.email && (
							<tr>
								<td>
									<strong>E-Mail:</strong>
								</td>
								<td>
									<a className="button is-text" href={`mailto:${member.user.email}`}>
										{member.user.email}
									</a>
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>
		</Widget>
	);
});
