import { Button } from 'app/components/common/Button';
import { CheckboxControl } from 'app/components/common/CheckboxControl';
import { Icon } from 'app/components/common/Icon';
import { InputControl } from 'app/components/common/InputControl';
import { Modal } from 'app/components/Modal';
import { Table } from 'app/components/table/table';
import { ITableColumn, TableModel, TableRow } from 'app/components/table/table.model';
import { useStore } from 'app/context';
import { UserModel } from 'app/models/core/user.model';
import { BnrDiffVM } from 'app/stores/ui/admin.bnr.diff.ui.store';
import { UserVM } from 'app/stores/ui/admin.user.ui.store';
import { formatDateLong } from 'app/utils';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import routes from 'routes';
import Panel from '../../Panel';
import { AdminUserHistoryList } from './AdminUserHistoryList';
export const AdminBnrDiffDetail = observer(() => {
	const { adminBnrDiffUiStore } = useStore();
	const bnrDiff = adminBnrDiffUiStore.current;
	const history = useHistory();

	const onClose = () => {
		runInAction(() => {
			adminBnrDiffUiStore.current = undefined;
			history.push(routes.ADMINBNRDIFF.getPath());
		});
	};

	return (
		<>
			{bnrDiff && (
				<Panel title={bnrDiff.diff.bnrId.toString()} subtitle={''} header={<> </>} onClose={onClose}>
					<table className="table">
						<tbody>
							<tr>
								<td>Änderung</td>
								<td className="full-width">
									<strong>{bnrDiff.diff.diff}</strong>
									{bnrDiff.diff.fromSteart && <s>{bnrDiff.diff.fromSteart.name}</s>}
									{bnrDiff.diff.toSteart && <> {bnrDiff.diff.toSteart.name}</>}
									<br />
									{bnrDiff.diff.fromName && <s>{bnrDiff.diff.fromName}</s>}
									{bnrDiff.diff.toName && <> {bnrDiff.diff.toName}</>}
								</td>
							</tr>
							{bnrDiff.user && (
								<tr>
									<td>Benutzer</td>
									<td className="full-width">
										Created: Date: {formatDateLong(bnrDiff.user.user.created)}
										<br />
										{bnrDiff.user.user.email} {bnrDiff.user.user.bensl} [{bnrDiff.user.user.steartText}]<br />
										Status: {bnrDiff.user.user.statusText}
										<br />
										AnzahlAgts: {bnrDiff.user.user.agtCount}
										<br />
										<br />
										<pre>{bnrDiff.user.user.comment}</pre>
									</td>
								</tr>
							)}
							{bnrDiff.user && bnrDiff.user.user.history.length > 0 && (
								<tr>
									<td>Historie</td>
									<td className="full-width">
										<AdminUserHistoryList user={bnrDiff.user.user} />
									</td>
								</tr>
							)}

							{bnrDiff && (
								<tr>
									<td colSpan={2}>
										<PossibleMatchingUsers user={bnrDiff} />
										<BnrDetailUsersList />
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</Panel>
			)}
		</>
	);
});

interface IPossibleMatchingUsers {
	user: BnrDiffVM;
}

export const PossibleMatchingUsers = observer((props: IPossibleMatchingUsers) => {
	const { adminBnrDiffUiStore } = useStore();

	const onTextChange = (name: string, value: string) => {
		adminBnrDiffUiStore.currentPossibleUserName = value;
	};

	useEffect(() => {
		runInAction(() => {
			if (adminBnrDiffUiStore.currentNameToSearchFor) {
				adminBnrDiffUiStore.currentPossibleUserName = adminBnrDiffUiStore.currentNameToSearchFor;
			} else {
				adminBnrDiffUiStore.currentPossibleUserName = '';
			}
		});
	}, [adminBnrDiffUiStore]);

	return (
		<>
			<InputControl
				inline={true}
				label="Schnellsuche nach existierendem Benutzer"
				name="fullText"
				value={adminBnrDiffUiStore.currentPossibleUserName}
				onChange={onTextChange}
				debounceTime={500}
				placeholder={'Textsuche: Name, Bensl, BnrId'}
			/>
		</>
	);
});

const BnrDetailUsersList = observer(() => {
	const { adminBnrDiffUiStore, uiStore } = useStore();
	const history = useHistory();

	const tm = useMemo(() => {
		const tm = new TableModel<UserVM, number>();
		let cols: ITableColumn<UserVM, number>[] = [
			{
				label: 'Bensl',
				path: 'user.bensl',
			},
			{
				label: 'Name',
				path: 'user.name',
			},
			{
				label: 'BnrId',
				path: 'user.bnrId',
				render: (row: TableRow<UserVM>) => {
					return (
						<>
							{adminBnrDiffUiStore.currentBnrId === row.data.user.bnrId && (
								<>
									<Icon iconClass={'check'} />
									<strong>{row.data.user.bnrId}</strong>
								</>
							)}
							{adminBnrDiffUiStore.currentBnrId !== row.data.user.bnrId && <>{row.data.user.bnrId}</>}
						</>
					);
				},
			},
			{
				label: 'Email',
				path: 'user.email',
			},
			{
				label: 'Status',
				path: 'user.statusText',
			},
			{
				label: 'VD',
				path: 'user.firstVd',
			},
			{
				label: 'GS',
				path: 'user.firstGs',
			},
			{
				label: 'Steart',
				path: 'user.steartText',
			},

			{
				label: 'Letzte Einladung Vor Tagen',
				path: 'user.lastInviteDaysAgo',
			},
			{
				label: 'AnzAgt',
				path: 'user.agtCount',
			},
			{
				label: 'Actions',
				path: 'user.id',
				render: (row: TableRow<UserVM>) => {
					return (
						<>
							{adminBnrDiffUiStore.canUpdateToBnrId(row.data.user.bnrId) && (
								<>
									<Button onClick={onButtonClick}>BnrId diesem Account zuweisen</Button>

									<UpdateBnrIdModal
										user={row.data.user}
										diff={adminBnrDiffUiStore.current!}
										onClose={() => uiStore.hideModal(uiStore.modalIds.updateBnrId)}
										onUpdate={onBnrIdUpdate}
									/>
								</>
							)}
						</>
					);
				},
			},
		];
		const onButtonClick = () => {
			uiStore.showModal(uiStore.modalIds.updateBnrId);
		};
		const onBnrIdUpdate = () => {
			alert('updated');
		};

		// tm.onRowClick = (row: TableRow<UserVM>) => {
		// 	//viewDoc(row.data);
		// };

		tm.setCols(cols);
		tm.sortBy = 'user.lastLoginDate';
		tm.sortAsc = false;
		tm.idProperty = 'id';
		tm.idType = 'string';
		return tm;
	}, [adminBnrDiffUiStore, uiStore]);

	useEffect(() => {
		adminBnrDiffUiStore.adminUserUiStore.findAll();
		tm.setRowData(adminBnrDiffUiStore.possibleMatchingUsers);
	}, [adminBnrDiffUiStore.currentBnrId, adminBnrDiffUiStore.possibleMatchingUsers, adminBnrDiffUiStore.adminUserUiStore, adminBnrDiffUiStore.adminUserUiStore.currentItems, tm]);

	return (
		<>
			<Table stickyHeader={true} tm={tm}></Table>
		</>
	);
});

interface INeueBnrIdModal {
	diff: BnrDiffVM;
	user: UserModel;
	onClose: () => void;
	onUpdate: () => void;
}
const UpdateBnrIdModal = observer((props: INeueBnrIdModal) => {
	const { uiStore, adminUserUiStore, adminBnrDiffUiStore } = useStore();

	const [diff] = useState<BnrDiffVM>(props.diff);
	const [user] = useState<UserModel>(props.user);
	const [transferData, setTransferData] = useState<boolean>(true);

	const toggleTransfer = (name: string, value: boolean) => {
		setTransferData(value);
	};

	const updateBnrId = () => {
		const req = {
			oldBnrId: user.bnrId,
			newBnrId: diff.diff.bnrId,
			transferData: transferData,
		};

		adminUserUiStore.updateUserBnrId(req).then(() => {
			adminBnrDiffUiStore.checkKwsAndLoad().then(() => {
				props.onUpdate();
				props.onClose();
			});
		});
	};

	return (
		<Modal modalId={uiStore.modalIds.updateBnrId} title={'BnrId Ändern'} onClose={props.onClose}>
			<>
				Benutzer {user._name} [{user.bensl}] {user.email} <br />
				hat aktuelle die BnrId {user.bnrId}
				<br />
				<CheckboxControl label="Datentransfer durchführen" name="twoOnThre" value={transferData} onChange={toggleTransfer} />
				<Button onClick={updateBnrId}>Diesem Benutzer die BnrId {diff.diff.bnrId} zuweisen</Button>
			</>
		</Modal>
	);
});
