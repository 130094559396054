import { Table, TableAggInfo } from 'app/components/table/table';
import { TableModel, ITableColumn, AggregrationType } from 'app/components/table/table.model';
import { useStore } from 'app/context';
import { FilterBranchenPosDefBySteart } from 'app/models/branchenplanung/bp.pos.def';
import { formatNum } from 'app/utils';
import { AgtBpVM } from 'app/stores/ui/agt.branchenplanung.ui.store';
import { observer } from 'mobx-react';
import React, { useMemo, useEffect } from 'react';
import { AgtBranchenplanungEdit } from './AgtBranchenplanungEdit';
import { AgenturNameCell } from 'app/components/shared/AgenturName.cell';

export const BranchenplanungOverviewList = observer(() => {
	const { agtBranchenplanungUiStore, anwendungenUiStore, session, agenturListUiStore } = useStore();
	const s = anwendungenUiStore.menu.current!.path.split('/');
	const y = s[s.length - 2];
	const year = parseInt(y);
	const tm = useMemo(() => {
		const tm = new TableModel<AgtBpVM, number>();

		const cols: ITableColumn<AgtBpVM, number>[] = [
			{
				label: 'Agentur',
				path: 'agt.name',
				sortBy: 'agt.name',
				render: AgenturNameCell,
				width: 300,
			},
		];
		FilterBranchenPosDefBySteart(session.currentUser!.steart).forEach((bpPos) => {
			let path = bpPos.prop;
			if (session.currentUser!.isGsl) {
				path = 'GSL.' + path;
			}
			let aggIf = (val: any) => {
				return val > 0;
			};
			if (bpPos.planKey === 'bestandsZuwachs') {
				aggIf = (val: any) => {
					return val >= 5;
				};
			}

			cols.push({
				label: bpPos.posText,
				path: path,
				format: formatNum,
				agg: [
					{
						path: path,
						format: formatNum,
						aggType: AggregrationType.sum,
						aggIf,
						visible: bpPos.planKey === 'bestandsZuwachs' ? false : true,
					},
					{
						path: path,
						format: formatNum,
						aggType: AggregrationType.avg,
						aggIf,
						visible: bpPos.planKey === 'bestandsZuwachs' ? false : true,
					},
					{
						path: path,
						format: formatNum,
						aggType: AggregrationType.count,
						aggIf,
						aggInfo: bpPos.planKey === 'bestandsZuwachs' ? <TableAggInfo infoText="Anzahl Agenturen über Zielwert" /> : null,
					},
					// {
					// 	path: 'agtId',
					// 	render: (tm) => {
					// 		return <MeinZielWertAggColumn tm={tm} planKey={planKey} />;
					// 	},
					// },
				],
			});
		});
		tm.sortBy = 'agt.name';
		tm.setCols(cols);
		tm.idProperty = 'agtId';
		tm.idType = 'number';
		return tm;
	}, [session.currentUser]);

	useEffect(() => {
		agtBranchenplanungUiStore.findByYear(year).then((res) => {
			res = agenturListUiStore.applyFilterOnAgt(res) as AgtBpVM[];

			if (anwendungenUiStore.selectedAgt) {
				const agtId = anwendungenUiStore.selectedAgt.agtId;
				res = res.filter((d) => d.agtId === agtId);
			}
			tm.setRowData(res);
		});
	}, [tm, year, agtBranchenplanungUiStore, anwendungenUiStore.selectedAgt, agenturListUiStore.filterHasChanged, agenturListUiStore]);

	return (
		<>
			{anwendungenUiStore.selectedAgt && <AgtBranchenplanungEdit agt={anwendungenUiStore.selectedAgt} year={anwendungenUiStore.currentBpYear} showErgebnisse={true} />}

			{!anwendungenUiStore.selectedAgt && (
				<>
					<Table stickyHeader={true} stickyFooter={true} tm={tm}></Table>
				</>
			)}
		</>
	);
});
