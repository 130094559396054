import Widget, { IWidgetConsumer } from 'app/components/common/Widget';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';

export const AdaOverviewWidget = observer((props: IWidgetConsumer) => {
	const { agenturUiStore } = useStore();
	const agt = agenturUiStore.current;

	if (!agt) return null;

	return (
		<Widget title={agt.name} modifier="is-paddingless" widgetId={props.widgetKey}>
			<table className="table is-text">
				<tbody>
					<tr>
						<td>
							<strong>VTNR</strong>
						</td>
						<td className="has-text-left">{agt.agtId}</td>
					</tr>
					<tr>
						<td>
							<strong>Bürotyp</strong>
						</td>
						<td className="has-text-left">{agt.buerotyp}</td>
					</tr>
					<tr>
						<td>
							<strong>Mietmodell</strong>
						</td>
						<td className="has-text-left">{agt.mietmodell}</td>
					</tr>
					<tr>
						<td>
							<strong>Steart</strong>
						</td>
						<td className="has-text-left">
							<div className="yes-wrap">{agt.steart?._name}</div>
						</td>
					</tr>
					<tr>
						<td>
							<strong>Anzahl Geräte</strong>
						</td>
						<td className="has-text-left">{agt.anzgeraete}</td>
					</tr>
					<tr>
						<td>
							<strong>VTNR Typ</strong>
						</td>
						<td className="has-text-left">{agt.vtnrType}</td>
					</tr>
				</tbody>
			</table>
		</Widget>
	);
});
