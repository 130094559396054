import { useStore } from 'app/context';
import { AupFile } from 'app/stores/admin.imports.store';
import { ImportJobVM } from 'app/stores/ui/admin.imports.ui.store';
import routes from 'routes';
import { notify } from '../common/notify';
import { useHistory } from 'react-router';
import { formatDateLong, humanizeFileSize } from 'app/utils';
import { Button } from '../common/Button';
import { observer } from 'mobx-react';

interface IAdminImportFiles {
	files: AupFile[];
	job: ImportJobVM;
}
export const AdminImportFiles = observer((props: IAdminImportFiles) => {
	const { adminImportsUiStore } = useStore();
	const history = useHistory();

	const handleSubmit = (fileName: string) => {
		if (!window.confirm(`Soll der Import mit der Datei ${fileName} gestartet werden?`)) {
			return;
		}
		if (!props.job.job.meta) {
			props.job.job.meta = {};
		}
		props.job.job.meta.fileName = fileName;
		notify('Job wird gestartet', '', 'info');
		adminImportsUiStore.triggerJob(props.job.job).then((res: any) => {
			history.push(routes.ADMINIMPORTS.getPath('job', res.id));
		});
	};

	return (
		<div className="pad-top-1rem border-top">
			<strong>Hochgeladene Dateien: </strong>
			<br />
			{props.files.length === 0 && <>Keine Dateien hochgeladen </>}
			<table className={'table'}>
				<thead>
					<tr>
						<th>Name</th>
						<th>Größe</th>
						<th>Datum</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{props.files.map((f) => (
						<tr key={f.fileName}>
							<td>{f.fileName}</td>
							<td>{humanizeFileSize(f.fileSize)}</td>
							<td>{formatDateLong(f.fileDate)}</td>
							<th>
								<Button type="button" className={'button is-primary'} onClick={() => handleSubmit(f.fileName)}>
									Importieren
								</Button>
							</th>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
});
