const values = [
	{
		key: 'kundenwerben',
		title: 'Neu-& Bestandskundenansprache',
		children: [
			{
				key: 'facebookpost',
				title: 'Facebook-Post',
			},
			{
				key: 'homepagemodul',
				title: 'Homepage-Modul',
			},
			{
				key: 'ueberpruefungkontaktdaten',
				title: 'Überprüfung Kontaktdaten (Digi-ID: Mail+Mobil)',
			},
			{
				key: 'wewedewe',
				title: 'Vorraussetzungen wEWE, dEWE',
			},
			{
				key: 'kundenwerben_leadnow',
				title: 'Lead.NOW',
			},
			{
				key: 'auslageinagt',
				title: 'Auslage in Agentur',
			},
			{
				key: 'heymoney',
				title: 'Hey Money',
			},
		],
		links: [
			{
				title: 'ONEPAGER Facebook',
				pdf: 'dva_onepager/dva_facebook.pdf',
			},
			{
				title: 'ONEPAGER Vertreter Homepage',
				pdf: 'dva_onepager/dva_vt_hompage.pdf',
			},
		],
	},

	{
		key: 'leadmanagement',
		title: 'Anlass- und Leadmanagement',
		children: [
			{
				key: 'leadnow',
				title: 'Lead.NOW',
			},
			{
				key: 'bearbeitungsstatndardszustaendigkeit',
				title: 'Zuständigkeit in der Bearbeitung von Anlässen & Aufgaben',
			},
			{
				key: 'einrichtungamisnow',
				title: 'Einrichtung AMIS.NOW',
			},
			{
				key: 'amisnowselbstverpflichtung',
				title: 'Selbstverpflichtung zum Einsatz von AMIS.NOW (Desktop & App)',
			},
			{
				key: 'lead_sea_smart_journey',
				title: 'SEA (Search Engine Advertising) und Lead.NOW',
			},
			{
				key: 'elpoko',
				title: 'elPoko',
			},
		],
	},

	{
		key: 'agenturauftritt',
		title: 'Agenturaufrtitt',
		children: [
			{
				key: 'aussenwerbung',
				title: 'Außenwerbung',
			},
			{
				key: 'moebilierung',
				title: 'Möbilierung / Raumaufteilung',
			},
			{
				key: 'dekoration',
				title: 'Dekoration',
			},
			{
				key: 'schaufenster',
				title: 'Schaufenster (Digital)',
			},
			{
				key: 'prospekteundgeschbenke',
				title: 'Prospekte und Werbegeschenke',
			},
			{
				key: 'digitalesallianzschaufenster',
				title: 'Digitales Allianz Schaufenster',
			},
			{
				key: 'ausstattungderagentur',
				title: '(technische) Ausstattung der Agentur',
			},
		],
	},

	{
		key: 'homepage',
		title: 'Homepage',
		children: [
			{
				key: 'aktualitaet',
				title: 'Aktualität',
			},
			{
				key: 'module',
				title: 'Module',
			},
			{
				key: 'neugikeiten-artikel',
				title: 'Neuigkeiten-Artikel',
			},
			{
				key: 'einbidunginsignatur',
				title: 'Einbindung in Signatur',
			},
			{
				key: 'whatsapp',
				title: 'WhatsApp Business',
			},
			{
				key: 'seo',
				title: 'SEO (Search Engine Optimization)',
			},
			{
				key: 'sea',
				title: 'SEA (Search Engine Advertising)',
			},
		],
		links: [
			{
				title: 'ONEPAGER Vertriebshomepage',
				pdf: 'dva_onepager/dva_vt_hompage.pdf',
			},
			{
				title: 'ONEPAGER Google SEO Packet',
				pdf: 'dva_onepager/dva_googleseo.pdf',
			},
			{
				title: 'ONEPAGER eSign',
				pdf: 'dva_onepager/dva_esign.pdf',
			},
			{
				title: 'ONEPAGER WhatsApp',
				pdf: 'dva_onepager/dva_whatsapp.pdf',
			},
			{
				title: 'ONEPAGER Online Werbebanner',
				pdf: 'dva_onepager/dva_onlinewerbebanner.pdf',
			},
		],
	},

	{
		key: 'socialmedia',
		title: 'Social Media',
		children: [
			{
				key: 'socialmediatoolbox',
				title: 'Social Media Toolbox',
			},
			{
				key: 'xing',
				title: 'Xing',
			},
			{
				key: 'linkedin',
				title: 'LinkedIn',
			},
			{
				key: 'whatsapp',
				title: 'WhatsApp Business',
			},
			{
				key: 'facebook',
				title: 'Facebook',
			},
			{
				key: 'insta',
				title: 'Instagram',
			},
			{
				key: 'pflegesocialmediaactivitaten',
				title: 'Pflege Social Media Aktivitäten',
			},
		],
		links: [
			{
				title: 'ONEPAGER WhatsApp',
				pdf: 'dva_onepager/dva_whatsapp.pdf',
			},
			{
				title: 'ONEPAGER XING',
				pdf: 'dva_onepager/dva_xing.pdf',
			},
			{
				title: 'ONEPAGER Facebook',
				pdf: 'dva_onepager/dva_facebook.pdf',
			},
		],
	},

	{
		key: 'mazavp',
		title: 'mAZ/AVP',
		children: [
			{
				key: 'mazregistrierung',
				title: 'mAZ-Registrierung für alle Kunden',
			},
			{
				key: 'kundenmailing',
				title: 'Mailing (Brief, Email)',
			},
			{
				key: 'aktivesangebot',
				title: 'Aktives Angebot von ESB (email statt Brief)',
			},
			{
				key: 'einbindungbuerokraft',
				title: 'Einbindung Bürokraft (Bsp. Schnellanmeldung Laufkundschaft)',
			},
			{
				key: 'bestandteilberatungsgespraech',
				title: 'Fixer Bestandteil Beratungsgespärch (MAB)',
			},
			{
				key: 'telefonaktion',
				title: 'Telefonaktion (Bsp. Aktionen Online)',
			},
		],
		links: [
			{
				title: 'ONEPAGER Meine Allianz',
				pdf: 'dva_onepager/dva_meineallianz.pdf',
			},
		],
	},

	{
		key: 'kukoma',
		title: 'KuKoMa',
		children: [
			{
				key: 'einrichtungkukoma',
				title: 'Einrichtung Kukoma',
			},
			{
				key: 'zustaendigkeitkukoma',
				title: 'Zuständigkeit Kukoma',
			},
			{
				key: 'einholunggeselschaftswewe',
				title: 'Einholung der Gesellschafts wEWE',
			},
			{
				key: 'vorgehebeinichterreichtenkunden',
				title: 'Vorgehen bei nicht erreichten Kunden',
			},
			{
				key: 'abgleichmitaktionenonline',
				title: 'Abgleich mit Aktionen Online',
			},
			{
				key: 'kundenueberjahreskontaktmonatinformieren',
				title: 'Kunden über Jahreskontaktmonat informieren',
			},
		],
	},

	{
		key: 'ekomi',
		title: 'eKomi',
		children: [
			{
				key: 'ablaufekomi',
				title: 'Ablauf eKomi',
			},
			{
				key: 'kundenauswahlekomi',
				title: 'Kundenauswahl eKomi',
			},
			{
				key: 'bestandteilberatungsgespärchekomi',
				title: 'Bestandteil Beratungsgespärch (MAB / eKomi)',
			},
			{
				key: 'einbindungaufhomepageekomi',
				title: 'Einbindung auf Homepage eKomi',
			},
			{
				key: 'erreichunghoehererstufenekomi',
				title: 'Erreichung höherer Stufen',
			},
			{
				key: 'sicherstellungvonmind50bewertungen',
				title: 'Sicherstellung von mindestens 50 Bewertungen p.a.',
			},
			{
				key: 'googlebewertungen',
				title: 'Google Bewertungen',
			},
		],
		links: [
			{
				title: 'ONEPAGER EKOMI',
				pdf: 'dva_onepager/dva_ekomi.pdf',
			},
		],
	},

	{
		key: 'telefoniekvminbound',
		title: 'Telefonie KVM Inbound',
		children: [
			{
				key: 'anmeldungivr',
				title: 'Anmeldung inkl. IVR',
			},
			{
				key: 'festlegungzeitenfuerkvmrufumleitung',
				title: 'Festlegung der Zeiten für KVM-Rufumleitung',
			},
			{
				key: 'servicezeitenvthomepage',
				title: 'Eintragen Servicezeiten auf VT-Homepage',
			},
			{
				key: 'nutzungpassiverterminierung',
				title: 'Nutzung passiver Terminierung (Freischaltung Outlook)',
			},
			{
				key: 'besuchkvmhalle',
				title: 'Besuch KVM Roadshow',
			},
			{
				key: 'bearbeitungsstatndardsrueckmeldungkvm',
				title: 'Bearbeitungsstandards bei Rückmeldung KVM',
			},
		],
	},
	{
		key: 'telefoniekvmoutbound',
		title: 'Telefonie KVM Outbound',
		children: [
			{
				key: 'anmeldungoutbound',
				title: 'Anmeldung',
			},
			{
				key: 'auswahlkundenoutbound',
				title: 'Auswahl Potentialkunden für Outbound',
			},
			{
				key: 'nutzungpassiverterminierungoutbund',
				title: 'Nutzung passiver Terminierung (Freischaltung Outlook)',
			},
			{
				key: 'festlegungzeitenfuerkvmrufumleitung',
				title: 'Festlegung der Zeiten für KVM-Rufumleitung',
			},

			{
				key: 'besuchkvmhalleoutbound',
				title: 'Besuch KVM Roadshow',
			},
			{
				key: 'bearbeitungsstatndardsrueckmeldungkvmoutbound',
				title: 'Bearbeitungsstandards bei Rückmeldung KVM',
			},
		],
	},
];
export default values;
