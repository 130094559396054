import { Field, useFormikContext } from 'formik';
import { SteartTextOpts } from 'app/models/core/steart.model';

export const SteartMultiSelectField = ({ ...props }) => {
	var { agtId, ...fieldProps } = props;
	const { setFieldValue } = useFormikContext();

	const checkAll = () => {
		setFieldValue(
			fieldProps.name,
			SteartTextOpts.map((p) => p.value.toString()),
		);
	};

	const uncheckAll = () => {
		setFieldValue(fieldProps.name, []);
	};

	return (
		<>
			<div>
				<div className="pad-bottom-1rem">
					<button type="button" onClick={checkAll}>
						ALLE
					</button>
					<button type="button" onClick={uncheckAll}>
						KEINE
					</button>
				</div>

				<div>
					{SteartTextOpts.map((p) => (
						<div key={p.value}>
							<Field {...fieldProps} type="checkbox" value={p.value.toString()} />
							<span> {p.label}</span>
						</div>
					))}
				</div>
			</div>
		</>
	);
};
