import { useStore } from 'app/context';
import { ErrungenschaftModel, ErrungenschaftState, IErrungenschaftModal } from 'app/models/errungenshaften/errungenschaften.model';
import { observer } from 'mobx-react';
import { BnrErrungenschaftenVM } from 'app/stores/ui/bnr.errungenschaften.ui.store';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Button } from '../common/Button';
import { Modal } from '../Modal';
import { ErrungenschaftGSB } from './ErrungenschaftGSB';
import { ErrungenschaftIcon, ErrungenschaftIconType } from './ErrungenshaftIcon';
import { ErrungenschaftYesNo } from './ErrungenschaftYesNo';
import { Errungenschaft } from './AgenturErrungenschaften';
import { BnrSecretErrungenschaften } from 'app/models/errungenshaften/bnr.errungenschaften.def';

export interface IAgtErrungenschaftYesNoGSB {
	key: string;
	state: ErrungenschaftState;
	gsbLevels: number[];
	gsbVal: number;
	boolVal: boolean;
	modal: IErrungenschaftModal;
	pathToReport?: string;
	valType: string;
}

export interface IAgtErrungenschaftVSP extends IAgtErrungenschaftYesNoGSB {
	vspKey: string;
}

export const BnrErrungenschaftDocsCreated = observer((props: IAgtErrungenschaftYesNoGSB) => {
	return <ErrungenschaftGSB {...props} icon={ErrungenschaftIconType.documentCreated} />;
});

export const BnrErrungenschaftDocsShared = observer((props: IAgtErrungenschaftYesNoGSB) => {
	return <ErrungenschaftGSB {...props} icon={ErrungenschaftIconType.documentShared} />;
});

export const BnrErrungenschaftTasksCompleted = observer((props: IAgtErrungenschaftYesNoGSB) => {
	return <ErrungenschaftGSB {...props} icon={ErrungenschaftIconType.massnahmen} />;
});

export const BnrErrungenschaftBranchenPlanned = observer((props: IAgtErrungenschaftYesNoGSB) => {
	return <ErrungenschaftGSB {...props} icon={ErrungenschaftIconType.bp} />;
});

export const BnrErrungenschaftJPGScreated = observer((props: IAgtErrungenschaftYesNoGSB) => {
	return <ErrungenschaftGSB {...props} icon={ErrungenschaftIconType.jpg} />;
});
export const BnrErrungenschaftGPEH = observer((props: IAgtErrungenschaftYesNoGSB) => {
	return <ErrungenschaftYesNo {...props} icon={ErrungenschaftIconType.gpeh} />;
});

export const BnrErrungenschaftVSP = observer((props: IAgtErrungenschaftVSP) => {
	switch (props.vspKey) {
		case 'idd':
			return <ErrungenschaftYesNo {...props} icon={ErrungenschaftIconType.weiterbildung} />;
		case 'lebenknp':
			return <ErrungenschaftYesNo {...props} icon={ErrungenschaftIconType.lebenknp} />;
		case 'privatschutz':
			return <ErrungenschaftYesNo {...props} icon={ErrungenschaftIconType.privatschutz} />;
		case 'khpkwlfnst':
			return <ErrungenschaftYesNo {...props} icon={ErrungenschaftIconType.khpkw} />;
		default:
			// console.warn('Errungenschaft unknown', props.vspKey);
			return <ErrungenschaftIcon {...props} valType="unknown" icon={ErrungenschaftIconType.vsp} />;
	}
});

export const BnrErrungenschaftAgtProd = observer((props: IAgtErrungenschaftYesNoGSB) => {
	return <ErrungenschaftYesNo {...props} icon={ErrungenschaftIconType.bewertungsziel} />;
});

interface IEasterEggErrungenschaft {
	isCompact?: boolean;
	action?: () => void;
}
export const MeisterPlaner = observer((props: IEasterEggErrungenschaft) => {
	const { bnrErrungenschaftenUiStore, uiStore, session } = useStore();

	const def = BnrSecretErrungenschaften.find((e) => e.key === 'bnr.meisterplaner')!;
	if (def.visibleForRoles && !def.visibleForRoles.includes(session.currentUser!.steart.id)) {
		return null;
	}

	const icon = props.isCompact ? ErrungenschaftIconType.meisterplanerSmall : ErrungenschaftIconType.meisterplaner;

	const showModal = () => {
		uiStore.showModal(uiStore.modalIds.bnrErrungenschaften);
	};

	const zeModal = {
		title: 'Meisterplaner',
		description: '',
	};

	return (
		<>
			{bnrErrungenschaftenUiStore.isMeisterPlaner && (
				<Button className="button is-blank" onClick={props.action ? props.action : showModal}>
					<ErrungenschaftIcon key="master" valType="easter" state={ErrungenschaftState.gold} icon={icon} modal={zeModal} />
				</Button>
			)}
			{/* Kann versteckt werden */}
			{/* {!bnrErrungenschaftenUiStore.isMeisterPlaner && <>Ist schlechter Planer!!</>} */}
		</>
	);
});

export const ZielErfueller = observer((props: IEasterEggErrungenschaft) => {
	const { bnrErrungenschaftenUiStore, uiStore, session } = useStore();

	const def = BnrSecretErrungenschaften.find((e) => e.key === 'bnr.zielerfueller')!;
	if (def.visibleForRoles && !def.visibleForRoles.includes(session.currentUser!.steart.id)) {
		return null;
	}

	const icon = props.isCompact ? ErrungenschaftIconType.zielerfuellerSmall : ErrungenschaftIconType.zielerfueller;

	const showModal = () => {
		uiStore.showModal(uiStore.modalIds.bnrErrungenschaften);
	};

	const zeModal = {
		title: 'Zielerfüller',
		description: 'Alle Ziele erreicht',
	};

	return (
		<>
			{bnrErrungenschaftenUiStore.isZielErfueller && (
				<Button className="button is-blank" onClick={props.action ? props.action : showModal}>
					<ErrungenschaftIcon key="erfueller" valType="easter" state={ErrungenschaftState.gold} icon={icon} modal={zeModal} />
				</Button>
			)}
			{/* Kann versteckt werden */}
			{/* {!bnrErrungenschaftenUiStore.isZielErfueller && <>Ist Versager</>} */}
		</>
	);
});
export const DvsExperte = observer((props: IEasterEggErrungenschaft) => {
	const { bnrErrungenschaftenUiStore, uiStore, session } = useStore();

	const def = BnrSecretErrungenschaften.find((e) => e.key === 'bnr.dvs.experte')!;
	if (def.visibleForRoles && !def.visibleForRoles.includes(session.currentUser!.steart.id)) {
		return null;
	}

	const iconGold = props.isCompact ? ErrungenschaftIconType.dvsExperteGoldSmall : ErrungenschaftIconType.dvsExperteGold;
	const iconSilver = props.isCompact ? ErrungenschaftIconType.dvsExperteSilverSmall : ErrungenschaftIconType.dvsExperteSilver;
	const iconBronze = props.isCompact ? ErrungenschaftIconType.dvsExperteBronzeSmall : ErrungenschaftIconType.dvsExperteBronze;

	const zeModal = {
		title: 'DVS Experte',
		description: '',
	};

	const showModal = () => {
		uiStore.showModal(uiStore.modalIds.bnrErrungenschaften);
	};

	return (
		<>
			{bnrErrungenschaftenUiStore.isDvsExperte && (
				<>
					<Button className="button is-blank" onClick={props.action ? props.action : showModal}>
						{bnrErrungenschaftenUiStore.dvsExperteLevel === ErrungenschaftState.gold && (
							<ErrungenschaftIcon key="experte" valType="easter" state={ErrungenschaftState.gold} icon={iconGold} modal={zeModal} />
						)}
						{bnrErrungenschaftenUiStore.dvsExperteLevel === ErrungenschaftState.silver && (
							<ErrungenschaftIcon key="experte" valType="easter" state={ErrungenschaftState.silver} icon={iconSilver} modal={zeModal} />
						)}
						{bnrErrungenschaftenUiStore.dvsExperteLevel === ErrungenschaftState.bronze && (
							<ErrungenschaftIcon key="experte" valType="easter" state={ErrungenschaftState.bronze} icon={iconBronze} modal={zeModal} />
						)}
					</Button>
				</>
			)}
			{/* Kann versteckt werden */}
			{/* {!bnrErrungenschaftenUiStore.isMeisterPlaner && <>Hat keine Ahnung</>} */}
		</>
	);
});

export const BnrErrungenschaften = observer(() => {
	const { bnrErrungenschaftenUiStore, uiStore } = useStore();
	const history = useHistory();

	const [vm, setVm] = useState<BnrErrungenschaftenVM>();

	useEffect(() => {
		setVm(bnrErrungenschaftenUiStore.current);
	}, [bnrErrungenschaftenUiStore]);

	const [modalModel, setModalModel] = useState<ErrungenschaftModel>();

	const onOpen = (e: ErrungenschaftModel) => {
		setModalModel(e);
		uiStore.showModal(uiStore.modalIds.errungenschaftMiniModal);
	};

	const onClose = () => {
		setModalModel(undefined);
	};

	const onNavigate = () => {
		uiStore.hideModal(uiStore.modalIds.errungenschaftMiniModal);
		if (modalModel && modalModel.pathToReport) {
			history.push(modalModel.pathToReport);
		}
	};

	return (
		<>
			{vm && (
				<>
					<div className="achievements-grid is-compact">
						<MeisterPlaner isCompact={true} />
						<ZielErfueller isCompact={true} />
						<DvsExperte isCompact={true} />

						{vm.sortedByAchieved.map((e) => (
							<Errungenschaft key={e.key} model={e} handleClick={onOpen} />
						))}
					</div>
				</>
			)}
			{modalModel && (
				<Modal title={modalModel.modal.title} size="small" modalId={uiStore.modalIds.errungenschaftMiniModal} onClose={onClose}>
					<p dangerouslySetInnerHTML={{ __html: modalModel.modal.description }}></p>

					{modalModel.pathToReport && (
						<div className="modal-footer">
							<Button className="button is-primary" onClick={onNavigate}>
								Zum Bericht
							</Button>
						</div>
					)}
				</Modal>
			)}
		</>
	);
});

export const BnrErrungenschaftenOverviewModal = observer(() => {
	const { uiStore } = useStore();

	return (
		<Modal modalId={uiStore.modalIds.bnrErrungenschaften} title={'Errungenschaften'} modifier="is-paddingless">
			<BnrErrungenschaftenOverview />
		</Modal>
	);
});

export enum EasterModalType {
	expert = 'export',
	meister = 'meister',
	ziel = 'ziel',
}

export const BnrErrungenschaftenOverview = observer(() => {
	const history = useHistory();
	const { bnrErrungenschaftenUiStore, uiStore } = useStore();
	const [vm, setVm] = useState<BnrErrungenschaftenVM>();

	useEffect(() => {
		setVm(bnrErrungenschaftenUiStore.current);
	}, [bnrErrungenschaftenUiStore]);

	const showTrophies = bnrErrungenschaftenUiStore.isMeisterPlaner || bnrErrungenschaftenUiStore.isDvsExperte || bnrErrungenschaftenUiStore.isZielErfueller;

	const onOpen = (e: ErrungenschaftModel) => {
		setModalModel(e);
		uiStore.showModal(uiStore.modalIds.errungenschaftMiniModal);
	};
	const onClose = () => {
		setModalModel(undefined);
	};

	const [modalModel, setModalModel] = useState<ErrungenschaftModel>();

	const onNavigate = () => {
		uiStore.hideModal(uiStore.modalIds.bnrErrungenschaften);
		uiStore.hideModal(uiStore.modalIds.errungenschaftMiniModal);
		if (modalModel && modalModel.pathToReport) {
			history.push(modalModel.pathToReport);
		}
	};

	const [easterModal, setEasterModal] = useState<EasterModalType>();
	const onEasterEgg = (egg: EasterModalType) => {
		setEasterModal(egg);
		uiStore.showModal(uiStore.modalIds.errungenschaftMiniModal);
	};

	const closeEasterEgg = () => {
		setEasterModal(undefined);
		uiStore.hideModal(uiStore.modalIds.errungenschaftMiniModal);
	};

	return (
		<>
			{vm && (
				<>
					<div className="achievements-modal">
						{showTrophies && (
							<div className="achievements-section">
								<div className="section-title">Trophäen</div>
								<div className="achievements-grid is-trophy-grid">
									<MeisterPlaner action={() => onEasterEgg(EasterModalType.meister)} />
									<ZielErfueller action={() => onEasterEgg(EasterModalType.ziel)} />
									<DvsExperte action={() => onEasterEgg(EasterModalType.expert)} />
								</div>
							</div>
						)}
						<div className="achievements-section">
							<div className="section-title">Erreichte Errungenschaften</div>
							<div className="achievements-grid">
								<>
									{vm.achievedItems.map((e) => (
										<Errungenschaft key={e.key} model={e} handleClick={() => onOpen(e)} />
									))}
								</>
							</div>
						</div>
						<div className="achievements-section">
							<div className="section-title">Offene Errungenschaften</div>
							<div className="achievements-grid">
								<>
									{vm.nonAchievedItems.map((e) => (
										<Errungenschaft key={e.key} model={e} handleClick={() => onOpen(e)} />
									))}
								</>
							</div>
						</div>
					</div>

					{easterModal && <EasterModal onClose={closeEasterEgg} modalType={easterModal} />}

					{modalModel && (
						<Modal title={modalModel.modal.title} size="small" modalId={uiStore.modalIds.errungenschaftMiniModal} onClose={onClose}>
							<p dangerouslySetInnerHTML={{ __html: modalModel.modal.description }}></p>

							{modalModel.pathToReport && (
								<div className="modal-footer">
									<Button className="button is-primary" onClick={onNavigate}>
										Zum Bericht
									</Button>
								</div>
							)}
						</Modal>
					)}
				</>
			)}
		</>
	);
});

interface IEasterModalProps {
	modalType: EasterModalType;
	onClose: () => void;
}
export const EasterModal = observer((props: IEasterModalProps) => {
	const { uiStore, bnrErrungenschaftenUiStore } = useStore();
	const { modalType } = props;

	const getTitle = () => {
		switch (modalType) {
			case EasterModalType.expert:
				return 'DVS Experte';
			case EasterModalType.meister:
				return 'Meisterplaner';
			case EasterModalType.ziel:
				return 'Zielerfüller';
		}
	};

	const zeModal = {
		title: getTitle(),
		description: '',
	};

	return (
		<Modal title={getTitle()} size="medium" modalId={uiStore.modalIds.errungenschaftMiniModal} onClose={props.onClose}>
			<div className="grid easteregg-modal">
				{modalType === EasterModalType.expert && (
					<>
						{bnrErrungenschaftenUiStore.dvsExperteLevel === 'gold' && (
							<ErrungenschaftIcon key="experte" valType="easter" state={ErrungenschaftState.gold} icon={ErrungenschaftIconType.dvsExperteGold} modal={zeModal} />
						)}
						{bnrErrungenschaftenUiStore.dvsExperteLevel === 'silver' && (
							<ErrungenschaftIcon key="experte" valType="easter" state={ErrungenschaftState.silver} icon={ErrungenschaftIconType.dvsExperteSilver} modal={zeModal} />
						)}
						{bnrErrungenschaftenUiStore.dvsExperteLevel === 'bronze' && (
							<ErrungenschaftIcon key="experte" valType="easter" state={ErrungenschaftState.bronze} icon={ErrungenschaftIconType.dvsExperteBronze} modal={zeModal} />
						)}

						<div className="details">
							<div className="title is-3">{getTitle()}</div>
							<p>
								EXPERTE ipsum, dolor sit amet consectetur adipisicing elit. Quia voluptatum aspernatur molestias at nobis voluptatem? Vero aperiam exercitationem,
								rem id fugit quaerat tempora iste nihil porro est modi dolore libero.{' '}
							</p>
						</div>
					</>
				)}
				{modalType === EasterModalType.ziel && (
					<>
						<ErrungenschaftIcon key="ziel" valType="easter" state={ErrungenschaftState.gold} icon={ErrungenschaftIconType.zielerfueller} modal={zeModal} />

						<div className="details">
							<div className="title is-3">{getTitle()}</div>
							<p>
								ZIELERFÜLLER ipsum, dolor sit amet consectetur adipisicing elit. Quia voluptatum aspernatur molestias at nobis voluptatem? Vero aperiam
								exercitationem, rem id fugit quaerat tempora iste nihil porro est modi dolore libero.{' '}
							</p>
						</div>
					</>
				)}
				{modalType === EasterModalType.meister && (
					<>
						<ErrungenschaftIcon key="meister" valType="easter" state={ErrungenschaftState.gold} icon={ErrungenschaftIconType.meisterplaner} modal={zeModal} />

						<div className="details">
							<div className="title is-3">{getTitle()}</div>
							<p>
								MEISTER ipsum, dolor sit amet consectetur adipisicing elit. Quia voluptatum aspernatur molestias at nobis voluptatem? Vero aperiam exercitationem,
								rem id fugit quaerat tempora iste nihil porro est modi dolore libero.{' '}
							</p>
						</div>
					</>
				)}
			</div>
		</Modal>
	);
});
