import axios from 'axios';
import moment from 'moment';
import { BaseCachedEntityStore } from './core/base.cached.entity.store';
import { UserLogin } from './ui/admin.user.login.ui.store';

interface TrackingModel { }

export class AdminTrackingStore extends BaseCachedEntityStore<TrackingModel> {
	async getMatomoWeekData(startDate?: string, endDate?: string) {
		if (!startDate || !endDate) return;
		const matomoToken = await this.getMatomoToken();
		const weeks = this.calcWeeks(startDate, endDate);

		const matomoWeekRequest = weeks.map((week, index) => {
			return `&urls[${index}]=` + encodeURIComponent(`method=Events.getAction&secondaryDimension=eventName&flat=1&idSite=1&period=week&date=${week}`);
		});

		const vdSummary = [
			'https://dvsdemo.isarbits.com/piwik/',
			'?module=API',
			'&method=API.getBulkRequest',
			'&format=json',
			matomoWeekRequest.join(''),
			'&token_auth=' + matomoToken,
		];

		return await axios.get(vdSummary.join('')).then((res) => {
			return res.data;
		});
	}

	async getMatomoVisitsData(startDate?: string, endDate?: string) {
		if (!startDate || !endDate) return;
		const matomoToken = await this.getMatomoToken();
		const dateRange = `${startDate},${endDate}`;

		const url0 = ['method=VisitsSummary.get', '&idSite=1', '&period=range&date=' + dateRange];
		const url1 = ['method=Actions.get', '&idSite=1', '&period=range&date=' + dateRange];
		const url2 = ['method=DevicesDetection.getType', '&idSite=1', '&period=range&date=' + dateRange];

		const visitSummary = [
			'https://dvsdemo.isarbits.com/piwik/',
			'?module=API',
			'&method=API.getBulkRequest',
			'&format=json',
			'&urls[0]=' + encodeURI(url0.join('')),
			'&urls[1]=' + encodeURI(url1.join('')),
			'&urls[2]=' + encodeURI(url2.join('')),
			'&token_auth=' + matomoToken,
		];

		const trackingData = await axios.get(visitSummary.join('')).then((res) => {
			const ret = {
				totalVisits: res.data[0].nb_visits,
				devices: res.data[2].slice(0, 3),
				averageTime: res.data[0].avg_time_on_site,
				actionsPerVisit: res.data[0].nb_actions_per_visit,
				loadingTime: res.data[1].avg_time_generation,
				pageViews: res.data[1].nb_pageviews,
			};
			return ret;
		});

		return trackingData;
	}

	async getMatomoToken() {
		await this.callRefreshTokenIfNecessary();
		const header = this.getAuthHeader();

		const res = await this.axios({
			url: 'api/admin/stats/getmatat',
			method: 'GET',
			headers: header.headers,
		});

		return res.data;
	}

	calcWeeks(startDate: string, endDate: string) {
		let current = moment(startDate).clone().startOf('week');
		let ret = [current.format('YYYY-MM-DD')];

		while (current.add(1, 'week').isSameOrBefore(endDate)) {
			ret.push(current.format('YYYY-MM-DD'));
		}

		return ret;
	}

	async getAgtBPStats() {
		await this.callRefreshTokenIfNecessary();
		const header = this.getAuthHeader();

		const res = await this.axios({
			url: 'api/admin/stats/agentur-bp-steart',
			method: 'GET',
			headers: header.headers,
		});

		return res.data;
	}

	async getAgtJpgStats() {
		await this.callRefreshTokenIfNecessary();
		const header = this.getAuthHeader();

		const res = await this.axios({
			url: 'api/admin/stats/agentur-year',
			method: 'GET',
			headers: header.headers,
		});

		return res.data;
	}

	async getAgtPlanDocStats() {
		await this.callRefreshTokenIfNecessary();
		const header = this.getAuthHeader();

		const res = await this.axios({
			url: 'api/admin/stats/plandocs',
			method: 'GET',
			headers: header.headers,
		});

		return res.data;
	}

	async getUserLoginTrackingData() {
		await this.callRefreshTokenIfNecessary();
		const header = this.getAuthHeader();

		const res = await this.axios({
			url: 'api/admin/stats/tracking',
			method: 'GET',
			headers: header.headers,
		});

		return res.data.map((x: any) => new UserLogin(x));

	}
}
