import { Einheit } from 'app/models/core/einheit.model';
import { AgtGpPosModel } from 'app/models/gp/agt.gp.pos.model';
import { allExceptBasis, allExceptPro3andSV, allExceptSV, onlyPro3, onlyPro3AndSV, PosTodo } from '../gp';
const agtPosDef: AgtGpPosModel[] = [];

/////////////////////// 2025 ////////////////////////////////////
// in agt.gp.pos.model.ts search for:
// 2025 this should be deletable
// then refactor accordingly. (definition bevor 2023 should be deleted)

// siehe auch loadProduktivitaet
agtPosDef.push(
	new AgtGpPosModel({
		year: 2024,
		posId: [30, 90144103, 90144102, 90144101],
		planKey: 'produktivitaet',
		einheit: Einheit.bew,
		group: 'eh',
		posText: 'Produktivität',
		hideFromTreeMenu: true,
	}),
);




// kunde
agtPosDef.push(
	new AgtGpPosModel({
		year: 2024,
		// posId: 2255001,
		posId: [1],
		planKey: 'idd',
		group: 'kunde',
		posText: 'Weiterbildungspflicht. AGT',
		einheit: Einheit.stck,
	}),
);
agtPosDef.push(
	new AgtGpPosModel({
		year: 2024,
		posId: [10],
		planKey: 'mazneu',
		group: 'kunde',
		posText: 'Neue MAZ Anmeldungen',
		einheit: Einheit.stck,
		boniStufen: 2,
	}),
);
agtPosDef.push(
	new AgtGpPosModel({
		year: 2024,
		posId: [13],
		planKey: 'mazneupft',
		group: 'kunde',
		posText: 'Neukunden mit P-LFNST SLK',
		einheit: Einheit.stck,
	}),
);
agtPosDef.push(
	new AgtGpPosModel({
		year: 2024,
		posId: [15],
		planKey: 'nps',
		posText: 'Kundenzufriedenheit NPS',
		group: 'kunde',
		einheit: Einheit.nichts,
		visibleForGps: onlyPro3AndSV,
	}),
);
agtPosDef.push(
	new AgtGpPosModel({
		year: 2024,
		posId: [16],
		planKey: 'gesamtzufriedenheit',
		group: 'kunde',
		posText: 'Gesamtzufriedenheit 5*',
		einheit: Einheit.nichts,
		visibleForGps: onlyPro3AndSV,
		decimals: 1
	}),
);
agtPosDef.push(
	new AgtGpPosModel({
		year: 2024,
		posId: [17],
		planKey: 'nps_anzahl',
		group: 'kunde',
		posText: 'Anz Kundenbewertungen NPS',
		einheit: Einheit.stck,
		visibleForGps: onlyPro3,
	}),
);

// vertriebsschwerpunkte
agtPosDef.push(
	new AgtGpPosModel({
		year: 2024,
		posId: [20],
		planKey: 'unternehmensschutz',
		group: 'vss',
		posText: 'Unternehmensschutz',
		einheit: Einheit.stck,
		boniStufen: 2,
	}),
);

agtPosDef.push(
	new AgtGpPosModel({
		year: 2024,
		posId: [24],
		planKey: 'lebenrisbewoze',
		group: 'vss',
		posText: 'VAG:Leben Ris. Bew Digi',
		einheit: Einheit.bew,
		boniStufen: 2,
	}),
);

agtPosDef.push(
	new AgtGpPosModel({
		year: 2024,
		group: 'vss',
		posId: [27],
		planKey: 'kvpflegelfnstea',
		posText: 'KV-Pflege-LFNST-EA_Digi',
		einheit: Einheit.stck,
	}),
);

// eingangshürde
agtPosDef.push(
	new AgtGpPosModel({
		year: 2024,
		group: 'eh',
		posId: [30],
		planKey: 'gesamtbewertung',
		posText: 'Gesamtbewertung',
		einheit: Einheit.bew,
		visibleForGps: allExceptPro3andSV,
	}),
);
agtPosDef.push(
	new AgtGpPosModel({
		year: 2024,
		group: 'eh',
		posId: [2255831, 2255832],
		planKey: 'unfallfstnr',
		posText: 'Unfall NST',
		einheit: Einheit.stck,
		// visibleForGps: allExceptPro3andSV,
	}),
);
agtPosDef.push(
	new AgtGpPosModel({
		year: 2024,
		group: 'eh',
		posId: [2255841, 2255842],
		planKey: 'lebennst',
		posText: 'Leben NST',
		einheit: Einheit.stck,
		// visibleForGps: allExceptPro3andSV,
	}),
);


// // Versicherung und Vorsorge
agtPosDef.push(
	new AgtGpPosModel({
		year: 2024,
		posId: [121],
		planKey: 'psachokrunfbew',
		group: 'vuv',
		posText: 'P-Sach o.Kr/U oAnP BEW Digi',
		einheit: Einheit.bew,
		pro3GpPos: { posId: 121, group: 'vuv', posText: 'P-Sach o.Kr/U oAnP BEW Digi' },
		visibleForGps: allExceptSV,
		boniStufen: 3,
	}),
);
agtPosDef.push(
	new AgtGpPosModel({
		year: 2024,
		posId: [129],
		planKey: 'psachokrunfstk',
		einheit: Einheit.stck,
		group: 'vuv',
		posText: 'P-Sach o.Kr/U BZUW Stück',
		visibleForGps: allExceptSV,
	}),
);

agtPosDef.push(
	new AgtGpPosModel({
		year: 2024,
		posId: [131, 1190251],
		planKey: 'unfallbew',
		einheit: Einheit.bew,
		group: 'vuv',
		posText: 'Unfall Bew o. Anp. Digi',
		boniStufen: 3,
	}),
);

let tempPosId = PosTodo();

agtPosDef.push(
	new AgtGpPosModel({
		year: 2024,
		posId: [tempPosId, 1190259],
		planKey: 'unfallrisbew',
		einheit: Einheit.bew,
		pro3GpPos: { posId: tempPosId, group: 'vuv', posText: 'Unfall Risiko BEW' },
		//boniStufe: +1, +2
	}),
);

tempPosId = PosTodo();
agtPosDef.push(
	new AgtGpPosModel({
		year: 2024,
		posId: tempPosId,
		planKey: 'kusbwedigi',
		einheit: Einheit.bew,
		pro3GpPos: { posId: tempPosId, group: 'vuv', posText: 'KUS Bewertung Digi' },
	}),
);

agtPosDef.push(
	new AgtGpPosModel({
		year: 2024,
		posId: [139],
		planKey: 'unfallrisbestzuwst',
		einheit: Einheit.bew,
		group: 'vuv',
		posText: 'Unf-Risiko Best. Zuw ST',
	}),
);

agtPosDef.push(
	new AgtGpPosModel({
		year: 2024,
		posId: [141],
		planKey: 'khpwklfnstdigi',
		group: 'vuv',
		posText: 'KH PKW LfNST Digi',
		einheit: Einheit.bew,
		visibleForGps: allExceptSV,
		boniStufen: 2,
	}),
);

agtPosDef.push(
	new AgtGpPosModel({
		year: 2024,
		posId: [151],
		planKey: 'fsachokraftoanpbew',
		group: 'vuv',
		posText: 'F-Sach o. Kraft o.Anp Bew',
		einheit: Einheit.bew,
		visibleForGps: allExceptSV,
		boniStufen: 3,
	}),
);

agtPosDef.push(
	new AgtGpPosModel({
		year: 2024,
		posId: [161],
		planKey: 'vaglebenbewdigi',
		pro3GpPos: { posId: 151 },
		group: 'vuv',
		posText: 'VAG Leben Bew Digi',
		einheit: Einheit.bew,
		visibleForGps: allExceptSV,
	}),
);
agtPosDef.push(
	new AgtGpPosModel({
		year: 2024,
		posId: [211],
		planKey: 'krankenzusatzbewdigi',
		group: 'vuv',
		posText: 'Kranken Zusatz Bew Digi',
		einheit: Einheit.bew,
		visibleForGps: allExceptSV,
		boniStufen: 3,
	}),
);

// GP PLUS
agtPosDef.push(
	new AgtGpPosModel({
		year: 2024,
		posId: [1180130, 1190130],
		planKey: 'lebenknpebdigi',
		group: 'gpplus',
		posText: 'Leben EB BEW Digi ',
		einheit: Einheit.bew,
	}),
);
agtPosDef.push(
	new AgtGpPosModel({
		year: 2024,
		posId: [1180150, 1190150],
		planKey: 'wiederanlagesumme',
		group: 'gpplus',
		posText: 'Wiederanlage',
		einheit: Einheit.bew,
	}),
);
agtPosDef.push(
	new AgtGpPosModel({
		year: 2024,
		posId: [1180220, 1190220],
		planKey: 'krankenbkvbew',
		einheit: Einheit.bew,
		group: 'gpplus',
		posText: 'Kranken bKV BEW',
	}),
);

agtPosDef.push(
	new AgtGpPosModel({
		year: 2024,
		posId: [1180230, 1190230],
		planKey: 'krankenneuebefkvgv',
		einheit: Einheit.bew,
		group: 'gpplus',
		posText: 'Kranken_neue_bef_bKV_GV',
	}),
);
agtPosDef.push(
	new AgtGpPosModel({
		year: 2024,
		posId: [1180300, 1190300],
		planKey: 'weiterbildungfonds',
		einheit: Einheit.std,
		group: 'gpplus',
		posText: 'Weiterbildungsz. Fonds',
		visibleForGps: allExceptBasis,
	}),
);
agtPosDef.push(
	new AgtGpPosModel({
		year: 2024,
		posId: [1180301, 1190301],
		planKey: 'fondsbew',
		einheit: Einheit.bew,
		group: 'gpplus',
		posText: 'Fonds Bewertung',
		visibleForGps: allExceptBasis,
	}),
);
agtPosDef.push(
	new AgtGpPosModel({
		year: 2024,
		posId: [1180311, 1190311],
		planKey: 'nettomittelzufluss',
		einheit: Einheit.bew,
		group: 'gpplus',
		posText: 'NMZ o. P EUR',
		visibleForGps: allExceptBasis,
	}),
);

agtPosDef.push(
	new AgtGpPosModel({
		year: 2024,
		posId: [1180401, 1190401],
		planKey: 'baufinanzierungbew',
		group: 'gpplus',
		posText: 'Baufinanzierung Bew AZL',
		einheit: Einheit.bew,
	}),
);

agtPosDef.push(
	new AgtGpPosModel({
		year: 2024,
		posId: [1180404, 1190404],
		planKey: 'risikiolvbaufi',
		einheit: Einheit.stck,
		group: 'gpplus',
		posText: 'Risiko-LV i.V.m. AZ Baufi',
	}),
);

agtPosDef.push(
	new AgtGpPosModel({
		year: 2024,
		posId: [1180421, 1190421],
		planKey: 'baufinanzierungwuro',
		einheit: Einheit.bew,
		group: 'gpplus',
		posText: 'Baufinanzierung Bew WüRo',
	}),
);

agtPosDef.push(
	new AgtGpPosModel({
		year: 2024,
		posId: [1180450, 1190450],
		planKey: 'bausparenneustueck',
		einheit: Einheit.stck,
		group: 'gpplus',
		posText: 'Bausparen Neustück',
	}),
);

agtPosDef.push(
	new AgtGpPosModel({
		year: 2024,
		posId: [1180451, 1190451],
		planKey: 'bausparenbewertung',
		einheit: Einheit.bew,
		group: 'gpplus',
		posText: 'Bausparen Bewertung m. GG Regel',
	}),
);

// Incentives: Basis
agtPosDef.push(
	new AgtGpPosModel({
		year: 2024,
		posId: [1180700, 1190700],
		planKey: 'lfirmenknp80ris',
		einheit: Einheit.stck,
		group: 'incfuerfach',
		posText: 'VAG: L-Firmen KNP80 + Ris',
		visibleForGps: onlyPro3,
	}),
);

agtPosDef.push(
	new AgtGpPosModel({
		year: 2024,
		posId: [1180710, 1190710],
		planKey: 'krankenbkVbew',
		einheit: Einheit.stck,
		group: 'incfuerfach',
		posText: 'VAG:L-F KNP80+R F-Online',
		visibleForGps: onlyPro3,
	}),
);

export const AgtPositionen2024 = agtPosDef;
