import { ApModel } from 'app/models/documents/ap.model';
import { DocumentVM } from 'app/stores/ui/document.ui.store';
import { observer } from 'mobx-react';
import { Button } from '../common/Button';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import FormField from '../form/FormField';
import { useStore } from 'app/context';
import React from 'react';
import { SelectField } from '../common/Select';
import { notify } from '../common/notify';
import { formatCurrency, formatMonth } from 'app/utils';

interface IApJahresZielForm {
	doc: DocumentVM;
}

const yupNum = Yup.number().typeError('Nur Zahleneingaben sind erlaubt').required('Eingabe erforderlich').default(0);

const abBestandSchema = Yup.object({
	startMonth: yupNum,
	endMonth: yupNum,
	bewertungVorPlanungszeitraum: yupNum,
	bewertungFuerPlanungszeitraum: yupNum,
	jahresplan: yupNum,
}).required();

type Bestand = Yup.InferType<typeof abBestandSchema>;

const monatOpts = [
	{ value: 1, label: formatMonth(1) },
	{ value: 2, label: formatMonth(2) },
	{ value: 3, label: formatMonth(3) },
	{ value: 4, label: formatMonth(4) },
	{ value: 5, label: formatMonth(5) },
	{ value: 6, label: formatMonth(6) },
	{ value: 7, label: formatMonth(7) },
	{ value: 8, label: formatMonth(8) },
	{ value: 9, label: formatMonth(9) },
	{ value: 10, label: formatMonth(10) },
	{ value: 11, label: formatMonth(11) },
	{ value: 12, label: formatMonth(12) },
];

export const ApPlanungszeitraumForm = observer((props: IApJahresZielForm) => {
	const { uiStore, documentUiStore } = useStore();
	const ap = props.doc.doc.content as ApModel;

	const onCancel = () => {
		uiStore.hideModal(uiStore.modalIds.apEditPlanungszeitraum);
	};

	const handleSubmit = async (values: Bestand) => {
		// for some reason the values come through as strings...
		if (typeof values.startMonth !== 'number') {
			values.startMonth = parseInt(values.startMonth);
		}
		if (typeof values.endMonth !== 'number') {
			values.endMonth = parseInt(values.endMonth);
		}

		if (values.startMonth >= values.endMonth) {
			notify('Planungstart muss vorm dem Planungsende liegen', '', 'warning');
			return;
		}

		ap.startMonth = values.startMonth;
		ap.endMonth = values.endMonth;
		ap.bewertungVorPlanungszeitraum = values.bewertungVorPlanungszeitraum;
		ap.bewertungFuerPlanungszeitraum = values.bewertungFuerPlanungszeitraum;
		ap.jahresplan = values.jahresplan;
		await documentUiStore.save(props.doc);
		uiStore.hideModal(uiStore.modalIds.apEditPlanungszeitraum);
	};

	return (
		<>
			<Formik
				validationSchema={abBestandSchema}
				initialValues={{
					startMonth: ap.startMonth,
					endMonth: ap.endMonth,
					bewertungVorPlanungszeitraum: ap.bewertungVorPlanungszeitraum,
					bewertungFuerPlanungszeitraum: ap.bewertungFuerPlanungszeitraum,
					jahresplan: ap.jahresplan,
				}}
				onSubmit={handleSubmit}
			>
				{({ errors, touched, values, isValid, handleChange }) => {
					return (
						<Form className="ap-form">
							<div>
								<FormField className="is-inline" label="Erforderliche Gesamtbewertung in €">
									<span>{formatCurrency(ap.gesamtbewertung)} €</span>
								</FormField>

								<FormField className="is-inline" error={errors.startMonth} touched={touched.startMonth} label="Planungsstart">
									<SelectField name={'startMonth'} opts={monatOpts} />
								</FormField>

								<FormField className="is-inline" error={errors.endMonth} touched={touched.endMonth} label="Planungsende">
									<SelectField name={'endMonth'} opts={monatOpts} />
								</FormField>

								{values.startMonth > 1 && (
									<FormField className="is-inline" error={errors.bewertungVorPlanungszeitraum} touched={touched.bewertungVorPlanungszeitraum} label="Bewertung vor Planung">
										<Field type="text" name={'bewertungVorPlanungszeitraum'} placeholder={''} />
									</FormField>
								)}
								<FormField className="is-inline" error={errors.bewertungFuerPlanungszeitraum} touched={touched.bewertungFuerPlanungszeitraum} label="Bewertung für Planung">
									<Field type="text" name={'bewertungFuerPlanungszeitraum'} placeholder={''} />
								</FormField>
								<FormField className="is-inline" error={errors.jahresplan} touched={touched.jahresplan} label="Bewertung Gesamt in €">
									<Field type="text" name={'jahresplan'} placeholder={''} />
								</FormField>
							</div>
							<div className="modal-footer">
								<Button type="button" className="button is-secondary" onClick={onCancel}>
									Abbrechen
								</Button>
								<Button type="submit" className="button is-primary ">
									Speichern
								</Button>
							</div>
						</Form>
					);
				}}
			</Formik>
		</>
	);
});
