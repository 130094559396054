import { observer } from 'mobx-react';
import { AkumaPrimeListe } from './AkumaPrimeListe';
import { AkumaPrimeSchema, AgtAkumaPrimeKwVM, AkumaPrimeType } from 'app/stores/ui/agt.akumaprime.ui.store';
import { useStore } from 'app/context';
import { useEffect, useState } from 'react';
import { AkumaPrimeAgtStackedHistory } from './AkumaPrimeStackedHistory';

import _ from 'lodash';
import { AkumaPrimeHorizontalBarChart } from './AkumaPrimeBarChart';
import { AkumaPrimeOptions } from './AkumaPrimeOverview';

export const AkumaPrimeTerminierungsOverview = observer(() => {
	const { berichteUiiStore, agtAkumaPrimeUiStore, agenturListUiStore } = useStore();
	const [data, setData] = useState<AgtAkumaPrimeKwVM[]>([]);
	const schema = AkumaPrimeSchema.filter((s) => s.type === AkumaPrimeType.terminierung);

	useEffect(() => {
		agtAkumaPrimeUiStore.load().then((res) => {
			let items = agtAkumaPrimeUiStore.items;

			if (berichteUiiStore.selectedAgt) {
				const agtId = berichteUiiStore.selectedAgt.agtId;
				items = agtAkumaPrimeUiStore.filterByAgtIdandKw(agtId);
				items = _.sortBy(items, 'bm').reverse();
				setData(items);
			} else {
				items = agenturListUiStore.applyFilterOnAgt(agtAkumaPrimeUiStore.currentKWItems) as AgtAkumaPrimeKwVM[];
				setData(items);
			}
		});
	}, [agtAkumaPrimeUiStore, agtAkumaPrimeUiStore.currentKWItems, berichteUiiStore.selectedAgt, berichteUiiStore.menu, agenturListUiStore, agenturListUiStore.filterHasChanged]);
	return (
		<>
			<AkumaPrimeOptions />

			{data.length === 0 ? (
				<>
					<div className="pad-1rem content">
						<h3 className="title is-5">Keine Daten</h3>
						<p>Keine AKUMA Prime teilnahme.</p>
					</div>
				</>
			) : (
				<div className="table-container pad-bottom-3rem">
					{berichteUiiStore.selectedAgt ? (
						<>
							<div>
								<AkumaPrimeAgtStackedHistory schema={schema} data={data} />
							</div>
						</>
					) : (
						<>{agtAkumaPrimeUiStore.showGraphs && <AkumaPrimeHorizontalBarChart data={data} primeType={AkumaPrimeType.terminierung} schema={schema} />}</>
					)}

					<AkumaPrimeListe
						schema={schema}
						quoteProps={'none'}
						data={data}
						primeType={AkumaPrimeType.terminierung}
						selectedAgt={berichteUiiStore.selectedAgt}
						showKw={!!berichteUiiStore.selectedAgt}
					/>
				</div>
			)}
		</>
	);
});
