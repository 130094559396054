import { Einheit } from 'app/models/core/einheit.model';
import { BnrGpPosModel } from '../bnr.gp.pos.model';

const posDef: BnrGpPosModel[] = [];

posDef.push(new BnrGpPosModel({ year: 2024, posId: 2245001, group: 'vss', posText: 'Weiterbildungspfl. BNR (IDD)', planKey: 'idd', einheit: Einheit.minuten }));

posDef.push(new BnrGpPosModel({ year: 2024, posId: 2245041, group: 'eh', posText: 'Baufinanzierung Bew ', planKey: 'baufinanzierungbew', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2024, posId: 2245042, group: 'eh', posText: 'Bausparen Bewertung ', planKey: 'bausparenbewertung', einheit: Einheit.bew }));
posDef.push(
	new BnrGpPosModel({
		year: 2024,
		posId: 2245101,
		group: 'eh',
		posText: 'Baufinanzierung Allianz Leben und Bausparen ',
		planKey: 'baufiallianzlebensparen',
		einheit: Einheit.bew,
	}),
);

posDef.push(new BnrGpPosModel({ year: 2024, posId: 2245102, group: 'neh', posText: 'Baufinanzierung Bew ', planKey: 'baufinanzierungbewneh', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2024, posId: 2245131, group: 'neh', posText: 'Bausparen Bewertung ', planKey: 'bausparenbewertungneh', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2024, posId: 2245141, group: 'neh', posText: 'Wüstenrot Finanzierung BW', planKey: 'baufinanzierungwuro', einheit: Einheit.bew }));

export const BbsPositionen = posDef;
