import { RefObject } from 'react';
import { IModalButton } from '../Modal';
import { Button } from './Button';

interface IPopover {
	target: RefObject<HTMLElement>;
	title?: string;
	showHighlight?: boolean;
	arrowPosition?: 'left' | 'right' | 'none';
	children?: React.ReactNode;
	buttons?: IModalButton[];
}

export const Popover: React.FC<IPopover> = (props: IPopover) => {
	const { children, target, title, showHighlight, buttons } = props;

	if (!target.current) return null;

	const tp = target.current.getBoundingClientRect();

	const pos = {
		top: tp.top,
		left: tp.right,
	};

	const makeGradientString = (): string => {
		if (showHighlight === false) {
			return 'rgba(0, 0, 0, .35)';
		}

		const w = Math.round(tp.width);
		const h = Math.round(tp.height);
		const x = Math.round(tp.x + tp.width / 2);
		const y = Math.round(tp.y + tp.height / 2);
		const grad = `rgba(0, 0, 0, 0), rgba(0, 0, 0, .35)`;

		return `radial-gradient(${w}px ${h}px at ${x}px ${y}px, ${grad})`;
	};

	const maskStyle = {
		background: makeGradientString(),
	};

	return (
		<>
			<div className="popover-overlay" style={maskStyle}></div>
			<div className="popover" style={pos}>
				{title && <div className="title">{title}</div>}
				{children && <div className="text">{children}</div>}
				{buttons && (
					<div className="actions">
						{buttons.map((b) => {
							return (
								<Button key={b.title} type={'button'} className={b.className} onClick={b.onClick}>
									{b.title}
								</Button>
							);
						})}
					</div>
				)}
			</div>
		</>
	);
};
