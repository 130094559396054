import { useStore } from 'app/context';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { Modal } from '../Modal';
import { NotizViewer } from './NotitzenViewer';

export const NotizViewerModal = observer(() => {
	const { uiStore, notizenUiStore } = useStore();
	const modalId = uiStore.modalIds.notizViewModal;
	const onClose = async () => {
		if (notizenUiStore.currentModalDoc && notizenUiStore.currentModalDoc.isNew) {
			await notizenUiStore.deleteById(notizenUiStore.currentModalDoc.id);
		}

		runInAction(() => {
			notizenUiStore.setCurrentModalDoc(undefined);
			uiStore.hideModal(modalId);
		});
	};
	return (
		<>
			{notizenUiStore.currentModalDoc && (
				<>
					<Modal modalId={modalId} title={'Notiz'} onClose={onClose}>
						<NotizViewer doc={notizenUiStore.currentModalDoc} onClose={onClose} />
					</Modal>
				</>
			)}
		</>
	);
});
