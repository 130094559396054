// @ts-nocheck
// disable checking because of react router typings issues
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { HashRouter as Router, Route, Switch, useHistory } from 'react-router-dom';
import routes, { IRoute } from './routes';
import './App.css';
import { AuthRoute } from 'auth.route';
import { useStore } from 'app/context';
import BusyScreen from 'app/components/BusyScreen';
import 'moment/locale/de';
import moment from 'moment';
import NotFoundContainer from 'app/containers/NotFoundContainer';
import { QueryParamProvider } from 'use-query-params';
import * as Sentry from '@sentry/react';
import { PdfViewer } from 'app/components/PdfViewer';
moment.locale('de');

const appClass = () => {
	const s = window.localStorage.getItem('debug');
	return s !== 'true' ? 'application' : 'application allow-debug';
};

const NotFound: React.FC = observer(() => {
	return <NotFoundContainer />;
});

const HistoryTracer = () => {
	const { userSettingsStore } = useStore();
	const history = useHistory();

	history.listen((props) => {
		userSettingsStore.addHistoryItem(props.pathname, props.search);
	});
	return <></>;
};

if (process.env.REACT_APP_ENV === 'production') {
	Sentry.init({
		dsn: 'https://586c6bdd912c40bca88f3e272d87ac50@o287519.ingest.sentry.io/5890677',
		integrations: [new Sentry.BrowserTracing({})],

		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0,
	});
}

const App: React.FC = observer((props) => {
	const { authStore, session } = useStore();
	const [isSetup, setIsSetup] = useState(false);

	useEffect(() => {
		authStore.setup().then(() => {
			setIsSetup(true);
		});
	}, [authStore]);

	if (process.env.REACT_APP_ENV !== 'production') {
		document.title = process.env.REACT_APP_ENV?.toUpperCase() || 'DVS DEV';
	}

	if (process.env.REACT_APP_ENV === 'production') {
		document.title = 'DVS';
	}

	return (
		<div className={appClass()}>
			{authStore.settingUp && <>Loading...</>}
			{session.isApiBusy && <BusyScreen />}
			{isSetup && (
				<Router>
					<QueryParamProvider ReactRouterRoute={Route}>
						<Switch>
							{Object.keys(routes).map((key, i) => {
								const r = (routes as any)[key] as IRoute;
								if (r.private) {
									return <AuthRoute key={key} exact={r.exact ? r.exact! : true} component={r.container} path={r.route} />;
								}
								return <Route key={key} exact={r.exact ? r.exact : true} component={r.container} path={r.route} />;
							})}
							<Route component={NotFound} />
						</Switch>
						<HistoryTracer />
					</QueryParamProvider>
				</Router>
			)}
			<PdfViewer />
		</div>
	);
});

export default App;
