import { useEffect } from 'react';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { Table } from './../table/table';
import { ITableColumn, TableModel, TableRow } from './../table/table.model';
import { TeamVM } from 'app/stores/ui/team.ui.store';
import { SteartTag } from '../agt/common/SteartTag';
import { useHistory } from 'react-router';
import routes from 'routes';

export const TeamList = observer(() => {
	const { teamUiStore } = useStore();

	const history = useHistory();

	const tm = new TableModel<TeamVM, number>();
	const cols: ITableColumn<TeamVM, number>[] = [
		{
			label: 'Steart',
			path: 'steart.name',
			sortBy: 'steart.sortOrder',
			render: (row: TableRow<TeamVM>) => {
				return <SteartTag id={row.data.steart.id} />;
			},
		},
		{
			label: 'Name',
			path: 'name',
		},
		{
			label: 'BnrId',
			path: 'bnrId',
		},
	];

	tm.setCols(cols);
	tm.sortBy = 'steart.sortOrder';
	tm.idProperty = 'bnrId';
	tm.idType = 'number';

	tm.onRowClick = (row: TableRow<TeamVM>) => {
		history.push(routes.TEAM.getPath(row.data.bnrId.toString(), teamUiStore.currentPanel));
	};

	useEffect(() => {
		teamUiStore.load().then(() => {
			tm.setRowData(teamUiStore.items);
		});
	}, [teamUiStore.items, teamUiStore]);

	return (
		<>
			<Table modifier="fill-last-col" tm={tm} stickyHeader={true}></Table>
		</>
	);
});
