import { useEffect } from 'react';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { Table } from './../../table/table';
import { ITableColumn, TableModel } from './../../table/table.model';
import { useHistory } from 'react-router';
import { BnrMulitDiffVM } from 'app/stores/ui/admin.bnr.diff.ui.store';

export const AdminBnrDiffMultiList = observer(() => {
	const { adminBnrDiffUiStore } = useStore();
	const history = useHistory();

	const tm = new TableModel<BnrMulitDiffVM, number>();
	let cols: ITableColumn<BnrMulitDiffVM, number>[] = [
		{
			label: 'BnrId',
			path: 'diff.bnrId',
		},
		{
			label: 'Name',
			path: 'diff.name',
		},
		{
			label: 'Stat',
			path: 'diff.stat',
		},
		{
			label: 'DVS Benutzer',
			path: 'user.user.name',
		}, {
			label: 'DVS Status',
			path: 'user.user.statusText',
		},
		{
			label: 'DVS Steart',
			path: 'user.user.steart.name',
		},

	];

	tm.setCols(cols);
	tm.sortBy = 'bnrId';
	tm.sortAsc = false;
	tm.idProperty = 'bnrId';
	tm.idType = 'string';

	useEffect(() => {
		adminBnrDiffUiStore.getMissingMulits().then(res => {
			tm.setRowData(res);
		})
	}, [adminBnrDiffUiStore, tm]);

	return (
		<>
			<Table stickyHeader={true} tm={tm}></Table>

			<p>
				<br />
				<strong>noagts:</strong> Keine Agenturen in der Datenlieferung vorhanden.<br />
				<strong>nogpdata:</strong> Kein GP Daten in der Datenlieferung vorhanden.<br />
				<strong>noaccount:</strong> Kein DVS Konto, jedoch Daten vorhanden.

			</p>
		</>
	);
});
