import { useStore } from 'app/context';
import { WidgetItemVm, WidgetsVm } from 'app/models/widgets/widget.vm';
import { observer } from 'mobx-react';
import { Button } from './Button';
import { Icon } from './Icon';

interface IWidgetContainer {
	vm: WidgetsVm;
}

interface IWidgetColumn {
	vm: WidgetsVm;
	cols: WidgetItemVm[];
}
export const WidgetColumn = observer((props: IWidgetColumn) => {
	return (
		<>
			{props.cols.map((w) => (
				<WidgetViewItem widget={w} vm={props.vm} key={w.data.widgetKey} />
			))}
		</>
	);
});

interface IWidgetItem {
	vm: WidgetsVm;
	widget: WidgetItemVm;
}

export const WidgetEditItem = observer((props: IWidgetItem) => {
	const w = props.widget;
	const onUp = () => {
		props.vm.moveUp(w);
	};
	const onDown = () => {
		props.vm.moveDown(w);
	};
	const onLeft = () => {
		props.vm.moveLeft(w);
	};
	const onRight = () => {
		props.vm.moveRight(w);
	};

	const toggleVisible = () => {
		w.toggleEnabled();
	};
	return (
		<div className={`widget-edit-buttons ${w.enabled ? 'widget-visible' : 'widget-hidden'}`}>
			<div className="debug">
				{w.data.widgetKey} {w.col} {w.sort} {w.data.id}
			</div>
			{w.canMoveUp && (
				<Button type="button" className="button is-small is-primary is-inverted  has-icon" onClick={onUp} disabled={!w.canMoveUp}>
					<Icon iconClass="arrow-up" />
				</Button>
			)}
			{w.canMoveDown && (
				<Button type="button" className="button is-small is-primary is-inverted has-icon" onClick={onDown} disabled={!w.canMoveDown}>
					<Icon iconClass="arrow-down" />
				</Button>
			)}
			{w.canMoveLeft && (
				<Button type="button" className="button is-small is-primary is-inverted has-icon" onClick={onLeft} disabled={!w.canMoveLeft}>
					<Icon iconClass="arrow-left" />
				</Button>
			)}
			{w.canMoveRight && (
				<Button type="button" className="button is-small is-primary is-inverted has-icon" onClick={onRight} disabled={!w.canMoveRight}>
					<Icon iconClass="arrow-right" />
				</Button>
			)}
			<Button type="button" className="button is-small is-primary is-inverted has-icon" onClick={toggleVisible}>
				{w.enabled && <Icon iconClass={'eye'} />}
				{!w.enabled && <Icon iconClass={'eye-slash'} />}
			</Button>
		</div>
	);
});

export const WidgetViewItem = observer((props: IWidgetItem) => {
	const w = props.widget;
	const editMode = props.vm.editMode;
	const component = w.data.component;
	return editMode ? (
		<div className="widget-view-item">
			<WidgetEditItem vm={props.vm} widget={w} />
			{component}
		</div>
	) : (
		<>{w.data.enabled && <>{component}</>}</>
	);
});

export const WidgetContainer = observer((props: IWidgetContainer) => {
	const vm = props.vm;

	return (
		<>
			<div className="left">
				<WidgetColumn cols={vm.left} vm={vm} />
			</div>
			<div className="right">
				<WidgetColumn cols={vm.right} vm={vm} />
			</div>
		</>
	);
});

interface IWidgetEditMode {
	vm: WidgetsVm;
}

export const WidgetEditModeButton = observer((props: IWidgetEditMode) => {
	const vm = props.vm;
	const { widgetUiStore } = useStore();

	const onSave = async () => {
		widgetUiStore.save(vm);
		vm.toggleEditMode();
	};

	const onClose = () => {
		vm.undo();
		vm.toggleEditMode();
	};

	return (
		<div className="widget-actions">
			{vm.editMode ? (
				<>
					<Button type="button" className="button is-primary " onClick={onSave}>
						Speichern
					</Button>
					<Button type="button" className="button is-secondary" onClick={onClose}>
						Abbrechen
					</Button>
				</>
			) : (
				<>
					<Button
						className="button is-icon is-widget-edit-button"
						data-tooltip="Widgets bearbeiten"
						data-tooltip-position="left"
						onClick={() => {
							vm.toggleEditMode();
						}}
					>
						<Icon iconClass="cog" />
					</Button>
				</>
			)}
		</div>
	);
});
