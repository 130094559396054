import { IRadioButtonListItem, RadioButtonList } from 'app/components/common/RadioButtonList';
import { CoronaCheckFormItem } from 'app/models/corona/corona.check.form.item';
import { LeadnowCheckFormItem } from 'app/models/leadnowcheck/leadnow.check.form.item';
import { useFormikContext } from 'formik';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

interface IFieldRow {
	formItem: LeadnowCheckFormItem | CoronaCheckFormItem;
	index: number;
}

export const CheckStatusField = observer((props: IFieldRow) => {
	const index = props.index;
	const path = `${index}.data.state`;

	const [val, setVal] = useState<string>('');
	const { getFieldMeta, setFieldValue } = useFormikContext();

	let buttons: IRadioButtonListItem[] = [
		{ icon: 'times', value: 'ignore', classModifier: 'is-red is-inverted' },
		{ icon: 'clock-o', value: 'inprogress', classModifier: 'is-blue is-inverted' },
		{ icon: 'check', value: 'done', classModifier: 'is-green is-inverted' },
	];

	if (props.formItem.data.groupId === 'overall') {
		buttons = [
			{ icon: 'circle-o', classModifier: 'is-check-button is-inverted is-red', label: 'Teilnahme abgelehnt', value: 'rejected' },
			{ icon: 'circle-o', classModifier: 'is-check-button is-inverted is-blue', label: 'Teilnahme gewünscht', value: 'maybe' },
			{ icon: 'circle-o', classModifier: 'is-check-button is-inverted is-green', label: 'Lead.NOW Teilnehmer', value: 'member' },
		];
	}
	useEffect(() => {
		const v = getFieldMeta(path).value;
		if (v) {
			setVal(v as string);
		} else {
			setVal('');
		}
	}, [path, setVal, getFieldMeta]);

	const onSelect = (val: any) => {
		setVal(val);
		setFieldValue(path, val);
	};

	return (
		<>
			<RadioButtonList classModifier="is-checklist" buttons={buttons} value={val} onSelect={onSelect} />
		</>
	);
});
