import { Steart } from 'app/models/core/steart.model';
import { ZeWidgetDef, ZeWidgetItem } from './vbl.ze.widgets';

export const LDPZeWidgets: ZeWidgetDef[] = [
	new ZeWidgetDef({
		title: 'Neuanmeldungen Meine Allianz',
		key: 'neunanmeldungen_maz',

		stearts: [Steart.LDP],
		items: [
			new ZeWidgetItem({
				text: 'Steigerung der Durchdringung von Kund:innen mit Meine Allianz (mAZ) im Bestand.',
				posId: 2320110,
				einheit: '%',
				decimals: 1,
			}),
		],
		// rptPath: `/berichte/production/gp/${moment().year()}/overview`,
	}),
	new ZeWidgetDef({
		title: 'Neue Digi-ID im Bestand (E-Mailadressen und Mobilnummern)',
		key: 'digiid_bestand',
		stearts: [Steart.LDP],
		items: [
			new ZeWidgetItem({
				text: 'Umwandlung Kund:innen ohne Digi ID in Kund:innen mit Digi ID im Bestand. Basis Jahresendbestand Vorjahr',
				posId: 2320105,
				einheit: '%',
				decimals: 1,
			}),
		],
		// rptPath: `/berichte/production/gp/${moment().year()}/overview`,
	}),
];

export const EDPZeWidgets: ZeWidgetDef[] = [
	new ZeWidgetDef({
		title: 'Neuanmeldungen Meine Allianz',
		key: 'neunanmeldungen_maz',
		stearts: [Steart.EDP],
		items: [
			new ZeWidgetItem({
				text: 'Steigerung der Durchdringung von Kund:innen mit Meine Allianz (mAZ) im Bestand. Basis: 01.01.2024',
				posId: 2310105,
			}),
		],
		// rptPath: `/berichte/production/gp/${moment().year()}/overview`,
	}),
	new ZeWidgetDef({
		title: 'Neue Digi-ID im Bestand (E-Mailadressen und Mobilnummern)',
		key: 'digiid_bestand',
		stearts: [Steart.EDP],
		items: [
			new ZeWidgetItem({
				text: 'Umwandlung Kund:innen ohne Digi ID in Kund:innen mit Digi ID im Bestand. Basis Jahresanfangsbestand 2024',
				posId: 2310100,
			}),
		],
		// rptPath: `/berichte/production/gp/${moment().year()}/overview`,
	}),
];

export const EDTZeWidgets: ZeWidgetDef[] = [
	new ZeWidgetDef({
		title: 'Neuanmeldungen Meine Allianz',
		key: 'neunanmeldungen_maz',
		stearts: [Steart.EDT],
		items: [
			new ZeWidgetItem({
				text: 'Steigerung der Durchdringung von Kund:innen mit Meine Allianz (mAZ) im Bestand',
				posId: 2315105,
			}),
		],
		// rptPath: `/berichte/production/gp/${moment().year()}/overview`,
	}),
	new ZeWidgetDef({
		title: 'Neue Digi-ID im Bestand (E-Mailadressen und Mobilnummern)',
		key: 'digiid_bestand',
		stearts: [Steart.EDT],
		items: [
			new ZeWidgetItem({
				text: 'Umwandlung Kund:innen ohne Digi ID in Kund:innen mit Digi ID im Bestand. Basis Jahresendbestand Vorjahr',
				posId: 2315100,
			}),
		],
		// rptPath: `/berichte/production/gp/${moment().year()}/overview`,
	}),
];
