import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { WMHeading } from './PlainTextWochenmail';
import { IWMStyle, WmTableRow } from './Wochenmail';

export const WochenmailTaskStats = observer((props: IWMStyle) => {
	const { wmStyles, isText } = props;
	const { wochenmailtUiStore } = useStore();
	const title = 'Maßnahmen';

	return (
		<>
			{wochenmailtUiStore.loaded && wochenmailtUiStore.showTasks && (
				<>
					{isText ? (
						<>
							<WMHeading title={title} />
							Diese Woche fällig: {wochenmailtUiStore.taskStats.dueThisWeek} <br />
							Offen: {wochenmailtUiStore.taskStats.offen} <br />
							Erledigt: {wochenmailtUiStore.taskStats.erledigt} <br />
							<br />
							<br />
						</>
					) : (
						<WmTableRow>
							<table width={'100%'} style={wmStyles.innerTableStyle}>
								<tbody>
									<tr>
										<td colSpan={3} style={wmStyles.headingTdStyle}>
											<h2 style={{ ...wmStyles.headingStyle, textAlign: 'center' }}>{title}</h2>
										</td>
									</tr>
									<tr>
										<td style={{ ...wmStyles.blueBlock, textAlign: 'center' }}>
											<p>Diese Woche fällig</p>
											<h2 style={{ ...wmStyles.headingStyle, textAlign: 'center' }}>{wochenmailtUiStore.taskStats.dueThisWeek}</h2>
										</td>
										<td style={{ ...wmStyles.yellowBlock, textAlign: 'center' }}>
											<p>Offen</p>
											<h2 style={{ ...wmStyles.headingStyle, textAlign: 'center' }}>{wochenmailtUiStore.taskStats.offen}</h2>
										</td>
										<td style={{ ...wmStyles.greenBlock, textAlign: 'center' }}>
											<p>Erledigt</p>
											<h2 style={{ ...wmStyles.headingStyle, textAlign: 'center' }}>{wochenmailtUiStore.taskStats.erledigt}</h2>
										</td>
									</tr>
								</tbody>
							</table>
						</WmTableRow>
					)}
				</>
			)}
		</>
	);
});
