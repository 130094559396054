import { BaseCachedEntityStore } from './core/base.cached.entity.store';
import { KvAgtModel } from 'app/models/kv.agt.model';
import { KvAgtMsModel } from 'app/models/kv.agt.ms.model';

export class KvAgtBaseStore extends BaseCachedEntityStore<KvAgtModel> {
	idProp: string = 'id';
	apiPath = 'api/kv_agt/XXX';

	createInstances(opts: any): KvAgtModel {
		return new KvAgtModel(opts);
	}
}

export class KvAgtMsBaseStore extends BaseCachedEntityStore<KvAgtMsModel> {
	idProp: string = 'id';
	apiPath = 'api/kv_agt_ms/XXX';

	createInstances(opts: any): KvAgtMsModel {
		return new KvAgtMsModel(opts);
	}
}
