import { useStore } from 'app/context';
import { observer } from 'mobx-react';

import FormField from 'app/components/form/FormField';
import { Button } from 'app/components/common/Button';
import { autorun } from 'mobx';
import { useState } from 'react';
import { InputControl } from 'app/components/common/InputControl';
import { UserModel } from 'app/models/core/user.model';
import { Modal } from 'app/components/Modal';
import routes from 'routes';
import { useHistory } from 'react-router';
import { SelectInput } from 'app/components/common/Select';

export const AdminNewMultiUserForm = observer(() => {
	const { adminUserUiStore, uiStore, agenturListUiStore } = useStore();
	const [existingBnrIdUser, setExistingBnrIdUser] = useState<UserModel>();
	const [existingBenslUser, setExistinBenslgUser] = useState<UserModel>();
	const [bnrId, setBnrId] = useState<number>(0);
	const [bensl, setBensl] = useState<string>('');
	const [steart, setSteart] = useState<string>('1001');
	const history = useHistory();
	const onClose = () => {
		uiStore.hideModal(uiStore.modalIds.adminNewMulti);
	};
	const openForm = async () => {
		history.push(routes.ADMINUSERS.getPath(bnrId.toString(), 'user', bensl, steart));
		onClose();
	};
	const onBnrIdChange = (name: string, val: string) => {
		autorun(
			() => {
				setExistingBnrIdUser(undefined);
				const _bnrId = Number(val);
				if (isNaN(_bnrId)) {
					return;
				}
				setBnrId(_bnrId);
				adminUserUiStore.findUserByBnrId(_bnrId).then((res) => {
					if (res && res.userId) {
						setExistingBnrIdUser(res);
					}
				});
			},
			{ delay: 500 },
		);
	};

	const onBenslChange = (name: string, val: string) => {
		autorun(
			() => {
				setExistinBenslgUser(undefined);
				setBensl(val);
				adminUserUiStore.findUserByBensl(val).then((res) => {
					if (res && res.user.userId) {
						setExistinBenslgUser(res.user);
					}
				});
			},
			{ delay: 500 },
		);
	};



	const onSteartChange = (name: string, opt: any) => {
		if (!opt) {
			return;
		}
		setSteart(opt.value)
	}

	return (
		<>
			<div>
				<FormField className="title" label="Bitte BnrId eingebe">
					<InputControl inline={true} label="BnrId" name="bnrId" value={bnrId} onChange={onBnrIdChange} placeholder={'Bitte BnrId eingeben'} />
					{/* BnrId darf nicht einem Benutzer zugeschlüsselt sein. */}
				</FormField>

				<FormField className="title" label="Bitte Bensl eingeben">
					<InputControl inline={true} label="Bensl" name="bensl" value={bensl} onChange={onBenslChange} placeholder={'Bitte Bensl eingeben'} />
				</FormField>
				<FormField className="title" label="VD">
					<SelectInput
						name="steart"
						// isMulti={false}
						emptyText="Steart Auswählen"
						value={steart}
						onChange={onSteartChange}
						opts={agenturListUiStore.steartMultiOpts}
					/>
				</FormField>

			</div>
			<div>
				{existingBnrIdUser && existingBnrIdUser.userId && (
					<>
						Benutzer mit BnrId {bnrId} existiert bereits: {existingBnrIdUser.name} / {existingBnrIdUser.bensl} /{existingBnrIdUser.email}
					</>
				)}

				{existingBenslUser && existingBenslUser.userId && (
					<>
						Benutzer mit Bensl {bensl} existiert bereits: {existingBenslUser.name} / {existingBenslUser.bensl} /{existingBenslUser.email}
					</>
				)}
			</div>
			<div> </div>

			<div className="modal-footer">
				<Button type="button" className="button is-secondary" onClick={onClose}>
					Abbrechen
				</Button>
				<Button type="button" className="button is-primary" disabled={!!existingBnrIdUser || !!existingBenslUser} onClick={openForm}>
					Neuen Benutzer anlegen
				</Button>
			</div>
		</>
	);
});

export const AdminNewMultiModal = observer(() => {
	const { uiStore } = useStore();
	const open = () => {
		uiStore.showModal(uiStore.modalIds.adminNewMulti);
	};
	return (
		<>
			<Button type="button" className="button is-small" onClick={open}>
				Neuen Multi anlegen
			</Button>
			<Modal title="Neuen Multi anlegen" modalId={uiStore.modalIds.adminNewMulti}>
				<AdminNewMultiUserForm />
			</Modal>
		</>
	);
});
