import { SelectInput } from 'app/components/common/Select';
import { AgenturNameCell } from 'app/components/shared/AgenturName.cell';
import { Table } from 'app/components/table/table';
import { AggregrationType, ITableColumn, TableModel, TableRow } from 'app/components/table/table.model';
import { useStore } from 'app/context';
import { LeadNowAbos, LeadNowType, LeadNowVM } from 'app/stores/ui/agt.leadnow.ui.store';
import { formatNum } from 'app/utils';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import { LeadNowBarChart } from './LeadNowBarChart';
import { useHistory } from 'react-router';
import routes from 'routes';
import _ from 'lodash';

export const LeadNowOptions = observer(() => {
	const { agtLeadNowUiStore } = useStore();

	const onMinChange = (name: string, opt: any) => {
		runInAction(() => {
			agtLeadNowUiStore.minKwCurrent = parseInt(opt.value);
		});
	};

	const onMaxChange = (name: string, opt: any) => {
		runInAction(() => {
			agtLeadNowUiStore.maxKwCurrent = parseInt(opt.value);
		});
	};

	return (
		<>
			<div className="grid is-col-auto align-center justify-start pad-1rem">
				<strong>Zeitraum: </strong>
				<div>
					<SelectInput name="kwMin" emptyText="Start KW" value={agtLeadNowUiStore.minKw} onChange={onMinChange} opts={agtLeadNowUiStore.kwsOpts} isClearable={false} />
				</div>
				<span>bis</span>
				<div>
					<SelectInput name="kwMax" emptyText="End KW" value={agtLeadNowUiStore.maxKw} onChange={onMaxChange} opts={agtLeadNowUiStore.kwsOpts} isClearable={false} />
				</div>
			</div>
		</>
	);
});

interface ILeadnowListe {
	type: LeadNowType;
}

export const LeadNowListe = observer((props: ILeadnowListe) => {
	const { berichteUiiStore, agtLeadNowUiStore, agenturListUiStore } = useStore();
	const history = useHistory();
	const [hasItems, setHasItems] = useState(false);

	const type = props.type === LeadNowType.brutto ? 'brutto' : 'netto';

	const tm = useMemo(() => {
		const tm = new TableModel<LeadNowVM, number>();
		const cols: ITableColumn<LeadNowVM, number>[] = [];

		cols.push({
			label: 'Agentur',
			path: 'agt.name',
			sortBy: 'agt.name',
			render: AgenturNameCell,
		});

		LeadNowAbos.forEach((c) => {
			cols.push({
				label: c.label,
				path: `${c.path}.${type}`,
				format: formatNum,
				agg: [
					{
						path: `${c.path}.${type}`,
						aggType: AggregrationType.sum,
					},
				],
			});
		});

		tm.sortBy = 'agt.name';
		tm.setCols(cols);

		tm.idProperty = 'agtId';
		tm.idType = 'number';

		tm.onRowClick = (row: TableRow<LeadNowVM>) => {
			const agtId = row.data.agt.agtId.toString();
			history.push(routes.AGENTUR.getPath(agtId, 'berichte', 'kunde', 'leadnow', type));
		};

		return tm;
	}, [history, type]);

	useEffect(() => {
		agtLeadNowUiStore.load().then(() => {
			let items = agtLeadNowUiStore.currentKWItems;
			if (berichteUiiStore.selectedAgt) {
				const agtId = berichteUiiStore.selectedAgt.agtId;
				items = items.filter((i) => i.agt.agtId === agtId);
			} else {
				items = agenturListUiStore.applyFilterOnAgt(items) as LeadNowVM[];
			}
			setHasItems(items.length > 0);
			tm.setRowData(items);
			tm.sort();
		});
	}, [
		tm,
		berichteUiiStore.selectedAgt,
		agenturListUiStore.filterHasChanged,
		agenturListUiStore,
		agtLeadNowUiStore.allVmItems,
		agtLeadNowUiStore.currentKWItems,
		agtLeadNowUiStore.maxKw,
		agtLeadNowUiStore.minKw,
		agtLeadNowUiStore,
	]);

	return (
		<>
			<LeadNowOptions />
			{!hasItems ? (
				<div className="pad-1rem">Keine Lead.NOW Teilnahme</div>
			) : (
				<>
					<LeadNowBarChart type={props.type} items={tm.data} />
					<Table stickyHeader={true} tm={tm}></Table>
				</>
			)}
		</>
	);
});
