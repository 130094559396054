import { BaseEntityStore } from './core/base.entity.store';

export class NewsModel {
	id: string;
	title: string;
	created: Date;
	text: string;
	publishDate: Date;
	authors: string;
	publishForRoles: string[];

	constructor(opts: any) {
		this.id = opts.id;
		this.title = opts.title;
		this.text = opts.text;
		this.created = opts.created;
		this.publishDate = opts.publishDate;
		if (!opts.text) {
			this.text = '';
		}

		this.authors = opts.authors;
		if (!this.authors) {
			this.authors = '';
		}
		this.publishForRoles = opts.publishForRoles;
		if (!this.publishForRoles) {
			this.publishForRoles = ['VBL'];
		}
	}
}

export class NewsStore extends BaseEntityStore<NewsModel> {
	apiPath = 'api/news';
	idProp: string = 'id';
	createInstances(opts: any): NewsModel {
		return new NewsModel(opts);
	}
}
