import { CoronaCheckFormItem } from 'app/models/corona/corona.check.form.item';
import { CoronaCheckVM } from 'app/models/corona/corona.check.vm';
import { AgenturStore } from '../agentur.store';
import { KvCoronaCheckStore } from '../kv.agt.coronacheck.store';
import { SessionStore } from '../session.store';

export class AgtCoronaCheckUiStore {
	session: SessionStore;
	agenturStore: AgenturStore;
	kvCoronaCheckStore: KvCoronaCheckStore;

	constructor(session: SessionStore, agenturStore: AgenturStore, kvLeadnowCheckStore: KvCoronaCheckStore) {
		this.session = session;
		this.agenturStore = agenturStore;
		this.kvCoronaCheckStore = kvLeadnowCheckStore;
	}

	items: CoronaCheckVM[] = [];
	loaded: boolean = false;
	async findAll() {
		if (this.loaded) {
			return this.items;
		}
		const agts = await this.agenturStore.findAll();
		const items = await this.kvCoronaCheckStore.findAll();

		const res: CoronaCheckVM[] = [];
		agts.forEach((agt) => {
			const agtItems = items.filter((i) => agt.agtId === i.agtId);
			res.push(new CoronaCheckVM(agt, agtItems));
		});
		this.items = res;
		this.loaded = true;

		return res;
	}

	async findByAgtId(agtId: number) {
		const items = await this.findAll();
		return items.find((i) => i.agtId === agtId);
	}

	async saveMany(agtId: number, items: CoronaCheckFormItem[]) {
		for (const item of items) {
			item.data.agtId = agtId;
			item.data.bnrId = this.session.currentUser!.bnrId;
			await this.kvCoronaCheckStore.save(item.data);
		}
		this.loaded = false;
	}
}
