import { useStore } from 'app/context';
import { Field, Form, Formik } from 'formik';
import { observer } from 'mobx-react';

import * as Yup from 'yup';
import { UserVM } from 'app/stores/ui/admin.user.ui.store';
import { notify } from 'app/components/common/notify';
import FormField from 'app/components/form/FormField';
import { Button } from 'app/components/common/Button';
import { UserStatusSelectField } from 'app/components/form/UserStatusSelectField';
import { formatDateLong } from 'app/utils';
import { UserStatus } from 'app/models/core/user.model';
import { AdminUserHistoryList } from './AdminUserHistoryList';
import routes from 'routes';
import { useHistory } from 'react-router';
import { SelectInput } from 'app/components/common/Select';
import { useState } from 'react';
import { FindSteart, SteartFromId } from 'app/models/core/steart.model';

const adminUserEditFormSchema = Yup.object({
	bensl: Yup.string().required('Bensl ist erfoderlich').default(''),
	bnrId: Yup.number().required(),
	firstName: Yup.string().required(),
	lastName: Yup.string().required(),
	steart: Yup.string(),
	status: Yup.string().required(),
	tel: Yup.string(),
	mobil: Yup.string(),
	email: Yup.string().required(),
	comment: Yup.string().default(''),
	demoBnrId: Yup.string()
}).required();

type AdminUserEdit = Yup.InferType<typeof adminUserEditFormSchema>;

const valOrEmptyString = (val: any) => {
	if (val) {
		return val;
	}
	return '';
};

interface IUserEditForm {
	user: UserVM;
	onClose: () => void;
}
export const AdminUserEditForm = observer((props: IUserEditForm) => {
	const { adminUserUiStore, agenturListUiStore } = useStore();
	const history = useHistory();
	const user = props.user;
	const isNew = !user.user.userId;
	const steartId = user.user.steart.id;
	const [steart, setSteart] = useState<string>(steartId.toString());


	const handleSave = async (values: AdminUserEdit) => {
		const u = user.user;
		if (!u.userId) {
			u.bensl = values.bensl;
		}
		u.bnrId = parseInt(values.bnrId.toString());
		u.firstName = values.firstName;
		u.lastName = values.lastName;
		u.comment = values.comment;
		u.email = values.email || '';
		u.tel = values.tel || '';
		u.mobil = values.mobil || '';
		u.demoBnrId = values.demoBnrId;

		if (!isNew && user.user.isMulti && steart) {
			u.steart = FindSteart(parseInt(steart));
		}

		if (u.demoBnrId === '') {
			u.demoBnrId = undefined;
		}
		if (values.status) {
			let status = parseInt(values.status);
			u.status = status as UserStatus;
		}
		try {

			const vm = await adminUserUiStore.save(user);
			if (isNew) {
				notify('Benutzer wurde angelegt', '');
				return vm.user.userId;
			} else {
				notify('Benutzer wurde gespeichert', '');
			}
			return true;
		} catch (ex) {
			notify('Fehler beim Anlegen/Speichern des Benutzers', '', 'error');
			return false;
		}
	};

	const handleSubmit = async (values: AdminUserEdit) => {
		const ret = await handleSave(values);
		if (ret === true) {
			props.onClose();
		}
		if (ret !== false) {
			history.push(routes.ADMINUSERS.getPath(ret.toString()));
			return;
		}
	};

	return (
		<>
			<Formik
				validationSchema={adminUserEditFormSchema}
				initialValues={{
					bensl: valOrEmptyString(user.user.bensl),
					bnrId: valOrEmptyString(user.user.bnrId),
					firstName: valOrEmptyString(user.user.firstName),
					lastName: valOrEmptyString(user.user.lastName),
					steart: user.user.steart.id.toString(),
					status: user.user.status.toString(),
					tel: valOrEmptyString(user.user.tel),
					mobil: valOrEmptyString(user.user.mobil),
					email: valOrEmptyString(user.user.email),
					comment: valOrEmptyString(user.user.comment),
					demoBnrId: valOrEmptyString(user.user.demoBnrId)
				}}
				onSubmit={handleSubmit}
			>
				{({ errors, touched, isValid, values }) => {
					const sendUserInvite = async () => {
						if (!isValid) {
							notify('Kein Versand, da noch Fehler', '', 'error');
							return;
						}
						if (!user.user.bensl) {
							notify('Kein Bensl', '', 'error');
							return;
						}
						await handleSave(values);
						await adminUserUiStore.sendUserInvite(user.user);
					};

					const onSteartChange = (name: string, opt: any) => {
						if (!opt) {
							return;
						}
						setSteart(opt.value)
					}



					return (
						<Form>
							<div className="pad-1rem">
								<FormField className="bensl is-inline" error={errors.bensl} touched={touched.bensl} label="Bensl">
									{user.user.userId ? <>{user.user.bensl}</> : <Field type="text" name="bensl" placeholder="Bensl" />}
								</FormField>

								<FormField className="is-inline" error={errors.bnrId} touched={touched.bnrId} label="BnrId">
									{user.user.bnrId !== 123 ? <Field type="text" name="bnrId" placeholder="BnrId" /> : <>{'123'}</>}
								</FormField>
								<FormField className="is-inline" error={errors.firstName} touched={touched.firstName} label="Vorname">
									<Field type="text" name="firstName" placeholder="Vorname" />
								</FormField>

								<FormField className="is-inline" error={errors.lastName} touched={touched.lastName} label="Nachname">
									<Field type="text" name="lastName" placeholder="Nachname" />
								</FormField>

								<FormField className="is-inline" label="Role">
									{user.user.bnrId === 123 && <>ADMIN</>}
									{user.user.authorities.map((a) => (
										<span key={a.name}>{a.name} </span>
									))}
								</FormField>
								<FormField className="is-inline" label="Status">
									<UserStatusSelectField name={'status'} />
								</FormField>
								{!isNew && user.user.isMulti &&
									<FormField className="is-inline" label="Stellenart (Nur Multis)">
										<SelectInput
											name="steart"
											// isMulti={false}
											emptyText="Steart Auswählen"
											value={steart}
											onChange={onSteartChange}
											opts={agenturListUiStore.steartMultiOpts}
										/>
									</FormField>
								}

								<FormField className="is-inline" error={errors.tel} touched={touched.tel} label="Telefon">
									<Field type="text" name="tel" placeholder="Telefon" />
								</FormField>
								<FormField className="is-inline" error={errors.mobil} touched={touched.mobil} label="Mobil">
									<Field type="text" name="mobil" placeholder="Mobil" />
								</FormField>
								<FormField className="is-inline" error={errors.email} touched={touched.email} label="E-Mail">
									<Field type="text" name="email" placeholder="E-Mail" />
								</FormField>
								<FormField className="is-inline" error={errors.comment} touched={touched.comment} label="Kommentar">
									<Field type="text" className="input" as="textarea" name="comment" placeholder="Kommentar" />
								</FormField>
								{user.user.isMulti && <>
									<FormField className="is-inline" error={errors.demoBnrId} touched={touched.demoBnrId} label="DemoBnrId (für Multis hier die VD eintragen)">
										<Field type="text" className="input" name="demoBnrId" placeholder="DemoBnrId" />
									</FormField>
								</>}
							</div>

							<div className="pad-1rem grid is-col-half border-top">
								<div className="is-left">
									<Button type="button" className="button is-secondary" onClick={sendUserInvite} disabled={values.status !== '0'}>
										Neuen User Einladen
									</Button>
								</div>

								<div className="is-right">
									<Button type="button" className="button is-secondary" onClick={props.onClose}>
										Abbrechen
									</Button>
									<Button type="submit" className="button is-primary " disabled={!isValid}>
										Speichern
									</Button>
								</div>
							</div>

							<FormField className="is-inline" label="Angemeldet in der DVS">
								{user.user.registeredInDvs ? <>JA</> : <>NEIN</>}
							</FormField>

							<FormField className="is-inline" label="Orga">
								VD : {user.user.firstVd} <br />
								GS : {user.user.firstGs}
							</FormField>

							<FormField className="is-inline" label="Letzter Login">
								{formatDateLong(user.user.lastLoginDate)}
							</FormField>
							<FormField className="is-inline" label="Letzter Login Versuch">
								{formatDateLong(user.user.lastAttemptDate)}
							</FormField>
							<FormField className="is-inline" label="Passwort zurückgesetzt am">
								{formatDateLong(user.user.lastPasswordResetDate)}
							</FormField>
							<FormField className="is-inline" label="Letzte 2FA Anforderung am">
								{formatDateLong(user.user.last2FADate)}
							</FormField>

							<FormField className="is-inline" label="DemoBnrId für Multis">
								{user.user.demoBnrId}
							</FormField>

							<AdminUserHistoryList user={user.user} />
						</Form>
					);
				}}
			</Formik>
		</>
	);
});
