import { Button } from 'app/components/common/Button';
import { CheckboxControl } from 'app/components/common/CheckboxControl';
import { useStore } from 'app/context';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { useState } from 'react';

export const ResetAllPasswords = observer(() => {
	const { adminUserLoginUiStore } = useStore();

	const [bulkResetSuccess, setBulkResetSuccess] = useState(false);

	const clickOne = () => {
		runInAction(() => {
			adminUserLoginUiStore.resetStepOne = 1;
		});
	};

	const clickTwo = () => {
		runInAction(() => {
			adminUserLoginUiStore.resetStepTwo = 1;
		});
	};

	const clickThree = () => {
		runInAction(async () => {
			await adminUserLoginUiStore.resetAllPassword();
			setBulkResetSuccess(true);
		});
	};

	if (bulkResetSuccess) {
		return (
			<>
				<div className="content">
					<h3>Alle Passwörter zurückgesetzt</h3>
					<img src="/media/nothing-to-see-here.webp" alt="fire" />
				</div>
			</>
		);
	}

	return (
		<>
			<div className="content">
				<h3>Alle Passwörter zurücksetzen</h3>

				<p>
					Wenn aus gegebenem Anlass <strong>ALLE</strong> Passwörter der DVS zurückgsetzt werden müssen, dann bitte auf den folgenden Button klicken. <br />
					Im Anschluß musst du die Aktion nochmal bestätigen. <br />
					Diese Aktion kann <strong>NICHT RÜCKGÄNGIG</strong> gemacht werden <br />
					<strong>Eine Mail mit deinem Bensl wird an den Gruppenpostkorb versendet!</strong> <br />
				</p>
			</div>

			{adminUserLoginUiStore.resetStepOne === 0 && (
				<>
					<Button className="button is-red is-inverted is-small" type="button" onClick={clickOne}>
						Alle Passwörter zurücksetzen?
					</Button>
				</>
			)}

			{adminUserLoginUiStore.resetStepOne === 1 && (
				<>
					<div className="content pad-bottom-1rem">
						<h2>Letzte Chance...</h2>

						<CheckboxControl
							label="Ich habe den Text gelesen und möchte trotzdem ALLE Passwörter zurücksetzen."
							name="sure"
							value={adminUserLoginUiStore.resetStepTwo === 1}
							onChange={clickTwo}
						/>
					</div>

					<Button className="button is-red" disabled={adminUserLoginUiStore.resetStepTwo === 0} onClick={clickThree}>
						Alle Passwörter zurücksetzen
					</Button>
				</>
			)}
		</>
	);
});
