import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { Link, useLocation } from 'react-router-dom';

export const AgenturMenu = observer(() => {
	const { agenturUiStore } = useStore();
	const menu = agenturUiStore.topMenu;
	const agt = agenturUiStore.current;
	const location = useLocation();
	return (
		<nav className="side-panel-nav">
			{agt &&
				menu &&
				menu.items.map((item) => (
					<Link key={item.name} className={item.active ? 'is-active' : ''} to={item.path + location.search}>
						{item.name}
					</Link>
				))}
		</nav>
	);
});
