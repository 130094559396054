import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { TeamNewDokument } from './TeamDokumente';

export const TeamMenu = observer(() => {
	const { teamUiStore } = useStore();
	const menu = teamUiStore.topMenu;
	const team = teamUiStore.current;
	return (
		<nav className="side-panel-nav">
			{team &&
				menu &&
				menu.items.map((item) => (
					<Link key={item.name} className={item.active ? 'is-active' : ''} to={item.path}>
						{item.name}
					</Link>
				))}
			<>
				<TeamNewDokument />
			</>
		</nav>
	);
});
