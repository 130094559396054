import BaseContainer from 'app/containers/BaseContainer';
import { observer } from 'mobx-react';
import { MainMenu } from './MainMenu';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useStore } from 'app/context';
import React, { useEffect, useState } from 'react';
import { runInAction } from 'mobx';
import { WidgetContainer, WidgetEditModeButton } from 'app/components/common/WidgetContainer';
import { WidgetScope } from 'app/models/widgets/widget.setting.model';
import { WidgetsVm } from 'app/models/widgets/widget.vm';
import routes from 'routes';

const HomeContainer = observer((props: RouteComponentProps) => {
	const { session, agenturUiStore } = useStore();
	const history = useHistory();

	useEffect(() => {
		runInAction(() => {
			agenturUiStore.current = undefined;
		});
	}, [agenturUiStore]);

	const currentUser = session.currentUser;

	const { widgetUiStore } = useStore();
	const [vm, setVm] = useState<WidgetsVm>();

	useEffect(() => {
		if (session.isAdmin) {
			return history.push(routes.ADMINHOME.getPath());
		}
		widgetUiStore.findByScope(WidgetScope.home).then((res) => {
			setVm(new WidgetsVm(res));
		});
	}, [widgetUiStore, history, session.isAdmin]);

	return (
		<BaseContainer sideMenu={<MainMenu {...props} />} navbar={<div>Home</div>} actionControls={vm && <WidgetEditModeButton vm={vm} />}>
			<div className="dashboard is-home">{currentUser && vm && <WidgetContainer vm={vm} />}</div>
		</BaseContainer>
	);
});

export default HomeContainer;
