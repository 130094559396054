import { PosVal } from './pos.val';

export class PosBjModel {
	constructor(opts: any) {
		this.agtId = opts.agtId;
		this.bm = opts.bm;
		this.ergBt = opts.ergBt;
		this.ergSt = opts.ergSt;
		this.posId = opts.posId;
	}
	agtId: number;
	bm: number;
	ergBt: number;
	ergSt: number;
	posId: number;

	getPosval() {
		return new PosVal(this.ergBt, this.ergSt);
	}
}
