import { makeObservable, observable } from 'mobx';
import { BaseCachedEntityStore } from './core/base.cached.entity.store';

export class AgtYearModel {
	constructor(item: any) {
		makeObservable(this);
		this.agtId = item.agtId;
		this.bnrId = item.bnrId;
		this.year = item.year;
		this.schwerpunktBBS = item.schwerpunktBBS ? item.schwerpunktBBS : false;
		this.schwerpunktBFG = item.schwerpunktBFG ? item.schwerpunktBFG : false;
		this.schwerpunktKME = item.schwerpunktKME ? item.schwerpunktKME : false;
		this.schwerpunktKVS = item.schwerpunktKVS ? item.schwerpunktKVS : false;
		this.schwerpunktSPS = item.schwerpunktSPS ? item.schwerpunktSPS : false;
		this.schwerpunktUB = item.schwerpunktUB ? item.schwerpunktUB : false;
		this.schwerpunktVAS = item.schwerpunktVAS ? item.schwerpunktVAS : false;
		this.schwerpunktBSG = item.schwerpunktBSG ? item.schwerpunktBSG : false;
		this.schwerpunktEDT = item.schwerpunktEDT ? item.schwerpunktEDT : false;
		this.schwerpunktEDP = item.schwerpunktEDP ? item.schwerpunktEDP : false;
	}

	agtId: number;
	bnrId: number;
	year: number;
	@observable schwerpunktBBS: boolean = false;
	@observable schwerpunktBFG: boolean = false;
	@observable schwerpunktKME: boolean = false;
	@observable schwerpunktKVS: boolean = false;
	@observable schwerpunktSPS: boolean = false;
	@observable schwerpunktUB: boolean = false;
	@observable schwerpunktVAS: boolean = false;
	@observable schwerpunktBSG: boolean = false;
	@observable schwerpunktEDT: boolean = false;
	@observable schwerpunktEDP: boolean = false;
}

export class AgtYearStore extends BaseCachedEntityStore<AgtYearModel> {
	apiPath = 'api/agt/year';
	idProp: string = 'id';
	createInstances(opts: any): AgtYearModel {
		return new AgtYearModel(opts);
	}
}
