import { computed, makeObservable, observable } from "mobx";
import { AdminWochenmailKwStore, KvWochenmailModel } from "../admin.wochenmail.kw.store";
import moment from "moment";
import { AdminUserUiStore, UserVM } from "./admin.user.ui.store";



export class WochenmailUserVM {
    constructor(mail: KvWochenmailModel, user?: UserVM) {
        this.mail = mail;
        this.user = user;
    }
    mail: KvWochenmailModel;
    user?: UserVM
}


export class AdminWochenmailUiStore {
    adminWochenmailKwStore: AdminWochenmailKwStore
    adminUserUiStore: AdminUserUiStore

    constructor(adminWochenmailKwStore: AdminWochenmailKwStore, adminUserUiStore: AdminUserUiStore) {
        makeObservable(this);
        this.adminWochenmailKwStore = adminWochenmailKwStore;
        this.adminUserUiStore = adminUserUiStore;
    }

    @observable
    currentKw: number = parseInt(moment().format('YYYYww'));


    @computed
    get currentItems() {

        return this.allItems.filter(x => x.mail.bm === this.currentKw);
    }



    @observable
    allItems: WochenmailUserVM[] = [];

    isLoaded: boolean = false;

    async load() {

        if (this.isLoaded) {
            return;
        }


        await Promise.all([this.adminWochenmailKwStore.findAll(), this.adminUserUiStore.load()]);

        const res: WochenmailUserVM[] = [];
        this.adminWochenmailKwStore._items.forEach(m => {
            const u = this.adminUserUiStore._items.find(x => x.user.bnrId === m.bnrId);
            res.push(new WochenmailUserVM(m, u));
        })
        this.allItems = res;
        this.isLoaded = true;



    }


}