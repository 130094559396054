import _ from 'lodash';
import { action, computed, makeObservable, observable } from 'mobx';
import { SessionStore } from '../session.store';
import { AgtBpVM, AgtBranchenplanungUiStore } from './agt.branchenplanung.ui.store';
import { AgtPlanUiStore, AgtPlanYearVM } from './agt.plan.ui.store';
import { DocumentUiStore, DocumentVM, SendDocPerEmailData } from './document.ui.store';
import { TemplateUiStore, TemplateVM } from './template.ui.store';

class ExportDocType {
	constructor(type: string, label: string, count: number) {
		makeObservable(this);
		this.type = type;
		this.label = label;
		this.count = count;
	}
	@observable
	type: string;
	@observable
	label: string;
	count: number;
	@observable
	selected: boolean = false;
}

export class AgtPlanExportOpts {
	constructor(plan: AgtPlanYearVM, docs: DocumentVM[], templates: TemplateVM[], bpData: AgtBpVM | undefined) {
		makeObservable(this);
		this.plan = plan;
		this.docs = docs;
		this.bpData = bpData;

		const cats = _.uniq(docs.map((d) => d.doc.category));

		const selectedDocTypes: ExportDocType[] = [];

		cats.forEach((cat) => {
			const t = templates.find((t) => t.template.content.category === cat);
			const catDogs = docs.filter((d) => d.doc.category === cat);
			let title = t ? t.title : cat;
			if (cat === 'upload') {
				let uploadCount = 0;
				catDogs.forEach((d) => (uploadCount += d.uploads.length));
				this.attachmentsCount = uploadCount;
				return;
			}
			if (cat === 'ap') {
				title = 'Planungstool';
			}

			if (cat === 'jpg') {
				title = 'Jahresplanung';
			}

			const exp = new ExportDocType(cat, title, catDogs.length);
			selectedDocTypes.push(exp);
		});
		this.selectedDocTypes = selectedDocTypes;
	}

	plan: AgtPlanYearVM;
	docs: DocumentVM[];

	@observable
	selectedDocTypes: ExportDocType[];

	@computed
	get title() {
		return `Export für ${this.plan.agt.name} ${this.plan.year}`;
	}

	@computed
	get allCount() {
		let s = 0;
		this.selectedDocTypes.forEach((dt) => (s += dt.count));
		return s;
	}

	@computed
	get apIsSelected() {
		const aps = this.selectedDocTypes.find((dt) => dt.type === 'ap');
		if (aps && aps.selected) {
			return true;
		}
		return false;
	}

	all: boolean = false;
	@action
	setAll(v: boolean) {
		this.all = v;
		this.bp = v;
		this.goals = v;
		this.tasks = v;
		// this.topics = v;
		this.attachments = v;
		this.selectedDocTypes.forEach((dt) => (dt.selected = v));
	}

	@computed
	get docIds() {
		let ids: string[] = [];
		this.selectedDocTypes.forEach((dt) => {
			if (!dt.selected) {
				return;
			}
			const ds = this.docs.filter((d) => d.doc.category === dt.type).map((d) => d.id);
			ids = ids.concat(ds);
		});
		return ids;
	}

	@observable
	bp: boolean = false;
	bpData?: AgtBpVM;

	@observable
	combined: boolean = false;
	@observable
	goals: boolean = false;
	@observable
	tasks: boolean = false;
	// @observable
	// topics: boolean = false;
	@observable
	attachments: boolean = false;
	@observable
	attachmentsCount: number = 0;

	serialize() {
		let bpRows;
		if (this.bpData) {
			bpRows = this.bpData.myPoses.map((bp) => {
				const v = _.get(this.bpData!, [bp.planKey]);
				return {
					label: bp.posText,
					prop: bp.planKey,
					unit: bp.einheit.shortText,
					unitShort: bp.einheit.longText,
					value: v,
				};
			});
		}
		return {
			planId: this.plan.plan.id!,
			combined: this.combined,
			bp: this.bp,
			bpData: { bpRows: bpRows },
			docIds: this.docIds,
			goals: this.goals,
			tasks: this.tasks,
			topics: this.tasks, // tasks and topics only together
			attachments: this.attachments,
		};
	}
}

export class AgtPlanExportUiStore {
	session: SessionStore;
	documentUiStore: DocumentUiStore;
	agtPlanUiStore: AgtPlanUiStore;
	agtBranchenplanungUiStore: AgtBranchenplanungUiStore;
	templateUiStore: TemplateUiStore;

	constructor(session: SessionStore, agtPlanUiStore: AgtPlanUiStore, documentUiStore: DocumentUiStore, agtBranchenplanungUiStore: AgtBranchenplanungUiStore, templateUiStore: TemplateUiStore) {
		this.session = session;
		this.agtPlanUiStore = agtPlanUiStore;
		this.documentUiStore = documentUiStore;
		this.agtBranchenplanungUiStore = agtBranchenplanungUiStore;
		this.templateUiStore = templateUiStore;
	}

	async getExportModel() {
		if (!this.agtPlanUiStore.current) {
			return;
		}
		const bp = await this.agtBranchenplanungUiStore.findByYearAndAgtId(this.agtPlanUiStore.current.year, this.agtPlanUiStore.current.agtId);
		return new AgtPlanExportOpts(this.agtPlanUiStore.current, this.documentUiStore.currentItems, this.templateUiStore.items, bp);
	}

	async export(exp: AgtPlanExportOpts) {
		this.session.incApiCallCount();
		await this.documentUiStore.bnrDocumentStore.multiDocExport(exp).finally(() => {
			this.session.decApiCallCount();
		});
	}

	async multiDocSend(mailData: SendDocPerEmailData, exp: AgtPlanExportOpts) {
		this.session.incApiCallCount();
		await this.documentUiStore.bnrDocumentStore.multiDocSend(mailData, exp).finally(() => {
			this.session.decApiCallCount();
		});
	}
}
