import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { TreeNode } from '../common/TreeNode';
import { TreeNodeModel } from 'app/models/core/tree.node.model';
import { useEffect, useState } from 'react';

export const BerichteTreeMenu = observer(() => {
	const { berichteUiiStore } = useStore();
	const [nodes, setNodes] = useState<TreeNodeModel[]>([]);
	useEffect(() => {
		berichteUiiStore.ensureLateLoadedNodes().then(() => {
			setNodes(berichteUiiStore.menu.nodes);
		});
	}, [berichteUiiStore.menu.nodes]);
	return (
		<div className="tree-nodes-container">
			<TreeNode nodes={nodes} treeStore={berichteUiiStore} />
		</div>
	);
});
