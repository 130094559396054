import { Einheit } from 'app/models/core/einheit.model';
import { BnrGpPosModel } from '../bnr.gp.pos.model';
import { PosUnDef } from '../gp';
const posDef: BnrGpPosModel[] = [];

posDef.push(new BnrGpPosModel({ year: 2023, posId: 2245001, group: 'vss', posText: 'Weiterbildungspfl. BNR (IDD)', planKey: 'idd', einheit: Einheit.minuten }));
posDef.push(new BnrGpPosModel({ year: 2023, posId: PosUnDef, group: 'vss', posText: 'Weiterbildungsz. Fonds', planKey: 'weiterbildungfonds', einheit: Einheit.minuten }));

posDef.push(new BnrGpPosModel({ year: 2023, posId: 2245042, group: 'eh', posText: 'Bausparen Bew ', planKey: 'bausparenbewertung', einheit: Einheit.bew }));

posDef.push(new BnrGpPosModel({ year: 2023, posId: 2245101, group: 'neh', posText: 'Baufinanzierung Bew ', planKey: 'baufinanzierungbew', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2023, posId: 2245131, group: 'neh', posText: 'Bausparen Bew ', planKey: 'bausparenbewertung', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2023, posId: 2245141, group: 'neh', posText: 'Baufinanzierung Bew WüRo', planKey: 'baufinanzierungwuro', einheit: Einheit.bew }));

export const BbsPositionen = posDef;
