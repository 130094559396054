import { observer } from 'mobx-react';
import { useEffect, useMemo } from 'react';
import { useStore } from 'app/context';

import Widget from 'app/components/common/Widget';
import { Table } from 'app/components/table/table';
import { AggregrationType, ITableColumn, TableModel, TableRow } from 'app/components/table/table.model';
import { IAgtReportStats, vdIds } from 'app/stores/ui/admin.usage.ui.store';
import moment from 'moment';
import { IBPTableRow } from 'app/stores/ui/admin.usage.ui.store';

export const AdminUsagePlanApDocs = observer(() => {
	const { adminUsageUiStore } = useStore();

	return (
		<>
			<div className="pad-1rem">
				<Widget title="Erstellte Planungs je VD" modifier="is-paddingless" action={<span className="tag is-green">DVS Datenbank</span>}>
					<AdminUsageVdPlanDocs data={adminUsageUiStore.agtVdApStats} currentVd={adminUsageUiStore.currentFilter.vdId} />
				</Widget>
			</div>
			<div className="pad-1rem">
				{adminUsageUiStore.currentFilter.vdId && (
					<Widget title="Erstellte Planungs je GS" modifier="is-paddingless" action={<span className="tag is-green">DVS Datenbank</span>}>
						<AdminUsageGsPlanDocs data={adminUsageUiStore.agtGsJApStats} currentVd={adminUsageUiStore.currentFilter.vdId} />
					</Widget>
				)}
			</div>
		</>
	);
});

export const AdminUsagePlanJpgDocs = observer(() => {
	const { adminUsageUiStore } = useStore();
	return (
		<>
			<div className="pad-1rem">
				<Widget title="Erstellte JPG je VD" modifier="is-paddingless" action={<span className="tag is-green">DVS Datenbank</span>}>
					<AdminUsageVdPlanDocs data={adminUsageUiStore.agtVdJpgStats} currentVd={adminUsageUiStore.currentFilter.vdId} />
				</Widget>
			</div>
			<div className="pad-1rem">
				{adminUsageUiStore.currentFilter.vdId && (
					<Widget title="Erstellte JPGS je GS" modifier="is-paddingless" action={<span className="tag is-green">DVS Datenbank</span>}>
						<AdminUsageGsPlanDocs data={adminUsageUiStore.agtGsJpgStats} currentVd={adminUsageUiStore.currentFilter.vdId} />
					</Widget>
				)}
			</div>
		</>
	);
});

interface IAdminUsagePlanDocs {
	data?: IAgtReportStats[];
	currentVd?: string;
}

export const AdminUsageVdPlanDocs = observer((props: IAdminUsagePlanDocs) => {
	const { adminUsageUiStore } = useStore();

	useEffect(() => {
		adminUsageUiStore.loadPlanDocStats();
	}, [adminUsageUiStore]);

	const tmVd = useMemo(() => {
		const tm = new TableModel<IBPTableRow, number>();
		let cols: ITableColumn<IBPTableRow, number>[] = [
			{
				label: 'Monat',
				path: 'ym',
				render: (row: TableRow<IBPTableRow>) => {
					return <span>{moment(row.data.ym).format('MMM YYYY')}</span>;
				},
			},
		];

		vdIds.forEach((vd) => {
			cols.push({
				label: adminUsageUiStore.currentFilter.vdId?.toUpperCase() === vd.toUpperCase() ? '*' + vd.toUpperCase() + '*' : vd.toUpperCase(),
				path: vd.toLowerCase(),
				agg: {
					path: vd.toLowerCase(),
					aggType: AggregrationType.sum,
				},
			});
		});

		cols.push({
			label: 'Summe',
			path: 'sum',
			agg: {
				path: 'sum',
				aggType: AggregrationType.sum,
			},
		});

		tm.setCols(cols);
		tm.sortBy = 'ym';
		tm.idProperty = 'id';
		tm.idType = 'string';
		return tm;
	}, [adminUsageUiStore.currentFilter.vdId]);

	useEffect(() => {
		if (props.data) {
			tmVd.setRowData(props.data);
		} else {
			tmVd.setRowData([]);
		}
	}, [props.data, tmVd]);

	return (
		<>
			<Table tm={tmVd} />
		</>
	);
});

export const AdminUsageGsPlanDocs = observer((props: IAdminUsagePlanDocs) => {
	const { adminUsageUiStore } = useStore();

	useEffect(() => {
		adminUsageUiStore.loadPlanDocStats();
	}, [adminUsageUiStore]);

	const tmGs = useMemo(() => {
		const tm = new TableModel<IBPTableRow, number>();
		let cols: ITableColumn<IBPTableRow, number>[] = [
			{
				label: 'Monat',
				path: 'ym',
				render: (row: TableRow<IBPTableRow>) => {
					return <span>{moment(row.data.ym).format('MMM YYYY')}</span>;
				},
			},
		];

		adminUsageUiStore.filteredGsIds.forEach((vd) => {
			cols.push({
				label: vd.toUpperCase(),
				path: vd.toLowerCase(),
				agg: {
					path: vd.toLowerCase(),
					aggType: AggregrationType.sum,
				},
			});
		});

		cols.push({
			label: 'Summe',
			path: 'sum',
			agg: {
				path: 'sum',
				aggType: AggregrationType.sum,
			},
		});

		tm.setCols(cols);
		tm.sortBy = 'ym';
		tm.idProperty = 'id';
		tm.idType = 'string';
		return tm;
	}, [adminUsageUiStore.filteredGsIds]);

	useEffect(() => {
		if (props.data) {
			tmGs.setRowData(props.data);
		} else {
			tmGs.setRowData([]);
		}
	}, [props.data, tmGs]);

	return (
		<>
			<Table tm={tmGs} />
		</>
	);
});
