import { observer } from 'mobx-react';
import DatePicker from 'react-datepicker';
import { useStore } from 'app/context';
import { ISelectOpt, SelectInput } from 'app/components/common/Select';
import { runInAction } from 'mobx';

export const AdminUsageFilter = observer(() => {
	const { adminUsageUiStore } = useStore();

	const onVdChange = (name: string, val: ISelectOpt) => {
		runInAction(() => {
			adminUsageUiStore.currentFilter.vdId = val ? val.value : null;
		});
	};
	const onStartDateChange = (value: Date) => {
		runInAction(() => {
			adminUsageUiStore.currentFilter.startDate = value;
		});
	};
	const onEndDateChange = (value: Date) => {
		runInAction(() => {
			adminUsageUiStore.currentFilter.endDate = value;
		});
	};

	return (
		<div className="admin-usage-filter pad-1rem grid is-col-auto">
			<div className="text-input">
				<SelectInput modifier="is-inline" name="vd" label="VD" value={adminUsageUiStore.currentFilter.vdId} onChange={onVdChange} opts={adminUsageUiStore.vdOpts} />
			</div>

			<div className="grid is-col-auto usage-date">
				<div className="">
					Start: <DatePicker name={'startData'} selected={adminUsageUiStore.currentFilter.startDate} onChange={onStartDateChange} dateFormat={'dd.MM.yy'} />
				</div>
				<div>
					Ende: <DatePicker name={'startData'} selected={adminUsageUiStore.currentFilter.endDate} onChange={onEndDateChange} dateFormat={'dd.MM.yy'} />
				</div>
			</div>
		</div>
	);
});
