import { useStore } from 'app/context';
import { formatDate } from 'app/utils';
import { observer } from 'mobx-react';
import { IWMStyle, WmTableRow } from './Wochenmail';
import { WMHeading } from './PlainTextWochenmail';

export const WochenmailBirthdays = observer((props: IWMStyle) => {
	const { wmStyles, isText } = props;
	const { wochenmailtUiStore } = useStore();
	const title = `Anstehende Geburtstage`;

	return (
		<>
			{wochenmailtUiStore.loaded && wochenmailtUiStore.showBirthdays && (
				<>
					{isText ? (
						<>
							<WMHeading title={title} />
							{wochenmailtUiStore.birthdays.map((p) => (
								<span key={p.id}>
									{p.fullName} ({p.stellenBezeichnung}): {formatDate(p.gebDat)} <br />
								</span>
							))}
							<br />
							<br />
						</>
					) : (
						<WmTableRow>
							<table width={'100%'} style={wmStyles.innerTableStyle}>
								<tbody>
									<tr>
										<td colSpan={2} style={wmStyles.headingTdStyle}>
											<h2 style={{ ...wmStyles.headingStyle, textAlign: 'center' }}>{title}</h2>
										</td>
									</tr>
									{wochenmailtUiStore.birthdays.map((p) => (
										<tr key={p.id}>
											<td>
												<strong>{p.fullName}</strong> ({p.stellenBezeichnung})
											</td>
											<td style={{ textAlign: 'right', paddingBottom: '10px' }}>{formatDate(p.gebDat)}</td>
										</tr>
									))}
								</tbody>
							</table>
						</WmTableRow>
					)}
				</>
			)}
		</>
	);
});
