import { AgenturModel } from 'app/models/agentur.model';
import { LeadnowCheckFormItem } from 'app/models/leadnowcheck/leadnow.check.form.item';
import { LeadNowCheckVM } from 'app/models/leadnowcheck/leadnow.check.vm';
import { AgenturStore } from '../agentur.store';
import { KvLeadnowCheckStore } from '../kv.agt.leadnowcheck.store';
import { SessionStore } from '../session.store';
import { AgtDigigFaktorUiStore } from './agt.digifaktor.ui.store';

export class AgtLeadNowCheckUiStore {
	session: SessionStore;
	agenturStore: AgenturStore;
	kvLeadnowCheckStore: KvLeadnowCheckStore;
	agtDigigFaktorUiStore: AgtDigigFaktorUiStore;

	constructor(session: SessionStore, agenturStore: AgenturStore, kvLeadnowCheckStore: KvLeadnowCheckStore, agtDigigFaktorUiStore: AgtDigigFaktorUiStore) {
		this.session = session;
		this.agenturStore = agenturStore;
		this.kvLeadnowCheckStore = kvLeadnowCheckStore;
		this.agtDigigFaktorUiStore = agtDigigFaktorUiStore;
	}

	items: LeadNowCheckVM[] = [];
	loaded: boolean = false;
	async findAll() {
		if (this.loaded) {
			return this.items;
		}
		const agts = await this.agenturStore.findAll();
		const items = await this.kvLeadnowCheckStore.findAll();
		const dfs = await this.agtDigigFaktorUiStore.findAllCurrent();

		const res: LeadNowCheckVM[] = [];
		agts.forEach((agt) => {
			const agtItems = items.filter((i) => agt.agtId === i.agtId);
			const df = dfs.find((df) => df.agtId === agt.agtId);
			res.push(new LeadNowCheckVM(agt, agtItems, df));
		});
		this.items = res;
		this.loaded = true;

		return res;
	}

	async findByAgtId(agtId: number) {
		const items = await this.findAll();
		return items.find((i) => i.agtId === agtId);
	}

	async saveMany(agtId: number, items: LeadnowCheckFormItem[]) {
		for (const item of items) {
			item.data.agtId = agtId;
			item.data.bnrId = this.session.currentUser!.bnrId;
			await this.kvLeadnowCheckStore.save(item.data);
		}
		this.loaded = false;
	}

	async openIntroMail(agt: AgenturModel, location: any) {
		const eSubject = encodeURI('Teilnahme Lead.NOW');
		const eBody = encodeURI(
			'Liebe(r) ,' +
				'\n\n' +
				'mit dieser Mail empfehle ich Sie für das Lead Management und stelle deshalb eine Verbindung zwischen Ihrer Agentur und dem Lead.NOW Team her.' +
				'\n\n' +
				'Über den folgende Link gelangen Sie zur Lead.NOW Plattform.' +
				'\n\n' +
				'https://leadnow.allianz.de  (nur via Allianz SSO)' +
				'\n\n' +
				'Für Fragen und Hilfestellungen wenden Sie sich gerne an den Postkorb leadmanagement@allianz.de. ' +
				'\n\n' +
				'Der Agenturservice Lead.NOW wird sich darüber hinaus mit Ihnen in Verbindung setzen.  ' +
				'\n\n' +
				' ' +
				'\n\n',
		);

		const leadNowVerteiler = 'leadmanagement@allianz.de';
		let emailAgt = '';
		if (agt.email) {
			emailAgt = ';' + agt.email;
		}

		let email = `mailto:?to=${leadNowVerteiler}${emailAgt}&subject=${eSubject}&body=${eBody}`;
		window.location.href = email;
	}
}
