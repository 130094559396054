import { useStore } from 'app/context';
import { Field, Form, Formik } from 'formik';
import { observer } from 'mobx-react';
import * as Yup from 'yup';
import { notify } from 'app/components/common/notify';
import FormField from 'app/components/form/FormField';
import { Button } from 'app/components/common/Button';
import { AdminBnrTransferables } from './AdminBnrTransferables';
import { useHistory } from 'react-router';
import routes from 'routes';

const adminBnrTransferNewFormSchema = Yup.object({
	sourceBnrId: Yup.number(),
	targetBnrId: Yup.number(),
	adminComment: Yup.string(),
	targetComment: Yup.string(),
}).required();

type AdminBnrTransferEditNew = Yup.InferType<typeof adminBnrTransferNewFormSchema>;

interface IUserEditForm {
	onClose: () => void;
}
export const AdminBnrTransferNewForm = observer((props: IUserEditForm) => {
	const { adminBnrTransferUiStore } = useStore();
	const history = useHistory();

	const handleSubmit = async (values: AdminBnrTransferEditNew) => {
		if (!adminBnrTransferUiStore.currentHasData) {
			notify('Keine Daten zum übernehmen vorhanden', '', 'warning');
			return;
		}
		const vm = await adminBnrTransferUiStore.newTransfer({
			sourceBnrId: values.sourceBnrId!,
			targetBnrId: values.targetBnrId!,
			adminComment: values.adminComment!,
			targetComment: values.targetComment!,
		});
		if (vm) {
			notify('Datenübernahme wurde angelegt', '', 'info');
			history.push(routes.ADMINBNRTRANSFER.getPath(vm.transfer.id));
			props.onClose();
		} else {
			notify('Datenübernahme konnten nicht angelegt werden', '', 'error');
			props.onClose();
		}
	};

	return (
		<>
			<Formik
				validationSchema={adminBnrTransferNewFormSchema}
				initialValues={{
					sourceBnrId: 3026841,
					targetBnrId: 3022597,
					targetComment: '',
					adminComment: '',
				}}
				onSubmit={handleSubmit}
			>
				{({ errors, touched, isValid, values }) => {
					return (
						<Form>
							<div className="pad-bottom-1rem border-bottom">
								<div className="pad-bottom-1rem">
									<strong>Quelle</strong>
								</div>
								<FormField className="is-inline" error={errors.sourceBnrId} touched={touched.sourceBnrId} label="Quell-BnrId">
									<Field type="text" name="sourceBnrId" placeholder="Quell-BnrId" />
								</FormField>
								<FormField className="is-inline" error={errors.targetBnrId} touched={touched.targetBnrId} label="Ziel-BnrId">
									<Field type="text" name="targetBnrId" placeholder="Ziel-BnrId" />
								</FormField>
								<FormField className="is-inline" error={errors.targetComment} touched={touched.targetComment} label="Anfrage Text (Ziel)">
									<Field className="input" type="text" as="textarea" name="targetComment" placeholder="Anfrage" />
								</FormField>
								<FormField className="is-inline" error={errors.adminComment} touched={touched.adminComment} label="Kommentar (nur für Admins)">
									<Field className="input" type="text" as="textarea" name={'adminComment'} placeholder="Kommentar (nur für Admins)" />
								</FormField>
							</div>

							<div className="pad-top-1rem pad-bottom-1rem">
								<strong>Übertragbare Daten</strong>

								<AdminBnrTransferables sourceBnrId={values.sourceBnrId} targetBnrId={values.targetBnrId} />
							</div>

							<div className="modal-footer">
								<Button type="button" className="button is-secondary" onClick={props.onClose}>
									Abbrechen
								</Button>
								<Button type="submit" className="button is-primary " disabled={!isValid}>
									Speichern
								</Button>
							</div>
						</Form>
					);
				}}
			</Formik>
		</>
	);
});
