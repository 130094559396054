import { useEffect } from 'react';
import BaseContainer from 'app/containers/BaseContainer';
import { MainMenu } from './MainMenu';
import { RouteComponentProps } from 'react-router-dom';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { useParams } from 'react-router';
import { TeamList } from 'app/components/team/TeamList';
import { TeamDetail } from 'app/components/team/TeamDetail';

const NavBar = observer(() => {
	return <div>Team</div>;
});
const TeamContainer = (props: RouteComponentProps) => {
	return (
		<BaseContainer sideMenu={<MainMenu {...props} />} navbar={<NavBar />}>
			<TeamList />
			<TeamDetail />
			<TeamLoader />
		</BaseContainer>
	);
};

export interface TeamParamTypes {
	bnrId?: string;
	panel?: string;
	level1?: string;
	level2?: string;
	level3?: string;
	level4?: string;
	level5?: string;
}

const TeamLoader = observer(() => {
	const { teamUiStore } = useStore();
	const { bnrId, panel, level1, level2 } = useParams<TeamParamTypes>();

	useEffect(() => {
		teamUiStore.loadById(bnrId, panel, level1, level2);
	}, [teamUiStore, bnrId, panel, level1, level2]);

	useEffect(() => {
		teamUiStore.load();
	}, [teamUiStore]);
	return <></>;
});

export default TeamContainer;
