import { Button } from 'app/components/common/Button';
import { Icon } from 'app/components/common/Icon';
import { useStore } from 'app/context';
import { BnrTaskModel, TaskStatus } from 'app/stores/bnr.task.store';
import { TaskVM } from 'app/stores/ui/task.ui.store';
import { formatDate } from 'app/utils';
import { observer } from 'mobx-react';
interface IAgtPlanTaskRow {
	task: TaskVM;
}

export const AgtPlanTaskRow = observer((props: IAgtPlanTaskRow) => {
	const { uiStore, taskUiStore } = useStore();
	const task = props.task.task;

	const onToggleStatus = () => {
		task.status = task.status === TaskStatus.CLOSED ? TaskStatus.OPEN : TaskStatus.CLOSED;
		taskUiStore.save(props.task);
	};

	const openModal = () => {
		taskUiStore.setCurrentModalTask(props.task);
		uiStore.showModal(uiStore.modalIds.taskViewModal);
	};

	const getTaskStatus = (task: BnrTaskModel) => {
		if (task.status === TaskStatus.CLOSED) {
			return 'status-green';
		}
		if (task.noDueDate) {
			return 'status-yellow';
		}

		const today = new Date();
		if (task.dueDate && task.dueDate.getTime() < today.getTime()) {
			return 'status-red';
		}

		const month = new Date();
		month.setMonth(today.getMonth() + 1);
		if (task.dueDate && task.dueDate.getTime() < month.getTime()) {
			return 'status-yellow';
		}

		return 'status-grey';
	};

	const getTaskIcon = (task: BnrTaskModel) => {
		if (task.status === TaskStatus.CLOSED) {
			return <Icon iconClass={'check-circle'} />;
		}

		const today = new Date();
		if (task.dueDate && task.dueDate.getTime() < today.getTime()) {
			return <Icon iconClass={'circle'} faModifier="far" />;
		}

		return <Icon iconClass={'circle-o'} />;
	};

	if (task.archived) {
		return <></>;
	}

	return (
		<div className={`task-list-item ${getTaskStatus(task)}`}>
			<div className="check">
				<Button className="button is-icon" onClick={onToggleStatus}>
					{getTaskIcon(task)}
				</Button>
			</div>
			<div className="text">{task.text}</div>
			{task.thema && <div className="thema">{task.thema!.title}</div>}
			<div className="actions">
				<Button type="button" className="button is-text" onClick={openModal}>
					Details
				</Button>
			</div>
			<div className="date">&nbsp;{task.dueDate && formatDate(task.dueDate)}</div>
		</div>
	);
});

interface IAgtPlanTasks {
	tasks: TaskVM[];
}

export const AgtPlanTasks = observer((props: IAgtPlanTasks) => {
	const tasks = props.tasks;

	return (
		<div className="tasks-list">
			{tasks.map((t) => (
				<AgtPlanTaskRow task={t} key={t.id} />
			))}
		</div>
	);
});
