import { useStore } from 'app/context';
import { Field, Form, Formik } from 'formik';
import { observer } from 'mobx-react';
import * as Yup from 'yup';
import FormField from 'app/components/form/FormField';
import { Button } from 'app/components/common/Button';
import { ImportJobVM } from 'app/stores/ui/admin.imports.ui.store';
import { useEffect, useState } from 'react';
import { AdminFileUpload } from '../AdminFileUpload';
import { AupFile, ImportDataType } from 'app/stores/admin.imports.store';
import { formatDateLong, humanizeFileSize } from 'app/utils';
import moment from 'moment';

const newAupFormSchema = Yup.object({
	vorjahr: Yup.string().required('KW ist erfoderlich').default(''),
}).required();

type NewAupForm = Yup.InferType<typeof newAupFormSchema>;

interface IUserEditForm {
	job: ImportJobVM;
	onClose: () => void;
}
export const AdminAupVorjahrForm = observer((props: IUserEditForm) => {
	const [lastChange, setLastChange] = useState(new Date());

	const handleSubmit = async (values: NewAupForm) => {
		// job.job.meta.kw = values.kw;
		// notify('Job wird gestart', '', 'info');
		// const res = (await adminImportsUiStore.triggerJob(job.job)) as any;
		// history.push(routes.ADMINIMPORTS.getPath('job', res.id));
	};

	const onUploadDone = () => {
		setLastChange(new Date());
	};

	return (
		<>
			<Formik
				validationSchema={newAupFormSchema}
				initialValues={{
					vorjahr: (moment().year() - 1).toString(),
				}}
				onSubmit={handleSubmit}
			>
				{({ errors, touched, isValid, values }) => {
					return (
						<Form>
							<div className="pad-1rem">
								<FormField className="is-inline" error={errors.vorjahr} touched={touched.vorjahr} label="vorjahr">
									<Field type="text" name="vorjahr" placeholder="vorjahr" />
								</FormField>
							</div>
							<div className="pad-1rem">
								<div className="form-control is-inline">
									<label>Dateien</label>
									{isValid && (
										<AdminFileUpload
											isButton={false}
											maxSize={200000000}
											// maxFiles={1}
											dataType={ImportDataType.aupvorjahr}
											kw={values.vorjahr}
											multiple={true}
											dropZoneActiveText={'Datei loslassen'}
											dropZoneText={'Dateien Hochladen'}
											uploadingText={'Upload läuft'}
											onDone={onUploadDone}
										/>
									)}
								</div>
							</div>

							<div className="pad-1rem">
								<AdminImportAupKWFiles kw={values.vorjahr} lastChange={lastChange} />
							</div>
							<div className="pad-1rem">
								<Button type="submit" className={'button is-primary'} disabled={true}>
									Import wird mit regulären AUP-Import ausgelöst
								</Button>
							</div>
						</Form>
					);
				}}
			</Formik>
		</>
	);
});

interface IAdminImportAupKWFiles {
	kw: string;
	lastChange: Date;
}
const AdminImportAupKWFiles = observer((props: IAdminImportAupKWFiles) => {
	const { adminImportsUiStore } = useStore();
	const [files, setFiles] = useState<AupFile[]>([]);

	useEffect(() => {
		console.log(props.lastChange);
		adminImportsUiStore.getAupVorjahrFiles(props.kw).then((files) => {
			setFiles(files);
		});
	}, [props.kw, props.lastChange, adminImportsUiStore]);

	return (
		<div className="pad-top-1rem border-top">
			<strong>Hochgeladene Dateien für Vorjahr: {props.kw}</strong>
			<br />

			<>Im moment werden nur pos_agt_ziel und pos_bnr_ziel verwendet.</>
			<table className={'table'}>
				<thead>
					<tr>
						<th>Name</th>
						<th>Größe</th>
						<th>Datum</th>
					</tr>
				</thead>
				<tbody>
					{files.map((f) => (
						<tr key={f.fileName}>
							<td>{f.fileName}</td>
							<td>{humanizeFileSize(f.fileSize)}</td>
							<td>{formatDateLong(f.fileDate)}</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
});
