import { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { useStore } from 'app/context';
import { Button } from 'app/components/common/Button';
import { useHistory } from 'react-router';
import routes from 'routes';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Icon } from 'app/components/common/Icon';
import { Popover } from 'app/components/common/Popover';

export const MainMenu = observer((props: RouteComponentProps) => {
	const { session, authStore, uiStore } = useStore();
	const history = useHistory();
	const onLogout = () => {
		closeMenu();
		authStore.logout();
		history.push(routes.LOGIN.getPath());
	};
	const openSettings = () => {
		closeMenu();
		history.push(routes.EINSTELLUNGEN.getPath());
	};

	const currentUser = session.currentUser;
	const menu = uiStore.visibleMenuItems;
	useEffect(() => {
		uiStore.mainMenu.setActivePath(props.location.pathname);
	}, [uiStore, props.location.pathname]);

	const closeMenu = () => {
		uiStore.toggleMobileMenu(false);
	};

	const collapseMenu = () => {
		session.setMenuCollapseState(!session.menuCollapse);
	};

	const popoverRef = useRef<HTMLDivElement>(null);
	const popoverButtons = [
		{
			title: 'Verstanden',
			className: 'button is-small is-primary is-inverted',
			onClick: () => {
				session.setMenuCollapseState(false);
			},
		},
	];

	return (
		<>
			{session.menuCollapse === undefined && (
				<Popover target={popoverRef} title="Neu" buttons={popoverButtons}>
					Sie haben nun die Möglichkeit, die Navigation auszublenden.
				</Popover>
			)}
			<div className={`main-menu ${uiStore.showMobileMenu ? `is-active-mobile` : ``} ${session.menuCollapse ? `is-collapsed` : ``}`}>
				<div className="main-menu-header">
					{currentUser && (
						<div className="user-details">
							<strong className="name">
								{currentUser.name} {session.isSuperAdmin && <Icon iconClass="user-secret" />}
							</strong>
							<div className="details">
								<span className="user-steart">{currentUser.steart.name}</span>
								<span>
									{currentUser.bensl} ({currentUser.bnrId})
								</span>
							</div>
						</div>
					)}
					<div ref={popoverRef} className="main-menu-collapse" data-tooltip={session.menuCollapse ? `Menu Aufklappen` : `Menu Zuklappen`}>
						<Button className="button is-icon" onClick={collapseMenu}>
							<Icon iconClass={session.menuCollapse ? `bars` : `chevron-circle-left`} faModifier="far" />
						</Button>
					</div>
					<div className="main-menu-close">
						<Button className="button is-icon" onClick={closeMenu}>
							<Icon iconClass="times" />
						</Button>
					</div>
				</div>
				<nav className="main-menu-nav">
					{menu &&
						menu.length > 0 &&
						menu.map((item) => (
							<Link key={item.name} className={`nav-item ${item.active ? 'is-active' : ''}`} to={item.path} onClick={closeMenu} data-tooltip={item.name}>
								<span className="icon">
									<Icon iconClass={item.icon} />
								</span>
								<span className="text">{item.name}</span>
							</Link>
						))}
				</nav>
				<div className="main-menu-footer">
					<Button onClick={openSettings} className="button is-inverted has-icon is-light" data-tooltip={'Einstellungen'}>
						<Icon iconClass="cog" />
					</Button>
					<Button onClick={onLogout} className="button is-inverted has-icon is-light" data-tooltip={'Abmelden'}>
						<Icon iconClass="sign-out" faModifier="fal" />
						<span>Abmelden</span>
					</Button>
				</div>
			</div>
		</>
	);
});
