import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { InputControl } from '../../common/InputControl';
import { runInAction } from 'mobx';
import { ISelectOpt, SelectInput } from '../../common/Select';
import { UserStatusSelectOpts } from 'app/models/core/user.model';
import { AdminUserSteartOpts } from 'app/models/core/steart.model';

export const AdminUsersFilter = observer(() => {
	const { adminUserUiStore } = useStore();

	const onTextChange = (name: string, val: string) => {
		runInAction(() => {
			adminUserUiStore.currentFilter.fullText = val;
		});
	};
	const onStatusChange = (name: string, val: ISelectOpt[]) => {
		runInAction(() => {
			adminUserUiStore.currentFilter.status = val.map((v) => v.value);
		});
	};

	const onVdChange = (name: string, val: ISelectOpt) => {
		runInAction(() => {
			adminUserUiStore.currentFilter.vd = val ? val.value : null;
		});
	};

	const onGsChange = (name: string, val: ISelectOpt | null) => {
		runInAction(() => {
			adminUserUiStore.currentFilter.gs = val ? val.value : null;
		});
	};

	const onSteartChange = (name: string, val: ISelectOpt[]) => {
		runInAction(() => {
			adminUserUiStore.currentFilter.steart = val.map((v) => v.value);
		});
	};

	return (
		<div className="admin-users-filter pad-1rem grid is-col-half">
			<div className="left grid">
				<div className="text-input">
					<InputControl
						inline={true}
						label="Filter"
						name="fullText"
						value={adminUserUiStore.currentFilter.fullText}
						onChange={onTextChange}
						debounceTime={500}
						placeholder={'Textsuche: Name, Bensl, BnrId'}
					/>
				</div>
				<div className="text-input">
					<SelectInput
						isMulti={true}
						modifier="is-inline"
						name="status"
						label="Status"
						value={adminUserUiStore.currentFilter.status}
						onChange={onStatusChange}
						opts={UserStatusSelectOpts}
					/>
				</div>

				<div className="text-input">
					<SelectInput modifier="is-inline" name="vd" label="VD" value={adminUserUiStore.currentFilter.vd} onChange={onVdChange} opts={adminUserUiStore.vdOpts} />
				</div>
			</div>
			<div className="right grid">
				<div className="text-input">
					<SelectInput modifier="is-inline" name="gs" label="GS" value={adminUserUiStore.currentFilter.gs} onChange={onGsChange} opts={adminUserUiStore.gsOpts} />
				</div>

				<div className="text-input">
					<SelectInput
						modifier="is-inline"
						isMulti={true}
						name="steart"
						label="Steart"
						value={adminUserUiStore.currentFilter.steart}
						onChange={onSteartChange}
						opts={AdminUserSteartOpts}
					/>
				</div>
			</div>

			<div className="active-filters">
				{adminUserUiStore.currentItems.length} / {adminUserUiStore._items.length} Benutzer
			</div>
		</div>
	);
});
