import { observer } from 'mobx-react';
import { useStore } from 'app/context';
import { runInAction } from 'mobx';
import { Modal } from 'app/components/Modal';
import { Button } from 'app/components/common/Button';

interface IConfirmModalProps {
	onConfirm: () => Promise<void>;
	onCancel: () => void;
	children: any;
	modalId?: string;
}

export const ConfirmModal = observer((props: IConfirmModalProps) => {
	const { uiStore } = useStore();
	const modalId = props.modalId ? props.modalId : uiStore.modalIds.confirmModal;

	const onConfirm = async () => {
		await props.onConfirm();
		runInAction(() => {
			uiStore.hideModal(modalId);
		});
	};
	const onCancel = () => {
		runInAction(() => {
			props.onCancel();
			uiStore.hideModal(modalId);
		});
	};

	return (
		<>
			<Modal modalId={modalId} title={'Bestätigung erforderlich'} onClose={onCancel}>
				<div>
					{props.children}
					<div className="modal-footer">
						<Button type="button" className="button is-primary " onClick={onConfirm}>
							Bestätigen
						</Button>
						<Button type="submit" className="button is-secondary" onClick={onCancel}>
							Abbrechen
						</Button>
					</div>
				</div>
			</Modal>
		</>
	);
});
