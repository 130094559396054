import * as Yup from 'yup';
import { notify } from 'app/components/common/notify';
import FormField, { FormDivider } from 'app/components/form/FormField';
import { Button } from 'app/components/common/Button';
import { observer } from 'mobx-react';
import { Field, Form, Formik } from 'formik';
import { useStore } from 'app/context';
import { AgenturModel } from 'app/models/agentur.model';
import { InspIdSelectField } from 'app/components/form/InspIdSelectField';
import { useEffect, useState } from 'react';
import { DefaultGp, GpModels } from 'app/models/gp/agt.gp.def';
import { GpSelectField } from 'app/components/form/GPSelectField';
import { BetreuerMultiSelectField } from 'app/components/form/BetreuerMultiSelectField';
import { Icon } from 'app/components/common/Icon';
import { useHistory } from 'react-router';
import routes from 'routes';
import { Modal } from 'app/components/Modal';
import { AgtSelectorList } from 'app/components/shared/AgtSelector';
import { runInAction } from 'mobx';

const tempAgtEditFormSchema = Yup.object({
	agtId: Yup.number(),
	name: Yup.string().required('Agenturname ist erforderlich'),
	inspId: Yup.string().required('Inspektorat ist erforderlich'),
	vtnrLebend: Yup.number().required(),
	vtnrGpincId: Yup.string().required(),
	vtnrStr: Yup.string().required('Vollständige Anschrift ist erforderlich'),
	vtnrPlz: Yup.number().required('Vollständige Anschrift ist erforderlich'),
	vtnrOrt: Yup.string().required('Vollständige Anschrift ist erforderlich'),
	comment: Yup.string().default(''),
	bnrIds: Yup.array().default([]),
}).required();

type TempAgtEdit = Yup.InferType<typeof tempAgtEditFormSchema>;

interface ITempAgtEditForm {
	tempAgt?: AgenturModel;
	onClose: () => void;
}

export const TempAgenturForm = observer((props: ITempAgtEditForm) => {
	const { tempAgenturUiStore, agenturListUiStore, session, uiStore } = useStore();
	const history = useHistory();

	const [agt, setAgt] = useState<AgenturModel>();
	const [updating, setIsUpdating] = useState<boolean>(false);

	const [inspId, setInspId] = useState<string>('0');
	const [gpId, setGpId] = useState<string>(DefaultGp.gpId.toString());

	useEffect(() => {
		const x = new AgenturModel({});
		setAgt(x);
		if (props.tempAgt) {
			setIsUpdating(true);

			setGpId(props.tempAgt.vtnrGpincId.toString());
			setInspId(props.tempAgt.inspId.toString());
			x.agtId = props.tempAgt.agtId;
			x.vtnrLebend = props.tempAgt.vtnrLebend;
			x.name = props.tempAgt.name;
			x.vtnrStr = props.tempAgt.vtnrStr;
			x.vtnrPlz = props.tempAgt.vtnrPlz;
			x.vtnrOrt = props.tempAgt.vtnrOrt;
			x.comment = props.tempAgt.comment;
			x.bnrIds = props.tempAgt.bnrIds;
		} else {
			if (agenturListUiStore.inspIdOptions && agenturListUiStore.inspIdOptions.length > 0) {
				setInspId(agenturListUiStore.inspIdOptions[0].value); // first value
			} else {
				setInspId('0');
			}
			setIsUpdating(false);

			x.agtId = 0;
			x.vtnrLebend = 1;
			x.name = 'Test Agentur';
			x.vtnrStr = 'Test Straße';
			x.vtnrPlz = 10000;
			x.vtnrOrt = 'Test Ort';
			x.comment = '';
			x.bnrIds = [tempAgenturUiStore.agenturStore.session.currentUser!.bnrId];
		}
	}, [props.tempAgt, agenturListUiStore, tempAgenturUiStore]);

	const handleSave = async (values: TempAgtEdit) => {
		if (!agt) {
			return;
		}
		agt.name = values.name;
		agt.agtId = 0;
		if (props.tempAgt) {
			agt.agtId = props.tempAgt.agtId;
		}
		agt.inspId = parseInt(values.inspId);
		agt.vtnrLebend = 1;

		const gpId = parseInt(values.vtnrGpincId);
		agt.vtnrGpincId = gpId;
		agt.vtnrGpincBez = GpModels.find((g) => g.gpId === gpId)!.name;

		agt.vtnrStr = values.vtnrStr;
		agt.vtnrPlz = values.vtnrPlz ? values.vtnrPlz : 0;
		agt.vtnrOrt = values.vtnrOrt;

		agt.comment = values.comment;

		agt.bnrIds = values.bnrIds.map((b) => parseInt(b));

		try {
			await tempAgenturUiStore.save(agt);
			return true;
		} catch (ex) {
			debugger;

			notify('Fehler beim Anlegen/Speichern der Agentur', '', 'error');
			return false;
		}
	};

	const handleSubmit = async (values: TempAgtEdit) => {
		if (await handleSave(values)) {
			notify('Temporäre Agentur wurde gespeichert', '', 'info');
			props.onClose();
			window.location.reload();
		}
	};

	const handleDelete = async () => {
		if (!props.tempAgt) {
			return;
		}
		if (!window.confirm('Soll die temporäre Agentur wirklich gelöscht werden? Alle zugehörigen Daten gehen verloren!')) {
			return;
		}

		await tempAgenturUiStore.delete(props.tempAgt.agtId);
		notify('Temporäre Agentur wurde gelöscht', '', 'info');
		history.push(routes.AGENTUR.getPath());
		window.location.reload();
	};

	const selectAgtForTransfer = () => {
		uiStore.showModal(uiStore.modalIds.tempAgtSelectTransferAgt);
	};

	const handleTransfer = async (targetAgtId: number) => {
		if (!props.tempAgt) {
			return;
		}

		notify('Daten werden übertragen', '', 'info');
		await tempAgenturUiStore.transfer(props.tempAgt.agtId, targetAgtId);

		history.push(routes.AGENTUR.getPath());
		window.location.reload();
	};

	if (!agt) {
		return <></>;
	}

	return (
		<>
			<Formik
				validationSchema={tempAgtEditFormSchema}
				initialValues={{
					agtId: agt.agtId,
					name: agt.name,
					inspId: inspId,
					vtnrLebend: agt.vtnrLebend,
					vtnrGpincId: gpId,
					vtnrStr: agt.vtnrStr,
					vtnrPlz: agt.vtnrPlz,
					vtnrOrt: agt.vtnrOrt,
					comment: agt.comment,
					bnrIds: agt.bnrIds.map((b) => b.toString()),
				}}
				onSubmit={handleSubmit}
			>
				{({ errors, touched, isValid, values }) => {
					return (
						<Form className="form">
							{errors.agtId}
							{errors.inspId}
							{errors.name}
							{errors.vtnrGpincId}
							{errors.vtnrLebend}
							{errors.vtnrOrt}
							{errors.vtnrOrt}
							{errors.vtnrPlz}
							{errors.vtnrStr}
							<div className="pad-1rem">
								<div className="note-box content margin-bottom-1rem">
									<p>
										<Icon iconClass="info-circle" />
									</p>
									<div className="note-message">
										<p>
											<strong>Anlage einer temporären Agentur</strong>
										</p>
										<ul className="is-marginless">
											<li>Für Neugründungen, Nachfolgeplanung oder Simulationen</li>
											<li>DVS vergibt eine temporäre VTNR</li>
											<li>Temporäre Agentur kann vollständig beplant werden (Planung / Dokumentaion / Branchenplanung etc.)</li>
											<li>Nach Abschluss koennen ggf. alle Dokumente & Zahlen in die finale/offizielle Agentur übertragen werden</li>
										</ul>
										<p></p>
									</div>
								</div>

								<FormField className="is-inline" error={errors.name} touched={touched.name} label="Titel oder Name der Agentur">
									<Field type="text" name="name" placeholder="Name" />
								</FormField>

								<FormField className="is-inline" label="GP" error={errors.vtnrGpincId} touched={touched.vtnrGpincId}>
									<GpSelectField name={'vtnrGpincId'} />
								</FormField>

								<FormField className="is-inline" label="Inspektorat" error={errors.inspId} touched={touched.inspId}>
									<InspIdSelectField name={'inspId'} />
								</FormField>

								<FormDivider label="Anschrift" className="is-inline" />

								<FormField className="is-inline" error={errors.vtnrStr} touched={touched.vtnrStr} label="Straße">
									<Field type="text" name="vtnrStr" placeholder="Straße" />
								</FormField>
								<FormField className="is-inline" error={errors.vtnrPlz} touched={touched.vtnrPlz} label="PLZ">
									<Field type="text" name="vtnrPlz" placeholder="PLZ" />
								</FormField>
								<FormField className="is-inline" error={errors.vtnrOrt} touched={touched.vtnrOrt} label="Ort">
									<Field type="text" name="vtnrOrt" placeholder="Ort" />
								</FormField>

								<FormField className="is-inline" error={errors.comment} touched={touched.comment} label="Kommentar">
									<Field type="text" className="input" as="textarea" name="comment" placeholder="Kommentar" />
								</FormField>

								<FormDivider label="Sichtbarkeit" className="is-inline">
									Wer sieht die temporäre Agentur
								</FormDivider>

								<FormField className="is-inline" label="Betreuer" touched={touched.bnrIds}>
									<BetreuerMultiSelectField name={'bnrIds'} />
								</FormField>
							</div>
							<div className="modal-footer">
								<Button type="button" className="button is-secondary" onClick={props.onClose}>
									Abbrechen
								</Button>
								{session.currentUser && session.currentUser.isVbl && (
									<>
										<Button type="submit" className="button is-primary " disabled={!isValid}>
											Speichern
										</Button>
										{updating && (
											<>
												<Button type="button" className="button is-info" onClick={selectAgtForTransfer}>
													Übertragen
												</Button>
												<Button type="button" className="button is-danger is-inverted" onClick={handleDelete}>
													Löschen
												</Button>
											</>
										)}
									</>
								)}
							</div>
						</Form>
					);
				}}
			</Formik>
			{updating && agt && <AgenturSelectModal tempAgt={agt} onSubmit={handleTransfer} />}
		</>
	);
});

interface IAgenturSelectModal {
	tempAgt: AgenturModel;
	onSubmit: (targetAgtId: number) => void;
}

export const AgenturSelectModal = observer((props: IAgenturSelectModal) => {
	const { uiStore } = useStore();
	const modalId = uiStore.modalIds.tempAgtSelectTransferAgt;

	const [targetAgt, setTargetAgt] = useState<AgenturModel>();

	const [targetName, setTargetName] = useState<string>('');

	const onCancel = () => {
		runInAction(() => {
			uiStore.hideModal(modalId);
		});
	};

	const onAgtSelect = (agt?: AgenturModel) => {
		console.log('agt', agt);
		setTargetAgt(agt);
		if (agt) {
			setTargetName(`${agt.name} [${agt.agtId}]`);
		} else {
			setTargetName('');
		}
	};

	const onTransfer = () => {
		if (!targetAgt) {
			return;
		}
		if (
			!window.confirm(
				`Alle Dokumente und Daten, die für die Temporäre Agentur erfasst wurden, werden and die Agentur ${targetName} übertragen. \n\nDies kann nicht rückgängig gemacht werden. \nSind sicher das Sie die Daten übertragen wollen? `,
			)
		) {
			return;
		}

		props.onSubmit(targetAgt.agtId);
		onCancel();
	};

	return (
		<>
			<Modal
				modalId={modalId}
				title={`Zielagentur für Agentur ${props.tempAgt.name} [${props.tempAgt.agtId}] auswählen.`}
				onClose={onCancel}
				modifier="is-medium is-paddingless is-table-modal"
			>
				<div>
					<AgtSelectorList onSelect={onAgtSelect} selectedAgt={targetAgt} />
				</div>
				<div className="modal-footer">
					<Button type="button" className="button is-secondary" onClick={onCancel}>
						Abbrechen
					</Button>

					<Button type="button" className="button is-primary " disabled={!targetAgt} onClick={onTransfer}>
						Übertrag starten auf {targetName}
					</Button>
				</div>
			</Modal>
		</>
	);
});
