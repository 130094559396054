import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { InputControl } from '../common/InputControl';
import { ISelectOpt, SelectInput } from '../common/Select';
import { useEffect, useState } from 'react';
import { Button } from '../common/Button';
import { Icon } from '../common/Icon';
import { Modal } from '../Modal';
import FormField, { FormDivider } from '../form/FormField';
import { runInAction } from 'mobx';
import { DefaultLegend } from 'app/stores/ui/agentur.maps.ui.store';
import { AgtType } from 'app/models/agentur.model';
import { AgenturListFilterSave } from './AgenturListFilterSave';
import { IAgtFilterable } from 'app/stores/ui/agt.list.filter.ui.store';
import { AgenturListFilterLoad } from './AgenturListFilterLoad';
import { AgenturListFilterDelete } from './AgenturListFilterDelete';

interface IAgenturListsFilter {
	hideAgt?: boolean;
	forceCollapse?: boolean;
}

interface IAgenturFilterCount {
	totalItems: number;
	filteredItems: number;
}

const AgenturFilterCount = observer((props: IAgenturFilterCount) => {
	return (
		<>
			{props.filteredItems} / {props.totalItems}
			&nbsp;Agenturen&nbsp;
		</>
	);
});

export const AgenturMapsFilter = observer((props: IAgenturListsFilter) => {
	const { agenturMapsUiStore } = useStore();

	return (
		<>
			<div className="agentur-maps-filter">
				<div className="agentur-filter-count">
					<AgenturFilterCount filteredItems={agenturMapsUiStore.filteredMapItems.length} totalItems={agenturMapsUiStore.mapableItems.length} />
				</div>

				<AgenturListsFilterForm {...props} isInline={false} />
			</div>
		</>
	);
});

export const AgenturListsFilter = observer((props: IAgenturListsFilter) => {
	const { agenturListUiStore, agtUserUiStore, uiStore } = useStore();

	const [isOpen, setIsOpen] = useState<boolean>(true);
	const [fullTextValue, setFullTextValue] = useState('');

	useEffect(() => {
		agtUserUiStore.load().then(() => {
			agenturListUiStore.load().then(() => {});
		});
	}, [agtUserUiStore, agenturListUiStore]);

	const onFulltextChange = (n: any, v: string) => {
		agenturListUiStore.currentFilter.setFullTextValue(v);
	};
	useEffect(() => {
		setFullTextValue(agenturListUiStore.currentFilter.fullTextFilterValue);
	}, [agenturListUiStore.currentFilter.fullTextFilterValue]);

	const filterCount = agenturListUiStore.currentFilter.filtersWithValue.length;
	const hasFilters = filterCount > 0;
	const toggleFilters = () => {
		setIsOpen(!isOpen);
		uiStore.showModal(uiStore.modalIds.agenturFilterModal);
	};

	return (
		<>
			<div className={`agentur-filter-container is-collapsed`}>
				<div className="agentur-filter-count">
					<AgenturFilterCount filteredItems={agenturListUiStore.totalFilterdCount} totalItems={agenturListUiStore.totalItemCount} />
				</div>
				<div className="current-filters">
					<InputControl
						name="fullText"
						label="Textsuche"
						value={fullTextValue}
						onChange={onFulltextChange}
						debounceTime={250}
						placeholder={'Freitext'}
						icon="search"
						modifier={`current-filter ${uiStore.showMap ? '' : 'is-inline'}`}
					/>
				</div>

				<div className="toggle-filters">
					{hasFilters && <div className="filter-count">{filterCount}</div>}
					<Button className={`button is-inverted ${hasFilters ? 'is-info' : ''}`} onClick={toggleFilters}>
						{hasFilters && <span>{filterCount} Filter</span>}
						<Icon iconClass="filter" />
					</Button>
				</div>
			</div>

			<Modal modalId={uiStore.modalIds.agenturFilterModal} title={'Agenturen filtern'} size={'side-panel'}>
				<AgenturListsFilterForm {...props} isInline={true} />
			</Modal>
		</>
	);
});

interface AgtFilterDef {
	label: string;
	type: 'string' | 'NumberArray';
	hit?: (x: IAgtFilterable) => boolean;
}

interface IAgenturListsFilterForm extends IAgenturListsFilter {
	isInline?: boolean;
}

const AgenturListsFilterForm = observer((props: IAgenturListsFilterForm) => {
	const { agenturListUiStore, uiStore, agenturMapsUiStore } = useStore();
	// const agtTypeFilter = agenturListUiStore.currentFilter.agtType;

	const onLegendChange = (name: string, val: ISelectOpt) => {
		runInAction(() => {
			agenturMapsUiStore.currentLegend = agenturMapsUiStore.legendOpts.find((o) => o.value === val.value) || DefaultLegend;
		});
	};

	const resetFilters = () => {
		agenturListUiStore.currentFilter.reset();
	};

	const closeModal = () => {
		uiStore.hideModal(uiStore.modalIds.agenturFilterModal);
	};

	return (
		<>
			{!uiStore.showMap && (
				<>
					{agenturListUiStore.currentFilter.savedFiltersOpts.length > 0 && (
						<div className="agt-list-load-filter">
							<AgenturListFilterLoad />
						</div>
					)}
				</>
			)}

			<div className={`agentur-filter-form`}>
				<FormDivider label="Filtern" />
				<div className="filters">
					{agenturListUiStore.currentFilter.visibleFilters.map((f) => {
						switch (f.type) {
							case 'Input':
								return (
									<FormField className={props.isInline ? 'is-inline' : ''} label={f.label} key={f.name}>
										<InputControl
											name={f.name}
											value={f.value}
											onChange={(n, v) => f.setPropertyString(v)}
											debounceTime={500}
											placeholder={f.placeholderText}
											icon="search"
										/>
									</FormField>
								);
							case 'SingleSelect':
								return (
									<FormField className={props.isInline ? 'is-inline' : ''} label={f.label} key={f.name}>
										<SelectInput
											name={f.name}
											isMulti={false}
											emptyText={f.placeholderText}
											value={f.value}
											onChange={(n, v) => f.setPropertyString(v)}
											opts={f.selectOpts!}
										/>
									</FormField>
								);
							case 'MultiSelect':
								return (
									<FormField className={props.isInline ? 'is-inline' : ''} label={f.label} key={f.name}>
										<SelectInput
											name={f.name}
											isMulti={true}
											emptyText={f.placeholderText}
											value={f.value}
											onChange={(n, v) => f.setPropertyArray(v)}
											opts={f.selectOpts!}
										/>
									</FormField>
								);
							case 'AgtTyp':
								if (uiStore.showMap) {
									return <></>;
								}

								return (
									<FormField className={props.isInline ? 'is-inline' : ''} label={f.label} key={f.name}>
										<div className="button-group is-radio-button-group is-large">
											<Button className={`button ${f.value === undefined ? 'is-active' : 'is-inverted'}`} onClick={() => f.setAgtType(undefined)}>
												Alle
											</Button>
											<Button className={`button ${f.value === AgtType.agt ? 'is-active' : 'is-inverted'}`} onClick={() => f.setAgtType(AgtType.agt)}>
												Agenturen
											</Button>
											<Button className={`button ${f.value === AgtType.ada ? 'is-active' : 'is-inverted'}`} onClick={() => f.setAgtType(AgtType.ada)}>
												ADAs
											</Button>
											<Button
												className={`button ${f.value === AgtType.sonstiges ? 'is-active' : 'is-inverted'}`}
												onClick={() => f.setAgtType(AgtType.sonstiges)}
											>
												Sonstiges
											</Button>
										</div>
									</FormField>
								);
							default:
								return <>E</>;
						}
					})}
				</div>

				{uiStore.showMap && (
					<>
						<FormDivider label="Darstellung" />
						<FormField className={props.isInline ? 'is-inline' : ''}>
							<SelectInput
								name="darstellung"
								isMulti={false}
								emptyText={agenturMapsUiStore.currentLegend.label}
								value={agenturMapsUiStore.currentLegend}
								onChange={onLegendChange}
								opts={agenturMapsUiStore.legendOpts}
							/>
						</FormField>
					</>
				)}
			</div>

			{!uiStore.showMap && (
				<>
					{agenturListUiStore.currentFilter.isDirty && (
						<>
							<FormDivider label="Speichern" />
							<AgenturListFilterSave />
						</>
					)}

					<>
						<div className="modal-footer">
							{agenturListUiStore.currentFilter.isLoadedFilter && <AgenturListFilterDelete />}

							<Button className="button is-inverted" onClick={resetFilters}>
								Zurücksetzen
							</Button>
							<Button className="button is-primary" onClick={closeModal}>
								Schliessen
							</Button>
						</div>
					</>
				</>
			)}
		</>
	);
});
