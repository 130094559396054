import { useStore } from 'app/context';
import { Field, Form, Formik } from 'formik';
import { observer } from 'mobx-react';
import * as Yup from 'yup';
import { notify } from 'app/components/common/notify';
import FormField from 'app/components/form/FormField';
import { Button } from 'app/components/common/Button';
import { ImportJobVM } from 'app/stores/ui/admin.imports.ui.store';
import { useHistory } from 'react-router';
import routes from 'routes';

const newAupFormSchema = Yup.object({
	kw: Yup.string().required('KW ist erfoderlich').default(''),
}).required();

type NewAupForm = Yup.InferType<typeof newAupFormSchema>;

interface IUserEditForm {
	job: ImportJobVM;
	onClose: () => void;
}
export const AdminImportNewCrawlerForm = observer((props: IUserEditForm) => {
	const { adminImportsUiStore } = useStore();
	const history = useHistory();
	const job = props.job;

	const handleSubmit = async (values: NewAupForm) => {
		job.job.meta.kw = values.kw;
		notify('Job wird gestartet', '', 'info');
		const res = (await adminImportsUiStore.triggerJob(job.job)) as any;
		history.push(routes.ADMINIMPORTS.getPath('job', res.id));
	};

	return (
		<>
			<Formik
				validationSchema={newAupFormSchema}
				initialValues={{
					kw: job.KW,
				}}
				onSubmit={handleSubmit}
			>
				{({ errors, touched, isValid, values }) => {
					return (
						<Form>
							<div className="pad-1rem">
								<FormField className="is-inline" error={errors.kw} touched={touched.kw} label="kw">
									<Field type="text" name="kw" placeholder="KW" />
								</FormField>
							</div>
							<div className="pad-1rem">
								<Button type="submit" className={'button is-primary'} disabled={!isValid}>
									Crawler für KW {values.kw} starten
								</Button>
							</div>
						</Form>
					);
				}}
			</Formik>
		</>
	);
});
