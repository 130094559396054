import { useStore } from 'app/context';
import { Field, Form, Formik } from 'formik';
import { observer } from 'mobx-react';
import * as Yup from 'yup';
import { notify } from 'app/components/common/notify';
import FormField from 'app/components/form/FormField';
import { Button } from 'app/components/common/Button';
import { INotifcationData } from 'app/stores/admin.notification.store';
import { ConfirmModal } from 'app/components/common/ConfirmModal';

const adminNotificationsSchema = Yup.object({
	betreff: Yup.string().required('betreff ist erfoderlich').default(''),
	text: Yup.string().trim(),
	testEmail: Yup.string().email().required(),
	testBensl: Yup.string(),
}).required();

type AdminNotifications = Yup.InferType<typeof adminNotificationsSchema>;

export const AdminNotificationsForm = observer(() => {
	const { adminNotificationsStore, uiStore } = useStore();

	const handleSubmit = async (values: AdminNotifications) => {};

	const getNotificationData = (values: AdminNotifications) => {
		const data: INotifcationData = {
			testEmailTo: values.testEmail,
			testBensl: values.testBensl,
			body: values.text,
			subject: values.betreff,
			sendLive: false,
		};
		return data;
	};

	return (
		<>
			<Formik
				validationSchema={adminNotificationsSchema}
				initialValues={{
					betreff: '[DVS] Persönlicher DVS-Bericht',
					text: '',
					testEmail: '',
					testBensl: '',
				}}
				onSubmit={handleSubmit}
			>
				{({ errors, touched, isValid, values }) => {
					const onTestVersand = async () => {
						const data = getNotificationData(values);
						data.sendLive = false;
						await adminNotificationsStore.sendNotification(data);
						notify('Testversand erfolgreich', '', 'info');
					};
					const onCancelLive = async () => {
						uiStore.hideModal(uiStore.modalIds.adminConfirmLiveNofications);
					};
					const onShowConfirm = () => {
						uiStore.showModal(uiStore.modalIds.adminConfirmLiveNofications);
					};
					const onSendLive = async () => {
						const data = getNotificationData(values);
						data.sendLive = true;
						await adminNotificationsStore.sendNotification(data);
						notify('Versand gestart', '', 'info');
					};

					return (
						<Form>
							<div className="pad-1rem">
								<FormField className="is-inline" error={errors.betreff} touched={touched.betreff} label="Betreff">
									<Field type="text" name="betreff" placeholder="Betreff" />
								</FormField>
								<FormField className="is-inline" error={errors.text} touched={touched.text} label="Text">
									<Field className="input" type="text" as="textarea" name="text" placeholder="Text" />
								</FormField>
								<FormField className="is-inline" error={errors.testEmail} touched={touched.testEmail} label="Test Versand an">
									<Field type="text" name="testEmail" placeholder=".. hier deine Email" />
								</FormField>
								<FormField className="is-inline" error={errors.testBensl} touched={touched.testBensl} label="Test Versand mit bensl">
									<Field type="text" name="testBensl" placeholder=".. ein Bensl deiner Wahl" />
								</FormField>
							</div>

							<div className="pad-1rem border-top">
								<Button type="button" className="button is-secondary" onClick={onTestVersand} disabled={!!errors.testBensl || !!errors.testEmail}>
									Test Versand (ungefährlich)
								</Button>
							</div>

							<div className="pad-1rem">
								<Button type="button" className="button is-red is-inverted" onClick={onShowConfirm}>
									LIVE Versand
								</Button>
								<br /> Vorsicht: Sendet die Email an <strong>alle</strong> VBLs.
							</div>

							<ConfirmModal modalId={uiStore.modalIds.adminConfirmLiveNofications} onConfirm={onSendLive} onCancel={onCancelLive}>
								Sind sie sicher dass sie die Emails an alle VBLs schicken wollen?
								<br />
								<br />
							</ConfirmModal>
						</Form>
					);
				}}
			</Formik>
		</>
	);
});
