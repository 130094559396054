import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { WidgetScope } from 'app/models/widgets/widget.setting.model';
import { WidgetContainer } from '../common/WidgetContainer';
import React, { useEffect, useState } from 'react';
import { WidgetsVm } from 'app/models/widgets/widget.vm';
import { DocumentViewerModal } from '../documents/DocumentViewerModal';
import { TaskViewerModal } from '../tasks/TaskViewModal';
import { runInAction } from 'mobx';

export const AgenturOverview = observer(() => {
	const { agenturUiStore, taskUiStore, documentUiStore } = useStore();
	const agt = agenturUiStore.current;

	const { widgetUiStore } = useStore();
	const [vm, setVm] = useState<WidgetsVm>();
	useEffect(() => {
		runInAction(() => {
			taskUiStore.currentFilter.reset();
			taskUiStore.currentFilter.agtId = agenturUiStore.currentAgtId;
			documentUiStore.currentFilter.agtId = agenturUiStore.currentAgtId;
		});
	}, [documentUiStore.currentFilter, agenturUiStore.currentAgtId, taskUiStore.currentFilter]);

	useEffect(() => {
		runInAction(() => {
			widgetUiStore.findByScope(WidgetScope.agt).then((res) => {
				res = res.filter((x) => !(x.hideFor && x.hideFor(agt)));
				setVm(new WidgetsVm(res));
			});
		});
	}, [widgetUiStore, agt]);

	return (
		<>
			<div className="dashboard has-bigger-left is-agentur">{agt && vm && <WidgetContainer vm={vm} />}</div>
			<DocumentViewerModal showPlan={true} showAgt={false} />
			<TaskViewerModal showPlan={true} showAgt={false} />
		</>
	);
});

export const ADAOverview = observer(() => {
	const { agenturUiStore, taskUiStore, documentUiStore } = useStore();
	const agt = agenturUiStore.current;

	const { widgetUiStore } = useStore();
	const [vm, setVm] = useState<WidgetsVm>();
	useEffect(() => {
		runInAction(() => {
			taskUiStore.currentFilter.reset();
			taskUiStore.currentFilter.agtId = agenturUiStore.currentAgtId;
			documentUiStore.currentFilter.agtId = agenturUiStore.currentAgtId;
		});
	}, [documentUiStore.currentFilter, agenturUiStore.currentAgtId, taskUiStore.currentFilter]);

	useEffect(() => {
		runInAction(() => {
			widgetUiStore.findByScope(WidgetScope.ada).then((res) => {
				res = res.filter((x) => !(x.hideFor && x.hideFor(agt)));
				setVm(new WidgetsVm(res));
			});
		});
	}, [widgetUiStore, agt]);

	return (
		<>
			<div className="dashboard has-bigger-left is-agentur">{agt && vm && <WidgetContainer vm={vm} />}</div>
			<DocumentViewerModal showPlan={true} showAgt={false} />
		</>
	);
});
