import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import routes from 'routes';
import Panel from '../Panel';
import { TreePanelHeader } from '../common/TreePanelHeader';
import { TreePanelMenu } from '../common/TreePanelMenu';
import { LeadNowVideoButton } from './leadnow/LeadNowVideoButton';
import { CoronaLeitfadenButton } from './corona/CoronaLeitfadenButton';

export const AnwendungenPanel: React.FC = observer(() => {
	const { anwendungenUiStore } = useStore();
	const node = anwendungenUiStore.menu.current;
	let title = node ? node.name : 'no node';

	const history = useHistory();

	const onClose = () => {
		if (anwendungenUiStore.isInAgt) {
			history.push(routes.AGENTUR.getPath(anwendungenUiStore.selectedAgt!.agtId.toString(), 'anwendungen'));
		} else {
			history.push(routes.ANWENDUNGEN.getPath());
		}
	};

	const path = node && node.currentPath ? node.currentPath : '';
	let extras;

	switch (path) {
		case 'bpoverview':
			break;
		case 'bp':
			const bpPos = anwendungenUiStore.currentBpPos!;
			title = ` ${node!.name} (${bpPos.einheit.longText})`;
			break;
		case 'leadnow':
			extras = <LeadNowVideoButton />;
			break;
		case 'corona':
			extras = <CoronaLeitfadenButton />;
			break;

		case '':
		case undefined:
		default:
			break;
	}

	let panelAreaRef = useRef<HTMLDivElement>(null);
	const [panel, setPanel] = useState<JSX.Element>(<></>);
	useEffect(() => {
		setPanel(anwendungenUiStore.currentComponent);
	}, [anwendungenUiStore.currentComponent]);

	return (
		<>
			{node && (
				<Panel classModifier="anwendungen-panel" title={title} header={<TreePanelMenu treeStore={anwendungenUiStore} />} onClose={onClose}>
					<TreePanelHeader title={title} panelAreaRef={panelAreaRef} treeStore={anwendungenUiStore} extras={extras} />
					<div className="panel-body" ref={panelAreaRef}>
						{panel}
					</div>
				</Panel>
			)}
		</>
	);
});
