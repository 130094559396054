import { KvAgtMsModel } from 'app/models/kv.agt.ms.model';
import { BaseCachedEntityStore } from './core/base.cached.entity.store';

export class KvAgtGoogleProfileStore extends BaseCachedEntityStore<KvAgtMsModel> {
	idProp: string = 'id';
	apiPath = 'api/kv_agentur_ms/googleprofile';

	createInstances(opts: any): KvAgtMsModel {
		return new KvAgtMsModel(opts);
	}
}
