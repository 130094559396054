import { Einheit } from 'app/models/core/einheit.model';
import { BnrGpPosModel } from '../bnr.gp.pos.model';
import { PosUnDef } from '../gp';
const posDef: BnrGpPosModel[] = [];

const of90 = (val: number) => val * 0.9;

posDef.push(new BnrGpPosModel({ year: 2023, posId: 2225001, group: 'vss', posText: 'Weiterbildungspfl. BNR (IDD)', planKey: 'idd', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2023, posId: 2225002, group: 'vss', posText: 'Weiterbildungsz.Fonds', planKey: 'weiterbildungfonds', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2023, posId: 2225011, group: 'vss', posText: 'Leben KNP AKS NSt Digi', planKey: 'lebenakspvnst', einheit: Einheit.lfNST }));
posDef.push(new BnrGpPosModel({ year: 2023, posId: 2225021, group: 'vss', posText: 'Leben Ris. Bew Digi', planKey: 'lebenrisbewoze', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2023, posId: 2225031, group: 'vss', posText: 'Nettomittelzufluss o. VZ', planKey: 'nettomittelzufluss', einheit: Einheit.bew }));
// not in agt gp
posDef.push(new BnrGpPosModel({ year: 2023, posId: 2225026, group: 'vss', posText: 'Leben Firmen Risiko', planKey: 'lebenrisbava', einheit: Einheit.bew }));
// not in agt gp
posDef.push(new BnrGpPosModel({ year: 2023, posId: 2275611, group: 'eh', posText: 'Leben Bew. o. ZE 100%', planKey: 'wb_lebenbewoze100', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2023, posId: 2275611, calcFunc: of90, group: 'eh', posText: 'Leben Bew. o. ZE 90%', planKey: 'wb_lebenbewoze90', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2023, posId: 2275612, group: 'eh', posText: 'Fonds Bew+FRVZ 100%', planKey: 'fondsbewfrz100', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2023, posId: 2275612, calcFunc: of90, group: 'eh', posText: 'Fonds Bew+FRVZ 90%', planKey: 'fondsbewfrz90', einheit: Einheit.bew }));

posDef.push(new BnrGpPosModel({ year: 2023, posId: 2225111, group: 'neh', posText: 'Leben KnP80 Bew Digi', planKey: 'lebenknp', einheit: Einheit.bew }));
// not in agt gp
posDef.push(new BnrGpPosModel({ year: 2023, posId: 2225121, group: 'neh', posText: 'Leben Firmen KnP80 Bew.', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2023, posId: 2225131, group: 'neh', posText: 'Fonds Bewertung', planKey: 'fondsbew', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2023, posId: PosUnDef, group: 'neh', posText: 'AktivDepot Digital', planKey: 'aktivdepotdigital', einheit: Einheit.bew }));
// not in agt gp
posDef.push(new BnrGpPosModel({ year: 2023, posId: 3825021, group: 'gpplus', posText: 'Wiederanlage', planKey: 'wb_wiederanlage', einheit: Einheit.bew }));
// not in agt gp
posDef.push(new BnrGpPosModel({ year: 2023, posId: 3825031, group: 'gpplus', posText: 'Firmen Fonds Bew', planKey: 'firmenfondsbew', einheit: Einheit.bew }));

export const VasPositionen = posDef;
