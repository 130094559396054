import { BnrTemplateModel } from './bnr.template.store';
import { BaseEntityStore } from './core/base.entity.store';

export class AdminTemplatesStore extends BaseEntityStore<BnrTemplateModel> {
	apiPath = 'api/admin/bnrvorlagen';
	idProp: string = 'id';
	createInstances(opts: any): BnrTemplateModel {
		return new BnrTemplateModel(opts);
	}

	async triggerAupJob(job: BnrTemplateModel) {
		return this.save(job);
	}
}
