import React from 'react';
import classNames from 'classnames';

interface IProps {
	label?: string;
	error?: string;
	touched?: boolean;
	className?: string;
	children?: React.ReactNode;
}

const FormField: React.FC<IProps> = ({ className, children, label, error, touched }) => {
	const classes = classNames('form-control', { 'has-errors': error && touched }, { [`${className}`]: className });

	return (
		<div className={classes}>
			{label && <label>{label}</label>}
			{children}
			{error && touched && <span className="help is-danger">{error}</span>}
		</div>
	);
};

export default FormField;

interface IFormDividerProps {
	label?: string;
	className?: string;
	children?: React.ReactNode;
}
export const FormDivider: React.FC<IFormDividerProps> = ({ className, label, children }) => {
	return (
		<div className={`form-control is-divider ${className || ''}`}>
			{label && <label>{label}</label>}
			{children ? <div className="children">{children}</div> : <div className="divider"></div>}
		</div>
	);
};
