import { computed, makeObservable, observable } from 'mobx';
import { formatAblauf } from 'app/utils';
import { AgenturModel } from 'app/models/agentur.model';

export class ExpertisModel {
	constructor(type: string) {
		makeObservable(this);
		this.type = type;
	}

	type: string;
	bit?: number = 0;
	end?: Date;

	@computed
	get endAblauf() {
		return formatAblauf(this.end);
	}

	@computed
	get text() {
		let res = '';
		switch (this.type) {
			case 'fachagenturFirmen':
				res += 'FA Firmen';
				break;
			case 'fachagenturKranken':
				res += 'FA Kranken';
				break;
			case 'fachagenturBaufi':
				res += 'FA Baufi';
				break;
			case 'experteFonds':
				res += 'Experte Fonds';
				break;
			case '34i':
				res += '34i';
				break;
			case 'faVermoegen':
				res += 'FA Vermögen';
				break;
			case 'zertAusbildung':
				res += 'Zertifizierte Ausbildungsagentur';
				break;
			case 'kompAv':
				res += 'Kompetenz Anlagevermittlung';
				break;
			case 'kompAb':
				res += 'Kompetenz Anlageberatung';
				break;
			default:
				res += this.type;
		}
		return res;
	}
}

export class AladinModel {
	constructor(agt: AgenturModel) {
		makeObservable(this);
		this.agtId = agt.agtId;
		// if (agt.agtId === 702130995) {
		// 	debugger;
		// }
		// this.bnrId = agt.bnrId;

		this.email = agt.aladinEmail;
		this.provStufenAP = agt.provStufenAP;
		this.provStufenFP = agt.provStufenFP;
		this.provApFonds = agt.provApFonds;
		this.provApLeben = agt.provApLeben;
		this.schadenregHoehe = agt.schadenregHoehe;
		this.schadenregRezertifizierungDatum = agt.schadenregSchulungsRezertifizierungDatum;

		const expFachFirme = new ExpertisModel('fachagenturFirmen');
		expFachFirme.bit = agt.fachagenturFirmen;
		expFachFirme.end = agt.fachagenturBaufiEndeDatum;
		this.expertisen.push(expFachFirme);

		const expFachKranken = new ExpertisModel('fachagenturKranken');
		expFachKranken.bit = agt.fachagenturKranken;
		expFachKranken.end = agt.fachagenturKrankenEndeDatum;
		this.expertisen.push(expFachKranken);

		const expBaufi = new ExpertisModel('fachagenturBaufi');
		expBaufi.bit = agt.fachagenturBaufi;
		expBaufi.end = agt.fachagenturBaufiEndeDatum;
		this.expertisen.push(expBaufi);

		const expFonds = new ExpertisModel('experteFonds');
		expFonds.bit = agt.experteFonds;
		expFonds.end = agt.experteFondsRezertifizierungsDatum;
		this.expertisen.push(expFonds);

		const exp34i = new ExpertisModel('34i');
		exp34i.bit = agt.a34i;
		this.expertisen.push(exp34i);


		// Aladin2
		const faVermoegen = new ExpertisModel('faVermoegen');
		faVermoegen.bit = agt.faVermoegen;
		this.expertisen.push(faVermoegen);

		const zertAusbildung = new ExpertisModel('zertAusbildung');
		zertAusbildung.bit = agt.zertAusbildung;
		this.expertisen.push(zertAusbildung);

		const kompAv = new ExpertisModel('kompAv');
		kompAv.bit = agt.kompAv;
		this.expertisen.push(kompAv);

		const kompAb = new ExpertisModel('kompAb');
		kompAb.bit = agt.kompAb;
		this.expertisen.push(kompAb);




		this.expertisen = this.expertisen.sort((e) => (e.bit ? e.bit : 99));
	}

	agtId: number;
	// bnrId: number;
	email?: string;
	@observable
	provStufenAP?: string;
	provStufenFP?: string;

	@computed
	get isSpezialvertrieb() {
		if (this.provStufenAP && this.provStufenAP.toLowerCase().indexOf('spezialver') >= 0) {
			return true;
		}
		if (this.provStufenFP && this.provStufenFP.toLowerCase().indexOf('spezialver') >= 0) {
			return true;
		}
		return false;
	}

	provApFonds?: number;
	provApLeben?: number;
	schadenregHoehe?: number;
	schadenregRezertifizierungDatum?: Date;

	@computed
	get shadenregRezerAblauf() {
		const date = this.schadenregRezertifizierungDatum;
		return formatAblauf(date);
	}

	expertisen: ExpertisModel[] = [];
}
