import { AgenturModel } from 'app/models/agentur.model';
import { BaseEntityStore } from './core/base.entity.store';

export class TempAgenturStore extends BaseEntityStore<AgenturModel> {
	apiPath = 'api/tempagt';
	idProp: string = 'agtId';

	createInstances(opts: any): AgenturModel {
		const agt = new AgenturModel(opts);
		return agt;
	}

	async transfer(tempAgtId: number, targetAgtId: number) {
		await this.callRefreshTokenIfNecessary();
		const url = `/api/tempagt/transfer/${tempAgtId}/${targetAgtId}`;
		const header = this.getAuthHeader();
		return this.axios({
			method: 'POST',
			url,
			headers: header.headers,
		});
	}
}
