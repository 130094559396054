import Widget, { IWidgetConsumer } from 'app/components/common/Widget';
import { observer } from 'mobx-react';
import { useStore } from 'app/context';
import { formatDate } from 'app/utils';
import React, { useEffect, useState } from 'react';
import { Button } from 'app/components/common/Button';
import { DocumentVM } from 'app/stores/ui/document.ui.store';
import { useHistory } from 'react-router';
import routes from 'routes';

const AllTeamDocs = () => {
	const history = useHistory();
	const { teamUiStore } = useStore();
	const onClick = () => {
		if (teamUiStore.currentBnrId) {
			history.push(routes.TEAM.getPath(teamUiStore.currentBnrId!.toString(), 'dokumente'));
		}
	};

	return (
		<Button className="button is-widget-header" onClick={onClick}>
			Alle
		</Button>
	);
};

export const TeamLast5DocsWidget = observer((props: IWidgetConsumer) => {
	const { uiStore, documentUiStore, teamUiStore } = useStore();

	const [docs, setDocs] = useState<DocumentVM[]>([]);
	useEffect(() => {
		setDocs(documentUiStore.last5ByTeam);
	}, [documentUiStore, documentUiStore.last5ByTeam, teamUiStore.currentBnrId]);

	const openDoc = (doc: DocumentVM) => {
		documentUiStore.setCurrentModalDoc(doc);
		uiStore.showModal(uiStore.modalIds.documentViewModal);
	};

	return (
		<Widget title={'Letzte Dokumente'} modifier="is-paddingless" action={<AllTeamDocs />} widgetId={props.widgetKey}>
			<table className="table">
				<tbody>
					{docs.map((d) => {
						return (
							<tr key={d.id}>
								<td>{formatDate(d.doc.modified)}</td>
								<td>{d.modalTitle}</td>
								<td className="has-text-right">
									<Button
										className="button is-text is-small"
										onClick={() => {
											openDoc(d);
										}}
									>
										Anzeigen
									</Button>
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</Widget>
	);
});
