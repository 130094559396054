import { MenuModel, TeamMenu } from 'app/models/core/menu.model';
import { UserModel } from 'app/models/core/user.model';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import routes from 'routes';
import { AgtUserUiStore } from './agt.user.ui.store';

export class TeamVM {
	user: UserModel;
	constructor(user: UserModel) {
		this.user = user;
	}

	@computed
	get bnrId() {
		return this.user.bnrId;
	}

	@computed
	get name() {
		return this.user.name;
	}

	@computed
	get steart() {
		return this.user.steart;
	}
}

export class TeamUiStore {
	agtUserUiStore: AgtUserUiStore;
	constructor(agtUserUiStore: AgtUserUiStore) {
		makeObservable(this);
		this.agtUserUiStore = agtUserUiStore;
	}

	@observable topMenu: MenuModel = TeamMenu;
	@observable currentPanel?: string;
	@observable currentLevel1?: string;
	@observable currentLevel2?: string;

	@observable current?: TeamVM;

	@computed
	get currentSteart() {
		return this.current && this.current.steart;
	}

	@computed
	get currentBnrId() {
		if (this.current) {
			return this.current.bnrId;
		}
		return undefined;
	}

	@observable
	items: TeamVM[] = [];
	@observable
	loaded: boolean = false;

	@action
	async loadById(sbnrId?: string, panel?: string, level1?: string, level2?: string) {
		this.currentLevel1 = level1;
		this.currentLevel2 = level2;
		this.currentPanel = panel;
		this.topMenu.setActivePath(panel!);

		if (sbnrId) {
			const bnrId = parseInt(sbnrId);
			const items = await this.load();
			runInAction(() => {
				this.current = items.find((u) => u.user.bnrId === bnrId);
			});
			if (this.current) {
				this.topMenu.items.forEach((i) => {
					const teamPath = routes.TEAM.getPath(this.current!.bnrId.toString(), i._path);
					i.updatePath(teamPath);
				});
				this.topMenu.setActivePath(panel!);
			}
		} else {
			this.current = undefined;
		}
	}

	@action
	async load() {
		if (this.loaded) {
			return this.items;
		}
		let users = await this.agtUserUiStore.load();
		users = users.filter((u) => u.bnrId !== this.agtUserUiStore.userStore.session.currentUser!.bnrId);

		const vms = users.map((u) => {
			return new TeamVM(u);
		});

		runInAction(() => {
			this.items = vms;
			this.loaded = true;
		});

		return this.items;
	}
}
