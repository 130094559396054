import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import React from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router';
import routes from 'routes';
import Panel from '../Panel';
import { TeamDokumente } from './TeamDokumente';
import { TeamMenu } from './TeamMenu';
import { TeamOverview } from './TeamOverview';

export const TeamDetail = observer(() => {
	const { teamUiStore } = useStore();
	const team = teamUiStore.current;
	const history = useHistory();
	let match = useRouteMatch();

	const onClose = () => {
		history.push(routes.TEAM.getPath());
	};

	return (
		<>
			{team && (
				<Panel title={team.name} subtitle={team.bnrId.toString()} header={<TeamMenu />} onClose={onClose}>
					<Switch>
						<Route path={`${match.path}/:bnrId/dokumente`}>
							<TeamDokumente />
						</Route>
						<Route path={`${match.path}/:bnrId`} exact>
							<TeamOverview />
						</Route>
					</Switch>
				</Panel>
			)}
		</>
	);
});
