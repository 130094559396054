import { observer } from 'mobx-react';
import { useStore } from 'app/context';
import React, { useEffect, useState } from 'react';
import { DocumentViewer } from 'app/components/documents/DocumentViewer';
import { DocumentShareModal } from 'app/components/documents/DocumentShareModal';
import { DocumentSendModal } from 'app/components/documents/DocumentSendModal';
import { DocumentFilter } from 'app/components/documents/DocumentsFilter';
import { AgtPlanYearVM } from 'app/stores/ui/agt.plan.ui.store';
import { DocumentViewerModal } from 'app/components/documents/DocumentViewerModal';

export const AgtPlanungDocs = observer(() => {
	const { agtPlanUiStore, documentUiStore } = useStore();

	const [planung, setPlanung] = useState<AgtPlanYearVM>();
	useEffect(() => {
		if (agtPlanUiStore.currentAgtId) {
			documentUiStore.currentFilter.resetScoped();
			if (agtPlanUiStore.current) {
				setPlanung(agtPlanUiStore.current);
				documentUiStore.load().then(() => {
					if (planung) {
						documentUiStore.ensurePlanungsToolDoc(planung).then(() => {
							documentUiStore.ensureJpgPlanungDocs(planung);
						});
					}
				});
			}
		} else {
		}
	}, [agtPlanUiStore, planung, documentUiStore, agtPlanUiStore.currentAgtId]);

	const onClose = () => {
		// nothing to do
	};

	const onModalClose = () => {
		documentUiStore.setCurrent();
	};

	return (
		<>
			{/* fix for force reflow bug when switching to edit mode https://yonatankra.com/layout-reflow/ */}
			{!documentUiStore.currentDocId && <DocumentFilter agtId={agtPlanUiStore.currentAgtId} planYear={agtPlanUiStore.currentYear} />}
			{documentUiStore.currentItems.map((d) => (
				<DocumentViewer key={d.key} doc={d} showAgt={false} editInModal={!d.isAp} showPlan={false} hideNavi={false} onClose={onClose} />
			))}
			<DocumentShareModal />
			<DocumentSendModal />
			<DocumentViewerModal showAgt={false} showPlan={false} onCloseComplete={onModalClose} />
		</>
	);
});
