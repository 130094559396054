import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';

const NotFoundContainer = observer(() => {
	return (
		<div className="not-found-container pad-1rem">
			<div className="pad-1rem content">
				<div className="title is-3">Seite nicht gefunden</div>
				<p>&nbsp;</p>
				<Link className="button is-primary" to="/">
					Zurück
				</Link>
			</div>
		</div>
	);
});

export default NotFoundContainer;
