export interface ILeadnowFormItem {
	groupId: string;
	key: string;
	label: string;
	details?: string;
	editable?: boolean;
	pro3Paket2?: boolean;
	visible?: boolean;
	actionButton?: any;
}

export interface ILeadNowFormGroup {
	groupId: string;
	name: string;
	visible?: boolean;
}

export const LeadnowFormGroups: ILeadNowFormGroup[] = [
	{
		name: 'Stand Digitalisierung',
		groupId: 'df',
		visible: true,
	},
	{
		name: 'Erwartungshaltung an das Lead Geschäft',
		groupId: 'erwartung',
		visible: true,
	},
	{
		name: 'Agenturprozesse rund um die Leadbearbeitung',
		groupId: 'agenturprozesse',
		visible: true,
	},
	{
		name: 'Status',
		groupId: 'overall',
		visible: true,
	},
];

export const LeadnowFormItems: ILeadnowFormItem[] = [
	{
		groupId: 'overall',
		key: 'overallState',
		label: 'Overall',
		visible: true,
	},
	{
		groupId: 'df',
		key: 'OT',
		label: 'Online Terminierung',
		editable: false,
	},
	{
		groupId: 'df',
		key: 'EKOMI',
		label: 'e-Komi',
		editable: false,
	},
	{
		groupId: 'df',
		key: 'ESIGN',
		label: 'e-Sign',
		editable: false,
	},
	{
		groupId: 'df',
		key: 'WA',
		label: 'WhatsApp',
		editable: false,
	},
	{
		groupId: 'df',
		key: 'GTM',
		label: 'Onlineberatung',
		editable: false,
	},
	{
		groupId: 'erwartung',
		key: 'zeitinvest',
		label: 'Bereit für Zeitinvest?',
		details: 'Ca. 20 % der Wochenarbeitszeit wird für die Bearbeitung von Leads beansprucht.',
	},
	{
		groupId: 'erwartung',
		key: 'finanzinvest',
		label: 'Bereit für Finanzinvest?',
		details: 'Ihr monatliches Investitionsvolumen sollte zwischen 800 – 1700 Euro je nach individuellem Bestellverhalten liegen.',
		pro3Paket2: false,
	},
	{
		groupId: 'erwartung',
		key: 'bestellmengen',
		label: 'Bestellmenge bekannt?',
		details: 'Die empfohlene Bestellmenge beträgt etwa 30 Leads pro Monat.',
		pro3Paket2: true,
	},
	{
		groupId: 'erwartung',
		key: 'conversionrate',
		label: 'Realistische Erwartung an Abschlußwahrscheinlichkeit?',
		details: 'Etwa 10 % der Nettoleads (d.h. nach Abzug der Leadstornos) führen zum Abschluss.',
	},
	{
		groupId: 'agenturprozesse',
		key: 'erstkontakt_interessent',
		label: 'Erstkontakt zum Interessenten',
		details: 'Erstkontakt zum Interessenten sollte innerhalb der ersten 10 Minuten nach Leadeingang erfolgen.',
	},
	// {
	// 	groupId: 'agenturprozesse',
	// 	key: 'reklamationsprozess',
	// 	label: 'Systematischer Reklamationsprozess?',
	// 	details: 'Marktübliche Reklamationsqutoten (10% in Sach, 50% in Leben) sind der Schlüssel zum Erfolg',
	// },
	{
		groupId: 'agenturprozesse',
		key: 'wiedervorlage',
		label: 'Integration Wiedervorlagesystem',
		details: 'Regelmäßiges Controlling hilft Ihnen Muster zu erkennen und Ihre internen Prozesse zu optimieren.',
	},
	{
		groupId: 'agenturprozesse',
		key: 'hartnaeckigkeit',
		label: 'Hartnäckigkeit und Durchhaltevermögen',
		details: 'Kontaktieren Sie einen Lead Interessenten bis zu sechs Mal bis zum erfolgreichen Erstkontakt.',
	},
	{
		groupId: 'agenturprozesse',
		key: 'qualifizierung',
		label: 'Qualifizierung und Weiterbildung',
		details: 'Zur Freischaltung Ihrer Agentur in Lead.NOW ist das erfolgreiche Absolvieren des e-Trainings Lead Management durch Sie als Agenturinhaber erforderlich.',
		actionButton: {
			buttonClass: 'no-print',
			buttonText: 'Akademie Start',
			buttonAction: 'openElearningEmail',
			buttonIcon: 'envelope',
		},
	},
];
