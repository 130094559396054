import MatomoTracker from '@datapunt/matomo-tracker-js';

export class TrackerStore {
	tracker?: MatomoTracker;
	opts: any;

	constructor(opts: any) {
		this.opts = opts;

		if (process.env.NODE_ENV !== 'production') {
			return;
		}

		this.tracker = new MatomoTracker({
			urlBase: window.location.origin,
			siteId: 1,
			trackerUrl: 'https://dvsdemo.isarbits.com/piwik/matomo.php',
			srcUrl: 'https://dvsdemo.isarbits.com/piwik/matomo.js',

			heartBeat: {
				active: false,
			},
			configurations: {
				setRequestMethod: 'POST',
			},
		});

		window.addEventListener('hashchange', this.trackPageView);
	}

	public trackPageView = () => {
		if (process.env.NODE_ENV !== 'production' || !this.tracker) {
			return;
		}

		this.tracker.trackPageView();
	};

	public trackEvent = (category: string, action: string, name: string) => {
		if (process.env.NODE_ENV !== 'production' || !this.tracker) {
			return;
		}
		this.tracker.trackEvent({ category, action, name });
	};
}
