import { computed } from 'mobx';
import { Einheit, EinheitModel, FindEinheit } from '../core/einheit.model';
// import { AgtPositionen2021 } from './2021/agt.positionen.def';
import { AgtPositionen2024 } from './2024/agt.positionen.def';
import { AgtPositionen2023 } from './2023/agt.positionen.def';
import { GpGroups, UnkownGroup } from './gp.groups';
import _ from 'lodash';

export interface IBnrGpPosModel {
	posId: number | number[];
	part?: string;
	group: string;
	planKey?: string;
	posText: string;
	einheit: Einheit;
	showGraph?: boolean;
	boniStufen?: number;
	year: number;
	posDesc?: string;

	calcFunc?: (val: number) => number;
}

export class BnrGpPosModel {
	constructor(opts: IBnrGpPosModel) {
		this._posId = opts.posId;
		this.year = opts.year;
		this.planKey = opts.planKey;
		this._group = opts.group;
		this.einheit = FindEinheit(opts.einheit);
		if (opts.showGraph) {
			this.showGraph = opts.showGraph;
		}
		if (opts.boniStufen) {
			this.boniStufen = opts.boniStufen;
		}
		this.posText = opts.posText;
		this.posDesc = opts.posDesc;
		this.calcFunc = opts.calcFunc;
	}

	calcFunc?: (val: number) => number;

	boniStufen: number = 1;
	_posId: number | number[];
	year: number;
	showGraph: boolean = false;
	einheit: EinheitModel;

	@computed
	get posId() {
		if (_.isArray(this._posId)) {
			return this._posId;
		}
		return [this._posId];
	}

	@computed
	get allPostIds() {
		let p = this.posId;
		if (this.boniStufen > 1) {
			const tp = this.posId[0];
			p = [tp];
			for (let i = 0; i < this.boniStufen; i++) {
				p.push(tp + i);
			}
		}
		return p;
	}

	_group: string;
	planKey?: string;
	posText: string;
	posDesc?: string;

	@computed
	get agtPosDef() {
		if (!this.planKey) {
			return undefined;
		}
		// let x = AgtPositionen2021.find((p) => p.planKey === this.planKey && p.year === this.year);
		// if (x) {
		// 	return x;
		// }
		let x = AgtPositionen2023.find((p) => p.planKey === this.planKey && p.year === this.year);
		if (x) {
			return x;
		}
		x = AgtPositionen2024.find((p) => p.planKey === this.planKey && p.year === this.year);
		return x;
	}

	@computed
	get group() {
		const res = GpGroups.find((group) => group.id === this._group);
		if (res) {
			return res;
		}
		return UnkownGroup;
	}
}
