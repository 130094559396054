import { UserHistory, UserModel } from 'app/models/core/user.model';
import { formatDateLong } from 'app/utils';
import { observer } from 'mobx-react';
import routes from 'routes';

interface IUserHistoryList {
	user: UserModel;
}

export const AdminUserHistoryList = observer((props: IUserHistoryList) => {
	const getAction = (h: UserHistory) => {
		switch (h.action) {
			case 'transferFrom':
				if (h.data) {
					const transferLink = '#' + routes.ADMINBNRTRANSFER.getPath(h.data);
					return <a href={transferLink}>{h.data}</a>;
				}
				return <></>;

			case 'bnrIdUpdate':
				return <>Vorherige BnrId {h.data}</>;
			default:
				break;
		}
		return <></>;
	};

	return (
		<ul>
			{props.user.history.map((h) => (
				<li key={h.date!.getTime()}>
					{formatDateLong(h.date)} {h.action} {getAction(h)}
				</li>
			))}
		</ul>
	);
});
