import { Table } from 'app/components/table/table';
import { AggregrationType, ITableColumn, TableModel } from 'app/components/table/table.model';
import { useStore } from 'app/context';
import { AgtAkumaPlanerVM, AkumaPlanerArtSchema } from 'app/stores/ui/agt.akumaplaner.ui.store';
import { formatKw, formatNum } from 'app/utils';
import { observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { AkumaPlanerOptions } from './AkumaPlanerListe';
import { AkumaPlanerKWModel, IAkumaPlanerValues } from 'app/stores/kv.agentur.year.akumaplaner.store';
import { AkumaPlanerWochenChart } from './AkumaPlanerWochenChart';

export const AkumaPlanerWochenbericht = observer(() => {
	const { berichteUiiStore, agtAkumaPlanerUiStore, agenturListUiStore } = useStore();
	const [groupedByKw, setGroupedByKw] = useState<IAkumaPlanerValues[]>();

	const tm = useMemo(() => {
		const tm = new TableModel<IAkumaPlanerValues, number>();
		const cols: ITableColumn<IAkumaPlanerValues, number>[] = [];

		cols.push({
			label: 'KW',
			path: 'bm',
			sortBy: 'bm',
			format: formatKw,
		});

		AkumaPlanerArtSchema.forEach((art) => {
			if (art.path === 'gpWochen') {
				return;
			}
			cols.push({
				label: art.label,
				path: `${art.path}`,
				format: formatNum,
				agg: [
					{
						path: `${art.path}`,
						aggType: AggregrationType.sum,
					},
				],
			});
		});

		tm.sortBy = 'bm';
		tm.setCols(cols);

		tm.idProperty = 'bm';
		tm.idType = 'number';

		return tm;
	}, []);

	useEffect(() => {
		agtAkumaPlanerUiStore.load().then(() => {
			let items = agtAkumaPlanerUiStore.currentKWItems;

			if (berichteUiiStore.selectedAgt) {
				const agtId = berichteUiiStore.selectedAgt.agtId;
				items = agtAkumaPlanerUiStore.filterByAgtIdandKw(agtId);
				items = _.sortBy(items, 'bm').reverse();
			} else {
				items = agenturListUiStore.applyFilterOnAgt(items) as AgtAkumaPlanerVM[];
			}

			// extract all kwItems
			let kwItems: AkumaPlanerKWModel[] = [];
			items.forEach((i) => {
				i.kwItems.forEach((k) => {
					kwItems.push(k);
				});
			});

			// group by bm and sum up all values
			const groupedByKw = _.chain(kwItems)
				.groupBy('bm')
				.map((value, key) => {
					const row: IAkumaPlanerValues = {
						bm: parseInt(key),
						gesamt: _.sumBy(value, 'gesamt'),
						km: _.sumBy(value, 'km'),
						nb: _.sumBy(value, 'nb'),
						nu: _.sumBy(value, 'nu'),
						zu: _.sumBy(value, 'zu'),
						zagt: _.sumBy(value, 'zagt'),
						zma: _.sumBy(value, 'zma'),
					};

					return row;
				})
				.value();

			setGroupedByKw(groupedByKw);
			tm.setRowData(groupedByKw);
			tm.sort();
		});
	}, [
		tm,
		berichteUiiStore.selectedAgt,
		agenturListUiStore.filterHasChanged,
		agenturListUiStore,
		agtAkumaPlanerUiStore.allVmItems,
		agtAkumaPlanerUiStore.currentKWItems,
		agtAkumaPlanerUiStore.maxKw,
		agtAkumaPlanerUiStore.minKw,
		agtAkumaPlanerUiStore.kws,
		agtAkumaPlanerUiStore,
	]);

	return (
		<>
			<AkumaPlanerOptions />
			{groupedByKw && <AkumaPlanerWochenChart data={groupedByKw} />}
			<Table stickyHeader={true} tm={tm}></Table>
		</>
	);
});
