import { Button } from 'app/components/common/Button';
import { useStore } from 'app/context';
import { NotizVM } from 'app/stores/ui/notizen.ui.store';
import { formatDateLong } from 'app/utils';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { HtmlContent } from '../common/HtmlContent';
import Widget, { IWidgetConsumer } from '../common/Widget';
import { WidgetNavigate } from '../common/WidgetNavigate';
import { NotizViewerModal } from './NotizViewerModal';

interface INotizItem {
	doc: NotizVM;
}
export const NotizItem = observer((props: INotizItem) => {
	const { notizenUiStore, uiStore } = useStore();

	const viewDoc = () => {
		runInAction(() => {
			notizenUiStore.setCurrentModalDoc(props.doc);
			uiStore.showModal(uiStore.modalIds.notizViewModal);
		});
	};
	return (
		<>
			<div className="notiz-widget-item">
				<Button className="button is-blank" onClick={viewDoc}>
					<HtmlContent content={props.doc.doc.content.text} />
					<div className="modified">
						Zuletzt geändert: <strong>{formatDateLong(props.doc.doc.modified)}</strong>
					</div>
				</Button>
			</div>
		</>
	);
});

export const NotizenHomeWidget = observer((props: IWidgetConsumer) => {
	const { notizenUiStore } = useStore();
	useEffect(() => {
		notizenUiStore.load();
	}, [notizenUiStore]);

	return (
		<>
			<Widget modifier="is-paddingless" title={'Meine Notizen'} action={<WidgetNavigate text={'Alle'} path={'/notizen'} />} widgetId={props.widgetKey}>
				{notizenUiStore.last5.map((n) => (
					<NotizItem key={n.id} doc={n} />
				))}
			</Widget>
			<NotizViewerModal />
		</>
	);
});
