import { Einheit } from 'app/models/core/einheit.model';
import { BnrGpPosModel } from '../bnr.gp.pos.model';
const posDef: BnrGpPosModel[] = [];

//ok
posDef.push(new BnrGpPosModel({ year: 2022, posId: 2265001, group: 'vss', posText: 'Weiterbildungspfl. BNR (IDD)', planKey: 'idd', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2022, posId: 2265010, group: 'vss', posText: 'KH-PkW LfNST Digi', planKey: 'khpkwlfnst', einheit: Einheit.lfNST }));
posDef.push(new BnrGpPosModel({ year: 2022, posId: 2265020, group: 'vss', posText: 'Fzg. in Kleinflotten/FPM', planKey: 'fzginkleinflotten', einheit: Einheit.lfNST }));
//nok: no data
posDef.push(new BnrGpPosModel({ year: 2022, posId: 2265032, group: 'vss', posText: 'P-Schutz o. Unf NSt_Digi', planKey: 'privatschutz', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2022, posId: 2265032, group: 'vss', posText: 'P-Schutz Unfall NSt_Digi', planKey: 'privatschutzunfall', einheit: Einheit.bew }));
// ok
posDef.push(new BnrGpPosModel({ year: 2022, posId: 2265041, group: 'eh', posText: 'Sach Bew o. Anp. U.', planKey: 'sachbewertung', einheit: Einheit.bew }));

posDef.push(new BnrGpPosModel({ year: 2022, posId: 2265131, group: 'neh', posText: 'P-Sach o.Kr_U_ Bew Digi', planKey: 'fsachokraftbew', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2022, posId: 2265151, group: 'neh', posText: 'Unfall Bew o. Anp. Digi', planKey: 'unfallbewrtungoanpp', einheit: Einheit.bew }));
//nok: are we rounding ziel up from 99,999,999 to 100,000,000?
posDef.push(new BnrGpPosModel({ year: 2022, posId: 2265160, group: 'neh', posText: 'Unfall Anschlussgeschäft', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2022, posId: 2265111, group: 'neh', posText: 'Firmen Sach o. Kr. Bew', planKey: 'psachokrunfbew', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2022, posId: 2265121, group: 'neh', posText: 'Firmen Kraft Bew', einheit: Einheit.bew }));

export const LsvPositionen = posDef;
