import { action, computed, IObservableArray, makeObservable, observable, runInAction } from 'mobx';
import { AdminBnrBlacklistStore, BnrBlacklistModel } from '../admin.bnrblacklist.store';

export class AdminBnrBlacklistUiStore {
	adminBnrBlacklistStore: AdminBnrBlacklistStore;

	constructor(adminBnrBlacklistStore: AdminBnrBlacklistStore) {
		makeObservable(this);
		this.adminBnrBlacklistStore = adminBnrBlacklistStore;
	}

	@observable current?: BnrBlacklistModel;

	@computed
	get currentItems() {
		return this._items;
	}

	_items: IObservableArray<BnrBlacklistModel> = observable([]);
	loaded: boolean = false;
	async load() {
		if (this.loaded) {
			return this._items;
		}

		const items = await this.adminBnrBlacklistStore.findAll();

		const res: BnrBlacklistModel[] = [];

		items.forEach((t) => {
			res.push(t);
		});
		runInAction(() => {
			this._items.replace(res);
			this.loaded = true;
		});

		return this._items;
	}

	@observable
	newBlacklist?: BnrBlacklistModel;

	@action
	createNew() {
		const blacklist = new BnrBlacklistModel({
			bnrId: 0,
			comment: '',
		});
		this.current = undefined;
		this.newBlacklist = blacklist;
	}

	@action
	async save(blacklist: BnrBlacklistModel) {
		await this.adminBnrBlacklistStore.save(blacklist);
		this.reload();
	}

	@action
	async reload() {
		this.loaded = false;
		await this.load();
	}

	async findAll() {
		return this.load();
	}
}
