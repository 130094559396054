import Widget, { IWidgetConsumer } from 'app/components/common/Widget';
import { useStore } from 'app/context';
import _ from 'lodash';
import { observer } from 'mobx-react';

export const AgenturThemenWidget = observer((props: IWidgetConsumer) => {
	const { agenturUiStore } = useStore();

	const agt = agenturUiStore.current;
	const jahresthemen = agt?.jahresthemen;
	const groupedThemen = _.groupBy(jahresthemen?.themen, 'group');

	return (
		<Widget title={'Jahresthemen'} modifier="is-paddingless" widgetId={props.widgetKey}>
			{agt && jahresthemen ? (
				<table className="table align-top">
					<tbody>
						{Object.keys(groupedThemen).map((key) => (
							<tr key={key}>
								<td>
									<strong>{key}</strong>
								</td>
								<td>
									{groupedThemen[key].map((thema) => (
										<div key={thema.key}>{thema.label}</div>
									))}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			) : (
				<div className="pad-1rem">Keine Jahresthemen</div>
			)}
		</Widget>
	);
});
