import { ApModel } from 'app/models/documents/ap.model';
import { DocumentVM } from 'app/stores/ui/document.ui.store';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { ApJahresZielFreeForm } from './ApJahresZielFreeForm';
import { ApJahresZielCalcForm } from './ApJahresZielCalcForm';
import { runInAction } from 'mobx';
import { IRadioButtonListItem, RadioButtonList } from 'app/components/common/RadioButtonList';

interface IApJahresZielForm {
	doc: DocumentVM;
}

enum CalcType {
	free = 'free',
	rechner = 'rechner',
}

export const ApCaltTypeToggle = observer((props: IApJahresZielForm) => {
	const ap = props.doc.doc.content as ApModel;

	const [calcType, setCalcType] = useState<CalcType>(CalcType.free);

	const toggleCalc = () => {
		runInAction(() => {
			ap.commissionCalcContent.enableCalc = !ap.commissionCalcContent.enableCalc;
			setCalcType(ap.commissionCalcContent.enableCalc ? CalcType.rechner : CalcType.free);
		});
	};
	useEffect(() => {
		setCalcType(ap.commissionCalcContent.enableCalc ? CalcType.rechner : CalcType.free);
	}, [ap]);

	const buttons: IRadioButtonListItem[] = [
		{ value: CalcType.free, label: 'Freie Eingabe' },
		{ value: CalcType.rechner, label: 'Via Einkommensrechner' },
	];

	return (
		<>
			<div className="grid is-col-half">
				<div className="is-left">
					<strong>Eingabe Gesamtbewertung</strong>
				</div>
				<div className="is-right">
					<RadioButtonList buttons={buttons} onSelect={toggleCalc} value={calcType} />
				</div>
			</div>
		</>
	);
});

export const ApJahresZielForm = observer((props: IApJahresZielForm) => {
	const ap = props.doc.doc.content as ApModel;
	return (
		<>
			<div className="grid ap-form">
				<ApCaltTypeToggle {...props} />

				{!ap.commissionCalcContent.enableCalc && <ApJahresZielFreeForm {...props} />}

				{ap.commissionCalcContent.enableCalc && <ApJahresZielCalcForm {...props} />}
			</div>
		</>
	);
});
