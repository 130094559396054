import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { useEffect, useRef } from 'react';
import { AgtAkumaPrimeKwVM, IAkumaPrimeDef } from 'app/stores/ui/agt.akumaprime.ui.store';
import { init, EChartsOption, ECharts } from 'echarts';
import { debounce } from 'lodash';

interface IAkumaPrimeAgtStackedHistory {
	schema: IAkumaPrimeDef[];
	data: AgtAkumaPrimeKwVM[];
}

export const AkumaPrimeAgtStackedHistory = observer((props: IAkumaPrimeAgtStackedHistory) => {
	const { berichteUiiStore } = useStore();
	const chartRef = useRef<HTMLDivElement>(null);
	const { data, schema } = props;

	useEffect(() => {
		const dim = ['name', ...schema.sort((a, b) => a.sort - b.sort).map((s) => s.path), 'total'];
		const legendData = schema.map((s) => ({ name: s.label }));

		const seriesOptions: echarts.SeriesOption = {
			type: 'bar',
			stack: 'a',
			label: {
				show: true,
				formatter: (params: any) => {
					const itemsTotal = params.value[params.value.length - 1];
					const val = params.value[params.encode.y[0]];
					const perc: string = itemsTotal > 0 ? `{italic|(${Math.round((val / itemsTotal) * 100)}%)}` : '';
					return val > 0 ? `{bold|${val}} ${perc}` : '';
				},

				rich: {
					bold: {
						fontWeight: 'bold',
					},
					italic: {
						fontStyle: 'italic',
					},
				},
			},
			emphasis: {
				focus: 'series',
			},
		};

		const seriesData = schema.map((s) => {
			return {
				...seriesOptions,
				name: s.label,
				color: s.color,
			};
		});

		// use invisible line with labes to show the total of einzuordnen...
		seriesData.push({
			// @ts-ignore
			type: 'line',
			name: 'Gesamt',
			color: 'transparent',
			symbolSize: 0,
			label: {
				show: true,
				position: 'top',
				formatter: (params) => {
					// @ts-ignore
					const val = params.value[params.encode.y[0]];
					return val > 0 ? `Gesamt: ${val}` : '';
				},
			},
		});

		const primeType = schema[0].type;
		const dataSetSource = data
			.sort((a, b) => b.kw - a.kw)
			.map((agtData) => {
				const name = agtData.kw;
				const vals = agtData.akumePrimeVals.filter((p) => p.type === primeType).sort((a, b) => a.sort - b.sort);
				const valsArr = vals.map((v) => v.value);
				const valsTotal = valsArr.reduce((acc, val) => acc + val, 0);
				return [name, ...valsArr, valsTotal];
			});

		let option: EChartsOption = {
			tooltip: {
				trigger: 'axis',
				valueFormatter: (value) => `${value}`,
			},
			xAxis: {
				type: 'category',
				axisLabel: {
					fontSize: 14,
				},
			},
			yAxis: {
				axisLabel: {
					fontSize: 14,
					// padding: [0, 5, 0, 0],
				},
			},
			dataset: {
				dimensions: dim,
				source: dataSetSource,
			},
			legend: {
				bottom: 10,
				data: legendData,
			},
			grid: {
				containLabel: true,
			},
			series: seriesData,
		};
		let chart: ECharts | null = null;
		if (chartRef.current !== null) {
			chart = init(chartRef.current, null, { renderer: 'svg' });
			chart.setOption(option);
		}

		const handleResize = debounce(() => {
			chart?.resize();
		}, 300);
		window.addEventListener('resize', handleResize);

		return () => {
			chart?.dispose();
			window.removeEventListener('resize', handleResize.cancel);
		};
	}, [chartRef, data, schema]);

	return (
		<>
			{berichteUiiStore.selectedAgt && (
				<div className="table-container">
					<div className="pad-1rem">
						<>
							<div style={{ width: '100%', height: 500, background: '#ffffff' }} ref={chartRef}></div>
						</>
					</div>
				</div>
			)}
		</>
	);
});
