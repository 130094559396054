import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import routes from 'routes';
import Panel from '../Panel';
import { TreePanelMenu } from '../common/TreePanelMenu';
import { TreePanelHeader } from '../common/TreePanelHeader';
import { DigifatktorOnePager } from './digifaktor/DigifaktorOnePager';
import { AkumaOnePager } from './akumaplaner/AkumaPlanerListe';

export const BerichtePanel: React.FC = observer(() => {
	const { berichteUiiStore } = useStore();
	const node = berichteUiiStore.menu.current;

	const history = useHistory();

	const onClose = () => {
		if (berichteUiiStore.isInAgt) {
			history.push(routes.AGENTUR.getPath(berichteUiiStore.selectedAgt!.agtId.toString(), 'berichte'));
		} else {
			history.push(routes.BERICHTE.getPath());
		}
	};

	const path = node && node.path ? node.path : '';
	let extras;

	if (path.indexOf('/digi/') > 0) {
		extras = <DigifatktorOnePager />;
	}
	if (path.indexOf('/akuma/planer/') > 0) {
		extras = <AkumaOnePager />;
	}

	let panelAreaRef = useRef<HTMLDivElement>(null);

	const [panel, setPanel] = useState<JSX.Element>(<></>);
	useEffect(() => {
		setPanel(berichteUiiStore.currentComponent);
	}, [berichteUiiStore.currentComponent]);

	return (
		<>
			{node && (
				<Panel classModifier="berichte-panel" title={node.name} header={<TreePanelMenu treeStore={berichteUiiStore} />} onClose={onClose}>
					<TreePanelHeader panelAreaRef={panelAreaRef} treeStore={berichteUiiStore} extras={extras} />
					<div className="panel-body" ref={panelAreaRef}>
						{panel}
					</div>
				</Panel>
			)}
		</>
	);
});
