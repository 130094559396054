import { useStore } from 'app/context';
import { ITransferable } from 'app/stores/ui/admin.bnrtransfer.ui.store';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';

interface IAdminBnrTransferables {
	sourceBnrId: number;
	targetBnrId: number;
}

export const AdminBnrTransferables = observer((props: IAdminBnrTransferables) => {
	const { adminBnrTransferUiStore } = useStore();
	const [transferables, setTransferables] = useState<ITransferable[]>();

	useEffect(() => {
		adminBnrTransferUiStore.check(props.sourceBnrId, props.targetBnrId).then((ts) => {
			setTransferables(ts);
		});
	}, [adminBnrTransferUiStore, props.sourceBnrId, props.targetBnrId]);

	return (
		<>
			{!transferables || (transferables.length === 0 && <div>Es sind keine Daten übertragbar</div>)}
			{transferables && transferables.length > 0 && (
				<table className={'table'}>
					<thead>
						<tr>
							<th>Datentyp</th>
							<th>Anzahl</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{transferables.map((t) => (
							<tr key={t.table}>
								<td>{t.table}</td>
								<td>{t.sum}</td>
							</tr>
						))}
					</tbody>
				</table>
			)}
		</>
	);
});
