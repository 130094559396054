import Widget, { IWidgetConsumer } from 'app/components/common/Widget';
import { WidgetNavigate } from 'app/components/common/WidgetNavigate';
import { useStore } from 'app/context';
import { BpAgtPosModel } from 'app/models/branchenplanung/bp.agt.pos.det.model';
import { FilterBranchenPosDefBySteart } from 'app/models/branchenplanung/bp.pos.def';
import { EinheitModel } from 'app/models/core/einheit.model';
import { AgtBpBaseModel } from 'app/stores/agt.bp.store';
import { AgtBpVM } from 'app/stores/ui/agt.branchenplanung.ui.store';
import { BPMAXYEAR } from 'app/stores/ui/berichte.ui.store';
import { formatNum, formatPercentTwoDig } from 'app/utils';
import { observer } from 'mobx-react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { BpUnitDisplay, RingValue } from './AgtBranchenplanungEdit';

interface IBpValue {
	posDef: BpAgtPosModel;
	editMode: boolean;
	einheit: EinheitModel;
	data: AgtBpVM | AgtBpBaseModel;
}

export const BpValue = observer((props: IBpValue) => {
	const posDef = props.posDef;
	const prop = posDef.prop;
	const [value, setValue] = useState<any>();

	useEffect(() => {
		let v = (props.data as any)[prop];
		if (!v) {
			// prevent this warning:
			// https://github.com/mui-org/material-ui/issues/4904
			v = '';
		}
		if (prop === 'leben') {
		}
		setValue(v);
	}, [prop, props.data]);

	return <span>{formatNum(value)}</span>;
});

interface IBnrBranchenplanungSummary {
	year: number;
	includeZe: boolean;
	filterOnBnrId?: number;
}

export const BnrBranchenplanungSummary = observer((props: IBnrBranchenplanungSummary) => {
	const { bnrBranchenplanungUiStore, agtUserUiStore, session, uiStore } = useStore();
	const _visiblePosDefs = FilterBranchenPosDefBySteart(session.currentUser!.steart);
	const [data, setData] = useState<AgtBpVM>();
	const [visiblePosDefs, setVisiblePosDefs] = useState<BpAgtPosModel[]>(_visiblePosDefs);
	const [editMode, setEditMode] = useState<boolean>(false);

	let titlePlanung = 'Meine Planung';
	if (session.currentUser!.isGsl) {
		titlePlanung = 'Planung GS';
	}

	useEffect(() => {
		setEditMode(uiStore.branchenPlanungEdit.enabled);
	}, [uiStore.branchenPlanungEdit.enabled]);

	useEffect(() => {
		if (props.filterOnBnrId) {
			agtUserUiStore.load().then(() => {
				const u = agtUserUiStore.users.find((u) => u.bnrId === props.filterOnBnrId);
				if (u) {
					setVisiblePosDefs(FilterBranchenPosDefBySteart(u.steart));
				}
			});
		}
	}, [props.filterOnBnrId, agtUserUiStore]);

	useEffect(() => {
		bnrBranchenplanungUiStore.getBnrSummaryByYear(props.year, session.currentUser!.isGsl, props.filterOnBnrId).then((d) => {
			setData(d);
		});
	}, [bnrBranchenplanungUiStore, props.year, props.filterOnBnrId, session.currentUser]);

	return (
		<>
			{data && (
				<div className="table-container">
					<table className="table">
						<thead>
							<tr>
								<th>Branche</th>
								<th>{titlePlanung} Σ</th>
								<th>Geplante Agenturen </th>
								{props.includeZe && (
									<>
										<th>GP/Ring Ziel</th>
										<th>Ergebnis</th>
										<th>Meine ZE</th>
										<th>ZE Zeit</th>
									</>
								)}
							</tr>
						</thead>

						<tbody>
							{visiblePosDefs.map((bpPosDef) => {
								const fak = bnrBranchenplanungUiStore.currentUmrechnungsFaktor;
								const einheit = bpPosDef.getEinheitForFak(fak);

								return (
									<tr key={bpPosDef.prop}>
										<td>
											<strong>{bpPosDef.posText}</strong> [<BpUnitDisplay model={bpPosDef} />]
										</td>
										<td>
											<div className="tag is-blue">
												<BpValue data={data} posDef={bpPosDef} editMode={editMode} einheit={einheit} />
											</div>
										</td>
										<td>
											<div className="tag is-green">
												<BpValue data={data.countPlanned} posDef={bpPosDef} editMode={editMode} einheit={einheit} /> / {data.totalAgtCount}
											</div>
										</td>
										{props.includeZe && (
											<>
												<td>
													<RingValue planKey={bpPosDef.planKey} data={data.ringZiel} />
												</td>
												<td>
													<RingValue planKey={bpPosDef.planKey} data={data.ringErgebnis} />
												</td>

												<td>
													<RingValue planKey={bpPosDef.planKey} data={data.ringZE} format={formatPercentTwoDig} />
												</td>

												<td>
													<RingValue planKey={bpPosDef.planKey} data={data.ringZEZeit} format={formatPercentTwoDig} />
												</td>
											</>
										)}
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			)}
		</>
	);
});

const BpActions = observer(() => {
	const rptPath = `/anwendungen/bp/${moment().year()}/overview`;
	return (
		<div className="grid is-col-auto align-center">
			<WidgetNavigate text={'Alle'} path={rptPath} />
		</div>
	);
});

export const BnrBranchenplanungSummaryWidget = observer((props: IWidgetConsumer) => {
	const currentYear = moment().year();

	return (
		<Widget modifier="is-paddingless" title={'Branchenplanung'} action={<BpActions />} widgetId={props.widgetKey}>
			<BnrBranchenplanungSummary year={currentYear} includeZe={false} />
		</Widget>
	);
});

export const TeamBranchenplanungSummaryWidget = observer((props: IWidgetConsumer) => {
	const { teamUiStore, agtUserUiStore, session } = useStore();
	const [visible, setVisible] = useState(false);
	if (!teamUiStore.current) {
		return <></>;
	}
	useEffect(() => {
		if (teamUiStore.current && teamUiStore.current.bnrId) {
			agtUserUiStore.load().then(() => {
				const u = agtUserUiStore.users.find((u) => u.bnrId === teamUiStore.current!.bnrId);
				if (u) {
					const curr = session.currentUser!;
					setVisible(curr.steart.canViewGroup(u));
				}
			});
		} else {
			setVisible(true);
		}
	}, [agtUserUiStore, session.currentUser, teamUiStore, teamUiStore.current.bnrId]);
	return (
		<>
			{visible && (
				<Widget modifier="is-paddingless" title={'Branchenplanung'} action={<BpActions />} widgetId={props.widgetKey}>
					<BnrBranchenplanungSummary year={BPMAXYEAR} includeZe={false} filterOnBnrId={teamUiStore.current.bnrId} />
				</Widget>
			)}
		</>
	);
});
