import { observer } from 'mobx-react';
import FormField from '../form/FormField';
import { InputControl } from '../common/InputControl';
import { useStore } from 'app/context';
import { useState } from 'react';
import { Button } from '../common/Button';

export const AgenturListFilterSave = observer(() => {
	const { agenturListUiStore } = useStore();

	const [text, setText] = useState<string>('');
	const saveFilter = () => {
		agenturListUiStore.currentFilter.saveFilter(text);
	};

	return (
		<>
			<FormField className={''} label="Filter speichern als">
				<InputControl name="filtersaveas" value={text} onChange={(name, value) => setText(value)} debounceTime={200} placeholder={'Speichern als...'} icon="save" />
			</FormField>

			<div className="pad-top-1rem is-right">
				<Button type="button" className="button is-primary" onClick={saveFilter} disabled={text.length < 3}>
					Speichern
				</Button>
			</div>
		</>
	);
});
