const data = [
	{ katId: 10, name: 'HV' },
	{ katId: 11, name: 'GV' },
	{ katId: 12, name: 'HVFIRM' },
	{ katId: 13, name: 'GVFIRM' },
	{ katId: 14, name: 'HVIE' },
	{ katId: 15, name: 'HV Pro3' },
	{ katId: 16, name: 'GV Pro3' },
	{ katId: 17, name: 'HVOB' },
	{ katId: 18, name: 'BOHV' },
	{ katId: 19, name: 'AGP' },
	{ katId: 20, name: 'Gebundener Vermittler/NV' },
	{ katId: 22, name: 'Allianz Netzwerkpartner' },
	{ katId: 30, name: 'Nebenberufs-Vertreter ohne Folgeprovisionsanspruch' },
	{ katId: 31, name: 'Verkaufsregion Direkt' },
	{ katId: 32, name: 'VIS-Bestände BI' },
	{ katId: 40, name: 'Außendienst-Angestellte(r)' },
	{ katId: 53, name: 'Mehrfachvertretung' },
	{ katId: 60, name: 'IDA Gebundener Vermittler/NV' },
	{ katId: 61, name: 'Pens. IDA/ADA Gebundener Vermittler/NV,Pens. IDA/ADA Gebundener Vermittler/NV' },
	{ katId: 62, name: 'Pens. GV/HV Gebundener Vermittler/NV' },
	{ katId: 63, name: 'IDA Botschafter' },
	{ katId: 64, name: 'Pensionäre IDA/ADA Botschafter' },
	{ katId: 65, name: 'Pensionäre GV/HV Botschafter' },
	{ katId: 90, name: 'sonstige' },
];

export class VertkatModel {
	constructor(opts: any) {
		this.id = opts.katId;
		this.name = opts.name;
	}
	id: number;
	name: string;
}

export const Vertkats = data.map((d) => new VertkatModel(d));
