import { Einheit } from 'app/models/core/einheit.model';
import { BnrGpPosModel } from '../bnr.gp.pos.model';
import { PosUnDef } from '../gp';
const posDef: BnrGpPosModel[] = [];

// posDef.push(
// 	new BnrGpPosModel({
// 		year: 2023,
// 		posId: 2315001,
// 		group: 'gwp',
// 		posText: 'Gesetzliche Weiterbildungspflicht',
// 		planKey: 'idd',
// 		einheit: Einheit.bew,
// 		posDesc: `Voraussetzung für die Bonifikation ist der Nachweis von 15 Weiterbildungsstunden im Geschäftsjahr`,
// 	}),
// );

posDef.push(
	new BnrGpPosModel({
		year: 2024,
		posId: PosUnDef,
		group: 'ausMaxi',
		posText: 'Systematische Vertriebsunterstützung',
		planKey: 'vertriebsunterstz_i',
		einheit: Einheit.quali,
		posDesc: `Aktive Unterstützung der ABV Hauptverwaltung bei strategischen Schwerpunkten zur systematischen Vertriebssteuerung in den Agenturen`,
	}),
);
posDef.push(
	new BnrGpPosModel({
		year: 2024,
		posId: PosUnDef,
		group: 'ausMaxi',
		posText: 'Kundenansprache und Agenturprozesse',
		planKey: 'agtproz',
		einheit: Einheit.quali,
		posDesc: `
		Multiplikator:in und Ansprechpartner:in im Verant- wortungsbereich für die Gestaltung kunden- orientierter Agenturabläufe. Verantwortung für die Etablierung von Anwendungen zur Steigerung der Kundenzufriedenheit und des Kundenkontakts. Identifizierung von Best-Practices und deren Multiplikatoren im Verantwortungsbereich`,
	}),
);
posDef.push(
	new BnrGpPosModel({
		year: 2024,
		posId: PosUnDef,
		group: 'ausMaxi',
		posText: 'Einsatz digitaler Instrumente',
		planKey: 'digi_instr',
		einheit: Einheit.quali,
		posDesc: `Befähigung von Vertretungen und Vertriebseinheiten im Verantwortungsbereich und Ausbau der Nutzung von zugelassenen digitalen Anwengungen&Tools entlang der strategischen Ziele der Gesellschaft.`,
	}),
);

posDef.push(
	new BnrGpPosModel({
		year: 2024,
		posId: [2310100, 2315100],
		group: 'max8k',
		posText: 'Neue Digi-ID im Bestand (E-Mailadressen und Mobilnummern)',
		planKey: 'digiid_bestand',
		einheit: Einheit.proz,
		posDesc: `Umwandlung Kund:innen ohne Digi ID in Kund:innen mit Digi ID im Bestand. Basis Jahresendbestand Vorjahr`,
	}),
);
posDef.push(
	new BnrGpPosModel({
		year: 2024,
		posId: [2310105, 2315105],
		group: 'max8k',
		posText: 'Neuanmeldungen Meine Allianz',
		planKey: 'neunanmeldungen_maz',
		einheit: Einheit.proz,
		posDesc: `Steigerung der Durchdringung von Kund:innen mit Meine Allianz (mAZ) im Bestand.`,
	}),
);
posDef.push(
	new BnrGpPosModel({
		year: 2024,
		posId: [2310125, 2315125],
		group: 'max8k',
		posText: 'Steigerung der Cross-Selling-Quote und Kundenbindung',
		planKey: 'crossselling',
		einheit: Einheit.proz,
		posDesc: `Anzahl GV/HV mit wiederkehrender AKUMA Nutzung in der VD plus Anzahl GV/HV an AKUMA.Prime (Wiederkehrende AKUMA Planer Nutzung von mindestens 6 Wochen im Jahr 2024)
						Stichtag der Bemessungsgrundlage für das Jahresziel ist der GV/HV Bestand zum 01.01.2024`,
	}),
);
posDef.push(
	new BnrGpPosModel({
		year: 2024,
		posId: [2310140, 2315130],
		group: 'max8k',
		posText: 'Durchführung, Vor- und Nachbereitung von Schulungsmaßnahmen',
		planKey: 'schulungen',
		einheit: Einheit.stck,
		posDesc: 'Anzahl gehaltener Schulungen über das AAA- Portal, die der EDT als Referent verantwortet',
	}),
	///////////
);
posDef.push(
	new BnrGpPosModel({
		year: 2024,
		posId: [2310150, 2315150],
		group: 'max8k',
		posText: 'Bewerbung der web-DSE in der VD',
		planKey: 'webdse',
		einheit: Einheit.quali,
		posDesc: `Bekanntmachung und Multiplikation der Themen der web-DSE durch die EDP zur stärkeren Frequentierung durch die Agenturen`,
	}),
);

posDef.push(
	new BnrGpPosModel({
		year: 2024,
		posId: [2310160, 2315160],
		group: 'max8k',
		posText: 'Unterstützung in der Umstellung auf AMIS.NOW',
		planKey: 'webdse',
		einheit: Einheit.quali,
		posDesc: `Durchführen von Schulungsmaßnahmen im Rahmen
		einzelner Komponenten der Datenmigration.		
		Eigeninitiative und Innovationsbereitschaft in der Breitenmultiplikation bspw. durch die Organisation von digitalen und physischen Events im Verantwortungsbereich bei der Umstellung auf AMIS.NOW.`,
	}),
);

export const EdtPositionen = posDef;
