import { Button } from 'app/components/common/Button';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router';
import routes from 'routes';

export const AdminNewBlacklistButton = observer(() => {
	const history = useHistory();
	const open = () => {
		history.push(routes.ADMINBNRBLACKLIST.getPath('new', '0'));
	};
	return (
		<>
			<Button type="button" className="button is-primary" onClick={open}>
				Neuen Blacklist-Eintrag
			</Button>
		</>
	);
});
