import { TableModel } from 'app/components/table/table.model';
import { formatCurrency, formatNum, formatPercent } from 'app/utils';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useState } from 'react';

import { ScatterChart, Scatter, XAxis, YAxis, ZAxis, ResponsiveContainer, Label, ReferenceArea, TooltipProps, Tooltip, ReferenceLine } from 'recharts';

import colors from 'assets/scss/colors.module.scss';
import { AgtSegmentVM, SEG_BAQ_LOWER } from 'app/stores/ui/agt.segment.ui.store';
import { SegmentState } from 'app/models/segment/segment.model';
import { SegmentName } from './SegmentTag';

export interface IScatterPlotVarationModel {
	key: string;
	labelX: string;
	labelY: string;
	pathX: string;
	pathY: string;
	buttonLabel: string;
	plotColor: string | ((model: any) => string);
	plotColorProp?: string;
	domainX?: [any, any];
	ticksX?: number[];
}

export class ScatterPlotVarationModel {
	constructor(opts: IScatterPlotVarationModel) {
		this.key = opts.key;
		this.labelX = opts.labelX;
		this.labelY = opts.labelY;
		this.pathX = opts.pathX;
		this.pathY = opts.pathY;
		this.buttonLabel = opts.buttonLabel;
		this.plotColor = opts.plotColor;
		if (opts.plotColorProp) {
			this.plotColorProp = opts.plotColorProp;
		}
		if (opts.domainX) {
			this.domainX = opts.domainX;
		}
		if (opts.ticksX) {
			this.ticksX = opts.ticksX;
		}
	}
	key: string;
	labelX: string;
	labelY: string;
	pathX: string;
	pathY: string;
	buttonLabel: string;
	plotColor: string | ((model: any) => string);
	plotColorProp?: string;
	domainX: [any, any] = ['auto', 'auto'];
	ticksX: number[] = [];
}

export class ScatterPlotModel<T, P> {
	constructor(opts: any) {
		this.tableModel = opts.tableModel;
		this.variations = opts.variations;
	}

	tableModel: TableModel<T, P>;
	variations: ScatterPlotVarationModel[];
}

export interface IScatterPlotChart<T, P> {
	tableModel: TableModel<T, P>;
}

export const SegmentChart = observer((props: IScatterPlotChart<any, any>) => {
	const [activeEntwicklungsIndex, setActiveEntwicklungsIndex] = useState<number | undefined>();
	const [activeStarterIndex, setActiveStarterIndex] = useState<number | undefined>();
	const [activePotentialIndex, setActivePotentialIndex] = useState<number | undefined>();
	const [activeBreitenIndex, setActiveBreitenIndex] = useState<number | undefined>();
	const [activeUnternehmerIndex, setActiveUnternehmerIndex] = useState<number | undefined>();

	const tm = props.tableModel;
	const formatXAxis = (val: any) => {
		return formatCurrency(val, 0, false);
	};

	const onMouseEnter = (data: any, index: number) => {
		const agtId = data.payload.agtId;
		tm.setHoverId(agtId);
		setIndexForAgt(data.payload.agt);
	};

	const onMouseLeave = () => {
		tm.setHoverId();
		resetAllActiveIndexes();
	};
	const baq1 = SEG_BAQ_LOWER;
	const baq2 = 20;

	// const maxBestand = _.maxBy(tm.data, 'bestand');
	const maxBestand = 3000000;
	// const maxBaq = _.maxBy(tm.data, 'baq');
	const maxBaq = 40;

	const bestand1 = 550000;
	const bestand2 = 1000000;

	const data: AgtSegmentVM[] = tm.data;

	const entwicklung = {
		label: 'Entwicklungsagentur',
		color: colors.red,
		data: data.filter((d) => d.displaySegment === SegmentState.entwicklungsagentur),
	};
	const breiten = {
		label: 'Breitenagentur',
		color: colors.yellow,
		data: data.filter((d) => d.displaySegment === SegmentState.breitenagentur),
	};

	const potential = {
		label: 'Potentialagentur',
		color: colors.blue,
		data: data.filter((d) => d.displaySegment === SegmentState.potential),
	};

	const unternehmer = {
		label: 'Unternehmeragentur',
		color: colors.green,
		data: data.filter((d) => d.displaySegment === SegmentState.unternehmer),
	};
	const starter = {
		label: 'Starteragentur',
		color: colors.purple,
		data: data.filter((d) => d.displaySegment === SegmentState.starter),
	};

	const setIndexForAgt = useCallback(
		(agt: AgtSegmentVM) => {
			if (!agt) {
				return;
			}
			switch (agt.displaySegment) {
				case SegmentState.entwicklungsagentur:
					setActiveEntwicklungsIndex(entwicklung.data.findIndex((d) => d.agtId === tm.hoverId));
					break;
				case SegmentState.starter:
					setActiveStarterIndex(starter.data.findIndex((d) => d.agtId === tm.hoverId));
					break;
				case SegmentState.potential:
					setActivePotentialIndex(potential.data.findIndex((d) => d.agtId === tm.hoverId));
					break;
				case SegmentState.breitenagentur:
					setActiveBreitenIndex(breiten.data.findIndex((d) => d.agtId === tm.hoverId));
					break;
				case SegmentState.unternehmer:
					setActiveUnternehmerIndex(breiten.data.findIndex((d) => d.agtId === tm.hoverId));
					break;
			}
		},
		[breiten.data, entwicklung.data, potential.data, starter.data, tm.hoverId],
	);

	const resetAllActiveIndexes = () => {
		setActiveEntwicklungsIndex(undefined);
		setActiveStarterIndex(undefined);
		setActivePotentialIndex(undefined);
		setActiveBreitenIndex(undefined);
		setActiveUnternehmerIndex(undefined);
	};

	useEffect(() => {
		const agt: AgtSegmentVM = tm.data.find((d) => d.agtId === tm.hoverId);
		resetAllActiveIndexes();
		if (agt) {
			setIndexForAgt(agt);
		}
	}, [tm.hoverId, tm.data, setIndexForAgt]);

	const margin = {
		top: 20,
		bottom: 20,
		left: 20,
		right: 20,
	};

	const activeCell = (props: any) => {
		const { cx, cy, fill } = props;
		return <circle cx={cx} cy={cy} r={10} fill={fill} stroke="none" />;
	};

	const bgGreen = '#E5F8D8';
	const bgBlue = '#E1F7FA';
	const bgYellow = '#FAF7DB';
	const bgRed = '#FBE9E9';
	const opacity = 0.7;

	data.forEach((d) => (d.z = 1)); // make the dots bigger by adding a z-axis

	return (
		<>
			<ResponsiveContainer width="99%" height={400} debounce={300}>
				<ScatterChart margin={margin}>
					{/* <CartesianGrid strokeDasharray="6 8" /> */}
					<XAxis dataKey="bestand3mMax" type="number" name="Bestand" unit="€" range={[0, maxBestand]} stroke={colors.grey} tickFormatter={formatXAxis}>
						<Label value={'Bestand'} offset={-10} position="insideBottom" />
					</XAxis>
					<YAxis dataKey="baq40Max" type="number" name="BAQ" unit="%" range={[0, maxBaq]}>
						<Label value={'BAQ'} offset={-10} position="insideLeft" />
					</YAxis>
					<ZAxis type="number" dataKey="z" scale={'log'} range={[1, 300]} name="z" unit="zs" />

					<ReferenceArea y1={0} y2={baq1} x1={0} x2={maxBestand} strokeWidth={0} fill={bgRed} fillOpacity={1} label={entwicklung.label} />
					<ReferenceArea y1={0} y2={baq1} x1={bestand1} x2={maxBestand} strokeWidth={0} fill={bgYellow} fillOpacity={1} label={breiten.label} />
					{/* Potential2 */}
					<ReferenceArea y1={baq1} y2={maxBaq} x1={0} x2={maxBestand} strokeWidth={0} fill={bgBlue} fillOpacity={1} label={potential.label} />
					{/* Potential2 */}
					<ReferenceArea y1={baq1} y2={baq2} x1={bestand2} x2={maxBestand} strokeWidth={0} fill={bgBlue} fillOpacity={1} label={''} />
					<ReferenceArea y1={baq2} y2={maxBaq} x1={bestand2} x2={maxBestand} strokeWidth={0} fill={bgGreen} fillOpacity={1} label={unternehmer.label} />

					<ReferenceLine y={SEG_BAQ_LOWER} stroke={colors.blue} strokeDasharray="8 12">
						<Label value={SEG_BAQ_LOWER + '%'} offset={-50} position="insideLeft" />
					</ReferenceLine>
					<ReferenceLine y={20} stroke={colors.green} strokeDasharray="8 12">
						{/* <Label value={'20%'} offset={-50} position="insideLeft" /> */}
					</ReferenceLine>
					<ReferenceLine x={550000} stroke={colors.red} strokeDasharray="8 12">
						<Label value={'550.000€'} offset={-30} position="insideBottom" />
					</ReferenceLine>
					<ReferenceLine x={1000000} stroke={colors.green} strokeDasharray="8 12">
						<Label value={'1.000.000€'} offset={-30} position="insideBottom" />
					</ReferenceLine>

					<Scatter opacity={opacity} name={entwicklung.label} data={entwicklung.data} fill={entwicklung.color} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} activeIndex={activeEntwicklungsIndex} activeShape={activeCell} dataKey={'agtId'} />
					<Scatter opacity={opacity} name={breiten.label} data={breiten.data} fill={breiten.color} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} activeIndex={activeBreitenIndex} activeShape={activeCell} dataKey={'agtId'} />
					<Scatter opacity={opacity} name={potential.label} data={potential.data} fill={potential.color} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} activeIndex={activePotentialIndex} activeShape={activeCell} dataKey={'agtId'} />
					<Scatter opacity={opacity} name={unternehmer.label} data={unternehmer.data} fill={unternehmer.color} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} activeIndex={activeUnternehmerIndex} activeShape={activeCell} dataKey={'agtId'} />
					<Scatter opacity={opacity} name={starter.label} data={starter.data} fill={starter.color} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} activeIndex={activeStarterIndex} activeShape={activeCell} dataKey={'agtId'} />
					<Tooltip content={<AgtSegmentTooltip />} />
				</ScatterChart>
			</ResponsiveContainer>
		</>
	);
});

const AgtSegmentTooltip = (props: TooltipProps<any, any>) => {
	const { active, payload } = props;
	if (active && payload && payload.length) {
		const data = payload[0].payload as AgtSegmentVM;
		return (
			<div className="custom-tooltip is-paddingless">
				<table className="table is-tooltip-table">
					<thead>
						<tr>
							<th colSpan={2}>{`${data.agt.name} (${data.agt.agtId})`}</th>
						</tr>
					</thead>
					<tbody>
						<>
							<tr>
								<td>Segment</td>
								<td>
									<SegmentName agt={data.agt} />
								</td>
							</tr>
							<tr>
								<td>BAQ:</td>
								<td>{formatPercent(data.baq, 1)}%</td>
							</tr>
							<tr>
								<td>Bestand</td>
								<td>{formatNum(data.bestand)}</td>
							</tr>
						</>
					</tbody>
				</table>
			</div>
		);
	}

	return null;
};
