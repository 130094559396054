import { action, computed, IObservableArray, makeObservable, observable } from 'mobx';
import { WidgetCol, WidgetSettingModel } from './widget.setting.model';

export class WidgetItemVm {
	constructor(item: WidgetSettingModel) {
		makeObservable(this);
		this.data = item;
		// temp values for undo!
		this.sort = item.opts.sort;
		this.col = item.opts.col;
		this.enabled = item.enabled;
	}
	data: WidgetSettingModel;
	allWidgets: IObservableArray<WidgetItemVm> = observable([]);

	@action
	applyForSave() {
		this.data.opts.sort = this.sort;
		this.data.opts.col = this.col;
		this.data.setEnabled(this.enabled);
	}

	@action
	undo() {
		this.sort = this.data.opts.sort;
		this.col = this.data.opts.col;
		this.enabled = this.data.enabled;
	}

	@action
	setAllWidgets(widgets: WidgetItemVm[]) {
		this.allWidgets.replace(widgets);
	}

	@observable
	enabled: boolean;

	@action
	toggleEnabled() {
		this.enabled = !this.enabled;
	}

	@observable
	sort: number;

	@observable
	col: number;

	@computed
	get widgetKey() {
		return this.data.widgetKey;
	}

	@computed
	get myColItems() {
		return this.allWidgets.filter((w) => w.col === this.col);
	}

	@computed
	get canMoveUp() {
		const first = this.myColItems[0];
		if (first && first.widgetKey === this.widgetKey) {
			return false;
		}
		return true;
	}

	@computed
	get canMoveDown() {
		const last = this.myColItems[this.myColItems.length - 1];
		if (last && last.widgetKey === this.widgetKey) {
			return false;
		}
		return true;
	}

	@computed
	get canMoveLeft() {
		return this.col === WidgetCol.right;
	}

	@computed
	get canMoveRight() {
		return this.col === WidgetCol.left;
	}
}

export class WidgetsVm {
	widgets: IObservableArray<WidgetItemVm> = observable([]);
	constructor(widgets: WidgetSettingModel[]) {
		makeObservable(this);
		const ws = widgets.map((i) => new WidgetItemVm(i));
		this.widgets.replace(ws);
		this.sort();
	}

	@action
	undo() {
		this.widgets.forEach((w) => w.undo());
	}

	@observable
	editMode: boolean = false;

	@action
	toggleEditMode() {
		this.editMode = !this.editMode;
	}

	@action
	moveUp(item: WidgetItemVm) {
		const myCols = this.widgets.filter((w) => w.col === item.col);
		const myIndex = myCols.findIndex((w) => w.widgetKey === item.widgetKey);
		const prevousItem = myCols[myIndex - 1];
		const pSort = prevousItem.sort;
		prevousItem.sort = item.sort;
		item.sort = pSort;
		this.sort();
	}
	@action
	moveDown(item: WidgetItemVm) {
		const myCols = this.widgets.filter((w) => w.col === item.col);
		const myIndex = myCols.findIndex((w) => w.widgetKey === item.widgetKey);
		const nextItem = myCols[myIndex + 1];
		const nSort = nextItem.sort;
		nextItem.sort = item.sort;
		item.sort = nSort;
		this.sort();
	}

	@action
	moveRight(item: WidgetItemVm) {
		item.col = WidgetCol.right;
		this.sort();
	}

	@action
	moveLeft(item: WidgetItemVm) {
		item.col = WidgetCol.left;
		this.sort();
	}

	@action
	sort() {
		const sort = this.widgets.sort((a, b) => a.sort - b.sort);
		sort.filter((a) => a.col === WidgetCol.left).forEach((a, i) => (a.sort = i));
		sort.filter((a) => a.col === WidgetCol.right).forEach((a, i) => (a.sort = i));
		this.widgets.replace(sort);
		this.widgets.forEach((w) => w.setAllWidgets(this.widgets));
	}

	@computed
	get left() {
		return this.widgets.filter((w) => w.col === WidgetCol.left);
	}

	@computed
	get right() {
		return this.widgets.filter((w) => w.col === WidgetCol.right);
	}
}
