import Widget, { IWidgetConsumer } from 'app/components/common/Widget';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { Icon } from 'app/components/common/Icon';
import { Button } from 'app/components/common/Button';
import { Modal } from 'app/components/Modal';
import { TempAgenturForm } from '../temp/TempAgenturForm';

export const AgenturTempInfoWidget = observer((props: IWidgetConsumer) => {
	const { agenturUiStore, uiStore, session } = useStore();
	const agt = agenturUiStore.current;
	const user = session.currentUser!;

	const openTempAgtModal = () => {
		uiStore.showModal(uiStore.modalIds.editTempAgentur);
	};

	const closeTempAgtMode = () => {
		uiStore.hideModal(uiStore.modalIds.editTempAgentur);
	};

	return (
		<Widget title={'Temporäre Agentur'} widgetId={props.widgetKey} disableCollapse={true}>
			{agt && agt.shouldBeTemp && (
				<>
					<div className="grid is-col-auto align-center">
						<div>
							<Icon iconClass={'drafting-compass'} faModifier={'fal'} />
							<strong> {agt.name}</strong>
						</div>
						<div className="is-right">
							{user.isVbl && (
								<Button className="button is-primary is-small" onClick={openTempAgtModal}>
									Bearbeiten
								</Button>
							)}
						</div>
					</div>
					<Modal modalId={uiStore.modalIds.editTempAgentur} title={'Temporäre Agentur bearbeiten'} size="large" modifier="is-paddingless">
						<TempAgenturForm onClose={closeTempAgtMode} tempAgt={agt} />
					</Modal>
				</>
			)}
		</Widget>
	);
});
