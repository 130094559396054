import { Einheit } from 'app/models/core/einheit.model';
import { BnrGpPosModel } from '../bnr.gp.pos.model';

const posDef: BnrGpPosModel[] = [];

posDef.push(new BnrGpPosModel({ year: 2024, posId: 2225001, group: 'vss', posText: 'Weiterbildungspfl. BNR', planKey: 'idd', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2024, posId: 2225002, group: 'vss', posText: 'Weiterbildungsz. Fonds', planKey: 'weiterbildungfonds', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2024, posId: 2225021, group: 'vss', posText: 'Leben Ris. Bew Digi', planKey: 'lebenrisbewoze', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2024, posId: 2225031, group: 'vss', posText: 'NMZ o. P EUR', planKey: 'nettomittelzufluss', einheit: Einheit.bew }));

posDef.push(new BnrGpPosModel({ year: 2024, posId: 2225041, group: 'eh', posText: 'Leben Bewo. ZE', planKey: 'wb_lebenbewoze90', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2024, posId: 2225042, group: 'eh', posText: 'Leben Bewo. ZE 100%', planKey: 'wb_lebenbewoze100', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2024, posId: 2225044, group: 'eh', posText: 'Fonds Bew+FRVZ 100%', planKey: 'fondsbewfrz100', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2024, posId: 2225043, group: 'eh', posText: 'Fonds Bew+FRVZ 90%', planKey: 'fondsbewfrz90', einheit: Einheit.bew }));

posDef.push(new BnrGpPosModel({ year: 2024, posId: 2225101, group: 'neh', posText: 'Rente/Leben Bew Digi', planKey: 'rentelebenbewdigi', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2024, posId: 2225111, group: 'neh', posText: 'Leben EB Digi', planKey: 'lebenebdigi', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2024, posId: 2225121, group: 'neh', posText: 'Leben Firmen Bew', planKey: 'lebenfirmendigi', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2024, posId: 2225131, group: 'neh', posText: 'Fonds Bewertung', planKey: 'fondsbew', einheit: Einheit.bew }));

posDef.push(new BnrGpPosModel({ year: 2024, posId: 3825021, group: 'gpplus', posText: 'Wiederanlage', planKey: 'wb_wiederanlage', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2024, posId: 3825031, group: 'gpplus', posText: 'Firmen Fonds Bew', planKey: 'firmenfondsbew', einheit: Einheit.bew }));

export const VasPositionen = posDef;
