import { humanizeFileSize } from 'app/utils';
import { ButtonHTMLAttributes } from 'react';

interface IFileTypeIcon extends ButtonHTMLAttributes<HTMLButtonElement> {
	fileSize: number;
}
export const FileSize = (props: IFileTypeIcon) => {
	const size = humanizeFileSize(props.fileSize);
	return <span className="file-size">{size}</span>;
};
