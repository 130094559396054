import BaseContainer from 'app/containers/BaseContainer';
import { observer } from 'mobx-react';
import { MainMenu } from './MainMenu';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useStore } from 'app/context';
import { useEffect } from 'react';
import { AdminBnrBlacklistList } from 'app/components/admin/bnrblacklist/AdminBnrBlacklistList';
import { AdminNewBlacklistButton } from 'app/components/admin/bnrblacklist/AdminNewBlacklistButton';
import { AdminBnrBlacklistDetail } from 'app/components/admin/bnrblacklist/AdminBnrBlacklistDetail';
import { EnsureAdmin } from './AdminHomeContainer';

export interface AdminTemplatesParmTypes {
	type?: string;
	id?: string;
}

const BlacklistActions = () => {
	return (
		<>
			<AdminNewBlacklistButton />
		</>
	);
};

const AdminBnrBlacklistContainer = observer((props: RouteComponentProps) => {
	const { session, adminBnrBlacklistUiStore } = useStore();

	const currentUser = session.currentUser;

	const { type, id } = useParams<AdminTemplatesParmTypes>();

	useEffect(() => {
		if (type === 'new') {
			adminBnrBlacklistUiStore.createNew();
		}
	}, [adminBnrBlacklistUiStore, id, type]);

	const BlacklistNav = () => {
		return (
			<div className="admin-navbar">
				<div className="is-left">
					<strong>Bnr Blacklist</strong>
				</div>
				<div className="is-right">
					<BlacklistActions />
				</div>
			</div>
		);
	};

	return (
		<BaseContainer classModifier="admin-container" sideMenu={<MainMenu {...props} />} navbar={<BlacklistNav />}>
			{currentUser && (
				<>
					<EnsureAdmin />
					<AdminBnrBlacklistList />
					<AdminBnrBlacklistDetail />
				</>
			)}
		</BaseContainer>
	);
});

export default AdminBnrBlacklistContainer;
