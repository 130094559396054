import { useStore } from 'app/context';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { ConfirmModal } from '../common/ConfirmModal';
import { DocumentSendModal } from '../documents/DocumentSendModal';
import { Modal } from '../Modal';
import { TaskViewer } from './TaskViewer';

interface IDocumentViewerModal {
	showAgt: boolean;
	showPlan: boolean;
}
export const TaskViewerModal = observer((props: IDocumentViewerModal) => {
	const { uiStore, taskUiStore } = useStore();
	const modalId = uiStore.modalIds.taskViewModal;
	const onClose = () => {
		runInAction(() => {
			taskUiStore.setCurrentModalTask(undefined);
			uiStore.hideModal(modalId);
		});
	};

	const onDelete = async () => {
		await taskUiStore.deleteById(taskUiStore.currentModalTask!.id);
	};

	return (
		<>
			{taskUiStore.currentModalTask && (
				<Modal size="large" modalId={modalId} title={taskUiStore.currentModalTask.task.text} onClose={onClose}>
					<TaskViewer task={taskUiStore.currentModalTask} showAgt={props.showAgt} showPlan={props.showPlan} />
					<ConfirmModal onConfirm={onDelete} onCancel={() => {}} modalId={uiStore.modalIds.confirmDeleteTask}>
						Sind Sie sicher, dass Sie diese Maßnahme löschen wollen? Dies kann nicht rückgängig gemacht werden.
					</ConfirmModal>
					<DocumentSendModal task={taskUiStore.currentModalTask} modalId={uiStore.modalIds.taskSendModal} />
				</Modal>
			)}
		</>
	);
});
