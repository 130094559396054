import { BnrZielModel } from './bnr.ziel.store';
import { BaseCachedEntityStore } from './core/base.cached.entity.store';

export class ZweitverkaueferZielStore extends BaseCachedEntityStore<BnrZielModel> {
	apiPath = 'api/mitarbeiter/posziel';
	idProp: string = 'id';
	disableFindForStearts = [123];

	datenStand: Date = new Date();
	createInstances(opts: any): BnrZielModel {
		return new BnrZielModel(opts);
	}
}
