import { computed } from 'mobx';

export class CoronaKVModel {
	constructor(opts: any) {
		// makeObservable(this);
		this.id = opts.id;
		this.agtId = opts.agtId;
		this.bnrId = opts.bnrId;
		this.state = opts.state;
		this.key = opts.key;
		this.groupId = opts.groupId;
		if (opts.comment) {
			this.comment = opts.comment;
		}
		this.completedUntil = opts.completedUntil;
	}
	id: number;
	agtId: number;
	bnrId: number;
	state?: 'done' | 'ignore' | 'unknown' | 'inprogress';
	groupId: string;
	key: string;
	comment: string = '';
	completedUntil?: Date;

	@computed
	get notStarted() {
		return !this.state || this.state === 'unknown';
	}

	@computed
	get inProgress() {
		return this.state === 'inprogress';
	}

	@computed
	get completed() {
		return this.state === 'done' || this.state === 'ignore';
	}
}
