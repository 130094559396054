export class PosVal {
	constructor(bt?: number, st?: number) {
		if (bt) {
			this.bt = bt;
		}
		if (st) {
			this.st = st;
		}
	}
	bt: number = 0;
	st: number = 0;
}
