import { useStore } from 'app/context';
import { ErrungenschaftModel } from 'app/models/errungenshaften/errungenschaften.model';
import { LeadNowCheckVM } from 'app/models/leadnowcheck/leadnow.check.vm';
import { AgtErrungenschaftenVM } from 'app/stores/ui/agt.errungenschaften.ui.store';
import { observer } from 'mobx-react';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import routes from 'routes';
import { Button } from '../common/Button';
import { LeadNowErrungenschaft } from '../anwendungen/leadnow/LeadNowIcon';
import { Modal } from '../Modal';
import { IAgtErrungenschaftYesNoGSB } from './BnrErrungenschaften';
import { ErrungenschaftGSB } from './ErrungenschaftGSB';
import { ErrungenschaftYesNo } from './ErrungenschaftYesNo';
import { ErrungenschaftIconType } from './ErrungenshaftIcon';

export const AgtErrungenschaftJPG = observer((props: IAgtErrungenschaftYesNoGSB) => {
	return <ErrungenschaftYesNo {...props} icon={ErrungenschaftIconType.jpgCreated} />;
});
export const AgtErrungenschaftAP = observer((props: IAgtErrungenschaftYesNoGSB) => {
	return <ErrungenschaftYesNo {...props} icon={ErrungenschaftIconType.planungstool} />;
});
export const AgtErrungenschaftBestandswachstum = observer((props: IAgtErrungenschaftYesNoGSB) => {
	return <ErrungenschaftYesNo {...props} icon={ErrungenschaftIconType.bestandswachstum} />;
});
export const AgtErrungenschaftDigiFaktor = observer((props: IAgtErrungenschaftYesNoGSB) => {
	return <ErrungenschaftYesNo {...props} icon={ErrungenschaftIconType.digiFaktor} />;
});
export const AgtErrungenschaftIDD = observer((props: IAgtErrungenschaftYesNoGSB) => {
	return <ErrungenschaftYesNo {...props} icon={ErrungenschaftIconType.weiterbildung} />;
});
export const AgtErrungenschaftGesamtbewertung = observer((props: IAgtErrungenschaftYesNoGSB) => {
	return <ErrungenschaftYesNo {...props} icon={ErrungenschaftIconType.bewertungsziel} />;
});

export const AgtErrungeschaftBreitenmass = observer((props: IAgtErrungenschaftYesNoGSB) => {
	return <ErrungenschaftYesNo {...props} icon={ErrungenschaftIconType.breitenmass} />;
});

export const AkumaPrimeErrungenschaftIcon = observer((props: IAgtErrungenschaftYesNoGSB) => {
	return <ErrungenschaftYesNo {...props} icon={ErrungenschaftIconType.akumaprime} />;
});

export const AkumaPlanerErrungenschaftIcon = observer((props: IAgtErrungenschaftYesNoGSB) => {
	return <ErrungenschaftGSB {...props} icon={ErrungenschaftIconType.akumaplaner} />;
});

export const MazErrungenschaftenIcon = observer((props: IAgtErrungenschaftYesNoGSB) => {
	return <ErrungenschaftYesNo {...props} icon={ErrungenschaftIconType.maz} />;
});
export const WeweErrungenschaftenIcon = observer((props: IAgtErrungenschaftYesNoGSB) => {
	return <ErrungenschaftYesNo {...props} icon={ErrungenschaftIconType.wewe} />;
});
export const DigiidErrungenschaftenIcon = observer((props: IAgtErrungenschaftYesNoGSB) => {
	return <ErrungenschaftYesNo {...props} icon={ErrungenschaftIconType.digid} />;
});

export const KwkErrungenschaftenIcon = observer((props: IAgtErrungenschaftYesNoGSB) => {
	return <ErrungenschaftYesNo {...props} icon={ErrungenschaftIconType.kwk} />;
});

export const AgtErrungenschaftRing = observer((props: IAgtErrungenschaftYesNoGSB) => {
	return <ErrungenschaftGSB {...props} icon={ErrungenschaftIconType.ring} />;
});

interface IAgenturErrungenschaften {
	model: AgtErrungenschaftenVM;
}

export const AgenturErrungenschaftenGrid = observer((props: IAgenturErrungenschaften) => {
	const { uiStore, agenturUiStore } = useStore();
	const history = useHistory();
	const agtId = agenturUiStore.currentAgtId;

	const onOpen = (e: ErrungenschaftModel) => {
		setModalModel(e);
		uiStore.showModal(uiStore.modalIds.errungenschaftMiniModal);
	};

	const [modalModel, setModalModel] = useState<ErrungenschaftModel>();

	const onNavigate = () => {
		uiStore.hideModal(uiStore.modalIds.errungenschaftMiniModal);
		if (modalModel && modalModel.pathToReport) {
			history.push('/agentur/' + agtId + '/' + modalModel.pathToReport);
		}
	};

	return (
		<>
			<div className="achievements-grid">
				<>
					{props.model.sortedByAchieved.map((e) => (
						<Errungenschaft key={e.key} model={e} handleClick={onOpen} />
					))}
				</>
				<AgtErrungenschaftLeadNow />
			</div>

			{modalModel && (
				<Modal title={modalModel.modal.title} size="small" modalId={uiStore.modalIds.errungenschaftMiniModal}>
					<p dangerouslySetInnerHTML={{ __html: modalModel.modal.description }}></p>

					{modalModel.pathToReport && (
						<div className="modal-footer">
							<Button className="button is-primary" onClick={onNavigate}>
								Zum Bericht
							</Button>
						</div>
					)}
				</Modal>
			)}
		</>
	);
});

export interface IErrungenschaft {
	model: ErrungenschaftModel;
	handleClick: (e: ErrungenschaftModel) => void;
}

export const Errungenschaft = observer((props: IErrungenschaft) => {
	const e = props.model;
	const { session } = useStore();

	if (props.model.visibleForRoles && !props.model.visibleForRoles.includes(session.currentUser!.steart.id)) {
		return null;
	}

	return (
		<>
			<Button className="button is-blank" key={e.key} onClick={() => props.handleClick(e)}>
				{e.component}
			</Button>
		</>
	);
});

export const AgenturErrungenschaften = observer(() => {
	const { agenturUiStore, agtErrungenschaftenUiStore } = useStore();
	const [vm, setVm] = useState<AgtErrungenschaftenVM>();

	useEffect(() => {
		if (agenturUiStore.currentAgtId) {
			agtErrungenschaftenUiStore.findByAgtId(agenturUiStore.currentAgtId).then((res) => {
				setVm(res);
			});
		}
	}, [agtErrungenschaftenUiStore, agenturUiStore.currentAgtId]);

	return <>{vm && <AgenturErrungenschaftenGrid model={vm} />}</>;
});

export const AgtErrungenschaftLeadNow = observer(() => {
	const { agenturUiStore, agtLeadNowCheckUiStore } = useStore();
	const [leadNow, setLeadNow] = useState<LeadNowCheckVM>();
	const history = useHistory();

	useEffect(() => {
		if (agenturUiStore.currentAgtId) {
			agtLeadNowCheckUiStore.findByAgtId(agenturUiStore.currentAgtId).then((res) => {
				setLeadNow(res);
			});
		}
	}, [agtLeadNowCheckUiStore, agenturUiStore.currentAgtId]);

	const openLeadNowPanel = () => {
		history.push(routes.AGENTUR.getPath(agenturUiStore.currentAgtId!.toString(), 'anwendungen', 'checklisten', 'leadnow'));
	};

	return (
		<>
			{leadNow && leadNow.overallState !== 'rejected' && (
				<Button className="button is-blank" onClick={openLeadNowPanel}>
					{/* for the widget, if the user hasn't rejected we want to show an icon */}
					<div className={`achievements-icon ${leadNow.empfehlung === 'none' ? 'is-fail' : ''}`}>
						<div className="icons">
							<div className="icon">
								<LeadNowErrungenschaft iconType={leadNow.iconType || 'rejected'} iconColor={leadNow.iconColor} />
							</div>
						</div>
						<div className="title">Lead.NOW</div>
					</div>
				</Button>
			)}
		</>
	);
});
