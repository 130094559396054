import { Stearts } from 'app/models/core/steart.model';
import { observer } from 'mobx-react';

interface ISteartTag {
	id: number;
	name?: string;
	modifier?: string;
}

export const SteartTag = observer((props: ISteartTag) => {
	const stId = Stearts.find((st) => st.id === props.id);

	if (!stId) {
		return null;
	}

	if (stId.name === 'BFG' || stId.name === 'SPS' || stId.name === 'LSV') {
		console.warn(`[SteartTag] Illegal Steart ${stId.name}`);
	}

	const getClassFromId = () => {
		return stId ? `color-${stId.name}` : '';
	};

	const getNameFromId = () => {
		return stId ? stId.name : '';
	};

	return <span className={`tag ${getClassFromId()} ${props.modifier || ''}`}>{props.name ? props.name : getNameFromId()}</span>;
});
