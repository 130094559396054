import { useStore } from 'app/context';
import { AgenturExtendedVM } from 'app/stores/ui/agt.list.ui.store';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import routes from 'routes';
import { Icon } from '../common/Icon';

interface IAgenturMap {
	mapRef: React.RefObject<HTMLDivElement>;
	onSelectAgt: (agt: AgenturExtendedVM | undefined) => void;
}

export const AgenturMapLegend = observer(() => {
	const { agenturMapsUiStore, uiStore } = useStore();

	return (
		<>
			{uiStore.showMap && agenturMapsUiStore.currentLegend && (
				<>
					{/* {agenturMapsUiStore.mapableItemsWithoutCoordinates.length} without coordinates */}
					<div className="map-legend">
						{agenturMapsUiStore.currentLegend.legendDescription.map((desc) => {
							return (
								<div className="map-legend-item" key={desc.value}>
									<span>{desc.label}</span> <div dangerouslySetInnerHTML={{ __html: desc.value as string }}></div>
								</div>
							);
						})}
					</div>
				</>
			)}
		</>
	);
});

export const AgenturMap = observer((props: IAgenturMap) => {
	const { agenturMapsUiStore } = useStore();

	const [map, setMap] = useState<google.maps.Map>();
	useEffect(() => {
		if (!map) {
			return;
		}
		agenturMapsUiStore.getMarkers(map, props.onSelectAgt);
		agenturMapsUiStore.drawLayer(map);
	}, [agenturMapsUiStore, agenturMapsUiStore.currentLegend, agenturMapsUiStore.filteredMapItems, map, props.onSelectAgt]);

	useEffect(() => {
		if (props.mapRef.current && agenturMapsUiStore.mapsStore.mapsLib && !map) {
			const mapSettings = agenturMapsUiStore.mapsStore.getMapSettings();
			const m = new agenturMapsUiStore.mapsStore.mapsLib!.Map(props.mapRef.current, {
				center: { lat: mapSettings.lat, lng: mapSettings.lng },
				zoom: mapSettings.zoom,
				mapId: mapSettings.mapId,
			});
			m.addListener('dragend', () => {
				agenturMapsUiStore.mapsStore.saveMapSettings(m);
			});
			setMap(m);
		}
		if (!map) {
			return;
		}
	}, [agenturMapsUiStore.filteredMapItems, agenturMapsUiStore.mapsStore, agenturMapsUiStore.mapsStore.mapsLib, map, props.mapRef]);

	return (
		<>
			<AgenturMapLegend />
			<AgtGroupInfowWindowContent />
		</>
	);
});

export const AgtGroupInfowWindowContent = observer(() => {
	const { agenturMapsUiStore } = useStore();
	const location = useLocation();

	const getUrl = (agt: AgenturExtendedVM) => {
		return '#' + routes.AGENTUR.getPath(agt.agtId.toString()) + location.search;
	};

	const [group, setGroup] = useState(agenturMapsUiStore.activeGroup);

	useEffect(() => {
		setGroup(agenturMapsUiStore.activeGroup);
	}, [agenturMapsUiStore.activeGroup]);

	return (
		<div id="infowindowcontent">
			{group && (
				<>
					<div className="maps-agt-group">
						{group.agts.length > 1 ? <span className="title is-5">Agenturen an diesem Standort</span> : <span className="title is-5">Agentur</span>}
						{group.agts.map((agt) => {
							const idx = agenturMapsUiStore.currentLegend.getIndex(agt);
							const color = agenturMapsUiStore.currentLegend.getColorByIndex(idx);

							return (
								<div className="maps-agt-group-item" key={`map-${agt.agtId}`}>
									{color && (
										<span style={{ color: color }}>
											<Icon iconClass="circle" />{' '}
										</span>
									)}
									<a className="button is-text" href={getUrl(agt)}>
										{agt.name} ({agt.agtId})
									</a>
								</div>
							);
						})}
					</div>
				</>
			)}
		</div>
	);
});
