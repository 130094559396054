const values = [
	{
		key: 'idd',
		title: 'IDD',
		children: [
			{
				key: 'lernkartenidd',
				title: 'Lernkarten IDD',
			},
			{
				key: 'lernkartenmab',
				title: 'Lernkarten MAB',
			},
			{
				key: 'weiterbildungnachhalten',
				title: 'Weiterbildung nachhalten',
			},
			{
				key: 'lernkartenprivatschutz',
				title: 'Lernkarten Privatschutz 2.0',
			},
			{
				key: 'idd_ils',
				title: 'ILS',
			},
		],
	},
	{
		key: 'abp',
		title: 'Meine Allianz Beratung',
		children: [
			{
				key: 'selbstverpflichtungeinsatz',
				title: 'Selbstverpflichtung zum Einsatz',
			},
			{
				key: 'verkaufsbegleitung',
				title: 'Verkaufsbegleitung',
			},
			{
				key: 'schulungmab',
				title: 'Schulung MAB (Übungszirkel)',
			},
			{
				key: 'vertreterschulen',
				title: 'Vertreter schulen Vertreter (Modellagentur)',
			},
			{
				key: 'schwerpunktagentur',
				title: 'Schwerpunktagentur',
			},
			{
				key: 'interaktionzweitverkaeufer',
				title: 'Interaktion / Coaching Zweitverkäufer',
			},
			{
				key: 'kundenstatus',
				title: 'Kundenstatus für jeden Kunden',
			},
			{
				key: 'jpg',
				title: 'Durchführung Jahresgespräch (Privat & Firmen)',
			},
		],
	},

	{
		key: 'rechtundgesetz',
		title: 'Recht und Gesetz',
		children: [
			{
				key: 'bedeutungwewe',
				title: 'Bedeutung und Notwendigkeit wEWE',
			},
			{
				key: 'schulungwewe',
				title: 'Schulung zu Einholungswege wEWE',
			},
			{
				key: 'agtprozesseinholencheck',
				title: 'Agenturprozesse zur Einholung überprüfen',
			},
			{
				key: 'dsgvo',
				title: 'Berücksichtigung EU-DSGVO (Einholung dEWE) ',
			},
		],
	},

	{
		key: 'onlineberatung',
		title: 'Online Beratung',
		children: [
			{
				key: 'webexlizenz',
				title: 'WebEx-Lizenz',
			},
			{
				key: 'startekursgtm',
				title: 'Zora 1a - Starterkurs WebEx',
			},
			{
				key: 'da20',
				title: 'Digitale Agentur 2.0',
			},
			{
				key: 'uebungszirkel',
				title: 'Übungszirkel',
			},
			{
				key: 'verterterschulungverterter',
				title: 'Vertreter schulen Vertreter',
			},
			{
				key: 'aktivesangebtoamkundenberatung',
				title: 'Aktives Angebot an Kunden (Online Beratung) ',
			},
			{
				key: 'aktivesangebtoamkundenterminierung',
				title: 'Aktives Angebot an Kunden (Online Terminierung) ',
			},
		],
		links: [
			{
				title: 'ONEPAGER WEBEX',
				pdf: 'dva_onepager/dva_webex.pdf',
			},
			{
				title: 'ONEPAGER Online Terminierung',
				pdf: 'dva_onepager/dva_onlineterminierung.pdf',
			},
		],
	},

	{
		key: 'beratungstechnik',
		title: 'Beratungstechnik',
		children: [
			{
				key: 'agenturausstattung',
				title: 'Agenturausstattung',
			},
			{
				key: 'erlernungknowhow',
				title: 'Aufbau von Know-How',
			},
			{
				key: 'aaaseminare',
				title: 'AAA-Seminare/Webinare',
			},
			{
				key: 'amisboxenstop',
				title: 'AMIS Boxenstopp',
			},
			{
				key: 'fit4amis',
				title: 'Fit4AMIS',
			},
		],
	},

	{
		key: 'aktionenonline',
		title: 'Aktionen Online',
		children: [
			{
				key: 'downloadnachamis',
				title: 'Download in AMIS',
			},
			{
				key: 'festlegungderzustaendigkeiten',
				title: 'Festlegung der Zuständigkeiten',
			},
			{
				key: 'einstellungvonaufgaben',
				title: 'Einstellen von Aufgaben',
			},
			{
				key: 'nachhaltenderbearbeitung',
				title: 'Nachhalten der Bearbeitung',
			},
		],
	},

	{
		key: 'spezialisteneinsatz',
		title: 'Spezialisteneinsatz',
		children: [
			{
				key: 'tarifschulungen',
				title: 'Tarifschulungen',
			},
			{
				key: 'themenschulungen',
				title: 'Themenschulungen',
			},
			{
				key: 'verstarktenutzungdse',
				title: 'Verstärkte Nutzung DSE',
			},
			{
				key: 'interktionmitdemzweitverkauefer',
				title: 'Interaktion mit Zweitverkäufer',
			},
		],
	},

	{
		key: 'beratungsstandards',
		title: 'Beratungsstandards',
		children: [
			{
				key: 'kundenversprechen',
				title: 'Kundenversprechen',
			},
			{
				key: 'ganzheitlicheberatung',
				title: 'Ganzheitliche Beratung',
			},
			{
				key: 'definierenundleben',
				title: 'Definieren und Leben',
			},
			{
				key: 'verlaesslichkeit',
				title: 'Verlässlichkeit',
			},
			{
				key: 'engagement',
				title: 'Engagement',
			},
			{
				key: 'adressatengrechtesprache',
				title: 'Adressatengerechte Sprache',
			},
			{
				key: 'angebotvirtuelleberatung',
				title: 'Angebot virtueller Beratung',
			},
			{
				key: 'angeboteazapps',
				title: 'Aktives Angebot der AZ-APPs (Rechnungen APKV, etc.)',
			},
		],
	},

	{
		key: 'vornachbereitung',
		title: 'Vor-/Nachbereitung',
		children: [
			{
				key: 'kdvpflege',
				title: 'KDV-Pflege',
			},
			{
				key: 'kontaktnachabschluss',
				title: 'Kontakt nach Abschluss',
			},
			{
				key: 'reaktionszeiten',
				title: 'Reaktionszeiten',
			},
			{
				key: 'zeitmanagement',
				title: 'Zeitmanagement',
			},
			{
				key: 'routenplanung',
				title: 'Routenplanung',
			},
			{
				key: 'unterstuetzungimschadensundleistungsfall',
				title: 'Unterstützung im Schadens- und Leistungsfall',
			},
		],
	},
];
export default values;
