import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { Button } from '../common/Button';
import { BnrErrungenschaften, BnrErrungenschaftenOverviewModal } from './BnrErrungenschaften';
import Widget, { IWidgetConsumer } from '../common/Widget';

const OpenModalButton = () => {
	const { uiStore } = useStore();

	const onOpen = () => {
		uiStore.showModal(uiStore.modalIds.bnrErrungenschaften);
	};

	return (
		<Button className="button is-widget-header" onClick={onOpen}>
			Anzeigen
		</Button>
	);
};

export const BnrErrungenschaftenWidget = observer((props: IWidgetConsumer) => {
	return (
		<>
			<Widget title={'Errungenschaften'} widgetId={props.widgetKey} action={<OpenModalButton />}>
				<BnrErrungenschaften />
			</Widget>
			<BnrErrungenschaftenOverviewModal />
		</>
	);
});
