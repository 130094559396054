import { fileInformationFromType } from 'app/utils';
import { ButtonHTMLAttributes } from 'react';
import { Icon } from './Icon';

interface IFileTypeIcon extends ButtonHTMLAttributes<HTMLButtonElement> {
	mime: string;
}
export const FileTypeIcon = (props: IFileTypeIcon) => {
	const f = fileInformationFromType(props.mime);
	return (
		<div className={`filetype-icon ${f.color}`}>
			<Icon iconClass={f.icon} />
		</div>
	);
};
