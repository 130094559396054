import { makeObservable, observable } from 'mobx';
import moment from 'moment';

export class KvBnrModel {
	constructor(opts: any) {
		makeObservable(this);
		if (!opts) {
			opts = {};
		}

		this.id = opts.id;
		this.bnrId = opts.bnrId;
		this.group = opts.group;
		this.key = opts.key;
		this.key2 = opts.key2;
		if (opts.valueNum) {
			this.valueNum = opts.valueNum;
		}

		if (opts.valueString) {
			this.valueString = opts.valueString;
		}
	}

	bnrId: number;
	group: string;
	id: string;
	key: string;
	key2: string;

	@observable
	valueNum: number = 0;

	@observable
	valueString: string = '';

	static getString = (items: KvBnrModel[], key: string): string | undefined => {
		const item = items.find((i) => i.key === key);
		if (item) {
			return item.valueString;
		}
	};
	static getNumber = (items: KvBnrModel[], key: string): number | undefined => {
		const item = items.find((i) => i.key === key);
		if (item) {
			return item.valueNum;
		}
	};
	static getDate = (items: KvBnrModel[], key: string): Date | undefined => {
		const item = items.find((i) => i.key === key);
		if (item && item.valueNum) {
			return moment(item.valueNum).toDate();
		}
	};
}
