import { useState } from 'react';

import FroalaEditor from 'react-froala-wysiwyg';

// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

import 'froala-editor/js/plugins/word_paste.min.js';
import 'froala-editor/js/languages/de.js';
import 'froala-editor/js/plugins/table.min.js';
import 'froala-editor/js/plugins/emoticons.min.js';
import 'froala-editor/js/plugins/font_size.min.js';
import 'froala-editor/js/plugins/colors.min.js';

import { useField, useFormikContext } from 'formik';
import React from 'react';

export const TextEditorField = ({ ...props }) => {
	const { setFieldValue } = useFormikContext();
	const [field] = useField(props as any);
	const [model] = useState<string>(field.value as string);

	const onChange = (val: string) => {
		setFieldValue(props.name, val);
	};

	return (
		<>
			<TextEditor text={model} onModelChange={onChange} />
		</>
	);
};

interface IProps {
	text: string;
	onModelChange: (val: string) => void;
}
interface IState {
	text: string;
}

// froala does not like hooks
export class TextEditor extends React.Component<IProps, IState> {
	public state: IState = {
		text: this.props.text,
	};

	handleModelChange(model: string) {
		this.setState({
			text: model,
		});
		this.props.onModelChange(model);
	}

	render() {
		const config = {
			language: 'de',
			//toolbarButtons: ['bold', 'italic', 'underline', 'strikeThrough', 'inserTable', '|', 'paragraphFormat', 'align', 'undo', 'redo', 'html'],
			toolbarButtons: [
				['bold', 'italic', 'underline', 'strikeThrough'],
				['fontFamily', 'fontSize', 'textColor', 'backgroundColor'],
				['insertTable'],
				// ['insertTable', 'emoticons'],
				['inlineClass', 'inlineStyle', 'clearFormatting'],
				['undo', 'redo'],
			],
			tableResizerOffset: 10,
			tableResizingLimit: 50,
			// wordDeniedTags: ['img'],
			// imagePaste: false,
			wordPasteModal: true,
			attribution: false,
			key: 're1H1qC4C3B5D6D5C5hBi1a2d2Za1IXBh1f1THTBPLIIWBORpF1F1E1F4F1C11A7C2E5D3==',
		};
		return <FroalaEditor config={config} model={this.state.text} onModelChange={this.handleModelChange.bind(this)} />;
	}
}
