import { SteartModel, UserStearts } from 'app/models/core/steart.model';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import sanitizeHtml from 'sanitize-html';
import { BnrTemplateModel, BnrTemplateStore } from '../bnr.template.store';

interface ITemplateVM {
	template: BnrTemplateModel;
}

export class TemplateSteart {
	constructor(steart: SteartModel, active: boolean) {
		this.steart = steart;
		this.active = active;
	}
	steart: SteartModel;
	active: boolean;
}

export class TemplateVM {
	constructor(opts: ITemplateVM) {
		makeObservable(this);
		this.template = opts.template;
	}

	@computed
	get id() {
		return this.template.id;
	}

	@computed
	get title() {
		return this.template.content.title;
	}

	@computed
	get sortString() {
		let s = '';
		if (this.template.content.category === 'ap') {
			s = '0';
		}
		if (this.template.content.category === 'kbv') {
			s = '1';
		}
		s = s + this.title;
		return s;
	}

	@observable
	template: BnrTemplateModel;

	@computed
	get canEdit() {
		return this.template.bnrId > 0 && this.template.content.deletable !== false && this.template.content.category !== 'kbvr';
	}

	@computed
	get canDelete() {
		return this.template.content.deletable !== false;
	}

	@computed
	get isInitialTemplate() {
		const cat = this.template.content.category;
		return (cat === 'notiz' || cat === 'an' || cat === 'ev') && this.template.content.deletable === false;
	}

	@computed
	get activeStearts() {
		return this.stearts.filter((s) => s.active);
	}

	@computed
	get stearts() {
		const ts = this.template.stearts;
		return UserStearts.map((s: SteartModel) => {
			const active = ts.find((i) => i === s!.steart);
			return new TemplateSteart(s!, !!active);
		});
	}

	@computed
	get isNew() {
		return !this.id;
	}
}

const bnrUploadTemplate = new BnrTemplateModel({
	contentJson: JSON.stringify({
		title: 'Dateiupload',
		text: '',
		category: 'upload',
		visible: false,
		deletable: false,
	}),
});
export const UploadTemplate = new TemplateVM({
	template: bnrUploadTemplate,
});
const bnrPlanungsToolTemplate = new BnrTemplateModel({
	id: 'planungstool',
	contentJson: JSON.stringify({
		title: 'Planungstool',
		text: '',
		category: 'ap',
		visible: true,
		deletable: false,
	}),
});

const bnrGpTemplate = new BnrTemplateModel({
	id: 'gp',
	contentJson: JSON.stringify({
		title: 'Geschäftsplan',
		text: '',
		category: 'bnrgp',
		visible: false,
		deletable: true,
	}),
});

export const PlanungsToolTemplate = new TemplateVM({
	template: bnrPlanungsToolTemplate,
});

export const BnrGpTemplate = new TemplateVM({
	template: bnrGpTemplate,
});

export class TemplateUiStore {
	bnrTemplateStore: BnrTemplateStore;

	constructor(bnrTemplateStore: BnrTemplateStore) {
		makeObservable(this);
		this.bnrTemplateStore = bnrTemplateStore;
	}

	@action
	setCurrent(t?: TemplateVM) {
		this.current = t;
	}

	@observable
	current?: TemplateVM;

	@computed
	get currentId() {
		return this.current ? this.current.id : '';
	}

	loaded: boolean = false;
	@observable
	items: TemplateVM[] = [];

	async load() {
		if (this.loaded) {
			return this.items;
		}

		const fetch = await Promise.all([this.bnrTemplateStore.findAll()]);

		const templates = fetch[0];

		let res: TemplateVM[] = [];

		templates.forEach((d) => {
			const vm = new TemplateVM({ template: d });
			if (d.content.category === 'kbvr') {
				d.disabled = true;
			}
			res.push(vm);
		});
		res.push(PlanungsToolTemplate);
		res.push(BnrGpTemplate);
		const user = this.bnrTemplateStore.session.currentUser!;

		if (!user.isVbl) {
			res = res.filter((t) => t.template.content.category !== 'kbvr' && t.id !== 'planungstool');
		}

		runInAction(() => {
			this.items = res;
			this.loaded = true;
		});

		return res;
	}

	async findAll() {
		return this.load();
	}

	async findByCategoryName(cat: string) {
		const items = await this.load();
		const i = items.find((i) => i.template.content.category === cat);
		if (i) {
			return i.title;
		} else {
			return cat;
		}
	}

	newTemplate() {
		const t = new BnrTemplateModel({
			bnrId: this.bnrTemplateStore.session.currentUser!.bnrId,
		});
		t.content.title = 'Neue Vorlage';
		t.content.text = '';
		const vm = new TemplateVM({ template: t });
		return vm;
	}

	async deleteTemplate(template: TemplateVM) {
		await this.bnrTemplateStore.deleteById(template.id);
		runInAction(() => {
			this.items = this.items.filter((i) => i.id !== template.id);
		});
	}

	async saveTemplate(template: TemplateVM) {
		template.template.bnrId = this.bnrTemplateStore.session.currentUser!.bnrId;
		template.template.content.text = sanitizeHtml(template.template.content.text);
		const t = await this.bnrTemplateStore.save(template.template);
		const vm = new TemplateVM({ template: t });
		return vm;
	}
}
