import kontakt2018 from './2018/bt-kontakt';
import beratung2018 from './2018/bt-beratung';
import nachhaltigkeit2018 from './2018/bt-nachhaltigkeit';
import kontakt2019 from './2019/bt-kontakt';
import beratung2019 from './2019/bt-beratung';
import nachhaltigkeit2019 from './2019/bt-nachhaltigkeit';
import kontakt2020 from './2020/bt-kontakt';
import beratung2020 from './2020/bt-beratung';
import nachhaltigkeit2020 from './2020/bt-nachhaltigkeit';
import kontakt2021 from './2021/bt-kontakt';
import beratung2021 from './2021/bt-beratung';
import nachhaltigkeit2021 from './2021/bt-nachhaltigkeit';

import kontakt2022 from './2022/bt-kontakt';
import beratung2022 from './2022/bt-beratung';
import nachhaltigkeit2022 from './2022/bt-nachhaltigkeit';

import kontakt2023 from './2023/bt-kontakt';
import beratung2023 from './2023/bt-beratung';
import nachhaltigkeit2023 from './2023/bt-nachhaltigkeit';

import kontakt2024 from './2024/bt-kontakt';
import beratung2024 from './2024/bt-beratung';
import nachhaltigkeit2024 from './2024/bt-nachhaltigkeit';

import { action, computed, IObservableArray, makeObservable, observable } from 'mobx';

export class HandlunsgsempfehlungLink {
	constructor(opts: any) {
		this.key = opts.key;
		this.pdf = opts.pdf;
	}
	key: string;
	pdf: string;
}

export class HandlunsgsempfehlungModel {
	constructor(opts: any) {
		makeObservable(this);
		this.key = opts.key;
		this.title = opts.title;
	}
	key: string;
	title: string;

	@observable
	highlight: boolean = false;
	@action
	search(s: string) {
		this.highlight = s !== '' && this.title.toLowerCase().indexOf(s) >= 0;
	}
}

export class ThemaModel {
	constructor(opts: any) {
		makeObservable(this);
		this.key = opts.key;
		this.title = opts.title;
		const children = opts.children.map((i: any) => new HandlunsgsempfehlungModel(i));
		this.children.replace(children);
		if (opts.links) {
			const links = opts.links.map((i: any) => new HandlunsgsempfehlungLink(i));
			this.links.replace(links);
		}
		if (opts.showMulti) {
			this.showMulti = true;
		}
	}
	key: string;
	title: string;
	children: IObservableArray<HandlunsgsempfehlungModel> = observable([]);
	links: IObservableArray<HandlunsgsempfehlungLink> = observable([]);

	showMulti: boolean = false;

	@observable
	selected: boolean = false;

	@observable
	isEigenesThema: boolean = false;
	@observable
	highlight: boolean = false;
	@action
	search(s: string) {
		this.highlight = s !== '' && this.title.toLowerCase().indexOf(s) >= 0;
	}
}

export class ThemenGroupModel {
	constructor(opts: any) {
		makeObservable(this);

		this.key = opts.key;
		this.title = opts.title;
		this.year = opts.year;
		const children = opts.children.map((i: any) => new ThemaModel(i));
		this.children.replace(children);
	}
	key: string;
	title: string;
	year: number;
	children: IObservableArray<ThemaModel> = observable([]);

	@computed
	get isEigene() {
		return this.key === 'eigene';
	}

	@observable
	highlight: boolean = false;
	@action
	search(s: string) {
		this.highlight = s !== '' && this.title.toLowerCase().indexOf(s) >= 0;
	}
}

interface IThemenGroup {
	key: string;
	title: string;
	year: number;
	children: any;
}

export const BrandenBurgerTorData: IThemenGroup[] = [
	{
		key: 'kontakt',
		title: 'Kontakt',
		children: kontakt2018,
		year: 2018,
	},
	{
		key: 'beratung',
		title: 'Beratung',
		children: beratung2018,
		year: 2018,
	},
	{
		key: 'nachhaltikeit',
		title: 'Nachhaltigkeit',
		children: nachhaltigkeit2018,
		year: 2018,
	},
	{
		key: 'kontakt',
		title: 'Kontakt',
		children: kontakt2019,
		year: 2019,
	},
	{
		key: 'beratung',
		title: 'Beratung',
		children: beratung2019,
		year: 2019,
	},
	{
		key: 'nachhaltikeit',
		title: 'Nachhaltigkeit',
		children: nachhaltigkeit2019,
		year: 2019,
	},
	{
		key: 'kontakt',
		title: 'Kontakt',
		children: kontakt2020,
		year: 2020,
	},
	{
		key: 'beratung',
		title: 'Beratung',
		children: beratung2020,
		year: 2020,
	},
	{
		key: 'nachhaltikeit',
		title: 'Nachhaltigkeit',
		children: nachhaltigkeit2020,
		year: 2020,
	},
	{
		key: 'kontakt',
		title: 'Kontakt',
		children: kontakt2021,
		year: 2021,
	},
	{
		key: 'beratung',
		title: 'Beratung',
		children: beratung2021,
		year: 2021,
	},
	{
		key: 'nachhaltikeit',
		title: 'Nachhaltigkeit',
		children: nachhaltigkeit2021,
		year: 2021,
	},
	{
		key: 'kontakt',
		title: 'Kontakt',
		children: kontakt2022,
		year: 2022,
	},
	{
		key: 'beratung',
		title: 'Beratung',
		children: beratung2022,
		year: 2022,
	},
	{
		key: 'nachhaltikeit',
		title: 'Nachhaltigkeit',
		children: nachhaltigkeit2022,
		year: 2022,
	},

	{
		key: 'kontakt',
		title: 'Kontakt',
		children: kontakt2023,
		year: 2023,
	},
	{
		key: 'beratung',
		title: 'Beratung',
		children: beratung2023,
		year: 2023,
	},
	{
		key: 'nachhaltikeit',
		title: 'Nachhaltigkeit',
		children: nachhaltigkeit2023,
		year: 2023,
	},

	{
		key: 'kontakt',
		title: 'Kontakt',
		children: kontakt2024,
		year: 2024,
	},
	{
		key: 'beratung',
		title: 'Beratung',
		children: beratung2024,
		year: 2024,
	},
	{
		key: 'nachhaltikeit',
		title: 'Nachhaltigkeit',
		children: nachhaltigkeit2024,
		year: 2024,
	},
];
