import { TableModel, ITableColumn, AggregrationType } from 'app/components/table/table.model';
import { formatNum } from 'app/utils';
import { Table } from 'app/components/table/table';
import { useStore } from 'app/context';
import * as Yup from 'yup';
import { observer } from 'mobx-react';
import React, { useMemo, useEffect, useState } from 'react';
import { AgtSchwerpunktVM } from 'app/stores/ui/agt.schwerpunkt.ui.store';
import { AgenturNameCell } from 'app/components/shared/AgenturName.cell';

export const schwerpunktSchema = Yup.object().shape({
	schwerpunktEDP: Yup.boolean(),
	schwerpunktEDT: Yup.boolean(),
	schwerpunktBBS: Yup.boolean(),
	schwerpunktKME: Yup.boolean(),
	schwerpunktKVS: Yup.boolean(),
	schwerpunktUB: Yup.boolean(),
	schwerpunktVAS: Yup.boolean(),
});

export const formatBool = (val: any) => {
	return <input type="checkbox" checked={val} disabled={true} />;
};

export const SchwerpunktList = observer(() => {
	const { agtSchwerpunktUiStore, anwendungenUiStore, agenturListUiStore, session } = useStore();
	const [loaded, setLoadad] = useState<boolean>(false);

	const year = anwendungenUiStore.currentSchwerpunktYear;

	const tm = useMemo(() => {
		const tm = new TableModel<AgtSchwerpunktVM, number>();

		const roles = agtSchwerpunktUiStore.getRolesByYear(anwendungenUiStore.currentSchwerpunktYear);

		const cols: ITableColumn<AgtSchwerpunktVM, number>[] = [
			{
				label: 'Agentur',
				path: 'agt.name',
				sortBy: 'agt.name',
				render: AgenturNameCell,
			},
		];
		let aggIf = (val: any) => {
			return val === true;
		};

		const editableRole = (r: string) => {
			if (session.currentUser!.isMulti) {
				if (['EDT', 'EDP'].includes(r)) {
					return true;
				}
				return false;
			} else {
				if (!['EDT', 'EDP'].includes(r)) {
					return true;
				}
				return false;
			}
		};

		roles.forEach((r) => {
			const path = 'schwerpunkt' + r;
			cols.push({
				label: r,
				path,
				format: formatBool,
				editable: editableRole(r),
				editType: 'checkbox',
				agg: anwendungenUiStore.selectedAgt
					? []
					: [
							{
								path,
								format: formatNum,
								aggType: AggregrationType.count,
								aggIf,
							},
					  ],
			});
		});

		tm.sortBy = 'agt.name';
		tm.setCols(cols);
		tm.idProperty = 'agtId';
		tm.idType = 'number';
		return tm;
	}, [agtSchwerpunktUiStore, anwendungenUiStore.currentSchwerpunktYear, anwendungenUiStore.selectedAgt]);

	useEffect(() => {
		agtSchwerpunktUiStore.findByYear(year).then((res) => {
			res = agenturListUiStore.applyFilterOnAgt(res) as AgtSchwerpunktVM[];
			if (anwendungenUiStore.selectedAgt) {
				const agtId = anwendungenUiStore.selectedAgt.agtId;
				res = res.filter((d) => d.agtId === agtId);
			}
			tm.setRowData(res);
			setLoadad(true);
		});
	}, [tm, year, setLoadad, agtSchwerpunktUiStore, anwendungenUiStore.selectedAgt, agenturListUiStore.filterHasChanged, agenturListUiStore]);

	const onSave = async () => {
		await agtSchwerpunktUiStore.saveMany(tm.data);
	};

	return (
		<>
			{loaded && (
				<>
					<Table stickyFooter={true} tm={tm} schema={schwerpunktSchema} onSubmit={onSave} />
				</>
			)}
		</>
	);
});
