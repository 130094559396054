import BaseContainer from 'app/containers/BaseContainer';
import { observer } from 'mobx-react';
import { MainMenu } from './MainMenu';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useStore } from 'app/context';
import { useEffect } from 'react';
import { AdminTemplatesList } from 'app/components/admin/templates/AdminTemplatesList';
import { AdminNewTemplateButtons } from 'app/components/admin/templates/AdminNewTemplateButton';
import { AdminTemplateDetail } from 'app/components/admin/templates/AdminTemplateDetail';
import { EnsureAdmin } from './AdminHomeContainer';

export interface AdminTemplatesParmTypes {
	type?: string;
	id?: string;
}

const TemplatesActions = () => {
	return (
		<>
			<AdminNewTemplateButtons />
		</>
	);
};

const AdminTemplatesContainer = observer((props: RouteComponentProps) => {
	const { session, adminTemplatesUiStore } = useStore();

	const currentUser = session.currentUser;

	const { type, id } = useParams<AdminTemplatesParmTypes>();

	useEffect(() => {
		if (type === 'edit') {
			adminTemplatesUiStore.loadById(id);
		}
		if (type === 'new') {
			if (id === '0') {
				adminTemplatesUiStore.createNewTemplate();
			}
		}
	}, [adminTemplatesUiStore, id, type]);

	const TemplatesNavbar = () => {
		return (
			<div className="admin-navbar">
				<div className="is-left">
					<strong>Vorlagen</strong>
				</div>
				<div className="is-right">
					<TemplatesActions />
				</div>
			</div>
		);
	};

	return (
		<BaseContainer classModifier="admin-container" sideMenu={<MainMenu {...props} />} navbar={<TemplatesNavbar />}>
			{currentUser && (
				<>
					<EnsureAdmin />
					<AdminTemplatesList />
					<AdminTemplateDetail />
				</>
			)}
		</BaseContainer>
	);
});

export default AdminTemplatesContainer;
