import _ from 'lodash';
import { computed, IObservableArray, makeObservable, observable, runInAction } from 'mobx';
import { NewsModel, NewsStore } from '../news.store';
import moment, { Moment } from 'moment';

export class NewsVM {
	news: NewsModel;
	constructor(opts: any) {
		makeObservable(this);
		this.news = opts;
	}

	@computed
	get id() {
		return this.news.id;
	}

	isPublishedAfter(date: Date | Moment) {
		return moment(date).isAfter(this.news.publishDate);
	}

	isPublishedBefore(date: Date | Moment) {
		return moment(date).isBefore(this.news.publishDate);
	}

	get textTrunc() {
		if (this.news.text.length < 20) {
			return this.news.text;
		}
		return this.news.text.substring(0, 19);

	}


}

export class NewsUiStore {
	newsStore: NewsStore;

	constructor(newsStore: NewsStore) {
		makeObservable(this);
		this.newsStore = newsStore;
	}

	//@observable current?: NewsVM;

	@computed
	get currentKwNews() {
		const now = moment();
		return this._items.filter((x) => x.isPublishedAfter(now));
	}

	@computed
	get currentItems() {
		const now = moment();
		return this._items.filter((x) => x.isPublishedAfter(now));
	}

	@computed
	get last3Items() {
		const items = this.currentItems;

		if (!items) {
			return [];
		}
		if (items.length < 3) {
			return items;
		}
		return items.slice(0, 3);
	}





	_items: IObservableArray<NewsVM> = observable([]);
	loaded: boolean = false;
	async load() {
		if (this.loaded) {
			return this._items;
		}

		const items = await this.newsStore.findAll();
		const res: NewsVM[] = [];

		items.forEach((t) => {
			const vm = new NewsVM(t);
			res.push(vm);
		});
		runInAction(() => {
			this._items.replace(res);
			this.loaded = true;
		});

		return this._items;
	}

	async findAll() {
		return this.load();
	}
}
