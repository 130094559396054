import { AgenturModel } from 'app/models/agentur.model';
import { KvAgtMsModel } from 'app/models/kv.agt.ms.model';
import _ from 'lodash';
import { computed } from 'mobx';
import { AgenturStore } from '../agentur.store';
import { KvAgtMsClusterStore } from '../kv.agt.cluster.store';

export class AgtClusterVM {
	constructor(agt: AgenturModel, items: KvAgtMsModel[]) {
		this.agt = agt;
		if (items.length === 0) {
			this.bm = 0;
			this.agtId = 0;
			this.bnrId = 0;
			return;
		}
		this.bm = items[0].bm;
		this.agtId = items[0].agtId;
		this.bnrId = items[0].bnrId;

		this.betreuungUb = KvAgtMsModel.getNumber(items, 'UB');
		this.betreuungBbs = KvAgtMsModel.getNumber(items, 'BBS');
		this.betreuungKme = KvAgtMsModel.getNumber(items, 'KME');
		this.betreuungKvs = KvAgtMsModel.getNumber(items, 'KVS');
		this.betreuungVas = KvAgtMsModel.getNumber(items, 'VAS');
		this.betreuungBsg = KvAgtMsModel.getNumber(items, 'BSG');
		this.betreuungVbl = KvAgtMsModel.getNumber(items, 'VBL');

		const cols = ['betreuungUb', 'betreuungBbs', 'betreuungKme', 'betreuungKvs', 'betreuungVas', 'betreuungBsg', 'betreuungVbl'];

		let sum = 0;
		let count = 0;
		cols.forEach((c) => {
			const val = _.get(this, c);
			if (val && val > 0) {
				sum += val;
				count++;
			}
		});
		const avg = sum / count;
		this.avg = Math.round(avg * 10) / 10;
	}

	bm: number;
	agtId: number;
	agt: AgenturModel;
	bnrId: number;
	betreuungUb?: number;
	betreuungBbs?: number;
	betreuungKme?: number;
	betreuungKvs?: number;
	betreuungVas?: number;
	betreuungBsg?: number;
	betreuungVbl?: number;

	avg?: number;
}

interface IClusterCol {
	name: string;
	sort: number;
	path: string;
}

export class AgtClusterUiStore {
	kvAgtMsClusterStore: KvAgtMsClusterStore;
	agenturStore: AgenturStore;

	constructor(agenturStore: AgenturStore, kvAgtMsClusterStore: KvAgtMsClusterStore) {
		this.kvAgtMsClusterStore = kvAgtMsClusterStore;
		this.agenturStore = agenturStore;
	}

	@computed
	get steartCols() {
		let cols: IClusterCol[] = [
			{
				name: 'UB',
				sort: 10,
				path: 'betreuungUb',
			},
			{
				name: 'BBS',
				sort: 7,
				path: 'betreuungBbs',
			},
			{
				name: 'KME',
				sort: 6,
				path: 'betreuungKme',
			},
			{
				name: 'KVS',
				sort: 5,
				path: 'betreuungKvs',
			},
			{
				name: 'VAS',
				sort: 4,
				path: 'betreuungVas',
			},
			{
				name: 'BSG',
				sort: 3,
				path: 'betreuungBsg',
			},
			{
				name: 'VBL',
				sort: 1,
				path: 'betreuungVbl',
			},
		];

		for (const col of cols) {
			if (col.name === this.agenturStore.session.currentSteart!.name) {
				col.sort = 0;
			}
		}

		return _.sortBy(cols, 'sort');
	}

	onLogout() {
		this.kvAgtMsClusterStore.onLogout();
		this.agenturStore.onLogout();
	}

	async filterByAgtId(agtId: number) {
		await this.load();
		return this.items.filter((i) => i.agtId === agtId);
	}

	async filterByAgtIdAndYear(agtId: number, year: number) {
		await this.load();
		return this.items.filter((i) => i.agtId === agtId && i.bm === year);
	}

	items: AgtClusterVM[] = [];
	years: number[] = [];

	datenStand?: number; // bm

	loadingPromise?: Promise<any>;
	loadingPromiseResolver?: any;

	async findAllByYear(year: number) {
		await this.load();
		return this.items.filter((i) => i.bm === year);
	}

	async load() {
		if (this.loadingPromise) {
			await this.loadingPromise;
			return;
		}

		this.loadingPromise = new Promise((resolve) => {
			this.loadingPromiseResolver = resolve;
		});
		const data = await this.kvAgtMsClusterStore.findAll();
		const agts = await this.agenturStore.findAll();

		this.years = _.uniq(_.map(data, 'bm')).sort();
		const groupedByAgt = _.chain(data)
			.groupBy('agtId')
			.map((value, key) => {
				return value;
			})
			.value();

		this.items = [];

		groupedByAgt.forEach((items) => {
			const agt = agts.find((a) => a.agtId === items[0].agtId);
			if (!agt) {
				// console.warn('agt.digifaktorui.store.load() could not find agt with id' + items[0].agtId);
				return;
			}
			const byBM = _.chain(items)
				.groupBy('bm')
				.map((value, key) => {
					return value;
				})
				.value();
			const bms = byBM.map((bmItems) => new AgtClusterVM(agt!, bmItems));
			this.items = this.items.concat(bms);
		});
		const max = _.maxBy(data, 'bm');
		if (max) {
			this.datenStand = max.bm;
		}
		if (this.loadingPromiseResolver) {
			this.loadingPromiseResolver();
		}
	}
}
