import { Einheit } from 'app/models/core/einheit.model';
import { BnrGpPosModel } from '../bnr.gp.pos.model';
import { PosUnDef } from '../gp';
const posDef: BnrGpPosModel[] = [];



posDef.push(
	new BnrGpPosModel({
		year: 2024,
		posId: PosUnDef,
		group: 'ausMaxi',
		posText: 'Unterstützung bei der Einführung der neuen Tools im Rahmen des Rollouts von AMIS.Now',
		planKey: 'amisnow',
		einheit: Einheit.quali,
		posDesc: `Befähigung von Vertretungen und Vertriebseinheiten im Verantwortungsbereich. Unterstützung bei der Einrichtung vor Ort bei Bedarf.`,
	}),
);
posDef.push(
	new BnrGpPosModel({
		year: 2024,
		posId: PosUnDef,
		group: 'ausMaxi',
		posText: 'Einsatz digitaler Instrumente',
		planKey: 'digi_instr',
		einheit: Einheit.quali,
		posDesc: `Einsatz digitaler Instrumente
		Befähigung von Vertretungen und Vertriebseinheiten
		im Verantwortungsbereich. Ausbau der Nutzung von digitalen Anwendungen, die von der Gesellschaft unterstützt und freigegeben sind, entlang der strategischen Ziele der Gesellschaft. `
		,
	}),
);
posDef.push(
	new BnrGpPosModel({
		year: 2024,
		posId: PosUnDef,
		group: 'ausMaxi',
		posText: 'Agenturprozesse und Agenturadministration',
		planKey: 'agtprozessundadmin',
		einheit: Einheit.quali,
		posDesc: `Multiplikator:in und Ansprechpartner:in im Verant-
		wortungsbereich für die Gestaltung effizienter Agenturabläufe und -prozesse. Verantwortung für die Etablierung von neuen Anwendungen durch gezielte Einsteuerung von Neuerungen und Best-Practice.`,
	}),
);

posDef.push(
	new BnrGpPosModel({
		year: 2024,
		posId: 2310100,
		group: 'max8k',
		posText: 'Neue Digital ID im Bestand (E-Mailadressen und Mobilnummern)',
		planKey: 'neuedigiid',
		einheit: Einheit.stck,
		posDesc: `Umwandlung Kund:innen ohne Digi ID in 	 
		Kund:innen mit Digi ID im Bestand. 	 
		Basis Jahresanfangsbestand 2024	 `,
	}),
);


posDef.push(
	new BnrGpPosModel({
		year: 2024,
		posId: 2310105,
		group: 'max8k',
		posText: 'Neuanmeldungen Meine Allianz',
		planKey: 'neumaz',
		einheit: Einheit.stck,
		posDesc: `
		Steigerung der Durchdringung von Kund:innen mit Meine Allianz (mAZ) im Bestand.
		Basis: 01.01.2024	 
		`,
	}),
);

posDef.push(
	new BnrGpPosModel({
		year: 2024,
		posId: 2310125,
		group: 'max8k',
		posText: 'Steigerung der Cross-Selling-Quote und Kundenbindung ',
		planKey: 'crosselling',
		einheit: Einheit.proz,
		posDesc: `Anzahl GV/HV mit wiederkehrender AKUMA Nutzung
		in der VD plus Anzahl GV/HV an AKUMA.Prime
		(Wiederkehrende AKUMA Planer Nutzung von 
		mindestens 6 Wochen im Jahr 2024)
		Stichtag der Bemessungsgrundlage für das 
		Jahresziel ist der GV/HV Bestand zum 01.01.2024
		`,
	}),
);


posDef.push(
	new BnrGpPosModel({
		year: 2024,
		posId: PosUnDef,
		group: 'max8k',
		posText: 'Hardwareadministration im Verantwortungsbereich',
		planKey: 'hardwardin',
		einheit: Einheit.quali,
		posDesc: `
		Nachhaltiges und wirtschaftliches Handeln bei 
		administrativen Tätigkeiten im Bereich Hardware
		im Verantwortungsbereich. Planung und Durchführung von praktikablen Arbeitsmustern,
		insbesondere bei der Bestellung und dem
		Abbau von Hardware.
	
		`,
	}),
);

posDef.push(
	new BnrGpPosModel({
		year: 2024,
		posId: 2310140,
		group: 'max8k',
		posText: 'Durchführung, Vor- und Nachbereitung von Schulungsmaßnahmen',
		planKey: 'schulung',
		einheit: Einheit.stck,
		posDesc: `Anzahl gehaltener Schulungen über das AAA-
		Portal, die der EDP als Referent:in verantwortet`,
	}),
);

posDef.push(
	new BnrGpPosModel({
		year: 2024,
		posId: PosUnDef,
		group: 'max8k',
		posText: 'Bewerbung der web-DSE in der jeweiligen VD',
		planKey: 'webdese',
		einheit: Einheit.quali,
		posDesc: ` Bekanntmachung der Themen- und Aufgabenschwerpunkte der web-DSE, Forcierung und 
		Förderung der direkten Nutzung
		`,
	}),
);

posDef.push(
	new BnrGpPosModel({
		year: 2024,
		posId: PosUnDef,
		group: 'max8k',
		posText: 'Datenmigration in der Umstellung auf AMIS.NOW',
		planKey: 'datemigration',
		einheit: Einheit.quali,
		posDesc: ` Befähigung von Vertretungen und Vertriebseinheiten im Verantwortungsbereich in der Umsetzung der durchzuführenden Maßnahmen vor der 
		Datenmigration für die in 2024 identifizierten Nutzergruppe.		
		`,
	}),
);


/////////////////////////////////////////



export const EdpPositionen = posDef;
