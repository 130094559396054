import { makeObservable, observable } from 'mobx';
import _ from 'lodash';
import { SessionStore } from './session.store';
import { KvAgtModel } from 'app/models/kv.agt.model';
import { KvAgtBaseStore } from './kv.agt.base.store';

export class DigitalreportModel {
	constructor(items: KvAgtModel[]) {
		makeObservable(this);
		if (items.length === 0) {
			this.agtId = 0;
			this.bnrId = 0;
			return;
		}
		this.agtId = items[0].agtId;
		this.bnrId = items[0].bnrId;

		this.anzKunden = KvAgtModel.getNumberDef(items, 'anzKunden');
		this.prozDigiId = KvAgtModel.getProzDef(items, 'prozDigiId');
		this.prozMaz = KvAgtModel.getProzDef(items, 'prozMaz');
		this.prozWewe = KvAgtModel.getProzDef(items, 'prozWewe');

		this.anzKunden18 = KvAgtModel.getNumberDef(items, 'anzKunden18');
		this.prozDigiId18 = KvAgtModel.getProzDef(items, 'prozDigiId18');
		this.prozMaz18 = KvAgtModel.getProzDef(items, 'prozMaz18');
		this.prozWewe18 = KvAgtModel.getProzDef(items, 'prozWewe18');

		this.anzKundenNeu = KvAgtModel.getNumberDef(items, 'anzKundenNeu');
		this.prozDigiIdNeu = KvAgtModel.getProzDef(items, 'prozDigiIdNeu');
		this.prozMazNeu = KvAgtModel.getProzDef(items, 'prozMazNeu');
		this.prozWeweNeu = KvAgtModel.getProzDef(items, 'prozWeweNeu');
	}

	agtId: number;
	bnrId: number;

	@observable anzKunden: number = 0;
	@observable prozDigiId: number = 0;
	@observable prozMaz: number = 0;
	@observable prozWewe: number = 0;

	@observable anzKunden18: number = 0;
	@observable prozDigiId18: number = 0;
	@observable prozMaz18: number = 0;
	@observable prozWewe18: number = 0;

	@observable anzKundenNeu: number = 0;
	@observable prozDigiIdNeu: number = 0;
	@observable prozMazNeu: number = 0;
	@observable prozWeweNeu: number = 0;
}

export class KvAgtDigitalreportStore {
	kvAgtStore: KvAgtBaseStore;

	constructor(session: SessionStore) {
		this.kvAgtStore = new KvAgtBaseStore(session);
		this.kvAgtStore.apiPath = 'api/kv_agt/digitalreport';
	}

	findByAgtId(agtId: number) {
		return this.items.find((i) => i.agtId === agtId);
	}

	items: DigitalreportModel[] = [];

	async load() {
		const data = await this.kvAgtStore.findAll();
		const grouped = _.chain(data)
			.groupBy('agtId')
			.map((value, key) => {
				return value;
			})
			.value();

		this.items = grouped.map((items) => new DigitalreportModel(items));
		return this.items;
	}
}
