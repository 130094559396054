import { Steart } from 'app/models/core/steart.model';
import { ZeWidgetDef, ZeWidgetItem } from './vbl.ze.widgets';

export const GslZeWidgets: ZeWidgetDef[] = [
	new ZeWidgetDef({
		title: 'Positionen GSW',
		key: 'xxx',
		stearts: [Steart.GSL],
		items: [
			new ZeWidgetItem({
				text: 'x',
				posId: -1,
				einheit: '%',
			}),
		],
		// rptPath: `/berichte/production/gp/${moment().year()}/overview`,
	}),
];
