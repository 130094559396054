import { IWidgetOpts, KvWidgetSettingModel } from 'app/stores/kv.bnr.widget.settings.store';
import { Steart } from '../core/steart.model';

export enum WidgetCol {
	left = 0,
	right = 1,
}

export enum WidgetScope {
	home = 'home',
	agt = 'agt',
	ada = 'ada',
	agtplanung = 'agtplanung',
	team = 'team',
}

export interface IWidget {
	scope: WidgetScope;
	widgetKey: string;
	enabled: boolean;
	opts: IWidgetOpts;
	component: JSX.Element;
	stearts?: Steart[];
	hideFor?: (opts: any) => boolean;
}

export class WidgetSettingModel extends KvWidgetSettingModel {
	constructor(widget: IWidget) {
		super({
			key: widget.scope,
			key2: widget.widgetKey,
			valueNum: widget.enabled ? 1 : 0,
			valueString: JSON.stringify(widget.opts),
		});
		this.component = widget.component;
		this.stearts = widget.stearts;
		this.hideFor = widget.hideFor;
	}
	component: JSX.Element;
	stearts?: Steart[];
	hideFor?: (opts: any) => boolean;
}
