import { ErrungenschaftModel, ErrungenschaftType, IErrungenschaft } from './errungenschaften.model';
import {
	AkumaPrimeErrungenschaftIcon,
	AgtErrungenschaftAP,
	AgtErrungenschaftBestandswachstum,
	AgtErrungenschaftDigiFaktor,
	AgtErrungenschaftGesamtbewertung,
	AgtErrungenschaftIDD,
	AgtErrungenschaftJPG,
	AgtErrungenschaftRing,
	AgtErrungeschaftBreitenmass,
	DigiidErrungenschaftenIcon,
	KwkErrungenschaftenIcon,
	MazErrungenschaftenIcon,
	WeweErrungenschaftenIcon,
	AkumaPlanerErrungenschaftIcon,
} from 'app/components/errungenschaften/AgenturErrungenschaften';
import { GPMAXYEAR } from 'app/stores/ui/berichte.ui.store';
import { LeiterSpeziSteartIds, MultiSteartIds, SpeziSteartIds, Steart } from '../core/steart.model';

const agtErrungenschaften: IErrungenschaft[] = [
	{
		key: 'agt.jpg',
		component: (m: ErrungenschaftModel) => <AgtErrungenschaftJPG {...m} />,
		valType: ErrungenschaftType.boolean,
		visibleForRoles: [Steart.VBL, ...MultiSteartIds],
		pathToReport: 'planung',
		modal: {
			title: 'JPG',
			description: 'Jahresplanungsgespräch mit Agentur geführt',
		},
	},
	{
		key: 'agt.ap',
		component: (m: ErrungenschaftModel) => <AgtErrungenschaftAP {...m} />,
		valType: ErrungenschaftType.boolean,
		visibleForRoles: [Steart.VBL],
		pathToReport: 'planung',
		modal: {
			title: 'Planungstool',
			description: 'Plantungstool mit Agentur befüllt',
		},
	},
	{
		key: 'agt.bestandswachstum',
		component: (m: ErrungenschaftModel) => <AgtErrungenschaftBestandswachstum {...m} />,
		valType: ErrungenschaftType.boolean,
		visibleForRoles: [Steart.VBL, ...SpeziSteartIds, ...LeiterSpeziSteartIds],
		pathToReport: 'berichte/bestand/analyse/agtneugesch5jahre',
		modal: {
			title: 'Bestand',
			description: 'Bestandswachstum der Agentur für das aktuelle Planjahr größer 3%',
		},
	},

	// {
	// 	key: 'agt.digifaktor',
	// 	component: (m: ErrungenschaftModel) => <AgtErrungenschaftDigiFaktor {...m} />,
	// 	valType: ErrungenschaftType.boolean,
	// 	// visibleForRoles = all
	// 	pathToReport: 'berichte/kunde/digi/DF',
	// 	modal: {
	// 		title: 'Digifaktor',
	// 		description: 'Agentur hat einen Digifaktor von mindestens 100 Punkten',
	// 	},
	// },
	{
		key: 'agt.ring',
		component: (m: ErrungenschaftModel) => <AgtErrungenschaftRing {...m} />,
		valType: ErrungenschaftType.gsb,
		visibleForRoles: [Steart.VBL, ...SpeziSteartIds, ...LeiterSpeziSteartIds],
		gsbLevels: [50, 20, 10],
		pathToReport: `berichte/production/ring/${GPMAXYEAR}/overview`,
		modal: {
			title: 'Ring',
			description: 'Zielerreichung im Wettbewerb',
		},
	},
	{
		key: 'agt.idd',
		component: (m: ErrungenschaftModel) => <AgtErrungenschaftIDD {...m} />,
		valType: ErrungenschaftType.boolean,
		// visibleForRoles = all
		pathToReport: `berichte/production/agtpos/${GPMAXYEAR}/kunde/idd`,
		modal: {
			title: 'IDD',
			description: 'Weiterbildungsaufwand von mind. 15 Stunden im aktuellen Planjahr',
		},
	},
	{
		key: 'agt.gesamtbewertung',
		component: (m: ErrungenschaftModel) => <AgtErrungenschaftGesamtbewertung {...m} />,
		valType: ErrungenschaftType.boolean,
		// visibleForRoles = all
		pathToReport: `berichte/production/gp/${GPMAXYEAR}/overview`,
		modal: {
			title: 'Bewertungsziel',
			description: 'Fall 1: Ist-Ges.Bew. > Ges.Bew. Branchenplanung <br/>Fall 2 : <br/>a.) Nicht Pro3: GP-EH erreicht <br/>b.) Pro3: Aktuelle AP-Stufe erreicht',
		},
	},
	{
		key: 'agt.produktivitaet',
		component: (m: ErrungenschaftModel) => <AgtErrungeschaftBreitenmass {...m} />,
		valType: ErrungenschaftType.boolean,
		// visibleForRoles = all
		pathToReport: `berichte/production/gp/${GPMAXYEAR}/prod-zwischenstand`,
		modal: {
			title: 'Breitenmaß',
			description: 'Produktivitätsziel des Breitenmaßes erreicht',
		},
	},
	{
		key: 'agt.akumaprime',
		component: (m: ErrungenschaftModel) => <AkumaPrimeErrungenschaftIcon {...m} />,
		valType: ErrungenschaftType.boolean,
		// visibleForRoles = all
		pathToReport: `berichte/kunde/akumaprime/uebersicht`,
		modal: {
			title: 'Akuma Prime',
			description: 'Teilnahme Akuma Prime',
		},
	},
	{
		key: 'agt.akumaplaner',
		component: (m: ErrungenschaftModel) => <AkumaPlanerErrungenschaftIcon {...m} />,
		valType: ErrungenschaftType.gsb,
		gsbLevels: [24, 12, 6],
		pathToReport: `berichte/kunde/akumaplaner/uebersicht`,
		modal: {
			title: 'Akuma Planer',
			description: 'Einteilung Kunden in Wochenscheiben<br/>Gold: 24 Wochen<br />Silber: 12 Wochen<br />Bronze: 6 Wochen',
		},
	},

	{
		key: 'agt.maz',
		component: (m: ErrungenschaftModel) => <MazErrungenschaftenIcon {...m} />,
		valType: ErrungenschaftType.boolean,
		// visibleForRoles = all
		pathToReport: `berichte/bestand/digitalreport/`,
		modal: {
			title: 'MAZ',
			description: 'Agentur hat im Bestand mAZ > 60%',
		},
	},
	{
		key: 'agt.wewe',
		component: (m: ErrungenschaftModel) => <WeweErrungenschaftenIcon {...m} />,
		valType: ErrungenschaftType.boolean,
		// visibleForRoles = all
		pathToReport: `berichte/bestand/digitalreport/`,
		modal: {
			title: 'wEWE',
			description: 'Agentur hat im Bestand wEWE > 70%',
		},
	},
	{
		key: 'agt.digiid',
		component: (m: ErrungenschaftModel) => <DigiidErrungenschaftenIcon {...m} />,
		valType: ErrungenschaftType.boolean,
		// visibleForRoles = all
		pathToReport: `berichte/bestand/digitalreport/`,
		modal: {
			title: 'Digi-ID',
			description: 'Agentur hat im Neugeschäft Digital ID > 95%',
		},
	},
	{
		key: 'agt.kwk',
		component: (m: ErrungenschaftModel) => <KwkErrungenschaftenIcon {...m} />,
		valType: ErrungenschaftType.boolean,
		// pathToReport: `berichte/bestand/digitalreport/`,
		modal: {
			title: 'Allianz Empfehlen',
			description: 'Kunden werben Kunden ',
		},
	},

	// NEW:
	// AKUMA
	// visibleForRoles = all

	// Voicegate
	// visibleForRoles: [Steart.VBL, ...MultiSteartIds],

	// freie Errungenschaft
	// visibleForRoles = all

	// BAQ
	// visibleForRoles: [Steart.VBL, Steart.LDP],
];

export const AgtErrungenschaftenDefinition = agtErrungenschaften;
