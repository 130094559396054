import { useFormikContext } from 'formik';
import { isArray } from 'lodash';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import Select, { MultiValue, SingleValue } from 'react-select';

export interface ISelectOpt {
	value: any;
	label: string;
}
interface ISelectBaseControl {
	value: any;
	opts: ISelectOpt[];
	label?: string;
	id?: string;
	emptyText?: string;
	name: string;
	onChange: (name: string, value: any) => void;
	modifier?: string;
	isMulti?: boolean;
	isClearable?: boolean;
}

export const SelectInput = observer((props: ISelectBaseControl) => {
	const [hasValue, setHasValue] = useState<boolean>(false);
	const emptyText = props.emptyText || 'Keine Auswahl';
	const isClearable = props.isClearable === undefined ? true : props.isClearable;
	const [selected, setSelected] = useState<ISelectOpt[] | ISelectOpt>([]);

	useEffect(() => {
		const res: ISelectOpt[] = [];
		let hasVal = false;

		if (props.value) {
			if (isArray(props.value)) {
				hasVal = props.value.length > 0;
				props.value.forEach((v: any) => {
					const o = props.opts.find((o) => o.value === v);
					if (o) {
						res.push(o);
					}
				});
			} else {
				const o = props.opts.find((o) => o.value === props.value);
				if (o) {
					hasVal = true;
					setSelected(o);
					setHasValue(hasVal);
					return;
				}
			}
		}
		setSelected(res);
		setHasValue(hasVal);
	}, [props.value, props.opts]);

	const onChange = (option: SingleValue<ISelectOpt> | MultiValue<ISelectOpt> | null) => {
		props.onChange(props.name, option);
	};

	const id = props.id ? props.id : props.name;

	return (
		<>
			<div className={`form-control ${props.modifier ? props.modifier : ''}`}>
				{props.label && <label htmlFor={id}>{props.label || props.name}</label>}
				<Select
					value={selected}
					onChange={onChange}
					id={id}
					name={props.name}
					options={props.opts}
					placeholder={emptyText}
					isClearable={isClearable}
					hideSelectedOptions={false}
					// menuIsOpen={props.isMulti}
					className={`react-select-container ${hasValue ? 'has-value' : ''}`}
					classNamePrefix="react-select"
					isMulti={props.isMulti}
				/>
			</div>
		</>
	);
});

interface ISelectFieldControl {
	label?: string;
	id?: string;
	name: string;
	opts: ISelectOpt[];
	onChange?: (name: string, value: any) => void;
}

export const SelectField = observer((props: ISelectFieldControl) => {
	const { setFieldValue, getFieldMeta } = useFormikContext();
	const [value, setValue] = useState(getFieldMeta(props.name).value);

	const onChange = (name: string, value: any) => {
		if (props.onChange) {
			props.onChange(name, value);
		}
		setFieldValue(props.name, value.value);
		setValue(value.value);
	};

	return <SelectInput {...props} name={props.name} onChange={onChange} value={value} isClearable={false} />;
});
