import React from 'react';
import { CheckboxControl } from 'app/components/common/CheckboxControl';
import { InputControl } from 'app/components/common/InputControl';
import { ISelectOpt } from 'app/components/common/Select';
import { useStore } from 'app/context';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';

export const AdminBnrTransferablesFilter = observer(() => {
	const { adminBnrTransferUiStore } = useStore();

	const onTextChange = (name: string, val: string) => {
		runInAction(() => {
			adminBnrTransferUiStore.fullText = val;
		});
	};

	const toggleTwoOnThree = (name: string, val: boolean) => {
		runInAction(() => {
			adminBnrTransferUiStore.hideAutomatedTransfer = val;
		});
	};

	return (
		<div className="admin-users-filter pad-1rem grid is-col-half">
			<div className="left grid">
				<div className="text-input">
					<InputControl
						inline={true}
						label="Filter"
						name="fullText"
						value={adminBnrTransferUiStore.fullText}
						onChange={onTextChange}
						debounceTime={500}
						placeholder={'Textsuche: Name, Bensl, BnrId'}
					/>
				</div>
			</div>
			<div className="right grid">
				<div className="text-input">
					<CheckboxControl
						label="Automatisierte Übernahmen ausblenden"
						name="twoOnThre"
						value={adminBnrTransferUiStore.hideAutomatedTransfer}
						onChange={toggleTwoOnThree}
					/>
				</div>
			</div>

			<div className="active-filters">
				{adminBnrTransferUiStore.filtered.length} / {adminBnrTransferUiStore.items.length} BNRIds
			</div>
		</div>
	);
});
