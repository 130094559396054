import React, { useRef, useEffect } from 'react';
import { observer } from 'mobx-react';
import { init, EChartsOption, SetOptionOpts, ECharts } from 'echarts';
import colors from 'assets/scss/colors.module.scss';
import _, { debounce } from 'lodash';

export interface ISankeyData {
	source: string;
	target: string;
	key?: string;
	value?: number;
	sourceColor?: string;
	targetColor?: string;
}

interface INodes {
	name: string;
	itemStyle?: {
		color: string;
	};
}
interface IConvertedData {
	nodes: INodes[];
	links: { source: string; target: string; value: number }[];
}

// add colours
const chartColors = [
	colors.blue,
	colors.yellow,
	colors.purple,
	colors.darkYellow,
	colors.green,
	colors.orange,
	colors.turquoise,
	colors.red,
	colors.richBlue,
	colors.richGreen,
	colors.richRed,
	colors.darkGreen,
	colors.richTurquoise,
	colors.brown,
];

const getChartColor = (nodes: INodes[]) => {
	const nodeColors = nodes.map((node) => node.itemStyle?.color);
	const availableColors = chartColors.filter((color) => !nodeColors.includes(color));
	return availableColors.length > 0 ? availableColors[0] : '#c99859';
};

function convertData(sankeyData: ISankeyData[]): IConvertedData {
	const result: IConvertedData = { nodes: [], links: [] };

	// sankey node names and colors
	const sources = sankeyData.map((node) => {
		return { name: node.source, color: node.sourceColor || 'notacolor' };
	});
	const targets = sankeyData.map((node) => {
		return { name: node.target, color: node.targetColor || 'notacolor' };
	});
	// remove duplicates
	const allNodes = Array.from(new Map([...sources, ...targets].map((item) => [item['name'], item])).values());

	result.nodes = allNodes.map((node) => {
		return {
			name: node.name,
			itemStyle: { color: node.color },
		};
	});
	result.nodes.forEach((node, index) => {
		if (node.itemStyle?.color === 'notacolor') {
			node.itemStyle = {
				color: getChartColor(result.nodes),
			};
		}
	});

	// sankey links and values
	sankeyData.forEach((node) => {
		result.links.push({
			source: node.source,
			target: node.target,
			value: node.value || 0,
		});
	});

	return result;
}

interface ISankeyChart {
	settings?: SetOptionOpts;
	loading?: boolean;
	data: ISankeyData[];
	opts?: EChartsOption;
	labelFormatter?: (params: any) => string;
}

export const SankeyChart = observer((props: ISankeyChart) => {
	const { settings, loading, data, labelFormatter } = props;
	const chartRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const chartData = convertData(data);

		const defaultOpts: EChartsOption = {
			series: {
				type: 'sankey',
				data: chartData.nodes,
				links: chartData.links,
				animationDuration: 250,
				animationEasing: 'quadraticOut',
				animation: true,
				emphasis: {
					focus: 'adjacency',
				},
				left: 0,
				right: 200,
				layoutIterations: 0,

				label: {
					formatter: labelFormatter || '{b} ({c})',
					rich: {
						bold: {
							fontWeight: 'bold',
						},
						italic: {
							fontStyle: 'italic',
						},
					},
				},
			},
		};

		const chartOptions: EChartsOption = _.merge({}, defaultOpts, props.opts);

		let chart: ECharts | null = null;
		if (chartRef.current !== null) {
			chart = init(chartRef.current, null, { renderer: 'svg' });
			chart.setOption(chartOptions, settings);
		}

		const handleResize = debounce(() => {
			chart?.resize();
		}, 300);
		window.addEventListener('resize', handleResize);

		return () => {
			chart?.dispose();
			window.removeEventListener('resize', handleResize.cancel);
		};
	}, [chartRef, data, settings, labelFormatter, props.opts]);

	useEffect(() => {
		return;
		/*
        // Update chart
        if (chartRef.current !== null) {
          const chart = getInstanceByDom(chartRef.current);
          if (chart) {
            chart.setOption(option, settings);
          }
        }
        */
	}, [chartRef, settings]);

	useEffect(() => {
		return;
		// Update chart
		/*
        if (chartRef.current !== null) {
          const chart = getInstanceByDom(chartRef.current);
          loading === true ? chart!.showLoading() : chart!.hideLoading();
        }
        */
	}, [loading]);

	return <div style={{ width: '100%', height: '500px', background: '#ffffff', breakInside: 'avoid' }} ref={chartRef}></div>;
});
