import { Einheit } from 'app/models/core/einheit.model';
import { BnrGpPosModel } from '../bnr.gp.pos.model';
const posDef: BnrGpPosModel[] = [];

posDef.push(new BnrGpPosModel({ year: 2024, posId: 2275001, group: 'vss', posText: 'Weiterbildungspfl. BNR', planKey: 'idd', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2024, posId: 2275021, group: 'vss', posText: 'Leben Ris. Bew Digi', planKey: 'lebenrisbewoze', einheit: Einheit.lfNST }));
posDef.push(new BnrGpPosModel({ year: 2024, posId: 2275031, group: 'vss', posText: 'Kranken HKV Pers. Digi', planKey: 'krankenbkwbew', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2024, posId: 2275041, group: 'vss', posText: 'NMZ o. P EUR', planKey: 'nettomittelzufluss', einheit: Einheit.bew }));

posDef.push(new BnrGpPosModel({ year: 2024, posId: 2275111, group: 'neh', posText: 'Leben EB Digi', planKey: 'vagrentelebenbewzoze', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2024, posId: 2275125, group: 'neh', posText: 'Leben Firmen Fonl o.BZM', planKey: 'lebenfirmenfonl', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2024, posId: 2275101, group: 'neh', posText: 'Rente/Leben Bew Digi', planKey: 'rentelebenbewdigi', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2024, posId: 2275121, group: 'neh', posText: 'Leben Firmen Bew.', planKey: 'lebenknpbew', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2024, posId: 2275131, group: 'neh', posText: 'Fonds Bewertung', planKey: 'fondsbew', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2024, posId: 2275151, group: 'neh', posText: 'Kranken bKV Bew', planKey: 'krankenbkvbew', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2024, posId: 2275161, group: 'neh', posText: 'KV u. Pfl. Zusatz Bew Dig', planKey: 'krankenzusatzbew', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2024, posId: 2275171, group: 'neh', posText: 'Bausp./BauFi Bewertung', planKey: 'baufinanzierungbew', einheit: Einheit.bew }));

posDef.push(new BnrGpPosModel({ year: 2024, posId: 3875021, group: 'gpplus', posText: 'Wiederanlage', planKey: 'wiederanlagesummeleben', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2024, posId: 3875031, group: 'gpplus', posText: 'Firmen Fonds Bew', planKey: 'firmenfondsbew', einheit: Einheit.bew }));
posDef.push(new BnrGpPosModel({ year: 2024, posId: 3875041, group: 'gpplus', posText: 'Kranken Pflege Bew Digi', planKey: 'krankpflegebewdigi', einheit: Einheit.bew }));

export const LpvPositionen = posDef;
