import BaseContainer from 'app/containers/BaseContainer';
import { observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router-dom';
import { Wochenmail } from 'app/components/wochenmail/Wochenmail';

const WochenmailContainer = observer((props: RouteComponentProps) => {
	return (
		<BaseContainer sideMenu={<></>} navbar={<div>Wochenmail</div>}>
			<Wochenmail />
		</BaseContainer>
	);
});

export default WochenmailContainer;
