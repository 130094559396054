import { useStore } from 'app/context';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { ChangePasswordForm } from './ChangePasswordForm';
import { Button } from './common/Button';
import { CheckboxControl } from './common/CheckboxControl';
import { notify } from './common/notify';
import { Modal } from './Modal';

export const SettingsForm = observer(() => {
	const { session, uiStore, userSettingsStore } = useStore();

	const setTracking = (name: string, val: boolean) => {
		session.setTrackingState(val);
		notify('Tracking', val ? 'Ein' : 'Aus', 'info');
	};

	const toggleEmailNotification = async (name: string, val: boolean) => {
		await userSettingsStore.setEmailNotification(val);
		runInAction(() => {
			session.currentUser!.emailNotifications = val;
		});
	};

	const openPwd = () => {
		uiStore.showModal(uiStore.modalIds.changePassword);
	};

	return (
		<>
			<div className="pad-1rem">
				<Button className="button" onClick={openPwd}>
					Aktuelles Passwort ändern
				</Button>
			</div>
			<div className="pad-1rem">
				<CheckboxControl label="Tracking" name="tracking" value={session.trackingEnabled} onChange={setTracking} />
			</div>
			<div className="pad-1rem">
				<CheckboxControl label="Email Wochenbericht" name="emailNotifications" value={session.currentUser!.emailNotifications} onChange={toggleEmailNotification} />
			</div>
			<Modal modalId={uiStore.modalIds.changePassword} title={'Passwort ändern'}>
				<ChangePasswordForm />
			</Modal>
		</>
	);
});
