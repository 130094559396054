import { TreePanelHeader } from 'app/components/common/TreePanelHeader';
import { TreePanelMenu } from 'app/components/common/TreePanelMenu';
import Panel from 'app/components/Panel';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import routes from 'routes';

export const AdminUsagePanel: React.FC = observer(() => {
	const { adminUsageUiStore } = useStore();
	const node = adminUsageUiStore.menu.current;
	let title = node ? node.name : 'no node';

	const history = useHistory();

	const onClose = () => {
		history.push(routes.ADMINTUSAGE.getPath());
	};

	let extras;
	//const path = node && node.currentPath ? node.currentPath : '';
	// switch (path) {
	// 	case 'bpoverview':
	// 		break;
	// 	case 'bp':
	// 		const bpPos = anwendungenUiStore.currentBpPos!;
	// 		title = ` ${node!.name} (${bpPos.einheit.longText})`;
	// 		break;
	// 	case 'leadnow':
	// 		extras = <LeadNowVideoButton />;
	// 		break;
	// 	case 'corona':
	// 		extras = <CoronaLeitfadenButton />;
	// 		break;

	// 	case '':
	// 	case undefined:
	// 	default:
	// 		break;
	// }

	let panelAreaRef = useRef<HTMLDivElement>(null);
	const [panel, setPanel] = useState<JSX.Element>(<></>);
	useEffect(() => {
		setPanel(adminUsageUiStore.currentComponent);
	}, [adminUsageUiStore.currentComponent]);

	return (
		<>
			{node && (
				<Panel classModifier="anwendungen-panel" title={title} header={<TreePanelMenu treeStore={adminUsageUiStore} />} onClose={onClose}>
					<TreePanelHeader title={title} panelAreaRef={panelAreaRef} treeStore={adminUsageUiStore} extras={extras} />
					<div className="panel-body" ref={panelAreaRef}>
						{panel}
					</div>
				</Panel>
			)}
		</>
	);
});
