import { ErrungenschaftState } from 'app/models/errungenshaften/errungenschaften.model';
import { KvAgtMsModel } from 'app/models/kv.agt.ms.model';
import { action, computed, observable } from 'mobx';
import moment from 'moment';
import { BaseCachedEntityStore } from './core/base.cached.entity.store';
import { KvBnrYearErrungenschaftenModel } from './kv.bnr.year.errungenschaften.store';

export class KvHistoryEntry {
	constructor(opts: any) {
		this.valueString = opts.valueString;
		this.changed = opts.changed;
		this.kw = opts.kw;
	}

	valueString: string = '';
	changed: Date = new Date();
	kw: string;

	static fromErrungenschaftModel(opts: KvAgenturYearErrungenschaftenModel | KvBnrYearErrungenschaftenModel) {
		return new KvHistoryEntry({
			valueString: opts.valueString,
			changed: new Date(),
			kw: moment().format('YYYYww'),
		});
	}
}

export class KvAgenturYearErrungenschaftenModel extends KvAgtMsModel {
	constructor(opts: any) {
		super(opts);
		if (this.valueJson) {
			this.history = JSON.parse(this.valueJson);
		}
	}

	@observable
	history?: KvHistoryEntry[];

	@computed
	get state() {
		return this.valueString as ErrungenschaftState;
	}

	@computed
	get year() {
		return this.bm;
	}

	@action
	addHistoryEntry() {
		if (!this.history) {
			this.history = [];
		}
		this.history.push(KvHistoryEntry.fromErrungenschaftModel(this));
		this.valueJson = JSON.stringify(this.history);
	}
}

export class KvAgenturYearErrungenschaftenStore extends BaseCachedEntityStore<KvAgenturYearErrungenschaftenModel> {
	idProp: string = 'id';
	apiPath = 'api/kv_agentur_ms/errungenschaften/' + moment().year();
	disableFindForStearts = [123];

	createInstances(opts: any): KvAgenturYearErrungenschaftenModel {
		return new KvAgenturYearErrungenschaftenModel(opts);
	}

	async saveMany(items: KvAgenturYearErrungenschaftenModel[]) {
		return this.post('many', items);
	}
}
