import { BaseCachedEntityStore } from './core/base.cached.entity.store';
import { LeadnowKVModel } from 'app/models/leadnowcheck/leadnow.kv.model';

export class KvLeadnowCheckStore extends BaseCachedEntityStore<LeadnowKVModel> {
	apiPath = 'api/leadnow';
	idProp: string = 'id';
	createInstances(opts: any): LeadnowKVModel {
		return new LeadnowKVModel(opts);
	}
}
