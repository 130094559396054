import { useEffect, useMemo } from 'react';
import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import { Table } from './../../table/table';
import { ITableColumn, TableModel, TableRow } from './../../table/table.model';
import { useHistory } from 'react-router';
import routes from 'routes';
import { Button } from 'app/components/common/Button';
import { NewsVM } from 'app/stores/ui/news.ui.store';
import { formatDate } from 'app/utils';

export const AdminNewsList = observer(() => {
    const { adminNewsUiStore } = useStore();
    const history = useHistory();

    const tm = useMemo(() => {
        const editNews = (doc: NewsVM) => {

            history.push(routes.ADMINNEWS.getPath('edit', doc.id));
        };

        const tm = new TableModel<NewsVM, number>();
        let cols: ITableColumn<NewsVM, number>[] = [

            {
                label: 'Titel',
                path: 'news.title',
            },
            {
                label: 'Text',
                path: 'textTrunc',

            },
            {
                label: 'Freigegeben für',
                path: 'news.publishForRoles',
                format: (val) => val.join(','),
            },
            {
                label: 'Öffi ab',
                path: 'news.publishDate',
                format: formatDate
            },
            {
                label: '',
                path: 'news.id',
                render: (row: TableRow<NewsVM>) => {
                    return <Button onClick={() => editNews(row.data)}> Bearbeiten </Button>;
                },
            },
        ];

        tm.setCols(cols);
        tm.sortBy = 'news.publishDate';
        tm.sortAsc = false;
        tm.idProperty = 'id';
        tm.idType = 'string';
        return tm;
    }, [history]);

    useEffect(() => {
        adminNewsUiStore.findAll().then(() => {
            tm.setRowData(adminNewsUiStore.currentItems);
        });
    }, [adminNewsUiStore, adminNewsUiStore.currentItems, tm]);

    return (
        <>
            <Table tm={tm}></Table>
        </>
    );
});