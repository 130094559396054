import { Field } from 'formik';
import { GpOpts } from 'app/models/gp/agt.gp.def';

export const GpSelectField = ({ ...props }) => {
	var { ...fieldProps } = props;
	return (
		<div className="select is-disabled">
			<Field {...fieldProps} as="select" disabled={true}>
				{GpOpts.map((p) => (
					<option key={p.value} value={p.value}>
						{p.label}
					</option>
				))}
			</Field>
		</div>
	);
};
