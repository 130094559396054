import React from 'react';
import { LeadnowList } from 'app/components/anwendungen/leadnow/LeadnowList';
import { ITreeNodeModel } from './core/tree.node.model';
import { LeiterSpeziSteartIds, SpeziSteartIds, Steart } from './core/steart.model';

export const AnwendungeTreeNodes: ITreeNodeModel = {
	name: 'root',
	icon: 'root',
	path: '',
	nodes: [
		{
			name: 'Branchenplanung',
			icon: 'chart-pie',
			path: 'bp',
			nodes: [],
			visibileForRoles: [Steart.VBL, ...SpeziSteartIds, ...LeiterSpeziSteartIds],
		},
		{
			name: 'Agenturen',
			icon: 'briefcase',
			path: 'agt',
			nodes: [
				{
					name: 'Schwerpunkt',
					icon: '',
					path: 'schwerpunkt',
					// ?? component: 'agt-schwerpunkt',
				},
				{
					name: 'Segmentierung',
					icon: '',
					path: 'segement',
					// ?? component: 'agt-schwerpunkt',
				},
			],
		},
		{
			name: 'Checklisten',
			icon: 'check',
			path: 'checklisten',
			nodes: [
				{
					name: 'Lead.NOW',
					icon: '',
					path: 'leadnow',
					component: <LeadnowList />,
				},
			],
		},

	],
};
