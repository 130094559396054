import { observer } from 'mobx-react';
import { useStore } from 'app/context';
import { Button } from '../common/Button';

export const AgenturListFilterDelete = observer(() => {
	const { agenturListUiStore } = useStore();
	const deleteFilter = () => {
		agenturListUiStore.currentFilter.deleteFilter(agenturListUiStore.currentFilter.loadedFilterName!);
	};
	return (
		<>
			{agenturListUiStore.currentFilter.loadedFilterName && (
				<Button className="button is-warning is-inverted" onClick={deleteFilter}>
					filter löschen
				</Button>
			)}
		</>
	);
});
