import { Button } from 'app/components/common/Button';
import { Icon } from 'app/components/common/Icon';
import Widget, { IWidgetConsumer } from 'app/components/common/Widget';
import { useStore } from 'app/context';
import { AgtPlanThemaVM } from 'app/stores/ui/agt.plan.themen.ui.store';
import { AgtPlanYearVM } from 'app/stores/ui/agt.plan.ui.store';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { AgtPlanTasks } from './AgtPlanungsTasks';

interface IAgtPlanungsThema {
	plan: AgtPlanYearVM;
	thema: AgtPlanThemaVM;
}
export const AgtPlanungsThema = observer((props: IAgtPlanungsThema) => {
	const thema = props.thema;
	const [open, setOpen] = useState(false);
	const toggleOpen = () => {
		setOpen(!open);
	};
	return (
		<div className="themen-list-item" key={thema.thema.key}>
			<Button className="button is-icon is-themen-list-button" onClick={toggleOpen}>
				{open ? <Icon iconClass={'caret-down'}></Icon> : <Icon iconClass={'caret-right'}></Icon>}
				<span className="themen-details">
					<span className="themen-title">{thema.thema.title}</span>
					<span className="themen-count">
						({thema.tasksCompleted.length} / {thema.tasks.length}) Maßnahmen
					</span>
				</span>
			</Button>
			{open && <AgtPlanTasks tasks={thema.tasks} />}
		</div>
	);
});

interface IAgtPlanungsThemen {
	plan: AgtPlanYearVM;
}
export const AgtPlanungsThemen = observer((props: IAgtPlanungsThemen) => {
	const { agtPlanThemenUiStore } = useStore();
	return (
		<div className="themen-list">
			{agtPlanThemenUiStore.themen.map((t, i) => (
				<AgtPlanungsThema key={t.thema.key} {...props} thema={t} />
			))}
		</div>
	);
});

export const AgtPlanungsThemenWidget = observer((props: IWidgetConsumer) => {
	const { agtPlanUiStore, agtPlanThemenUiStore, uiStore, taskUiStore } = useStore();

	const [showThemen, setShowThemen] = useState(true);

	useEffect(() => {
		taskUiStore.findAll().then((tasks) => {});
	}, [taskUiStore]);

	useEffect(() => {
		if (uiStore.apThemenEdit.enabled) {
			agtPlanThemenUiStore.initTree();
			uiStore.showModal(uiStore.modalIds.apThemenAuswahl);
		}
	}, [uiStore.apThemenEdit.enabled, uiStore, agtPlanThemenUiStore]);

	return (
		<>
			{agtPlanUiStore.current && (
				<Widget title="Themen und Maßnahmen" modifier="is-themen-widget" edit={uiStore.apThemenEdit} widgetId={props.widgetKey}>
					<div className="button-group is-full-width">
						<Button className={`button is-primary is-small is-inverted ${showThemen ? 'is-active' : ''}`} onClick={() => setShowThemen(true)} disabled={showThemen}>
							Themen
						</Button>
						<Button className={`button is-primary is-small is-inverted ${!showThemen ? 'is-active' : ''}`} onClick={() => setShowThemen(false)} disabled={!showThemen}>
							Maßnahmen
						</Button>
					</div>

					{showThemen && <AgtPlanungsThemen plan={agtPlanUiStore.current} />}
					{!showThemen && (
						<div className="pad-top-1rem">
							<AgtPlanTasks tasks={agtPlanThemenUiStore.planTasks} />
						</div>
					)}
				</Widget>
			)}
		</>
	);
});
