import BaseContainer from 'app/containers/BaseContainer';
import { observer } from 'mobx-react';
import { MainMenu } from './MainMenu';
import { RouteComponentProps } from 'react-router-dom';
import { TasksList } from 'app/components/tasks/TasksList';
import React, { useEffect } from 'react';
import { TaskViewerModal } from 'app/components/tasks/TaskViewModal';
import { runInAction } from 'mobx';
import { useStore } from 'app/context';
import { TasksFilter } from 'app/components/tasks/TasksFilter';
import { TaskNewButton } from 'app/components/tasks/TaskNewButton';

const TasksContainer = observer((props: RouteComponentProps) => {
	const { taskUiStore } = useStore();
	useEffect(() => {
		runInAction(() => {
			taskUiStore.currentFilter.reset();
		});
	}, [taskUiStore, taskUiStore.currentFilter]);

	return (
		<BaseContainer classModifier="tasks-container" sideMenu={<MainMenu {...props} />} navbar={<div>Maßnahmen</div>}>
			<div className="tasks-grid">
				<TasksFilter />
				<TaskNewButton />
				<TasksList />
				<TaskViewerModal showAgt={true} showPlan={true} />
			</div>
		</BaseContainer>
	);
});

export default TasksContainer;
