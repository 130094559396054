import { FindSteart } from 'app/models/core/steart.model';
import { computed } from 'mobx';
import { BaseCachedEntityStore } from './core/base.cached.entity.store';

export class BnrModel {
	constructor(opts: any) {
		this.bnrId = opts.bnrId;
		this.bnrSteart = opts.bnrSteart;
		this.name = opts.name;
	}
	bnrId: number;
	bnrSteart: number;
	name: string;

	@computed
	get steart() {
		return FindSteart(this.bnrSteart);
	}
}

export class BnrStore extends BaseCachedEntityStore<BnrModel> {
	apiPath = 'api/vbl/all';
	idProp: string = 'id';
	createInstances(opts: any): BnrModel {
		return new BnrModel(opts);
	}
}
