import { CoronaCheckFormItem } from 'app/models/corona/corona.check.form.item';
import { LeadnowCheckFormItem } from 'app/models/leadnowcheck/leadnow.check.form.item';
import { Field } from 'formik';
import { observer } from 'mobx-react';
import React from 'react';

interface IFieldRow {
	formItem: LeadnowCheckFormItem | CoronaCheckFormItem;
	index: number;
}
export const CheckCommentField = observer((props: IFieldRow) => {
	const index = props.index;
	const path = `${index}.data.comment`;
	return <Field placeholder="Kommentar" name={path} type="text" />;
});
