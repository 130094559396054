import { Button } from 'app/components/common/Button';
import { Icon } from 'app/components/common/Icon';
import { useStore } from 'app/context';
import { AgenturModel } from 'app/models/agentur.model';
import { LeadnowCheckFormItem } from 'app/models/leadnowcheck/leadnow.check.form.item';
import { LeadNowCheckVM } from 'app/models/leadnowcheck/leadnow.check.vm';
import { observer } from 'mobx-react';
import { Form, Formik } from 'formik';
import { ILeadNowFormGroup, LeadnowFormGroups } from 'app/models/leadnowcheck/leadnow.form.items';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import * as Yup from 'yup';
import { CheckCommentField } from '../check-shared/CheckCommentField';
import { CheckFormThema } from '../check-shared/CheckFormThema';
import { CheckStatusField } from '../check-shared/CheckStatusField';
import { LeadNowIcon } from './LeadNowIcon';
import { CheckActionButton } from '../check-shared/CheckActionButton';

interface ILeadNowEdit {
	agt: AgenturModel;
	onDone: () => void;
}

interface IChecklistFormGroup {
	group: ILeadNowFormGroup;
	groupItems: LeadnowCheckFormItem[];
	model: LeadNowCheckVM;
}
export const ChecklistFormGroup = observer((props: IChecklistFormGroup) => {
	const group = props.group;
	const items = props.groupItems;
	const model = props.model;

	return (
		<>
			<tbody className={group.groupId === 'overall' ? 'is-overall' : ''} key={group.groupId}>
				<ChecklistGroupRow {...group} />
				{items.map((formItem) => {
					const index = model.items.findIndex((i) => i.data.key === formItem.data.key);
					return <ChecklistFieldRow key={formItem.data.key} formItem={formItem} index={index} />;
				})}
			</tbody>
		</>
	);
});

export const ChecklistGroupRow = observer((props: ILeadNowFormGroup) => {
	const group = props;
	if (group.visible === false) {
		return <></>;
	}

	return (
		<>
			<tr className={`checklist-group-title`}>
				<th colSpan={3}>{group.name}</th>
			</tr>

			{group.groupId !== 'overall' && (
				<tr>
					<td width={350}>Thema</td>
					<td width={150}>Status</td>
					<td>Kommentar</td>
				</tr>
			)}
		</>
	);
});
interface IChecklistFieldRow {
	formItem: LeadnowCheckFormItem;
	index: number;
}

export const ChecklistFieldRow = observer((props: IChecklistFieldRow) => {
	const formItem = props.formItem;
	if (formItem.visible === false) {
		return <></>;
	}
	const data = formItem.data;

	if (data.groupId === 'overall') {
		return (
			<tr className="checklist-group-item" key={formItem.data.key}>
				<td colSpan={3}>
					<CheckStatusField {...props} />
				</td>
			</tr>
		);
	}

	return (
		<tr className="checklist-group-item" key={formItem.data.key}>
			<td>
				<CheckFormThema {...props} />
			</td>
			<td>
				{formItem.editable ? (
					<>
						<CheckStatusField {...props} />
					</>
				) : (
					<>
						<ReadOnlyState state={data.state} />
					</>
				)}
			</td>
			<td>
				<CheckCommentField {...props} />
				<CheckActionButton {...props} />
			</td>
		</tr>
	);
});

interface IReadOnlyState {
	state?: string;
}
export const ReadOnlyState = observer((props: IReadOnlyState) => {
	return (
		<Button className={`button is-block ${props.state === 'done' ? 'is-green' : 'is-red'}`}>
			<Icon iconClass={props.state === 'done' ? 'check' : 'times'} />
		</Button>
	);
});

export const leadNowSchema = Yup.array().of(
	Yup.object().shape({
		data: Yup.object().shape({
			state: Yup.string().nullable(),
			comment: Yup.string(),
		}),
	}),
);

export const LeadNowEdit = observer((props: ILeadNowEdit) => {
	const { agtLeadNowCheckUiStore, anwendungenUiStore } = useStore();

	const [model, setModel] = useState<LeadNowCheckVM>();
	useEffect(() => {
		agtLeadNowCheckUiStore.findByAgtId(props.agt.agtId).then((model) => {
			setModel(model);
		});
	}, [agtLeadNowCheckUiStore, props.agt.agtId]);

	const handleSubmit = async (values: LeadnowCheckFormItem[]) => {
		await agtLeadNowCheckUiStore.saveMany(props.agt.agtId, values);
		props.onDone();
	};

	const location = useLocation();

	const openIntroMail = () => {
		agtLeadNowCheckUiStore.openIntroMail(anwendungenUiStore.selectedAgt!, location);
	};

	if (!model) {
		return <></>;
	}

	return (
		<Formik validationSchema={leadNowSchema} initialValues={model.items} onSubmit={handleSubmit} validateOnMount={true} enableReinitialize={true}>
			{({ isValid, resetForm }) => {
				const onCancel = () => {
					resetForm();
					props.onDone();
				};
				return (
					<Form className="checklist">
						<div className="checklist-intro leadnow-intro">
							<div className="content">
								<p>
									<strong>Grundlagen zum Lead Management</strong>
								</p>
								<p>
									Klare Zielstellung, kontinuierlicher Invest, strukturierte und schnelle Kontaktaufnahme und regelmäßiges Controlling bilden die Rahmenbedingungen für erfolgreiche Neukundengewinnung mit Leads. Dabei ist zu beachten, dass es sich im Leadgeschäft
									um ein Quotengeschäft handelt. Hartnäckigkeit und Durchhaltevermögen zahlen sich dabei aus.
								</p>
								<p>Ihr Gesamterfolg basiert maßgeblich auf Ihrer Beratungsqualität.</p>
								<p>
									Wir empfehlen jeder Agentur, sich das{' '}
									<a href="https://amisonline.allianz.de/protected/agentur/marketingundwerbung/internetagenturcenter/leadmanagement-lead-now/index.html" target="_blank" rel="noreferrer">
										Erklärvideo zu Lead Management
									</a>{' '}
									anzuschauen.
								</p>
							</div>
							<div className="icon">
								<LeadNowIcon iconType={model.iconType} iconColor={model.iconColor} />
							</div>
						</div>

						<table className="checklist-form">
							{LeadnowFormGroups.map((group) => {
								const groupItems = model.items.filter((i) => i.data.groupId === group.groupId);
								return <ChecklistFormGroup key={group.groupId} group={group} groupItems={groupItems} model={model} />;
							})}
						</table>

						<div className="panel-fake-footer grid is-col-half">
							<div className="left">
								<Button className="button" onClick={openIntroMail}>
									Intro Mail Lead.NOW
								</Button>
							</div>
							<div className="text-right">
								<Button type="button" className="button is-secondary " onClick={onCancel}>
									Abbrechen
								</Button>
								<Button type="submit" className="button is-primary " isFormInvalid={!isValid}>
									Speichern
								</Button>
							</div>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
});
