import { ZielErgebnisBalkenModel } from 'app/components/charts/ZielErgebnisBalken';
import { AgtProdPosVM } from 'app/models/berichte/agt.prod.pos.vm';
import { BnrProdPosModel } from 'app/models/berichte/bnr.prod.pos.model';
import { UserModel } from 'app/models/core/user.model';
import _ from 'lodash';
import { AgenturStore } from '../agentur.store';
import { BnrGpStore } from '../bnr.gp.store';
import { BetreuerZielStore, BnrZielModel, BnrZielStore } from '../bnr.ziel.store';
import { SessionStore } from '../session.store';
import { AgtProdUiStore } from './agt.prod.ui.store';
import { GPMAXYEAR } from './berichte.ui.store';
import { BnrGpPosModel } from 'app/models/gp/bnr.gp.pos.model';

export class BnrProdUiStore {
	session: SessionStore;
	agenturStore: AgenturStore;
	bnrZielStore: BnrZielStore;
	agtProdUiStore: AgtProdUiStore;
	gpStore: BnrGpStore;
	betreuerZielStore: BetreuerZielStore;
	constructor(
		session: SessionStore,
		agenturStore: AgenturStore,
		bnrZielStore: BnrZielStore,
		agtProdUiStore: AgtProdUiStore,
		gpStore: BnrGpStore,
		betreuerZielStore: BetreuerZielStore,
	) {
		this.session = session;
		this.agenturStore = agenturStore;
		this.bnrZielStore = bnrZielStore;
		this.agtProdUiStore = agtProdUiStore;
		this.gpStore = gpStore;
		this.betreuerZielStore = betreuerZielStore;
	}
	onLogout() {
		this.agenturStore.onLogout();
	}

	// async findAll(year: number) {
	// 	let all = await this.bnrZielStore.findAll();
	// 	all = all.filter((x) => x.year === year);

	// 	let positionen = this.gpStore.positionen;
	// 	if (year < GPMAXYEAR) {
	// 		positionen = this.gpStore.positionenVorjahr;
	// 	}

	// 	const res: BnrProdPosModel[] = [];
	// 	for (const posDef of positionen) {
	// 		const posVal = all.find((i) => i.posId === posDef._posId);
	// 		let eigeneVals: AgtProdPosVM[] = [];
	// 		if (posDef.planKey) {
	// 			eigeneVals = await this.agtProdUiStore.findByPlanKey(posDef.planKey, year);
	// 		}
	// 		const eigenesZiel = _.sumBy(eigeneVals, (e) => (e.eigenerVal ? e.eigenerVal.valueNum : 0));
	// 		const d = new BnrProdPosModel({ posDef, posVal, eigenesZiel });
	// 		res.push(d);
	// 	}
	// 	return res;
	// }

	async findAllForBetreuer(u: UserModel, year: number) {
		let all = await this.betreuerZielStore.findAllByBnrId(u.bnrId);
		all = all.filter((x) => x.year === year);

		let positionen = this.gpStore.getPositionBySteart(u.steart.steart);
		if (year < GPMAXYEAR) {
			positionen = this.gpStore.getPositionVorjahrBySteart(u.steart.steart);
		}

		const res: BnrProdPosModel[] = [];
		for (const posDef of positionen) {
			const posVals = all.filter((i) => posDef.allPostIds.includes(i.posId));
			let eigeneVals: AgtProdPosVM[] = [];
			if (posDef.planKey) {
				eigeneVals = await this.agtProdUiStore.findByPlanKey(posDef.planKey, year);
			}
			const eigenesZiel = _.sumBy(eigeneVals, (e) => (e.eigenerVal ? e.eigenerVal.valueNum : 0));
			const d = this.handleBoniStufeForBnr(u, posDef, posVals, eigenesZiel);
			res.push(d);
		}
		return res;
	}

	handleBoniStufeForBnr(u: UserModel, gpPos: BnrGpPosModel, values: BnrZielModel[], ev: number) {
		values = _.sortBy(values, (v) => v.ziel);
		const v = values[0];

		const erg = v ? v.ergebnis : 0;
		let currStufe = 0;
		let boniStufenZiele: number[] = [];

		if (gpPos.boniStufen > 1 && values.length > 1) {
			values.forEach((bv) => {
				boniStufenZiele.push(bv.ziel);
				if (erg > bv.ziel) {
					currStufe += 1;
				}
			});
		}
		const p = new BnrProdPosModel({ posDef: gpPos, posVal: v, eigenesZiel: ev, boniStufe: currStufe, boniStufenZiele });

		return p;
	}

	async getZeForPosId(posIds: number[], year: number) {
		let item;
		for (const posId of posIds) {
			item = await this.bnrZielStore.findByPosId(posId, year);
			if (item) {
				break;
			}
		}
		if (item) {
			return new ZielErgebnisBalkenModel(item.ziel, item.ergebnis);
		}
		return new ZielErgebnisBalkenModel(0, 0);
	}

	async getZeForPlanKey(planKey: string, year: number) {
		return this.getZeForPosIdForBetreuerByPlanKey(this.session.currentUser!.asUserModel, planKey, year);
	}

	async getZeForPosIdForBetreuerByPosId(u: UserModel, posIds: number[], year: number) {
		let item;
		for (const posId of posIds) {
			item = await this.betreuerZielStore.findByBnrIdPosId(u.bnrId, posId, year);
			if (item) {
				break;
			}
		}
		if (item) {
			return new ZielErgebnisBalkenModel(item.ziel, item.ergebnis);
		}
		return new ZielErgebnisBalkenModel(0, 0);
	}
	async getZeForPosIdForBetreuerByPlanKey(u: UserModel, planKey: string, year: number) {
		let all = await this.betreuerZielStore.findAllByBnrId(u.bnrId);
		all = all.filter((x) => x.year === year);

		let positionen = this.gpStore.getPositionBySteart(u.steart.steart);
		if (year < GPMAXYEAR) {
			positionen = this.gpStore.getPositionVorjahrBySteart(u.steart.steart);
		}
		const posDef = positionen.find((p) => p.planKey === planKey);
		if (posDef) {
			const posVal = all.find((i) => posDef!.posId.includes(i.posId));
			const d = new BnrProdPosModel({ posDef, posVal, eigenesZiel: 0 });
			if (d.posVal) {
				return new ZielErgebnisBalkenModel(d.posVal.ziel, d.posVal.ergebnis);
			}
		}

		return new ZielErgebnisBalkenModel(0, 0);
	}
}

export class BetreuerBnrProdUiStore {
	session: SessionStore;
	agenturStore: AgenturStore;
	bnrZielStore: BnrZielStore;
	agtProdUiStore: AgtProdUiStore;
	gpStore: BnrGpStore;
	constructor(session: SessionStore, agenturStore: AgenturStore, posBnrZielStore: BnrZielStore, agtProdUiStore: AgtProdUiStore, gpStore: BnrGpStore) {
		this.session = session;
		this.agenturStore = agenturStore;
		this.bnrZielStore = posBnrZielStore;
		this.agtProdUiStore = agtProdUiStore;
		this.gpStore = gpStore;
	}
	onLogout() {
		this.agenturStore.onLogout();
	}

	async findAll(year: number) {
		let all = await this.bnrZielStore.findAll();
		all = all.filter((x) => x.year === year);

		let positionen = this.gpStore.positionen;
		if (year < GPMAXYEAR) {
			positionen = this.gpStore.positionenVorjahr;
		}

		const res: BnrProdPosModel[] = [];
		for (const posDef of positionen) {
			const posVal = all.find((i) => posDef.posId.includes(i.posId));
			let eigeneVals: AgtProdPosVM[] = [];
			if (posDef.planKey) {
				eigeneVals = await this.agtProdUiStore.findByPlanKey(posDef.planKey, year);
			}
			const eigenesZiel = _.sumBy(eigeneVals, (e) => (e.eigenerVal ? e.eigenerVal.valueNum : 0));
			const d = new BnrProdPosModel({ posDef, posVal, eigenesZiel });
			res.push(d);
		}
		return res;
	}

	async getZeForPosId(posId: number, year: number) {
		const item = await this.bnrZielStore.findByPosId(posId, year);
		if (item) {
			return new ZielErgebnisBalkenModel(item.ziel, item.ergebnis);
		}
		return new ZielErgebnisBalkenModel(0, 0);
	}
}
