import { UserModel } from 'app/models/core/user.model';
import { SegementStates, SegmentState } from 'app/models/segment/segment.model';
import _ from 'lodash';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { UserStore } from '../user.store';
import { schwerpunktRoles } from './agt.schwerpunkt.ui.store';

export class AgtUserUiStore {
	userStore: UserStore;

	constructor(userStore: UserStore) {
		makeObservable(this);
		this.userStore = userStore;
	}

	@observable
	loaded: boolean = false;

	@observable
	users: UserModel[] = [];

	@action
	async load() {
		if (this.loaded) {
			return this.users;
		}
		const res = await this.userStore.findAll();
		runInAction(() => {
			this.users = res;
			this.loaded = true;
		});
		return this.users;
	}

	async getUserByBnrId(bnrId: number) {
		await this.load();
		return this.users.find((u) => u.bnrId === bnrId);
	}

	@computed
	get betreuerOptsExcludingSelf() {
		let sorted = _.sortBy(this.users, 'steartText', 'name').reverse();
		let betreuer = sorted.map((u) => {
			return {
				label: `${u.name} [${u.steartText}] `,
				value: u.bnrId,
			};
		});
		betreuer = [
			{
				label: `Ohne VB `,
				value: 999,
			},
		].concat(betreuer.filter((b) => b.value !== this.userStore.session.currentUser!.bnrId));
		return betreuer;
	}

	@computed
	get betreuerOptsAllExcludingSelf() {
		let sorted = _.sortBy(this.users, 'steartText', 'name').reverse();
		sorted.filter((u) => u.steartText !== 'GSL'); // remove GSL

		let betreuer = sorted.map((u) => {
			return {
				label: `${u.name} [${u.steartText}] `,
				value: u.bnrId,
			};
		});
		return betreuer.filter((b) => b.value !== this.userStore.session.currentUser!.bnrId);
	}





	onLogout() {
		this.userStore.onLogout();
	}
}
