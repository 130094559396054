import { useStore } from 'app/context';
import { TemplateVM } from 'app/stores/ui/template.ui.store';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import routes from 'routes';
import Panel from '../../Panel';
import { AdminTemplateEditForm } from './AdminTemplateEditForm';

export const AdminTemplateDetail = observer(() => {
	const { adminTemplatesUiStore } = useStore();
	const [title, setTitle] = useState('');
	const [template, setTemplate] = useState<TemplateVM>();

	const history = useHistory();

	useEffect(() => {
		setTemplate(undefined);
		if (adminTemplatesUiStore.current) {
			setTitle('Job Details ' + adminTemplatesUiStore.current.template.content.title);
			setTemplate(adminTemplatesUiStore.current);
		}
		if (adminTemplatesUiStore.newTemplate) {
			setTitle('Neue Vorlage ');
			setTemplate(adminTemplatesUiStore.newTemplate);
		}
	}, [adminTemplatesUiStore.newTemplate, adminTemplatesUiStore]);

	const onClose = () => {
		runInAction(() => {
			adminTemplatesUiStore.current = undefined;
			adminTemplatesUiStore.newTemplate = undefined;
			setTemplate(undefined);
			history.push(routes.ADMINTEMPLATES.getPath());
		});
	};

	return (
		<>
			{template && (
				<Panel title={title} header={<> </>} onClose={onClose}>
					{template && (
						<>
							<AdminTemplateEditForm template={template} />
						</>
					)}
				</Panel>
			)}
		</>
	);
});
