import { useStore } from 'app/context';
import { observer } from 'mobx-react';
import React from 'react';
import { Button } from '../common/Button';

export const TaskNewButton = observer(() => {
	const { taskUiStore, uiStore } = useStore();

	const addMassnahme = () => {
		const task = taskUiStore.addMassnahme();
		taskUiStore.setCurrentModalTask(task);
		uiStore.showModal(uiStore.modalIds.taskViewModal);
	};
	return (
		<>
			<Button className="button is-primary is-inverted new-task-button" onClick={addMassnahme}>
				Neue Maßnahme erstellen
			</Button>
		</>
	);
});
