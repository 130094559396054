import { LeadNowCheckVM } from 'app/models/leadnowcheck/leadnow.check.vm';
import { observer } from 'mobx-react';
interface ILeadNowIcon {
	model: LeadNowCheckVM;
}

export const LeadNowOverallState = observer((props: ILeadNowIcon) => {
	let txt = '';
	switch (props.model.overallState) {
		case 'yes':
		case 'member':
			txt = 'Lead.NOW Teilnehmer';
			break;
		case 'maybe':
			txt = 'Teilnahme gewünscht';
			break;
		case 'rejected':
			txt = 'Abgelehnt';
			break;
	}
	return <>{txt} </>;
});
