const data = [
	{ gpId: 1116, name: 'GP GV/HV - Variante 60' },
	{ gpId: 1119, name: 'GP GV/HV - Variante 90' },
	{ gpId: 1111, name: 'GP GV/HV - Variante 10' },
	{ gpId: 1130, name: 'GP GV/HV mit Pro3-Provisionsmodell' },
	{ gpId: 1110, name: 'GP GV/HV - Basisvariante ' },
	{ gpId: 1113, name: 'GP GV/HV - Variante 30' },
	{ gpId: 1114, name: 'GP GV/HV - Variante 40' },
	{ gpId: 1115, name: 'GP GV/HV - Variante 53-54' },
	{ gpId: 1117, name: 'GP GV/HV - Variante 70' },
];

export class GpModel {
	constructor(opts: any) {
		this.gpId = opts.gpId;
		this.name = opts.name;
	}
	gpId: number;
	name: string;
}

export const GpModels = data.map((d) => new GpModel(d));

export const DefaultGp = GpModels.find((d) => d.gpId === 1130)!;

export const GpOpts = GpModels.map((g) => {
	return {
		label: g.name,
		value: g.gpId,
	};
});
